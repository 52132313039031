import styled from "styled-components";

const StyledHeader = styled.div<{ isActive: boolean }>`
  ${(p) => p.theme.textStyle.uiS};
  box-sizing: border-box;
  display: flex;
  flex-flow: column wrap;
  flex-direction: row;
  color: ${(p) => (p.isActive ? p.theme.color.textHighEmphasis : p.theme.color.textMediumEmphasis)};
  width: 100%;
  justify-content: center;
  position: relative;
  &:hover {
    color: ${(p) => p.theme.color.interactive01};
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  svg {
    margin-left: 4px;
  }
`;

const InteractiveArrow = styled.div`
  display: flex;
  svg {
    path {
      fill: ${(p) => p.theme.color.interactive01};
    }
  }
`;

const ChecksWrapper = styled.div`
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.s};
`;

export { StyledHeader, IconsWrapper, InteractiveArrow, ChecksWrapper };
