import { IColumn } from "@ax/types";

const getMaxColumns = (width: number, isSimple: boolean): { value: number; text: string } => {
  let maxColumns = 5;
  if (width >= 1680) {
    maxColumns = 6;
  }
  if (width > 1920) {
    maxColumns = 7;
  }

  if (isSimple) {
    maxColumns = maxColumns - 1;
  }

  switch (maxColumns) {
    case 4:
      return { value: 4, text: "four" };
    case 6:
      return { value: 6, text: "six" };
    case 7:
      return { value: 7, text: "seven" };
    default:
      return { value: 5, text: "five" };
  }
};

const updateColumns = (columnsState: IColumn[], maxColumns: number): IColumn[] => {
  if (!columnsState) return [];
  const activeColumns = columnsState.filter((col) => col.show).length;
  let availableColumns = maxColumns - activeColumns;

  return columnsState.map((col) => {
    if (!col.show && availableColumns > 0) {
      col.show = true;
      availableColumns = availableColumns - 1;
    }
    if (col.show && !col.default && availableColumns < 0) {
      col.show = false;
    }
    return col;
  });
};

export { getMaxColumns, updateColumns };
