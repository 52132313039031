import React from "react";
import { NavLink } from "react-router-dom";

import { SubNav, MenuItem } from "@ax/components";

import * as S from "./style";

const DefaultNav = (props: IProps): JSX.Element => {
  const { current, defaultTypes, onClick } = props;

  return (
    <SubNav>
      {defaultTypes &&
        defaultTypes.map((type: string, key: number) => {
          const isSelected = !!current && type === current;
          const selectedClass = isSelected ? "selected" : "";
          const handleClick = () => onClick(type);
          return (
            <MenuItem key={key} onClick={handleClick} className={selectedClass}>
              <NavLink to="#">
                <S.Link active={isSelected}>{type}</S.Link>
              </NavLink>
            </MenuItem>
          );
        })}
    </SubNav>
  );
};

interface IProps {
  current: string | null;
  defaultTypes: string[];
  onClick(id: string): void;
}

export default DefaultNav;
