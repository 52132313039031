import { template } from "./config";
import { IServiceConfig, sendRequest } from "./utils";

const SERVICES: { [key: string]: IServiceConfig } = {
  GET_MENU: {
    ...template,
    endpoint: ["/site/", "/menus"],
    method: "GET",
  },
  UPDATE_MENU: {
    ...template,
    endpoint: "/menu_container/",
    method: "PUT",
  },
};

const getMenus = (siteID: number) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_MENU;

  SERVICES.GET_MENU.dynamicUrl = `${host}${prefix}${siteID}${suffix}`;

  return sendRequest(SERVICES.GET_MENU);
};

const updateMenu = (currentMenu: any, menuID: number) => {
  const { host, endpoint } = SERVICES.UPDATE_MENU;

  SERVICES.UPDATE_MENU.dynamicUrl = `${host}${endpoint}${menuID}`;

  return sendRequest(SERVICES.UPDATE_MENU, currentMenu);
};

export default {
  getMenus,
  updateMenu,
};
