import * as React from "react";

function SvgWarning(props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 21L12 2l11 19H1zm18.53-2L12 5.99 4.47 19h15.06zM11 16v2h2v-2h-2zm0-6h2v4h-2v-4z"
        fill="#5057FF"
      />
    </svg>
  );
}

export default SvgWarning;
