import React, { useState } from "react";

import { connect } from "react-redux";
import { menuActions } from "@ax/containers/Navigation";
import { IRootState, IMenuItem, IMenuItemConfig } from "@ax/types";
import { FloatingPanel, Button } from "@ax/components";

import Form from "./Form";

import * as S from "./style";

const ConfigPanel = (props: IProps): JSX.Element => {
  const { isOpen, toggleModal, updateMenuItem, item } = props;

  const initState: IMenuItemConfig | null = item ? item.config : null;
  const [form, setForm] = useState(initState);

  const editItemAction = () => {
    const newItem = {
      ...item,
      config: form,
    };
    updateMenuItem(newItem);
    toggleModal();
  };

  const editButton = {
    label: "Update",
    action: editItemAction,
  };

  return (
    <FloatingPanel title="Subsection Config" toggleModal={toggleModal} isOpen={isOpen}>
      <S.Wrapper>
        <Form form={form} setForm={setForm} />
        <S.Footer>
          <Button className="button" type="button" onClick={editButton.action}>
            {editButton.label}
          </Button>
        </S.Footer>
      </S.Wrapper>
    </FloatingPanel>
  );
};

const mapStateToProps = (state: IRootState) => ({
  item: state.menu.item,
});

const mapDispatchToProps = {
  updateMenuItem: menuActions.updateMenuItem,
};

interface IStateProps {
  item: IMenuItem | null;
}
interface ISidePanelProps {
  isOpen: boolean;
  toggleModal(): void;
}

interface IDispatchProps {
  updateMenuItem(itemProps: any): void;
}

type IProps = IDispatchProps & ISidePanelProps & IStateProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConfigPanel);
