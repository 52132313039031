import React from "react";

const SvgHistory = props => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12a9 9 0 119 9c-2.49 0-4.73-1.01-6.36-2.64l1.42-1.42A6.944 6.944 0 0013 19c3.87 0 7-3.13 7-7s-3.13-7-7-7-7 3.13-7 7h3l-4 3.99L1 12h3zm8 1V8h1.5v4.15l3.52 2.09-.77 1.28L12 13z"
      fill="#5057FF"
    />
  </svg>
);

export default SvgHistory;
