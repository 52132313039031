import React from "react";
import { Switch, Route } from "react-router-dom";

import Redirects from "../Redirects";
import Robots from "./Robots";

const navItems = [
  {
    title: "Robots.txt Editor",
    path: "/settings/robots",
    component: Robots,
    permission: "global.seoAnalytics.editSeoAnalyticsInGlobalPages",
  },
  {
    title: "URL Redirect Manager",
    path: "/settings/redirects",
    component: Redirects,
    permission: "global.seoAnalytics.editSeoAnalyticsInGlobalPages",
  },
];

const GlobalSettings = (): JSX.Element => {
  return (
    <Switch>
      {navItems.map((item, index) => (
        <Route exact path={item.path} key={index}>
          {React.createElement(item.component, { navItems, currentNavItem: item }, null)}
        </Route>
      ))}
    </Switch>
  );
};

export default GlobalSettings;
