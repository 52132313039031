import * as React from "react";

function SvgEu(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.001 512.001" {...props}>
      <path
        d="M503.172 423.724H8.828A8.829 8.829 0 010 414.896V97.104a8.829 8.829 0 018.828-8.828h494.345a8.829 8.829 0 018.828 8.828v317.792a8.83 8.83 0 01-8.829 8.828z"
        fill="#ff4b55"
      />
      <path
        d="M512 97.104a8.829 8.829 0 00-8.828-8.828h-31.438L255.999 229.619 40.266 88.276H8.828A8.829 8.829 0 000 97.104v17.554L215.734 256 0 397.343v17.554a8.829 8.829 0 008.828 8.828h31.438L256 282.382l215.734 141.343h31.438a8.829 8.829 0 008.828-8.828v-17.554L296.265 256l215.734-141.343.001-17.553z"
        fill="#73af00"
      />
      <path fill="#f5f5f5" d="M512 233.931H278.068V88.276h-44.137v145.655H0v44.138h233.931v145.655h44.137V278.069H512z" />
    </svg>
  );
}

export default SvgEu;
