import styled from "styled-components";

const QuantityButton = styled.div`
  background-color: ${(p) => p.theme.color.uiBackground03};
  position: absolute;
  cursor: pointer;
  width: ${(p) => p.theme.spacing.m};
  text-align: center;
  transform: translateX(-105%);
  height: 50%;
  svg {
    width: ${(p) => p.theme.spacing.s};
  }
  :hover {
    background-color: ${(p) => p.theme.color.overlayHoverPrimary};
  }
  :focus {
    background-color: ${(p) => p.theme.color.overlayFocusPrimary};
  }
  :active {
    background-color: ${(p) => p.theme.color.overlayPressedPrimary};
  }
`;

export const ArrowUp = styled(QuantityButton)`
  top: 0;
  border-radius: 0 4px 0 0;
`;

export const ArrowDown = styled(QuantityButton)`
  bottom: 0;
  border-radius: 0 0 4px 0;
`;

export const QuantityNav = styled.div<{ error: boolean | undefined; disabled?: boolean }>`
  float: left;
  position: relative;
  height: ${(p) => p.theme.spacing.l};
  border-color: ${(p) => (p.error ? p.theme.color.error : p.theme.color.uiLine)};
  border-style: solid;
  border-width: 1px 0;
  border-radius: ${(p) => p.theme.radii.s};

  ${ArrowDown}, ${ArrowUp} {
    pointer-events: ${(p) => (p.disabled ? "none" : "auto")};
  }
`;

export const Input = styled.input<{ error: boolean | undefined }>`
  flex: 1;
  border: 1px solid;
  border-color: ${(p) => (p.error ? p.theme.color.error : p.theme.color.uiLine)};
  border-radius: ${(p) => p.theme.radii.s};
  background: ${(p) => p.theme.color.uiBackground02};
  padding: ${(p) => `0 0 0 ${p.theme.spacing.s}`};
  height: ${(p) => p.theme.spacing.l};
  width: 100%;
  ${(p) => p.theme.textStyle.fieldContent};
  color: ${(p) => p.theme.color.textHighEmphasis};

  -moz-appearance: textfield;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
   opacity: 0;
  }

  &:active,
  &:focus {
    outline: none;
    border-width: 1px;
    border-color: ${(p) => (p.error ? p.theme.color.error : p.theme.color.interactive01)};
  }

  &::placeholder {
    color: ${(p) => p.theme.color.textLowEmphasis};s
  }

  &:disabled {
    color: ${p => p.theme.color.interactiveDisabled};
    border-color: ${p => p.theme.color.interactiveDisabled};
  }
`;

export const FieldWrapper = styled.div<{ error: boolean | undefined }>`
  display: flex;
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;
