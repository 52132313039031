import styled from "styled-components";

const gutter = "6px";
const imageSizes: Record<string, number> = {
  "S": 2.5,
  "L": 4,
  "P": 1.75
}

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`;

export const GalleryTabs = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Header = styled.div`
  width: 100%;
  border-bottom: 1px solid ${p => p.theme.color.uiLine};
  border-right: 1px solid ${p => p.theme.color.uiLine};
  padding: 0 ${p => p.theme.spacing.m};
  background-color: ${p => p.theme.color.uiBackground02};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Search = styled.div`
  padding: ${p => p.theme.spacing.s} ${p => p.theme.spacing.m};
  border-right: 1px solid ${p => p.theme.color.uiLine};
  background-color: ${p => p.theme.color.uiBackground02};
`

export const Filters = styled.div`
  display: flex;
  height: ${p => p.theme.spacing.l};
  align-items: center;

  & > * {
    &:not(:last-child) {
      margin-right: ${p => p.theme.spacing.l};
    }
  }
`

export const TabsWrapper = styled.div`
  width: ${p => `calc(${p.theme.spacing.xl} * 3)`};
`;

export const GalleryWrapper = styled.div`
  background-color: ${p => p.theme.color.uiBackground01};
  overflow: auto;
  padding-right: ${gutter};
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const IconChecked = styled.div`
  display: none;
  opacity: 0;
  position: absolute;
  top: ${p => p.theme.spacing.xs};
  left: ${p => p.theme.spacing.xs};
  transition: opacity 0.1s;
  svg {
    path {
      fill: ${p => p.theme.color.interactive02};
    }
  }
`;

export const IconUnchecked = styled.div`
  display: none;
  opacity: 0;
  position: absolute;
  top: ${p => p.theme.spacing.xs};
  left: ${p => p.theme.spacing.xs};
  transition: opacity 0.1s;
  svg {
    path {
      fill: ${p => p.theme.color.interactive02};
    }
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  align-items: stretch;
  padding: ${p => p.theme.spacing.m};
  margin: 0 -${gutter};
  margin-top: -${gutter};
  border-right: 1px solid ${p => p.theme.color.uiLine};
  min-height: calc(100% + ${gutter});

  &::after {
    content: "";
    flex: auto;
  }
`;

export const GridItem = styled.div<{ orientation: string }>`
  padding-left: ${gutter};
  padding-top: ${gutter};
  flex: ${p => p.orientation === "P" ? 0 : 1};

  &:last-child {
    flex: 0;
  }
`;

export const ImageWrapper = styled.div<{ selected: boolean, orientation: string }>`
  position: relative;
  min-width: ${p => `calc(${p.theme.spacing.l} * ${imageSizes[p.orientation]})`};
  width: ${p => p.orientation === "P" ? `calc(${p.theme.spacing.l} * ${imageSizes["P"]})` : "auto"};
  height: ${p => `calc(${p.theme.spacing.l} * ${imageSizes["S"]})`};
  border: 1px solid;
  border-color: ${p => (p.selected === true ? p.theme.color.interactive02 : p.theme.color.uiLine)};
  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${p => (p.selected === true ? 1 : 0)};
    transition: opacity 0.1s;
    background-color: ${p => (p.selected === true ? p.theme.color.overlay : `transparent`)};
  }
  :hover {
    cursor: pointer;
    :before {
      background-color: ${p => p.theme.color.overlay};
      opacity: 1;
    }
    ${IconUnchecked} {
      display: block;
      opacity: 1;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  ${IconChecked} {
    display: ${p => (p.selected === true ? `block` : 0)};
    opacity: ${p => (p.selected === true ? 1 : 0)};
  }
`;

export const LoadingWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: ${p => p.theme.spacing.s};
`;

export const EmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  align-content: center;
  padding: ${p => p.theme.spacing.m};
  margin: 0 -${gutter};
  margin-top: -${gutter};
  border-right: 1px solid ${p => p.theme.color.uiLine};
  flex-grow: 1;
`;

export const NotificationWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

export const SearchTags = styled.div`
  padding-left: ${p => p.theme.spacing.m};
  padding-right: ${p => p.theme.spacing.m};
  & > div:nth-child(1){
    margin-top: ${p => p.theme.spacing.m};
  }
  & > div:nth-child(2){
    margin-top: ${p => p.theme.spacing.xs};
  }
`;
