import React from "react";
import { Route, Switch } from "react-router-dom";

import StructuredDataList from "./StructuredDataList";
import Form from "./Form";

const StructuredData = (): JSX.Element => {
  return (
    <Switch>
      <Route exact path="/data/:id" component={StructuredDataList} />
      <Route exact path="/data/pages/editor" />
      <Route exact path="/data/:id/editor" component={Form} />
      <Route exact path="/sites/data/:id/editor" component={Form} />
    </Switch>
  );
};

export default StructuredData;
