import { useState } from "react";
import { IQueryValue } from "@ax/types";

const useFilterQuery = () => {
  const initialQueryValues: Record<string, IQueryValue[]> = {
    translated: [{ value: "all", label: "All" }],
  };

  const [query, setQuery] = useState(initialQueryValues);
  const [currentFilterQuery, setCurrentFilterQuery] = useState("");

  const setFilterQuery = (filterValues: Record<string, IQueryValue[]>) => {
    const { translated } = filterValues;
    let filterQuery = "";

    const currentQuery = (pointer: string, values: IQueryValue[]): string => {
      const stringValues =
        Array.isArray(values) && values.length
          ? values.map((value) => (value.value !== "all" ? value.value : "")).join(",")
          : "";

      return !stringValues.length
        ? filterQuery
        : filterQuery.length
        ? filterQuery.concat(`&${pointer}=${stringValues}`)
        : `&${pointer}=${stringValues}`;
    };

    const isNotInitialValue = (pointer: string) => {
      return filterValues[pointer] && initialQueryValues[pointer] !== filterValues[pointer];
    };

    if (isNotInitialValue("translated")) filterQuery = currentQuery("translated", translated);

    setCurrentFilterQuery(filterQuery);
  };

  const setFiltersSelection = (pointer: string, filter: IQueryValue[]) => {
    const { translated } = query;
    const filterValues = {
      translated: pointer === "translated" ? filter : translated,
    };

    setQuery(filterValues);
    setFilterQuery(filterValues);
  };

  const resetFilterQuery = () => {
    setQuery(initialQueryValues);
    setCurrentFilterQuery("");
  };

  return {
    setFiltersSelection,
    resetFilterQuery,
    filterValues: query,
    filterQuery: currentFilterQuery,
  };
};

export { useFilterQuery };
