import styled from "styled-components";

const Wrapper = styled.div`
  scroll-behavior: smooth;
  height: 100%;
  position: relative;
  .headroom {
    position: relative !important;
  }
  body.preview:not(&) {
    min-width: 0 !important;
  }
`;

export { Wrapper };
