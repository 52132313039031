import React, { useEffect } from "react";
import { connect } from "react-redux";

import { IRootState } from "@ax/types";
import { useIsDirty } from "@ax/hooks";
import { RouteLeavingGuard } from "@ax/guards";
import { ErrorToast, Loading, MainWrapper } from "@ax/components";
import { appActions } from "@ax/containers/App";
import { dataPacksActions } from "@ax/containers/Settings";

import Editor from "./Editor";
import * as S from "./style";

const TemplateEditor = (props: IProps) => {
  const { isLoading, isSaving, editorContent, setHistoryPush, template, updateDataPack, dataPackSelected } = props;

  const { isDirty, resetDirty } = useIsDirty(editorContent);

  const rightButtonProps = {
    label: !isDirty ? "Saved" : isSaving ? "Saving" : "Save",
    disabled: isSaving || !isDirty,
    action: async () => {
      const isSaved = await updateDataPack(dataPackSelected.id);
      if (isSaved) {
        resetDirty(false);
      }
    },
  };

  const leavingGuardText = (
    <>
      Some content <strong>is not saved</strong> on this page.
    </>
  );

  const goBack = (path: string) => setHistoryPush(path);

  return isLoading && !template ? (
    <Loading />
  ) : (
    <>
      <RouteLeavingGuard when={isDirty} action={goBack} text={leavingGuardText} />
      <MainWrapper title={template} backLink={true} rightButton={rightButtonProps} fixedAppBar={true} fullWidth={true}>
        <ErrorToast size="l" />
        <S.Content>
          <Editor />
        </S.Content>
      </MainWrapper>
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  isLoading: state.app.isLoading,
  isSaving: state.app.isSaving,
  editorContent: state.pageEditor.editorContent,
  template: state.pageEditor.template,
  dataPackSelected: state.dataPacks.selected,
});

interface IStateProps {
  isLoading: boolean;
  isSaving: boolean;
  editorContent: any;
  template: string;
  dataPackSelected: any;
}

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
  updateDataPack: dataPacksActions.updateDataPack,
};

interface IDispatchProps {
  setHistoryPush(path: string, isEditor?: boolean): void;
  updateDataPack: (dataPack?: any) => Promise<boolean>;
}

type IProps = IStateProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(TemplateEditor);
