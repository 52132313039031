import React from "react";

const SvgChange = props => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99 19L3 15L6.99 11L6.99 14L14 14L14 16L6.99 16L6.99 19ZM10 8L17.01 8L17.01 5L21 9L17.01 13L17.01 10L10 10L10 8Z"
      fill="#5057FF"
      />
  </svg>
);

export default SvgChange;
