import * as React from "react";

import * as S from "./style";

const Circle = (props: ICircleProps) => {
  const { animation, size = 400 } = props;

  return <S.Circle animation={animation} size={size} />;
};

interface ICircleProps {
  animation: number;
  size?: number;
}

export default Circle;
