import Menus from "./../modules/Navigation/Menus";
import Defaults from "./../modules/Navigation/Defaults";
import DefaultsEditor from "../modules/Navigation/Defaults/DefaultsEditor";
import Editor from "./../modules/PageEditor";
import Content from "./../modules/Content";
import ContentTypes from "./../modules/Settings/ContentTypes";
import Settings from "./../modules/Settings";
import CategoriesList from "./../modules/Categories/CategoriesList";
import StructuredData from "./../modules/StructuredData";
import SeoAnalyticsSettings from "./../modules/Settings/SeoAnalyticsSettings";
import TemplateEditor from "../modules/Settings/ContentTypes/DataPacks/Config/Form/TemplateConfig/TemplateEditor";
import Users from "./../modules/Users";
import UserCreate from "./../modules/Users/UserCreate";
import UserEdit from "./../modules/Users/UserEdit";
import Profile from "./../modules/Users/Profile";
import Integrations from "./../modules/Settings/Integrations";
import IntegrationForm from "./../modules/Settings/Integrations/IntegrationForm";
import FileDrive from "./../modules/FileDrive";

const BASE_PATH = "/sites";

export default [
  {
    path: `${BASE_PATH}/pages`,
    component: Content,
    name: "Site Content",
    showInNav: true,
    icon: "page",
    permission: "content.accessToPages",
  },
  {
    path: `${BASE_PATH}/pages/editor`,
    component: Editor,
    name: "Page Editor",
    showInNav: false,
    hideNav: true,
    permission: "content.accessToPages",
  },
  {
    path: `${BASE_PATH}/pages/editor/new`,
    component: Editor,
    name: "New Page Editor",
    showInNav: false,
    hideNav: true,
    permission: "content.createPages",
  },
  {
    path: `${BASE_PATH}/categories`,
    component: CategoriesList,
    name: "Categories",
    showInNav: true,
    icon: "Category",
    permission: "categories.accessToSiteCategories",
  },
  {
    path: `${BASE_PATH}/data/:id/editor`,
    component: StructuredData,
    showInNav: false,
    icon: "",
    name: "New StructuredData",
    permission: "content.accessToPages",
  },
  { path: `${BASE_PATH}/profile`, component: Profile, name: "Profile", showInNav: false, icon: "User" },
  {
    path: `${BASE_PATH}/navigations`,
    component: null,
    name: "Navigation",
    showInNav: true,
    icon: "Menu",
    permission: "navigation.accessToNavigationSettings",
    routesGroups: [
      {
        name: "Navigation",
        routes: [
          {
            path: `${BASE_PATH}/navigations/menus`,
            component: Menus,
            name: "Menus",
            permission: "navigation.accessToNavigationSettings",
          },
          {
            path: `${BASE_PATH}/navigations/modules`,
            component: Defaults,
            name: "Navigation modules",
            permission: "navigation.accessToNavigationSettings",
          },
        ],
      },
    ],
  },
  {
    path: `${BASE_PATH}/files`,
    component: FileDrive,
    name: "File Drive Manager",
    showInNav: true,
    icon: "Photo-library",
    permission: "mediaGallery.accessToSiteFileDrive",
  },
  {
    path: `${BASE_PATH}/users`,
    component: Users,
    name: "Users",
    showInNav: true,
    icon: "Users",
    permission: "usersRoles.accessToUsersRoles",
  },
  {
    path: `${BASE_PATH}/roles`,
    component: Users,
    name: "Roles",
    showInNav: false,
    permission: "usersRoles.accessToUsersRoles",
  },
  {
    path: `${BASE_PATH}/users/new`,
    component: UserCreate,
    name: "New User",
    showInNav: false,
    permission: "usersRoles.createUsers",
  },
  {
    path: `${BASE_PATH}/users/edit`,
    component: UserEdit,
    name: "New User",
    showInNav: false,
    permission: "usersRoles.editUsers",
  },
  {
    path: `${BASE_PATH}/navigations/editor`,
    component: DefaultsEditor,
    name: "Defaults Editor",
    showInNav: false,
    hideNav: true,
    isEditor: true,
    permission: "",
  },
  {
    path: `${BASE_PATH}/settings/content-types/editor`,
    component: TemplateEditor,
    name: "Template Editor",
    showInNav: false,
    hideNav: true,
    isEditor: true,
    permission: "general.manageContentTypes",
  },
  {
    path: `${BASE_PATH}/settings`,
    component: null,
    name: "Settings",
    showInNav: true,
    icon: "Settings",
    permission: [
      "general.manageSiteSettings",
      "general.manageContentTypes",
      "seoAnalytics.manageAnalyticsSiteSettings",
      "general.manageSiteThirdPartyIntegrations",
    ],
    routesGroups: [
      {
        name: "Settings",
        routes: [
          {
            path: `${BASE_PATH}/settings/globals`,
            component: Settings,
            name: "General",
            permission: "general.manageSiteSettings",
          },
          {
            path: `${BASE_PATH}/settings/content-types`,
            component: ContentTypes,
            name: "Content types",
            permission: "general.manageContentTypes",
          },
          {
            path: `${BASE_PATH}/settings/seo`,
            component: SeoAnalyticsSettings,
            name: "SEO & Analytics",
            permission: "seoAnalytics.manageAnalyticsSiteSettings",
          },
          {
            path: `${BASE_PATH}/settings/addons`,
            component: Integrations,
            name: "Add-ons",
            permission: "general.manageSiteThirdPartyIntegrations",
          },
        ],
      },
    ],
  },
  {
    path: `${BASE_PATH}/settings/languages`,
    component: Settings,
    name: "Languages",
    showInNav: false,
    permission: "general.accessToLanguages",
  },
  {
    path: `${BASE_PATH}/settings/social`,
    component: Settings,
    name: "Social",
    showInNav: false,
    permission: "general.manageSocialMedia",
  },
  {
    path: `${BASE_PATH}/settings/analytics`,
    component: SeoAnalyticsSettings,
    name: "Analytics",
    showInNav: false,
    permission: "seoAnalytics.manageAnalyticsSiteSettings",
  },
  {
    path: `${BASE_PATH}/settings/addons/new`,
    component: IntegrationForm,
    name: "New Add-on",
    showInNav: false,
    permission: "general.manageSiteThirdPartyIntegrations",
  },
  {
    path: `${BASE_PATH}/settings/addons/edit`,
    component: IntegrationForm,
    name: "Edit Add-on",
    showInNav: false,
    permission: "general.manageSiteThirdPartyIntegrations",
  },
];
