import React from "react";

const SvgCalendar = props => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.01 2.9 0.01 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM4 9V11H6V9H4ZM10 9H8V11H10V9ZM14 9V11H12V9H14ZM2 18H16V7H2V18Z"
      fill="#5057FF"
    />
  </svg>
);

export default SvgCalendar;
