import styled from "styled-components";

const Label = styled.div`
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  padding-bottom: ${(p) => p.theme.spacing.xs};
  margin-bottom: ${(p) => p.theme.spacing.s};
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
`;

export { Label };
