import React from "react";

import * as S from "./style";

const FieldsDivider = (props: IProps): JSX.Element => {
  const { data } = props;
  const { title, text } = data;

  return (
    <S.DividerWrapper>
      <S.DividerTitle>{title}</S.DividerTitle>
      <S.DividerText>{text}</S.DividerText>
    </S.DividerWrapper>
  );
};

interface IProps {
  data: { title: string; text: string };
}

export default FieldsDivider;
