import React, { useState } from "react";
import { connect } from "react-redux";
import { IRootState } from "@ax/types";
import { pageEditorActions } from "@ax/containers/PageEditor";

import * as S from "./style";

const SummaryButton = (props: ISummaryButtonProps): JSX.Element => {
  const { autoSummary, getPageSummary } = props;

  const initialState = {
    isLoading: false,
    isClicked: false,
    error: false,
  };

  const [state, setState] = useState(initialState);

  const handleClick = async () => {
    setState((state) => ({ ...state, isLoading: true, error: false }));
    const generated = await getPageSummary();
    setState((state) => ({ ...state, isLoading: false }));
    if (generated) {
      setState((state) => ({ ...state, isClicked: true }));
    } else {
      setState((state) => ({ ...state, error: true }));
    }
  };

  const buttonText = state.isLoading
    ? "Processing..."
    : state.isClicked
    ? "Regenerate SEO data with AI"
    : "Generate SEO data with AI";

  return (
    <>
      {autoSummary ? (
        <S.Wrapper data-testid="summary-button-wrapper">
          <S.Text>
            You can automatically generate <strong>SEO meta descriptions</strong> and <strong>keywords</strong> quickly
            with <strong>AI</strong>.
          </S.Text>
          <S.ButtonWrapper>
            <S.StyledButton
              type="button"
              onClick={handleClick}
              icon={!state.isLoading ? "Ia" : undefined}
              loader={state.isLoading ? "circle" : undefined}
            >
              {buttonText}
            </S.StyledButton>
          </S.ButtonWrapper>
          {state.error && <S.ErrorText>We're having problems. Please try again in a few minutes.</S.ErrorText>}
        </S.Wrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export interface ISummaryButtonProps {
  autoSummary: boolean;
  getPageSummary: () => Promise<boolean>;
}

const mapDispatchToProps = {
  getPageSummary: pageEditorActions.getPageSummary,
};

const mapStateToProps = (state: IRootState) => ({
  autoSummary: state.app.globalSettings.autoSummary,
});

export default connect(mapStateToProps, mapDispatchToProps)(SummaryButton);
