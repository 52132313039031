import React, { useState } from "react";
import { CheckGroup, Button, FloatingMenu } from "@ax/components";

import * as S from "./style";

const ExportButton = (props: IExportButtonProps): JSX.Element => {
  const { onClick } = props;

  const [state, setState] = useState<(string | number)[]>([]);

  const handleChange = (value: string | (string | number)[]) => Array.isArray(value) && setState(value);

  const handleClick = () => onClick(state);

  const options = [
    { name: "csv", value: "csv", title: ".csv" },
    { name: "xml", value: "xml", title: ".xml" },
    { name: "json", value: "json", title: ".json" },
  ];

  const LinkButton = () => (
    <S.ButtonWrapper>
      <Button className="button" type="button" buttonStyle="text" icon="download">
        Export Data
      </Button>
    </S.ButtonWrapper>
  );

  return (
    <S.Wrapper>
      <FloatingMenu Button={LinkButton} position="left" closeOnSelect={false} offset={-25}>
        <S.MenuWrapper>
          <S.HelpText>Select the format(s) in which you want to export.</S.HelpText>
          <S.ChecksWrapper>
            <CheckGroup options={options} value={state} onChange={handleChange} multipleSelection={true} />
          </S.ChecksWrapper>
          <S.ExportWrapper>
            <Button className="button" type="button" buttonStyle="line" onClick={handleClick}>
              Export Data
            </Button>
          </S.ExportWrapper>
        </S.MenuWrapper>
      </FloatingMenu>
    </S.Wrapper>
  );
};

export interface IExportButtonProps {
  onClick: (formats: (string | number)[]) => void;
}

export default ExportButton;
