import React, { useState } from "react";

import { FieldsBehavior, Modal } from "@ax/components";

import * as S from "./style";

const CopyModal = (props: ICopyModalProps): JSX.Element => {
  const { action, isOpen, hide } = props;

  const [site, setSite] = useState();

  const mainCopyModalAction = {
    title: "Copy add-on",
    onClick: () => site && action(site),
    disabled: !site,
  };

  const secondaryCopyModalAction = { title: "Cancel", onClick: hide };

  return (
    <Modal
      isOpen={isOpen}
      hide={hide}
      title="Copy add-on in another site"
      secondaryAction={secondaryCopyModalAction}
      mainAction={mainCopyModalAction}
      size="S"
      overflow="visible"
    >
      <S.ModalContent>
        <S.Title>Select a site to copy this add-on.</S.Title>
        <FieldsBehavior name="language" fieldType="AsyncSelect" value={site} entity="sites" onChange={setSite} />
      </S.ModalContent>
    </Modal>
  );
};

interface ICopyModalProps {
  action: (site: number) => void;
  isOpen: boolean;
  hide: () => void;
}

export default CopyModal;
