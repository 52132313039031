import styled from "styled-components";
import { ActionMenu } from "@ax/components";

const Wrapper = styled.div`
  position: relative;
  background-color: ${(p) => p.theme.color.uiBackground02};
  border: ${(p) => `1px solid ${p.theme.color.uiLine}`};
  border-radius: ${p => p.theme.radii.s};
  display: flex;
  align-items: center;
  min-width: 220px;
  max-width: 280px;
  height: 64px;
  cursor: pointer;
  &:hover {
    background-color: ${(p) => p.theme.color.overlayHoverPrimary};
  }
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-left: ${(p) => p.theme.spacing.m};
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => p.theme.colors.textHighEmphasis};
  margin-left: ${(p) => p.theme.spacing.xs};
`;

const StyledActionMenu = styled(ActionMenu)`
  width: 32px;
  display: flex;
  margin-left: auto;
  margin-right: ${(p) => p.theme.spacing.xs};
`;

export { Wrapper, IconWrapper, Title, StyledActionMenu };
