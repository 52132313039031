import { hasProps } from "@ax/helpers";

const defaultOptions = (vusOptions: Record<string, unknown>[]) => {
  return !vusOptions.find((entry) => entry.theme);
};

const getOptions = (theme: string, vusOptions: Record<string, unknown>[]) => {
  const themes = vusOptions.filter((entry) => entry.theme).map((entry) => entry.theme);
  const themeExists = themes.includes(theme);
  const defaultsOptions = vusOptions.filter((entry) => hasProps(entry, ["img", "value"]));

  // 1 - Si options solo tiene las defaults options (legacy) devuelve las defaults.
  // 2 - Si options no tiene el theme, devuelve las defaults.
  if (defaultOptions(vusOptions) || !themeExists) return defaultsOptions;

  // Si options tiene el theme, devuelve las options de ese theme.
  if (themeExists) {
    const optionsObj = vusOptions.find((entry) => entry.theme === theme);
    return optionsObj && optionsObj.options;
  }

  return [];
};

export { getOptions };
