import styled from "styled-components";

const List = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 290px);
  grid-gap: ${(p) => p.theme.spacing.m};
  padding: ${(p) => p.theme.spacing.m} 0 105px ${(p) => p.theme.spacing.m};
  height: fit-content;
  overflow: auto;
`;

export {
  List
}