import { Button } from "@ax/components";
import styled from "styled-components";

const Wrapper = styled.section`
  width: 720px;
  margin: ${(p) => `${p.theme.spacing.m} 0 0 ${p.theme.spacing.m}`};
  padding-bottom: calc(${(p) => p.theme.spacing.xl} * 2);
`;

const Heading = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  color: ${(p) => p.theme.color.textHighEmphasis};
  padding-bottom: ${(p) => p.theme.spacing.xs};
`;

const SettingsWrapper = styled.div`
  position: relative;
`;

const SettingContent = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const SettingText = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  width: calc(${(p) => p.theme.spacing.l} * 12);
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const StyledButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${(p) => p.theme.spacing.m};
`;

const PagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

const ButtonWrapper = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const PageElement = styled.div`
  position: relative;
  ${(p) => p.theme.textStyle.uiXS};
  ${(p) => p.theme.colors.textMediumEmphasis};
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xs};
  margin: calc(${(p) => p.theme.spacing.xxs} / 2);
  background-color: ${(p) => p.theme.colors.uiBackground03};
  border-radius: ${(p) => p.theme.radii.xs};
  white-space: nowrap;
`;

const ContentWrapper = styled.div`
  padding: ${(p) => p.theme.spacing.m};
  height: 100%;
`;

export {
  Wrapper,
  Heading,
  SettingsWrapper,
  SettingContent,
  SettingText,
  StyledButton,
  Table,
  PagesWrapper,
  ButtonWrapper,
  PageElement,
  ContentWrapper,
};
