const NAME = "dataPacks";

const SET_ACTIVATED = `${NAME}/SET_ACTIVATED`;
const SET_SELECTED = `${NAME}/SET_SELECTED`;
const SET_CONFIG_FORM_DATA = `${NAME}/SET_CONFIG_FORM_DATA`;
const SET_AVAILABLE = `${NAME}/SET_AVAILABLE`;
const SET_CATEGORIES = `${NAME}/SET_CATEGORIES`;
const SET_MODULES = `${NAME}/SET_MODULES`;
const SET_TEMPLATES = `${NAME}/SET_TEMPLATES`;

// TODO ERROR DICTIONARY
const ERROR_CODE = {
  isBeingUsed: 409,
};

export {
  SET_ACTIVATED,
  SET_SELECTED,
  SET_CONFIG_FORM_DATA,
  SET_AVAILABLE,
  SET_CATEGORIES,
  SET_MODULES,
  SET_TEMPLATES,
  ERROR_CODE,
};
