import * as React from "react";

function SvgPt(props) {
  return (
    <svg viewBox="0 0 512.001 512.001" {...props}>
      <path
        d="M503.172 423.725H8.828A8.829 8.829 0 010 414.897V97.104a8.829 8.829 0 018.828-8.828h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
        fill="#ff4b55"
      />
      <path d="M185.379 88.277H8.828A8.828 8.828 0 000 97.104v317.793a8.829 8.829 0 008.828 8.828H185.38V88.277h-.001z" fill="#73af00" />
      <circle cx={185.45} cy={256.001} r={79.38} fill="#ffe15a" />
      <path
        d="M211.932 229.518v35.31c0 14.603-11.88 26.483-26.483 26.483s-26.483-11.88-26.483-26.483v-35.31h52.966m8.827-17.655h-70.621a8.829 8.829 0 00-8.828 8.828v44.138c0 24.376 19.762 44.138 44.138 44.138s44.138-19.762 44.138-44.138v-44.138a8.827 8.827 0 00-8.827-8.828z"
        fill="#ff4b55"
      />
      <path d="M211.932 229.518v35.31c0 14.603-11.88 26.483-26.483 26.483s-26.483-11.88-26.483-26.483v-35.31h52.966" fill="#f5f5f5" />
      <g fill="#ffe15a">
        <circle cx={150.07} cy={220.691} r={4.414} />
        <circle cx={220.69} cy={220.691} r={4.414} />
        <circle cx={150.07} cy={256.001} r={4.414} />
        <circle cx={220.69} cy={256.001} r={4.414} />
        <circle cx={185.38} cy={220.691} r={4.414} />
        <circle cx={211.88} cy={288.551} r={4.414} />
        <circle cx={159.4} cy={288.551} r={4.414} />
      </g>
      <g fill="#41479b">
        <path d="M191.149 253.763v7.602c0 3.144-2.558 5.702-5.702 5.702s-5.702-2.558-5.702-5.702v-7.602h11.404M191.149 235.741v7.602c0 3.144-2.558 5.702-5.702 5.702s-5.702-2.558-5.702-5.702v-7.602h11.404M191.149 271.97v7.602c0 3.144-2.558 5.702-5.702 5.702s-5.702-2.558-5.702-5.702v-7.602h11.404M206.506 253.763v7.602c0 3.144-2.558 5.702-5.702 5.702s-5.702-2.558-5.702-5.702v-7.602h11.404M175.794 253.763v7.602c0 3.144-2.558 5.702-5.702 5.702s-5.702-2.558-5.702-5.702v-7.602h11.404" />
      </g>
    </svg>
  );
}

export default SvgPt;
