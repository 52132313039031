import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { formatBytes, getFormattedDateWithTimezone } from "@ax/helpers";
import { Button, FieldsBehavior, IconAction, Tabs } from "@ax/components";
import { IFile, IRootState } from "@ax/types";
import { fileDriveActions } from "@ax/containers/FileDrive";
import { useModal } from "@ax/hooks";
import { DeleteFileModal } from "../../atoms";
import UsageContent from "./UsageContent";

import * as S from "./style";

const DetailPanel = (props: IProps) => {
  const {
    file,
    isSaving,
    activeDelete,
    isAllowedToEdit,
    toggleModal,
    onDelete,
    updateFile,
    form,
    setForm,
    isDirty,
    resetDirty,
  } = props;
  const { title, alt, tags, site } = file;

  const [selectedTab, setSelectedTab] = useState("details");
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();

  useEffect(() => {
    const initState: IFormState = { title: title || "", alt: alt || "", tags: tags || [] };
    setForm(initState);
  }, [file]);

  const handleTitle = (value: string) => setForm({ ...form, title: value });
  const handleAlt = (value: string) => setForm({ ...form, alt: value });
  const handleTags = (value: string[]) => setForm({ ...form, tags: value });

  const handleSave = async () => {
    const updated = await updateFile(file.id, form, site || "global");
    if (updated) {
      resetDirty();
    }
  };

  const handleOpenUrl = () => {
    const win = window.open(file.url, "_blank");
    if (win) {
      win.focus();
    }
  };

  const handleCopyUrl = () => navigator.clipboard.writeText(file.url);

  const handleDeleteFile = () => {
    onDelete(file.id);
    isDeleteOpen && toggleDeleteModal();
    toggleModal();
  };

  const handleSelectedTab = (tab: "details" | "in use") => {
    if (tab !== selectedTab) {
      setSelectedTab(tab);
    }
  };

  const mainNewModalAction = {
    title: "Delete document",
    onClick: () => handleDeleteFile(),
  };

  const secondaryNewModalAction = { title: "Cancel", onClick: toggleDeleteModal };

  const tabs = ["details", "in use"];

  return (
    <>
      <S.Wrapper>
        <S.Header>
          DETAILS
          {/*<Tabs tabs={tabs} active={selectedTab} setSelectedTab={handleSelectedTab} noMargins />*/}
        </S.Header>
        {selectedTab === "in use" ? (
          <UsageContent file={file} />
        ) : (
          <>
            <S.Content>
              <S.FileInfo>
                <S.FileName>{file.fileName}</S.FileName>
                <S.InfoLine>
                  <strong>Uploaded:</strong> {getFormattedDateWithTimezone(file.uploadDate, "d MMM Y")}
                </S.InfoLine>
                <S.InfoLine>
                  <strong>Type:</strong> {file.fileType}
                </S.InfoLine>
                <S.InfoLine>
                  <strong>Size:</strong> {formatBytes(file.sizeBytes)}
                </S.InfoLine>
                <S.FileActions>
                  <Button type="button" onClick={handleCopyUrl} buttonStyle="line">
                    Copy URL
                  </Button>
                  <IconAction icon="OpenOutside" size="m" onClick={handleOpenUrl} />
                </S.FileActions>
              </S.FileInfo>
              <S.FileForm>
                {isAllowedToEdit ? (
                  <>
                    <FieldsBehavior
                      title="Title"
                      name="title"
                      value={form.title}
                      fieldType="TextField"
                      onChange={handleTitle}
                    />
                    <FieldsBehavior
                      title="Alternative text"
                      name="alt"
                      value={form.alt}
                      fieldType="TextField"
                      onChange={handleAlt}
                    />
                  </>
                ) : (
                  <>
                    <S.Label>Title</S.Label>
                    <S.FieldText>{form.title}</S.FieldText>
                    <S.Label>Alternative text</S.Label>
                    <S.FieldText>{form.alt}</S.FieldText>
                  </>
                )}
                <FieldsBehavior
                  title="Tags"
                  value={form.tags}
                  fieldType="TagsField"
                  onChange={handleTags}
                  disabled={!isAllowedToEdit}
                  helptext="Type a tag and press enter to create it"
                />
              </S.FileForm>
            </S.Content>
            {(isAllowedToEdit || activeDelete) && (
              <S.Footer>
                {activeDelete && (
                  <Button type="button" buttonStyle="text" onClick={toggleDeleteModal}>
                    Delete
                  </Button>
                )}
                {isAllowedToEdit && (
                  <Button type="button" onClick={handleSave} disabled={isSaving || !isDirty}>
                    {isSaving ? "Saving" : "Save"}
                  </Button>
                )}
              </S.Footer>
            )}
          </>
        )}
      </S.Wrapper>
      {isDeleteOpen && (
        <DeleteFileModal
          isOpen={isDeleteOpen}
          toggleModal={toggleDeleteModal}
          mainModalAction={mainNewModalAction}
          secondaryModalAction={secondaryNewModalAction}
          title={file.fileName}
          isChild={true}
        />
      )}
    </>
  );
};

interface IProps {
  file: IFile;
  isSaving: boolean;
  activeDelete: boolean;
  isAllowedToEdit: boolean;
  form: IFormState;
  setForm(form: IFormState): void;
  isDirty: boolean;
  resetDirty(): void;
  toggleModal(): void;
  onDelete(fileID: number): void;
  updateFile(
    fileID: number,
    data: { title: string; alt: string; tags: string[] },
    siteID: number | "global"
  ): Promise<boolean>;
}

interface IFormState {
  title: string;
  alt: string;
  tags: string[];
}

const mapStateToProps = (state: IRootState) => ({
  isSaving: state.app.isSaving,
});

const mapDispatchToProps = {
  updateFile: fileDriveActions.updateFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailPanel);
