import React from "react";
import { CheckField, Icon, ProgressBar } from "@ax/components";

import * as S from "./style";

const UploadItem = (props: IProps) => {
  const { uploadPercentage, grid } = props;

  return (
    <S.Wrapper grid={grid}>
      {!grid && (
        <S.CheckWrapper role="cell">
          <CheckField name="check" value={0} checked={false} />
        </S.CheckWrapper>
      )}
      <S.IconWrapper>
        <Icon name="page" size="32" />
      </S.IconWrapper>
      <S.BarWrapper>
        <ProgressBar percentage={uploadPercentage} />
      </S.BarWrapper>
      <S.Text>Uploading...</S.Text>
    </S.Wrapper>
  );
};

interface IProps {
  uploadPercentage: number;
  grid: boolean;
}

export default UploadItem;
