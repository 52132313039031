import styled from "styled-components";

import { Cell, Row } from "@ax/components/TableList/TableItem/style";
import { ActionMenu } from "@ax/components";

const CheckCell = styled(Cell)`
  padding-left: 0;
  padding-right: 0;
  width: 32px;
  label {
    margin-bottom: ${(p) => p.theme.spacing.s};
  }
`;

const NameCell = styled(Cell)<{ clickable: boolean; isGroup: boolean }>`
  ${(p) => p.theme.textStyle.uiL};
  padding-left: ${(p) => p.theme.spacing.xs};
  pointer-events: ${(p) => (p.clickable ? "auto" : "none")};
  color: ${(p) => (p.isGroup ? p.theme.color.interactive01 : p.theme.color.textHighEmphasis)};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
`;

const ActionsCell = styled(Cell)`
  flex: 0 0 80px;
`;

const TransCell = styled(Cell)`
  ${(p) => p.theme.textStyle.uiXS};
  flex: 0 0 115px;
  align-items: center;
`;

const SelectableCell = styled(Cell)`
  ${(p) => p.theme.textStyle.uiXS};
  flex: 0 0 115px;
  align-items: center;
`;

const CodeCell = styled(Cell)<{ clickable: boolean }>`
  ${(p) => p.theme.textStyle.uiXS};
  pointer-events: ${(p) => (p.clickable ? "auto" : "none")};
  flex: 0 0 250px;
`;

const StyledActionMenu = styled(ActionMenu)`
  opacity: 0;
  width: 32px;
  display: flex;
  margin-left: auto;
`;

const CategoryRow = styled(Row)`
  &:hover {
    ${StyledActionMenu} {
      opacity: 1;
    }
  }
`;

const FlagsWrapper = styled.div`
  display: flex;
  svg {
    margin-right: 2px;
    margin-bottom: -3px; // TODO
    display: inline;
    vertical-align: baseline;
  }
`;

const HandleCell = styled(Cell)`
  flex-flow: row nowrap;
  padding: ${(p) => `0 ${p.theme.spacing.xs} 0 0`};
  align-items: center;
`;

const IconWrapper = styled.div`
  div {
    padding: ${(p) => `${p.theme.spacing.s} 0 ${p.theme.spacing.s} ${p.theme.spacing.xxs}`};
  }
`;

const IconWrapperDrag = styled.div`
  display: flex;
  align-items: center;
  padding: ${(p) => `${p.theme.spacing.s} 0 ${p.theme.spacing.s} ${p.theme.spacing.xs}`};
  height: 100%;
  opacity: 1;
  svg {
    path {
      fill: ${(p) => p.theme.color.textLowEmphasis};
    }
  }
`;

const FolderWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-right: ${(p) => p.theme.spacing.xs};
`;

export {
  CheckCell,
  NameCell,
  ActionsCell,
  TransCell,
  CodeCell,
  SelectableCell,
  StyledActionMenu,
  CategoryRow,
  FlagsWrapper,
  IconWrapper,
  IconWrapperDrag,
  HandleCell,
  FolderWrapper,
};
