import * as React from "react";

import { ThemeProvider, createGlobalStyle } from "styled-components";
import { parseTheme } from "@ax/helpers";
import reset from "styled-reset";
import { normalize } from "polished";
import fonts from "./fonts";
import globalTheme from "../themes/theme.json";

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${normalize()}
  ${fonts}

  html, body {
    ${(p: any) => p.theme.textStyle.uiM};
    font-family: ${(p: any) => p.theme.fontFamily.primary} !important;
    color: ${(p: any) => p.theme.color.textHighEmphasis};
    background-color: ${(p: any) => p.theme.color.uiBackground01};
    min-height: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    overscroll-behavior: none;
  }
  body {
    min-width: 1280px;
  }
  * { box-sizing: border-box; }
  a { color: inherit; text-decoration: none; }
  img { display: block; width: 100%; max-width: 100%; }
  em { font-style: italic; }
  h1 { margin: 0 }

  #root {
    height: 100vh;
    display: flex;
  }

  div.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img {
    display: none !important;
  }

  div.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img {
    -webkit-transform: translateY(-50%) translateX(-50%) !important;
    -moz-transform: translateY(-50%) translateX(-50%) !important;
    -ms-transform: translateY(-50%) translateX(-50%) !important;
    -o-transform: translateY(-50%) translateX(-50%) !important;
  }
`;

const Style = (props: any) => {
  return (
    <ThemeProvider theme={parseTheme(globalTheme)}>
      <>
        <GlobalStyle />
        {props.children}
      </>
    </ThemeProvider>
  );
};

export default Style;
