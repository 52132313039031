import styled from "styled-components";

const Content = styled.section`
  display: flex;
  min-height: 100%;
`;

const NotificationWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: ${(p) => `calc(${p.theme.spacing.s} * 4)`};
  left: 0;
  right: 0;
  z-index: 4;
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};

  p {
    margin-bottom: ${(p) => p.theme.spacing.m};
  }
`;

const ModalFields = styled.div`
  display: flex;
  width: 100%;
  & > div:nth-child(odd) {
    margin-right: ${(p) => p.theme.spacing.m};
  }
`;

export { Content, NotificationWrapper, ModalContent, ModalFields };
