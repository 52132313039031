import React from "react";

import { IModal } from "@ax/types";
import { Modal, FieldsBehavior } from "@ax/components";
import FolderTree from "./FolderTree";
import { IFolderFormState } from ".";

import * as S from "./style";

const NewFolderModal = (props: INewModalProps): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction, form, setForm } = props;

  const setNameValue = (name: string) => setForm({ ...form, name });
  const setFolder = (folderID: number | null) => setForm({ ...form, folderID });

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      mainModalAction?.onClick();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Create New Folder"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
      height={640}
    >
      <S.ModalContent>
        <FieldsBehavior
          title="Folder name"
          name="name"
          fieldType="TextField"
          value={form.name}
          placeholder="Type a name of the new folder"
          onChange={setNameValue}
          onKeyDown={handleKeyDown}
          autoComplete="nodelay"
          autoFocus
        />
        <FolderTree folderID={form.folderID || 0} onClick={setFolder} />
      </S.ModalContent>
    </Modal>
  );
};

const DeleteFolderModal = (props: IDeleteModalProps): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction, title } = props;

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Delete Folder?"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
      height={240}
    >
      <S.ModalContent>
        Are you sure you want to delete <strong>{title} folder</strong>? All documents inside the folder will also be
        deleted. This action <strong>cannot be undone</strong>.
      </S.ModalContent>
    </Modal>
  );
};

const RenameFolderModal = (props: IRenameModalProps): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction, value, setValue } = props;

  const setNameValue = (name: string) => setValue(name);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      mainModalAction?.onClick();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Rename Folder"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
      height={244}
    >
      <S.ModalContent>
        <S.NoMarginFieldsBehavior
          title="Folder name"
          name="name"
          fieldType="TextField"
          value={value}
          onChange={setNameValue}
          onKeyDown={handleKeyDown}
          autoComplete="nodelay"
          autoFocus
        />
      </S.ModalContent>
    </Modal>
  );
};

const DeleteFileModal = (props: IDeleteModalProps): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction, title, isChild } = props;

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Delete document?"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
      height={240}
      isChild={isChild}
    >
      <S.ModalContent>
        Are you sure you want to delete <strong>{title}</strong>?<br />
        This action <strong>cannot be undone</strong>.
      </S.ModalContent>
    </Modal>
  );
};

const MoveItemModal = (props: IMoveModalProps): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction, folder, setFolder, hidden, hideRoot } = props;

  const handleSetFolder = (folderID: number) => setFolder(folderID);

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Move to"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
      height={528}
    >
      <S.ModalContent>
        <FolderTree folderID={folder || 0} onClick={handleSetFolder} hidden={hidden} hideRoot={hideRoot} />
      </S.ModalContent>
    </Modal>
  );
};

const NotSavedModal = (props: IModal): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction } = props;

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      size="S"
      title="Unsaved changes"
      mainAction={mainModalAction}
      secondaryAction={secondaryModalAction}
      isChild={true}
    >
      {
        <S.ModalContent>
          Some content is not saved on this file. If you exit without saving it, it will be lost. Do you want to discard
          your changes?
        </S.ModalContent>
      }
    </Modal>
  );
};

interface INewModalProps extends IModal {
  form: IFolderFormState;
  setForm(form: IFolderFormState): void;
}

interface IRenameModalProps extends IModal {
  value: string;
  setValue(value: string): void;
}

interface IDeleteModalProps extends IModal {
  title: string;
}

interface IMoveModalProps extends IModal {
  folder: number;
  setFolder(folderID: number): void;
  hidden?: number[];
  hideRoot?: boolean;
}

export { NewFolderModal, DeleteFolderModal, DeleteFileModal, MoveItemModal, RenameFolderModal, NotSavedModal };
