import React from "react";
import { IContentFilter, IDynamicFilter, IStructuredData } from "@ax/types";

const getDynamicFilters = (values: IStructuredData[]): IDynamicFilter[] =>
  values &&
  values.map((value) => ({
    label: value.title,
    value: value.id,
    fromPage: value.fromPage,
    editable: value.editable !== undefined ? value.editable : false,
    firstTemplate: null,
    isPrivate: value.private !== undefined ? value.private : false,
  }));

const getFilters = (dynamicValues: IStructuredData[]): IContentFilter[] => {
  const filters: IContentFilter[] = [{ label: "All Content", value: "all-pages" }];

  const pageTypes = dynamicValues.filter((value) => value.fromPage);
  const simpleTypes = dynamicValues.filter((value) => !value.fromPage);

  if (pageTypes.length) {
    filters.push({
      label: "Page Content Types",
      value: "pages",
      items: getDynamicFilters(pageTypes),
      description: (
        <>
          Content shown in a page. Data is filled
          <br />
          on a page and has a URL.
        </>
      ),
    });
  }

  if (simpleTypes.length) {
    filters.push({
      label: "Simple Content Types",
      value: "simple",
      items: getDynamicFilters(simpleTypes),
      description: (
        <>
          Pageless content. Data is entered on a<br />
          form and consumed in lists and
          <br />
          distributors.
        </>
      ),
    });
  }

  return filters;
};

export { getDynamicFilters, getFilters };
