import styled from "styled-components";
import { ActionMenu } from "@ax/components";

const StyledActionMenu = styled(ActionMenu)`
  opacity: 0;
  width: 32px;
  display: flex;
  margin-left: auto;
`;

const Component = styled.div`
  ${(p) => p.theme.textStyle.fieldLabel};
  color: ${(p) => p.theme.color.textHighEmphasis};
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: ${(p) => p.theme.spacing.l};
  background: ${(p) => p.theme.color.uiBackground02};
  border: 1px solid transparent;
  margin-bottom: ${(p) => p.theme.spacing.xs};
  padding: 0 ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.radii.s};
  box-shadow: ${(p) => p.theme.shadow.shadowS};
  text-align: left;
  cursor: pointer;

  &:hover {
    background: ${(p) => p.theme.color.overlayHoverPrimary};
  }

  &:hover ${StyledActionMenu} {
    opacity: 1;
  }
`;

const Name = styled.div`
  ${(p) => p.theme.textStyle.fieldLabel};
  color: ${(p) => p.theme.color.textHighEmphasis};
  width: 40%;
`

const Values = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
`

const Button = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  display: flex;
  color: ${(p) => p.theme.color.interactive01};
  margin-left: auto;
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};
`;

export { Component, Button, Name, Values, StyledActionMenu, ModalContent };
