import styled from "styled-components";

const Wrapper = styled.div`
  padding: ${(p) => p.theme.spacing.m};
  height: 100%;
`;

const ContentWrapper = styled.div`
  overflow-y: scroll;
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
  position: absolute;
  left: 0;
  display: flex;
  width: 100%;
  height: calc(100% - ${(p) => p.theme.spacing.s} * 10);
  padding: 0 ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  flex-direction: column;
`;

const ActionWrapper = styled.div`
  position: absolute;
  bottom: ${(p) => p.theme.spacing.m};
  right: ${(p) => p.theme.spacing.m};
  display: flex;
  justify-content: flex-end;
  padding-top: ${(p) => p.theme.spacing.s};
`;

const NoteWrapper = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

export { Wrapper, ContentWrapper, ActionWrapper, NoteWrapper };
