import styled from "styled-components";

const Card = styled.div`
  padding: 0 ${(p) => p.theme.spacing.s};
  background-color: ${(p) => p.theme.color.interactiveBackground};
  border-radius: ${p => p.theme.radii.s};
  border: 1px solid ${(p) => p.theme.color.uiLine};
`;

const CardTitle = styled.div`
  ${p => p.theme.textStyle.headingXXS};
  color: ${p => p.theme.color.textMediumEmphasis};
  padding-top: ${(p) => p.theme.spacing.s};
  padding-bottom: ${(p) => p.theme.spacing.xxs};
`;

const CardSubtitle = styled.div`
  padding-bottom: ${(p) => p.theme.spacing.s};
`;

const Thumbnail = styled.div<{backgroundUrl: string}>`
  background: url(${p => p.backgroundUrl}) no-repeat;
  background-size: cover;
  background-position: top;
  min-height: 140px;
  margin-bottom: ${(p) => p.theme.spacing.s};
  width: 193px;
`;

export { Card, CardTitle, CardSubtitle, Thumbnail }
