import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100%;
  padding-bottom: ${(p) => `calc(${p.theme.spacing.l} + ${p.theme.spacing.s})`};
`;

const FormWrapper = styled.div`
  position: relative;
  padding: ${(p) => p.theme.spacing.m};
  height: 100%;
  overflow: auto;
`;

const SourcesWrapper = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const SourceActions = styled.div`
  position: relative;
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textLowEmphasis};
  padding: ${(p) => `${p.theme.spacing.xs} 0`};
`;

const DataLabel = styled.span`
  display: block;
  width: 100%;
  padding-bottom: ${(p) => p.theme.spacing.xs};
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
`;

const FieldLabel = styled.label`
  ${(p) => p.theme.textStyle.fieldLabel};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  display: block;
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const RadioWrapper = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const SelectWrapper = styled.div`
  position: absolute;
  right: ${(p) => p.theme.spacing.m};
  margin-top: ${(p) => `-${p.theme.spacing.xs}`};
  div {
    right: 0;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  text-align: right;
  padding: ${(p) => `${p.theme.spacing.s} ${p.theme.spacing.m} ${p.theme.spacing.m} ${p.theme.spacing.m}`};
  border-top: 1px solid ${(p) => p.theme.color.uiLine};
  align-items: center;
  button {
    flex-shrink: 0;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const SourceMenu = styled.ul``;

const SourceItem = styled.li`
  padding: ${(p) => p.theme.spacing.s};
  ${(p) => p.theme.textStyle.fieldContent};
  color: ${(p) => p.theme.color.textHighEmphasis};
  text-transform: capitalize;
  &:hover {
    background: ${(p) => p.theme.color.overlayHoverPrimary};
  }
`;

const AdvancedWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.m};
`;

const ConfigLabel = styled.div<{ isOpen: boolean }>`
  position: relative;
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.colors.interactive01};
  margin-bottom: ${(p) => p.theme.spacing.xs};
  cursor: pointer;
  :after {
    content: "";
    border: solid ${(p) => p.theme.color.interactive01};
    margin-left: ${(p) => p.theme.spacing.xs};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: ${(p) => (p.isOpen ? `rotate(-135deg) translate(-2px, 0)` : `rotate(45deg) translate(0, -2px)`)};
  }
`;

const ConfigWrapper = styled.div<{ isOpen?: boolean }>`
  overflow-y: ${(p) => (p.isOpen === false ? "hidden" : "visible")};
  height: ${(p) => (p.isOpen === false ? 0 : "auto")};
  transition: height 0.5s ease-in-out;
`;

const ConfigContent = styled.div<{ smallMargin: boolean; isLast?: boolean }>`
  margin-bottom: ${(p) => p.theme.spacing.m};
  padding: ${(p) =>
    p.smallMargin ? `0 0 ${p.theme.spacing.xs} ${p.theme.spacing.m}` : `0 0 ${p.theme.spacing.m} ${p.theme.spacing.m}`};
  border-bottom: ${(p) => (p.isLast ? "none" : `1px solid ${p.theme.color.uiLine}`)};
`;

const SubConfigContent = styled.div<{ hasMargin?: boolean }>`
  padding-left: ${(p) => p.theme.spacing.m};
  margin-bottom: ${(p) => (p.hasMargin ? p.theme.spacing.s : "0")};
`;

const OptionLabel = styled.div<{ isOpen: boolean }>`
  position: relative;
  margin-bottom: ${(p) => p.theme.spacing.xs};
  padding-left: ${(p) => p.theme.spacing.xxs};
  cursor: pointer;
  :before {
    position: absolute;
    top: 6px;
    content: "";
    border: solid ${(p) => p.theme.color.interactive01};
    margin-right: 12px;
    border-width: 0 2px 2px 0;
    padding: 3px;
    transform: ${(p) => (p.isOpen ? `rotate(45deg) translate(-3px, 0)` : `rotate(-45deg) translate(0, -3px)`)};
  }
`;

const OptionText = styled.div`
  ${(p) => p.theme.textStyle.fieldContent};
  color: ${(p) => p.theme.colors.textHighEmphasis};
`;

const SubOptionText = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.colors.textHighEmphasis};
`;

const LabelContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
`;

const OptionDescription = styled.div<{ isOpen?: boolean }>`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  padding-right: ${(p) => p.theme.spacing.s};
  overflow-y: ${(p) => (p.isOpen === false ? "hidden" : "visible")};
  height: ${(p) => (p.isOpen === false ? 0 : "auto")};
  transition: height 0.5s ease-in-out;
`;

const CheckWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.xs};
  label {
    margin-bottom: 0;
    color: ${(p) => p.theme.colors.textHighEmphasis};
  }
`;

const ErrorWrapper = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.colors.error};
  width: 100%;
  padding-right: ${(p) => p.theme.spacing.s};
`;

export {
  Wrapper,
  FormWrapper,
  SourcesWrapper,
  SourceActions,
  DataLabel,
  FieldLabel,
  RadioWrapper,
  ActionsWrapper,
  IconWrapper,
  SourceMenu,
  SourceItem,
  SelectWrapper,
  AdvancedWrapper,
  ConfigLabel,
  ConfigWrapper,
  ConfigContent,
  SubConfigContent,
  OptionLabel,
  LabelContent,
  OptionDescription,
  OptionText,
  SubOptionText,
  CheckWrapper,
  ErrorWrapper,
};
