import React, { useState } from "react";
import { Icon } from "@ax/components";

import * as S from "./style";

const SubNotification = (props: ISubNotificationProps): JSX.Element => {
  const { text } = props;

  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <S.Wrapper isVisible={isVisible} data-testid="subnotification-wrapper">
      <S.Row>
        <S.Text>{text}</S.Text>
        <S.ActionsWrapper>
          <S.CloseWrapper onClick={handleClose} data-testid="subnotification-close-button">
            <Icon name="close" size="16" />
          </S.CloseWrapper>
        </S.ActionsWrapper>
      </S.Row>
    </S.Wrapper>
  );
};

export interface ISubNotificationProps {
  text: string;
}

export default SubNotification;
