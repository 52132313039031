import React from "react";
import { BulkSelectionOptions } from "@ax/components";
import {
  IColumn,
  IQueryValue,
  ISchemaField,
  IStructuredDataQueryValues,
  IStructuredDataSortedInitialState,
} from "@ax/types";
import { usePermission } from "@ax/hooks";
import TableHeader from "./TableHeader";

const BulkHeader = (props: IProps): JSX.Element => {
  const {
    showBulk,
    checkState,
    bulkDelete,
    selectItems,
    selectAllItems,
    totalItems,
    isScrolling,
    bulkPublish,
    bulkUnpublish,
    isEditable,
    isFromPage,
    sortItems,
    filterItems,
    filterValues,
    sortedListStatus,
    isAllPages,
    categoryColumns,
    columns,
    setColumns,
    maxColumns,
    exportAction,
    setHoverCheck,
  } = props;

  const isAllowedToPublishPages = usePermission("global.globalData.publishUnpublishAllGlobalData");
  const isAllowedToDeletePage = usePermission("global.globalData.deleteAllGlobalData");

  const deleteAction = {
    icon: "delete",
    text: "delete",
    action: bulkDelete,
  };

  const actions = isAllowedToPublishPages
    ? [
        {
          icon: "upload-pending",
          text: "publish",
          action: bulkPublish,
        },
        {
          icon: "offline",
          text: "unpublish",
          action: bulkUnpublish,
        },
      ]
    : [];

  const bulkActions = isEditable && isAllowedToDeletePage ? [deleteAction, ...actions] : actions;

  return showBulk ? (
    <BulkSelectionOptions
      isScrolling={isScrolling}
      checkState={checkState}
      actions={bulkActions}
      selectItems={selectItems}
      totalItems={totalItems}
      exportAction={exportAction}
    />
  ) : (
    <TableHeader
      totalItems={totalItems}
      selectAllItems={selectAllItems}
      isScrolling={isScrolling}
      isFromPage={isFromPage}
      sortItems={sortItems}
      filterItems={filterItems}
      sortedListStatus={sortedListStatus}
      filterValues={filterValues}
      isAllPages={isAllPages}
      checkState={checkState}
      categoryColumns={categoryColumns}
      columns={columns}
      setColumns={setColumns}
      maxColumns={maxColumns}
      setHoverCheck={setHoverCheck}
    />
  );
};

interface IProps {
  showBulk: boolean;
  checkState: Record<string, boolean>;
  bulkDelete: () => void;
  bulkPublish: () => void;
  bulkUnpublish: () => void;
  selectItems: () => void;
  selectAllItems: () => void;
  totalItems: number;
  isScrolling: boolean;
  isEditable: boolean | null;
  isFromPage: boolean;
  sortItems: (orderPointer: IQueryValue[], isAscending: boolean) => void;
  filterItems: (filterPointer: string, filtersSelected: IQueryValue[]) => void;
  sortedListStatus: IStructuredDataSortedInitialState;
  isAllPages: boolean;
  filterValues: IStructuredDataQueryValues;
  categoryColumns: ISchemaField[];
  columns: IColumn[];
  setColumns: (columns: IColumn[]) => void;
  maxColumns: { value: number; text: string };
  exportAction?(formats: (number | string)[]): void;
  setHoverCheck: (state: boolean) => void;
}

export default BulkHeader;
