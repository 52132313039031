import { getRichTextConfig, parseClassNames } from "./helpers";

const richTextConfig = getRichTextConfig();
const paragraphStyles = richTextConfig?.paragraphStyles ? parseClassNames(richTextConfig.paragraphStyles) : undefined;
const tableStyles = richTextConfig?.tableStyles ? parseClassNames(richTextConfig.tableStyles) : undefined;
const tableCellStyles = richTextConfig?.tableCellStyles ? parseClassNames(richTextConfig.tableCellStyles) : undefined;

const miscButtons = [
  "underline",
  "textColor",
  "formatOLSimple",
  "specialCharacters",
  "insertHR",
  "alignLeft",
  "alignRight",
  "alignJustify",
  "outdent",
  "indent",
  "undo",
  "redo",
  "selectAll",
  "html",
  "insertTable",
  paragraphStyles ? "paragraphStyle" : undefined,
];

const buttonsFull = {
  moreText: {
    buttons: ["bold", "italic", "paragraphFormat", "formatUL", "quote", "alignCenter"],
    buttonsVisible: 6,
  },
  moreText2: {
    buttons: ["insertLink", "insertImage", "insertVideo", "clearFormatting", "fullscreen"],
    buttonsVisible: 5,
  },
  moreMisc: {
    buttons: miscButtons,
    align: "right",
    buttonsVisible: 0,
  },
};

const buttons = [
  [
    "bold",
    "italic",
    "formatUL",
    "insertLink",
    "paragraphFormat",
    paragraphStyles ? "paragraphStyle" : undefined,
  ],
];

const wysiwygConfig = {
  key: process.env.REACT_APP_FROALA_KEY,
  // pastePlain: false,
  pasteDeniedAttrs: ["class", "id", "style", "dir"],
  listAdvancedTypes: false,
  attribution: false,
  paragraphFormat: {
    N: "Normal",
    H1: "Heading 1",
    H2: "Heading 2",
    H3: "Heading 3",
    H4: "Heading 4",
  },
  imageEditButtons: [
    "imageReplace",
    "imageAlign",
    "imageCaption",
    "imageRemove",
    "|",
    "imageLink",
    "linkOpen",
    "linkEdit",
    "linkRemove",
    "-",
    "imageDisplay",
    "imageAlt",
  ],
  imageResize: false,
  linkEditButtons: ["linkOpen", "linkEdit", "linkRemove"],
  videoInsertButtons: ["videoBack", "|", "videoByURL", "videoEmbed"],
  imageAllowedTypes: ["jpeg", "jpg", "png", "svg"],
  imageMaxSize: 20 * 1024 * 1024,
  imageManagerLoadURL: `${process.env.REACT_APP_API_ENDPOINT}/images/wysiwyg`,
  requestWithCORS: false,
  paragraphStyles,
  tableStyles,
  tableCellStyles,
  imageUploadRemoteUrls: false,
};

export { buttonsFull, buttons, wysiwygConfig };
