import React from "react";

import { IDimension } from "@ax/types";
import { useModal } from "@ax/hooks";
import { splitAndJoin, trimText } from "@ax/helpers";
import { Modal } from "@ax/components";

import DimensionPanel from "../DimensionPanel";
import * as S from "./style";

const Item = (props: IProps): JSX.Element => {
  const { item, setDimensionItem, removeDimension } = props;

  const { isOpen, toggleModal } = useModal();
  const { isOpen: isRemoveOpen, toggleModal: toggleRemoveModal } = useModal();

  const handleClick = () => toggleModal();

  const values = splitAndJoin(item.values, ";", "; ");
  const hasValues = !["", "null"].includes(values);
  const valuesText = hasValues ? values : "The value is defined on page";

  const dimensionOptions = [
    {
      label: "Delete",
      icon: "delete",
      action: toggleRemoveModal,
    },
  ];

  const mainRemoveModalAction = {
    title: "Delete Dimension",
    onClick: removeDimension,
  };

  const secondaryRemoveModalAction = { title: "Cancel", onClick: toggleRemoveModal };

  return (
    <>
      <S.Component onClick={handleClick}>
        <S.Name>{item.name}</S.Name>
        <S.Values>{trimText(valuesText, 70)}</S.Values>
        <S.StyledActionMenu icon="more" options={dimensionOptions} tooltip="Dimension actions" />
      </S.Component>
      <DimensionPanel item={item} isOpen={isOpen} toggleModal={toggleModal} setDimensionItem={setDimensionItem} />
      <Modal
        isOpen={isRemoveOpen}
        hide={toggleRemoveModal}
        title="Delete Dimension?"
        secondaryAction={secondaryRemoveModalAction}
        mainAction={mainRemoveModalAction}
        size="S"
      >
        <S.ModalContent>
          <p>
            Are you sure you want to delete <strong>{item.name} dimension</strong>? If you delete it, this dimension
            will no longer be measurable.
            <br />
            This action <strong>cannot be undone</strong>.
          </p>
        </S.ModalContent>
      </Modal>
    </>
  );
};

interface IProps {
  item: IDimension;
  setDimensionItem(content: IDimension): void;
  removeDimension: () => void;
}

export default Item;
