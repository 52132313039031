import React from "react";

import { FieldsBehavior } from "@ax/components";
import { getModuleStyles } from "@ax/helpers";
import { IMenuItemConfig } from "@ax/types";

import * as S from "./style";

const Form = (props: IProps): JSX.Element => {
  const { form, setForm } = props;

  const headerOptions = getModuleStyles("Header");
  const footerOptions = getModuleStyles("Footer");

  const handleHeaderChange = (newStyle: string) => {
    setForm({ ...form, headerStyle: newStyle });
  };

  const handleFooterChange = (newStyle: string) => {
    setForm({ ...form, footerStyle: newStyle });
  };

  const noteValue = {
    title: "Subsection view",
    text: "You can choose how to view the children of this section in navigation modules.",
  };

  const headerValue = form && form.headerStyle ? form.headerStyle : headerOptions[0] ? headerOptions[0].value : null;
  const footerValue = form && form.footerStyle ? form.footerStyle : footerOptions[0] ? footerOptions[0].value : null;

  return (
    <>
      <FieldsBehavior title="" fieldType="NoteField" value={noteValue} />
      {headerOptions.length > 0 && (
        <>
          <S.Label>Header</S.Label>
          <FieldsBehavior
            title=""
            fieldType="VisualUniqueSelection"
            columns={2}
            options={headerOptions}
            value={headerValue}
            onChange={handleHeaderChange}
          />
        </>
      )}
      {footerOptions.length > 0 && (
        <>
          <S.Label>Footer</S.Label>
          <FieldsBehavior
            title=""
            fieldType="VisualUniqueSelection"
            elementUniqueSelection={true}
            columns={2}
            options={footerOptions}
            value={footerValue}
            onChange={handleFooterChange}
          />
        </>
      )}
    </>
  );
};

interface IProps {
  form: IMenuItemConfig | null;
  setForm: (value: IMenuItemConfig) => void;
}

export default Form;
