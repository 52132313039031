import styled from "styled-components";
import { Header } from "@ax/components/TableList/style";

const TableHeader = styled.div<{ isScrolling?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: ${(p) => p.theme.spacing.m};
  border-bottom: ${(p) => (p.isScrolling ? `1px solid ${p.theme.color.uiLine};` : "")};
`;

const CheckHeader = styled(Header)`
  padding-left: ${(p) => p.theme.spacing.m};
  width: 32px;
`;

const NameHeader = styled(Header)`
  flex: 0 0 350px;
`;

const DescriptionHeader = styled(Header)`
  flex: 1;
`;

const AppliedOnHeader = styled(Header)`
  flex: 0 0 200px;
`;

const StateHeader = styled(Header)`
  flex: 0 0 100px;
  padding: 0;
  justify-content: center;
`;

const CodeHeader = styled(Header)`
  flex: 0 0 130px;
  justify-content: center;
`;

const ActionsHeader = styled(Header)`
  flex: 0 0 85px;
  padding-right: 0;
  justify-content: flex-end;
`;

export {
  TableHeader,
  CheckHeader,
  NameHeader,
  DescriptionHeader,
  AppliedOnHeader,
  ActionsHeader,
  CodeHeader,
  StateHeader,
};
