import styled from "styled-components";
import { Header } from "@ax/components/TableList/style";

const TableHeader = styled.div<{ isScrolling?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: ${(p) => p.theme.spacing.m};
  border-bottom: ${(p) => (p.isScrolling ? `1px solid ${p.theme.color.uiLine};` : "")};
`;

const CheckHeader = styled(Header)`
  padding-left: ${(p) => p.theme.spacing.m};
  width: 32px;
`;

const SiteHeader = styled(Header)`
  width: 200px;
`;

const UrlHeader = styled(Header)`
  flex: 1;
`;

const ActionsHeader = styled(Header)`
  width: 125px;
  padding-right: 0;
  justify-content: flex-end;
`;

export {
  TableHeader,
  CheckHeader,
  SiteHeader,
  UrlHeader,
  ActionsHeader,
};
