import type { Locale, Site } from "../types/core";

import * as React from "react";

import { usePage } from "./usePage";
import { useSite } from "./useSite";
import { I18nContext } from "../contexts/I18n";

type I18nTranslationFunction = (
	/** Key from the translation object that will be obtained  */
	key: string,
	/** Fallback string in case `key` doesn't return nothing (undefined) */
	defaultString?: string,
) => string | Record<string, unknown>;

/**
 * returns functions to get the translations from the context taking into
 * account the language (locale) of the current page.
 * @see [Documentación](https://www.notion.so/griddoio/useI18n-021b08689dcf4abd8f666da7b7646611)
 *
 * @example
 * const { getTraslation, getNestedTranslation } = useI18n()
 * const foo = getTranslation("submitButtonLabel")
 * const bar = getNestedTranslation("modules.backLabel")
 */
function useI18n(props?: UseI18nProps) {
	const locale = props?.locale;

	const i18nContext = React.useContext(I18nContext);

	const { siteLangs } = useSite();
	const { languageId } = usePage();
	const translations = i18nContext?.translations;

	if (!translations) {
		console.warn("Griddo: <SiteProvider> needs to have the prop translations");
	}

	if (siteLangs === undefined) {
		console.warn(
			"Griddo: <SiteProvider> needs to have the prop siteLangs set with the site languages",
		);
	}

	if (!languageId) {
		console.warn(
			"Griddo: <PageProvider> needs to have the prop languageId set with the page language id",
		);
	}

	if (!checkLangId(siteLangs, languageId)) {
		console.warn(`Griddo: languageId ${languageId} doesn't exist in this site`);
	}

	if (locale !== undefined && !checkLocale(siteLangs, locale)) {
		console.warn(`Griddo: locale ${locale} doesn't exist in this site`);
	}

	const currentLocale = locale || getLocaleFromSiteLangs(siteLangs, languageId);

	const localeTranslations =
		translations && currentLocale ? translations[currentLocale] : undefined;

	/**
	 * Return a static translation based on the translation object and current page language using a string key as argument.
	 * @example: getTranslation("greetings")
	 */
	const getTranslation: I18nTranslationFunction = (
		key: string,
		defaultString = "",
	) => {
		if (localeTranslations?.[key]) {
			return localeTranslations[key] as string | Record<string, unknown>;
		}

		return defaultString;
	};

	/**
	 * Return a static translation based on the translation object and current page language using a dot notation string key as argument.
	 * Translate function helper with "dot object notation"
	 * @example: getNestedTranslation("ui.strings.followUs")
	 */
	const getNestedTranslation: I18nTranslationFunction = (
		key: string,
		defaultString = "",
	) => {
		return localeTranslations
			? getNestedPropValue(key, localeTranslations) || defaultString
			: defaultString;
	};

	if (!currentLocale || !languageId) {
		console.warn(
			"Griddo: You forgot to put <I18nProvider> or exists an error in language id.",
		);
	}

	return { getTranslation, getNestedTranslation };
}

function checkLangId(siteLangs: Site["siteLangs"], id?: number) {
	return !!siteLangs?.find((lang) => lang.id === id);
}

function getLocaleFromSiteLangs(
	siteLangs: Site["siteLangs"],
	id?: number,
): Locale | undefined {
	return siteLangs?.find((lang) => lang.id === id)?.locale;
}

function checkLocale(siteLangs: Site["siteLangs"], locale: Locale) {
	return !!siteLangs?.find((lang) => lang?.locale === locale);
}

/**
 * Get the value of a nested key in string dot notation
 * @example:
 * Get the value of prop3 from foo object
 * getNestedPropValue("prop1.prop2.prop3", foo)
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getNestedPropValue(key: string, mainObject: Record<string, any>) {
	const prop = key?.split(".").reduce((obj, prop) => {
		if (obj[prop]) {
			return obj[prop];
		}

		// return empty string if object[prop] is undefined to prevent type error
		// trying to acces a prop of an undefined
		return "";
	}, mainObject);

	return prop;
}

export interface UseI18nProps {
	/** Locale code from where get the translation. This locale override the current page language */
	locale?: Locale;
}

export { useI18n };
