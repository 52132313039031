import styled from "styled-components";

const ResultsCount = styled.span`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textLowEmphasis};
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
`;

export { ResultsCount };
