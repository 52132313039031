import React, { useState } from "react";

import { global } from "@ax/api";
import { isReqOk, validateEmail } from "@ax/helpers";
import { Modal, FieldsBehavior } from "@ax/components";

import * as S from "./style";

const RecoveryModal = (props: IRecoveryProps): JSX.Element => {
  const { isOpen, toggleModal } = props;

  const initialModalState = {
    input: "",
    isMailSent: false,
    error: false,
  };

  const [modalState, setModalState] = useState(initialModalState);

  const handleResetPassword = async () => {
    const response = await global.forgotPassword(modalState.input);
    if (isReqOk(response.status)) {
      setModalState({ ...modalState, isMailSent: true });
    } else {
      setModalState({ ...modalState, error: true });
    }
  };

  const handleCloseModal = () => {
    setModalState(initialModalState);
    toggleModal();
  };

  const handleModalChange = (value: string) => {
    setModalState({ ...modalState, input: value });
  };

  const mainAction = !modalState.isMailSent
    ? { title: "Reset Password", onClick: handleResetPassword, disabled: !validateEmail(modalState.input) }
    : { title: "Got it!", onClick: handleCloseModal };

  const secondaryAction = !modalState.isMailSent ? { title: "Cancel", onClick: handleCloseModal } : undefined;

  const errorText = modalState.error ? "Sorry, this email doesn't exist." : "";

  const ModalContent = !modalState.isMailSent ? (
    <>
      <p>Forgot your password? No problem, we&apos;ll send you an email with instructions to reset it.</p>
      <FieldsBehavior
        fieldType="TextField"
        placeholder="Type your email"
        title="Email"
        mandatory={true}
        value={modalState.input}
        onChange={handleModalChange}
        error={modalState.error}
        helptext={errorText}
        name="email"
      />
    </>
  ) : (
    <>
      <p>
        We sent you an email with further instructions to reset your password to <strong>{modalState.input}</strong>.
      </p>
      <p>If you don&apos;t receive it, please check your spam folder or contact support.</p>
    </>
  );

  return (
    <Modal
      isOpen={isOpen}
      hide={handleCloseModal}
      size="S"
      title="Recover Password"
      mainAction={mainAction}
      secondaryAction={secondaryAction}
    >
      {isOpen ? <S.ModalContent data-testid="recovery-modal-content">{ModalContent}</S.ModalContent> : null}
    </Modal>
  );
};

export interface IRecoveryProps {
  isOpen: boolean;
  toggleModal(): void;
}

export default RecoveryModal;
