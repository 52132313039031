import styled from "styled-components";

const Wrapper = styled.div`
  border-top: 1px solid ${(p) => p.theme.color.uiLine};
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
  margin: ${(p) => p.theme.spacing.m} 0;
  padding: ${(p) => p.theme.spacing.m} 0;
`;

const Heading = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  color: ${(p) => p.theme.color.textHighEmphasis};
  padding-bottom: ${(p) => p.theme.spacing.xs};
`;

const Text = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  width: calc(${(p) => p.theme.spacing.l} * 12);
`;

const Separator = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};
  p {
    margin-bottom: ${(p) => p.theme.spacing.m};
  }
  div {
    margin-bottom: 0;
  }
`;

export { Wrapper, Heading, Text, Separator, ModalContent };
