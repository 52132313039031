import styled from "styled-components";
import { Cell, Row } from "@ax/components/TableList/TableItem/style";

const CheckCell = styled(Cell)`
  padding-left: ${(p) => p.theme.spacing.m};
  width: 32px;
  justify-content: flex-start;
  label {
    margin-bottom: ${(p) => p.theme.spacing.s};
  }
`;

const TitleCell = styled(Cell)`
  color: ${(p) => p.theme.color.textHighEmphasis};
  width: 100%;
`;

const Name = styled.div`
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const Description = styled.div`
  ${(p) => p.theme.textStyle.fieldContent};
  color: ${(p) => p.theme.color.textHighEmphasis};
`;

const RoleItem = styled(Row)<{ isSelected: boolean; isReadOnly: boolean }>`
  border: ${(p) => (p.isSelected ? `2px solid ${p.theme.color.interactive01}` : `1px solid ${p.theme.color.uiLine}`)};
  :before {
    background-color: transparent;
  }
  :hover {
    cursor: ${(p) => (p.isReadOnly ? "default" : "pointer")};
    &:before {
      background-color: ${(p) => (p.isReadOnly ? "transparent" : p.theme.color.overlayHoverPrimary)};
    }
  }
`;

export { CheckCell, TitleCell, RoleItem, Name, Description };
