import React from "react";

import { FieldsBehavior } from "@ax/components";
import { splitAndTrim } from "@ax/helpers";
import { IAnalytics, IDimension } from "@ax/types";

import { IState } from "..";
import * as S from "../style";

const DimensionsGroup = (props: {
  analytics: IAnalytics;
  state: IState;
  setDimension: (value: Record<string, string>) => void;
  disabled?: boolean;
}): JSX.Element => {
  const { analytics, state, setDimension, disabled } = props;
  const { groupSelect, values } = state;
  const selectedGroup = analytics.groups.find((g) => g.name === groupSelect);
  const dimensionNames = splitAndTrim(selectedGroup?.dimensions, ";");
  const groupDimensions = dimensionNames.map((dimensionName) => {
    const dimension = analytics.dimensions?.find((dimension) => dimension.name === dimensionName);
    return dimension;
  });

  return (
    <>
      <S.FieldsDivider />
      {groupDimensions &&
        groupDimensions.map(
          (dimension, idx) =>
            dimension && <DimensionValue key={idx} {...{ dimension, setDimension, values, disabled }} />
        )}
    </>
  );
};

const DimensionsSelection = (props: {
  analytics: IAnalytics;
  state: IState;
  setDimension: (value: Record<string, string>) => void;
  disabled?: boolean;
}): JSX.Element => {
  const { analytics, state, setDimension, disabled } = props;
  const { dimensionsSelect, values } = state;
  const selectedDimensions = analytics.dimensions?.filter((d) => dimensionsSelect.includes(d.name));

  return (
    <>
      {selectedDimensions?.length ? <S.FieldsDivider /> : <></>}
      {selectedDimensions &&
        selectedDimensions.map((dimension, idx) => (
          <DimensionValue key={idx} {...{ dimension, setDimension, values, disabled }} />
        ))}
    </>
  );
};

const DimensionValue = (props: {
  dimension: IDimension;
  setDimension: (value: Record<string, string>) => void;
  values: Record<string, string>;
  disabled?: boolean;
}) => {
  const { dimension, setDimension, values, disabled } = props;
  const dimensionValues = splitAndTrim(dimension?.values, ";");
  const options = dimensionValues.map((option) => ({ label: option, value: option }));
  const handleOnChange = (value: string) => {
    dimension && setDimension({ [dimension.name]: value });
  };

  const isNullValue = dimensionValues.includes("null");
  const fieldValue = values && dimension ? values[dimension.name] : "";

  return isNullValue ? (
    <FieldsBehavior
      title={dimension?.name}
      fieldType="TextField"
      value={fieldValue}
      onChange={handleOnChange}
      placeholder="Type a variable"
      disabled={disabled}
    />
  ) : (
    <FieldsBehavior
      title={dimension?.name}
      fieldType="Select"
      options={options}
      value={fieldValue}
      onChange={handleOnChange}
      placeholder="Select variable"
      disabled={disabled}
    />
  );
};

export { DimensionsGroup, DimensionsSelection };
