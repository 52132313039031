const NAME = "gallery";

const SET_DATA = `${NAME}/SET_DATA`;
const SET_IS_LOADING = `${NAME}/SET_IS_LOADING`;
const SET_IS_SAVING = `${NAME}/SET_IS_SAVING`;
const SET_IS_UPLOADING = `${NAME}/SET_IS_UPLOADING`;
const SET_UPLOAD_SUCCESS = `${NAME}/SET_UPLOAD_SUCCESS`;
const SET_UPLOAD_ERROR = `${NAME}/SET_UPLOAD_ERROR`;

export {
  NAME,
  SET_DATA,
  SET_IS_LOADING,
  SET_IS_SAVING,
  SET_IS_UPLOADING,
  SET_UPLOAD_SUCCESS,
  SET_UPLOAD_ERROR,
};
