import React from "react";
import styled from "styled-components";
import { Header } from "@ax/components/TableList/style";

const Date = styled((props) => <Header {...props} />)<{ isActive: boolean }>`
  width: 100px;
  &:hover {
    color: ${(p) => p.theme.color.interactive01};
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  svg {
    margin-left: 4px;
  }
`;

const InteractiveArrow = styled.div`
  display: flex;
  svg {
    path {
      fill: ${(p) => p.theme.color.interactive01};
    }
  }
`;

export { Date, IconsWrapper, InteractiveArrow };
