import styled from "styled-components";

const ListTitle = styled.h6`
  ${(p) => p.theme.textStyle.headingXXS};
  padding: ${(p) => `${p.theme.spacing.xs} ${p.theme.spacing.s}`};
  cursor: initial;
  white-space: nowrap;
`;

const StyledListItem = styled.span<{ isSelected?: boolean }>`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => (p.isSelected ? p.theme.colors.interactive01 : p.theme.colors.textMediumEmphasis)};
  padding: ${(p) => `${p.theme.spacing.xs} ${p.theme.spacing.s}`};
  cursor: pointer;
  font-weight: ${(p) => (p.isSelected ? "bold" : "normal")};
  display: flex;
  justify-content: space-between;

  &:hover {
    background-color: ${(p) => p.theme.colors.overlayHoverPrimary};
  }
`;

export { ListTitle, StyledListItem };
