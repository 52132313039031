import React from "react";
import { connect } from "react-redux";

import { Browser } from "@ax/components";
import { ILanguage, IRootState, ISite, ISocialState } from "@ax/types";

const TemplateBrowser = (props: IProps) => {
  const { socials, cloudinaryName, content, currentSiteInfo, siteLangs } = props;

  if (!currentSiteInfo) {
    throw new Error(`ERROR: User reached Template Editor with null site info`);
  }

  const { theme, id: siteID } = currentSiteInfo;
  const { header, footer } = content;

  return (
    <Browser
      isPage={true}
      socials={socials}
      content={content}
      url=""
      theme={theme}
      cloudinaryName={cloudinaryName}
      siteLangs={siteLangs}
      siteID={siteID}
      header={header}
      footer={footer}
    />
  );
};

interface IProps {
  content: any;
  currentSiteInfo: ISite | null;
  socials: ISocialState;
  cloudinaryName: string | null;
  siteLangs: ILanguage[];
}

const mapStateToProps = (state: IRootState): IProps => ({
  content: { ...state.pageEditor.editorContent },
  currentSiteInfo: state.sites.currentSiteInfo,
  socials: state.social,
  cloudinaryName: state.app.globalSettings.cloudinaryName,
  siteLangs: state.sites.currentSiteLanguages,
});

export default connect(mapStateToProps)(TemplateBrowser);
