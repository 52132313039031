import React, { useState } from "react";
import { connect } from "react-redux";
import { Icon, Modal } from "@ax/components";
import { useModal } from "@ax/hooks";
import { IRootState } from "@ax/types";
import { pageEditorActions } from "@ax/containers/PageEditor";
import { structuredDataActions } from "@ax/containers/StructuredData";

import * as S from "./style";

const TranslateButton = (props: ITranslateButtonProps): JSX.Element => {
  const {
    lang,
    autoTranslation,
    getPageTranslation,
    content,
    isPageIATranslated,
    isDataContentIATranslated,
    setIsPageTranslated,
    structuredDataForm,
    getDataContentTranslation,
    setIsContentTranslated,
  } = props;

  const canBeTranslated = !!structuredDataForm ? structuredDataForm.canBeTranslated : content.canBeTranslated;

  const initialState = {
    isLoading: false,
    error: false,
  };

  const [state, setState] = useState(initialState);
  const { isOpen, toggleModal } = useModal();

  const handleClick = async () => {
    toggleModal();
    setState((state) => ({ ...state, isLoading: true, error: false }));
    const generated = !!structuredDataForm ? await getDataContentTranslation(lang) : await getPageTranslation(lang);
    setState((state) => ({ ...state, isLoading: false }));
    if (!generated) {
      setState((state) => ({ ...state, error: true }));
    }
  };

  const handleClose = () => !!structuredDataForm ? setIsContentTranslated(false) : setIsPageTranslated(false);

  const buttonText = state.isLoading ? "Processing..." : "Translate page with AI";

  return (
    <>
      {(isPageIATranslated || isDataContentIATranslated) && !canBeTranslated && (
        <S.Wrapper data-testid="translated-notification-wrapper" isSmall={false}>
          <S.NotificationContent>
            <S.Text>This page is translated with Artificial Intelligence.</S.Text>
            <S.IconWrapper onClick={handleClose}>
              <Icon name="close" size="16" />
            </S.IconWrapper>
          </S.NotificationContent>
        </S.Wrapper>
      )}
      {autoTranslation && canBeTranslated ? (
        <>
          <S.Wrapper data-testid="translate-button-wrapper" isSmall={!!structuredDataForm}>
            <S.Text>
              Using <strong>Artificial Intelligence</strong>, you can translate the page automatically.
            </S.Text>
            <S.ButtonWrapper>
              <S.StyledButton
                type="button"
                onClick={toggleModal}
                icon={!state.isLoading ? "Ia" : undefined}
                loader={state.isLoading ? "circle" : undefined}
              >
                {buttonText}
              </S.StyledButton>
            </S.ButtonWrapper>
            {state.error && <S.ErrorText>We're having problems. Please try again in a few minutes.</S.ErrorText>}
          </S.Wrapper>
          <Modal
            isOpen={isOpen}
            hide={toggleModal}
            size="S"
            title="Translate page with AI"
            mainAction={{ title: "Translate with AI", icon: "Ia", onClick: handleClick }}
            secondaryAction={{ title: "Cancel", onClick: toggleModal }}
          >
            <S.ModalContent>
              The automatic translation <strong>will replace all the text on the page</strong>. Before proceeding, make
              sure that the existing text is not critical.
            </S.ModalContent>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export interface ITranslateButtonProps {
  lang: number;
  autoTranslation: boolean;
  content: any;
  structuredDataForm: any | null;
  isPageIATranslated: boolean;
  isDataContentIATranslated: boolean;
  getPageTranslation: (langID: number) => Promise<boolean>;
  getDataContentTranslation: (langID: number) => Promise<boolean>;
  setIsPageTranslated: (isTranslated: boolean) => Promise<void>;
  setIsContentTranslated: (isTranslated: boolean) => Promise<void>;
}

const mapDispatchToProps = {
  getPageTranslation: pageEditorActions.getPageTranslation,
  setIsPageTranslated: pageEditorActions.setIsTranslated,
  getDataContentTranslation: structuredDataActions.getDataContentTranslation,
  setIsContentTranslated: structuredDataActions.setIsTranslated,
};

const mapStateToProps = (state: IRootState) => ({
  autoTranslation: state.app.globalSettings.autoTranslation,
  content: state.pageEditor.editorContent,
  isPageIATranslated: state.pageEditor.isIATranslated,
  isDataContentIATranslated: state.structuredData.isIATranslated,
  structuredDataForm: state.structuredData.form,
});

export default connect(mapStateToProps, mapDispatchToProps)(TranslateButton);
