import React from "react";
import { connect } from "react-redux";

import { menuActions } from "@ax/containers/Navigation";
import { appActions } from "@ax/containers/App";
import { ILanguage, IMenuItem, IRootState } from "@ax/types";
import { setIsSavedData } from "@ax/forms";
import { MainWrapper } from "@ax/components";
import { usePermission } from "@ax/hooks";

import { useShouldBeSaved } from "./helpers";
import List from "./List";

const MenuView = (props: IMenus) => {
  const { updateMenu, setLanguage, isSaving, lang, siteLanguages, editorMenu, savedMenu, currentMenu } = props;

  const { isDirty } = useShouldBeSaved(editorMenu, savedMenu, currentMenu);
  const isAllowedToMangageMenus = usePermission("navigation.manageSiteMenu");

  const saveMenu = () => {
    setIsSavedData(true);
    return updateMenu();
  };

  const rightButtonProps = isAllowedToMangageMenus
    ? {
        label: isSaving ? "Saving" : isDirty ? "Save" : "Saved",
        disabled: isSaving || !isDirty,
        action: saveMenu,
      }
    : undefined;

  const languageActions = {
    setLanguage,
  };

  return (
    <MainWrapper
      title="Menus"
      rightButton={rightButtonProps}
      language={lang}
      availableLanguages={siteLanguages}
      languageActions={languageActions}
      isDirty={isDirty}
    >
      <List isDirty={isDirty} />
    </MainWrapper>
  );
};

const mapStateToProps = (state: IRootState) => ({
  isSaving: state.app.isSaving,
  lang: state.app.lang,
  siteLanguages: state.sites.currentSiteLanguages,
  editorMenu: state.menu.editorMenu.elements,
  savedMenu: state.menu.savedMenu.elements,
  currentMenu: state.menu.currentMenu,
});

const mapDispatchToProps = {
  updateMenu: menuActions.updateMenu,
  setLanguage: appActions.setLanguage,
};

interface IStateProps {
  isSaving: boolean;
  lang: { locale: string; id: number | null };
  siteLanguages: ILanguage[];
  editorMenu: IMenuItem[] | undefined;
  savedMenu: IMenuItem[] | undefined;
  currentMenu: string;
}

interface IDispatchProps {
  updateMenu(): void;
  setLanguage(lang: { locale: string; id: number | null }): void;
}

type IMenus = IStateProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(MenuView);
