import React from "react";
import styled from "styled-components";
import { BulkSelectionOptions } from "@ax/components";

const BulkWrapper = styled.div`
  width: 100%;
  span {
    margin-left: ${(p) => p.theme.spacing.xs};
  }
`;

const StyledBulkSelectionOptions = styled(BulkSelectionOptions)`
  padding: ${(p) => `0 0 0 ${p.theme.spacing.xs}`};
  height: ${(p) => p.theme.spacing.s};
`;

export { BulkWrapper, StyledBulkSelectionOptions }
