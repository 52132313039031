import styled from "styled-components";

const InformativeMenu = styled.div`
  padding: ${p => p.theme.spacing.xs} ${p => p.theme.spacing.s} 0 ${p => p.theme.spacing.s};
  ${p => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textLowEmphasis};
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  text-align: right;
`;

export { InformativeMenu, Footer }