import React from "react";

import { CheckField, SiteFilter, TableCounter, DateFilter, Tooltip } from "@ax/components";
import { IQueryValue } from "@ax/types";

import * as S from "./style";

const TableHeader = (props: IProps): JSX.Element => {
  const {
    totalItems,
    selectAllItems,
    isScrolling,
    filterItems,
    filterValues,
    isSiteItem,
    sortItems,
    sortedListStatus,
    checkState,
    setHoverCheck,
  } = props;

  return (
    <S.TableHeader isScrolling={isScrolling}>
      <S.CheckHeader>
        <Tooltip content="Select All Redirects" bottom>
          <CheckField
            key="selectAll"
            name="selectAll"
            value="selectAll"
            onChange={selectAllItems}
            checked={checkState.isAllSelected || checkState.hoverCheck}
            disabled={false}
            error={false}
            setHoverCheck={setHoverCheck}
          />
        </Tooltip>
      </S.CheckHeader>
      {!isSiteItem && (
        <S.SiteHeader>
          <SiteFilter filterItems={filterItems} value={filterValues} pointer="sites" center={false} />
        </S.SiteHeader>
      )}
      <S.UrlHeader>Old URL</S.UrlHeader>
      <S.UrlHeader>New URL</S.UrlHeader>
      <DateFilter sortItems={sortItems} sortedState={sortedListStatus} />
      <S.ActionsHeader>
        <TableCounter totalItems={totalItems} />
      </S.ActionsHeader>
    </S.TableHeader>
  );
};

interface IProps {
  totalItems: number;
  isScrolling: boolean;
  selectAllItems: () => void;
  filterItems: (filterPointer: string, filtersSelected: IQueryValue[]) => void;
  filterValues: any;
  isSiteItem: boolean;
  sortItems: (orderPointer: IQueryValue[], isAscending: boolean) => void;
  sortedListStatus: { isAscending: boolean; sortedByDate: boolean };
  checkState: Record<string, boolean>;
  setHoverCheck: (state: boolean) => void;
}

export default TableHeader;
