import React from "react";
import { connect } from "react-redux";

import { ILanguage, IRootState } from "@ax/types";
import { Flag } from "@ax/components";
import ConnectedField from "./ConnectedField";

import * as S from "./style";

const Form = (props: IProps): JSX.Element => {
  const { currentSiteLanguages, item } = props;

  const currentSiteLanguagesIds = currentSiteLanguages.map((e) => e.id);

  const LanguageElement = () =>
    item ? (
      <S.LanguageItem>
        <S.ItemHeader>You are editing</S.ItemHeader>
        <S.ItemContent>
          <Flag name={item.locale} />
          <span>{`${item.language} Language`}</span>
        </S.ItemContent>
      </S.LanguageItem>
    ) : (
      <ConnectedField
        title="Language"
        name="language"
        type="AsyncSelect"
        entity="languages"
        filter={currentSiteLanguagesIds}
        mandatory={true}
      />
    );

  const pathText =
    "This path is for the site on this language. Please, fill with the complete subdomain, included the locale prefix. Example: /es/name-of-school";

  return (
    <>
      <LanguageElement />
      <ConnectedField title="Domain" name="domain" type="AsyncSelect" entity="domains" mandatory={true} />
      <ConnectedField
        title="Path"
        name="path"
        type="TextField"
        mandatory={true}
        helptext={pathText}
        autoComplete="lang-path"
      />
      <ConnectedField title="" name="isDefault" type="UniqueCheck" options={[{ title: "Default language" }]} />
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  currentSiteLanguages: state.sites.currentSiteLanguages,
});

interface IProps {
  item?: ILanguage;
  currentSiteLanguages: ILanguage[];
}

export default connect(mapStateToProps)(Form);
