import React from "react";
import { Route, Redirect } from "react-router-dom";

import { usePermission } from "@ax/hooks";
import { IUser } from "@ax/types";
import { ErrorPage } from "@ax/components";

const PrivateRoute = (props: IProps): JSX.Element => {
  const { component: Component, token, permission, currentUser, ...rest } = props;

  const isAllowed = usePermission(permission);

  const getComponent = (routeProps: any) => {
    if (!!currentUser && !isAllowed) {
      return <ErrorPage type="forbidden" />;
    }
    return token ? <Component {...routeProps} /> : <Redirect to="/login" />;
  };

  return <Route {...rest} render={getComponent} />;
};

interface IProps {
  component: any;
  path: string;
  token: string;
  exact: any;
  permission: string | string[] | undefined;
  currentUser: IUser | null;
}

export default PrivateRoute;
