import styled from "styled-components";

const getWidth = (width: number, space: string) => {
  const minWidth = 1280 - width - parseInt(space) * 2;
  return minWidth < 500 ? `500px` : `${minWidth}px`;
};

export const LeftPanel = styled.section`
  position: relative;
  flex-grow: 1;
  min-width: 500px;
`;

export const RightPanel = styled.section<{ width: number }>`
  display: flex;
  position: relative;
  padding: ${(p) => `0 ${p.theme.spacing.m} ${p.theme.spacing.m} ${p.theme.spacing.m}`};
  flex-shrink: 0;
  width: ${(p) => p.width}px;
  min-width: 344px;
  max-width: ${(p) => `calc(100% - 500px - ${p.theme.spacing.m})`};
  flex-direction: column;
`;

export const FixedPanel = styled.div<{ width: number }>`
  position: sticky;
  top: calc(${(p) => p.theme.spacing.xl} + ${(p) => p.theme.spacing.m});
  height: calc(
    100vh - (${(p) => p.theme.spacing.xl} + (${(p) => p.theme.spacing.m} * 2)) -
      (${(p) => p.theme.spacing.m} + (${(p) => p.theme.spacing.xs} * 2))
  );
  min-width: ${(p) => getWidth(p.width, p.theme.spacing.m)};
  margin-left: ${(p) => p.theme.spacing.m};
`;
