import React from "react";
import { FieldContainer, FieldsBehavior } from "@ax/components";
import { IErrorItem, IPage, ISite } from "@ax/types";

const getInnerFields = (
  innerFields: any[],
  innerActions: any,
  selectedContent: IPage,
  isTemplateActivated: boolean,
  theme: string,
  parentDisabled?: boolean,
  site?: ISite,
  errors?: IErrorItem[],
  deleteError?: (error: IErrorItem) => void
) => {
  let fieldArr: any[] = [];

  return (
    innerFields &&
    innerFields.map((singleFieldProps: any) => {
      const { key } = singleFieldProps;
      const error = errors && errors.find((err: any) => err.editorID === selectedContent.editorID && err.key === key);

      if (singleFieldProps.type === "ConditionalField" || singleFieldProps.type === "ArrayFieldGroup") {
        fieldArr = getInnerFields(
          singleFieldProps.fields,
          innerActions,
          selectedContent,
          isTemplateActivated,
          theme,
          parentDisabled,
          site,
          errors,
          deleteError
        );
      }

      return (
        <FieldContainer
          key={key}
          objKey={key}
          innerFields={fieldArr}
          field={singleFieldProps}
          actions={innerActions}
          selectedContent={selectedContent}
          updateValue={innerActions.updateValue}
          goTo={innerActions.goTo}
          site={site}
          {...singleFieldProps}
          disabled={!isTemplateActivated || singleFieldProps.disabled || parentDisabled}
          error={error}
          deleteError={deleteError}
          theme={theme}
        />
      );
    })
  );
};

const getStructuredDataInnerFields = (
  innerFields: any[],
  content: any,
  updateValue: (value: Record<string, unknown>) => void,
  theme: string,
  errors: IErrorItem[]
) => {
  let fieldArr: any[] = [];

  return (
    innerFields &&
    innerFields.map((singleFieldProps: any) => {
      const { key, type, fields } = singleFieldProps;

      const handleChange = (newValue: any) => {
        updateValue({ [key]: newValue });
      };

      const value = content && content[key];

      if (type === "ConditionalField" || type === "ArrayFieldGroup") {
        fieldArr = getStructuredDataInnerFields(fields, content, updateValue, theme, errors);
      }

      const error = errors.find((err: any) => err.key === key);

      const fieldProps = {
        value,
        objKey: key,
        fieldType: type,
        innerFields: fieldArr,
        field: singleFieldProps,
        onChange: handleChange,
        ...singleFieldProps,
        theme,
        error,
      };

      return <FieldsBehavior key={key} {...fieldProps} />;
    })
  );
};

export { getInnerFields, getStructuredDataInnerFields };
