import React, { useState } from "react";

import { IModal } from "@ax/types";
import { Modal, FieldsBehavior } from "@ax/components";
import { stringToDate } from "@ax/helpers";

import * as S from "./style";

const ScheduleModal = (props: IScheduleModal): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction, scheduleDate, setScheduleDate } = props;

  const [error, setError] = useState(false);

  const handleDateChange = (dateVal: string) => {
    setScheduleDate({ ...scheduleDate, date: dateVal });
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const date = stringToDate(dateVal);
    if (date >= now) {
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleTimeChange = (time: string) => {
    setScheduleDate({ ...scheduleDate, time });
    const timeArray = time.split(":");
    if (timeArray[0] === "00") {
      setError(true);
    } else {
      setError(false);
    }
  };

  const modalAction: any = { ...mainModalAction, disabled: error };

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Schedule publication"
      secondaryAction={secondaryModalAction}
      mainAction={modalAction}
      size="M"
      height={330}
      overflow="visible"
    >
      <S.ModalContent>
        <p>
          You can schedule the publication of your page at a <strong>specific date and time</strong>.{" "}
          <strong>The selected time is approximate</strong> due to our structural publication process.
        </p>
        <S.ModalFields>
          <FieldsBehavior
            title="Date"
            name="date"
            fieldType="DateField"
            value={scheduleDate.date}
            onChange={handleDateChange}
            validators={{ dateFormat: "futureDate" }}
            mandatory={true}
            futureDate={true}
          />
          <FieldsBehavior
            title="Time"
            name="time"
            fieldType="TimeField"
            value={scheduleDate.time}
            onChange={handleTimeChange}
            helptext="12 hour time format"
            mandatory={true}
          />
        </S.ModalFields>
      </S.ModalContent>
    </Modal>
  );
};

const CancelScheduleModal = (props: IModal): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction } = props;

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Cancel schedule publication"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
      height={240}
    >
      <S.ModalContent>
        <p>
          Are you sure you want to cancel the scheduled publication? Once canceled,{" "}
          <strong>the page will not be published at the selected time</strong>.
        </p>
      </S.ModalContent>
    </Modal>
  );
};

interface IScheduleModal extends IModal {
  scheduleDate: { date: string; time: string };
  setScheduleDate: (scheduleDate: { date: string; time: string }) => void;
}

export { ScheduleModal, CancelScheduleModal };
