import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { pages } from "@ax/api";
import { getDefaultTheme, isReqOk } from "@ax/helpers";
import { BrowserContent, Loading } from "@ax/components";

import * as S from "./style";

const PublicPreview = () => {
  const { id: pageID, entity } = useParams<{ id: string; entity: string }>();
  const [state, setState] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    document.body.classList.add("preview");

    const getPage = async () => {
      if (!isMounted) return;

      setIsLoading(true);
      const response = await pages.getPublicPage(parseInt(pageID), entity);
      if (isReqOk(response.status)) {
        setState(response.data);
      } else {
        console.log("Error en getPublicPage");
      }
      setIsLoading(false);
    };

    getPage();

    return function cleanup() {
      isMounted = false;
      document.body.classList.remove("preview");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const globalTheme = getDefaultTheme();
  const theme = state && state.site ? state.siteInfo.theme : globalTheme;
  const socials = state && state.site ? state.siteInfo.socials : [];
  const langs = state && state.site ? state.siteInfo.siteLanguages : [];

  if (isLoading || !state) return <Loading />;

  return (
    <S.Wrapper ref={(ref: any) => ((window as any).browserRef = ref)}>
      <BrowserContent
        cloudinaryName={state && state.cloudinaryName}
        theme={theme}
        socials={socials}
        siteLangs={langs}
        siteID={state && state.site}
        isPage={true}
        content={state}
        header={state && state.headerContent}
        footer={state && state.footerContent}
        languageID={state && state.language}
        pageLanguages={state && state.pageLanguages}
        renderer="preview"
      />
    </S.Wrapper>
  );
};

export default PublicPreview;
