import { useCallback, useEffect } from "react";

const useOnMessageReceivedFromIframe = (actions?: {
  setSelectedContentAction: any;
  deleteModuleAction(editorID: number[]): void;
  duplicateModuleAction(editorID: number[]): number;
  setScrollEditorIDAction(editorID: number | null): void;
}): void => {
  const onMessageReceivedFromIframe = useCallback(
    (ev: MessageEvent<{ type: string; message: string }>) => {
      if (typeof ev.data !== "object") return;
      if (!ev.data.type) return;
      if (!ev.data.message) return;
      if (ev.data.type === "module-click") {
        actions?.setSelectedContentAction(ev.data.message);
      }
      if (ev.data.type === "module-delete") {
        actions?.setSelectedContentAction(0);
        actions?.deleteModuleAction([parseInt(ev.data.message)]);
      }
      if (ev.data.type === "module-duplicate") {
        const duplicatedEditorID = actions?.duplicateModuleAction([parseInt(ev.data.message)]);
        actions?.setSelectedContentAction(duplicatedEditorID);
      }
      if (ev.data.type === "module-scroll") {
        actions?.setScrollEditorIDAction(parseInt(ev.data.message));
      }
    },
    [actions]
  );

  useEffect(() => {
    window.addEventListener("message", onMessageReceivedFromIframe);
    return () => window.removeEventListener("message", onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]);
};

const useOnMessageReceivedFromOutside = (setEditorContent: any, setSelectedContent: any): void => {
  const onMessageReceivedFromOutside = useCallback(
    (ev: MessageEvent<{ type: string; message: Record<string, unknown> }>) => {
      if (typeof ev.data !== "object") return;
      if (!ev.data.type) return;
      if (!ev.data.message) return;
      if (ev.data.type === "content-update") {
        setEditorContent(ev.data.message);
      }
      if (ev.data.type === "selected-content") {
        setSelectedContent(ev.data.message);
      }
    },
    [setEditorContent, setSelectedContent]
  );

  useEffect(() => {
    window.addEventListener("message", onMessageReceivedFromOutside);
    return () => window.removeEventListener("message", onMessageReceivedFromOutside);
  }, [onMessageReceivedFromOutside]);
};

export { useOnMessageReceivedFromIframe, useOnMessageReceivedFromOutside };
