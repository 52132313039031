import styled from "styled-components";

const Tracker = styled.div`
  height: ${p => p.theme.spacing.xxs};
  width: 100%;
  border-radius: 2px;
  background-color: #e6e7f8;
`;

const ProgressInTracker = styled.div<{ percentage: number; inverse: boolean }>`
  background-color: ${p => p.inverse ? p.theme.color.interactiveInverse : p.theme.color.interactive01};
  border-radius: 2px;
  height: 100%;
  width: ${p => p.percentage}%;
`;

export { Tracker, ProgressInTracker }
