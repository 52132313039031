import styled from "styled-components";
import { ActionMenu } from "@ax/components";

const Wrapper = styled.div<{ scrollDown: boolean }>`
  padding-top: ${(p) => p.theme.spacing.m};
  background-color: ${(p) => p.theme.color.uiBackground01};
  position: ${(p) => (p.scrollDown ? "relative" : "sticky")};
  top: ${(p) => (p.scrollDown ? "0" : "63px")};
  z-index: 3;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Title = styled.p`
  ${(p) => p.theme.textStyle.headingS};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const StyledActionMenu = styled(ActionMenu)`
  width: 32px;
  position: absolute;
  right: 0;
  top: 0;
`;

export { Wrapper, HeaderWrapper, Title, StyledActionMenu };
