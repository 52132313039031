import styled from "styled-components";

const ListWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  overflow: auto;
  width: 100%;
  flex-direction: column;
  padding-bottom: ${p => p.theme.spacing.m};
`;

const ModalContent = styled.div`
  padding: ${p => p.theme.spacing.m};
  p {
    margin-bottom: ${p => p.theme.spacing.m};
  }
  div {
    margin-bottom: 0;
  }
`;

const LoadingWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export {
  ListWrapper,
  ContentWrapper,
  ModalContent,
  LoadingWrapper
}
