import * as React from "react";
const SvgNewFolder = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#5057FF"
      d="M20 6h-8l-2-2H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2Zm0 12H4V6h5.17l2 2H20v10Zm-8-4h2v2h2v-2h2v-2h-2v-2h-2v2h-2v2Z"
    />
  </svg>
);
export default SvgNewFolder;
