import styled from "styled-components";

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Title = styled.p`
  ${(p) => p.theme.textStyle.headingS};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const Tab = styled.div`
  border-bottom: ${(p) => `1px solid ${p.theme.color.uiLine}`};
  margin: ${(p) => p.theme.spacing.s} 0;
  padding-bottom: ${(p) => p.theme.spacing.s};
  ${(p) => p.theme.textStyle.headingXS};
  color: ${(p) => p.theme.color.textHighEmphasis};
  text-align: center;
`;

const FieldsWrapper = styled.div`
  margin: ${(p) => `${p.theme.spacing.m} 0 ${p.theme.spacing.m} 0`};
  height: 100%;
`;

const FieldsTitle = styled.div`
  position: relative;
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  padding-bottom: ${(p) => p.theme.spacing.xs};
  margin-bottom: ${(p) => p.theme.spacing.m};
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
`;

export { HeaderWrapper, Title, Tab, FieldsWrapper, FieldsTitle };
