const NAME = "integrations";

const SET_INTEGRATIONS = `${NAME}/SET_INTEGRATIONS`;
const SET_TOTAL_ITEMS = `${NAME}/SET_TOTAL_ITEMS`;
const SET_CURRENT_INTEGRATION = `${NAME}/SET_CURRENT_INTEGRATION`;
const SET_COPY_INTEGRATION = `${NAME}/SET_COPY_INTEGRATION`;

const DEFAULT_PARAMS = {
  page: 1,
  itemsPerPage: 50,
  pagination: true,
};

export { NAME, SET_INTEGRATIONS, SET_TOTAL_ITEMS, DEFAULT_PARAMS, SET_CURRENT_INTEGRATION, SET_COPY_INTEGRATION };
