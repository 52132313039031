import React, { useEffect } from "react";
import { connect } from "react-redux";

import { IRootState, ILanguage, ISettingsForm } from "@ax/types";
import { slugify } from "@ax/helpers";
import { ErrorToast, FieldsBehavior } from "@ax/components";

import * as S from "./style";

const SiteModal = (props: ISiteModalProps): JSX.Element => {
  const { languages, form, setForm } = props;
  const { name, defaultLanguage, path, domain } = form;
  const globalDefaultLang = languages?.find((lang: ILanguage) => lang.isDefault);

  const updateForm = (newValue: Record<string, string | number>) =>
    setForm((state: ISettingsForm) => ({ ...state, ...newValue }));

  const setNameValue = (value: string) => updateForm({ name: value, path: slugify(value) });
  const setLangValue = (value: number) => updateForm({ defaultLanguage: value });
  const setPathValue = (value: string) => updateForm({ path: value });
  const setDomainValue = (value: number) => updateForm({ domain: value });

  useEffect(() => {
    globalDefaultLang && setLangValue(globalDefaultLang.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const slugHelpText =
    "The path is for the site on this language. Please, fill with the name of the site. Example: /the-site-url";

  return (
    <>
      <ErrorToast size="l" />
      <S.Form data-testid="site-modal-form">
        <S.FieldsWrapper>
          <FieldsBehavior
            title="Name"
            name="name"
            fieldType="TextField"
            value={name}
            mandatory={true}
            placeholder="Type a name of your new site"
            onChange={setNameValue}
          />
          <FieldsBehavior
            title="Select default language"
            name="language"
            fieldType="AsyncSelect"
            value={defaultLanguage}
            entity="languages"
            onChange={setLangValue}
            mandatory={true}
          />
        </S.FieldsWrapper>
        <S.FieldsWrapper>
          <FieldsBehavior
            title="Domain"
            name="domain"
            fieldType="AsyncSelect"
            entity="domains"
            value={domain}
            onChange={setDomainValue}
            mandatory={true}
          />
          <FieldsBehavior
            title="Path"
            name="path"
            fieldType="TextField"
            value={path}
            mandatory={true}
            placeholder="/site"
            onChange={setPathValue}
            helptext={slugHelpText}
          />
        </S.FieldsWrapper>
      </S.Form>
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  languages: state.app.globalLangs,
});

interface IStateProps {
  languages?: ILanguage[];
  form: { name: string; defaultLanguage: number | null; path: string; domain: string | null };
  setForm: React.Dispatch<React.SetStateAction<any>>;
}

type ISiteModalProps = IStateProps;

export default connect(mapStateToProps, null)(SiteModal);
