import React from "react";
import { IIntegration } from "@ax/types";

const getSortedListStatus = (
  orderPointer: string,
  isAscending: boolean
): { isAscending: boolean; sortedByTitle: boolean } => {
  const sortedListStatus = {
    isAscending,
    sortedByTitle: orderPointer === "name",
  };

  return sortedListStatus;
};

const getIntegrationsNames = (integrations: IIntegration[], ids: number[]) => {
  const names = integrations.reduce(
    (prev: string[], curr) => (curr.id && ids.includes(curr.id) ? [...prev, curr.name] : prev),
    []
  );
  const namesString = names.join(", ");
  const lastCommaIndex = namesString.lastIndexOf(", ");
  if (lastCommaIndex > -1) {
    return (
      <>
        <strong>{namesString.substring(0, lastCommaIndex - 1)}</strong> and
        <strong>{namesString.substring(lastCommaIndex + 1)}</strong> add-ons
      </>
    );
  } else {
    return (
      <>
        <strong>{namesString}</strong> add-on
      </>
    );
  }
};

export { getSortedListStatus, getIntegrationsNames };
