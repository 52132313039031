import React from "react";

import { IDimension, IDimensionsGroup } from "@ax/types";
import { useModal } from "@ax/hooks";
import { splitAndJoin } from "@ax/helpers";
import { Modal } from "@ax/components";

import GroupPanel from "../GroupPanel";
import * as S from "./style";

const Item = (props: IProps): JSX.Element => {
  const { item, setGroupItem, dimensions, removeGroup } = props;

  const { isOpen, toggleModal } = useModal();
  const { isOpen: isRemoveOpen, toggleModal: toggleRemoveModal } = useModal();

  const handleClick = () => toggleModal();

  const contentType = splitAndJoin(item.templates, ";", ", ");
  const hasContentType = !["null", ""].includes(contentType);
  const contentTypeText = hasContentType ? "Content Type" : "No Content Type";

  const groupOptions = [
    {
      label: "Delete",
      icon: "delete",
      action: toggleRemoveModal,
    },
  ];

  const mainRemoveModalAction = {
    title: "Delete Group",
    onClick: removeGroup,
  };

  const secondaryRemoveModalAction = { title: "Cancel", onClick: toggleRemoveModal };

  return (
    <>
      <S.Component onClick={handleClick}>
        <S.Name>{item.name}</S.Name>
        <S.ComponentInfo>
          <S.Values>{contentTypeText}{hasContentType && <S.ContentType>{contentType}</S.ContentType>}</S.Values>
          <S.StyledActionMenu icon="more" options={groupOptions} tooltip="Dimensions group actions" />
        </S.ComponentInfo>
      </S.Component>
      <GroupPanel item={item} isOpen={isOpen} toggleModal={toggleModal} setGroupItem={setGroupItem} dimensions={dimensions} />
      <Modal
        isOpen={isRemoveOpen}
        hide={toggleRemoveModal}
        title="Delete Group?"
        secondaryAction={secondaryRemoveModalAction}
        mainAction={mainRemoveModalAction}
        size="S"
      >
        <S.ModalContent>
          <p>
            Are you sure you want to delete <strong>{item.name} dimensions</strong>? If you delete it, you will no longer be able to use it.
            <br />
            This action <strong>cannot be undone</strong>.
          </p>
        </S.ModalContent>
      </Modal>
    </>
  );
};

interface IProps {
  item: IDimensionsGroup;
  setGroupItem(content: IDimensionsGroup): void;
  dimensions: IDimension[];
  removeGroup: () => void;
}

export default Item;
