import React from "react";

import SameComponentArray, { ISameComponentArrayProps } from "./SameComponentArray";
import MixableComponentArray, { IMixableComponentArrayProps } from "./MixableComponentArray";

const ComponentArraySelector = (props: ISameComponentArrayProps & IMixableComponentArrayProps): JSX.Element => {
  const { elementUniqueSelection = false } = props;
  const Component = elementUniqueSelection ? SameComponentArray : MixableComponentArray;

  return <Component {...props} />;
};

export default ComponentArraySelector;
