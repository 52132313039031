import React from "react";

import { CheckField, Button, ElementsTooltip } from "@ax/components";
import { useModal } from "@ax/hooks";

import { IRole } from "@ax/types";
import RolesModal from "./RolesModal";

import * as S from "./style";

const SiteItem = (props: ISiteItemProps): JSX.Element => {
  const { item, sites, roles, disabled = false, readOnly = false, addRoles, selected, featured, description } = props;
  const { name, id, onChange } = item;
  const isSelected = sites.includes(id);

  const { isOpen, toggleModal } = useModal();

  const handleClick = (e: React.MouseEvent) => {
    if (readOnly) return;
    e.preventDefault();
    e.stopPropagation();
    onChange(id);
  };

  const handleChange = () => onChange(id);

  const handleAddRoles = (roles: number[]) => addRoles && addRoles({ siteId: id, roles });

  const rolesString =
    roles && roles.filter((role: IRole) => selected && selected.includes(role.id)).map((role: IRole) => role.name);
  const colors =
    rolesString &&
    rolesString.reduce((prev, current) => {
      const color: IRole | undefined = roles.find((role: IRole) => role.name === current);
      return { ...prev, [current]: color?.hex };
    }, {});

  const textButton = selected && selected.length ? "Edit Roles" : "Add Roles";
  const iconButton = selected && selected.length ? "edit" : undefined;

  return (
    <>
      <S.SiteItem
        disabled={disabled}
        role="rowgroup"
        readOnly={readOnly}
        selected={!readOnly && isSelected}
        data-testid="user-site-item"
        isChecked={featured && isSelected}
      >
        {!readOnly ? (
          <S.CheckCell role="cell" featured={featured} onClick={handleClick} data-testid="check-cell">
            <CheckField disabled={disabled} name={name} value={id} checked={isSelected} onChange={handleChange} />
          </S.CheckCell>
        ) : null}
        {featured ? (
          <S.WrapperCell role="cell" data-testid="wrapper-cell-featured">
            <S.Name onClick={handleClick}>{name}</S.Name>
            <S.Description onClick={handleClick}>{description}</S.Description>
            <S.Actions hasTags={rolesString ? rolesString.length > 0 : false}>
              <ElementsTooltip
                elements={rolesString}
                maxChar={30}
                rounded={true}
                defaultElements={2}
                elementsPerRow={2}
                colors={colors}
              />
              {!readOnly && (
                <Button type="button" buttonStyle="text" onClick={toggleModal} icon={iconButton}>
                  {textButton}
                </Button>
              )}
            </S.Actions>
          </S.WrapperCell>
        ) : (
          <>
            <S.NameCell role="cell" disabled={disabled} onClick={handleClick} data-testid="name-cell">
              {name}
            </S.NameCell>
            <S.RolesCell role="cell" onClick={handleClick}>
              <ElementsTooltip
                elements={rolesString}
                maxChar={30}
                rounded={true}
                defaultElements={2}
                elementsPerRow={2}
                colors={colors}
              />
            </S.RolesCell>
            {!readOnly && (
              <S.ActionCell role="cell" data-testid="action-cell">
                <Button type="button" buttonStyle="text" onClick={toggleModal} icon={iconButton}>
                  {textButton}
                </Button>
              </S.ActionCell>
            )}
          </>
        )}
      </S.SiteItem>
      <RolesModal
        isOpen={isOpen}
        toggleModal={toggleModal}
        roles={roles}
        addRoles={handleAddRoles}
        selectedRoles={selected}
        isGlobal={id==="global"}
      />
    </>
  );
};

export interface ISiteItemProps {
  item: { name: string; id: number | string; onChange: (id: number | string) => void };
  sites: (string | number)[];
  roles?: IRole[];
  addRoles?(newRoles: any): void;
  selected?: number[];
  disabled?: boolean;
  readOnly?: boolean;
  featured?: boolean;
  description?: string;
}

export default SiteItem;
