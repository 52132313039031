import React, { useEffect, useCallback, useState, useRef } from "react";
import { connect } from "react-redux";

import { appActions } from "@ax/containers/App";
import { menuActions } from "@ax/containers/Navigation";
import { IRootState, IHeader, IFooter, ISite } from "@ax/types";
import { useBulkSelection, usePermission, useToast, useModal } from "@ax/hooks";
import { capitalize, isMultipleNavigationModules } from "@ax/helpers";
import { navigationActions } from "@ax/containers/Navigation";
import { MainWrapper, TableList, ErrorToast, Toast, Notification } from "@ax/components";

import DefaultItem from "./Item";
import DefaultNav from "./Nav";
import BulkHeader from "./BulkHeader";
import { NavigationModulesWarning } from "./atoms";
import ReplaceNavModal from "./ReplaceNavModal";

import * as S from "./style";

const DefaultsList = (props: IProps): JSX.Element => {
  const {
    currentDefaultsContent,
    lang,
    siteLanguages,
    setLanguage,
    totalItems,
    setSelectedDefault,
    getHeaders,
    getFooters,
    selectedDefault,
    setHistoryPush,
    setHeader,
    setFooter,
    restoreNavigation,
    deleteNavigation,
    getMenus,
    resetDefaultsValues,
    currentSiteInfo,
  } = props;

  const [page, setPage] = useState(1);
  const [deletedNav, setDeletedNav] = useState<number | number[] | null>(null);
  const [updatedPages, setUpdatedPages] = useState<number | null>(null);
  const { isVisible, toggleToast, setIsVisible } = useToast();
  const { isVisible: isPagesVisible, toggleToast: togglePagesToast, setIsVisible: setIsPagesVisible } = useToast();
  const [isScrolling, setIsScrolling] = useState(false);
  const tableRef = useRef<HTMLDivElement>(null);
  const [isNavigationNotificationOpen, setIsNavigationNotificationOpen] = useState(false);
  const { isOpen: isReplaceOpened, toggleModal: toggleReplaceModal } = useModal();

  const isAllowedToCreateHeaders = usePermission("navigation.createSiteHeaders") && selectedDefault === "Headers";
  const isAllowedToCreateFooters = usePermission("navigation.createSiteFooters") && selectedDefault === "Footers";
  const isAllowedToDeleteHeaders = usePermission("navigation.deleteSiteHeaders") && selectedDefault === "Headers";
  const isAllowedToDeleteFooters = usePermission("navigation.deleteSiteFooters") && selectedDefault === "Footers";

  const navIds = currentDefaultsContent && currentDefaultsContent.map((nav: any) => nav.id);

  const {
    resetBulkSelection,
    selectedItems,
    isSelected,
    areItemsSelected,
    checkState,
    addToBulkSelection,
    selectAllItems,
    setHoverCheck,
  } = useBulkSelection(navIds);

  const itemsPerPage = 50;
  const defaultTypes = ["Headers", "Footers"];

  const currentType = selectedDefault === "Headers" ? "header" : "footer";

  const showNavigationModulesWarning = isMultipleNavigationModules();

  const getParams = useCallback(() => {
    return {
      page,
      itemsPerPage,
      pagination: true,
      deleted: false,
      include_draft: false,
    };
  }, [page]);

  const getContents = useCallback((selectedDefault: string) => {
    const params: any = getParams();
    params.selectedDefault = selectedDefault;
    selectedDefault === "Headers" ? getHeaders(params) : getFooters(params);
    setSelectedDefault(selectedDefault);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getContents(selectedDefault);
    if (tableRef.current) {
      tableRef.current.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const memoizedGetMenus = useCallback(() => getMenus(), [getMenus]);

  useEffect(() => {
    resetDefaultsValues();
    memoizedGetMenus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isNavigationModulesChanged =
      currentSiteInfo &&
      currentSiteInfo.navigationModules?.[currentType] &&
      currentDefaultsContent.some(
        (navigation) => navigation.component !== currentSiteInfo?.navigationModules[currentType]
      );
    setIsNavigationNotificationOpen(isNavigationModulesChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDefaultsContent]);

  const handleClick = (selectedDefault: string) => {
    unselectAllItems();
    getContents(selectedDefault);
  };

  const setContent = (item: any) => {
    const { type, id } = item;
    const isHeader = type === "header";
    isHeader ? setHeader(id) : setFooter(id);
  };

  const addNewDefault = () => {
    setHeader(null);
    setFooter(null);
    const path = "/sites/navigations/editor";
    setHistoryPush(path, true);
  };

  const rightButtonProps =
    isAllowedToCreateHeaders || isAllowedToCreateFooters
      ? {
          label: "New",
          action: () => addNewDefault(),
        }
      : undefined;

  const languageActions = {
    setLanguage,
  };

  const pagination = {
    setPage,
    itemsPerPage,
    totalItems,
    currPage: page,
  };

  const undoAction = () => {
    if (deletedNav) {
      restoreNavigation(deletedNav, currentType);
    }
    setIsVisible(false);
  };

  const toastProps = {
    action: () => undoAction(),
    setIsVisible,
    message: deletedNav ? `${capitalize(currentType)} deleted` : "",
  };

  const toastPagesProps = {
    setIsVisible: setIsPagesVisible,
    message: `${updatedPages} pages updated with new ${currentType}s.`,
  };

  const handleToast = (pages: number) => {
    setUpdatedPages(pages);
    togglePagesToast();
  };

  const handleErrorAction = () => toggleReplaceModal();

  const bulkDelete = async () => {
    const deleted = await deleteNavigation(selectedItems.all, currentType, handleErrorAction);
    setDeletedNav(selectedItems.all);
    if (deleted) {
      toggleToast();
    }
  };

  const unselectAllItems = () => resetBulkSelection();

  const selectItems = () => (checkState.isAllSelected ? unselectAllItems() : handleSelectAll());

  const handleSelectAll = () => selectAllItems();

  const onScroll = (e: any) => setIsScrolling(e.target.scrollTop > 0);

  const bulkActions =
    isAllowedToDeleteFooters || isAllowedToDeleteHeaders
      ? [
          {
            icon: "delete",
            text: "delete",
            action: bulkDelete,
          },
        ]
      : [];

  const TableHeader = (
    <BulkHeader
      showBulk={areItemsSelected(navIds)}
      bulkActions={bulkActions}
      selectAllItems={handleSelectAll}
      totalItems={totalItems}
      selectItems={selectItems}
      checkState={checkState}
      isScrolling={isScrolling}
      setHoverCheck={setHoverCheck}
    />
  );

  const goToSiteSettings = () => setHistoryPush("/sites/settings/globals");

  return (
    <MainWrapper
      title="Navigation modules"
      rightButton={rightButtonProps}
      language={lang}
      availableLanguages={siteLanguages}
      languageActions={languageActions}
    >
      <S.DefaultListWrapper>
        <DefaultNav current={selectedDefault} defaultTypes={defaultTypes} onClick={handleClick} />
        <S.TableWrapper>
          <ErrorToast />
          {isNavigationNotificationOpen && (
            <Notification
              type="warning"
              text="The design of the navigation modules has changed. Please, check the previously created and save them."
            />
          )}
          {showNavigationModulesWarning && <NavigationModulesWarning goTo={goToSiteSettings} />}
          <TableList
            tableHeader={TableHeader}
            pagination={pagination}
            onScroll={onScroll}
            hasFixedHeader={true}
            tableRef={tableRef}
          >
            {currentDefaultsContent &&
              currentDefaultsContent.map((defaultContent: IHeader | IFooter) => {
                const isItemSelected = isSelected(defaultContent.id);
                return (
                  <DefaultItem
                    defaultContent={defaultContent}
                    key={defaultContent.id}
                    languages={siteLanguages}
                    lang={lang}
                    toggleToast={toggleToast}
                    setDeletedNav={setDeletedNav}
                    isSelected={isItemSelected}
                    onChange={addToBulkSelection}
                    handleClick={setContent}
                    setHistoryPush={setHistoryPush}
                    toggleReplaceModal={toggleReplaceModal}
                    hoverCheck={checkState.hoverCheck}
                  />
                );
              })}
          </TableList>
        </S.TableWrapper>
        {isVisible && <Toast {...toastProps} />}
        {isPagesVisible && <Toast {...toastPagesProps} />}
      </S.DefaultListWrapper>
      {isReplaceOpened && (
        <ReplaceNavModal
          isOpen={isReplaceOpened}
          toggleModal={toggleReplaceModal}
          type={currentType}
          navID={deletedNav}
          toggleToast={handleToast}
        />
      )}
    </MainWrapper>
  );
};

const mapStateToProps = (state: IRootState) => ({
  siteLanguages: state.sites.currentSiteLanguages,
  lang: state.app.lang,
  selectedDefault: state.navigation.selectedDefault,
  currentDefaultsContent: state.navigation.currentDefaultsContent,
  totalItems: state.navigation.totalItems,
  currentSiteInfo: state.sites.currentSiteInfo,
});

interface IDispatchProps {
  setLanguage(lang: { locale: string; id: number | null }): void;
  getHeaders(params: any): void;
  getFooters(params: any): void;
  setSelectedDefault(id: string): void;
  setHistoryPush(path: string, isEditor?: boolean): any;
  setHeader(id: number | null): void;
  setFooter(id: number | null): void;
  restoreNavigation(navID: number | number[], type: string): void;
  deleteNavigation(navID: number[], type: string, errorAction: () => void): Promise<boolean>;
  getMenus(): void;
  resetDefaultsValues(): void;
}

interface IDefaultsProps {
  lang: { locale: string; id: number | null };
  siteLanguages: any[];
  selectedDefault: string;
  currentDefaultsContent: (IHeader | IFooter)[];
  totalItems: number;
  currentSiteInfo: ISite | null;
}

type IProps = IDefaultsProps & IDispatchProps;

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
  setLanguage: appActions.setLanguage,
  getHeaders: navigationActions.getHeaders,
  getFooters: navigationActions.getFooters,
  setSelectedDefault: navigationActions.setSelectedDefault,
  setHeader: navigationActions.setHeader,
  setFooter: navigationActions.setFooter,
  restoreNavigation: navigationActions.restoreNavigation,
  deleteNavigation: navigationActions.deleteNavigation,
  getMenus: menuActions.getMenus,
  resetDefaultsValues: navigationActions.resetDefaultsValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultsList);
