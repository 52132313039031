const hex3to6 = (color: string) => {
  const hexcolor = color.replace('#', '');
  return "#" + hexcolor.split('').map((hex: string) => hex + hex).join('');
}

const defaultOptions = (vusOptions: Record<string, unknown>[]) => {
  return !vusOptions.find((entry) => entry.theme);
};

const defaultColors = [
  "#d9e3f0",
  "#f47373",
  "#697689",
  "#37d67a",
  "#2ccce4",
  "#555555",
  "#dce775",
  "#ff8a65",
  "#ba68c8",
];

const getOptions = (theme: string, isThemePalette: boolean | undefined, colorOptions: any[] | undefined) => {
  if (!colorOptions) return defaultColors;
  if (isThemePalette) return colorOptions;

  const themes = colorOptions.filter((entry) => entry.theme).map((entry) => entry.theme);
  const themeExists = themes.includes(theme);
  const defaultsOptions = colorOptions.filter((entry) => typeof entry === "string");

  // 1 - Si options solo tiene las defaults options (legacy) devuelve las defaults.
  // 2 - Si options no tiene el theme, devuelve las defaults.
  if (defaultOptions(colorOptions) || !themeExists) return defaultsOptions;

  // Si options tiene el theme, devuelve las options de ese theme.
  const optionsObj = colorOptions.find((entry) => entry.theme === theme);

  return optionsObj && optionsObj.options;
};


export { hex3to6, getOptions, defaultColors };