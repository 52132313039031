import React from "react";

const getImage = (name: string) => {
  const simpleIcons: any = {
    es: "Es_ES",
    en: "En_GB",
    de: "De_DE",
    fr: "Fr_FR",
    it: "It_IT",
    pt: "Pt_PT",
  };
  const filename = simpleIcons[name?.toLowerCase()] || name;

  try {
    return require(`./components/${filename}`).default;
  } catch (err) {
    return null;
  }
};
const Flag = (props: IProps) => {
  const name = props.name.charAt(0).toUpperCase() + props.name.slice(1);

  const size = props.size ? props.size : "24";

  const Svg = getImage(name);

  if (Svg) {
    return <Svg height={size} width={size} viewBox="0 0 512 512" />;
  }

  return null;
};

export interface IStateProps {
  name: string;
  size?: string;
}

type IProps = IStateProps;

export default Flag;
