import React, { memo } from "react";
import { Icon } from "@ax/components";

import * as S from "./style";

const EmptyPanel = () => {
  return (
    <S.NoDetailsWrapper>
      <S.IconWrapper>
        <Icon name="alert" size="32" />
      </S.IconWrapper>
      <S.Title>Select a package to see details</S.Title>
    </S.NoDetailsWrapper>
  );
};

export default memo(EmptyPanel);
