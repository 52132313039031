import React, { useState } from "react";

import { Modal, FieldsBehavior } from "@ax/components";
import { IMenuItem } from "@ax/types";

import * as S from "./style";

const RemoveModal = (props: IRemoveModalProps) => {
  const { isOpen, toggleModal, deleteItem, item } = props;

  const [removeItemState, setRemoveItemState] = useState("deleteItem");
  const deleteChildren = removeItemState === "deleteChildren";

  const mainRemoveAction = {
    title: "Delete",
    onClick: () => deleteItem(item, deleteChildren),
  };

  const secondaryRemoveAction = {
    title: "Cancel",
    onClick: toggleModal,
  };

  const removeItemOptions = [
    {
      title: "Delete the element",
      name: "deleteItem",
      value: "deleteItem",
    },
    {
      title: "Delete element and associated sections.",
      name: "deleteChildren",
      value: "deleteChildren",
    },
  ];

  const RemoveModal = () => (
    <S.ModalContent>
      <p>
        You are going to delete an element that have some associated sections. Choose if you want to delete the element
        and the sections or only this one.
      </p>
      <FieldsBehavior
        name="removeItemChildren"
        fieldType="RadioGroup"
        value={removeItemState}
        options={removeItemOptions}
        onChange={setRemoveItemState}
      />
    </S.ModalContent>
  );

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      size="S"
      title="Delete Item"
      mainAction={mainRemoveAction}
      secondaryAction={secondaryRemoveAction}
    >
      <RemoveModal />
    </Modal>
  );
};

interface IRemoveModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  deleteItem: (item: IMenuItem, deleteChildren: boolean) => void;
  item: IMenuItem;
}

export { RemoveModal };
