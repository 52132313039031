import styled from "styled-components";

const Date = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
`;

const Type = styled.div`
  ${(p) => p.theme.textStyle.headingXSS};
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.fieldContent};
`;

const Item = styled.li<{ disabled: boolean }>`
  display: flex;
  background-color: ${(p) => p.theme.color.uiBarBackground};
  border: ${(p) => `1px solid ${p.theme.color.uiLine}`};
  border-radius: ${(p) => p.theme.radii.s};
  padding: ${(p) => p.theme.spacing.s};
  margin-bottom: ${(p) => p.theme.spacing.xs};
  cursor: ${(p) => (p.disabled ? "default" : "pointer")};
  :hover {
    background-color: ${(p) => (p.disabled ? p.theme.color.uiBarBackground : p.theme.color.overlayHoverPrimary)};
  }
  :focus {
    background-color: ${(p) => p.theme.color.overlayFocusPrimary};
  }
  ${Date} {
    color: ${(p) => (p.disabled ? p.theme.color.uiLine : p.theme.color.textMediumEmphasis)};
  }
  ${Type} {
    color: ${(p) => (p.disabled ? p.theme.color.uiLine : p.theme.color.textLowEmphasis)};
  }
  ${Title} {
    color: ${(p) => (p.disabled ? p.theme.color.uiLine : p.theme.color.textHighEmphasis)};
  }
`;

const TextWrapper = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

export { Item, TextWrapper, Header, Date, Type, Title };
