import styled from "styled-components";

const EditorWrapper = styled.div<{ error: boolean | undefined; disabled: boolean | undefined }>`
  pointer-events: ${(p) => (p.disabled ? "none" : "auto")};
  color: ${(p) => (p.disabled ? p.theme.color.interactiveDisabled : p.theme.color.textHighEmphasis)};
  img {
    opacity: ${(p) => (p.disabled ? 0.5 : 1)};
  }

  .richTextEditor {
    ${(p) => p.theme.textStyle.fieldContent};
    background-color: ${(p) => p.theme.color.uiBackground02};
    border: 1px solid
      ${(p) =>
        p.error === true ? p.theme.color.error : p.disabled ? p.theme.color.interactiveDisabled : p.theme.color.uiLine};
    border-radius: ${(p) => p.theme.radii.s};
    color: ${(p) => (p.disabled ? p.theme.color.interactiveDisabled : p.theme.color.textHighEmphasis)};
    padding: ${(p) => `0 ${p.theme.spacing.s}`};
    min-height: ${(p) => `calc(${p.theme.spacing.l} * 3)`};
    max-height: ${(p) => `calc(${p.theme.spacing.xl} * 10)`};
    width: 100%;
    overflow: auto;

    &:active,
    &:focus,
    &:focus-within {
      outline: none;
      border-color: ${(p) => (p.error === true ? p.theme.color.error : p.theme.color.interactive01)};
    }

    h1 {
      font-size: 22px;
      font-weight: 800;
      line-height: 25px;
    }

    h2 {
      font-size: 20px;
      font-weight: 400;
      line-height: 25px;
    }

    h3 {
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
    }

    h4 {
      font-size: 17px;
      font-weight: 600;
    }

    a {
      color: blue;
      text-decoration: underline;
    }
  }

  .DraftEditor-root {
    .DraftEditor-editorContainer {
      z-index: 0;
    }
  }

  .richTextToolbar {
    padding: 0;
    background: transparent;
    margin-bottom: ${(p) => p.theme.spacing.xs};

    .rdw-inline-wrapper,
    .rdw-list-wrapper,
    .rdw-link-wrapper,
    .rdw-block-wrapper {
      margin-bottom: 0;
      height: 24px;
    }

    .rdw-option-wrapper  {
      min-width: 24px;
      height: 24px;
      background: transparent;
      border-radius: ${(p) => p.theme.radii.s};
      margin: ${(p) => `0 ${p.theme.spacing.xs} 0 0`};
      :hover {
        background: ${(p) => p.theme.color.overlayHoverPrimary};
        box-shadow: none;
      }
    }

    .rdw-option-active {
      background: ${(p) => p.theme.color.overlayHoverPrimary};
      box-shadow: none;
    }

    .richTextBlockType {
      height: 24px;
      margin: ${(p) => `0 ${p.theme.spacing.xs} 0 0`};
      :hover {
        box-shadow: none;
      }
      ul {
        overflow-y: auto;
        width: 115px;
        box-shadow: ${(p) => p.theme.shadow.shadowL};
        border-radius: ${(p) => p.theme.radii.s};
        padding: ${(p) => `${p.theme.spacing.xs} 0`};
        :hover {
          box-shadow: ${(p) => p.theme.shadow.shadowL};
        }
        li {
          padding: ${(p) => `${p.theme.spacing.xs}`};
        }
        li:nth-child(2) {
          font-size: 22px;
          font-weight: 700;
        }
        li:nth-child(3) {
          font-size: 20px;
          font-weight: 400;
        }
        li:nth-child(4) {
          font-size: 18px;
          font-weight: 700;
        }
        li:nth-child(5) {
          font-size: 17px;
          font-weight: 600;
        }
      }

      .rdw-dropdown-carettoopen {
        border-top: 6px solid ${(p) => (p.disabled ? p.theme.color.interactiveDisabled : "black")};
      }

      .rdw-dropdownoption-active {
        background: ${(p) => p.theme.color.overlayPressedPrimary};
      }
      .rdw-dropdownoption-highlighted {
        background: ${(p) => p.theme.color.overlayHoverPrimary};
      }
    }

    .richTextLinkModal {
      padding: ${(p) => p.theme.spacing.s};
      height: auto;
      left: -30px;
      box-shadow: ${(p) => p.theme.shadow.shadowL};
      border-radius: ${(p) => p.theme.radii.s};

      label {
        ${(p) => p.theme.textStyle.fieldLabel};
        color: ${(p) => p.theme.color.textMediumEmphasis};
      }
      .rdw-link-modal-input {
        border: ${(p) => `1px solid ${p.theme.color.uiLine}`};
        border-radius: ${(p) => p.theme.radii.s};
        padding: ${(p) => `${p.theme.spacing.s} ${p.theme.spacing.xs}`};
        height: ${(p) => p.theme.spacing.m};
      }
      .rdw-link-modal-buttonsection {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        button {
          ${(p) => p.theme.textStyle.uiButton};
          background-color: ${(p) => p.theme.color.interactive01};
          border-radius: 4px;
          border: none;
          color: #ffffff;
          min-height: calc(${(p) => p.theme.spacing.s} * 2);
          padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.s};
          vertical-align: middle;
          cursor: pointer;
          position: relative;
          :before {
            content: "";
            border-radius: 4px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 0.1s;
          }
          :focus {
            outline: 1px solid ${(p) => p.theme.color.interactive02};
          }
          :hover {
            box-shadow: none;
          }
          :hover:before {
            background-color: ${(p) => p.theme.color.overlayHoverDark};
            color: #ffffff;
            opacity: 1;
          }
          :focus:before {
            background-color: ${(p) => p.theme.color.overlayFocusDark};
            opacity: 1;
          }
          :active:before {
            background-color: ${(p) => p.theme.color.overlayPressedDark};
            opacity: 1;
          }
          :disabled {
            cursor: default;
            background-color: ${(p) => p.theme.color.interactiveDisabled};
            :hover:before,
            :focus:before,
            :active:before {
              background-color: transparent;
            }
          }
        }
        button:nth-child(1) {
          order: 2;
        }
        button:nth-child(2) {
          order: 1;
          background-color: transparent;
          color: ${(p) => p.theme.color.interactive01};
          padding: 0 ${(p) => p.theme.spacing.xs};
          :hover:before {
            background-color: ${(p) => p.theme.color.overlayHoverPrimary};
          }
          :focus:before {
            background-color: ${(p) => p.theme.color.overlayFocusPrimary};
          }
          :active:before {
            background-color: ${(p) => p.theme.color.overlayPressedPrimary};
          }
        }
      }
    }
  }
`;

export { EditorWrapper };
