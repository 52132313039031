import type { ISOLocale, Locale, Page } from "../../types/core";

import * as React from "react";

import { I18nProvider } from "../I18n";
import { SiteContext } from "../Site";

const defaultValue: PageContextProps = {} as const;

const PageContext = React.createContext<PageContextProps>(defaultValue);

/**
 * `<PageProvider>`
 *
 * This provider component inject some props in the context of the page. Then you can retrieve the context values using the `usePage()` hook.
 */
function PageProvider(props: PageProviderProps): JSX.Element {
	// extract children and use ...rest for the rest of the props
	const { children, ...rest } = props;
	const siteContextValue = React.useContext(SiteContext);
	const translations = siteContextValue?.translations;

	return (
		<PageContext.Provider value={{ ...rest }}>
			<I18nProvider translations={translations}>{children}</I18nProvider>
		</PageContext.Provider>
	);
}

type PageProps = Pick<
	Page,
	| "activeSectionBase"
	| "activeSectionSlug"
	| "apiUrl"
	| "breadcrumb"
	| "canonicalURL"
	| "componentList"
	| "dimensions"
	| "firstModule"
	| "footer"
	| "footerTheme"
	| "fullPath"
	| "fullUrl"
	| "header"
	| "headerTheme"
	| "id"
	| "isHome"
	| "isIndexed"
	| "languageId"
	| "metaDescription"
	| "metasAdvanced"
	| "metaTitle"
	| "modified"
	| "origin"
	| "pageLanguages"
	| "published"
	| "sectionModules"
	| "site"
	| "siteMetadata"
	| "siteSlug"
	| "socialDescription"
	| "socialImage"
	| "socialTitle"
	| "structuredData"
	| "structuredDataContent"
	| "template"
	| "theme"
	| "title"
>;

export type PageContextProps = Partial<PageProps> & {
	/** The locale code for the page language */
	locale?: Locale;
	/** The locale code (ISO format) for the page language */
	ISOLocale?: ISOLocale;
};

export interface PageProviderProps extends PageProps {
	children: JSX.Element;
}

export { PageContext, PageProvider };
