import * as React from "react";

function SvgSendEmail(props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2h-9v-2h9V8l-8 5-8-5v5H3V6c0-1.1.9-2 2-2zm8 7l8-5H5l8 5zm-7 4l-4 4 4 4v-3h4v-2H6v-3z"
        fill="#5057FF"
      />
    </svg>
  );
}

export default SvgSendEmail;
