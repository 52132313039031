import styled from "styled-components";
import { Cell, Row } from "@ax/components/TableList/TableItem/style";

const RadioCell = styled(Cell)`
  padding: ${(p) => p.theme.spacing.s};
  width: 32px;
  justify-content: flex-start;
  label {
    margin-bottom: ${(p) => p.theme.spacing.s};
  }
`;

const OptionItem = styled(Row)<{ selected: boolean; readOnly?: boolean }>`
  border: ${(p) => (p.selected ? `2px solid ${p.theme.color.interactive01}` : `1px solid ${p.theme.color.uiLine}`)};
  :before {
    background-color: transparent;
  }
  &:hover {
    cursor: ${(p) => (p.readOnly ? "default" : "pointer")};
    background-color: ${(p) =>
      p.readOnly
        ? p.theme.color.uiBackground02
        : p.selected
        ? p.theme.color.overlayPressedPrimary
        : p.theme.color.overlayHoverPrimary};
    &:before {
      background-color: ${(p) => (p.readOnly ? "transparent" : p.theme.color.overlayHoverPrimary)};
    }
  }
`;

const WrapperCell = styled(Cell)`
  width: 100%;
`;

const Name = styled.div`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const Description = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

export { RadioCell, OptionItem, WrapperCell, Name, Description };
