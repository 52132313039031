import styled from "styled-components";
import { Cell, Row } from "@ax/components/TableList/TableItem/style";
import { ActionMenu } from "@ax/components";

const StyledActionMenu = styled(ActionMenu)`
  opacity: 0;
`;

const SiteItemRow = styled(Row)<{ global?: boolean }>`
  &:hover {
    ${StyledActionMenu} {
      opacity: 1;
    }
  }
`;

const CheckCell = styled(Cell)`
  label {
    margin-bottom: ${(p) => p.theme.spacing.s};
  }
  > div {
    width: ${(p) => p.theme.spacing.s};
  }
`;

const SiteWrapper = styled.div`
  background: ${(p) => p.theme.color.uiBackground02};
  height: 252px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${(p) => p.theme.spacing.s};
  cursor: pointer;
`;

const ThumbailCell = styled(Cell)`
  padding: 8px 0;
  img {
    width: 64px;
    height: 48px;
  }
`;

const NameCell = styled(Cell)`
  min-width: 374px;
  max-width: 374px;
  svg {
    height: ${(p) => p.theme.spacing.s};
    path {
      fill: ${(p) => p.theme.color.iconMediumEmphasis};
    }
  }
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => p.theme.color.textHighEmphasis};
  display: flex;
  align-items: center;
`;

const UrlCell = styled(Cell)`
  flex-grow: 1;
  align-items: right;
`;

const LiveCell = styled(Cell)`
  flex: 0 0 100px;
  align-items: center;
  width: 100px;
  svg {
    width: ${(p) => p.theme.spacing.m};
    height: ${(p) => p.theme.spacing.m};
  }
`;
const DateCell = styled(Cell)`
  flex: 0 0 200px;
  align-items: center;
`;

const ActionsCell = styled(Cell)`
  flex: 0;
  text-align: center;
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};

  p:nth-child(1) {
    margin-bottom: ${(p) => p.theme.spacing.m};
  }

  p:nth-child(2) {
    margin-bottom: ${(p) => p.theme.spacing.xxs};
  }
`;

export {
  StyledActionMenu,
  SiteItemRow,
  CheckCell,
  SiteWrapper,
  ThumbailCell,
  NameCell,
  Title,
  UrlCell,
  LiveCell,
  DateCell,
  ActionsCell,
  ModalContent,
};
