import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;

  height: 100vh;
  background: ${(p) => p.theme.colors.uiBackground01};
  box-shadow: ${(p) => p.theme.shadow.rightPanel};
  padding-bottom: calc(${(p) => p.theme.spacing.m} * 2);
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${(p) => p.theme.spacing.xl};
  width: 100%;
  padding: 0 ${(p) => p.theme.spacing.m};
  background-color: ${(p) => p.theme.colors.uiBackground02};
  border-bottom: 1px solid ${(p) => p.theme.colors.uiLine};
  h6 {
    ${(p) => p.theme.textStyle.headingM}
    color: ${(p) => p.theme.colors.textHighEmphasis};
    text-transform: capitalize;
  }
`;

const Title = styled.h6``;

const Content = styled.div`
  list-style: none;
  padding: ${(p) => p.theme.spacing.m};
  height: ${(p) => `calc(100vh - (${p.theme.spacing.xl} * 2))`};
  width: ${(p) => `calc(${p.theme.spacing.xl} * 3)`};
  overflow: auto;
  border-right: 1px solid ${(p) => p.theme.colors.uiLine};
  &:last-child {
    border-right: 0;
    width: ${(p) => `calc(${p.theme.spacing.xl} * 4)`};
  }
`;

const ButtonWrapper = styled.div`
  margin: 0 0 0 auto;
`;

export { Wrapper, Content, Header, Title, ButtonWrapper };
