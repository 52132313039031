import React from "react";
import styled from "styled-components";
import ActionMenu from "@ax/components/ActionMenu";

const Wrapper = styled.div<{ isOpen: boolean }>`
  background-color: ${(p) => p.theme.color.uiBarBackground};
  margin-bottom: ${(p) => p.theme.spacing.xs};
  border: 1px solid ${(p) => (p.isOpen ? p.theme.color.interactive01 : p.theme.color.uiLine)};
  border-radius: ${(p) => p.theme.radii.s};
`;

const StyledActionMenu = styled((props) => <ActionMenu {...props} />)`
  width: ${(p) => p.theme.spacing.m};
  height: ${(p) => p.theme.spacing.m};
  display: flex;
  margin-left: auto;
`;

const Title = styled.div<{ isOpen: boolean }>`
  position: relative;
  display: flex;
  ${(p) => p.theme.textStyle.fieldContent};
  color: ${(p) => p.theme.colors.textHighEmphasis};
  padding: ${(p) => p.theme.spacing.s};
  cursor: pointer;
  text-transform: capitalize;
  align-items: center;
  width: 100%;
  :after {
    position: absolute;
    right: 55px;
    top: ${(p) => (p.isOpen ? `28px` : `24px`)};
    content: "";
    border: solid ${(p) => p.theme.color.interactive01};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: ${(p) => (p.isOpen ? `rotate(-135deg)` : `rotate(45deg)`)};
  }
`;

const Content = styled.div<{ isOpen: boolean }>`
  display: ${(p) => (p.isOpen ? "block" : "none")};
  max-height: ${(p) => (p.isOpen ? `auto` : 0)};
  transition: all 0.5s ease-in-out;
  padding: ${(p) => `0 ${p.theme.spacing.s}`};
`;

export { Wrapper, Title, Content, StyledActionMenu };
