import React, { memo } from "react";
import { IconAction, Tooltip } from "@ax/components";

const AddItemButton = (props: IProps) => {
  const { handleClick, tooltipText } = props;

  return (
    <Tooltip content={tooltipText} hideOnClick>
      <IconAction icon="add" onClick={handleClick} />
    </Tooltip>
  );
};

interface IProps {
  handleClick: any;
  tooltipText: string;
}

export default memo(AddItemButton);
