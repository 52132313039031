import React from "react";

const SvgPaste = props => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      d="M7 16h7v2H7v-2zm0-4h10v2H7v-2zm0-4h10v2H7V8zm12-4h-4.18C14.4 2.84 13.3 2 12 2c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 20H5V6h14v14z"
      fill="#5057FF"
    />
  </svg>
);

export default SvgPaste;
