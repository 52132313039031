import React from "react";

const SvgLock = (props) => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      fill="#20224C"
      fillOpacity="0.2"
      d="M18.9,8h-1.1V5.7C17.7,2.6,15.2,0,12,0S6.3,2.6,6.3,5.7V8H5.1c-1.3,0-2.3,1-2.3,2.3v11.4c0,1.3,1,2.3,2.3,2.3
 h13.7c1.3,0,2.3-1,2.3-2.3V10.3C21.1,9,20.1,8,18.9,8z M8.6,5.7c0-1.9,1.5-3.4,3.4-3.4s3.4,1.5,3.4,3.4V8H8.6V5.7z M18.9,21.7H5.1
 V10.3h13.7V21.7z M12,18.3c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3s-2.3,1-2.3,2.3C9.7,17.3,10.7,18.3,12,18.3z"
    />
  </svg>
);

export default SvgLock;
