import { toBlob } from "html-to-image";

const getStateValues = (getState: any) => {
  const {
    sites: { currentSiteInfo },
    navigation: {
      editorContent,
      selectedDefault,
      selectedEditorID,
      breadcrumb,
      header,
      footer,
      isNewTranslation,
      currentDefaultsContent,
      selectedContent,
    },
    app: { isSaving, isLoading },
  } = getState();
  const section = editorContent && editorContent[0];
  const { modules } = !!section && section;

  return {
    currentSiteInfo,
    editorContent,
    selectedDefault,
    selectedEditorID,
    breadcrumb,
    header,
    footer,
    isNewTranslation,
    currentDefaultsContent,
    isSaving,
    isLoading,
    section,
    modules,
    selectedContent,
  };
};

const getFormData = (values: any, image?: File | null): FormData => {
  const form = new FormData();
  form.append("navigation", JSON.stringify(values));
  image && form.append("file", image);
  return form;
};

const getImage = async (navHtml: HTMLDivElement): Promise<File | null> => {
  const browserContent = navHtml.querySelector<HTMLElement>(".browser-content");

  if (!browserContent) {
    return null;
  }

  const { height, overflow } = getComputedStyle(browserContent);
  browserContent.style.height = "auto";
  browserContent.style.overflow = "visible";
  const originalHeight = browserContent.clientHeight;

  const elChildren = browserContent.querySelectorAll("*");
  let maxAbsoluteElementHeight = 0;
  [].forEach.call(elChildren, function (element: any) {
    const isAbsolutePosition = getComputedStyle(element).position === "absolute";
    const isMaxAbsolutePositionHeight = element.clientHeight > maxAbsoluteElementHeight;
    if (isAbsolutePosition && isMaxAbsolutePositionHeight) {
      maxAbsoluteElementHeight = element.clientHeight;
    }
  });
  if (maxAbsoluteElementHeight) {
    const actualHeight = originalHeight + maxAbsoluteElementHeight;
    browserContent.style.height = `${actualHeight}px`;
  }

  return toBlob(browserContent, { quality: 0.95, pixelRatio: 0.5 })
    .then((imageBlob) => {
      return imageBlob && new File([imageBlob], "navigation-thumbnail.png", { type: "image/png" });
    })
    .finally(() => {
      browserContent.style.height = height;
      browserContent.style.overflow = overflow;
    })
    .catch((err) => {
      //console.log(err);
      return null;
    });
};

export { getStateValues, getFormData, getImage };
