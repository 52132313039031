import React from "react";

import { IBreadcrumbItem } from "@ax/types";
import { Icon } from "@ax/components";

import * as S from "./style";

const Breadcrumb = ({ breadcrumb, setSelectedContent }: IBreadcrumbProps): JSX.Element => {
  const isRoot = breadcrumb.length <= 1;
  const isPageSection = (i: number, component: string) => i === 1 && component === "Section";

  return (
    <S.BreadcrumbGroup data-testid="breadcrumb-group-wrapper">
      {!isRoot &&
        breadcrumb.slice(0).map((item: IBreadcrumbItem, index: number, arr: any) => {
          const { editorID, component, displayName } = item;
          const isLastItem = breadcrumb.length === index + 1;
          const parentComponent = index >= 1 ? breadcrumb[index - 1].component : null;
          const handleClick = () => setSelectedContent(editorID, parentComponent);

          return isPageSection(index, component) ? null : (
            <React.Fragment key={`${editorID}${index}`}>
              <S.BreadcrumbItem data-testid="breadcrumb-item" onClick={handleClick} isLastItem={isLastItem}>
                {displayName}
              </S.BreadcrumbItem>
              {index <= arr.length - 2 && (
                <S.Icon>
                  <Icon name="RightArrow" size="16px" />
                </S.Icon>
              )}
            </React.Fragment>
          );
        })}
    </S.BreadcrumbGroup>
  );
};

export interface IBreadcrumbProps {
  breadcrumb: IBreadcrumbItem[];
  setSelectedContent: any;
}

export default Breadcrumb;
