import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import * as S from "./style";

const LoginSlider = (props: ILoginSliderProps): JSX.Element => {
  const { className } = props;

  const customSlider = useRef<any>(null);

  const beforeChange = (prev: number, next: number) => {
    const prevSlideElement = customSlider.current.innerSlider.list.querySelector(`[data-index="${prev}"]`);
    const nextSlideElement = customSlider.current.innerSlider.list.querySelector(`[data-index="${next}"]`);

    setTimeout(() => {
      prevSlideElement.classList.add("prev-slide-anim");
      nextSlideElement.classList.remove("prev-slide-anim");
    }, 100);
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    draggable: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange,
  };

  const handleNext = () => customSlider.current?.slickNext();
  const handlePrev = () => customSlider.current?.slickPrev();

  const Banner = ({ text, image }: any) => (
    <S.BannerWrapper>
      <S.Title>{text}</S.Title>
      <S.Image image={image} />
    </S.BannerWrapper>
  );

  return (
    <S.Wrapper className={className}>
      <S.Buttons>
        <S.Arrow onClick={handlePrev} direction="left" />
        <S.Arrow onClick={handleNext} direction="right" />
      </S.Buttons>
      <Slider {...settings} ref={(slider) => (customSlider.current = slider)}>
        <Banner text="Full independence to publish content at any time" image="/img/slider/content.png" />
        <Banner text="Thousands of possibilities, literally" image="/img/slider/editor.png" />
        {/*<Banner text="Optimized images without any effort" image="/img/slider/gallery.png" />*/}
        <Banner text="Comprehensive integration with Analytics" image="/img/slider/analytics.png" />
      </Slider>
    </S.Wrapper>
  );
};

export interface ILoginSliderProps {
  className: string;
}

export default LoginSlider;
