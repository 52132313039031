import styled from "styled-components";

const placeRight = ["components", "headers", "footers"];

const Wrapper = styled.div<{ optionsType?: string }>`
  position: fixed;
  left: ${(p) => (p.optionsType && placeRight.includes(p.optionsType) ? "unset" : 0)};
  right: ${(p) => (p.optionsType && placeRight.includes(p.optionsType) ? 0 : "unset")};
  top: 0;
  z-index: 1000;

  height: 100vh;
  background: ${(p) => p.theme.colors.uiBackground01};
  box-shadow: ${(p) =>
    p.optionsType && placeRight.includes(p.optionsType) ? p.theme.shadow.rightPanel : p.theme.shadow.leftPanel};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${(p) => p.theme.spacing.xl};
  width: 100%;
  padding: 0 ${(p) => p.theme.spacing.m};
  background-color: ${(p) => p.theme.colors.uiBackground02};
  border-bottom: 1px solid ${(p) => p.theme.colors.uiLine};
  h6 {
    ${(p) => p.theme.textStyle.headingM}
    color: ${(p) => p.theme.colors.textHighEmphasis};
    text-transform: capitalize;
  }
`;

const Title = styled.h6``;

const ColumnsWrapper = styled.div`
  display: flex;
`;

const Content = styled.div`
  list-style: none;
  padding: ${(p) => p.theme.spacing.m};
  height: ${(p) => `calc(100vh - ${p.theme.spacing.xl})`};
  width: ${(p) => `calc(${p.theme.spacing.xl} * 3)`};
  overflow: auto;
  border-right: 1px solid ${(p) => p.theme.colors.uiLine};
  &:last-child {
    border-right: 0;
    width: ${(p) => `calc(${p.theme.spacing.xl} * 4)`};
  }
`;

const NavLink = styled.a``;

const FeaturedWrapper = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.colors.uiLine};
  padding-bottom: ${(p) => p.theme.spacing.xs};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const SearchWrapper = styled.div``;

const Link = styled.div<{ active: boolean }>`
  color: ${(p) => (p.active ? p.theme.color.textHighEmphasis : p.theme.color.textMediumEmphasis)};
`;

const ButtonWrapper = styled.div`
  margin: 0 0 0 auto;
`;

export {
  Wrapper,
  Content,
  Header,
  Title,
  ColumnsWrapper,
  NavLink,
  FeaturedWrapper,
  SearchWrapper,
  Link,
  ButtonWrapper,
};
