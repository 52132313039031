import styled from "styled-components";
import { Header } from "@ax/components/TableList/style";

const TableHeader = styled.div<{ isScrolling?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: ${(p) => p.theme.spacing.m};
  border-bottom: ${(p) => (p.isScrolling ? `1px solid ${p.theme.color.uiLine};` : "")};
`;

const CheckHeader = styled(Header)`
  padding-left: ${(p) => p.theme.spacing.m};
  width: 32px;
`;

const NameWrapper = styled.div`
  flex-grow: 1;
  position: relative;
`;

const ActionsHeader = styled(Header)`
  width: 64px;
  padding-right: 0;
`;

const SiteWrapper = styled.div`
  width: 210px;
  position: relative;
`;

const RolesWrapper = styled.div<{ isSite: boolean }>`
  width: ${(p) => (p.isSite ? "450px" : "275px")};
  position: relative;
`;

const StatusHeader = styled(Header)`
  width: 98px;
`;

export { TableHeader, CheckHeader, NameWrapper, ActionsHeader, RolesWrapper, SiteWrapper, StatusHeader };
