import React, { memo } from "react";
import { IconAction, SideModal, Tooltip } from "@ax/components";

const AddItemButton = (props: IProps) => {
  const { handleClick, whiteList, isModuleArr, categories, theme, isOpen, toggleModal } = props;
  const optionsType = isModuleArr ? "modules" : "components";
  const addAction = whiteList.length <= 1 ? () => handleClick(whiteList[0]) : toggleModal;

  const tooltip = isModuleArr ? "Add module" : "Add component";

  return (
    <>
      <Tooltip content={tooltip} hideOnClick>
        <IconAction icon="add" onClick={addAction} />
      </Tooltip>
      <SideModal
        optionsType={optionsType}
        whiteList={whiteList}
        categories={categories}
        toggleModal={toggleModal}
        isOpen={isOpen}
        handleClick={handleClick}
        theme={theme}
        showSearch
      />
    </>
  );
};

interface IProps {
  handleClick: any;
  whiteList: any[];
  isModuleArr: boolean;
  categories?: any;
  theme: string;
  toggleModal: () => void;
  isOpen: boolean;
}

export default memo(AddItemButton);
