import styled from "styled-components";

const Wrapper = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const MenuWrapper = styled.div<{ isInAppBar?: boolean; position: string; offset?: number }>`
  position: absolute;
  z-index: 7;
  padding-top: ${(p) => (p.isInAppBar ? p.theme.spacing.s : p.theme.spacing.xs)};
  margin-right: ${(p) => (p.isInAppBar ? p.theme.spacing.s : "0")};
  ${(p) => p.position}: ${(p) => (p.offset ? `${p.offset}px` : "0")};
`;

const Menu = styled.div`
  padding: ${(p) => p.theme.spacing.xs} 0;
  max-width: ${(p) => `calc(${p.theme.spacing.l} * 5)`};
  min-width: ${(p) => `calc(${p.theme.spacing.m} * 7)`};
  background: ${(p) => p.theme.color.uiBackground02};
  box-shadow: ${(p) => p.theme.shadow.shadowL};
  border-radius: ${(p) => p.theme.spacing.xxs};
`;

export { Wrapper, ButtonWrapper, MenuWrapper, Menu };
