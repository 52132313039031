import React, { useEffect, useRef, useState } from "react";
import { useHandleClickOutside } from "@ax/hooks";

import * as S from "./style";

const Tooltip = (props: ITooltipProps): JSX.Element => {
  const { content, children, hideOnClick = true, bottom, left, expanded, top } = props;

  const initialState: IState = {
    active: false,
    childrenWidth: 0,
    clicked: false,
    fixOutOfBounds: 0,
  };
  const [state, setState] = useState(initialState);
  const { active, childrenWidth, clicked, fixOutOfBounds } = state;

  const changeState = (value: any) => {
    setState((state) => ({ ...state, ...value }));
  };

  const childrenRef = useRef<HTMLDivElement>(null);
  const tipRef = useRef<HTMLDivElement>(null);

  let timeout: any;

  const showTip = () => {
    if (!clicked) {
      timeout = setTimeout(() => {
        changeState({ active: true });
      }, 1000);
    }
  };

  const hideTip = () => {
    clearInterval(timeout);
    changeState({ active: false });
  };

  const handleClick = () => {
    if (hideOnClick) {
      changeState({ clicked: !clicked });
      hideTip();
    }
  };

  const handleClickOutside = (e: any) => {
    if (childrenRef.current && childrenRef.current.contains(e.target)) {
      return;
    }
    changeState({ clicked: false });
  };

  useHandleClickOutside(clicked, handleClickOutside);

  useEffect(() => {
    if (childrenRef.current && childrenRef.current.children[0]) {
      const childrenWidth = childrenRef.current.children[0].clientWidth;
      changeState({ childrenWidth });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenRef]);

  useEffect(() => {
    if (active && tipRef.current) {
      const clientRect = tipRef.current.getBoundingClientRect();

      const { left, right } = clientRect;
      const windowSize = window.innerWidth;
      if (left < 0) {
        changeState({ fixOutOfBounds: left });
      } else if (right > windowSize) {
        changeState({ fixOutOfBounds: right - windowSize });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipRef, active]);

  if (!content) return children;

  return (
    <S.Tooltip
      data-testid="tooltip-component"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      onMouseDown={handleClick}
      expanded={expanded}
    >
      <div ref={childrenRef}>{children}</div>
      <S.Tip
        data-testid="tip-component"
        active={active}
        childrenWidth={childrenWidth}
        bottom={bottom}
        ref={tipRef}
        fixOutOfBounds={fixOutOfBounds}
        left={left}
        expanded={expanded}
        top={top}
      >
        {content}
      </S.Tip>
    </S.Tooltip>
  );
};

interface IState {
  active: boolean;
  childrenWidth: number;
  clicked: boolean;
  fixOutOfBounds: number;
}

export interface ITooltipProps {
  content?: string | boolean | JSX.Element[] | JSX.Element;
  children: any;
  hideOnClick?: boolean;
  bottom?: boolean;
  left?: number;
  expanded?: boolean;
  top?: number;
}

export default Tooltip;
