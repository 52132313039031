import React from "react";

import * as S from "./style";

const NavSubItem = (props: INavSubItemProps): JSX.Element => {
  const { children, onClick, active, type } = props;

  const handleOnClick = (e: React.MouseEvent<HTMLLIElement>) => {
    if (onClick !== undefined) {
      e.preventDefault();
      onClick(e);
    }
  };

  return (
    <S.SubItem onClick={handleOnClick} active={active} type={type}>
      {children}
    </S.SubItem>
  );
};

interface INavSubItemProps {
  children: JSX.Element | string;
  active: boolean;
  type: string;
  onClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
}

export default NavSubItem;
