import React from "react";
import { connect } from "react-redux";

import { ResizePanel } from "@ax/components";
import { IRootState } from "@ax/types";
import { getDefaultTheme } from "@ax/helpers";

import TemplateBrowser from "./TemplateBrowser";
import ConfigPanel from "./ConfigPanel";

const Editor = (props: IProps) => {
  const theme = getDefaultTheme();

  return <ResizePanel leftPanel={<TemplateBrowser />} rightPanel={<ConfigPanel theme={theme} {...props} />} />;
};

type IProps = {
  isLoading: boolean;
  template: string;
};

const mapStateToProps = (state: IRootState): IProps => ({
  isLoading: state.app.isLoading,
  template: state.pageEditor.template,
});

export default connect(mapStateToProps)(Editor);
