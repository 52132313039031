import React from "react";
import { connect } from "react-redux";

import { IRootState, ISite, ITemplate } from "@ax/types";
import { getSchemaThumbnails, getTemplateThumbnails } from "@ax/helpers";

import Card from "./Card";
import Form from "./Form";

import * as S from "./style";

const ConfigPack = (props: IProps): JSX.Element => {
  const { selected, currentSiteInfo } = props;
  const { title, description, categories, templates, modules, structuredData } = selected;
  const isFromPage = templates && templates.length > 0;

  if (!currentSiteInfo) {
    throw new Error(`ERROR: User reached DataPacks with null site info`);
  }

  return (
    <S.Wrapper>
      <S.Pack>
        <S.Heading>Site data</S.Heading>
        <S.Title>{`${title} Pack`}</S.Title>
        <S.Description>{description}</S.Description>
        <S.Items>
          <S.CardList>
            {structuredData &&
              structuredData.map(
                (data: any, key: number) =>
                  data.fromPage === false && (
                    <Card key={`${key}${data.title}`} title={"Content Type"} subtitle={data.title} />
                  )
              )}
            {categories &&
              categories.map((categorie: any, key: number) => (
                <Card key={`${key}${categorie.title}`} title={"Categories"} subtitle={categorie.title} />
              ))}
          </S.CardList>
          <S.CardList>
            {modules &&
              modules.map((module: any, key: number) => {
                const thumbnails = getSchemaThumbnails(module.id, currentSiteInfo.theme);
                return (
                  <Card
                    key={`${key}${module.title}`}
                    title={"Module"}
                    subtitle={module.title}
                    thumbnail={thumbnails && thumbnails["2x"]}
                  />
                );
              })}
            {templates &&
              templates.map((template: ITemplate, key: number) => {
                const thumbnails = getTemplateThumbnails(template.id, currentSiteInfo.theme);
                return (
                  <Card
                    key={`${key}${template.title}`}
                    title={"Template"}
                    subtitle={template.title}
                    thumbnail={thumbnails && thumbnails["2x"]}
                  />
                );
              })}
          </S.CardList>
        </S.Items>
      </S.Pack>
      {isFromPage && <Form />}
    </S.Wrapper>
  );
};

interface IProps {
  selected: any;
  currentSiteInfo: ISite | null;
}

const mapStateToProps = (state: IRootState) => ({
  selected: state.dataPacks.selected,
  currentSiteInfo: state.sites.currentSiteInfo,
});

export default connect(mapStateToProps)(ConfigPack);
