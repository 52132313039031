import styled from "styled-components";
import Tag from "../Tag";

const Wrapper = styled.div`
  display: flex;
  background-color: ${(p) => p.theme.color.uiBackground03};
  width: 100%;
  padding: ${(p) => `${p.theme.spacing.xs} ${p.theme.spacing.m}`};
  align-items: center;
`;

const Text = styled.div`
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-right: ${(p) => p.theme.spacing.xs};
  flex-shrink: 0;
`;

const StyledTag = styled(Tag)`
  margin-right: ${(p) => p.theme.spacing.xs};
`;

const TagList = styled.div``;

const ButtonWrapper = styled.div`
  margin-left: auto;
  flex-shrink: 0;
`;

export { Wrapper, TagList, Text, StyledTag, ButtonWrapper }
