import React, { useLayoutEffect, useState } from "react";

import { CheckGroup, FloatingMenu, Icon, ListTitle } from "@ax/components";
import { areEquals } from "@ax/helpers";
import { IFilterValue, IQueryValue } from "@ax/types";

import * as S from "./style";

const RoleFilter = (props: IRoleFilterProps): JSX.Element => {
  const { filterItems, value, pointer, center = true, label = "Roles", selectAllOption = "all", filters } = props;

  const initialState = [selectAllOption];
  const parsedValue =
    value && Array.isArray(value[pointer]) && value[pointer].length
      ? value[pointer].map((val: IQueryValue) => val.value)
      : initialState;
  const [selectedValue, setSelectedValue] = useState(parsedValue);

  useLayoutEffect(() => {
    const parsedValue =
      value && Array.isArray(value[pointer]) && value[pointer].length
        ? value[pointer].map((val: IQueryValue) => val.value)
        : initialState;
    setSelectedValue(parsedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const setQuery = (selection: any) => {
    if (!selection.length) {
      selection = initialState;
    }
    setSelectedValue(selection);
    const queryFilters: IQueryValue[] = selection.map((value: string | number) => {
      const label = filters && filters.find((opt: IFilterValue) => opt.value === value);
      return { value, label: label?.title || "" };
    });
    filterItems(pointer, queryFilters);
  };

  const isActive = !areEquals(selectedValue, initialState);

  const Header = () => (
    <S.Wrapper isActive={isActive} center={center}>
      {label}
      <S.IconsWrapper>
        {isActive ? <Icon name="Filter" size="16" /> : <Icon name="DownArrow" size="16" />}
      </S.IconsWrapper>
    </S.Wrapper>
  );

  return (
    <FloatingMenu Button={Header} position="left" closeOnSelect={false} isCheckGroup={true}>
      <ListTitle>Filter by Role</ListTitle>
      <S.ChecksWrapper>
        <CheckGroup options={filters} value={selectedValue} onChange={setQuery} selectAllOption={selectAllOption} />
      </S.ChecksWrapper>
    </FloatingMenu>
  );
};

export interface IRoleFilterProps {
  filterItems(pointer: string, filter: IQueryValue[]): void;
  value: Record<string, IQueryValue[]>;
  pointer: string;
  center?: boolean;
  label?: string;
  selectAllOption?: string;
  filters?: IFilterValue[];
}

export default RoleFilter;
