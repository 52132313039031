import { template } from "./config";
import { IServiceConfig, sendRequest } from "./utils";

const SERVICES: { [key: string]: IServiceConfig } = {
  GET_REDIRECTS: {
    ...template,
    endpoint: "/redirects",
    method: "GET",
  },
  CREATE_REDIRECT: {
    ...template,
    endpoint: "/redirect",
    method: "POST",
  },
  UPDATE_REDIRECT: {
    ...template,
    endpoint: "/redirect/",
    method: "PUT",
  },
  DELETE_REDIRECT: {
    ...template,
    endpoint: "/redirect/",
    method: "DELETE",
  },
  DELETE_REDIRECT_BULK: {
    ...template,
    endpoint: "/redirects/bulk",
    method: "DELETE",
  },
  CREATE_REDIRECT_BULK_CHECK: {
    ...template,
    endpoint: "/redirect/bulk/check",
    method: "POST",
  },
  CREATE_REDIRECT_BULK: {
    ...template,
    endpoint: "/redirect/bulk",
    method: "POST",
  },
};

const getRedirects = async (params: any, filters?: string) => {
  const { page, itemsPerPage, pagination, query, filterBy } = params;

  const { host, endpoint } = SERVICES.GET_REDIRECTS;

  const filterString = filters || "";
  const searchQuery = query ? `&query=${query}` : "";
  const filterQuery = filterBy ? `&filterBy=${filterBy}` : "";

  SERVICES.GET_REDIRECTS.dynamicUrl = `${host}${endpoint}?page=${page}&itemsPerPage=${itemsPerPage}&pagination=${pagination}${filterString}${searchQuery}${filterQuery}`;

  return sendRequest(SERVICES.GET_REDIRECTS);
};

const createRedirect = async (redirect: any, force?: boolean) => {
  const { host, endpoint } = SERVICES.CREATE_REDIRECT;

  const forceString = force ? "?force=1" : "";
  SERVICES.CREATE_REDIRECT.dynamicUrl = `${host}${endpoint}${forceString}`;

  return sendRequest(SERVICES.CREATE_REDIRECT, { ...redirect });
};

const updateRedirect = async (redirect: any, redirectID: number) => {
  const { host, endpoint } = SERVICES.UPDATE_REDIRECT;

  SERVICES.UPDATE_REDIRECT.dynamicUrl = `${host}${endpoint}${redirectID}`;

  return sendRequest(SERVICES.UPDATE_REDIRECT, { ...redirect });
};

const deleteRedirect = async (redirectID: number) => {
  const { host, endpoint } = SERVICES.DELETE_REDIRECT;

  SERVICES.DELETE_REDIRECT.dynamicUrl = `${host}${endpoint}${redirectID}`;

  return sendRequest(SERVICES.DELETE_REDIRECT);
};

const deleteRedirectsBulk = async (ids: number[]) => {
  return sendRequest(SERVICES.DELETE_REDIRECT_BULK, { ids });
};

const createRedirectsBulkCheck = async (redirects: any[]) => {
  return sendRequest(SERVICES.CREATE_REDIRECT_BULK_CHECK, { redirects });
};

const createRedirectsBulk = async (redirects: any[]) => {
  return sendRequest(SERVICES.CREATE_REDIRECT_BULK, { redirects });
};

export default {
  getRedirects,
  createRedirect,
  updateRedirect,
  deleteRedirect,
  deleteRedirectsBulk,
  createRedirectsBulk,
  createRedirectsBulkCheck,
};
