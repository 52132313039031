import * as React from "react";

const NavigationContext = React.createContext<NavigationContextProps | null>(
	null,
);

/**
 * <NavigationProvider>
 */
function NavigationProvider(props: NavigationProviderProps) {
	return (
		<NavigationContext.Provider value={{ isNavigation: true }}>
			{props.children}
		</NavigationContext.Provider>
	);
}

interface NavigationContextProps {
	isNavigation: true;
}

interface NavigationProviderProps {
	children: JSX.Element;
}

export { NavigationContext, NavigationProvider, type NavigationContextProps };
