import React from "react";

const SvgDisabled = props => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      d="M19.35 9.04A7.49 7.49 0 0012 3c-1.48 0-2.85.43-4.01 1.17l1.46 1.46a5.497 5.497 0 018.05 4.87v.5H19c1.66 0 3 1.34 3 3 0 1.13-.64 2.11-1.56 2.62l1.45 1.45C23.16 17.16 24 15.68 24 14c0-2.64-2.05-4.78-4.65-4.96zM3 4.27l2.75 2.74C2.56 7.15 0 9.77 0 13c0 3.31 2.69 6 6 6h11.73l2 2L21 19.73 4.27 3 3 4.27zM7.73 9l8 8H6c-2.21 0-4-1.79-4-4s1.79-4 4-4h1.73z"
      fill="#20224C"
      fillOpacity={0.2}
    />
  </svg>
);

export default SvgDisabled;
