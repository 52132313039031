import React from "react";
import { BulkSelectionOptions } from "@ax/components";
import { IQueryValue } from "@ax/types";
import TableHeader from "./TableHeader";

const BulkHeader = (props: IProps): JSX.Element => {
  const {
    showBulk,
    checkState,
    bulkDelete,
    selectItems,
    selectAllItems,
    totalItems,
    isScrolling,
    filterItems,
    filterValues,
    isSiteItem,
    sortItems,
    sortedListStatus,
    setHoverCheck,
  } = props;

  const bulkActions = [
    {
      icon: "delete",
      text: "delete",
      action: bulkDelete,
    },
  ];

  return showBulk ? (
    <BulkSelectionOptions
      checkState={checkState}
      actions={bulkActions}
      selectItems={selectItems}
      totalItems={totalItems}
    />
  ) : (
    <TableHeader
      totalItems={totalItems}
      selectAllItems={selectAllItems}
      isScrolling={isScrolling}
      filterItems={filterItems}
      filterValues={filterValues}
      isSiteItem={isSiteItem}
      sortItems={sortItems}
      sortedListStatus={sortedListStatus}
      checkState={checkState}
      setHoverCheck={setHoverCheck}
    />
  );
};

interface IProps {
  showBulk: boolean;
  checkState: any;
  bulkDelete: () => void;
  selectItems: () => void;
  selectAllItems: () => void;
  totalItems: number;
  isScrolling: boolean;
  filterItems: (filterPointer: string, filtersSelected: IQueryValue[]) => void;
  filterValues: any;
  isSiteItem: boolean;
  sortItems: (orderPointer: IQueryValue[], isAscending: boolean) => void;
  sortedListStatus: { isAscending: boolean; sortedByDate: boolean };
  setHoverCheck: (state: boolean) => void;
}

export default BulkHeader;
