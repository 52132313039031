import styled from "styled-components";

const ActionMenu = styled.div`
  width: ${(p) => `calc(4 * ${p.theme.spacing.l})`};
  max-height: 380px;
  overflow: auto;
`;

const MenuHeader = styled.div`
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.color.textLowEmphasis};
  padding: ${(p) => `${p.theme.spacing.xs} ${p.theme.spacing.s}`};
`;

const Wrapper = styled.div<{ clickable: boolean }>`
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.s};
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
  pointer-events: ${(p) => (p.clickable ? "auto" : "none")};
  cursor: ${(p) => (p.clickable ? "pointer" : "default")};
  :hover {
    background-color: ${(p) => (p.clickable ? p.theme.color.overlayHoverPrimary : "transparent")};
  }
`;

const Header = styled.div<{ type: string }>`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textLowEmphasis};
  display: flex;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  svg {
    margin-right: ${(p) => p.theme.spacing.xxs};
    path {
      fill: ${(p) => (p.type === "warning" ? p.theme.color.interactive02 : p.theme.color.error)};
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const Subtitle = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textLowEmphasis};
`;

const Type = styled.span`
  text-transform: capitalize;
`;

const Link = styled.div`
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.color.interactive01};
  margin-top: ${(p) => p.theme.spacing.xxs};
`;

export { ActionMenu, MenuHeader, Wrapper, Header, Content, Title, Subtitle, Type, Link };
