import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  background-color: ${(p) => p.theme.color.uiBackground03};
  width: 236px;
  height: 32px;
  margin-top: ${(p) => p.theme.spacing.xxs};
  border-radius: ${(p) => p.theme.radii.s};
  align-items: center;
  padding: ${(p) => `0 ${p.theme.spacing.xs}`};
`;

const SelectAllCheckField = styled.div`
  margin-left: ${(p) => p.theme.spacing.xxs};
  width: ${(p) => p.theme.spacing.s};
`;

const CloseWrapper = styled.div`
  width: ${(p) => p.theme.spacing.s};
  height: ${(p) => p.theme.spacing.s};
  cursor: pointer;
  svg {
    path {
      fill: ${(p) => p.theme.color.iconMediumEmphasis};
    }
  }
`;

const Counter = styled.div`
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-left: ${(p) => p.theme.spacing.xs};
`;

const BulkActions = styled.div`
  display: flex;
  margin-left: auto;
  button {
    margin-left: ${(p) => p.theme.spacing.xxs};
  }
`;

export { Wrapper, CloseWrapper, SelectAllCheckField, Counter, BulkActions };
