import React from "react";
import { usePermission } from "@ax/hooks";

const Restricted = (props: IRestrictedProps): JSX.Element => {
  const { to, fallback, children } = props;
  const isAllowed = usePermission(to);

  if (isAllowed) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
};

interface IRestrictedProps {
  children: JSX.Element | JSX.Element[];
  to: string | string[];
  fallback?: JSX.Element;
}

export default Restricted;
