import React, { memo } from "react";

import { getThumbnailProps, filterImageText } from "@ax/helpers";

import * as S from "./style";

const getThumbnailData = (option: any, theme: string) => {
  option = filterImageText(option.component);
  return getThumbnailProps(option, false, theme);
};

const SideModalOption = (props: IProps) => {
  const { option, handleClick, theme, selected } = props;

  const thumbnailProps = getThumbnailData(option, theme);

  const setOption = () => {
    handleClick(option);
  };

  return (
    <S.Item onClick={setOption} selected={selected}>
      <S.Thumbnail {...thumbnailProps} />
    </S.Item>
  );
};

interface IProps {
  option: any;
  handleClick: any;
  theme: string;
  selected: boolean;
}

export default memo(SideModalOption);
