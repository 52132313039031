import { deepClone } from "@ax/helpers";
import { usePrevious } from "@ax/hooks";

const removeIrrelevantKeys = (menu: any) => {
  const removeKeys = (item: any) => {
    delete item.editorID;
    delete item.isExpanded;
    delete item.parentEditorID;
    return item;
  };

  const cleanMenu = menu.map((item: any) => {
    const hasChildren = item.children.length > 0;
    if (!hasChildren) return removeKeys(item);
    removeIrrelevantKeys(item.children);
    return removeKeys(item);
  });

  return cleanMenu;
};

const useShouldBeSaved = (editorMenu: any, savedMenu: any, currType: string) => {
  const prevType = usePrevious(currType);

  const cleanMenu = (menu: any) => {
    const menuCloned = menu && deepClone(menu);
    const menuValues = menuCloned && menuCloned.length > 0 && removeIrrelevantKeys(menuCloned);

    if (menuValues) {
      menuValues.forEach((menuItem: any) => {
        menuItem && menuItem.url && delete menuItem["url"]["linkToURL"];
      });
    }

    const menuObj: any = menuValues
      ? {
          id: prevType ? currType : null,
          ...menuValues,
        }
      : undefined;

    return menuObj;
  };

  const editorValues = cleanMenu(editorMenu);
  const savedValues = cleanMenu(savedMenu);

  const isDirty = JSON.stringify(editorValues) !== JSON.stringify(savedValues);

  return { isDirty };
};

export { useShouldBeSaved };
