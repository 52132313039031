import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  &:hover {
    color: ${p => p.theme.color.interactive01};
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  svg {
    margin-left: 4px;
  }
`;

const InteractiveArrow = styled.div`
  display: flex;
  svg {
    path {
      fill: ${p => p.theme.color.interactive01};
    }
  }
`;

export {
  Wrapper,
  IconsWrapper,
  InteractiveArrow
};
