import styled from "styled-components";

export const ButtonWrapper = styled.div``;

export const InputWrapper = styled.div<{ error: boolean | undefined }>`
  position: relative;

  ${ButtonWrapper} {
    position: absolute;
    top: 12px;
    width: ${(p) => p.theme.spacing.m};
    height: ${(p) => p.theme.spacing.m};
    right: ${(p) => p.theme.spacing.s};
    button {
      width: ${(p) => p.theme.spacing.m};
      height: ${(p) => p.theme.spacing.m};
    }
  }
  .react-datepicker__input-container,
  input {
    flex: 1;
    border-top: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-left: 1px solid;
    border-color: ${(p) => (p.error === true ? p.theme.color.error : p.theme.color.uiLine)};
    border-radius: ${(p) => p.theme.radii.s};
    background: ${(p) => p.theme.color.uiBackground02};
    padding: ${(p) => `0 ${p.theme.spacing.s}`};
    height: ${(p) => p.theme.spacing.l};
    width: 100%;
    ${(p) => p.theme.textStyle.fieldContent};
    color: ${(p) => p.theme.color.textHighEmphasis};
    :active,
    :focus {
      -moz-outline-style: none;
      outline: 0;
      border-color: ${(p) => (p.error === true ? p.theme.color.error : p.theme.color.interactive01)};
    }

    &::placeholder {
      color: ${(p) => p.theme.color.textLowEmphasis};
    }

    &:read-only {
      background-color: ${(p) => p.theme.color.uiBackground03};
      border: none;
      color: ${(p) => p.theme.color.textMediumEmphasis};
      padding: ${(p) => `0 0 0 ${p.theme.spacing.s}`};
    }

    &:disabled {
      color: ${(p) => p.theme.color.interactiveDisabled};
      border-color: ${(p) => p.theme.color.interactiveDisabled};
    }
  }
`;

export const Input = styled.input``;

export const IconWrapper = styled.div`
  border-radius: 50%;

  :hover {
    background-color: ${(p) => p.theme.color.overlayHoverPrimary};
    opacity: 1;
    cursor: pointer;
  }
  svg {
    width: ${(p) => p.theme.spacing.m};
    height: ${(p) => p.theme.spacing.m};
    path {
      fill: ${(p) => p.theme.color.interactive01};
    }
  }
`;
