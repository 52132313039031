import styled from "styled-components";

const FolderBack = styled.div<{ size?: "S" | "M" }>`
  position: relative;
  background-color: ${(p) => p.theme.color.uiBackground02};
  border: ${(p) => `1px solid ${p.theme.color.uiLine}`};
  border-radius: ${(p) => p.theme.radii.s};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 84px;
  height:  ${(p) => p.size === "S" ? "40px" : "64px"};
  cursor: pointer;
  padding: ${(p) => `0 ${p.theme.spacing.s}`};
  margin-right: ${(p) => p.size === "S" ? p.theme.spacing.xxs : p.theme.spacing.s};
  &:hover {
    background-color: ${(p) => p.theme.color.overlayHoverPrimary};
  }
`;

const BackIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  &:last-child {
    svg {
      path {
        fill: ${(p) => p.theme.color.iconHighEmphasis};
      }
    }
  }
`;

export { FolderBack, BackIconWrapper }
