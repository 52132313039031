import styled from "styled-components";

export const Label = styled.label<{ error: boolean | undefined; inversed: boolean }>`
  ${(p) => p.theme.textStyle.fieldLabel};
  color: ${(p) =>
    p.error === true
      ? p.theme.color.error
      : p.inversed
      ? p.theme.color.textHighEmphasisInverse
      : p.theme.color.textMediumEmphasis};
  display: block;
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

export const Asterisk = styled.span`
  color: ${(p) => p.theme.color.error};
`;

export const HelpText = styled.div<{ error: boolean | undefined; inversed: boolean }>`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) =>
    p.error === true
      ? p.theme.color.error
      : p.inversed
      ? p.theme.color.textMediumEmphasisInverse
      : p.theme.color.textMediumEmphasis};
`;

export const Header = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
`;

export const Icons = styled.div`
  opacity: 0;
  display: flex;
`;

export const Wrapper = styled.div<{ showTitle: boolean | undefined }>`
  position: relative;
  margin-bottom: ${(p) => p.theme.spacing.m};
  padding-top: ${(p) => (p.showTitle ? p.theme.spacing.m : 0)};
  width: 100%;

  &:hover > .fieldHeader ${Icons} {
    opacity: 1;
  }

  &.filled {
    padding-top: ${(p) => (p.showTitle ? `calc(${p.theme.spacing.m} + ${p.theme.spacing.s})` : p.theme.spacing.s)};
    padding-left: ${(p) => p.theme.spacing.s};
    padding-right: ${(p) => p.theme.spacing.s};
    padding-bottom: ${(p) => p.theme.spacing.xs};
    background-color: ${(p) => p.theme.color.uiBarBackground};
    border-radius: ${(p) => p.theme.radii.s};
    box-shadow: ${(p) => p.theme.shadow.shadowS};

    ${Header} {
      top: ${(p) => p.theme.spacing.s};
      left: ${(p) => p.theme.spacing.s};
    }
  }
`;

export const Content = styled.div<{ error: boolean | undefined; inversed: boolean }>`
  &:focus-within {
    & + div label {
      color: ${(p) =>
        p.error === true
          ? p.theme.color.error
          : p.inversed
          ? p.theme.color.textHighEmphasisInverse
          : p.theme.color.interactive01};
    }
  }
`;
