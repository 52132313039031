/* eslint-disable react/display-name */
/* eslint-disable react/no-unknown-property */
import type {
	FetchPriority,
	ImageCropType,
	ImageDecoding,
	ImageLoading,
	ImagePosition,
	ImageTransform,
	ResponsiveImageProps,
} from "../../types/core";

import * as React from "react";
import { AspectRatio } from "react-aspect-ratio";

import "react-aspect-ratio/aspect-ratio.css";
import { useGriddoImage, useSite } from "../../hooks";

import "./styles.css";

/**
 * Renders an image from the **Griddo** cdn.
 * Also return a ref with the `<picture>` tag.
 * @see [Documentation](https://www.notion.so/griddoio/GriddoImage-a3973298b4e7447884f29185dae2d07e)
 *
 * @example
 * <GriddoImage
 *   url="https://images.griddo.io/butterfly"
 *   width="640px"
 *   height="480px"
 *   quality={75}
 * />
 */
const GriddoImage = React.forwardRef<HTMLPictureElement, GriddoImageProps>(
	(
		{
			width,
			height,
			ratio,
			fixed,
			url,
			loading,
			decoding,
			responsive,
			quality,
			crop,
			format,
			formats,
			position,
			transforms,
			...props
		},
		ref,
	) => {
		if (!url) return null;

		const { griddoDamDefaults } = useSite() || {};

		const imageConfig = {
			// ...griddoDamDefaults,
			loading: loading || griddoDamDefaults?.loading,
			decoding: decoding || griddoDamDefaults?.decoding,
			quality: quality || griddoDamDefaults?.quality,
			crop: crop || griddoDamDefaults?.crop,
			format: format,
			formats: formats || griddoDamDefaults?.formats,
			position: position,
			transforms: transforms,
		};

		// Get data necesary for the image component
		const { src, sizes, avif, jpeg, gif, webp, svg } = useGriddoImage({
			url: url,
			crop: imageConfig.crop,
			format: imageConfig.format,
			quality: imageConfig.quality,
			responsive: responsive,
			position: imageConfig.position,
			transforms: imageConfig.transforms,
			width: width,
			height: height,
		});

		const GifImage = () => (
			<img
				data-image="griddo"
				loading={loading || imageConfig.loading}
				decoding={decoding || imageConfig.decoding}
				srcSet={responsive && gif?.srcSet?.join(",")}
				sizes={responsive && sizes}
				src={gif?.srcSetURL && gif.srcSetURL[0]}
				width={width}
				height={height}
				fetchpriority={props.fetchpriority}
				{...props}
			/>
		);

		const SvgImage = () => (
			<img
				data-image="griddo"
				loading={loading || imageConfig.loading}
				decoding={decoding || imageConfig.decoding}
				src={svg?.srcSetURL && svg.srcSetURL[0]}
				width={width}
				height={height}
				fetchpriority={props.fetchpriority}
				{...props}
			/>
		);

		const Picture = () => {
			return (
				<picture ref={ref}>
					{/* avif */}
					{imageConfig.formats?.includes("avif") && (
						<source
							srcSet={avif?.srcSet?.join(",")}
							sizes={responsive && sizes}
							type="image/avif"
						/>
					)}

					{/* webp */}
					{imageConfig.formats?.includes("webp") && (
						<source
							srcSet={webp?.srcSet?.join(",")}
							sizes={responsive && sizes}
							type="image/webp"
						/>
					)}

					{/* jpeg */}
					<img
						data-image="griddo"
						loading={loading || imageConfig.loading}
						decoding={decoding || imageConfig.decoding}
						sizes={responsive && sizes}
						srcSet={jpeg?.srcSet?.join(",")}
						src={src}
						width={width}
						height={height}
						fetchpriority={props.fetchpriority}
						{...props}
					/>
				</picture>
			);
		};

		if (!url) {
			return null;
		}

		if (format === "svg") {
			return <SvgImage />;
		}

		if (format === "gif") {
			return <GifImage />;
		}

		if (fixed || !responsive) {
			return <Picture />;
		}

		return (
			<AspectRatio
				ratio={ratio || 1}
				style={{ maxWidth: "100%", alignSelf: "normal" }}
			>
				<Picture />
			</AspectRatio>
		);
	},
);

// Omit src, srcSet and sizes, as GriddoImage component will add them and it
// doesn't need to be extends from React types.
// Also omit, width and height because GriddoImage only accepts strings with
// 'px' format and the React.ComponentPropsWithRef<"img"> an be numbers too.
export interface GriddoImageCommonProps
	extends Omit<
		React.ComponentPropsWithRef<"img">,
		"src" | "srcSet" | "sizes" | "width" | "height"
	> {
	crop?: ImageCropType;
	fetchpriority?: FetchPriority;
	height?: string;
	position?: ImagePosition;
	url?: string;
	width?: string;
	ratio?: number;
	fixed?: boolean;
	loading?: ImageLoading;
	decoding?: ImageDecoding;
	responsive?: Array<ResponsiveImageProps>;
	quality?: number;
	formats?: Array<"webp" | "avif">;
	transforms?: ImageTransform;
}

export interface GriddoImageSvg extends GriddoImageCommonProps {
	// format is used to check if is a GriddoImageSvg image
	format?: "svg";
	responsive?: never;
	quality?: never;
	formats?: never;
	transforms?: never;
	ratio?: never;
}

export interface GriddoImageGif extends GriddoImageCommonProps {
	// format is used to check if is a GriddoImageGif image
	format?: "gif";
	responsive?: never;
	quality?: never;
	formats?: never;
	transforms?: never;
}

export interface GriddoImageJpgWebpAvif extends GriddoImageCommonProps {
	// format is used to check if is a GriddoImageJpgWebpAvif image
	/** Format is set automatically based on formats prop */
	format?: never;
}

export type GriddoImageProps =
	| GriddoImageJpgWebpAvif
	| GriddoImageGif
	| GriddoImageSvg;

export { GriddoImage };
