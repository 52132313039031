import React from "react";

import { IModal } from "@ax/types";
import { Modal, FieldsBehavior } from "@ax/components";
import { slugify } from "@ax/helpers";

import * as S from "./style";

const DeleteModal = (props: IDeleteModal): JSX.Element => {
  const {
    isOpen,
    toggleModal,
    mainModalAction,
    secondaryModalAction,
    title,
    isTranslated,
    deleteAllVersions,
    setDeleteAllVersions,
  } = props;

  const options = [
    {
      title: "Delete only this version",
      name: "deletePage",
      value: false,
    },
    {
      title: "Delete all languages versions",
      name: "deleteAll",
      value: true,
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Delete Global Data?"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
    >
      {isTranslated ? (
        <>
          <S.ModalContent>
            <p>
              Are you sure you want to delete <strong>{title}</strong> page? If you delete it, this page does not appear
              in the sites associated. Also, this page has some translations associated.
            </p>
            <FieldsBehavior
              name="removeAllVersions"
              fieldType="RadioGroup"
              value={deleteAllVersions}
              options={options}
              onChange={setDeleteAllVersions}
            />
          </S.ModalContent>
        </>
      ) : (
        <S.ModalContent>
          Are you sure you want to delete <strong>{title}</strong> page? If you delete it, this page does not appear in
          the sites associated. This action <strong>cannot be undone</strong>.
        </S.ModalContent>
      )}
    </Modal>
  );
};

const DuplicateModal = (props: IDuplicateModal): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction, duplicateModalState, setDuplicateModalState } =
    props;

  const handleNameChange = (value: string) => {
    setDuplicateModalState({ title: value, slug: slugify(value) });
  };

  const handleSlugChange = (value: string) => {
    setDuplicateModalState((state: { title: string; slug: string }) => ({ ...state, slug: value }));
  };

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Duplicate page"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="M"
    >
      <S.ModalContent>
        <FieldsBehavior
          fieldType="TextField"
          name="title"
          title="Title"
          mandatory={true}
          value={duplicateModalState.title}
          onChange={handleNameChange}
        />
        <FieldsBehavior
          fieldType="TextField"
          name="slug"
          title="Slug"
          mandatory={true}
          value={duplicateModalState.slug}
          onChange={handleSlugChange}
        />
      </S.ModalContent>
    </Modal>
  );
};

const UnpublishModal = (props: IModal): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction } = props;

  return (
    <Modal isOpen={isOpen} hide={toggleModal} title="Unpublish Modified Page" mainAction={mainModalAction} size="S">
      <S.ModalContent>
        <p>
          There are some saved changes that are not published on this page. To Unpublish this page,{" "}
          <strong>you must publish these changes or discard them.</strong>
        </p>
      </S.ModalContent>
    </Modal>
  );
};

interface IDeleteModal extends IModal {
  title: string;
  isTranslated: boolean;
  deleteAllVersions: boolean;
  setDeleteAllVersions: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IDuplicateModal extends IModal {
  duplicateModalState: { title: string; slug: string };
  setDuplicateModalState: React.Dispatch<React.SetStateAction<{ title: string; slug: string }>>;
}

export { DeleteModal, DuplicateModal, UnpublishModal };
