import React from "react";

import { FieldsBehavior } from "@ax/components";
import { slugify } from "@ax/helpers";
import { IFormState } from "..";

const Form = (props: IFormProps): JSX.Element => {
  const { edit, form, isTranslation, setForm } = props;

  const isCategory = form.type === "category";

  const setNameValue = (value: string) => {
    if (!edit) {
      setForm({ ...form, name: value, code: slugify(value) });
    } else {
      setForm({ ...form, name: value });
    }
  };

  const setCodeValue = (value: string) => setForm({ ...form, code: value });
  const setTypeValue = (value: "category" | "group") => setForm({ ...form, type: value });
  const setSelectableValue = (value: boolean) => setForm({ ...form, selectable: value });

  const typeOptions = [
    {
      title: "Category",
      name: "category",
      value: "category",
    },
    {
      title: "Grouping category",
      name: "group",
      value: "group",
    },
  ];

  const selectableOptions = [
    {
      title: "No",
      name: "no",
      value: false,
    },
    {
      title: "Yes",
      name: "yes",
      value: true,
    },
  ];

  const noteText = isCategory
    ? {
        title: "Category",
        text: "Create a category to assign to the data you want. This will help both you and your users filter content effectively.",
      }
    : {
        title: "Grouping category",
        text: "Create category groups to make it easier for your users to find what they're looking for.",
      };

  const helptext =
    "By default, the grouping is used to separate categories. Select 'yes' if you want it to act as an additional filter in the listings.";

  return (
    <>
      {!edit && (
        <FieldsBehavior
          title="Type"
          name="type"
          fieldType="RadioGroup"
          options={typeOptions}
          value={form.type}
          onChange={setTypeValue}
        />
      )}
      {!isTranslation && <FieldsBehavior fieldType="NoteField" value={noteText} />}
      <FieldsBehavior
        title="Name"
        name="name"
        fieldType="TextField"
        value={form.name}
        onChange={setNameValue}
        autoComplete="category-name"
        mandatory={true}
      />
      {isCategory && (
        <FieldsBehavior
          title="Code"
          name="code"
          fieldType="TextField"
          value={form.code}
          onChange={setCodeValue}
          autoComplete="category-code"
          mandatory={true}
        />
      )}
      {!isCategory && !isTranslation && (
        <FieldsBehavior
          title="Selectable?"
          name="selectable"
          fieldType="RadioGroup"
          options={selectableOptions}
          value={form.selectable}
          onChange={setSelectableValue}
          helptext={helptext}
        />
      )}
    </>
  );
};

interface IFormProps {
  edit: boolean | undefined;
  form: IFormState;
  isTranslation: boolean;
  setForm: (values: IFormState) => void;
}

export default Form;
