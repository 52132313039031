import { template } from "./config";
import { IServiceConfig, sendRequest } from "./utils";

const SERVICES: { [key: string]: IServiceConfig } = {
  GET_DATA_PACKS: {
    ...template,
    endpoint: "/data_pack",
    method: "GET",
    hasToken: true,
  },
  GET_SITE_DATA_PACKS: {
    ...template,
    endpoint: ["/site/", "/data_pack"],
    method: "GET",
    hasToken: true,
  },
  DELETE_SITE_DATA_PACK: {
    ...template,
    endpoint: ["/site/", "/data_pack"],
    method: "DELETE",
    hasToken: true,
  },
  GET_DATA_PACK: {
    ...template,
    endpoint: "/data_pack/",
    method: "GET",
    hasToken: true,
  },
  UPDATE_DATA_PACK: {
    ...template,
    endpoint: ["/site/", "/data_pack/"],
    method: "PUT",
    hasToken: true,
  },
  ADD_DATA_PACK: {
    ...template,
    endpoint: ["/site/", "/data_pack/"],
    method: "POST",
    hasToken: true,
  },
  GET_CATEGORIES: {
    ...template,
    endpoint: "/data_pack_categories",
    method: "GET",
    hasToken: true,
  },
  GET_SITE_MODULES: {
    ...template,
    endpoint: ["/site/", "/modules"],
    method: "GET",
    hasToken: true,
  },
  GET_SITE_TEMPLATES: {
    ...template,
    endpoint: ["/site/", "/templates"],
    method: "GET",
    hasToken: true,
  },
};

const getDataPacks = async (queryFilters?: string) => {
  const serviceConfig = { ...SERVICES.GET_DATA_PACKS };
  const { host, endpoint } = serviceConfig;

  if (queryFilters) {
    const dynamicUrl = `${host}${endpoint}${queryFilters}`;
    serviceConfig.dynamicUrl = dynamicUrl;
  }

  return sendRequest(serviceConfig);
};

const getSiteDataPacks = async (siteID: number, queryFilters?: string) => {
  const serviceConfig = { ...SERVICES.GET_SITE_DATA_PACKS };

  const {
    host,
    endpoint: [prefix, suffix],
  } = serviceConfig;

  const dynamicUrl = queryFilters
    ? `${host}${prefix}${siteID}${suffix}/${queryFilters}`
    : `${host}${prefix}${siteID}${suffix}`;
  serviceConfig.dynamicUrl = dynamicUrl;

  return sendRequest(serviceConfig);
};

const getSiteDataPack = async (siteID: number, dataPackID: string) => {
  const serviceConfig = { ...SERVICES.GET_SITE_DATA_PACKS };

  const {
    host,
    endpoint: [prefix, suffix],
  } = serviceConfig;

  serviceConfig.dynamicUrl = `${host}${prefix}${siteID}${suffix}/${dataPackID}`;

  return sendRequest(serviceConfig);
};

const deleteSiteDataPack = async (siteID: number, packID: string, force = false) => {
  const serviceConfig = { ...SERVICES.DELETE_SITE_DATA_PACK };
  const {
    host,
    endpoint: [prefix, suffix],
  } = serviceConfig;

  serviceConfig.dynamicUrl = `${host}${prefix}${siteID}${suffix}/${packID}?force=${force}`;

  return sendRequest(serviceConfig);
};

const getDataPack = async (packID: string) => {
  const serviceConfig = { ...SERVICES.GET_DATA_PACK };

  const { host, endpoint } = serviceConfig;

  serviceConfig.dynamicUrl = `${host}${endpoint}${packID}`;

  return sendRequest(serviceConfig);
};

const updateDataPack = async (siteID: number, packID: string, config: any) => {
  const serviceConfig = { ...SERVICES.UPDATE_DATA_PACK };
  const {
    host,
    endpoint: [prefix, suffix],
  } = serviceConfig;

  serviceConfig.dynamicUrl = `${host}${prefix}${siteID}${suffix}${packID}`;

  return sendRequest(serviceConfig, config);
};

const addDataPack = async (siteID: number, packID: string) => {
  const serviceConfig = { ...SERVICES.ADD_DATA_PACK };

  const {
    host,
    endpoint: [prefix, suffix],
  } = serviceConfig;

  serviceConfig.dynamicUrl = `${host}${prefix}${siteID}${suffix}${packID}`;

  return sendRequest(serviceConfig);
};

const getCategories = async () => sendRequest(SERVICES.GET_CATEGORIES);

const getSiteModules = async (siteID: number) => {
  const serviceConfig = { ...SERVICES.GET_SITE_MODULES };
  const {
    host,
    endpoint: [prefix, suffix],
  } = serviceConfig;

  serviceConfig.dynamicUrl = `${host}${prefix}${siteID}${suffix}`;

  return sendRequest(serviceConfig);
};

const getSiteTemplates = async (siteID: number) => {
  const serviceConfig = { ...SERVICES.GET_SITE_TEMPLATES };

  const {
    host,
    endpoint: [prefix, suffix],
  } = serviceConfig;

  serviceConfig.dynamicUrl = `${host}${prefix}${siteID}${suffix}`;

  return sendRequest(serviceConfig);
};

export default {
  getSiteDataPacks,
  getDataPack,
  updateDataPack,
  getDataPacks,
  getSiteDataPack,
  deleteSiteDataPack,
  addDataPack,
  getCategories,
  getSiteModules,
  getSiteTemplates,
};
