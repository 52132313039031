import React from "react";
import { connect } from "react-redux";
import { navigationActions } from "@ax/containers/Navigation/Defaults";

import { ResizePanel, ConfigPanel } from "@ax/components";
import { IBreadcrumbItem, IModule, INotification, IRootState, ISchema, ISite } from "@ax/types";
import DefaultsBrowser from "./DefaultsBrowser";

const Editor = (props: IProps) => {
  const {
    setSelectedContent,
    schema,
    deleteModule,
    duplicateModule,
    breadcrumb,
    selectedParent,
    activatedModules,
    addComponent,
    addModule,
    moveModule,
    replaceModule,
    replaceElementsInCollection,
    setSelectedTab,
    copyModule,
    pasteModule,
    setNotification,
    selectedTab,
    isLoading,
    site,
    browserRef,
  } = props;

  if (!site) {
    throw new Error(`ERROR: User reached Editor with null site info`);
  }

  const actions = {
    deleteModuleAction: deleteModule,
    addComponentAction: addComponent,
    addModuleAction: addModule,
    moveModuleAction: moveModule,
    replaceModuleAction: replaceModule,
    duplicateModuleAction: duplicateModule,
    replaceElementsInCollectionAction: replaceElementsInCollection,
    setSelectedContentAction: setSelectedContent,
    copyModuleAction: copyModule,
    pasteModuleAction: pasteModule,
    setNotificationAction: setNotification,
  };

  return (
    <ResizePanel
      leftPanel={<DefaultsBrowser browserRef={browserRef} actions={actions} />}
      rightPanel={
        <ConfigPanel
          schema={schema}
          actions={actions}
          breadcrumb={breadcrumb}
          selectedParent={selectedParent}
          activatedModules={activatedModules}
          setSelectedContent={setSelectedContent}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          isPage={false}
          isLoading={isLoading}
          isEditable={true}
          theme={site.theme}
        />
      }
    />
  );
};

interface IEditorStateProps {
  // TODO: Define content Type
  schema: ISchema | Record<string, unknown>;
  breadcrumb: IBreadcrumbItem[];
  selectedParent: any;
  activatedModules: string[];
  selectedTab: string;
  isLoading: boolean;
  site: ISite | null;
}

interface IPageBrowserDispatchProps {
  setSelectedContent(editorID: number): void;
  setSelectedTab(tab: string): void;
  deleteModule(editorID: number[], key?: string): void;
  duplicateModule(editorID: number[], key?: string): number;
  addComponent(componentType: any, key: string): void;
  addModule(type: string, key: string, selectedID: number, isComponentModule?: boolean): void;
  replaceModule(module: any, parent: any, objKey: string): void;
  replaceElementsInCollection(newValue: string, reference: string): void;
  moveModule(moduleID: number[], selectedContent: any, newIndex: number, key: string): void;
  copyModule(editorID: number[]): boolean | number;
  pasteModule(editorID: number, key: string, modulesToPaste: IModule[]): Promise<{ error?: INotification }>;
  setNotification: (notification: INotification) => void;
  browserRef?: React.RefObject<HTMLDivElement>;
}

type IProps = IEditorStateProps & IPageBrowserDispatchProps;

const mapStateToProps = (state: IRootState): IEditorStateProps => ({
  schema: state.navigation.schema,
  breadcrumb: state.navigation.breadcrumb,
  selectedParent: state.navigation.selectedParent,
  activatedModules: state.dataPacks.modules,
  selectedTab: state.navigation.tab,
  isLoading: state.app.isLoading,
  site: state.sites.currentSiteInfo,
});

const mapDispatchToProps = {
  setSelectedContent: navigationActions.setSelectedContent,
  setSelectedTab: navigationActions.setSelectedTab,
  deleteModule: navigationActions.deleteModule,
  duplicateModule: navigationActions.duplicateModule,
  addComponent: navigationActions.addComponent,
  addModule: navigationActions.addModule,
  moveModule: navigationActions.moveModule,
  replaceModule: navigationActions.replaceModule,
  replaceElementsInCollection: navigationActions.replaceElementsInCollection,
  copyModule: navigationActions.copyModule,
  pasteModule: navigationActions.pasteModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
