import React from "react";

import { useModal } from "@ax/hooks";
import { SideModal, ActionMenu } from "@ax/components";

import * as S from "./style";

const pageEditorID = 0;

const NavigationField = (props: IField): JSX.Element => {
  const {
    type,
    template,
    defaults,
    updateEditorContent,
    content,
    updateDataPackFormValue,
    dataPackConfigFormData,
    theme,
  } = props;
  const { isOpen, toggleModal } = useModal();

  const optionsType = `${type}s`;

  const defaultSiteHeader = defaults.find(
    (component: any) => component.setAsDefault && component.type === "header"
  )?.id;
  const defaultSiteFooter = defaults.find(
    (component: any) => component.setAsDefault && component.type === "footer"
  )?.id;

  const defaultOption = type === "header" ? defaultSiteHeader : defaultSiteFooter;

  const options = defaults.filter(
    (component: any) => component.type === type && content[component.type]?.id !== component.id
  );

  const mappedOptions = options.map((option: any) =>
    option.id === defaultOption
      ? { ...option, isDefault: true, tag: "site default" }
      : { ...option, isDefault: false, tag: null }
  );

  const actionMenuOptions = [
    {
      label: "replace",
      icon: "change",
      action: toggleModal,
    },
  ];

  const actionMenuIcon = "more";

  const handleReplace = (option: any) => {
    const optionID = option.isDefault ? null : option.id;
    const configFormData = {
      ...dataPackConfigFormData,
      templates: {
        ...dataPackConfigFormData.templates,
        [template]: {
          ...(!!dataPackConfigFormData.templates && dataPackConfigFormData.templates[template]),
          ...(type === "header" && { defaultHeader: optionID }),
          ...(type === "footer" && { defaultFooter: optionID }),
        },
      },
    };
    updateDataPackFormValue(configFormData);
    updateEditorContent(pageEditorID, type, optionID ? option : null);
    toggleModal();
  };

  const isDefaultNavigation = () => {
    const defaultType = type === "header" ? "defaultHeader" : "defaultFooter";
    const templateNavigation = dataPackConfigFormData.templates && dataPackConfigFormData.templates[template];
    const templateDefaultNavigation = templateNavigation && templateNavigation[defaultType];
    if (!templateDefaultNavigation) return true;
    const siteDefaultNavigation = defaults.find((navigation: any) => navigation.id === templateDefaultNavigation);
    return !!siteDefaultNavigation?.isDefault;
  };

  const hasOptions: boolean | undefined = options?.length > 0 || !isDefaultNavigation();

  if (!content[type]) return <></>;

  return (
    <>
      <S.Component isArray disabled>
        <S.Label>{content[type].title}</S.Label>
        <S.HiddenButtonsWrapper>
          {hasOptions && <ActionMenu options={actionMenuOptions} icon={actionMenuIcon} />}
        </S.HiddenButtonsWrapper>
      </S.Component>
      {hasOptions && (
        <SideModal
          optionsType={optionsType}
          whiteList={mappedOptions}
          handleClick={handleReplace}
          toggleModal={toggleModal}
          isOpen={isOpen}
          theme={theme}
        />
      )}
    </>
  );
};

interface IField {
  type: string;
  template: string;
  defaults: any;
  content: any;
  dataPackConfigFormData: any;
  theme: string;
  updateEditorContent: (selectedEditorID: number, key: string, value: any) => void;
  updateDataPackFormValue: (value: any) => void;
}

export { NavigationField };
