import { useCallback, useEffect, useState } from "react";

const useResizable = () => {
  const [node, setNode] = useState<HTMLElement>();

  const ref = useCallback((nodeEle: any) => {
    setNode(nodeEle);
  }, []);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      if (!node) {
        return;
      }

      const startPos = e.clientX;
      const styles = window.getComputedStyle(node);
      const w = parseInt(styles.width, 10);

      const handleMouseMove = (e: MouseEvent) => {
        e.preventDefault();
        const dx = e.clientX - startPos;
        node.style.width = `${w + dx}px`;
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [node]
  );

  useEffect(() => {
    if (!node) {
      return;
    }

    const resizerElements = [...node.querySelectorAll(".resizer")];
    resizerElements.forEach((resizerEle: any) => {
      resizerEle.addEventListener("mousedown", handleMouseDown);
    });

    return () => {
      resizerElements.forEach((resizerEle: any) => {
        resizerEle.removeEventListener("mousedown", handleMouseDown);
      });
    };
  }, [node]);

  return [ref];
};

export { useResizable };
