import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100%;
`;

const IconWrapper = styled.div`
  position: relative;
  margin-bottom: ${(p) => p.theme.spacing.m};
  background-color: ${(p) => p.theme.colors.uiBackground03};
  border-radius: 50%;
  width: ${(p) => p.theme.spacing.xl};
  height: ${(p) => p.theme.spacing.xl};
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.headingS};
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const Text = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.m};
  text-align: center;
`;

const ActionWrapper = styled.div``;

export { Wrapper, IconWrapper, Title, Text, ActionWrapper };
