import React, { useState } from "react";
import { CheckField, ElementsTooltip, Tag, Tooltip } from "@ax/components";
import { IActionMenuOption, ICheck, IFile } from "@ax/types";
import { formatBytes, getFileIcon, getFormattedDateWithTimezone, trimText } from "@ax/helpers";
import { useModal } from "@ax/hooks";

import { DeleteFileModal, MoveItemModal } from "../atoms";

import * as S from "./style";

const ListItem = (props: IProps) => {
  const {
    file,
    isSelected,
    currentFolderID,
    isAllowedToDelete,
    isAllowedToEdit,
    isAllowedToMove,
    isSearching,
    hoverCheck,
    onChange,
    onClick,
    onDelete,
    onMove,
  } = props;
  const { id, fileName, fileType, sizeBytes, uploadDate, tags, folder } = file;

  const [selectedFolder, setSelectedFolder] = useState<number>(currentFolderID || 0);
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();
  const { isOpen: isMoveOpen, toggleModal: toggleMoveModal } = useModal();

  const handleChange = (value: ICheck) => onChange(value);
  const handleClick = () => onClick(file);

  let menuOptions: IActionMenuOption[] = [];

  if (isAllowedToEdit) {
    menuOptions = [
      {
        label: "Edit",
        icon: "edit",
        action: () => onClick(file),
      },
    ];

    if (isAllowedToMove) {
      menuOptions = [
        ...menuOptions,
        {
          label: "Move to...",
          icon: "change",
          action: () => toggleMoveModal(),
        },
      ];
    }
  }

  if (isAllowedToDelete) {
    menuOptions = [
      ...menuOptions,
      {
        label: "Delete",
        icon: "delete",
        action: () => toggleDeleteModal(),
      },
    ];
  }

  const iconUrl = `/img/icons/${getFileIcon(fileType)}`;

  const handleDeleteFile = () => {
    onDelete(id);
    isDeleteOpen && toggleDeleteModal();
  };

  const handleMoveFile = () => {
    if (folder?.folderId !== selectedFolder) {
      onMove(id, selectedFolder);
      isMoveOpen && toggleMoveModal();
      setSelectedFolder(0);
    }
  };

  const handleModalClose = () => {
    setSelectedFolder(0);
    isMoveOpen && toggleMoveModal();
  };

  const mainDeleteModalAction = {
    title: "Delete document",
    onClick: () => handleDeleteFile(),
  };

  const secondaryDeleteModalAction = { title: "Cancel", onClick: toggleDeleteModal };

  const mainMoveModalAction = {
    title: "Move",
    onClick: () => handleMoveFile(),
  };

  const secondaryMoveModalAction = { title: "Cancel", onClick: handleModalClose };

  const FileTitle = () =>
    fileName.length > 50 ? <Tooltip content={fileName}>{trimText(fileName, 50)}</Tooltip> : <>{fileName}</>;

  const FolderTag = () => {
    if (!folder) return <></>;
    return folder.folderName.length > 20 ? (
      <Tooltip content={folder.folderName}>
        <Tag type="square" text={trimText(folder.folderName, 20)} icon="project" />
      </Tooltip>
    ) : (
      <Tag type="square" text={folder.folderName} icon="project" />
    );
  };

  return (
    <>
      <S.ItemRow role="rowgroup" selected={isSelected}>
        <S.CheckCell role="cell">
          <CheckField name="check" value={id} checked={isSelected || hoverCheck} onChange={handleChange} />
        </S.CheckCell>
        <S.IconCell role="cell" onClick={handleClick}>
          <img src={iconUrl} alt={`${fileType} Icon`} />
        </S.IconCell>
        <S.NameCell role="cell" onClick={handleClick} data-testid="file-name-cell">
          <FileTitle />
        </S.NameCell>
        <S.TypeCell role="cell" onClick={handleClick}>
          <S.FileType>{fileType}</S.FileType>
        </S.TypeCell>
        <S.SizeCell role="cell" onClick={handleClick}>
          {formatBytes(sizeBytes)}
        </S.SizeCell>
        <S.UpdatedCell role="cell" onClick={handleClick}>
          {getFormattedDateWithTimezone(uploadDate, "d MMM Y")}
        </S.UpdatedCell>
        <S.TagsCell role="cell" onClick={handleClick}>
          {tags.length > 0 && (
            <ElementsTooltip
              elements={file.tags}
              elementsPerRow={3}
              defaultElements={3}
              rounded={true}
              defaultColor="#FFBB37"
              prefix="#"
              size="M"
            />
          )}
        </S.TagsCell>
        {isSearching && (
          <S.FolderCell role="cell">
            <FolderTag />
          </S.FolderCell>
        )}
        <S.ActionsCell role="cell">
          <S.StyledActionMenu icon="more" options={menuOptions} tooltip="File actions" />
        </S.ActionsCell>
      </S.ItemRow>
      {isDeleteOpen && (
        <DeleteFileModal
          isOpen={isDeleteOpen}
          toggleModal={toggleDeleteModal}
          mainModalAction={mainDeleteModalAction}
          secondaryModalAction={secondaryDeleteModalAction}
          title={fileName}
        />
      )}
      {isMoveOpen && (
        <MoveItemModal
          isOpen={isMoveOpen}
          toggleModal={handleModalClose}
          mainModalAction={mainMoveModalAction}
          secondaryModalAction={secondaryMoveModalAction}
          folder={selectedFolder}
          setFolder={setSelectedFolder}
          hideRoot={!folder}
        />
      )}
    </>
  );
};

interface IProps {
  file: IFile;
  isSelected: boolean;
  currentFolderID: number | null;
  isAllowedToEdit: boolean;
  isAllowedToDelete: boolean;
  isAllowedToMove: boolean;
  isSearching: boolean;
  onChange(e: ICheck): void;
  onClick(file: IFile): void;
  onDelete(fileID: number): void;
  onMove(fileID: number, folderID: number): void;
  hoverCheck: boolean;
}

export default ListItem;
