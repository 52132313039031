export const NAME = "sites";

export const SET_SITES = `${NAME}/SET_SITES`;
export const SET_RECENT_SITES = `${NAME}/SET_RECENT_SITES`;
export const SET_SITES_TOTAL_ITEMS = `${NAME}/SET_SITES_TOTAL_ITEMS`;
export const SET_SITES_BY_LANG = `${NAME}/SET_SITES_BY_LANG`;
export const SET_CURRENT_SITE_INFO = `${NAME}/SET_CURRENT_SITE_INFO`;
export const SET_CURRENT_SITE_PAGES = `${NAME}/SET_CURRENT_SITE_PAGES`;
export const SET_ALL_SITE_PAGES = `${NAME}/SET_ALL_SITE_PAGES`;
export const SET_FILTER: string | null = `${NAME}/SET_FILTER`;
export const SET_TOTAL_ITEMS: string | null = `${NAME}/SET_TOTAL_ITEMS`;
export const SET_CURRENT_SITE_LANGUAGES: string | null = `${NAME}/SET_CURRENT_SITE_LANGUAGES`;
export const SET_INITIAL_VALUES = `${NAME}/SET_INITIAL_VALUES`;
export const SET_SAVED_SITE_INFO = `${NAME}/SET_SAVED_SITE_INFO`;
export const SET_CURRENT_SITE_ERROR_PAGES = `${NAME}/SET_CURRENT_SITE_ERROR_PAGES`;
export const SET_CONTENT_FILTERS = `${NAME}/SET_CONTENT_FILTERS`;
export const SET_CONFIG = `${NAME}/SET_CONFIG`;
export const SET_CURRENT_SEARCH = `${NAME}/SET_CURRENT_SEARCH`;
export const SET_THEME_ELEMENTS = `${NAME}/SET_THEME_ELEMENTS`;

export const ITEMS_PER_PAGE = 50;

export const DEFAULT_PARAMS = {
  page: 1,
  itemsPerPage: ITEMS_PER_PAGE,
  pagination: true,
  deleted: false,
  include_draft: true,
};
