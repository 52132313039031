import React, { useEffect, useState } from "react";

import { NoteField, FieldsBehavior } from "@ax/components";

import { IAnalyticsFieldProps, IState } from "..";
import { DimensionsGroup, DimensionsSelection } from "./atoms";
import * as S from "../style";

const PageAnalytics = (props: IAnalyticsFieldProps): JSX.Element => {
  const { value, onChange, analytics, disabled } = props;

  const initialState = {
    contentSelect: "",
    groupSelect: "",
    dimensionsSelect: [],
    values: {},
  };

  const [state, setState] = useState<IState>(value || initialState);

  useEffect(() => {
    onChange && onChange(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleContentSelect = (contentSelect: string) => {
    setState({ ...initialState, contentSelect });
  };

  const handleGroupSelect = (groupSelect: string) => {
    setState((state: IState) => ({ ...state, groupSelect, values: {} }));
  };

  const handleDimensionsSelect = (selection: string[]) => {
    const values: Record<string, string> = {};
    let dimensionsSelect = selection;

    if (selection.includes("all")) {
      dimensionsSelect = analytics.dimensions?.map((dimension) => dimension.name);
    }

    dimensionsSelect?.forEach((dimension) => (values[dimension] = state.values[dimension]));
    setState((state: IState) => ({ ...state, dimensionsSelect, values }));
  };

  const setDimension = (value: Record<string, string>) => {
    setState((state: IState) => ({ ...state, values: { ...state.values, ...value } }));
  };

  const contentOptions = [
    {
      label: "Dimensions Group",
      value: "group",
    },
    {
      label: "Individual Dimensions",
      value: "individual",
    },
  ];

  const groupOptions: { label: string; value: string }[] = [];
  analytics.groups?.forEach((group) => {
    groupOptions.push({ label: group.name, value: group.name });
  });

  const noteText = (
    <>
      Select <strong>Dimension Group</strong> if you want to select one of the groups you created in the global settings
      (based on the page content).
      <br />
      <br />
      Select <strong>Individual Dimensions</strong> if you want to select the dimensions to track manually.
    </>
  );

  const isGroup = state.contentSelect === "group";
  const isIndividual = state.contentSelect === "individual";

  const dimensionOptions = [
    {
      name: "all",
      value: "all",
      title: "Select All",
    },
  ];
  analytics.dimensions?.forEach((dimension) => {
    const option = {
      name: dimension.name,
      value: dimension.name,
      title: dimension.name,
    };
    dimensionOptions.push(option);
  });

  return (
    <>
      <NoteField value={{ text: noteText }} />
      <S.FieldWrapper>
        <FieldsBehavior
          title="Content Dimension"
          fieldType="Select"
          options={contentOptions}
          value={state.contentSelect}
          onChange={handleContentSelect}
          placeholder="Select Dimensions Options"
          disabled={disabled}
        />
      </S.FieldWrapper>
      {isGroup && (
        <>
          <FieldsBehavior
            title="Select Group"
            fieldType="Select"
            options={groupOptions}
            value={state.groupSelect}
            onChange={handleGroupSelect}
            placeholder="Select Group"
            disabled={disabled}
          />
          {!!state.groupSelect && <DimensionsGroup {...{ analytics, state, setDimension, disabled }} />}
        </>
      )}
      {isIndividual && (
        <>
          <FieldsBehavior
            title="Select Dimensions"
            fieldType="MultiCheckSelect"
            options={dimensionOptions}
            value={state.dimensionsSelect}
            onChange={handleDimensionsSelect}
            selectAllOption="all"
            placeholder="Select Dimensions"
            disabled={disabled}
            floating
          />
          {!!state.dimensionsSelect && <DimensionsSelection {...{ analytics, state, setDimension, disabled }} />}
        </>
      )}
    </>
  );
};

export default PageAnalytics;
