import React, { useEffect, useState } from "react";
import { CheckGroup, FloatingMenu, Icon, ListTitle } from "@ax/components";
import { areEquals } from "@ax/helpers";
import { IQueryValue } from "@ax/types";

import * as S from "./style";

const CheckGroupFilter = (props: ITypeFilterProps): JSX.Element => {
  const {
    label,
    description,
    filterItems,
    filters,
    pointer,
    value,
    selectAllOption,
    initialState,
    multipleSelection = true,
  } = props;

  const arrayValues = Array.isArray(value) && value.length ? value.map((val) => val.value) : initialState;
  const [selectedValue, setSelectedValue] = useState(arrayValues);

  useEffect(() => {
    const arrayValues = Array.isArray(value) && value.length ? value.map((val) => val.value) : initialState;
    setSelectedValue(arrayValues);
  }, [value]);

  const setFilterQuery = (selection: any) => {
    if (!selection.length) {
      selection = initialState;
    }
    setSelectedValue(selection);
    const queryFilters: IQueryValue[] = selection.map((value: string | number) => {
      const label = filters.find((opt: IFilter) => opt.value === value);
      return { value, label: label?.title || "" };
    });
    filterItems(pointer, queryFilters);
  };

  const isActive = !areEquals(selectedValue, initialState);

  const Header = () => (
    <S.StyledHeader isActive={isActive}>
      {label}
      <S.IconsWrapper>
        {isActive ? <Icon name="Filter" size="16" /> : <Icon name="DownArrow" size="16" />}
      </S.IconsWrapper>
    </S.StyledHeader>
  );

  return (
    <FloatingMenu Button={Header} closeOnSelect={!multipleSelection} isCheckGroup={true} position="center">
      <ListTitle>{description}</ListTitle>
      <S.ChecksWrapper>
        <CheckGroup
          options={filters}
          value={selectedValue}
          onChange={setFilterQuery}
          selectAllOption={selectAllOption}
          multipleSelection={multipleSelection}
        />
      </S.ChecksWrapper>
    </FloatingMenu>
  );
};

interface IFilter {
  name: string;
  value: string;
  title: string;
}

interface ITypeFilterProps {
  label: string;
  description: string;
  filterItems(pointer: string, filter: IQueryValue[]): void;
  filters: IFilter[];
  pointer: string;
  value: IQueryValue[];
  selectAllOption: string;
  initialState: string[];
  multipleSelection?: boolean;
}

export default CheckGroupFilter;
