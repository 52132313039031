import styled from "styled-components";

const ErrorWrapper = styled.div<{ size?: string; fixed?: boolean }>`
  position: ${(p) => (p.fixed ? "fixed" : p.size ? "absolute" : "sticky")};
  top: ${(p) => (p.fixed ? p.theme.spacing.xl : "0")};
  left: ${(p) => {
    switch (p.size) {
      case "l":
        return 0;
      case "m":
        return (p) => `calc(${p.theme.spacing.m} * 3 + ${p.theme.spacing.s})`;
      case "s":
        return (p) => `calc(${p.theme.spacing.m} * 3 + ${p.theme.spacing.s} + (${p.theme.spacing.m} * 10))`;
      default:
        return 0;
    }
  }};
  right: 0;
  z-index: 1100;
`;

export { ErrorWrapper };
