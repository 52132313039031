import { useState, useEffect } from "react";

const useModal = (initialState?: boolean) => {
  const [isOpen, setIsOpen] = useState(initialState || false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  if (isOpen) {
    document.body.classList.add("modal-open");
  } else {
    document.body.classList.remove("modal-open");
  }

  return {
    isOpen,
    toggleModal,
  };
};

const useHandleClickOutside = (isOpen: boolean, handleClickOutside: any) => {
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);
};

const useToast = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [state, setState] = useState<any>(null);

  let temp: any;
  const setTemp = () => (temp = setTimeout(() => setIsVisible(false), 6000));
  const stopTemp = () => clearTimeout(temp);

  const toggleToast = (state?: unknown) => {
    setIsVisible(true);
    setTemp();
    stopTemp();
    state && setState(state);
  };

  return { isVisible, setIsVisible, toggleToast, state };
};

export { useModal, useHandleClickOutside, useToast };
