import * as React from "react";

function SvgDots(props) {
  return (
    <svg data-testid="dots-svg" viewBox="0 0 100 100" {...props}>
      <circle fill="#5057FF" cx={6} cy={50} r={6}>
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin={0.1} />
      </circle>
      <circle fill="#5057FF" cx={26} cy={50} r={6}>
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin={0.2} />
      </circle>
      <circle fill="#5057FF" cx={46} cy={50} r={6}>
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin={0.3} />
      </circle>
    </svg>
  );
}

export default SvgDots;
