import * as React from "react";

function SvgExtend(props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 8.394L14.41 7 19 11.505 14.41 16 13 14.616l3.17-3.111L13 8.394zm-8 0L6.41 7 11 11.505 6.41 16 5 14.616l3.17-3.111L5 8.394z"
        fill="#5057FF"
      />
    </svg>
  );
}

export default SvgExtend;
