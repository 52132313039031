import { Icon } from "@ax/components";
import React, { useEffect, useState } from "react";
import { Select } from "../Fields";

import * as S from "./style";

const SearchField = (props: ISearchFieldProps): JSX.Element => {
  const {
    onChange,
    placeholder,
    closeOnInactive = false,
    searchOnEnter = true,
    disabled = false,
    searchFilters,
    onFilterChange,
    small,
    autoFocus = true,
    size = "M",
    value,
  } = props;

  const [isOpen, setIsOpen] = useState(value && value.trim() !== "" ? true : false);
  const [inputValue, setInputValue] = useState(value || "");
  const [selectValue, setSelectValue] = useState<string>("");

  useEffect(() => {
    if (inputValue.length === 0) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const toggleField = () => setIsOpen(!isOpen);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    if (!searchOnEnter) {
      onChange(newValue);
    }
  };

  const handleClear = () => {
    setInputValue("");
    onChange("");
    setSelectValue("");
  };

  const handleClose = () => {
    toggleField();
    handleClear();
  };

  const handleClick = () => {
    closeOnInactive ? handleClose() : handleClear();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (searchOnEnter && e.key === "Enter") {
      onChange(inputValue);
    }
  };

  const handleSelectChange = (value: string) => {
    setSelectValue(value);
    onFilterChange && onFilterChange(value);
  };

  const showField = isOpen || !closeOnInactive;

  return (
    <S.Wrapper data-testid="search-field-wrapper">
      {showField ? (
        <S.FieldWrapper closeOnInactive={closeOnInactive} disabled={disabled} data-testid="field-wrapper">
          {searchFilters && searchFilters.length && (
            <>
              <S.FilterWrapper data-testid="filter-wrapper">
                <Select
                  name="filterSelect"
                  value={selectValue}
                  options={searchFilters}
                  onChange={handleSelectChange}
                  type="inline"
                  placeholder="Filter by"
                />
              </S.FilterWrapper>
              <S.Separator />
            </>
          )}
          <S.Input
            type="text"
            value={inputValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            closeOnInactive={closeOnInactive}
            disabled={disabled}
            data-testid="search-input"
            small={small}
            autoFocus={autoFocus && showField}
            inputSize={size}
          />
          {inputValue.trim() !== "" && searchOnEnter && <S.HelpText>Press ENTER</S.HelpText>}
          {closeOnInactive || inputValue.length > 0 ? (
            <S.IconCloseWrapper onClick={handleClick} data-testid="icon-close-wrapper">
              <Icon name="close" size="24" />
            </S.IconCloseWrapper>
          ) : (
            <S.IconSearchWrapper disabled={disabled} data-testid="icon-search-wrapper-2">
              <Icon name="search" size="24" />
            </S.IconSearchWrapper>
          )}
        </S.FieldWrapper>
      ) : (
        <S.IconSearchWrapper disabled={disabled} onClick={toggleField} data-testid="icon-search-wrapper">
          <Icon name="search" size="24" />
        </S.IconSearchWrapper>
      )}
    </S.Wrapper>
  );
};

export interface ISearchFieldProps {
  onChange(query: string): void;
  placeholder?: string;
  closeOnInactive?: boolean;
  searchOnEnter?: boolean;
  disabled?: boolean;
  searchFilters?: any;
  onFilterChange?(filter: string): void;
  small?: boolean;
  autoFocus?: boolean;
  size?: "M" | "S" | "XS";
  value?: string;
}

export default SearchField;
