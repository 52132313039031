import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { format } from "date-fns";

import { sites } from "@ax/api";
import { IRootState, IPage } from "@ax/types";
import { isReqOk } from "@ax/helpers";
import { CheckField, SearchField, Icon } from "@ax/components";
import { useBulkSelection } from "@ax/hooks";

import * as S from "./style";

const PageImporter = (props: any): JSX.Element => {
  const { lang, structuredData, setPagesToImport, siteID } = props;
  const [pages, setPages] = useState([]);
  const [allPages, setAllPages] = useState<IPage[] | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const pageIds = pages && pages.map((page: IPage) => page.id);
  const { resetBulkSelection, selectedItems, isSelected, checkState, addToBulkSelection, selectAllItems } =
    useBulkSelection(pageIds);

  const getParams = useCallback(() => {
    const params = {
      lang: lang.locale,
      page: 1,
      itemsPerPage: 100,
      pagination: false,
      deleted: false,
      include_draft: true,
      filterStructuredData: structuredData,
      query: searchQuery,
      excludeSite: siteID,
      liveStatus: "active,upload-pending",
    };

    return params;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang.locale, searchQuery]);

  const handleGetGlobalPages = async () => {
    try {
      const response = await sites.getGlobalPagesLight(getParams());
      if (isReqOk(response.status)) {
        const {
          data: { items },
        } = response;
        setPages(items);
        if (!allPages) setAllPages(items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetGlobalPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getParams]);

  useEffect(() => {
    setPagesToImport(selectedItems.all);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const handleSelectAll = () => {
    checkState.isAllSelected ? resetBulkSelection() : selectAllItems();
  };

  const PageItem = (props: IPage): JSX.Element => {
    const { id, title, modified } = props;

    return (
      <S.PageItem role="rowgroup">
        <S.Check>
          <CheckField name="check" value={id} checked={isSelected(id)} onChange={addToBulkSelection} />
        </S.Check>
        <S.PageInfo>
          <S.Header>
            <S.Type>{structuredData}</S.Type>
            <S.Date>{format(new Date(modified), "dd MMM yy")}</S.Date>
          </S.Header>
          <S.Title>{title}</S.Title>
        </S.PageInfo>
      </S.PageItem>
    );
  };

  const SelectedItem = (props: IPage): JSX.Element => {
    const { id, title } = props;
    const removeItem = () => addToBulkSelection({ value: id, isChecked: false });

    return (
      <S.SelectedItem>
        <S.IconWrapper onClick={removeItem}>
          <Icon name="close" size="20" />
        </S.IconWrapper>
        <S.Title>{title}</S.Title>
      </S.SelectedItem>
    );
  };

  const selectedPages = allPages && allPages.filter((page: IPage) => selectedItems.all.includes(page.id));

  return (
    <S.Table>
      <S.Column>
        <SearchField onChange={setSearchQuery} />
        <S.SelectedTitle>Pages Selected</S.SelectedTitle>
        <S.SelectedList>
          {selectedPages && selectedPages.map((page: IPage) => <SelectedItem key={page.id} {...page} />)}
        </S.SelectedList>
      </S.Column>
      <S.Column>
        <S.CheckAll>
          <CheckField
            key="selectAll"
            name="selectAll"
            value="selectAll"
            onChange={handleSelectAll}
            checked={checkState.isAllSelected}
            disabled={false}
            error={false}
          />
          <span>Select All</span>
        </S.CheckAll>
        <S.PageList>{!!pages.length && pages.map((page: IPage) => <PageItem key={page.id} {...page} />)}</S.PageList>
      </S.Column>
    </S.Table>
  );
};

const mapStateToProps = (
  state: IRootState,
  ownProps: { structuredData: string; setPagesToImport: React.Dispatch<React.SetStateAction<never[]>> }
) => ({
  siteID: state.sites.currentSiteInfo && state.sites.currentSiteInfo.id,
  lang: state.app.lang,
  structuredData: ownProps.structuredData,
  setPagesToImport: ownProps.setPagesToImport,
});

export default connect(mapStateToProps, null)(PageImporter);
