import React from "react";

import Icon from "../Icon";
import * as S from "./style";

const BackFolder = (props: IBackFolderProps): JSX.Element => {
  const { onClick, size } = props;

  const iconSize = size === "S" ? "16" : "24";

  return (
    <S.FolderBack onClick={onClick} size={size}>
      <S.BackIconWrapper>
        <Icon name="project"/>
      </S.BackIconWrapper>
      <S.BackIconWrapper>
        <Icon name="back" />
      </S.BackIconWrapper>
    </S.FolderBack>
  );
};

export interface IBackFolderProps {
  onClick: () => void;
  size?: "S"|"M";
}

export default BackFolder;
