import { schemas } from "components";
import {
  IColumn,
  IPage,
  IPageLanguage,
  ISchemaField,
  IStructuredData,
  IStructuredDataFilter,
  IStructuredDataValue,
} from "@ax/types";
import { pageStatus } from "@ax/containers/PageEditor/interfaces";
import { arrayInsert } from "@ax/helpers";

const { templates } = schemas;

const getOptionFilters = (options: { global: IStructuredData[]; site: IStructuredData[] }): IStructuredDataFilter[] => {
  const pageOptions = options.global.filter((option: IStructuredData) => option.fromPage);

  const filters = pageOptions.map((option: IStructuredData) => ({
    label: option.title,
    value: option.id.toLowerCase(),
  }));

  const uniqueFilters = [...new Map(filters.map((item: IStructuredDataFilter) => [item.value, item])).values()];
  const sortedUniqueFilters = uniqueFilters.sort((a: IStructuredDataFilter, b: IStructuredDataFilter) =>
    a.label.localeCompare(b.label)
  );
  const staticFilterIdx = sortedUniqueFilters.findIndex((filter: IStructuredDataFilter) => filter.value === "static");
  const staticFilter = sortedUniqueFilters.splice(staticFilterIdx, 1);
  sortedUniqueFilters.unshift(...staticFilter);

  return sortedUniqueFilters;
};

const mapStructuredOptions = (options: { global: IStructuredData[]; site: IStructuredData[] }) => {
  const pureOptions = options.global.filter((option: IStructuredData) => !option.fromPage);

  return pureOptions.map((option: IStructuredData) => {
    const { id, title } = option;
    const type = title.toLowerCase();

    return {
      name: id,
      value: id,
      title,
      type,
      isData: true,
    };
  });
};

const getOptionValues = (options: { global: IStructuredData[]; site: IStructuredData[] }): IStructuredDataValue[] => {
  const templatesOptionsValues: IStructuredDataValue[] = [];

  Object.keys(templates).forEach((schema: string) => {
    const currSchema = templates[schema];
    const { component, displayName } = currSchema;
    const type =
      !!currSchema.type && currSchema.type.value && currSchema.type.value && currSchema.type.value.toLowerCase();

    const isDetailTemplate = currSchema.type.mode === "detail";

    !!currSchema.type &&
      isDetailTemplate &&
      templatesOptionsValues.push({
        name: component,
        title: displayName,
        type,
        value: component,
      });
  });

  return [...templatesOptionsValues, ...mapStructuredOptions(options)];
};

const getSelectedValue = (
  options: { filters: IStructuredDataFilter[]; values: IStructuredDataValue[] },
  filter?: string
): string => {
  const firstOptionsFilter = options.filters[0]?.value;
  const selectedValue =
    !filter || filter === "all-pages"
      ? options.values.find((value: IStructuredDataValue) => value.type === firstOptionsFilter)?.value
      : options.values.find((value: IStructuredDataValue) => value.type === filter.toLowerCase())?.value;
  return selectedValue || "";
};

const getCurrentFilter = (structuredData: any, filter: any) =>
  structuredData.filter((dataObj: any) => dataObj.id === filter)[0];

const filterByStatus = (bulkSelection: number[], currentSitePages: IPage[]): Record<string, number[]> => {
  const notPublishedItems: number[] = [];
  const publishedItems: number[] = [];
  const draftItems: number[] = [];

  currentSitePages.forEach((page: IPage) => {
    const { id, liveStatus, haveDraftPage } = page;
    if (bulkSelection.includes(id)) {
      switch (liveStatus.status) {
        case pageStatus.OFFLINE_PENDING:
        case pageStatus.OFFLINE:
        case pageStatus.UPLOAD_PENDING:
          haveDraftPage ? draftItems.push(id) : notPublishedItems.push(id);
          break;
        case pageStatus.PUBLISHED:
          haveDraftPage ? draftItems.push(id) : publishedItems.push(id);
          break;
      }
    }
  });

  const filteredItems = {
    all: bulkSelection,
    notPublished: notPublishedItems,
    published: publishedItems,
    drafts: draftItems,
  };

  return filteredItems;
};

const getSortedListStatus = (orderPointer: string, isAscending: boolean) => {
  const sortedListStatus = {
    isAscending,
    sortedByTitle: orderPointer === "title",
    sortedByURL: orderPointer === "slug",
    sortedByDate: ["modified", "date"].includes(orderPointer),
  };

  return sortedListStatus;
};

const getAllLangPagesIds = (
  currentSitePages: IPage[],
  selectedItems: Record<string, number[]>,
  getAllVersions: boolean
): number[] => {
  let langsPagesIds: number[] = [];
  if (getAllVersions) {
    const selectedPages = currentSitePages.filter((page: IPage) => selectedItems.all.includes(page.id));
    langsPagesIds = selectedPages.reduce((ids: number[], page: IPage) => {
      const langsPageIds = page.pageLanguages.map((lang: IPageLanguage) => lang.pageId);
      return [...ids, ...langsPageIds];
    }, []);
  }

  return langsPagesIds.length ? langsPagesIds : selectedItems.all;
};

const getColumns = (
  categoryColumns: ISchemaField[],
  isStructuredDataFromPage: boolean,
  isAllPages: boolean,
  maxColumns: number
): IColumn[] => {
  let defaultColumns: IColumn[] = [
    { id: "live", title: "Live", show: true, default: true },
    { id: "status", title: "Status", show: true, default: true },
    { id: "translation", title: "Trans.", show: true, default: true },
  ];

  if (isStructuredDataFromPage) {
    defaultColumns.push({ id: "seo", title: "SEO", show: true, default: true });
  }

  if (isStructuredDataFromPage) {
    defaultColumns = [
      { id: "site", title: "Site", show: isAllPages ? false : true, default: isAllPages ? false : true },
      ...defaultColumns,
    ];
  }

  if (isAllPages) {
    defaultColumns = [{ id: "type", title: "Types", show: true, default: true }, ...defaultColumns];
  }

  let availableColumns = maxColumns - defaultColumns.length;

  const extraColumns = categoryColumns.reduce((acc: IColumn[], cur: ISchemaField) => {
    acc.push({ id: cur.key, title: cur.title, show: availableColumns > 0, default: false });
    availableColumns = availableColumns - 1;
    return acc;
  }, []);

  const position = isStructuredDataFromPage ? 2 : 1;
  const columns = arrayInsert(defaultColumns, position, extraColumns);

  return columns;
};

export {
  getOptionValues,
  getSelectedValue,
  getOptionFilters,
  getCurrentFilter,
  filterByStatus,
  getSortedListStatus,
  getAllLangPagesIds,
  getColumns,
};
