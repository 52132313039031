import React from "react";

import { VisualOption } from "@ax/components";
import { getOptions } from "../utils";

import * as S from "./style";

const ImageSelection = (props: IImageSelectionProps): JSX.Element => {
  const { value, onChange, options, objKey, columns, disabled, theme } = props;

  const mappedOptions: any = getOptions(theme, options);

  const handleChange = (newValue: string) => onChange(newValue);

  return (
    <S.Wrapper columns={columns} data-testid="imageSelectionComponent">
      {mappedOptions.map((option: any, index: number) => (
        <VisualOption
          value={option.value}
          onChange={handleChange}
          label={option.value}
          key={index}
          name={objKey}
          checked={option.value === value}
          img={option.img}
          disabled={disabled}
        />
      ))}
    </S.Wrapper>
  );
};

export interface IImageSelectionProps {
  value: string;
  title: string;
  onChange: (value: string) => void;
  options: any;
  name: string;
  objKey: string;
  columns: number;
  disabled?: boolean;
  theme: string;
}

export default ImageSelection;
