import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import { appActions } from "@ax/containers/App";
import { usersActions } from "@ax/containers/Users";
import { Button, FieldsBehavior, ErrorToast } from "@ax/components";
import { ICreatePasswordParams } from "@ax/types";

import * as S from "./style";

const CreatePass = (props: IProps) => {
  const { setHistoryPush, createPassword } = props;

  const { id, token } = useParams<{ id: string; token: string }>();

  const initState = { pass1: "", pass2: "" };
  const [state, setState] = useState(initState);

  const handlePass1Change = (value: string) => {
    setState({ ...state, pass1: value });
  };

  const handlePass2Change = (value: string) => {
    setState({ ...state, pass2: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const params = {
      id,
      token,
      password: state.pass1,
      retypedPassword: state.pass2,
    };

    const passCreated = await createPassword(id, params);
    if (passCreated) {
      setHistoryPush("/profile?init=true");
    }
  };

  const validPasswords = state.pass1.trim() !== "" && state.pass2.trim() && state.pass1 === state.pass2;

  return (
    <S.Wrapper>
      <S.Main>
        <S.Header>
          <S.Subtitle>Welcome to Griddo</S.Subtitle>
          <S.Title>Create your password</S.Title>
        </S.Header>
        <ErrorToast />
        <S.Form onSubmit={handleSubmit}>
          <FieldsBehavior
            fieldType="TextField"
            title="Password"
            inputType="password"
            mandatory={true}
            value={state.pass1}
            onChange={handlePass1Change}
            validators={{ mandatory: true }}
            autoComplete="off"
          />
          <FieldsBehavior
            fieldType="TextField"
            title="Confirm your Password"
            inputType="password"
            mandatory={true}
            value={state.pass2}
            onChange={handlePass2Change}
            validators={{ isSamePass: state.pass1, mandatory: true }}
            autoComplete="off"
          />
          <Button type="submit" disabled={!validPasswords}>
            Create and log in
          </Button>
        </S.Form>
      </S.Main>
    </S.Wrapper>
  );
};

interface IProps {
  setHistoryPush(path: string): any;
  createPassword(id: string, params: ICreatePasswordParams): Promise<boolean>;
}

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
  createPassword: usersActions.createPassword,
};

export default connect(null, mapDispatchToProps)(CreatePass);
