import type { FullPath } from "../../types/core";

const ENVS = { pre: "pre", pro: "pro" } as const;

function getEnvironment(fullPath?: FullPath) {
	return fullPath?.domainUrl?.endsWith(`/${fullPath.domain}`)
		? ENVS.pro
		: ENVS.pre;
}

function getRel(newTab: boolean, noFollow: boolean) {
	return `${noFollow ? "nofollow" : ""} ${newTab ? "noreferrer" : ""}`.trim();
}

function getTarget(newTab: boolean) {
	return newTab ? "_blank" : "_self";
}

function getRelativeToRootPath({
	env,
	fullPath,
	to,
}: {
	env: string;
	fullPath: FullPath;
	to?: string;
}) {
	if (typeof to !== "string") {
		return to;
	}
	if (env === ENVS.pre) {
		const cleanDomain = fullPath?.domainUrl
			?.split(fullPath?.domain || "")
			.join("");
		return to?.split(cleanDomain || "")?.join("") || to;
	}
	if (env === ENVS.pro) {
		return to?.split(fullPath?.domainUrl || "")?.join("") || to;
	}
	return to;
}

export { getEnvironment, getRel, getRelativeToRootPath, getTarget };
