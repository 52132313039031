import { schemas } from "components";

const getRichTextConfig = (): IRichTextConfig | null => {
  return schemas?.richTextConfig ? schemas.richTextConfig : null;
};

const parseClassNames = (styles: { label: string; className: string }[]) =>
  styles.reduce(
    (acc, current: { label: string; className: string }) => ({ ...acc, [current.className]: current.label }),
    {}
  );

interface IRichTextConfig {
  paragraphStyles?: { label: string; className: string }[];
  tableStyles?: { label: string; className: string }[];
  tableCellStyles?: { label: string; className: string }[];
}

export { getRichTextConfig, parseClassNames };
