import React from "react";
import { connect } from "react-redux";

import { ILanguage, IRootState, ISiteLanguage } from "@ax/types";
import { languagesActions } from "@ax/containers/Settings";
import { FloatingPanel, Button } from "@ax/components";

import Form from "./Form";

import * as S from "./style";

const LanguagePanel = (props: IProps): JSX.Element => {
  const { isOpen, toggleModal, createSiteLanguage, updateSiteLanguage, currentSiteID, languageData, item } = props;

  if (!currentSiteID) {
    throw new Error(`ERROR: User reached Languages with null site info`);
  }

  const addItemAction = () => {
    createSiteLanguage(currentSiteID, languageData);
    toggleModal();
  };

  const editItemAction = () => {
    updateSiteLanguage(currentSiteID, languageData);
    toggleModal();
  };

  const addButton = {
    label: "Add",
    action: addItemAction,
    disabled: false,
  };

  const editButton = {
    label: "Update",
    action: editItemAction,
    disabled: false,
  };

  const title = item ? "Update Language" : "New Language";

  return (
    <FloatingPanel title={title} toggleModal={toggleModal} isOpen={isOpen}>
      <S.Wrapper>
        <Form item={item} />
        <S.Footer>
          {item ? (
            <Button className="button" type="button" onClick={editButton.action} disabled={editButton.disabled}>
              {editButton.label}
            </Button>
          ) : (
            <Button className="button" type="button" onClick={addButton.action} disabled={addButton.disabled}>
              {addButton.label}
            </Button>
          )}
        </S.Footer>
      </S.Wrapper>
    </FloatingPanel>
  );
};

const mapStateToProps = (state: IRootState) => ({
  currentSiteID: state.sites.currentSiteInfo && state.sites.currentSiteInfo.id,
  languageData: state.languages.form,
});

const mapDispatchToProps = {
  createSiteLanguage: languagesActions.createSiteLanguage,
  updateSiteLanguage: languagesActions.updateSiteLanguage,
};

interface IStateProps {
  currentSiteID: number | null;
  languageData: ISiteLanguage;
}

interface ILanguagePanelProps {
  item?: ILanguage;
  isOpen: boolean;
  toggleModal(): any;
}

interface IDispatchProps {
  createSiteLanguage(siteID: number, data: ISiteLanguage): void;
  updateSiteLanguage(siteID: number, data: ISiteLanguage): void;
}

type IProps = IDispatchProps & ILanguagePanelProps & IStateProps;

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePanel);
