import { ItemId, TreeData, TreeItem } from "@atlaskit/tree";
import { isEmptyObj } from "@ax/helpers";
import { ICategoryGroup, IStructuredDataCategory } from "@ax/types";

const normalizeItems = (itemList: (IStructuredDataCategory | ICategoryGroup)[], tree: TreeData) => {
  const normalized: TreeData = {
    rootId: "root",
    items: {
      root: {
        id: "root",
        children: [],
        hasChildren: true,
        isExpanded: true,
        isChildrenLoading: false,
      },
    },
  };

  const previousItems: (IStructuredDataCategory | ICategoryGroup)[] = [];
  if (!isEmptyObj(tree.items)) {
    previousItems.push(...formatTree(tree));
  }

  const isItemExpanded = (item: IStructuredDataCategory | ICategoryGroup) => {
    if (!item) return true;
    return item.isExpanded;
  };

  const getElement = (
    item: IStructuredDataCategory | ICategoryGroup,
    previousItems: IStructuredDataCategory | ICategoryGroup
  ) => {
    normalized.items[item.id] = {
      ...item,
      children: [],
      isExpanded: isItemExpanded(previousItems),
    };
    if (item.children && item.children.length > 0) {
      item.children.forEach((child: IStructuredDataCategory | ICategoryGroup, index: number) => {
        normalized.items[item.id].children.push(child.id);
        getElement(child, previousItems?.children[index]);
      });
    }
  };

  itemList.forEach((item: IStructuredDataCategory | ICategoryGroup, index: number) => {
    normalized.items.root.children.push(item.id);
    getElement(item, previousItems[index]);
  });

  return normalized;
};

const getElementsFromTree = (tree: TreeData): TreeItem[] => {
  const { items } = tree;
  const elements: TreeItem[] = [];
  for (const item of Object.values(items)) {
    elements.push(item);
  }
  return elements;
};

const getChild = (child: ItemId, elements: any[]): IStructuredDataCategory | ICategoryGroup => {
  const element = elements.find((element: IStructuredDataCategory | ICategoryGroup) => element.id === child);
  const children = element?.children.map((child: ItemId) => getChild(child, elements));
  return { ...element, children };
};

const formatTree = (tree: TreeData) => {
  const elements: TreeItem[] = getElementsFromTree(tree);

  const root: any = elements.find((element: TreeItem) => element.id === "root");
  const formattedTree = root.children.map((child: ItemId) => {
    return getChild(child, elements);
  });

  return formattedTree;
};

const formatItem = (item: any, tree: TreeData) => {
  const elements = getElementsFromTree(tree);

  const formattedItem = {
    ...item,
    children: item.children.map((child: ItemId) => getChild(child, elements)),
  };

  return formattedItem;
};

const findChild = (tree: TreeData, child: ItemId) => {
  const elements: any[] = getElementsFromTree(tree);
  return elements.find((element) => element.id === parseInt(child as string));
};

const findChildByIndex = (tree: TreeData, parentId: ItemId, index: number) => {
  const elements: any[] = getElementsFromTree(tree);
  const parent = elements.find((element) =>
    parentId === "root" ? element.id === parentId : element.id === parseInt(parentId as string)
  );
  const elementID = parent && parent.children[index] ? parent.children[index] : null;
  return elementID ? elements.find((element) => element.id === elementID) : undefined;
};

const getIDsFromTree = (dataContent: (IStructuredDataCategory | ICategoryGroup)[]): number[] => {
  return dataContent.reduce((acc: number[], current) => {
    acc.push(current.id);
    const children = getIDsFromTree(current.children || []);
    if (children.length) {
      acc = [...acc, ...children];
    }
    return acc;
  }, []);
};

export { normalizeItems, formatItem, findChild, findChildByIndex, getElementsFromTree, getIDsFromTree };
