import { IComponent } from "@ax/types";
import { getDisplayName } from "./schemas";

const isComponentEmpty = (component: IComponent): boolean => {
  const keys = Object.keys(component);
  const privateKeys = ["componentID", "component", "parentEditorID"];

  return keys.length <= privateKeys.length;
};

const setAsContainedComponent = (component: IComponent): IContainedComponent => {
  const displayName = getDisplayName(component.component);
  return {
    containerText: displayName,
    component: component.component,
    componentID: component.editorID,
  };
};

const areAllComponentsEmpty = (container: any): IContainerEvaluation => {
  const components = Object.keys(container);
  const filledComponents: IComponent[] = [];
  let containedComponent: any;

  components.forEach((component: string) => {
    if (container[component] && !isComponentEmpty(container[component])) {
      containedComponent = setAsContainedComponent(container[component]);
      filledComponents.push(container[component]);
    }
  });

  return {
    isEmpty: !filledComponents.length,
    containedComponent,
  };
};

const isEmptyContainer = (container: any, isMultiple?: boolean): IContainerEvaluation => {
  return isMultiple
    ? areAllComponentsEmpty(container)
    : {
        isEmpty: isComponentEmpty(container),
        containedComponent: setAsContainedComponent(container),
      };
};

const areEqual = (prevProps: any, newProps: any): boolean => {
  if (
    prevProps.field.type === "FieldGroup" ||
    (prevProps.field.fields && prevProps.field.fields.length > 0) ||
    prevProps.selectedEditorID !== newProps.selectedEditorID
  ) {
    return false;
  }

  const {
    selectedContent: { type },
  } = prevProps;
  const isTemplate = type === "template";
  const prevValue = isTemplate
    ? prevProps.selectedContent.template[prevProps.objKey]
    : prevProps.selectedContent[prevProps.objKey];
  const newValue = isTemplate
    ? newProps.selectedContent.template[newProps.objKey]
    : newProps.selectedContent[newProps.objKey];

  const isObject = typeof prevValue === "object";
  if (isObject) return false;

  return prevValue === newValue;
};

interface IContainerEvaluation {
  isEmpty: boolean;
  containedComponent: IContainedComponent;
}

interface IContainedComponent {
  containerText: string;
  component: string;
  componentID: number;
}

export { isComponentEmpty, setAsContainedComponent, areAllComponentsEmpty, isEmptyContainer, areEqual };
