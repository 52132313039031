import React from "react";

import * as S from "./style";

import { Icon, Tooltip } from "@ax/components";

const HiddenField = ({ title, showField, hasMultipleOptions }: IHiddenFieldProps): JSX.Element => {
  return hasMultipleOptions ? (
    <S.WrapperMultipleOptions onClick={showField} data-testid="field-multiple-options">
      <Icon name="add" />
    </S.WrapperMultipleOptions>
  ) : (
    <S.Wrapper onClick={showField} data-testid="field-single-option">
      <S.Label>{title}</S.Label>
      <Tooltip content="Show">
        <Icon name="add" />
      </Tooltip>
    </S.Wrapper>
  );
};

export interface IHiddenFieldProps {
  title: string;
  showField: any;
  hasMultipleOptions?: boolean;
}

export default HiddenField;
