import React from "react";
import { connect } from "react-redux";

import { IRootState } from "@ax/types";
import { menuActions } from "@ax/containers/Navigation/Menu";
import { FieldsBehavior } from "@ax/components";

const ConnectedField = (props: IProps) => {
  const { form, type, name, updateFormValue, setIsGalleryOpened } = props;

  const value = form[name];

  const handleChange = (newValue: any) => updateFormValue({ [name]: newValue });

  const fieldProps = {
    value,
    onChange: handleChange,
    setIsGalleryOpened: type === "ImageField" ? setIsGalleryOpened : undefined,
    ...props,
  };

  return <FieldsBehavior fieldType={type} {...fieldProps} />;
};

interface IProps {
  form: any;
  type: string;
  title: string;
  name: string;
  mandatory?: boolean;
  helptext?: string;
  options?: any;
  updateFormValue: (value: any) => void;
  setIsGalleryOpened?: (isGalleryOpened: boolean) => void;
}

const mapStateToProps = (state: IRootState) => ({
  form: state.menu.form,
});

const mapDispatchToProps = {
  updateFormValue: menuActions.updateFormValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedField)
