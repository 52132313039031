import React from "react";
import { connect } from "react-redux";
import FroalaEditorComponent from "react-froala-wysiwyg";
import { decodeEntities } from "@ax/helpers";
import { IImage, ISite, IRootState } from "@ax/types";
import { galleryActions } from "@ax/containers/Gallery";

import { wysiwygConfig, buttons, buttonsFull } from "./config";
import "./vendors";
import * as S from "./style";

const Wysiwyg = (props: IWysiwygProps): JSX.Element => {
  const {
    value,
    error,
    onChange,
    placeholder,
    full = true,
    disabled,
    handleValidation,
    site,
    token,
    uploadImage,
  } = props;

  const imageSite = site ? site.id : "global";

  const handleChange = (model: string) => {
    onChange(model);
    const stripedHtml = decodeEntities(model);
    error && handleValidation && handleValidation(stripedHtml);
  };

  const dynamicConfig = {
    placeholderText: placeholder,
    toolbarButtons: full ? buttonsFull : buttons,
    imageUpload: full ? true : false,
    requestHeaders: {
      Authorization: `bearer ${token}`,
    },
    events: {
      initialized() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const editor: any = this;
        if (disabled) {
          setTimeout(() => {
            editor.edit.off();
          }, 1000);
        }
      },
      "image.beforeUpload": async function (images: FileList) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const editor: any = this;
        const response = await uploadImage(images[0], imageSite);
        if (response) {
          editor.image.insert(response.url, null, null, editor.image.get());
        } else {
          editor.image.remove(editor.image.get());
        }
        return false;
      },
      blur: function () {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const editor: any = this;
        const html = editor.html.get();
        const stripedHtml = decodeEntities(html);
        handleValidation && handleValidation(stripedHtml);
      },
    },
  };

  const config = { ...wysiwygConfig, ...dynamicConfig };

  return (
    <S.EditorWrapper error={error} disabled={disabled} data-testid="wysiwyg-wrapper">
      <FroalaEditorComponent tag="textarea" model={value} config={config} onModelChange={handleChange} />
    </S.EditorWrapper>
  );
};

interface IProps {
  name: string;
  value: string;
  title: string;
  full?: boolean;
  helptext?: string;
  error?: boolean;
  placeholder?: string;
  token: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  handleValidation?: (value: string) => void;
  site: ISite;
}

const mapStateToProps = (state: IRootState) => ({
  token: state.app.token,
});

interface IDispatchProps {
  uploadImage: (imageFile: File, site: number | string) => Promise<IImage>;
}

const mapDispatchToProps = {
  uploadImage: galleryActions.uploadImage,
};

export type IWysiwygProps = IProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(Wysiwyg);
