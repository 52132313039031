import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 1px solid ${(p) => p.theme.color.uiLine};
  border-radius: 4px;
  background-color: ${(p) => p.theme.color.uiBackground02};
  min-height: ${(p) => p.theme.spacing.l};
  padding: ${(p) => p.theme.spacing.xs};
  width: 100%;
  &:focus-within {
    border-color: ${(p) => p.theme.color.interactive01};
  }
`;

const Input = styled.input`
  ${(p) => p.theme.textStyle.fieldContent};
  color: ${(p) => p.theme.color.textHighEmphasis};
  background: ${(p) => p.theme.color.uiBackground02};
  border: none;
  &:focus {
    border: none;
    outline: none;
  }
  &::placeholder {
    color: ${(p) => p.theme.color.textLowEmphasis};
  }
`;

export { Wrapper, Input };
