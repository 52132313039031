import { useState } from "react";
import { IQueryValue } from "@ax/types";

const useSortedListStatus = () => {
  const sortedInitialState: { isAscending: boolean; sortedByTitle: boolean } = {
    isAscending: false,
    sortedByTitle: false,
  };

  const [sortedListStatus, setSortedListStatus] = useState(sortedInitialState);

  return {
    sortedListStatus,
    setSortedListStatus,
  };
};

const useFilterQuery = () => {
  const initialQueryValues: Record<string, IQueryValue[]> = {
    filterApplication: [{ value: "all", label: "All" }],
    filterState: [{ value: "all", label: "All" }],
    filterCode: [{ value: "all", label: "All" }],
  };

  const [query, setQuery] = useState(initialQueryValues);
  const [currentFilterQuery, setCurrentFilterQuery] = useState("");

  const setFilterQuery = (filterValues: Record<string, IQueryValue[]>) => {
    const { filterApplication, filterState, filterCode } = filterValues;
    let filterQuery = "";

    const currentQuery = (pointer: string, values: IQueryValue[]): string => {
      const stringValues = Array.isArray(values)
        ? values.map((value) => (value.value !== "all" ? value.value : "")).join(",")
        : "";

      return !stringValues.length ? filterQuery : filterQuery.concat(`&${pointer}=${stringValues}`);
    };

    const isNotInitialValue = (pointer: "filterApplication" | "filterState" | "filterCode") => {
      return filterValues[pointer] && initialQueryValues[pointer] !== filterValues[pointer];
    };

    if (isNotInitialValue("filterApplication")) filterQuery = currentQuery("filterApplication", filterApplication);
    if (isNotInitialValue("filterState")) filterQuery = currentQuery("filterState", filterState);
    if (isNotInitialValue("filterCode")) filterQuery = currentQuery("filterCode", filterCode);

    setCurrentFilterQuery(filterQuery);
  };

  const setFiltersSelection = (pointer: string, filter: IQueryValue[]) => {
    const { filterApplication, filterState, filterCode } = query;
    const filterValues = {
      filterApplication: pointer === "filterApplication" ? filter : filterApplication,
      filterState: pointer === "filterState" ? filter : filterState,
      filterCode: pointer === "filterCode" ? filter : filterCode,
    };

    setQuery(filterValues);
    setFilterQuery(filterValues);
  };

  const resetFilterQuery = () => {
    setQuery(initialQueryValues);
    setCurrentFilterQuery("");
  };

  const isFiltered = Object.keys(query).some((key: any) => query[key as never] !== initialQueryValues[key as never]);

  return {
    setFiltersSelection,
    resetFilterQuery,
    filterValues: query,
    filterQuery: currentFilterQuery,
    isFiltered,
  };
};

export { useSortedListStatus, useFilterQuery };
