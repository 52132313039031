import React, { useEffect, useState, forwardRef } from "react";

import { isValidDate, stringToDate, getStringifyDateRange, getRange, isValidDateRange } from "@ax/helpers";

import { Icon, IconAction } from "@ax/components";

import * as S from "./style";

const DatePickerInput = (props: IDatePickerProps, ref: any): JSX.Element => {
  const { dates, onClick, handleChange, isOpen, error, disabled, handleValidation, validators } = props;
  const { start, end } = dates;
  const placeholder = "dd/mm/yyyy";

  const currentDate = getStringifyDateRange(start, end);

  const [value, setValue] = useState(currentDate);
  useEffect(() => setValue(currentDate), [dates, currentDate]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setValue(inputValue);
    const range = getRange(inputValue);
    const isRange = range.length === 2;

    if (isRange && isValidDateRange(inputValue)) {
      const start = stringToDate(range[0]);
      const end = stringToDate(range[1]);
      handleChange([start, end]);
    } else {
      if (isValidDate(inputValue)) {
        const date = stringToDate(inputValue);
        handleChange([date, null]);
      } else if (!inputValue) {
        handleChange([null, null]);
      }
    }
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    handleValidation && handleValidation(inputValue, validators);
  };

  const CalendarIcon = <IconAction icon="calendar" onClick={onClick} disabled={disabled} />;
  const CloseIcon = (
    <S.IconWrapper>
      <Icon name="close" size="16" />
    </S.IconWrapper>
  );

  return (
    <S.InputWrapper error={error}>
      <S.Input
        value={value || ""}
        placeholder={placeholder}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        disabled={disabled}
      />
      <S.ButtonWrapper>{isOpen ? CloseIcon : CalendarIcon}</S.ButtonWrapper>
    </S.InputWrapper>
  );
};

interface IDatePickerProps {
  dates: any;
  handleChange: (value: any) => void;
  onClick?: (e: any) => void;
  isOpen: boolean;
  error?: boolean;
  disabled?: boolean;
  handleValidation?: (value: string, validators?: Record<string, unknown>) => void;
  validators?: Record<string, unknown>;
}

export default forwardRef(DatePickerInput);
