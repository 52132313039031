import React, { useState } from "react";

import { IGlobalSettings } from "@ax/containers/App/reducer";
import { useModal } from "@ax/hooks";
import { Button, FieldsBehavior, ErrorToast } from "@ax/components";
import RecoveryModal from "./RecoveryModal";

import * as S from "./style";

declare global {
  interface Window {
    handleErrorClick: () => void;
  }
}

const LoginForm = (props: ILoginFormProps): JSX.Element => {
  const {
    handleSubmit,
    email,
    handleEmail,
    password,
    handlePassword,
    isLoggingIn,
    settings,
    rememberMe,
    handleRememberMe,
  } = props;

  const [viewPass, setViewPass] = useState(false);
  const { isOpen, toggleModal } = useModal();

  window.handleErrorClick = toggleModal;

  const _handleEmail = (e: string) => handleEmail(e);

  const _handlePwd = (e: string) => handlePassword(e);

  const _togglePassword = () => setViewPass(!viewPass);

  const btnText = isLoggingIn ? "Sending" : "Login";

  const inputType = viewPass ? "text" : "password";
  const icon = viewPass ? "hide" : "view";

  const textName = settings.welcomeText2 ? settings.welcomeText2 : "Griddo";
  const nameLogo =
    textName === "Griddo" ? <img src="/img/logos/logoGriddoExtended@3x.svg" alt="Griddo Logo" /> : textName;

  return (
    <S.Wrapper>
      <S.Main>
        <S.Header>
          <S.Title>
            <div>Welcome to</div>
            <div>{nameLogo}</div>
          </S.Title>
          <S.Subtitle>To start using {textName}, introduce your email and password.</S.Subtitle>
        </S.Header>
        <ErrorToast />
        <S.Form onSubmit={handleSubmit}>
          <FieldsBehavior
            fieldType="TextField"
            title="Email"
            autoComplete="email"
            value={email}
            onChange={_handleEmail}
            name="email"
            inversed={true}
            placeholder="Type your email"
          />
          <FieldsBehavior
            fieldType="TextField"
            title="Password"
            inputType={inputType}
            value={password}
            onChange={_handlePwd}
            autoComplete="current-password"
            icon={icon}
            onClickIcon={_togglePassword}
            iconPosition="in"
            name="password"
            inversed={true}
          />
          <S.Actions>
            <FieldsBehavior
              fieldType="UniqueCheck"
              title=""
              value={rememberMe}
              onChange={handleRememberMe}
              options={[{ title: "Remember me" }]}
              name="rememberMe"
              inversed={true}
            />
            <S.Password>
              <span
                onClick={toggleModal}
                onKeyDown={toggleModal}
                role="checkbox"
                aria-checked="false"
                tabIndex={0}
                data-testid="forgot-button"
              >
                Lost your password?
              </span>
            </S.Password>
          </S.Actions>
          <Button type="submit" disabled={isLoggingIn ? true : false}>
            {btnText}
          </Button>
          <RecoveryModal isOpen={isOpen} toggleModal={toggleModal} />
        </S.Form>
      </S.Main>
      <S.Secuoyas>
        Made with care at{" "}
        <a href="https://www.secuoyas.com/" target="_blank" rel="noopener noreferrer">
          <img src="/img/logos/logoSQY.svg" alt="Secuoyas logo" />
        </a>
      </S.Secuoyas>
    </S.Wrapper>
  );
};

export interface ILoginFormProps {
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  email: string;
  handleEmail: (email: string) => void;
  password: string;
  handlePassword: (password: string) => void;
  isLoggingIn: boolean;
  settings: IGlobalSettings;
  rememberMe: boolean;
  handleRememberMe: () => void;
}

export default LoginForm;
