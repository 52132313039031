import React from "react";

import { IIntegration, IModal } from "@ax/types";
import { Modal } from "@ax/components";

import { getIntegrationsNames } from "./utils";
import * as S from "./style";

const DeleteModal = (props: IModal & { integrations: IIntegration[]; selectedIds: number[] }): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction, integrations, selectedIds } = props;

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Delete Add-ons"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
    >
      <S.ModalContent>
        Are you sure you want to delete {getIntegrationsNames(integrations, selectedIds)}? This action{" "}
        <strong>cannot be undone</strong>.
      </S.ModalContent>
    </Modal>
  );
};

const DeactivateModal = (props: IModal & { integrations: IIntegration[]; selectedIds: number[] }): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction, integrations, selectedIds } = props;

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Deactivate Add-ons"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
    >
      <S.ModalContent>
        Are you sure you want to disable {getIntegrationsNames(integrations, selectedIds)}? These add-ons will stop
        working in the pages previously added.
      </S.ModalContent>
    </Modal>
  );
};

export { DeleteModal, DeactivateModal };
