const formatIframe = (iframeToFormat: string, firstConversion?: boolean) => {
  let iframeFormatted: string = iframeToFormat;

  if (firstConversion) {
    if (iframeFormatted.match(/<iframe[^>]*?(?:\/>|>[^<]*?<\/iframe>)/g)) {
      iframeFormatted = iframeToFormat.replaceAll("<iframe", "&lt;iframe");
      iframeFormatted = iframeFormatted.replaceAll("></iframe>", "&gt;&lt;/iframe&gt;");
    }
  } else {
    if (iframeToFormat.match(/(?:&lt;iframe[^>]*)/g)) {
      iframeFormatted = iframeToFormat.replaceAll("&lt;iframe", "<iframe");
      iframeFormatted = iframeFormatted.replaceAll("&gt;&lt;/iframe&gt;", "></iframe>");
      iframeFormatted = iframeFormatted.replace(/&quot;/g, '"');
      iframeFormatted = iframeFormatted.replace(/&amp;/g, "&");

      const urlFromIframe: string =
        iframeFormatted.split("src=")[1].split(/"/g)[1] === "<a href="
          ? iframeFormatted.split("src=")[1].split(/"/g)[2]
          : iframeFormatted.split("src=")[1].split(/"/g)[1];

      iframeFormatted = iframeFormatted.replace(
        /(src="|src='|src=”<a.*?>).*?(<\/a>'|<\/a>"|<\/a>”)/,
        `src="${urlFromIframe}"`
      );
    }
  }
  return iframeFormatted;
};

export { formatIframe };
