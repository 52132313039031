import React from "react";

import ImageSelection, { IImageSelectionProps } from "./ImageSelection";
import ScrollableSelection, { IScrollableSelectionProps } from "./ScrollableSelection";

const VisualUniqueSelection = (props: IImageSelectionProps & IScrollableSelectionProps): JSX.Element => {
  const { elementUniqueSelection = false } = props;
  const Component = elementUniqueSelection ? ScrollableSelection : ImageSelection;

  return <Component {...props} />;
};

export default VisualUniqueSelection;
