import styled from "styled-components";

const Wrapper = styled.div<{ background: boolean | undefined }>`
  background: ${(p) => (p.background ? p.theme.colors.uiBackground03 : "none")};
  border-radius: ${(p) => (p.background ? p.theme.spacing.xxs : 0)};
  padding: ${(p) => (p.background ? p.theme.spacing.s : 0)};
  display: ${(p) => (p.background ? "flex" : "block")};
`;

const Input = styled.input`
  display: none;

  &:checked + Label:after {
    left: 58%;
  }

  &:checked + Label {
    background: ${(p) => p.theme.color.interactive01};
  }

  &:disabled + Label {
    pointer-events: none;
    opacity: 40%;
  }
`;

const Label = styled.label<{ size?: string }>`
  box-sizing: border-box;
  outline: 0;
  display: block;
  width: ${(p) => (p.size === "m" ? "4em" : "40px")};
  height: ${(p) => (p.size === "m" ? "2em" : "18px")};
  position: relative;
  cursor: pointer;
  background: ${(p) => p.theme.color.interactiveDisabled};
  border-radius: ${(p) => (p.size === "m" ? "2em" : " 2em")};
  padding: ${(p) => (p.size === "m" ? "4px" : " 2px")};
  transition: all 0.4s ease;

  &::selection {
    background: none;
  }

  &:after {
    border-radius: 50%;
    background: ${(p) => p.theme.color.interactiveBackground};
    transition: all 0.2s ease;
  }

  &:after,
  &:before {
    position: relative;
    display: block;
    content: "";
    width: ${(p) => (p.size === "m" ? "42%" : "14px")};
    height: ${(p) => (p.size === "m" ? "100%" : "14px")};
  }

  &:after {
    left: 0;
  }

  &:before {
    display: none;
  }
`;

const AuxText = styled.span`
  ${(p) => p.theme.textStyle?.uiXS};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  padding-left: ${(p) => p.theme.spacing.xs};
`;

export { Wrapper, Input, Label, AuxText };
