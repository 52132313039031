import React from "react";
import { connect } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";

import { IRootState } from "@ax/types";
import { ErrorPage, Loading } from "@ax/components";

import Routing from "./Routing";
import Style from "../../Style";

const App = (props: IProps) => {
  const { isRehydrated } = props;

  return (
    <Style>
      <ErrorBoundary fallback={<ErrorPage type="wrong" />}>
        {isRehydrated ? (
          <>
            <Routing />
          </>
        ) : (
          <Loading />
        )}
      </ErrorBoundary>
    </Style>
  );
};

function mapStateToProps(state: IRootState) {
  return {
    isRehydrated: state.app.isRehydrated,
  };
}

interface IProps {
  isRehydrated: boolean;
}

export default connect(mapStateToProps)(App);
