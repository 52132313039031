import React, { useEffect } from "react";
import { connect } from "react-redux";

import { ILogoutAction } from "@ax/containers/App/interfaces";
import { appActions } from "@ax/containers/App";

const Logout = (props: IProps) => {
  const { logout, setHistoryPush } = props;

  useEffect(() => {
    setHistoryPush("/login");
    return () => {
      logout();
    };
  }, [logout, setHistoryPush]);

  return <></>;
};

interface IProps {
  logout(): ILogoutAction;
  setHistoryPush(path: string, isEditor?: boolean): Promise<void>;
}

const mapDispatchToProps = {
  logout: appActions.logout,
  setHistoryPush: appActions.setHistoryPush,
};

export default connect(null, mapDispatchToProps)(Logout);
