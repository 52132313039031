import React from "react";

import PageBrowser from "../PageBrowser";
import * as S from "./style";

const PreviewBrowser = () => {
  return (
    <S.BrowserWrapper>
      <PageBrowser isTemplateActivated={true} isReadOnly={true} isPreview={true} />
    </S.BrowserWrapper>
  );
};

export default PreviewBrowser;
