import styled from "styled-components";

const ListItem = styled.li`
  display: flex;
  width: 100%;
  padding: ${(p) => p.theme.spacing.s};
  background-color: ${(p) => p.theme.color.uiBarBackground};
  border: 1px solid ${(p) => p.theme.color.uiLine};
  border-radius: ${(p) => p.theme.radii.s};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  :hover {
    background-color: ${(p) => p.theme.color.overlayHoverPrimary};
    cursor: pointer;
  }
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.fieldContent}
  color: ${(p) => p.theme.colors.textHighEmphasis};
`;

const Header = styled.div`
  display: flex;
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const Type = styled.div`
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.colors.textLowEmphasis};
`;

const Date = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.colors.textLowEmphasis};
  margin-left: auto;
`;

const CheckWrapper = styled.div`
  display: flex;
`;

const Content = styled.div`
  width: 100%;
`;

export { ListItem, Title, Header, Type, Date, CheckWrapper, Content };
