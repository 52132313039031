import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${(p) => p.theme.color.uiBackground04};
`;

const BackgroundAnimation = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 900px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  z-index: 1;
`;

const Logo = styled.img`
  display: block;
  max-height: 46px;
  height: 100%;
  width: auto;
`;

const ErrorNumber = styled.div`
  font-size: 228px;
  line-height: 228px;
  letter-spacing: 32px;
  color: ${(p) => p.theme.color.textHighEmphasisInverse};
  text-align: center;
`;

const ErrorTitle = styled.div`
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  color: ${(p) => p.theme.color.textHighEmphasisInverse};
  text-align: center;
  margin-bottom: ${(p) => p.theme.spacing.xs};
  text-transform: uppercase;
  letter-spacing: 0.75px;
`;

const ErrorTitleWrong = styled.div`
  ${(p) => p.theme.textStyle.headingM};
  color: ${(p) => p.theme.color.textHighEmphasisInverse};
  text-align: center;
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const ErrorDescription = styled.div`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => p.theme.color.textMediumEmphasisInverse};
  text-align: center;
`;

const ErrorDescriptionWrong = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textHighEmphasisInverse};
  text-align: center;
`;

const ErrorAction = styled.div`
  display: flex;
  margin-top: ${(p) => p.theme.spacing.m};
  justify-content: center;
`;

const ContentNumber = styled.div``;

const ContentWrong = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(32, 34, 76, 0.6);
  width: 390px;
  align-items: center;
  padding-top: ${(p) => `calc(${p.theme.spacing.m} + ${p.theme.spacing.xs})`};
  padding-bottom: ${(p) => `calc(${p.theme.spacing.m} + ${p.theme.spacing.xs})`};
  padding-left: ${(p) => p.theme.spacing.m};
  padding-right: ${(p) => p.theme.spacing.m};
  margin-top: ${(p) => `calc(${p.theme.spacing.m} + ${p.theme.spacing.s})`};
`;

const IconWrapper = styled.div`
  width: ${(p) => p.theme.spacing.xl};
  background-color: ${(p) => p.theme.color.uiBackground03};
  padding: ${(p) => p.theme.spacing.s};
  border-radius: 50%;
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

export {
  Wrapper,
  Content,
  ErrorNumber,
  ErrorTitle,
  ErrorTitleWrong,
  ErrorDescription,
  ErrorDescriptionWrong,
  Logo,
  ErrorAction,
  ContentNumber,
  ContentWrong,
  BackgroundAnimation,
  IconWrapper,
};
