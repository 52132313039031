import styled from "styled-components";

export const Wrapper = styled.section`
  background: ${p => p.theme.color.uiBackground01};
  height: 100vh;
`;

export const LoadingWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
