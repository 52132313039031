import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { IContentType, IGlobalLanguage, IPage, IRootState, ISite } from "@ax/types";
import { useDebounce } from "@ax/hooks";
import { getStructuredDataTitle, isReqOk } from "@ax/helpers";
import { sites, structuredData } from "@ax/api";
import { Loader, Pagination, TextField, Select, Button, AsyncSelect } from "@ax/components";
import SelectionListItem from "./SelectionListItem";

import * as S from "./style";

const PageFinder = (props: IPageFinderProps): JSX.Element => {
  const { onClick, currentSiteID, isOpen, allSites, lang, globalLangs, multiple, hideSites, pages } = props;

  const langOptions = globalLangs.map((lang) => ({ label: lang.label, value: lang.id.toString() }));

  const initialState = {
    site: currentSiteID || allSites[0].id,
    lang: lang.id,
    type: "all",
    query: "",
    items: [],
    page: 1,
    totalItems: 0,
  };
  const [state, setState] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPages, setSelectedPages] = useState<IPage[]>([]);
  const [selectedPagesIds, setSelectedPagesIds] = useState<number[]>(pages ? pages : []);
  const [showSelected, setShowSelected] = useState(false);
  const debouncedSearch = useDebounce(state.query);

  const allTypesOption = { label: "All content", value: "all" };
  const [contentTypeOptions, setContentTypeOptions] = useState<{ label: string; value: string }[]>([
    { ...allTypesOption },
  ]);

  const changeContentTypeOptions = (types: { label: string; value: string }[]) =>
    setContentTypeOptions([allTypesOption, ...types]);

  const itemsPerPage = 50;

  const resetQuery = () => setState((state) => ({ ...state, query: "" }));

  const changeState = (key: string, value: string | number | null) => setState((state) => ({ ...state, [key]: value }));

  useEffect(() => {
    if (!isOpen) resetQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    let isMounted = true;
    const params = {
      deleted: false,
      filterPages: selectedPagesIds,
      siteID: state.site,
      ignoreLang: true,
    };

    const getSelectedItems = async () => {
      if (!isMounted) return;
      const response: { status: number; data: { totalItems: number; items: any } } = await sites.getSitePages(params);
      if (isReqOk(response.status)) {
        setSelectedPages(response.data.items);
      } else {
        console.log("Error en getSelectedItems");
      }
    };

    if (selectedPagesIds && selectedPagesIds.length > 0) {
      getSelectedItems();
    }

    return function cleanup() {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isMounted = true;
    const params = {
      siteID: state.site,
      deleted: false,
      page: state.page,
      itemsPerPage,
      query: debouncedSearch,
      lang: state.lang,
      filterStructuredData: state.type,
      format: "list",
    };

    const getAndSetItems = async () => {
      if (!isMounted) return;

      setIsLoading(true);
      const response: { status: number; data: { totalItems: number; items: any } } = await sites.getSitePages(params);
      if (isReqOk(response.status)) {
        setState((state) => ({ ...state, items: response.data.items, totalItems: response.data.totalItems }));
      } else {
        console.log("Error en getAndSetItems");
      }
      setIsLoading(false);
    };
    getAndSetItems();

    return function cleanup() {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSiteID, debouncedSearch, state.page, state.lang, state.site, state.type]);

  const getAndSetContentTypes = async () => {
    const { site, lang } = state;
    const { data } = await structuredData.getContentTypes(site, lang);
    const contentTypes = data.items ? data.items.map((type: IContentType) => ({ label: type.title, value: type.id })) : [];
    changeContentTypeOptions(contentTypes);
    changeState("type", "all");
  };

  useEffect(() => {
    getAndSetContentTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.site]);

  const textFieldProps = {
    value: state.query,
    onChange: (search: string) => changeState("query", search),
    placeholder: "Search",
    icon: "search",
    iconPosition: "in",
  };

  const setPage = (newPage: number) => changeState("page", newPage);

  const handleChange = (item: { value: IPage; isChecked: boolean }) => {
    const { value, isChecked } = item;

    let newSelection: IPage[] = [...selectedPages];
    isChecked ? newSelection.push(value) : (newSelection = newSelection.filter((page: IPage) => page.id !== value.id));
    const newSelectionIDs = newSelection.map((page: IPage) => page.id);

    setSelectedPages(newSelection);
    setSelectedPagesIds(newSelectionIDs);

    if (showSelected && newSelection.length === 0) {
      setShowSelected(false);
    }
  };

  const handleSelectedClick = () => setShowSelected(!showSelected);

  const handleButtonClick = () => onClick(selectedPages);

  const getPageList = (items: IPage[]) =>
    items &&
    items.map((page: IPage) => (
      <SelectionListItem
        option={page}
        key={page.id}
        title={page.title}
        type={getStructuredDataTitle(page.structuredData)}
        date={page.modified}
        onClick={onClick}
        pageID={page.id}
        isSelected={selectedPagesIds.includes(page.id)}
        onChange={handleChange}
        multiple={multiple}
      />
    ));

  const showPagination = state.totalItems > itemsPerPage;

  const hasQuery = state.query.length > 0;

  const buttonText = !showSelected ? `view selected (${selectedPages.length})` : "hide selected";

  return (
    <S.Wrapper>
      <S.LanguageSiteFilters>
        <Select
          name="select"
          options={langOptions}
          onChange={(value) => changeState("lang", value)}
          value={state.lang.toString()}
          type="inline"
          mandatory={true}
        />
        {!hideSites && (
          <AsyncSelect
            name="select"
            entity="sites"
            onChange={(value) => changeState("site", value)}
            value={state.site}
            type="inline"
            mandatory={true}
          />
        )}
        {selectedPages.length > 0 && (
          <S.ButtonWrapper>
            <Button type="button" buttonStyle="text" onClick={handleSelectedClick}>
              {buttonText}
            </Button>
          </S.ButtonWrapper>
        )}
      </S.LanguageSiteFilters>
      <S.ContentTypeFilter>
        <S.Text>Content type</S.Text>
        <Select
          name="select"
          options={contentTypeOptions}
          onChange={(value) => changeState("type", value)}
          value={state.type}
          mandatory
        />
      </S.ContentTypeFilter>
      <S.SearchWrapper>
        {hasQuery ? (
          <TextField {...textFieldProps} onClickIcon={resetQuery} icon="close" />
        ) : (
          <TextField {...textFieldProps} />
        )}
      </S.SearchWrapper>
      <S.LowText>Most recent content</S.LowText>
      {isLoading ? (
        <S.LoadingWrapper>
          <Loader name="circle" />
        </S.LoadingWrapper>
      ) : (
        <S.ListWrapper>
          <S.PageList>{getPageList(showSelected ? selectedPages : state.items)}</S.PageList>
          <S.PaginationWrapper>
            {showPagination && (
              <Pagination
                size="S"
                setPage={setPage}
                totalItems={state.totalItems}
                itemsPerPage={itemsPerPage}
                currPage={state.page}
              />
            )}
          </S.PaginationWrapper>
        </S.ListWrapper>
      )}
      {selectedPages.length > 0 && (
        <S.ActionWrapper>
          <Button type="button" onClick={handleButtonClick}>
            Add pages
          </Button>
        </S.ActionWrapper>
      )}
    </S.Wrapper>
  );
};

interface IPageFinderProps {
  onClick: (value: IPage | IPage[]) => void;
  currentSiteID: number | null;
  isOpen: boolean;
  allSites: ISite[];
  lang: { locale: string; id: number };
  globalLangs: IGlobalLanguage[];
  multiple?: boolean;
  hideSites?: boolean;
  pages?: number[];
}

const mapStateToProps = (state: IRootState) => ({
  currentSiteID: state.sites.currentSiteInfo && state.sites.currentSiteInfo.id,
  allSites: state.sites.sites,
  lang: state.app.lang,
  globalLangs: state.app.globalLangs,
});

export default connect(mapStateToProps)(PageFinder);
