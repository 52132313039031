export type GriddoAlertRegisterProps = {
	/** Public API URL which can be obtained from useSite() */
	publicApiUrl: string;
	/** Error type: E: error, W: warning, I: info */
	level: "E" | "W" | "I";
	/** Generic area related to the alert for later grouping in queries */
	area?: string;
	/** Detailed error message. */
	description: string;
	/** An object where you can include all the error details you find convenient. */
	fullData?: Record<string, unknown>;
	/**
	 * Indicates whether we want to send an immediate SNS notification.
	 * If false, it will only be saved in the database log. If true, an SNS
	 * notification is also sent.
	 * The default value will be true if the alert level (level) is E
	 * (Error) and false in all other cases. */
	instantNotification?: boolean;
};

export async function griddoAlertRegister({
	area,
	description,
	fullData,
	instantNotification = false,
	level,
	publicApiUrl,
}: GriddoAlertRegisterProps) {
	const url = `${publicApiUrl}/alert`;
	const body = { level, area, description, fullData, instantNotification };
	const fetchOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(body),
	};

	try {
		await fetch(url, fetchOptions);
	} catch (error) {
		console.error("Error creating Griddo alert:", error);
	}
}
