import styled from "styled-components";
import { Cell, Row } from "@ax/components/TableList/TableItem/style";

const CheckCell = styled(Cell)<{ featured?: boolean }>`
  padding: ${(p) => (p.featured ? p.theme.spacing.s : `12px ${p.theme.spacing.s}`)};
  width: 32px;
  justify-content: ${(p) => (p.featured ? "flex-start" : "center")};
  label {
    margin-bottom: ${(p) => p.theme.spacing.s};
  }
`;

const NameCell = styled(Cell)<{ disabled?: boolean }>`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => (p.disabled ? p.theme.color.interactiveDisabled : p.theme.color.textHighEmphasis)};
  flex-grow: 1;
  padding: ${(p) => `12px ${p.theme.spacing.s}`};
`;

const RolesCell = styled(Cell)`
  padding: ${(p) => `12px ${p.theme.spacing.s}`};
`;

const ActionCell = styled(Cell)`
  flex: 0 0 150px;
  padding: ${(p) => `12px ${p.theme.spacing.s}`};
`;

const SiteItem = styled(Row)<{ disabled?: boolean; readOnly?: boolean; isChecked?: boolean }>`
  pointer-events: ${(p) => (p.disabled ? "none" : "auto")};
  border: ${(p) => (p.isChecked ? `2px solid ${p.theme.color.interactive01}` : `1px solid ${p.theme.color.uiLine}`)};
  :before {
    background-color: transparent;
  }
  &:hover {
    cursor: ${(p) => (p.readOnly ? "default" : "pointer")};
    background-color: ${(p) =>
      p.readOnly
        ? p.theme.color.uiBackground02
        : p.selected
        ? p.theme.color.overlayPressedPrimary
        : p.theme.color.overlayHoverPrimary};
    &:before {
      background-color: ${(p) => (p.readOnly ? "transparent" : p.theme.color.overlayHoverPrimary)};
    }
  }
`;

const WrapperCell = styled(Cell)`
  width: 100%;
`;

const Name = styled.div`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const Description = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

const Actions = styled.div<{ hasTags: boolean }>`
  display: flex;
  align-items: center;
  & > div {
    margin-right: ${(p) => (p.hasTags ? p.theme.spacing.s : "0")};
  }
`;

export { CheckCell, NameCell, RolesCell, ActionCell, SiteItem, WrapperCell, Name, Description, Actions };
