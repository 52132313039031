import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  width: calc(6 * ${(p) => p.theme.spacing.xl});
  right: 0;
  top: 0;
  z-index: 1000;
  height: 100vh;
  background: ${(p) => p.theme.colors.uiBackground01};
  box-shadow: ${(p) => p.theme.shadow.rightPanel};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${(p) => p.theme.spacing.xl};
  width: 100%;
  padding: 0 ${(p) => p.theme.spacing.m};
  background-color: ${(p) => p.theme.colors.uiBackground02};
  border-bottom: 1px solid ${(p) => p.theme.colors.uiLine};
  h6 {
    ${(p) => p.theme.textStyle.headingM}
    color: ${(p) => p.theme.colors.textHighEmphasis};
    text-transform: capitalize;
  }
`;

const Title = styled.h6``;

const Content = styled.div`
  list-style: none;
  padding: ${(p) => p.theme.spacing.m};
  height: ${(p) => `calc(100vh - (${p.theme.spacing.xl} * 2))`};
  width: ${(p) => `calc(${p.theme.spacing.xl} * 6)`};
  overflow: auto;
`;

const ButtonWrapper = styled.div`
  margin: 0 0 0 auto;
`;

const NavigationOptionsWrapper = styled.div`
  margin: ${(p) => p.theme.spacing.m} 0 ${(p) => p.theme.spacing.l};
`;

const NavigationOptionsTitle = styled.div`
  ${(p) => p.theme.textStyle.headingXSS}
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  text-transform: uppercase;
  padding-bottom: ${(p) => p.theme.spacing.xs};
  margin-bottom: ${(p) => p.theme.spacing.s};
  border-bottom: 1px solid ${(p) => p.theme.colors.uiLine};
`;

const Footer = styled.div`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m} 0;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${(p) => p.theme.color.uiLine};
`;

export { Wrapper, Content, Header, Title, ButtonWrapper, NavigationOptionsWrapper, NavigationOptionsTitle, Footer };
