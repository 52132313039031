import React from "react";

import { IModal } from "@ax/types";
import { Modal, FieldsBehavior } from "@ax/components";

import * as S from "./style";

const DeleteModal = (props: IModal): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction } = props;

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Delete category"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
    >
      <S.ModalContent>
        <p>
          Are you sure you want to delete this category? It won't be assignable to new data, and existing data with this
          category will lose it.
          <br />
          This action <strong>cannot be undone</strong>.
        </p>
      </S.ModalContent>
    </Modal>
  );
};

const DeleteGroupModal = (props: IDeleteGroupModal): JSX.Element => {
  const {
    isOpen,
    toggleModal,
    mainModalAction,
    secondaryModalAction,
    deleteGroupCategories,
    setDeleteGroupCategories,
  } = props;

  const options = [
    {
      title: "Delete only the grouping category",
      name: "deleteGroup",
      value: false,
    },
    {
      title: "Delete grouping category and associated categories.",
      name: "deleteAll",
      value: true,
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Delete grouping category"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
    >
      <S.ModalContent>
        <p>
          You are going to delete a grouping category that have some categories associated. Choose if you want to delete
          grouping and categories or only this one.
        </p>
        <FieldsBehavior
          name="deleteGroupCategories"
          fieldType="RadioGroup"
          value={deleteGroupCategories}
          options={options}
          onChange={setDeleteGroupCategories}
        />
      </S.ModalContent>
    </Modal>
  );
};

interface IDeleteGroupModal extends IModal {
  deleteGroupCategories: boolean;
  setDeleteGroupCategories: React.Dispatch<React.SetStateAction<boolean>>;
}

export { DeleteModal, DeleteGroupModal };
