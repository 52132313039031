import React from "react";
import { Icon } from "@ax/components";
import { ListTitle } from "./style";
import * as S from "./style";

const ListItem = (props: ListItemProps): JSX.Element => {
  const { isSelected, children, onClick } = props;

  return (
    <S.StyledListItem isSelected={isSelected} onClick={onClick} data-testid="list-item">
      {children}
      {isSelected && <Icon name="Done" size="16" />}
    </S.StyledListItem>
  );
};

export interface ListItemProps {
  isSelected?: boolean;
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLSpanElement>;
}

export { ListTitle, ListItem };
