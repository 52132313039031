import * as React from "react";
const SvgOpenPanel = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#5057FF"
      fillRule="evenodd"
      d="M21 4v16c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2Zm-2 0H9v16h10V4Zm-6 11-.705-.705L14.585 12l-2.29-2.295L13 9l3 3-3 3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgOpenPanel;
