import styled from "styled-components";
import { ActionMenu } from "@ax/components";
import { Cell, Row } from "@ax/components/TableList/TableItem/style";

const StyledActionMenu = styled(ActionMenu)`
  opacity: 0;
`;

const ItemRow = styled(Row)<{ global?: boolean }>`
  &:hover {
    ${StyledActionMenu} {
      opacity: 1;
    }
  }
`;

const CheckCell = styled(Cell)`
  label {
    margin-bottom: ${(p) => p.theme.spacing.s};
  }
  > div {
    width: ${(p) => p.theme.spacing.s};
  }
`;

const IconCell = styled(Cell)`
  padding: 8px 0;
  img {
    height: 34px;
  }
`;

const NameCell = styled(Cell)`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => p.theme.colors.textHighEmphasis};
  flex-grow: 1;
`;

const TypeCell = styled(Cell)`
  flex: 0 0 80px;
  align-items: center;
  text-transform: uppercase;
`;

const FileType = styled.div`
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  border: ${(p) => `1px solid ${p.theme.color.textMediumEmphasis}`};
  border-radius: ${(p) => p.theme.radii.s};
  padding: ${(p) => `0 ${p.theme.spacing.xxs}`};
`;

const SizeCell = styled(Cell)`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  flex: 0 0 85px;
  align-items: center;
`;

const UpdatedCell = styled(Cell)`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  flex: 0 0 120px;
  align-items: center;
`;

const TagsCell = styled(Cell)`
  flex: 0 0 300px;
`;

const FolderCell = styled(Cell)`
  flex: 0 0 200px;
  align-content: flex-start;
`;

const ActionsCell = styled(Cell)`
  flex: 0 0 70px;
  text-align: center;
`;

export {
  ItemRow,
  StyledActionMenu,
  CheckCell,
  IconCell,
  NameCell,
  TypeCell,
  FileType,
  SizeCell,
  UpdatedCell,
  TagsCell,
  ActionsCell,
  FolderCell,
};
