import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  svg {
    margin-left: 4px;
  }
`;

const ChecksWrapper = styled.div`
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.s};
`;

const Note = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  background-color: ${(p) => p.theme.color.uiBackground03};
  padding: ${(p) => p.theme.spacing.xs};
  border-radius: ${(p) => p.theme.radii.s};
  margin: ${(p) => `0 ${p.theme.spacing.s}`};
`;

export { Wrapper, IconsWrapper, ChecksWrapper, Note };
