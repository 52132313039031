import styled from "styled-components";

const NavItemWrapper = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

const NavLink = styled.div`
  display: flex;
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  align-items: center;
  padding-bottom: ${(p) => p.theme.spacing.xs};
  cursor: pointer;
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
`;

const Arrow = styled.div`
  margin-left: auto;
  height: ${(p) => p.theme.spacing.m};
  width: ${(p) => p.theme.spacing.m};
`;

const Dropdown = styled.ul<{ isOpen: boolean }>`
  display: ${(p) => (p.isOpen ? "flex" : "none")};
  transition: all 0.5s ease-in-out;
  opacity: ${(p) => (p.isOpen ? "1" : "0")};
  flex-direction: column;
`;

const Link = styled.div<{ active: boolean }>`
  color: ${(p) => (p.active ? p.theme.color.textHighEmphasis : p.theme.color.textMediumEmphasis)};
`;

export { NavItemWrapper, NavLink, Title, Arrow, Dropdown, Link };
