import * as React from "react";

function SvgDe(props) {
  return (
    <svg viewBox="0 0 512.001 512.001" {...props}>
      <path d="M512 200.093H0V97.104a8.829 8.829 0 018.828-8.828h494.345a8.829 8.829 0 018.828 8.828L512 200.093z" fill="#464655" />
      <path d="M503.172 423.725H8.828A8.829 8.829 0 010 414.897V311.909h512v102.988a8.828 8.828 0 01-8.828 8.828z" fill="#ffe15a" />
      <path fill="#ff4b55" d="M0 200.091h512v111.81H0z" />
    </svg>
  );
}

export default SvgDe;
