import history from "./history";

import multisite from "./multisite";
import site from "./site";
import publicRoutes from "./publicRoutes";

const privateRoutes = [...multisite, ...site];

const routes = [...privateRoutes, ...publicRoutes];
export interface IRouter {
  path: string;
  component: any;
  name: string;
  routesGroups?: Array<{
    name: string;
    routes: Array<{ path: string; component: any; name: string; permission?: string }>;
    showInNav?: boolean;
  }>;
  showInNav?: boolean;
  icon?: string;
  hideNav?: boolean;
  onClick?: () => void;
  url?: string;
  target?: string;
  permission?: string | string[];
}

export { privateRoutes, publicRoutes, routes, history, multisite, site };
