import { AxiosResponse } from "axios";
import { IIntegration } from "@ax/types";
import { template } from "./config";
import { IServiceConfig, sendRequest } from "./utils";

const SERVICES: { [key: string]: IServiceConfig } = {
  GET_INTEGRATIONS: {
    ...template,
    endpoint: ["/site/", "/integrations"],
    method: "GET",
  },
  GET_INTEGRATION: {
    ...template,
    endpoint: ["/site/", "/integration/"],
    method: "GET",
  },
  CREATE_INTEGRATION: {
    ...template,
    endpoint: "/integration/site/",
    method: "POST",
  },
  UPDATE_INTEGRATION: {
    ...template,
    endpoint: ["/integration/", "/site/"],
    method: "PUT",
  },
  DELETE_INTEGRATION: {
    ...template,
    endpoint: "/integration/",
    method: "DELETE",
  },
  DELETE_INTEGRATION_BULK: {
    ...template,
    endpoint: "/integration/bulk",
    method: "DELETE",
  },
  CHANGE_INTEGRATION_STATE: {
    ...template,
    endpoint: ["/integration/", "/state/"],
    method: "PUT",
  },
  CHANGE_INTEGRATION_STATE_BULK: {
    ...template,
    endpoint: ["/integration/state/", "/bulk"],
    method: "PUT",
  },
  DUPLICATE_INTEGRATION: {
    ...template,
    endpoint: ["/integration/", "/duplicate/"],
    method: "POST",
  },
  ORDER_INTEGRATION: {
    ...template,
    endpoint: ["/integration/", "/order/"],
    method: "PUT",
  },
};

const getIntegrations = async (site: number, params: any = {}): Promise<AxiosResponse> => {
  const { page, itemsPerPage, pagination = false, filter = "", filterState } = params;
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_INTEGRATIONS;

  const stateQuery = filterState ? `&filterState=${filterState}` : "";
  SERVICES.GET_INTEGRATIONS.dynamicUrl = `${host}${prefix}${site}${suffix}?pagination=${pagination}&order=scriptOrder-asc${filter}${stateQuery}`;
  if (pagination) SERVICES.GET_INTEGRATIONS.dynamicUrl += `&page=${page}&itemsPerPage=${itemsPerPage}`;

  return sendRequest(SERVICES.GET_INTEGRATIONS);
};

const getIntegration = async (id: number, siteID: number): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_INTEGRATION;

  SERVICES.GET_INTEGRATION.dynamicUrl = `${host}${prefix}${siteID}${suffix}${id}`;

  return sendRequest(SERVICES.GET_INTEGRATION);
};

const createIntegration = async (data: IIntegration, siteID: number): Promise<AxiosResponse> => {
  const { host, endpoint } = SERVICES.CREATE_INTEGRATION;

  SERVICES.CREATE_INTEGRATION.dynamicUrl = `${host}${endpoint}${siteID}`;

  return sendRequest(SERVICES.CREATE_INTEGRATION, { ...data });
};

const updateIntegration = async (data: IIntegration, siteID: number): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.UPDATE_INTEGRATION;

  SERVICES.UPDATE_INTEGRATION.dynamicUrl = `${host}${prefix}${data.id}${suffix}${siteID}`;

  return sendRequest(SERVICES.UPDATE_INTEGRATION, { ...data });
};

const deleteIntegration = async (id: number): Promise<AxiosResponse> => {
  const { host, endpoint } = SERVICES.DELETE_INTEGRATION;

  SERVICES.DELETE_INTEGRATION.dynamicUrl = `${host}${endpoint}${id}`;

  return sendRequest(SERVICES.DELETE_INTEGRATION);
};

const deleteIntegrationsBulk = async (ids: number[]): Promise<AxiosResponse> => {
  return sendRequest(SERVICES.DELETE_INTEGRATION_BULK, { ids });
};

const changeState = async (id: number, state: "enable" | "disable"): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.CHANGE_INTEGRATION_STATE;

  SERVICES.CHANGE_INTEGRATION_STATE.dynamicUrl = `${host}${prefix}${id}${suffix}${state}`;

  return sendRequest(SERVICES.CHANGE_INTEGRATION_STATE);
};

const changeStateBulk = async (ids: number[], state: "enable" | "disable"): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.CHANGE_INTEGRATION_STATE_BULK;

  SERVICES.CHANGE_INTEGRATION_STATE_BULK.dynamicUrl = `${host}${prefix}${state}${suffix}`;

  return sendRequest(SERVICES.CHANGE_INTEGRATION_STATE_BULK, { ids });
};

const duplicateIntegration = async (id: number, site: number): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.DUPLICATE_INTEGRATION;

  SERVICES.DUPLICATE_INTEGRATION.dynamicUrl = `${host}${prefix}${id}${suffix}${site}`;

  return sendRequest(SERVICES.DUPLICATE_INTEGRATION);
};

const orderIntegration = async (id: number, newOrder: number): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.ORDER_INTEGRATION;

  SERVICES.ORDER_INTEGRATION.dynamicUrl = `${host}${prefix}${id}${suffix}${newOrder}`;

  return sendRequest(SERVICES.ORDER_INTEGRATION);
};

export default {
  getIntegrations,
  createIntegration,
  updateIntegration,
  getIntegration,
  deleteIntegration,
  deleteIntegrationsBulk,
  changeState,
  changeStateBulk,
  duplicateIntegration,
  orderIntegration,
};
