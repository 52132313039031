import React, { memo } from "react";

import { useToast } from "@ax/hooks";
import { Tooltip, IconAction, Toast } from "@ax/components";

const PasteModuleButton = (props: IProps): JSX.Element => {
  const { pasteIntegration } = props;

  const { isVisible, toggleToast, setIsVisible } = useToast();

  const handlePasteModule = async () => {
    pasteIntegration();
    toggleToast();
  };

  return (
    <>
      <Tooltip content="Paste from clipboard">
        <IconAction icon="paste" onClick={handlePasteModule} />
      </Tooltip>
      {isVisible && <Toast message="1 Custom Code pasted from clipboard" setIsVisible={setIsVisible} />}
    </>
  );
};

interface IProps {
  pasteIntegration: () => void;
}

export default memo(PasteModuleButton);
