import styled from "styled-components";

export const Heading = styled.div`
  ${p => p.theme.textStyle.headingXXS};
  margin-top: ${p => p.theme.spacing.m};
  margin-bottom: ${p => p.theme.spacing.xs};
  cursor: default;

  &:empty {
    display: none;
  }
`;

export const SubItem = styled.div`
  display: block;
  ${p => p.theme.textStyle.uiM};
  color: ${p => p.theme.color.textHighEmphasis};
  clear: both;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  > a {
    display: flex;
    padding: ${p => p.theme.spacing.xs};
    border-radius: ${p => p.theme.radii.s};
    color: inherit;

    &:hover {
      background: ${p => p.theme.color.overlayHoverPrimary};
    }
  }
`;

export const Item = styled.article``;

export const Link = styled.div<{ active: boolean }>`
    color: ${p => p.active ? p.theme.color.textHighEmphasis : p.theme.color.textMediumEmphasis};
`;
