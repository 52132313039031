import React, { useMemo } from "react";

import * as S from "./style";

const ToggleField = (props: IToggleFieldProps): JSX.Element => {
  const { name, value, disabled, onChange, size = "m", background = false, auxText } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    if (onChange) {
      onChange(isChecked);
    }
  };

  const id = useMemo(() => Math.random().toString(), []);

  return (
    <S.Wrapper background={background} data-testid="toggle-field-wrapper">
      <S.Input
        id={`toggle-${id}`}
        type="checkbox"
        name={name}
        value={value || false}
        checked={value || false}
        disabled={disabled}
        onChange={handleChange}
        data-testid="toggle-field-input"
      />
      <S.Label htmlFor={`toggle-${id}`} size={background ? "s" : size} />
      {auxText && <S.AuxText data-testid="toggle-auxtext">{auxText}</S.AuxText>}
    </S.Wrapper>
  );
};

export interface IToggleFieldProps {
  name: string;
  value: any;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  size?: "s" | "m";
  background?: boolean;
  auxText?: string;
}

export default ToggleField;
