import React, { memo } from "react";
import { connect } from "react-redux";

import { menuActions } from "@ax/containers/Navigation";
import { sitesActions } from "@ax/containers/Sites";
import { FieldsBehavior } from "@ax/components";
import { IRootState, IMenuForm, ISchemaField } from "@ax/types";
import { getMenuItems } from "@ax/helpers";

import ConnectedField from "./ConnectedField";

const Form = (props: IProps): JSX.Element => {
  const { form, updateFormValue, toggleSecondaryPanel, setIsGalleryOpened } = props;

  const { url, label, type } = form;

  const setLinkValue = (value: any) => updateFormValue({ url: value });
  const setLabelValue = (value: string) => updateFormValue({ label: value });
  const setTypeValue = (value: string) => updateFormValue({ type: value });

  const typeLinkOptions = [
    {
      title: "Link",
      name: "link",
      value: "link",
    },
    {
      title: "Grouping Element",
      name: "group",
      value: "group",
    },
  ];

  const typeLink = type ? type : "link";

  const menuItems = getMenuItems(typeLink);
  const schemaFields = menuItems && menuItems.fields ? menuItems.fields : [];

  const generateFields = (fields: ISchemaField[]) => fields.map((field: ISchemaField) => <ConnectedField {...field } name={field.key} setIsGalleryOpened={setIsGalleryOpened} />);

  return (
    <>
      <FieldsBehavior
        title="Type"
        name="quantityGroup"
        fieldType="RadioGroup"
        options={typeLinkOptions}
        value={typeLink}
        onChange={setTypeValue}
      />
      <FieldsBehavior
        title="Navigation Label"
        name="navigationLabel"
        fieldType="TextField"
        autoComplete="nav-label"
        value={label || ""}
        onChange={setLabelValue}
      />
      {type === "link" ? (
        <FieldsBehavior
            title="Link"
            name="link"
            fieldType="UrlField"
            value={url || null}
            onChange={setLinkValue}
            advanced={true}
            handlePanel={toggleSecondaryPanel}
            inFloatingPanel={true}
          />
      ) : null }
      {generateFields(schemaFields)}
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  form: state.menu.form,
});

const mapDispatchToProps = {
  updateFormValue: menuActions.updateFormValue,
  getSitePages: sitesActions.getSitePages,
};

interface IStateProps {
  form: IMenuForm;
}

interface IFormProps {
  toggleSecondaryPanel(): any;
  setIsGalleryOpened?: (isGalleryOpened: boolean) => void;
}
interface IDispatchProps {
  updateFormValue(value: any): void;
}

type IProps = IDispatchProps & IStateProps & IFormProps;

const memoizedForm = memo(Form);

export default connect(mapStateToProps, mapDispatchToProps)(memoizedForm);
