const ERRORS: Record<string, string> = {
  ERR001: "Please, fill me. This field is required.",
  ERR002: "At least {value} characters are required.",
  ERR003: "Maximum {value} characters allowed.",
  ERR004: "Oh no! This doesn't look like an email",
  ERR005: "Remember using this structure: http://website.com",
  ERR006: "Remember using a valid URL format",
  ERR007: "Mmm, that doesn't look like a phone to me.",
  ERR008: "Please, enter a value bigger than {value}",
  ERR009: "Please, enter a value smaller than {value}",
  ERR010: "Please, enter a future date.",
  ERR011: "Please, enter a past date.",
  ERR012: "Please, enter at least {value} elements to look great.",
  ERR013: "Oh! This looks so empty. Please add at least an element.",
  ERR014: "Sorry, this page does not exist anymore. Pick another one.",
  ERR015: "Oh sorry, we required this field to continue.",
  ERR016: "This field is still filled with mockup data.",
  ERR017: "The URL is already in use.",
  ERR018: "No H1 in this page.",
  ERR019: "Not enough entries published. {value} items required",
  ERR020: "Sorry, email or password are not correct. ",
  ERR021: "Sorry, we need some info to let you in. Check the fields below.",
  ERR022: "Sorry, this page has not been published. We are working to solve it.",
  ERR023: "There are some errors on the page so you can not publish yet. Please review them in the error panel.",
  ERR024: "There are some errors on the page. Please review them in the error panel.",
  ERR025: "You are having connection problems. Check your connection to continue working.",
  ERR026: "Sorry! We are having server problems. Give us some minutes to fix it and contact the system responsible.",
  ERR027: "Sorry, we are having unexpected problems. Please try again later or contact the system responsible.",
  ERR028: "Sorry, we need al least a language to work with. Please add one.",
  ERR029: "Sorry, we need you to fill the required fields.",
  ERR030: "There is another language using this folder. Please, write a new one.",
  ERR031: "There is already content associated to this language. Please delete it first.",
  ERR032: "Oh, oh, we cannot work without at least one draft state.",
  ERR033: "Be aware that content in the deleted state has no state now.",
  ERR034: "Color must be indicated in hexadecimal, for example #fffff",
  ERR035: "Sorry, there is some errors in fields. Please, check it out!",
  ERR036: "Sorry, there are too many elements. Add at most {value}.",
  ERR037: "Sorry, we can not delete this content. This content is in use.",
  ERR038: "Sorry, the content you are trying to link is broken or unpublished.",
  ERR039: "Sorry, this color doesn't exist. Please add new one.",
  ERR040: "Sorry, the file is not in a valid format.",
  ERR041: "Sorry, the password doesn’t match.",
  ERR042: "This content is part of disabled content type package. To publish it, you must first activate it.",
  ERR043: "Please, enter a valid date.",
};

export { ERRORS };
