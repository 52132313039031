import styled from "styled-components";
import { Header } from "@ax/components/TableList/style";

const TableHeader = styled.div<{ isScrolling?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: ${(p) => p.theme.spacing.m};
  border-bottom: ${(p) => (p.isScrolling ? `1px solid ${p.theme.color.uiLine};` : "")};
`;

const CheckHeader = styled(Header)`
  padding-left: ${(p) => p.theme.spacing.m};
  width: 32px;
`;

const NameWrapper = styled.div`
  width: 400px;
  position: relative;
`;

const PermissionsWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  position: relative;
`;

const ActionsHeader = styled(Header as any)<{ isSiteView: boolean }>`
  display: flex;
  width: ${(p) => (p.isSiteView ? "210px" : 'auto')};
  justify-content: end;
  padding-right: 0;
`;

const UsersWrapper = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  position: relative;
`;

export { TableHeader, CheckHeader, NameWrapper, PermissionsWrapper, ActionsHeader, UsersWrapper };
