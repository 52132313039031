import React from "react";

function SvgDone(props) {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" fill="#5057FF" />
    </svg>
  );
}

export default SvgDone;
