import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  min-width: calc(${(p) => p.theme.spacing.xl} * 2);
  max-width: calc(${(p) => p.theme.spacing.xl} * 6);
  z-index: 2;
`;

const Field = styled.div<{ isOpen: boolean; disabled?: boolean }>`
  position: relative;
  ${(p) => p.theme.textStyle.fieldContent};
  color: ${(p) => (p.disabled ? p.theme.color.interactiveDisabled : p.theme.color.textHighEmphasis)};
  display: flex;
  width: 100%;
  height: ${(p) => p.theme.spacing.l};
  background-color: ${(p) => p.theme.color.interactiveBackground};
  border: 1px solid
    ${(p) =>
      p.isOpen
        ? p.theme.color.interactive01
        : p.disabled
        ? p.theme.color.interactiveDisabled
        : p.theme.color.uiLine};
  border-radius: ${(p) => p.theme.radii.s};
  align-items: center;
  cursor: pointer;
  padding: 0 ${(p) => p.theme.spacing.s};

  :focus,
  :active {
    border: 1px solid ${(p) => p.theme.color.interactive01};
    outline: none;
  }

  :after {
    position: absolute;
    right: 16px;
    top: ${(p) => (p.isOpen ? `22px` : `18px`)};
    content: "";
    border: solid ${(p) => p.theme.color.interactive01};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: ${(p) => (p.isOpen ? `rotate(-135deg)` : `rotate(45deg)`)};
  }
`;

const DropDown = styled.div<{ floating?: boolean; isOpen: boolean }>`
  background-color: ${(p) => p.theme.color.uiBarBackground};
  border: ${(p) => (p.isOpen ? `1px solid ${p.theme.color.uiLine}` : "none")};
  border-radius: ${(p) => p.theme.radii.s};
  padding: ${(p) => p.isOpen ? `${p.theme.spacing.s} ${p.theme.spacing.xs} ${p.theme.spacing.xs} ${p.theme.spacing.s}` : 0};
  width: 100%;
  z-index: 2;
  height: ${(p) => (p.isOpen ? "100%" : 0)};
  max-height: 130px;
  min-height: 100px;
  overflow: ${(p) => (p.isOpen ? "auto" : "hidden")};
  position: ${(p) => (p.floating ? "absolute" : "block")};
`;

const Asterisk = styled.span`
  color: ${(p) => p.theme.color?.error};
`;

export { Wrapper, Field, DropDown, Asterisk };
