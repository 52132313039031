import * as React from "react";
const SvgMicrosoft = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none" {...props}>
    <path fill="#F35325" d="M1.196.695h6.956v6.957H1.196V.695Z" />
    <path fill="#81BC06" d="M8.848.695h6.956v6.957H8.848V.695Z" />
    <path fill="#05A6F0" d="M1.196 8.348h6.956v6.956H1.196V8.348Z" />
    <path fill="#FFBA08" d="M8.848 8.348h6.956v6.956H8.848V8.348Z" />
  </svg>
);
export default SvgMicrosoft;
