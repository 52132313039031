import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;
  width: 100%;
`;

const FormWrapper = styled.div`
  width: 720px;
  margin: ${(p) => `${p.theme.spacing.m} 0 0 ${p.theme.spacing.m}`};
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};
`;

export { Wrapper, ContentWrapper, FormWrapper, ModalContent };
