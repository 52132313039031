import React from "react";

import { CheckField, TableCounter, Tooltip } from "@ax/components";

import * as S from "./style";

const TableHeader = (props: IProps): JSX.Element => {
  const { isScrolling, selectAllItems, checkState, totalItems, isSearching, setHoverCheck } = props;

  return (
    <S.TableHeader isScrolling={isScrolling}>
      <S.CheckHeader>
        <Tooltip content="Select All Files">
          <CheckField
            key="selectAll"
            name="selectAll"
            value="selectAll"
            onChange={selectAllItems}
            checked={checkState.isAllSelected || checkState.hoverCheck}
            disabled={false}
            error={false}
            setHoverCheck={setHoverCheck}
          />
        </Tooltip>
      </S.CheckHeader>
      <S.NameHeader>Name</S.NameHeader>
      <S.TypeHeader>Type</S.TypeHeader>
      <S.SizeHeader>Size</S.SizeHeader>
      <S.UpdatedHeader>Updated</S.UpdatedHeader>
      <S.TagsHeader>Tags</S.TagsHeader>
      {isSearching && <S.FolderHeader>Folder</S.FolderHeader>}
      <S.CounterHeader>
        <TableCounter totalItems={totalItems} />
      </S.CounterHeader>
    </S.TableHeader>
  );
};

interface IProps {
  isScrolling: boolean;
  totalItems: number;
  selectAllItems: () => void;
  checkState: Record<string, boolean>;
  isSearching: boolean;
  setHoverCheck: (state: boolean) => void;
}

export default TableHeader;
