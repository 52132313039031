import styled from "styled-components";

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};
  p {
    margin-bottom: ${(p) => p.theme.spacing.m};
  }
  div {
    margin-bottom: 0;
  }
`;

export { ModalContent };
