import React, { memo } from "react";

import { getDisplayName, getThumbnailProps, filterImageText } from "@ax/helpers";
import { Tag } from "@ax/components";

import * as S from "./style";

const getThumbnailData = (option: any, theme: string) => {
  option = filterImageText(option.component ? option.component : option);

  return getThumbnailProps(option, false, theme);
};

const SideModalOption = (props: IProps) => {
  const { option, handleClick, toggleModal, theme } = props;

  const isNavigationDefault = ["header", "footer"].includes(option.type);

  const navigationThumbnail = isNavigationDefault &&
    option.thumbnail && { alt: option.title, src: option.thumbnail.url };
  const thumbnailProps = navigationThumbnail || getThumbnailData(option, theme);

  const optionParam = option.component || option;
  const label = isNavigationDefault ? option.title : getDisplayName(optionParam);

  const setOption = () => {
    if (handleClick) {
      handleClick(option);
      toggleModal();
    }
  };

  const defaultTag = option.tag ? (
    <S.TagWrapper data-testid="side-modal-option-tag">
      <Tag text={option.tag} type="square" />
    </S.TagWrapper>
  ) : null;

  return (
    <S.Item onClick={setOption} data-testid="side-modal-option">
      <S.Thumbnail data-testid="side-modal-option-img" {...thumbnailProps} />
      {label}
      {defaultTag}
    </S.Item>
  );
};

interface IProps {
  option: any;
  handleClick?: (option: any) => void;
  toggleModal: () => void;
  children: string;
  theme: string;
}

export default memo(SideModalOption);
