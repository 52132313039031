const formatTimeItem = (value?: string | number): string => {
  return `${value || ""}00`.substr(0, 2);
};

const validateTimeAndCursor = (value = "", defaultValue = "", cursorPosition = 0): [string, number] => {
  const [oldH, oldM] = defaultValue.split(":");

  let newCursorPosition = Number(cursorPosition);
  let [newH, newM] = String(value).split(":");

  newH = formatTimeItem(newH);
  if (Number(newH[0]) > 1) {
    newH = oldH;
    newCursorPosition -= 1;
  } else if (Number(newH[0]) === 1) {
    if (Number(oldH[0]) === 1 && Number(newH[1]) > 2) {
      newH = `1${oldH[1]}`;
      newCursorPosition -= 2;
    } else if (Number(newH[1]) > 2) {
      newH = "12";
    }
  }

  newM = formatTimeItem(newM);
  if (Number(newM[0]) > 5) {
    newM = oldM;
    newCursorPosition -= 1;
  }

  const validatedValue = `${newH}:${newM}`;

  return [validatedValue, newCursorPosition];
};

export { validateTimeAndCursor };
