import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { getTreePosition } from '../../utils/tree';
import { getDestinationPath, getSourcePath } from '../../utils/flat-tree';
/*
    Translates a drag&drop movement from an index based position to a relative (parent, index) position
*/
export var calculateFinalDropPositions = function calculateFinalDropPositions(tree, flattenedTree, dragState) {
  var source = dragState.source,
    destination = dragState.destination,
    combine = dragState.combine,
    horizontalLevel = dragState.horizontalLevel;
  var sourcePath = getSourcePath(flattenedTree, source.index);
  var sourcePosition = getTreePosition(tree, sourcePath);
  if (combine) {
    return {
      sourcePosition: sourcePosition,
      destinationPosition: {
        parentId: combine.draggableId
      }
    };
  }
  if (!destination) {
    return {
      sourcePosition: sourcePosition,
      destinationPosition: undefined
    };
  }
  var destinationPath = getDestinationPath(flattenedTree, source.index, destination.index, horizontalLevel);
  var destinationPosition = _objectSpread({}, getTreePosition(tree, destinationPath));
  return {
    sourcePosition: sourcePosition,
    destinationPosition: destinationPosition
  };
};