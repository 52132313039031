import React from "react";

import { IModal, ISite } from "@ax/types";
import { Modal, FieldsBehavior, Select, Button } from "@ax/components";

import * as S from "./style";

const DeleteModal = (props: IDeleteModal): JSX.Element => {
  const {
    isOpen,
    toggleModal,
    mainModalAction,
    secondaryModalAction,
    isTranslated,
    deleteAllVersions,
    setDeleteAllVersions,
    title,
  } = props;

  const options = [
    {
      title: "Delete only this version",
      name: "deletePage",
      value: false,
    },
    {
      title: "Delete all languages versions",
      name: "deleteAll",
      value: true,
    },
  ];

  const pageTitle = title ? <strong>{title}</strong> : "this";

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Delete Page?"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
    >
      {isTranslated ? (
        <>
          <S.ModalContent>
            <p>
              You are going to delete {pageTitle} page that have some translations associated. Choose if you want to
              delete all languages versions or only this one.
            </p>
            <FieldsBehavior
              name="removeAllVersions"
              fieldType="RadioGroup"
              value={deleteAllVersions}
              options={options}
              onChange={setDeleteAllVersions}
            />
          </S.ModalContent>
        </>
      ) : (
        <S.ModalContent>
          Are you sure you want to delete {pageTitle} page?
          <br />
          This action <strong>cannot be undone</strong>.
        </S.ModalContent>
      )}
    </Modal>
  );
};

const MainActionButton = (props: IActionButton): JSX.Element => (
  <Button type="button" onClick={props.onClick} disabled={props.disabled}>
    {props.title}
  </Button>
);

const SecondaryActionButton = (props: IActionButton): JSX.Element => (
  <Button type="button" buttonStyle="text" onClick={props.onClick}>
    {props.title}
  </Button>
);

const CopyModal = (props: ICopyModal): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction, setSite, sites, site } = props;
  const sitesOptions = sites.map((site: ISite) => ({ label: site.name, value: site.id.toString() }));

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      size="S"
      title="Copy page in another site"
      mainAction={mainModalAction}
      secondaryAction={secondaryModalAction}
      overflow="visible"
    >
      <S.ModalContent>
        <p>
          <strong>Select a site to copy this page. </strong>
          <br />
          You can only select sites with the same language as this page.
        </p>
        <S.SelectWrapper>
          <Select
            name="select"
            options={sitesOptions}
            onChange={(value: string) => setSite(value)}
            value={site?.toString() || ""}
            mandatory={true}
          />
        </S.SelectWrapper>
      </S.ModalContent>
    </Modal>
  );
};

interface IDeleteModal extends IModal {
  isTranslated: boolean;
  deleteAllVersions: boolean;
  setDeleteAllVersions: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
}

interface IActionButton {
  onClick: () => void;
  title: string;
  disabled?: boolean;
}

interface ICopyModal extends IModal {
  setSite: React.Dispatch<React.SetStateAction<any>>;
  sites: ISite[];
  site: string | null;
}

export { DeleteModal, CopyModal, MainActionButton, SecondaryActionButton };
