import ResetPass from "./../modules/ResetPass";
import CreatePass from "./../modules/CreatePass";
import Login from "./../modules/Login";
import PublicPreview from "./../modules/PublicPreview";

const publicRoutes = [
  { path: "/login/:petitionId", component: Login, name: "Login", hideNav: true },
  { path: "/login", component: Login, name: "Login", hideNav: true },
  { path: "/new-password/:id/:token", component: ResetPass, name: "Reset Password", hideNav: true },
  { path: "/set-password/:id/:token", component: CreatePass, name: "Create Password", hideNav: true },
  { path: "/page-preview/:id/:entity", component: PublicPreview, name: "Preview", hideNav: true },
];

export default publicRoutes;
