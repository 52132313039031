const NAME = "users";

const SET_USERS = `${NAME}/SET_USERS`;
const SET_CURRENT_USER = `${NAME}/SET_CURRENT_USER`;
const SET_USER_FORM = `${NAME}/SET_USER_FORM`;
const SET_ROLES = `${NAME}/SET_ROLES`;
const SET_CURRENT_PERMISSIONS = `${NAME}/SET_CURRENT_PERMISSIONS`;
const SET_GLOBAL_PERMISSIONS = `${NAME}/SET_GLOBAL_PERMISSIONS`;

export { NAME, SET_USERS, SET_USER_FORM, SET_CURRENT_USER, SET_ROLES, SET_CURRENT_PERMISSIONS, SET_GLOBAL_PERMISSIONS };
