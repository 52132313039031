import styled from "styled-components";
import { Header } from "@ax/components/TableList/style";

const TableHeader = styled.div<{ isScrolling?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: ${(p) => p.theme.spacing.m};
  border-bottom: ${(p) => (p.isScrolling ? `1px solid ${p.theme.color.uiLine};` : "")};
`;

const CheckHeader = styled(Header)`
  padding-left: ${(p) => p.theme.spacing.m};
  width: 32px;
`;

const NameHeader = styled(Header)`
  flex-grow: 1;
`;

const SiteHeader = styled(Header)`
  width: 175px;
  justify-content: flex-start;
`;

const LiveHeader = styled(Header)`
  width: 80px;
  justify-content: center;
`;

const StatusHeader = styled(Header)`
  flex: 0 0 170px;
  justify-content: center;
`;

const TypeHeader = styled(Header)`
  flex: 0 0 170px;
  justify-content: center;
`;

const TransHeader = styled(Header)`
  flex: 0 0 115px;
  justify-content: center;
`;

const ActionsHeader = styled(Header)`
  flex: 0 0 85px;
  padding-right: 0;
  padding-left: 0;
  justify-content: flex-end;
  align-items: center;
`;

const SeoHeader = styled(Header)`
  flex: 0 0 124px;
`;

const NameWrapper = styled.div`
  flex-grow: 1;
  position: relative;
`;

const HeaderWrapper = styled.div`
  position: relative;
`;

export {
  TableHeader,
  CheckHeader,
  NameHeader,
  LiveHeader,
  StatusHeader,
  TransHeader,
  ActionsHeader,
  SiteHeader,
  NameWrapper,
  HeaderWrapper,
  SeoHeader,
  TypeHeader,
};
