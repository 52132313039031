import { IDomainRobot } from "@ax/types";
import { template } from "./config";
import { IServiceConfig, sendRequest } from "./utils";

const SERVICES: { [key: string]: IServiceConfig } = {
  GET_ROBOTS: {
    ...template,
    endpoint: "/domains/robots",
    method: "GET",
  },
  UPDATE_ROBOTS: {
    ...template,
    endpoint: "/domains/robots/bulk",
    method: "PUT",
  },
};

const getDomainsRobots = async () => {
  return sendRequest(SERVICES.GET_ROBOTS);
};

const updateDomainsRobots = async (robots: IDomainRobot[]) => {
  return sendRequest(SERVICES.UPDATE_ROBOTS, { robots });
};

export default { getDomainsRobots, updateDomainsRobots };
