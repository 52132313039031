import styled from "styled-components";
import { Cell } from "@ax/components/TableList/TableItem/style";

const BarWrapper = styled.div``;

const Wrapper = styled.div<{ grid: boolean }>`
  display: flex;
  flex-direction: ${(p) => (p.grid ? "column" : "row")};
  border: ${(p) => `1px dashed ${p.theme.color.interactive01}`};
  border-radius: ${(p) => p.theme.radii.s};
  align-items: center;
  padding: ${(p) => (p.grid ? p.theme.spacing.m : `${p.theme.spacing.xs} ${p.theme.spacing.xs} ${p.theme.spacing.xs} 0`)};
  margin-bottom: ${(p) => (p.grid ? "0" : p.theme.spacing.xs)};

  ${BarWrapper} {
    width: ${(p) => (p.grid ? "150px" : "250px")};
    margin: ${(p) => (p.grid ? `${p.theme.spacing.s} 0`: `0 ${p.theme.spacing.m}`)};
  }
`;

const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
`;

const Text = styled.div`
  ${(p) => p.theme.textStyle.fieldLabel};
  color: ${(p) => p.theme.colors.interactive01};
`;

const CheckWrapper = styled(Cell)`
  label {
    margin-bottom: ${(p) => p.theme.spacing.s};
  }
  > div {
    width: ${(p) => p.theme.spacing.s};
  }
`;

export { Wrapper, IconWrapper, BarWrapper, Text, CheckWrapper };
