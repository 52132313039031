import styled from "styled-components";

const Wrapper = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(p) => `0 ${p.theme.spacing.m}`};
  color: ${(p) => p.theme.color.textHighEmphasisInverse};
  ${(p) => p.theme.textStyle.uiM};
  display: ${(p) => (p.isVisible ? "block" : "none")};
  transition: all 0.5s;
  background-color: ${(p) => p.theme.colors.overlayHoverDark};
  border-bottom: 1px solid ${(p) => p.theme.colors.iconLowEmphasisInverse};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: ${(p) => p.theme.spacing.l};
  z-index: 2;
  padding-left: ${(p) => `calc( ${p.theme.spacing.m} + ${p.theme.spacing.s})`};
  padding-right: ${(p) => p.theme.spacing.xxs};

  svg {
    width: ${(p) => p.theme.spacing.s};
    height: ${(p) => p.theme.spacing.s};
    path {
      fill: ${(p) => p.theme.color.textHighEmphasisInverse};
    }
  }
`;

const Text = styled.span``;

const ActionsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const CloseWrapper = styled.span`
  cursor: pointer;
  width: 16px;
  height: 16px;
`;

export { Wrapper, Text, Row, ActionsWrapper, CloseWrapper };
