import React from "react";
import { connect } from "react-redux";
import { appActions } from "@ax/containers/App";
import { pageEditorActions } from "@ax/containers/PageEditor";
import { sitesActions } from "@ax/containers/Sites";
import { structuredDataActions } from "@ax/containers/StructuredData";
import { ISetCurrentPageIDAction } from "@ax/containers/PageEditor/interfaces";
import { IFile, IFileUseItem, IFileUsePages, IRootState } from "@ax/types";
import Item from "./Item";
import ItemGroup from "./ItemGroup";

import * as S from "./style";

const UsageContent = (props: IProps) => {
  const {
    file,
    currentSiteID,
    selectedTab,
    setHistoryPush,
    setCurrentPageID,
    getSite,
    setCurrentDataID,
    setSelectedStructuredData,
  } = props;
  const {
    contentInUse: { pages, simpleStructuredData, globalPages },
  } = file;

  const isSiteView = !!currentSiteID;

  const getPageList = () => {
    if (isSiteView && selectedTab !== "global") {
      const pageItems = pages[0] ? pages[0].pages : [];
      return pageItems.map((item: IFileUseItem) => {
        const handleClick = () => {
          setCurrentPageID(item.id);
          setHistoryPush("pages/editor", true);
        };

        return <Item title={item.title} date={item.published} key={item.id} onClick={handleClick} />;
      });
    } else {
      return pages.map((item: IFileUsePages) => {
        const handleClick = async (page: IFileUseItem) => {
          await getSite(item.siteId);
          setCurrentPageID(page.id);
          setHistoryPush("/sites/pages/editor", true);
        };
        return <ItemGroup title={item.siteName} items={item.pages} onClick={handleClick} />;
      });
    }
  };

  const getGlobalPageList = () => {
    if (globalPages.length === 0) {
      return <></>;
    } else {
      const handleClick = (item: IFileUseItem) => {
        setCurrentPageID(item.id);
        const path = isSiteView ? "/data/pages/editor" : "data/pages/editor";
        setHistoryPush(path, true);
      };
      return <ItemGroup title="Global Pages" items={globalPages} onClick={handleClick} />;
    }
  };

  const getStructuredDataList = () => {
    if (simpleStructuredData.length === 0) {
      return <></>;
    }
    if (isSiteView && selectedTab !== "global") {
      return simpleStructuredData.map((item: IFileUseItem) => {
        const handleClick = () => {
          item.structuredDataId && setSelectedStructuredData(item.structuredDataId, "site");
          setCurrentDataID(item.id);
          setHistoryPush(`data/${item.structuredDataId}/editor`, true);
        };

        return (
          <Item
            title={item.title}
            date={item.published}
            key={item.id}
            type={item.structuredDataId}
            onClick={handleClick}
          />
        );
      });
    } else {
      const handleClick = (item: IFileUseItem) => {
        item.structuredDataId && setSelectedStructuredData(item.structuredDataId, "global");
        setCurrentDataID(item.id);
        setHistoryPush(`/data/${item.structuredDataId}/editor`, true);
      };
      return <ItemGroup title="Global Simple Content" items={simpleStructuredData} onClick={handleClick} />;
    }
  };

  return (
    <S.Content>
      <S.Note>
        Only documents <strong>selected from the File field</strong> are counted. Documents added via a URL are not
        displayed in this list.
      </S.Note>
      <S.ListWrapper>
        {getPageList()}
        {getGlobalPageList()}
        {getStructuredDataList()}
      </S.ListWrapper>
    </S.Content>
  );
};

interface IProps {
  file: IFile;
  currentSiteID: number | null;
  selectedTab: "site" | "global";
  setHistoryPush(page: string, isEditor: boolean): Promise<void>;
  setCurrentPageID(currentPageID: number | null): ISetCurrentPageIDAction;
  getSite(siteID: number): Promise<void>;
  setCurrentDataID(id: number | null): void;
  setSelectedStructuredData(id: string, scope: string): void;
}

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
  setCurrentPageID: pageEditorActions.setCurrentPageID,
  getSite: sitesActions.getSite,
  setCurrentDataID: structuredDataActions.setCurrentDataID,
  setSelectedStructuredData: structuredDataActions.setSelectedStructuredData,
};

const mapStateToProps = (state: IRootState) => ({
  currentSiteID: state.sites.currentSiteInfo && state.sites.currentSiteInfo.id,
  selectedTab: state.fileDrive.selectedTab,
});

export default connect(mapStateToProps, mapDispatchToProps)(UsageContent);
