import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
`;

const Label = styled.div`
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  padding-bottom: ${(p) => p.theme.spacing.xs};
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
`;

const Asterisk = styled.span`
  color: ${(p) => p.theme.color.error};
`;

const ActionWrapper = styled.div`
  position: absolute;
  right: 0;
  top: ${(p) => p.theme.spacing.xxs};
`;

const DataWrapper = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

const SourcesWrapper = styled.div`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => p.theme.colors.textHighEmphasis};
  text-transform: capitalize;
`;

const TypeContainer = styled.div`
  display: flex;
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const ConfigWrapper = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.colors.textHighEmphasis};
`;

const Title = styled.span`
  margin-right: ${(p) => p.theme.spacing.xs};
`;

const ModeWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  min-height: 32px;
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const SingleModeText = styled.span`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.colors.textHighEmphasis};
`;

const Note = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  background-color: ${(p) => p.theme.color.uiBackground03};
  padding: ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.radii.s};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-top: ${(p) => p.theme.spacing.xxs};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  svg {
    vertical-align: middle;
    display: inline-block;
  }
`;

export {
  Asterisk,
  Wrapper,
  Label,
  ActionWrapper,
  DataWrapper,
  TypeContainer,
  SourcesWrapper,
  ConfigWrapper,
  Title,
  ModeWrapper,
  SingleModeText,
  Note,
};
