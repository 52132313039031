import React from "react";
import { getInitials } from "@ax/helpers";

import * as S from "./style";

const Avatar = (props: IAvatarProps): JSX.Element => {
  const { image, name, size } = props;

  return (
    <S.AvatarWrapper data-testid="avatar-wrapper" size={size}>
      <S.Avatar image={image} initials={getInitials(name)} data-testid="avatar" size={size} />
    </S.AvatarWrapper>
  );
};

export interface IAvatarProps {
  image?: string | null;
  name?: string;
  size?: string;
}

export default Avatar;
