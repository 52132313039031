import { Button } from "@ax/components";
import React from "react";
import styled from "styled-components";

const FieldWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.s};
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const DataWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: ${(p) => p.theme.spacing.m};
  height: ${(p) => p.theme.spacing.m};
  svg {
    width: ${(p) => p.theme.spacing.m};
    height: ${(p) => p.theme.spacing.m};
    path {
      fill: ${(p) => p.theme.color.textMediumEmphasis};
    }
  }
`;

const DataTitle = styled.div`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-left: ${(p) => p.theme.spacing.xs};
`;

const StyledButton = styled((props) => <Button {...props} />)`
  margin-left: auto;
`;

export { FieldWrapper, DataWrapper, IconWrapper, DataTitle, StyledButton };
