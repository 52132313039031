import React from "react";

import PageBrowser from "../PageBrowser";
import * as S from "./style";

const PreviewBrowser = (props: IProps) => {
  const { theme } = props;
  return (
    <S.BrowserWrapper>
      <PageBrowser isReadOnly={true} isPreview={true} theme={theme} />
    </S.BrowserWrapper>
  );
};

interface IProps {
  theme: string;
}

export default PreviewBrowser;
