import styled from "styled-components";

const TemplatesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${p => p.theme.spacing.xs};
  padding: ${p => p.theme.spacing.m} 0;
`;

const TemplateCard = styled.div`
  display: flex;
  align-items: center;
  padding: ${(p) => p.theme.spacing.s};
  background-color: ${(p) => p.theme.color.interactiveBackground};
  border-radius: ${p => p.theme.radii.s};
  border: 1px solid ${(p) => p.theme.color.uiLine};
  cursor: pointer;
`;

const TemplateCardTitle = styled.div`
  ${p => p.theme.textStyle.uiMSemibold};
  color: ${p => p.theme.color.textHighEmphasis};
`;

const TemplateThumbnail = styled.div<{ backgroundUrl: string }>`
  background: url(${p => p.backgroundUrl}) no-repeat;
  background-size: cover;
  background-position: top;
  min-height: 86px;
  width: 63px;
  margin-right: ${(p) => p.theme.spacing.s};
`;

const Heading = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  color: ${(p) => p.theme.color.textHighEmphasis};
  padding-bottom: ${(p) => p.theme.spacing.xs};
`;

const Text = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  width: calc(${(p) => p.theme.spacing.l} * 12);
`;

export {
  TemplateCard,
  TemplateCardTitle,
  TemplateThumbnail,
  TemplatesWrapper,
  Heading,
  Text,
}
