import React, { useState } from "react";
import { Button, FieldsBehavior, FloatingPanel, NoteField } from "@ax/components";
import { IIntegrationVariable, ILanguage, IVariableLang } from "@ax/types";

import * as S from "./style";

function VariablePanel(props: IProps): JSX.Element {
  const { onClick, langs, item, isOpen, toggleModal } = props;

  const initialState: IIntegrationVariable = item
    ? item
    : {
        variableName: "",
        variableKey: "",
        defaultValue: "",
        multilanguage: [],
      };

  const [state, setState] = useState<IIntegrationVariable>(initialState);

  const handleNameChange = (value: string) => setState({ ...state, variableName: value });
  const handleKeyChange = (value: string) => setState({ ...state, variableKey: value });
  const handleValueChange = (value: string) => setState({ ...state, defaultValue: value });
  const handleIsMultilanguage = (isMultilanguage: any) => {
    if (isMultilanguage) {
      const updatedMultilanguages = state.multilanguage ? [...state.multilanguage] : [];
      langs.forEach((lang: ILanguage) => updatedMultilanguages.push({ languageId: lang.id, value: "" }));
      setState({ ...state, defaultValue: "", multilanguage: updatedMultilanguages });
    } else {
      setState({ ...state, multilanguage: [] });
    }
  };

  const handleButtonClick = () => {
    onClick(state);
    toggleModal();
  };

  const variableOptions = [
    {
      value: false,
      title: "No",
      name: "no",
    },
    {
      value: true,
      title: "Yes",
      name: "yes",
    },
  ];

  const title = item ? "Edit variable" : "Add Variable";

  return (
    <FloatingPanel title={title} toggleModal={toggleModal} isOpen={isOpen}>
      <S.Wrapper>
        <S.ContentWrapper>
          <S.NoteWrapper>
            <NoteField value={{ title: "", text: "Make sure you include the variable key in the code." }} />
          </S.NoteWrapper>
          <FieldsBehavior
            title="Is this variable language-dependent?"
            name="isMultilanguage"
            fieldType="RadioGroup"
            value={!!state.multilanguage?.length}
            options={variableOptions}
            onChange={handleIsMultilanguage}
          />
          <FieldsBehavior
            title="Variable Name"
            name="variableName"
            fieldType="TextField"
            placeholder="Type a variable name"
            mandatory
            value={state.variableName}
            onChange={handleNameChange}
          />
          <FieldsBehavior
            title="Variable Key"
            name="variableKey"
            fieldType="TextField"
            placeholder="Type a variable {key}"
            mandatory
            value={state.variableKey}
            onChange={handleKeyChange}
            helptext="Define a Key to this variable"
          />
          {!state.multilanguage?.length && (
            <FieldsBehavior
              title="Default Value"
              name="defaulValue"
              fieldType="TextArea"
              placeholder="Type a default value"
              value={state.defaultValue}
              onChange={handleValueChange}
              helptext="You can define a default value to this variable"
            />
          )}
          {state.multilanguage?.map((field: IVariableLang, index: number) => {
            const language = langs.find((lang: ILanguage) => lang.id === field.languageId);

            const handleLangChange = (value: string) => {
              const langs = state.multilanguage ? [...state.multilanguage] : [];
              langs[index].value = value;
              setState({ ...state, multilanguage: langs });
            };

            return (
              <FieldsBehavior
                title={`Default ${language?.language} Value`}
                name="defaulValue"
                fieldType="TextArea"
                placeholder="Type a default value"
                value={field.value}
                onChange={handleLangChange}
                helptext={`You can define a ${language?.language} default value to this variable`}
                key={field.languageId}
              />
            );
          })}
        </S.ContentWrapper>
        <S.ActionWrapper>
          <Button type="button" onClick={handleButtonClick}>
            {title}
          </Button>
        </S.ActionWrapper>
      </S.Wrapper>
    </FloatingPanel>
  );
}

interface IProps {
  onClick: (value: IIntegrationVariable) => void;
  langs: ILanguage[];
  item?: IIntegrationVariable;
  isOpen: boolean;
  toggleModal: () => void;
}

export default VariablePanel;
