import { ILanguage, IPageLanguage } from "@ax/types";

const getCurrentLanguages = (languages: ILanguage[], pageLanguages: IPageLanguage[]): ILanguage[] => {
  const available: ILanguage[] = [];

  pageLanguages.forEach(
    (pageLang: IPageLanguage) =>
      languages &&
      languages.forEach((language: ILanguage) => {
        if (language.id === pageLang.languageId) {
          available.push(language);
        }
      })
  );

  return available;
};

export { getCurrentLanguages };
