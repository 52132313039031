import styled from "styled-components";

export const DetailPanelWrapper = styled.div<{ hidden: boolean }>`
  position: relative;
  opacity: ${p => (p.hidden ? "0" : "1")};
  transition: opacity 0.1s;
  height: 100%;
  width: 100%;
  display: ${p => (p.hidden ? "none" : "block")};
`;

export const PanelActions = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  background-color: ${p => p.theme.color.uiBarBackground};
  padding: ${p => p.theme.spacing.s} ${p => p.theme.spacing.m};
  border-top: 1px solid ${p => p.theme.color.uiLine};
  button:not(:first-child) {
    margin-left: ${p => p.theme.spacing.s};
  }
`;

export const PanelForm = styled.div`
  position: relative;
  width: 100%;
  height: calc(${p => p.theme.spacing.xl} * 8);
  overflow: auto;
`;

export const Date = styled.div`
  ${p => p.theme.textStyle.uiM};
  color: ${p => p.theme.color.textMediumEmphasis};
  margin-bottom: ${p => p.theme.spacing.xxs};
`;

export const Type = styled.div`
  ${p => p.theme.textStyle.uiM};
  color: ${p => p.theme.color.textMediumEmphasis};
  margin-bottom: ${p => p.theme.spacing.xxs};
`;

export const ImageInfoWrapper = styled.div`
  padding: ${p => p.theme.spacing.m};
  border-bottom: 1px solid ${p => p.theme.color.uiLine};
`;

export const ImageWrapper = styled.div`
  display: flex;
  margin-bottom: ${p => p.theme.spacing.s};
  align-items: flex-start;
  justify-content: space-between;
`;

export const Image = styled.div`
  max-width: 120px;
  width: 100%;
  img {
    border: 1px solid ${p => p.theme.color.uiLine};
    border-radius: 4px;
  }
`;

export const ImageName = styled.div`
  ${p => p.theme.textStyle.uiL};
  color: ${p => p.theme.color.textHighEmphasis};
  margin-bottom: ${p => p.theme.spacing.xxs};
  overflow-wrap: break-word;
`;

export const ImageSize = styled.div`
  ${p => p.theme.textStyle.uiM};
  color: ${p => p.theme.color.textMediumEmphasis};
  margin-bottom: ${p => p.theme.spacing.xxs};
`;

export const ImageDimensions = styled.div`
  ${p => p.theme.textStyle.uiM};
  color: ${p => p.theme.color.textMediumEmphasis};
`;

export const FormWrapper = styled.div`
  padding: ${p => p.theme.spacing.m};
`;

export const ImageUrl = styled.div`
  display: flex;
  ${p => p.theme.textStyle.fieldContent};
  color: ${p => p.theme.color.textMediumEmphasis};
  background-color: ${p => p.theme.color.uiBackground03};
  border-radius: 4px;
  width: 100%;
  height: ${p => p.theme.spacing.l};
  padding: 0 ${p => p.theme.spacing.s};
  margin-bottom: ${p => p.theme.spacing.m};
  overflow: hidden;
  white-space: nowrap;
`;

export const AddToGlobal = styled.div`
  padding: ${p => `${p.theme.spacing.s} ${p.theme.spacing.m} ${p.theme.spacing.xs} ${p.theme.spacing.m}`};
  border-bottom: 1px solid ${p => p.theme.color.uiLine};
`;
