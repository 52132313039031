import React, { memo } from "react";

import { useToast } from "@ax/hooks";
import { Tooltip, IconAction, Toast } from "@ax/components";
import { IModule, INotification } from "@ax/types";

const PasteModuleButton = (props: IPasteModuleProps): JSX.Element => {
  const {
    pasteModule,
    setNotification,
    setHistoryPush,
    editorID,
    isModuleCopyUnavailable,
    arrayKey,
    modulesToPaste,
    slots,
  } = props;

  const { isVisible, toggleToast, setIsVisible, state: toastState } = useToast();

  const handlePasteModule = async () => {
    if (isModuleCopyUnavailable) {
      const notification: INotification = {
        type: "error",
        text: "You are trying to paste a module that is part of a disabled content type package. To copy it, you must first activate it.",
        btnText: "Activate package",
        onClick: () => setHistoryPush && setHistoryPush("/sites/settings/content-types", false),
      };
      setNotification && setNotification(notification);
    }

    if (slots && modulesToPaste.length > slots) {
      const notification: INotification = {
        type: "error",
        text: "Unable to paste modules: The destination area has a limit on the number of modules allowed. Please adjust the selection accordingly.",
      };
      setNotification && setNotification(notification);
    } else {
      const pasteResult = await pasteModule(editorID, arrayKey, modulesToPaste);
      if (pasteResult?.error) {
        const { type, text } = pasteResult.error;
        setNotification && setNotification({ type, text });
      } else {
        toggleToast(`${modulesToPaste.length} module${modulesToPaste.length > 1 ? "s" : ""} pasted from clipboard`);
      }
    }
  };

  return (
    <>
      <Tooltip content="Paste from clipboard">
        <IconAction icon="paste" onClick={handlePasteModule} />
      </Tooltip>
      {isVisible && <Toast message={`${toastState}`} setIsVisible={setIsVisible} />}
    </>
  );
};

export interface IPasteModuleProps {
  pasteModule: (editorID: number, key: string, modulesToPaste: IModule[]) => Promise<{ error?: INotification }>;
  setNotification?: (notification: INotification) => void;
  setHistoryPush?: (path: string, isEditor: boolean) => void;
  editorID: number;
  isModuleCopyUnavailable: boolean;
  arrayKey: string;
  modulesToPaste: IModule[];
  slots: number | null;
}

export default memo(PasteModuleButton);
