import React, { useRef } from "react";

import { IModal, IRedirect } from "@ax/types";
import { Button, Icon, Modal } from "@ax/components";

import * as S from "./style";

const DeleteModal = (props: IModal): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction } = props;

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Delete Redirects"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
    >
      <S.ModalContent>
        Are you sure you want to delete the selected <strong>redirects</strong>? This action{" "}
        <strong>cannot be undone</strong>.
      </S.ModalContent>
    </Modal>
  );
};

const OverwriteModal = (props: IModal): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction } = props;

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Overwrite Redirect"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
    >
      <S.ModalContent>
        This Redirect is already created. It will overwrite.
        <br />
        This action <strong>cannot be undone</strong>.
      </S.ModalContent>
    </Modal>
  );
};

const ImportModal = (props: IImportModal): JSX.Element => {
  const { isOpen, toggleModal, checkImportData, isUploading, setIsUploading } = props;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => fileInputRef.current && fileInputRef.current.click();

  const csvToArray = (str: string, delimiter = ";") => {
    const fixedStr = str.replace(/,/g, ";").replace(/\r/g, "");
    const rows = fixedStr.slice(fixedStr.indexOf("\n") + 1).split("\n");

    const arr = rows.map((row: string) => {
      const values = row.split(delimiter);
      return {
        from: values[0],
        to: values[1],
      };
    });

    return arr;
  };

  const handleFileChange = (e: any) => {
    setIsUploading(true);
    const file = e.currentTarget.files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const text = e.target.result;
      const data = csvToArray(text);
      checkImportData(data);
    };

    reader.readAsText(file);
  };

  return (
    <Modal isOpen={isOpen} hide={toggleModal} title="Import Redirects" size="M">
      <S.ModalCenterContent>
        {isUploading ? (
          <>
            <S.ModalIconWrapper>
              <Icon name="uploadFile" size="48" />
            </S.ModalIconWrapper>
            <S.ModalUploadingText>Uploading...</S.ModalUploadingText>
          </>
        ) : (
          <S.ModalContentWrapper>
            <S.ModalIcon>
              <Icon name="uploadFile" size="32" />
            </S.ModalIcon>
            <S.ModalTitle>Import 301 Redirects</S.ModalTitle>
            <S.ModalText>
              Import your URL redirects by uploading a CSV file.
              <br />
              To use the right format, please download the template available below.
            </S.ModalText>
            <S.ModalButtons>
              <input
                onChange={handleFileChange}
                multiple={false}
                ref={fileInputRef}
                type="file"
                accept=".csv,.xlsx,.xls"
                hidden
              />
              <Button className="button" type="button" buttonStyle="line">
                <a
                  href={process.env.PUBLIC_URL + "/templates/template-redirects.csv"}
                  download={"template-redirects.csv"}
                >
                  Download Template
                </a>
              </Button>
              <Button className="button" type="button" onClick={handleUploadClick}>
                Upload CSV file
              </Button>
            </S.ModalButtons>
          </S.ModalContentWrapper>
        )}
      </S.ModalCenterContent>
    </Modal>
  );
};

const ImportCheckModal = (props: IImportCheckModal): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction, imports, totalImports } = props;

  const mapModalItems = (items: IRedirect[], icon: string, type: "online" | "error" | "info") => {
    return items.map((item: IRedirect) => (
      <S.ModalImportItem key={item.from}>
        <S.ModalItemIcon type={type}>
          <Icon name={icon} size="16" />
        </S.ModalItemIcon>
        <S.ModalUrl>{item.from}</S.ModalUrl>
        <S.ModalItemArrow>
          <Icon name="fullArrowRight" size="16" />
        </S.ModalItemArrow>
        <S.ModalUrl>{typeof item.to === "string" && item.to}</S.ModalUrl>
      </S.ModalImportItem>
    ));
  };

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      mainAction={mainModalAction}
      secondaryAction={secondaryModalAction}
      title="Import Redirects"
      size="L"
    >
      {imports && (
        <S.ModalContent>
          <S.ModalImportResult>
            We found <strong>{totalImports} redirects</strong>. Continue to finish the uploading.
          </S.ModalImportResult>
          {imports.error.length > 0 && (
            <S.ModalImportWrapper>
              <S.ModalImportSection>
                <strong>{imports.error.length}</strong> redirects that do not correspond to any site.
              </S.ModalImportSection>
              {mapModalItems(imports.error, "close", "error")}
            </S.ModalImportWrapper>
          )}
          {imports.existing.length > 0 && (
            <S.ModalImportWrapper>
              <S.ModalImportSection>
                <strong>{imports.existing.length}</strong> redirects already created. It will overwrite.
              </S.ModalImportSection>
              {mapModalItems(imports.existing, "info", "info")}
            </S.ModalImportWrapper>
          )}
          {imports.ok.length > 0 && (
            <S.ModalImportWrapper>
              <S.ModalImportSection>
                <strong>{imports.ok.length}</strong> redirects imported correctly.
              </S.ModalImportSection>
              {mapModalItems(imports.ok, "successSolid", "online")}
            </S.ModalImportWrapper>
          )}
        </S.ModalContent>
      )}
    </Modal>
  );
};

interface IImportModal extends IModal {
  isUploading: boolean;
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
  checkImportData: (data: { from: string; to: string }[]) => void;
}

interface IImportCheckModal extends IModal {
  totalImports: number;
  imports: null | {
    error: IRedirect[];
    existing: IRedirect[];
    ok: IRedirect[];
  };
}

export { DeleteModal, ImportModal, OverwriteModal, ImportCheckModal };
