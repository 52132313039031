import React from "react";

import * as S from "./style";

const RadioField = (props: IRadioFieldProps): JSX.Element => {
  const { name, title, value, checked, error, disabled, onChange } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <S.Wrapper data-testid="radio-field-wrapper">
      <S.Label>
        {title}
        <S.Input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
          data-testid="radio-field-input"
        />
        <S.CheckMark checked={checked} error={error} />
      </S.Label>
    </S.Wrapper>
  );
};

export interface IRadioFieldProps {
  name: string;
  title?: string;
  value: string;
  checked?: boolean;
  error?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

export default RadioField;
