import React, { memo, useEffect } from "react";
import { createPortal } from "react-dom";
import { IconAction } from "@ax/components";
import Button from "../Button";

import * as S from "./style";

const Modal = (props: IModalProps): JSX.Element | null => {
  const {
    isOpen,
    hide,
    children,
    title,
    size,
    mainAction,
    secondaryAction,
    height,
    overflow = "auto",
    isChild,
  } = props;

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        hide();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleKeyPress);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isOpen, hide]);

  const titleContent = title ? <S.Title>{title}</S.Title> : "";

  const mainActionButton = mainAction ? (
    <Button type="button" onClick={mainAction.onClick} disabled={mainAction.disabled} icon={mainAction.icon}>
      {mainAction.title}
    </Button>
  ) : (
    ""
  );

  const secondaryActionButton = secondaryAction ? (
    <Button type="button" buttonStyle="text" onClick={secondaryAction.onClick}>
      {secondaryAction.title}
    </Button>
  ) : (
    ""
  );

  const footer =
    mainAction || secondaryAction ? (
      <S.ModalFooter>
        {secondaryActionButton}
        {mainActionButton}
      </S.ModalFooter>
    ) : (
      ""
    );

  return isOpen
    ? createPortal(
        <>
          <S.ModalOverlay isChild={isChild} />
          <S.ModalWrapper data-testid="modal-wrapper" isChild={isChild}>
            <S.Modal size={size} height={height} data-testid="modal-component">
              <S.ModalHeader>
                {titleContent}
                <S.ButtonWrapper>
                  <IconAction size="s" icon="close" onClick={hide} />
                </S.ButtonWrapper>
              </S.ModalHeader>
              <S.ModalContent overflow={overflow}>{children}</S.ModalContent>
              {footer}
            </S.Modal>
          </S.ModalWrapper>
        </>,
        document.body
      )
    : null;
};

export interface IModalProps {
  isOpen: boolean;
  hide: () => void;
  children: any;
  title?: string;
  size?: "S" | "M" | "L" | "XL";
  overflow?: string;
  mainAction?: IAction;
  secondaryAction?: IAction;
  height?: number;
  isChild?: boolean;
}

interface IAction {
  title: string;
  onClick: (item?: any) => void;
  disabled?: boolean;
  icon?: string;
}

export default memo(Modal);
