import React, { useReducer, useEffect } from "react";
import { connect } from "react-redux";

import { IRootState, IStructuredData, ITemplateOption, IThemeElements } from "@ax/types";
import { filterThemeTemplates, getThumbnailProps } from "@ax/helpers";
import { MenuItem, RadioGroup } from "@ax/components";
import { structuredDataActions } from "@ax/containers/StructuredData";
import { SecondaryActionButton, MainActionButton } from "./../atoms";

import { reducer, IOptionTableStore, setColumnValues, setSelectedType, setOption } from "./store";

import * as S from "./style";

const OptionTable = (props: IOptionTableProps): JSX.Element => {
  const {
    selectData,
    selectPage,
    filters,
    values,
    selectedValue,
    setIsStructuredData,
    theme,
    mainAction,
    secondaryAction,
    structuredData,
    themeElements,
    currentStructuredData,
  } = props;

  const filteredValues = filterThemeTemplates(themeElements, values);

  const filterOptions = (value: string, objKey: "value" | "type") =>
    filteredValues.filter((item) => item[objKey] === value);

  const filterOptionsByDataPack = (value: string) => {
    return filteredValues.filter((item) => {
      return (
        item.editable !== false && (item.dataPacks ? item.dataPacks.includes(value.toUpperCase()) : item.type === value)
      );
    });
  };

  const currentType = currentStructuredData
    ? currentStructuredData.dataPacks[0]
    : filters.length
    ? filters[0].value
    : "";

  const initialState: IOptionTableStore = {
    columnValues: filterOptions(currentType, "type"),
    showThumbnail: true,
    selectedOption: selectedValue,
    selectedType: currentType,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const selectOption = (option: string, currentOptions?: ITemplateOption[]) => {
    const { columnValues } = state;
    const availableOptions = currentOptions ? currentOptions : columnValues;

    const optionObj = availableOptions.find((item) => item.value === option);
    setSelectedOption(option);
    if (optionObj) {
      const { isData } = optionObj;
      setValue(option, !!isData);
    }
  };

  let filteredOptionsByDataPack = filterOptionsByDataPack(state.selectedType);

  useEffect(() => {
    displayOptions({ value: state.selectedType, label: state.selectedType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setValue = (value: string, isStructuredData: boolean) => {
    setIsStructuredData(isStructuredData);
    isStructuredData ? selectData(value) : selectPage(value);
  };

  const setType = (type: string) => dispatch(setSelectedType(type));
  const setValues = (columns: ITemplateOption[]) => dispatch(setColumnValues(columns));
  const setSelectedOption = (selectedOption: string) => dispatch(setOption({ selectedOption }));

  const isOptionInType = (column: ITemplateOption[]) => {
    const optionValues = filterOptions(state.selectedOption, "value");
    return column.includes(optionValues[0]);
  };

  const thumbnailProps =
    state.showThumbnail &&
    isOptionInType(filteredOptionsByDataPack) &&
    getThumbnailProps(state.selectedOption, true, theme);

  const displayOptions = (item: IOptionFilter) => {
    const { value } = item;

    setType(value);
    filteredOptionsByDataPack = filterOptionsByDataPack(value);
    filteredOptionsByDataPack.forEach((option) => {
      const { name } = option;

      const currentGlobalStructuredData = structuredData.global.find((data) => data.schema.templates?.includes(name));
      const currentLocalStructuredData = structuredData.site.find((data) => data.schema.templates?.includes(name));

      if (currentGlobalStructuredData || currentLocalStructuredData) {
        filteredOptionsByDataPack = filteredOptionsByDataPack.map((option) => {
          if (option.mode !== "detail") return option;
          const optionType = option.type;
          const currentStructuredDataId = currentGlobalStructuredData?.id || currentLocalStructuredData?.id;
          if (currentStructuredDataId?.toLowerCase() === optionType.toLowerCase()) {
            const title = currentGlobalStructuredData
              ? `Get ${currentGlobalStructuredData.title} from Global`
              : currentLocalStructuredData?.title;
            return { ...option, title: title || "" };
          }
          return option;
        });
        const globalOptionIdx = filteredOptionsByDataPack.findIndex((option) => option.mode === "detail");
        const globalOption = filteredOptionsByDataPack.splice(globalOptionIdx, 1);
        filteredOptionsByDataPack.push(...globalOption);
      }
    });

    filteredOptionsByDataPack.sort((ele, comp) => {
      // Use localeCompare to compare strings alphabetically
      return ele.title.localeCompare(comp.title);
    });

    setValues(filteredOptionsByDataPack);

    const currentStructuredDataTemplate: string | undefined =
      currentStructuredData && currentStructuredData.schema.templates
        ? currentStructuredData.schema.templates[0]
        : undefined;

    const selectedTemplate =
      filteredOptionsByDataPack.find((opt) => opt.value === currentStructuredDataTemplate)?.value ||
      filteredOptionsByDataPack[0]?.value ||
      "";

    selectOption(selectedTemplate, filteredOptionsByDataPack);
  };

  const handleChange = (value: string | boolean) => typeof value === "string" && selectOption(value);

  const hasThumbnail = state.showThumbnail && thumbnailProps && isOptionInType(filteredOptionsByDataPack);

  return (
    <S.Table>
      <S.LeftColumn>
        {filters.map((item, i: number) => {
          const displayFilteredOptions = () => state.selectedType !== item.value && displayOptions(item);
          const isSelected = item.value === state.selectedType;
          const selectedClass = isSelected ? "selected" : "";
          return (
            <MenuItem key={`${item.value}${i}`} className={selectedClass} onClick={displayFilteredOptions}>
              <S.NavLink>
                <S.Link active={isSelected}>{item.label}</S.Link>
              </S.NavLink>
            </MenuItem>
          );
        })}
      </S.LeftColumn>
      <S.Column>
        <RadioGroup options={state.columnValues} onChange={handleChange} value={state.selectedOption} name="" />
      </S.Column>
      <S.Column>
        {hasThumbnail && (
          <S.ThumbnailWrapper>
            {thumbnailProps && <S.Thumbnail backgroundUrl={thumbnailProps.src} />}
          </S.ThumbnailWrapper>
        )}
        <S.Actions>
          <SecondaryActionButton onClick={secondaryAction.onClick} title={secondaryAction.title} />
          <MainActionButton onClick={mainAction.onClick} title={mainAction.title} />
        </S.Actions>
      </S.Column>
    </S.Table>
  );
};

interface IAction {
  title: string;
  onClick: () => void;
}

interface IOptionFilter {
  label: string;
  value: string;
  isData?: boolean;
  special?: string;
  mode?: string;
}

interface IOptionTableProps {
  selectData: (value: string) => void;
  selectPage: (value: string) => void;
  setIsStructuredData: (isActive: boolean) => void;
  filters: IOptionFilter[];
  values: ITemplateOption[];
  selectedValue: string;
  theme: string;
  mainAction: IAction;
  secondaryAction: IAction;
  structuredData: { global: IStructuredData[]; site: IStructuredData[] };
  themeElements: IThemeElements | null;
  currentStructuredData: IStructuredData | null;
}

const mapStateToProps = (state: IRootState) => ({
  structuredData: state.structuredData.structuredData,
  themeElements: state.sites.themeElements,
  currentStructuredData: state.structuredData.currentStructuredData,
});

const mapDispatchToProps = {
  setIsStructuredData: structuredDataActions.setIsActive,
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionTable);
