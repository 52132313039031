import React, { memo } from "react";

import { IImage, ISite } from "@ax/types";
import { usePermission } from "@ax/hooks";

import GalleryDragAndDrop from "./GalleryDragAndDrop";
import DetailPanel from "./DetailPanel";
import * as S from "./style";

const GalleryPanel = (props: IGalleryPanelProps) => {
  const { imageSelected, validFormats, setImage, isGlobalTab, scope, selectedTab, site, refreshImages, selectImage } =
    props;

  const isAllowedToUpload = usePermission("mediaGallery.addImages");

  const allowUpload = (site && (!isGlobalTab || !selectedTab) && isAllowedToUpload) || (!site && isAllowedToUpload);

  return (
    <S.GalleryPanel>
      <GalleryDragAndDrop
        isImageSelected={!!imageSelected}
        validFormats={validFormats}
        site={scope}
        allowUpload={allowUpload}
        refreshImages={refreshImages}
        selectImage={selectImage}
      />
      <DetailPanel
        imageSelected={imageSelected}
        isImageSelected={!!imageSelected}
        setImage={setImage}
        isGlobalTab={isGlobalTab}
        refreshImages={refreshImages}
        selectImage={selectImage}
      />
    </S.GalleryPanel>
  );
};

export interface IGalleryPanelProps {
  imageSelected: IImage | null;
  validFormats: string[];
  setImage: (imageData: any) => void;
  isGlobalTab: boolean;
  scope: number | "global";
  selectedTab: string;
  refreshImages: () => Promise<void>;
  site: ISite | null;
  selectImage(item: IImage | null): void;
}

export default memo(GalleryPanel);
