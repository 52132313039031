import styled from "styled-components";

const TabsRow = styled.div<{ icons?: boolean; isInAppBar?: boolean; noMargins?: boolean }>`
  display: flex;
  border-bottom: ${(p) => (p.icons || p.isInAppBar || p.noMargins ? "none" : `1px solid ${p.theme.color.uiLine}`)};
  margin-bottom: ${(p) => (p.isInAppBar || p.noMargins ? 0 : p.theme.spacing.m)};
  align-self: flex-end;
  height: ${(p) => (p.noMargins || p.isInAppBar ? "auto" : "100%")};
`;

const TabItem = styled.button<{ active: boolean; isInAppBar?: boolean; inversed?: boolean }>`
  flex-grow: 1;
  border: none;
  border-bottom: 4px solid
    ${(p) =>
      !p.active ? "transparent" : p.inversed ? p.theme.color.iconHighEmphasisInverse : p.theme.color.interactive01};
  ${(p) => p.theme.textStyle.headingXS};
  color: ${(p) => (p.active ? p.theme.color.textHighEmphasis : p.theme.color.textMediumEmphasis)};
  cursor: ${(p) => (p.active ? "initial" : "pointer")};
  background: transparent;
  height: ${(p) => (p.isInAppBar ? "100%" : "48px")};

  &:hover {
    color: ${(p) => (p.active ? p.theme.color.textHighEmphasis : p.theme.color.interactive01)};
    svg {
      path {
        fill: ${(p) => (p.inversed ? p.theme.color.iconHighEmphasisInverse : p.theme.color.interactive01)};
      }
    }
  }

  &:active,
  &:focus {
    outline: none;
  }

  svg {
    width: ${(p) => p.theme.spacing.m};
    height: ${(p) => p.theme.spacing.m};
    path {
      fill: ${(p) =>
        !p.active
          ? p.theme.color.interactiveDisabled
          : p.inversed
          ? p.theme.color.iconHighEmphasisInverse
          : p.theme.color.interactive01};
    }
  }
`;

const TabIcon = styled.div`
  text-transform: none;
  padding-left: ${(p) => p.theme.spacing.xxs};
  padding-right: ${(p) => p.theme.spacing.xxs};
`;

export { TabsRow, TabItem, TabIcon };
