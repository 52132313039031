import React from "react";

import { IFolder } from "@ax/types";
import { Icon, Tooltip } from "@ax/components";
import { trimText } from "@ax/helpers";

import * as S from "./style";

const FolderItem = (props: IProps) => {
  const { folder, onClick } = props;
  const { id, folderName } = folder;

  const handleClick = () => onClick(id);

  const FolderTitle = () =>
    folderName.length > 15 ? (
      <Tooltip content={folderName}>
        <S.Title>{trimText(folderName, 15)}</S.Title>
      </Tooltip>
    ) : (
      <S.Title>{folderName}</S.Title>
    );

  return (
      <S.Wrapper onClick={handleClick}>
        <S.IconWrapper>
          <Icon name="project" size="24" />
        </S.IconWrapper>
        <FolderTitle />
      </S.Wrapper>
  );
};

interface IProps {
  folder: IFolder;
  onClick(folderID: number): void;
}

export default FolderItem;
