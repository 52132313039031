import React from "react";
import { Link } from "react-router-dom";

import { Button } from "@ax/components";
import { FieldsBehavior } from "@ax/components";
import { IDimension, IDimensionsGroup } from "@ax/types";
import { splitAndTrim, splitAndJoin } from "@ax/helpers";

import * as S from "./style";

const Warning = (props: IWarning): JSX.Element => {
  const { setShowWarning } = props;

  const warningText = (
    <div>
      Analytics has <Link to="/settings/analytics">Global settings</Link>. If you need, you can change the Analytics ID
      for this site. If you have already defined a project for this site in GTM, you don&apos;t need to define it here.
      You cannot edit the Dimensions and Values here, only view them.
    </div>
  );

  const handleButtonClick = () => {
    setShowWarning(false);
  };

  return (
    <>
      <S.WarningHeading>Analytics</S.WarningHeading>
      <S.WarningText>{warningText}</S.WarningText>
      <Button type="button" buttonStyle="solid" onClick={handleButtonClick}>
        Define Site Analytics
      </Button>
    </>
  );
};

const ScriptCode = (props: IScriptCode): JSX.Element => {
  const { scriptCode, setScriptCode } = props;

  return (
    <S.ScriptCodeWrapper>
      <FieldsBehavior
        title="Analytics Script Code"
        name="siteScriptCode"
        fieldType="TextArea"
        value={scriptCode}
        onChange={setScriptCode}
        placeholder="Type the Google Analytics or Google Tag Manager code."
      />
    </S.ScriptCodeWrapper>
  );
};

const Dimensions = (props: IDimensions): JSX.Element => {
  const { dimensions } = props;

  const heading = "Data Layer configuration";
  const description = (
    <>
      You cannot edit the Dimensions and Values here, only view them. To edit them, you have to go to{" "}
      <Link to="/settings/analytics">Global Settings</Link>.
    </>
  );

  const Dimension = (props: IDimension) => {
    const { name, values } = props;
    const hasValues = !["", ";null;"].includes(values);
    const getValues = () => splitAndTrim(values, ";").map((value) => `${value}; `);
    return (
      <S.Component>
        <S.ComponentName>{name}</S.ComponentName>
        <S.ComponentValues>{hasValues ? getValues() : "The value is defined on page"}</S.ComponentValues>
      </S.Component>
    );
  };

  return (
    <>
      <S.Heading>{heading}</S.Heading>
      <S.Description>{description}</S.Description>
      <S.ComponentsWrapper itemsSize={1 / 2}>
        {dimensions.map((dimension, idx) => (
          <Dimension key={idx} {...dimension} />
        ))}
      </S.ComponentsWrapper>
    </>
  );
};

const Groups = (props: IGroups): JSX.Element => {
  const { groups } = props;

  const heading = "Dimensions group by content";
  const description =
    "In global, you can create groups with the dimensions to measure on a page based on its content. You can not edit them here. Only view them.";

  const Group = (props: IDimensionsGroup) => {
    const { name, dimensions, templates } = props;
    const getDimensions = () => splitAndTrim(dimensions, ";").map((value, idx) => <p key={idx}>{value}</p>);
    const contentType = splitAndJoin(templates, ";", ", ");
    const hasContentType = !["null", ""].includes(contentType);
    const contentTypeText = hasContentType ? <span>{contentType}</span> : "NO";
    return (
      <S.Component>
        <div>
          <S.ComponentName>{name}</S.ComponentName>
          <S.ComponentValues>{getDimensions()}</S.ComponentValues>
        </div>
        <S.ContentType>Content type: {contentTypeText}</S.ContentType>
      </S.Component>
    );
  };

  return (
    <>
      <S.Heading>{heading}</S.Heading>
      <S.Description>{description}</S.Description>
      <S.ComponentsWrapper itemsSize={1 / 3}>
        {groups.map((group, idx) => (
          <Group key={idx} {...group} />
        ))}
      </S.ComponentsWrapper>
    </>
  );
};

interface IWarning {
  setShowWarning: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IScriptCode {
  scriptCode: string | null;
  setScriptCode: React.Dispatch<React.SetStateAction<string>>;
}

interface IDimensions {
  dimensions: IDimension[];
}

interface IGroups {
  groups: IDimensionsGroup[];
}

export { Warning, ScriptCode, Dimensions, Groups };
