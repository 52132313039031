import styled from "styled-components";

import { Cell, Row } from "@ax/components/TableList/TableItem/style";
import { ActionMenu } from "@ax/components";

const CheckCell = styled(Cell)`
  padding-right: 0;
  padding-left: ${(p) => p.theme.spacing.m};
  label {
    margin-bottom: ${(p) => p.theme.spacing.s};
    padding-left: ${(p) => p.theme.spacing.s};
  }
`;

const UserCell = styled(Cell)<{ clickable: boolean }>`
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  pointer-events: ${(p) => (p.clickable ? "auto" : "none")};
  flex-wrap: nowrap;
`;

const AvatarWrapper = styled.div`
  margin-right: ${(p) => p.theme.spacing.s};
`;

const UserInfo = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

const UserName = styled.div`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => p.theme.colors.textHighEmphasis};
`;

const UserEmail = styled.div<{ width: number }>`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: ${(p) => `${p.width}px`};
`;

const UserUsername = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
`;

const StatusCell = styled(Cell)`
  flex: 0 0 98px;
`;

const ActionsCell = styled(Cell)`
  flex: 0 0 64px;
`;

const RolesCell = styled(Cell)<{ isSite: boolean; clickable: boolean }>`
  flex: ${(p) => (p.isSite ? "0 0 450px" : "0 0 275px")};
  pointer-events: ${(p) => (p.clickable ? "auto" : "none")};
`;

const SitesCell = styled(Cell)<{ clickable: boolean }>`
  flex: 0 0 210px;
  pointer-events: ${(p) => (p.clickable ? "auto" : "none")};
`;

const ActionsWrapper = styled.div`
  opacity: 0;
  transition: opacity 0.1s;
  text-align: right;
`;

const StyledActionMenu = styled(ActionMenu)`
  opacity: 0;
  width: 32px;
  display: flex;
  margin-left: auto;
`;

const UserRow = styled(Row)`
  cursor: pointer;
  &:hover {
    ${StyledActionMenu} {
      opacity: 1;
    }
  }
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};
  p {
    margin-bottom: ${(p) => p.theme.spacing.s};
  }
`;

export {
  CheckCell,
  UserCell,
  AvatarWrapper,
  UserInfo,
  UserName,
  UserEmail,
  UserUsername,
  ActionsCell,
  ActionsWrapper,
  StyledActionMenu,
  UserRow,
  ModalContent,
  StatusCell,
  RolesCell,
  SitesCell,
};
