import React from "react";

import { IModal } from "@ax/types";
import { Modal } from "@ax/components";

import * as S from "./style";

const DeleteModal = (props: IModal & { integrationName: string | undefined }): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction, integrationName } = props;

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title={`Remove ${integrationName} add-on`}
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
    >
      <S.ModalContent>
        Are you sure you want to remove <strong>{integrationName}</strong> from this page?
      </S.ModalContent>
    </Modal>
  );
};

export { DeleteModal };
