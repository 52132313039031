import React from "react";
import { connect } from "react-redux";

import { IRedirect } from "@ax/types";
import { Button, FieldsBehavior, FloatingPanel } from "@ax/components";
import { redirectsActions } from "@ax/containers/Redirects";

import * as S from "./style";

const RedirectPanel = (props: IProps): JSX.Element => {
  const {
    isOpen,
    toggleModal,
    redirect,
    isOpenedSecond,
    toggleSecondaryPanel,
    addRedirect,
    updateRedirect,
    formValues,
    setFormValues,
    currentFilter,
  } = props;

  const handleOldUrl = (newValue: string) => setFormValues({ ...formValues, from: newValue });

  const handleNewUrl = (newValue: any) => {
    const url = !newValue ? "" : newValue.linkTo ? newValue.linkToURL : newValue.href;
    const pageId = newValue ? newValue.linkTo : undefined;
    const toValue = { ...newValue, pageId, url };
    setFormValues({ ...formValues, to: toValue });
  };

  const addItemAction = () => addRedirect();

  const editItemAction = async () => {
    const toPage = formValues.to.pageId ? formValues.to.pageId : formValues.to.url;
    formValues.id && (await updateRedirect({ from: formValues.from, to: toPage }, formValues.id, currentFilter));
    toggleModal();
  };

  const addButton = {
    label: "Add Redirect",
    action: addItemAction,
    disabled: false,
  };

  const editButton = {
    label: "Update Redirect",
    action: editItemAction,
    disabled: false,
  };

  const title = redirect ? "Update Redirect" : "Add Redirect";

  return (
    <FloatingPanel
      title={title}
      toggleModal={toggleModal}
      isOpen={isOpen}
      isOpenedSecond={isOpenedSecond}
      handlePanel={toggleSecondaryPanel}
    >
      <S.Wrapper>
        <FieldsBehavior
          title="Old URL"
          name="oldUrl"
          fieldType="TextField"
          value={formValues.from}
          onChange={handleOldUrl}
          autoComplete="redirect-old"
          helptext="Type the complete old page url you want to redirect."
        />
        <FieldsBehavior
          title="New URL"
          name="newUrl"
          fieldType="UrlField"
          value={formValues.to}
          onChange={handleNewUrl}
          handlePanel={toggleSecondaryPanel}
          inFloatingPanel={true}
          autoComplete="redirect-new"
          helptext="Select an internal page or paste an external url."
        />
        <S.Footer>
          {redirect ? (
            <Button className="button" type="button" onClick={editButton.action} disabled={editButton.disabled}>
              {editButton.label}
            </Button>
          ) : (
            <Button className="button" type="button" onClick={addButton.action} disabled={addButton.disabled}>
              {addButton.label}
            </Button>
          )}
        </S.Footer>
      </S.Wrapper>
    </FloatingPanel>
  );
};

const mapDispatchToProps = {
  updateRedirect: redirectsActions.updateRedirect,
};
interface ICategoryPanelProps {
  isOpen: boolean;
  isOpenedSecond: boolean;
  toggleSecondaryPanel(): any;
  toggleModal(): any;
  redirect?: IRedirect;
  formValues: IRedirect;
  setFormValues(redirect: IRedirect): void;
  currentFilter: string;
}

interface IDispatchProps {
  addRedirect(force?: boolean): void;
  updateRedirect(redirect: { from: string; to: string | number }, redirectID: number, filter?: string): Promise<void>;
}

type IProps = ICategoryPanelProps & IDispatchProps;

export default connect(null, mapDispatchToProps)(RedirectPanel);
