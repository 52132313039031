import styled from "styled-components";

export const FieldGroup = styled.div<{ inline?: boolean }>`
  width: 100%;
  margin-bottom: ${(p) => p.inline ? 0 : p.theme.spacing.xxs};
  display: ${(p) => p.inline ? "flex" : "block"};
`;

export const RadioFieldWrapper = styled.div<{ inline?: boolean }>`
  display: flex;

  &:not(:last-child) {
    margin-right: ${(p) => p.inline ? p.theme.spacing.s : 0};
  }

  & > :first-child {
    flex-grow: 2;
  }
`;