import React from "react";

import { CheckField, TableCounter, LastAccessFilter, NameFilter, LiveFilter, Tooltip } from "@ax/components";
import { IQueryValue } from "@ax/types";

import * as S from "./style";

const TableHeader = (props: IProps): JSX.Element => {
  const {
    isScrolling,
    selectAllItems,
    checkState,
    sortItems,
    filterItems,
    sortedListStatus,
    totalItems,
    filterValues,
    setHoverCheck,
  } = props;

  const liveStatusValue: Record<string, string> = {
    all: "all",
    online: "active",
    offline: "offline",
  };

  const liveFilterValue = filterValues.liveStatus.length
    ? [{ value: liveStatusValue[filterValues.liveStatus[0].value], label: filterValues.liveStatus[0].label }]
    : [];

  return (
    <S.TableHeader isScrolling={isScrolling} data-testid="sites-table-header">
      <S.CheckHeader>
        <Tooltip content="Select All Sites">
          <CheckField
            key="selectAll"
            name="selectAll"
            value="selectAll"
            onChange={selectAllItems}
            checked={checkState.isAllSelected || checkState.hoverCheck}
            disabled={false}
            error={false}
            setHoverCheck={setHoverCheck}
          />
        </Tooltip>
      </S.CheckHeader>
      <S.NameWrapper>
        <NameFilter sortItems={sortItems} sortedState={sortedListStatus} pointer="name" />
      </S.NameWrapper>
      <S.DomainHeader>Domain</S.DomainHeader>
      <S.LiveHeader>
        <LiveFilter filterItems={filterItems} value={liveFilterValue} statusOptions={["offline", "active"]} />
      </S.LiveHeader>
      <S.LastAccessCell>
        <LastAccessFilter sortItems={sortItems} sortedState={sortedListStatus} />
      </S.LastAccessCell>
      <TableCounter totalItems={totalItems} />
    </S.TableHeader>
  );
};

interface IProps {
  isScrolling: boolean;
  totalItems: number;
  selectAllItems: () => void;
  checkState: Record<string, boolean>;
  sortItems: (orderPointer: IQueryValue[], isAscending: boolean) => void;
  filterItems: (filterPointer: string, filtersSelected: IQueryValue[]) => void;
  sortedListStatus: { isAscending: boolean; sortedByDate: boolean; sortedByTitle: boolean; sortedByURL: boolean };
  filterValues: Record<string, IQueryValue[]>;
  setHoverCheck: (state: boolean) => void;
}

export default TableHeader;
