import React from "react";

import * as S from "./style";

const Card = (props: any): JSX.Element => {
  const { title, subtitle, thumbnail } = props;

  return (          
    <S.Card key={subtitle}>
      <S.CardTitle>{title}</S.CardTitle>
      <S.CardSubtitle>{subtitle}</S.CardSubtitle>
      {thumbnail && <S.Thumbnail backgroundUrl={thumbnail} />}
    </S.Card>
  )
};

export default Card;