import styled from "styled-components";

export const Button = styled.button<{
  size: string | undefined;
  inversed?: boolean;
  disabled?: boolean;
  active?: boolean;
}>`
  background: ${(p) => (p.active ? p.theme.color?.overlayHoverPrimary : "transparent")};
  border: none;
  border-radius: 50%;
  color: inherit;
  cursor: pointer;
  font-size: 0;
  outline: transparent;
  padding: ${(p) => p.theme.spacing?.xxs};
  position: relative;
  pointer-events: ${(p) => (p.disabled ? "none" : "auto")};
  :before {
    content: "";
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.1s;
  }

  :hover:before {
    background-color: ${(p) => p.theme.color?.overlayHoverPrimary};
    opacity: 1;
  }
  :focus:before {
    background-color: ${(p) => p.theme.color?.overlayFocusPrimary};
    opacity: 1;
  }
  :active:before {
    background-color: ${(p) => p.theme.color?.overlayPressedPrimary};
    opacity: 1;
  }
  :disabled {
    cursor: default;
    :hover:before,
    :focus:before,
    :active:before {
      background-color: transparent;
    }
    svg {
      path {
        fill: ${(p) => p.theme.color?.interactiveDisabled};
      }
    }
  }
  svg {
    width: ${(p) => (p.size === "s" ? p.theme.spacing?.s : p.theme.spacing?.m)};
    height: ${(p) => (p.size === "s" ? p.theme.spacing?.s : p.theme.spacing?.m)};
    path {
      fill: ${(p) => (p.inversed ? p.theme.color?.textHighEmphasisInverse : p.theme.color?.interactive01)};
    }
  }
`;

export const Icon = styled.div``;
