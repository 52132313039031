import styled from "styled-components";

import { Cell, Row } from "@ax/components/TableList/TableItem/style";
import { ActionMenu } from "@ax/components";

const CheckCell = styled(Cell)`
  padding-right: 0;
  padding-left: ${(p) => p.theme.spacing.m};
  label {
    margin-bottom: ${(p) => p.theme.spacing.s};
    padding-left: ${(p) => p.theme.spacing.s};
  }
`;

const NameCell = styled(Cell)`
  flex-grow: 1;
`;

const Title = styled.div<{ width: number }>`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => p.theme.color.textHighEmphasis};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: ${(p) => `${p.width}px`};
`;

const Slug = styled.div<{ width: number }>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: ${(p) => `${p.width}px`};
`;

const TypeCell = styled(Cell)`
  align-items: center;
  flex: 0 0 170px;
`;

const SiteCell = styled(Cell)`
  flex: 0 0 175px;
  align-items: center;
`;

const ActionsCell = styled(Cell)`
  flex: 0 0 85px;
  padding-left: 0;
`;

const ActionsWrapper = styled.div`
  opacity: 0;
  transition: opacity 0.1s;
  text-align: right;
`;

const TransCell = styled(Cell)`
  ${(p) => p.theme.textStyle.uiXS};
  align-items: center;
  position: relative;
  flex: 0 0 115px;
`;

const LiveCell = styled(Cell)`
  flex: 0 0 80px;
  align-items: center;
  svg {
    width: ${(p) => p.theme.spacing.m};
    height: ${(p) => p.theme.spacing.m};
  }
`;

const StatusCell = styled(Cell)`
  flex: 0 0 170px;
  align-items: center;
  div:nth-child(2) {
    margin-top: ${(p) => p.theme.spacing.xs};
  }
`;

const ModDate = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textLowEmphasis};
`;

const StyledActionMenu = styled(ActionMenu)`
  opacity: 0;
  width: 32px;
  display: flex;
  margin-left: auto;
`;

const StructuredDataRow = styled(Row)<{ disabled: boolean }>`
  position: relative;
  cursor: ${(p) => (p.disabled ? "default" : "pointer")};
  &:hover {
    ${StyledActionMenu} {
      opacity: 1;
    }
  }
  ${NameCell}, ${LiveCell}, ${StatusCell} {
    pointer-events: ${(p) => (p.disabled ? "none" : "auto")};
  }
`;

const FlagsWrapper = styled.div`
  svg {
    margin-right: 2px;
    margin-bottom: -3px;
    display: inline;
    vertical-align: baseline;
  }
`;

const SeoCell = styled(Cell)`
  ${(p) => p.theme.textStyle.uiXS};
  flex: 0 0 124px;
`;

const StyledSeo = styled.div`
  margin-bottom: 4px;
  :hover {
    color: ${(p) => p.theme.color.interactive01};
  }
`;

const StyledDone = styled.span`
  display: inline-block;
  line-height: 0;
  margin-right: 2px;
  svg {
    margin-bottom: -4px;
    width: ${(p) => p.theme.spacing.s};
    height: ${(p) => p.theme.spacing.s};
    path {
      fill: #afc628;
    }
  }
`;

const StyledClose = styled.span`
  display: inline-block;
  line-height: 0;
  margin-right: 2px;
  svg {
    margin-bottom: -4px;
    width: ${(p) => p.theme.spacing.s};
    height: ${(p) => p.theme.spacing.s};
    path {
      fill: ${(p) => p.theme.color.error};
    }
  }
`;

const FloatingSeo = styled.div`
  padding: ${(p) => `${p.theme.spacing.xs} ${p.theme.spacing.s}`};
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};

  p {
    margin-bottom: ${(p) => p.theme.spacing.m};
  }
`;

export {
  CheckCell,
  NameCell,
  ActionsCell,
  ActionsWrapper,
  StyledActionMenu,
  TransCell,
  LiveCell,
  StatusCell,
  ModDate,
  StructuredDataRow,
  FlagsWrapper,
  TypeCell,
  SiteCell,
  SeoCell,
  StyledSeo,
  StyledDone,
  StyledClose,
  FloatingSeo,
  ModalContent,
  Title,
  Slug,
};
