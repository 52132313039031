import { CREATE_FORM, UPDATE_FORM } from "./constants";

export interface ILanguageState {
  form: {
    language: number | null;
    path: string;
    domain: number | null;
    isDefault: boolean;
  };
}

export const initialState = {
  form: {
    language: null,
    path: "",
    domain: null,
    isDefault: false
  },
};

export function reducer(state = initialState, action: any): ILanguageState {
  switch (action.type) {
    case CREATE_FORM:
    case UPDATE_FORM:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export { initialState as languagesInitialState, reducer as languagesReducer };
