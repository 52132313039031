import React, { useState } from "react";
import { connect } from "react-redux";

import { ErrorToast, FieldsBehavior, Modal } from "@ax/components";
import { usersActions } from "@ax/containers/Users";
import { IModal, IRootState } from "@ax/types";

import * as S from "./style";

const TypeChangeModal = (props: ITypeChangeProps): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction, email, isSuperAdmin } = props;

  const type = isSuperAdmin ? "member" : "Super admin";

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Change user type?"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
    >
      <S.ModalContent>
        Are you sure you want to change {email} as a {type} user?
      </S.ModalContent>
    </Modal>
  );
};

const PasswordForm = (props: IPasswordFormProps) => {
  const { id, isOpen, toggleModal, isSaving, updatePassword } = props;

  const initForm: IState = { currentPassword: "", password: "", retypedPassword: "" };
  const [form, setForm] = useState(initForm);

  const handleCurrentPasswordChange = (value: string) => setForm({ ...form, currentPassword: value });
  const handlePasswordChange = (value: string) => setForm({ ...form, password: value });
  const handleRetypedPasswordChange = (value: string) => setForm({ ...form, retypedPassword: value });

  const validPasswords =
    form.currentPassword.trim().length &&
    form.password.trim() !== "" &&
    form.retypedPassword.trim() &&
    form.password === form.retypedPassword;

  const closeModal = () => {
    toggleModal();
    setForm(initForm);
  };

  const modalProps = {
    mainAction: {
      title: isSaving ? "Saving password" : "Change password",
      onClick: async () => {
        const updated = await updatePassword(id, form);
        if (updated) closeModal();
      },
      disabled: !validPasswords || isSaving,
    },
    secondaryAction: { title: "Cancel", onClick: closeModal },
    title: "Change your Password",
  };

  return (
    <Modal isOpen={isOpen} hide={toggleModal} size="M" {...modalProps}>
      <ErrorToast size="l" />
      <S.ModalContent>
        <FieldsBehavior
          title="Current Password"
          name="currentPassword"
          fieldType="TextField"
          inputType="password"
          placeholder="Type your current password"
          mandatory
          value={form.currentPassword}
          onChange={handleCurrentPasswordChange}
        />
        <FieldsBehavior
          title="New Password"
          name="newPassword"
          fieldType="TextField"
          inputType="password"
          placeholder="Type a password"
          mandatory
          value={form.password}
          onChange={handlePasswordChange}
        />
        <FieldsBehavior
          title="Confirm your New Password"
          name="retypedPassword"
          fieldType="TextField"
          inputType="password"
          placeholder="Type a password"
          mandatory
          value={form.retypedPassword}
          onChange={handleRetypedPasswordChange}
        />
      </S.ModalContent>
    </Modal>
  );
};

interface ITypeChangeProps extends IModal {
  email: string;
  isSuperAdmin: boolean;
}

const mapStateToProps = (state: IRootState) => ({
  isSaving: state.app.isSaving,
});

interface IDispatchProps {
  updatePassword(id: number | null, data: IState): Promise<boolean>;
}

const mapDispatchToProps = {
  updatePassword: usersActions.updatePassword,
};

interface IPasswordProps {
  id: number | null;
  isOpen: boolean;
  toggleModal: () => void;
  isSaving: boolean;
}

interface IState {
  currentPassword: string;
  password: string;
  retypedPassword: string;
}

type IPasswordFormProps = IPasswordProps & IDispatchProps;

const PasswordModal = connect(mapStateToProps, mapDispatchToProps)(PasswordForm);

export { PasswordModal, TypeChangeModal };
