import styled from "styled-components";

const ItemWrapper = styled.div``;

const NewIconWrapper = styled.div`
  width: 16px;
  height: 16px;
  margin-left: auto;
`;

const Item = styled.div<{ selected: boolean }>`
  display: flex;
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  align-items: center;
  cursor: pointer;
  padding: ${(p) => `${p.theme.spacing.xxs} ${p.theme.spacing.xs}`};
  border-radius: ${(p) => p.theme.radii.s};
  background-color: ${(p) => (p.selected ? p.theme.color.overlayPressedPrimary : "transparent")};
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
  &:hover {
    background-color: ${(p) => (p.selected ? p.theme.color.overlayPressedPrimary : p.theme.color.overlayHoverPrimary)};
  }
  ${NewIconWrapper} {
    opacity: ${(p) => (p.selected ? "1" : "0")};
  }
`;

const Name = styled.div`
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-left: ${(p) => p.theme.spacing.xs};
  white-space: nowrap;
`;

const RootName = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textHighEmphasis};
  white-space: nowrap;
`;

const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  svg {
    path {
      fill: ${(p) => p.theme.color.iconMediumEmphasis};
    }
  }
`;

const ArrowWrapper = styled.div`
  width: 16px;
  height: 16px;
  margin-right: ${(p) => p.theme.spacing.xxs};
  white-space: nowrap;
  flex-shrink: 0;
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-top: ${(p) => p.theme.spacing.xs};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

export { ItemWrapper, Item, Name, RootName, IconWrapper, ArrowWrapper, Title, NewIconWrapper };
