import isEqual from "lodash.isequal";
import { useEffect, useState, useRef } from "react";

const useShouldBeSaved = (selected: any | null, form: any | null): { isDirty: boolean } => {
  const formRef = useRef();
  const [isDirty, setIsDirty] = useState(false);
  const savedValues = selected && selected.config && JSON.stringify(selected.config);
  const stringValue = form && JSON.stringify(form);

  useEffect(() => {
    if (!formRef.current) {
      formRef.current = stringValue && JSON.parse(stringValue);
    }
    const stringFormRef = formRef.current && JSON.stringify(formRef.current);
    savedValues ? setIsDirty(savedValues !== stringValue) : setIsDirty(!isEqual(stringFormRef, stringValue));
  }, [form]);

  return { isDirty };
};

export { useShouldBeSaved };
