import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import { useHandleClickOutside } from "@ax/hooks";
import { Button, IconAction, NoteField } from "@ax/components";

import SideModalOption from "./SideModalOption";
import * as S from "./style";

const SideModal = (props: ISideModalProps): JSX.Element | null => {
  const {
    navigationModules,
    form,
    isOpen,
    toggleModal,
    theme,
    setNavigationModulesValue,
    isNavigationModulesChanged,
    setIsNavigationModulesChanged,
  } = props;

  const [previousNavigationModules, setPreviousNavigationModules] = useState(form.navigationModules);

  useEffect(() => {
    isOpen && setPreviousNavigationModules(form.navigationModules);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const closeDiscardingChanges = () => {
    setNavigationModulesValue(previousNavigationModules);
    toggleModal();
  };

  const save = () => {
    setIsNavigationModulesChanged(true);
    toggleModal();
  };

  const wrapperRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (e: any) => {
    if (wrapperRef.current?.contains(e.target)) return;
    closeDiscardingChanges();
  };

  useHandleClickOutside(isOpen, handleClickOutside);

  const getNavigationOptions = (type: string): JSX.Element => {
    return (
      <S.NavigationOptionsWrapper>
        <S.NavigationOptionsTitle>{type}</S.NavigationOptionsTitle>
        {navigationModules[type].map((option: any, index: number) => {
          const isSelected = form?.navigationModules && form?.navigationModules[type] === option.component;
          const isDefault = !(form?.navigationModules && form?.navigationModules[type]) && option.defaultNavigation;
          const handleClick = (option: any) =>
            setNavigationModulesValue({ ...form.navigationModules, [type]: option.component });
          return (
            <SideModalOption
              option={option}
              selected={isSelected || isDefault}
              handleClick={handleClick}
              theme={theme}
              key={`${option}${index}`}
            />
          );
        })}
      </S.NavigationOptionsWrapper>
    );
  };

  const noteFieldText = isNavigationModulesChanged
    ? "If you change the design, this will be changed in all headers and footers of all pages of the site."
    : "Select the design you want for this site and create as many headers and footers as you need.";

  const buttonText = isNavigationModulesChanged ? "Change design" : "Select design";

  return isOpen
    ? createPortal(
        <S.Wrapper ref={wrapperRef}>
          <S.Header>
            <S.Title>Navigation modules design</S.Title>
            <S.ButtonWrapper>
              <IconAction icon="close" onClick={closeDiscardingChanges} />
            </S.ButtonWrapper>
          </S.Header>
          <S.Content>
            <NoteField value={{ text: noteFieldText }} />
            {["header", "footer"].map((type: string) => getNavigationOptions(type))}
          </S.Content>
          <S.Footer>
            <Button type="button" onClick={save} buttonStyle="solid">
              {buttonText}
            </Button>
          </S.Footer>
        </S.Wrapper>,
        document.body
      )
    : null;
};

interface ISideModalProps {
  isOpen: boolean;
  navigationModules: Record<string, any[]>;
  toggleModal: () => void;
  theme: string;
  form: any;
  setNavigationModulesValue: (value: any) => void;
  isNavigationModulesChanged: boolean;
  setIsNavigationModulesChanged: Dispatch<SetStateAction<boolean>>;
}

export default SideModal;
