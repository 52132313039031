const isEmptyArray = (arr: any[]) => arr && arr.length === 0;

const moveArrayElement = (element: any, arr: any[], newIndex: number): Array<any> => {
  const arrCopy = [...arr];
  if (arrCopy.length <= 1) return arrCopy;
  const elementIndex = arrCopy.findIndex((el: any) => el === element);
  const el = arrCopy[elementIndex];
  arrCopy.splice(elementIndex, 1);
  arrCopy.splice(newIndex, 0, el);
  return arrCopy;
};

const arrayInsert = (arr: any[], index: number, newItem: any) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  ...newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

export { isEmptyArray, moveArrayElement, arrayInsert };
