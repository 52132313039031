import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { IStructuredData } from "@ax/types";
import { MenuGroup, MenuItem, SubNav } from "@ax/components";
import { pageEditorActions } from "@ax/containers/PageEditor";

import { getFilters } from "./utils";

import * as S from "./style";

const ContentFilters = (props: IProps): JSX.Element => {
  const { current, dynamicValues, onClick, addNew, isAllowedToCreate } = props;

  const filters = getFilters(dynamicValues);

  const handleClick = (value: string) => {
    if (value !== current) {
      onClick(value);
    }
  };

  return (
    <SubNav>
      <S.Wrapper>
        {filters.map((filter) => {
          const isSelected = filter.value === current;
          const selectedClass = isSelected ? "selected" : "";

          return (
            <React.Fragment key={filter.value}>
              {!filter.items ? (
                <MenuItem className={selectedClass} onClick={() => handleClick(filter.value)}>
                  <NavLink to="#">
                    <S.Link active={isSelected}>{filter.label}</S.Link>
                  </NavLink>
                </MenuItem>
              ) : (
                <MenuGroup
                  filter={filter}
                  current={current}
                  onClick={handleClick}
                  addNew={addNew}
                  isAllowedToCreate={isAllowedToCreate}
                />
              )}
            </React.Fragment>
          );
        })}
      </S.Wrapper>
    </SubNav>
  );
};

interface IProps {
  current: string | undefined;
  addTemplate(template: string): void;
  dynamicValues: IStructuredData[];
  onClick(dataID: string): void;
  addNew: () => void;
  isAllowedToCreate: boolean;
}

const mapDispatchToProps = {
  addTemplate: pageEditorActions.addTemplate,
};

export default connect(null, mapDispatchToProps)(ContentFilters);
