import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { getParentPath, getIndexAmongSiblings } from './path';
import { mutateTree } from './mutateTree';

/*
  Transforms tree structure into flat list of items for rendering purposes.
  We recursively go through all the elements and its children first on each level
 */
export var flattenTree = function flattenTree(tree) {
  var path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return tree.items[tree.rootId] ? tree.items[tree.rootId].children.reduce(function (accum, itemId, index) {
    // iterating through all the children on the given level
    var item = tree.items[itemId];
    var currentPath = [].concat(_toConsumableArray(path), [index]);
    // we create a flattened item for the current item
    var currentItem = createFlattenedItem(item, currentPath);
    // we flatten its children
    var children = flattenChildren(tree, item, currentPath);
    // append to the accumulator
    return [].concat(_toConsumableArray(accum), [currentItem], _toConsumableArray(children));
  }, []) : [];
};

/*
  Constructs a new FlattenedItem
 */
var createFlattenedItem = function createFlattenedItem(item, currentPath) {
  return {
    item: item,
    path: currentPath
  };
};

/*
  Flatten the children of the given subtree
*/
var flattenChildren = function flattenChildren(tree, item, currentPath) {
  return item.isExpanded ? flattenTree({
    rootId: item.id,
    items: tree.items
  }, currentPath) : [];
};
export var getItem = function getItem(tree, path) {
  var cursor = tree.items[tree.rootId];
  var _iterator = _createForOfIteratorHelper(path),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var i = _step.value;
      cursor = tree.items[cursor.children[i]];
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return cursor;
};
export var getParent = function getParent(tree, path) {
  var parentPath = getParentPath(path);
  return getItem(tree, parentPath);
};
export var getTreePosition = function getTreePosition(tree, path) {
  var parent = getParent(tree, path);
  var index = getIndexAmongSiblings(path);
  return {
    parentId: parent.id,
    index: index
  };
};
var hasLoadedChildren = function hasLoadedChildren(item) {
  return !!item.hasChildren && item.children.length > 0;
};
var isLeafItem = function isLeafItem(item) {
  return !item.hasChildren;
};
export var removeItemFromTree = function removeItemFromTree(tree, position) {
  var sourceParent = tree.items[position.parentId];
  var newSourceChildren = _toConsumableArray(sourceParent.children);
  var itemRemoved = newSourceChildren.splice(position.index, 1)[0];
  var newTree = mutateTree(tree, position.parentId, {
    children: newSourceChildren,
    hasChildren: newSourceChildren.length > 0,
    isExpanded: newSourceChildren.length > 0 && sourceParent.isExpanded
  });
  return {
    tree: newTree,
    itemRemoved: itemRemoved
  };
};
export var addItemToTree = function addItemToTree(tree, position, item) {
  var destinationParent = tree.items[position.parentId];
  var newDestinationChildren = _toConsumableArray(destinationParent.children);
  if (typeof position.index === 'undefined') {
    if (hasLoadedChildren(destinationParent) || isLeafItem(destinationParent)) {
      newDestinationChildren.push(item);
    }
  } else {
    newDestinationChildren.splice(position.index, 0, item);
  }
  return mutateTree(tree, position.parentId, {
    children: newDestinationChildren,
    hasChildren: true
  });
};