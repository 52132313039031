import React, { useState } from "react";

import { FloatingMenu, Icon, ListTitle, ListItem } from "@ax/components";
import { areEquals } from "@ax/helpers";
import { IQueryValue } from "@ax/types";

import * as S from "./style";

const Orientation = ({ filterItems }: IOrientationProps): JSX.Element => {
  const selectAllOption = "all";
  const initialState = ["all"];
  const pointer = "orientation";
  const [selectedValue, setSelectedValue] = useState(initialState);

  const filters: Record<string, string> = {
    all: "All",
    landscape: "Horizontal",
    portrait: "Vertical",
    square: "Square",
  };

  const setQuery = (selection: string) => {
    if (!selection.length) {
      selection = selectAllOption;
    }
    setSelectedValue([selection]);
    filterItems(pointer, [{ value: selection, label: filters[selection] }]);
  };

  const isActive = !areEquals(selectedValue, initialState);

  const Header = () => (
    <S.Orientation data-testid="orientation-wrapper" isActive={isActive}>
      Orientation
      <S.IconsWrapper>
        {isActive && <Icon name="Filter" size="16" />}
        <S.InteractiveArrow>
          <Icon name="DownArrow" size="16" />
        </S.InteractiveArrow>
      </S.IconsWrapper>
    </S.Orientation>
  );

  return (
    <FloatingMenu Button={Header} position="center" closeOnSelect={true}>
      <ListTitle>Filter by orientation</ListTitle>
      <ListItem isSelected={selectedValue.includes("all")} onClick={() => setQuery("all")}>
        All
      </ListItem>
      <ListItem isSelected={selectedValue.includes("landscape")} onClick={() => setQuery("landscape")}>
        Horizontal
      </ListItem>
      <ListItem isSelected={selectedValue.includes("portrait")} onClick={() => setQuery("portrait")}>
        Vertical
      </ListItem>
      <ListItem isSelected={selectedValue.includes("square")} onClick={() => setQuery("square")}>
        Square
      </ListItem>
    </FloatingMenu>
  );
};

export interface IOrientationProps {
  filterItems(pointer: string, filter: IQueryValue[]): void;
}

export default Orientation;
