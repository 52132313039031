import React from "react";
import { connect } from "react-redux";

import { IRootState } from "@ax/types";
import { Loading } from "@ax/components";
import { pageEditorActions } from "@ax/containers/PageEditor";
import { dataPacksActions } from "@ax/containers/Settings";

import { NavigationField } from "./NavigationField";
import { IntegrationsField } from "./IntegrationsField";
import * as S from "./style";

const navigationTypes = ["header", "footer"];

const ConfigPanel = (props: IProps): JSX.Element => {
  const { isLoading, template, ...rest } = props;

  if (isLoading && !template) return <Loading />;

  return (
    <>
      <S.HeaderWrapper>
        <S.Title>{template}</S.Title>
      </S.HeaderWrapper>
      <S.Tab>Config</S.Tab>
      <S.FieldsWrapper>
        <S.FieldsTitle>Default modules</S.FieldsTitle>
        {navigationTypes.map((type, idx) => (
          <NavigationField key={idx} type={type} template={template} {...rest} />
        ))}
        <IntegrationsField template={template} {...rest} />
      </S.FieldsWrapper>
    </>
  );
};

interface IStateProps {
  isLoading: boolean;
  template: string;
  defaults: any;
  content: any;
  dataPackConfigFormData: any;
  theme: string;
  templateConfig: any;
}

interface IDispatchProps {
  updateEditorContent: (selectedEditorID: number, key: string, value: any) => void;
  updateDataPackFormValue: (value: any) => void;
}

type IProps = IStateProps & IDispatchProps;

const mapStateToProps = (state: IRootState) => ({
  defaults: state.navigation.currentDefaultsContent,
  content: { ...state.pageEditor.editorContent },
  templateConfig: { ...state.pageEditor.templateConfig },
  dataPackConfigFormData: state.dataPacks.configFormData,
});

const mapDispatchToProps = {
  updateEditorContent: pageEditorActions.updateEditorContent,
  updateDataPackFormValue: dataPacksActions.updateFormValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigPanel);