import { template } from "./config";
import { IServiceConfig, sendRequest } from "./utils";

const SERVICES: { [key: string]: IServiceConfig } = {
  GET_SITE_ITEMS: {
    ...template,
    endpoint: "/select/site/",
    method: "GET",
  },
  GET_ITEMS: {
    ...template,
    endpoint: "/select",
    method: "GET",
  },
  GET_SITES: {
    ...template,
    endpoint: "/select/sites",
    method: "GET",
  },
};

const getSelectSiteItems = async (siteId: number, entity: string, params: any, pageID?: number) => {
  const { host, endpoint } = SERVICES.GET_SITE_ITEMS;

  let urlParams = "";
  if (params) {
    for (const prop in params) {
      urlParams = urlParams + `${prop}=${params[prop]}&`;
    }
    urlParams = "?" + urlParams.slice(0, -1);
  }

  SERVICES.GET_SITE_ITEMS.dynamicUrl = pageID
    ? `${host}${endpoint}${siteId}/${entity}/${pageID}${urlParams}`
    : `${host}${endpoint}${siteId}/${entity}${urlParams}`;

  return sendRequest(SERVICES.GET_SITE_ITEMS);
};

const getSelectItems = async (entity: string, entityId?: number | string) => {
  const { host, endpoint } = SERVICES.GET_ITEMS;
  SERVICES.GET_ITEMS.dynamicUrl = entityId
    ? `${host}${endpoint}/${entity}/${entityId}`
    : `${host}${endpoint}/${entity}/`;

  return sendRequest(SERVICES.GET_ITEMS);
};

const getSelectSites = async () => {
  const { host, endpoint } = SERVICES.GET_SITES;
  SERVICES.GET_ITEMS.dynamicUrl = `${host}${endpoint}`;

  return sendRequest(SERVICES.GET_SITES);
};

export default { getSelectSiteItems, getSelectItems, getSelectSites };
