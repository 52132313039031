import styled from "styled-components";

const IconWrapper = styled.div<{ error: boolean | undefined; readonly: boolean | undefined }>`
  position: relative;
  background-color: ${(p) => (p.readonly === true ? p.theme.color.uiBackground03 : `transparent`)};
  border-top: ${(p) => (p.readonly === true ? `none` : `1px solid`)};
  border-right: ${(p) => (p.readonly === true ? `none` : `1px solid`)};
  border-bottom: ${(p) => (p.readonly === true ? `none` : `1px solid`)};
  border-color: ${(p) => (p.error === true ? p.theme.color.error : p.theme.color.uiLine)};
  border-radius: ${(p) => `0 ${p.theme.radii.s} ${p.theme.radii.s} 0`};
  width: ${(p) =>
    p.readonly === true
      ? `calc(${p.theme.spacing.xs} * 2  + ${p.theme.spacing.s})`
      : `calc(${p.theme.spacing.xs} * 2  + ${p.theme.spacing.m})`};
  button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
      width: ${(p) => (p.readonly === true ? p.theme.spacing.s : p.theme.spacing.m)};
      height: ${(p) => (p.readonly === true ? p.theme.spacing.s : p.theme.spacing.m)}};
    }
  }
`;

const Input = styled.input<{
  error?: boolean;
  button?: boolean;
  icon?: boolean;
  hasPrefix: boolean;
  prefixWidth: number;
}>`
  flex: 1;
  border-top: 1px solid;
  border-right: ${(p) => (p.button ? `none` : `1px solid`)};
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-color: ${(p) => (p.error === true ? p.theme.color.error : p.theme.color.uiLine)};
  border-radius: ${(p) => (p.button ? `${p.theme.radii.s} 0 0 ${p.theme.radii.s}` : p.theme.radii.s)};
  background: ${(p) => p.theme.color.uiBackground02};
  padding-top: 0;
  padding-right: ${(p) => (p.icon ? `40px` : p.theme.spacing.s)};
  padding-bottom: 0;
  padding-left: ${(p) => (p.hasPrefix ? `calc(${p.prefixWidth}px + 15px)` : p.theme.spacing.s)};
  height: ${(p) => p.theme.spacing.l};
  width: 100%;
  ${(p) => p.theme.textStyle.fieldContent};
  color: ${(p) => p.theme.color.textHighEmphasis};

  &:active,
  &:focus {
    outline: none;
    border-color: ${(p) => (p.error === true ? p.theme.color.error : p.theme.color.interactive01)};
  }

  &::placeholder {
    color: ${(p) => p.theme.color.textLowEmphasis};
  }

  &:read-only {
    background-color: ${(p) => p.theme.color.uiBackground03};
    border: none;
    color: ${(p) => p.theme.color.textMediumEmphasis};
    padding: ${(p) => `0 0 0 ${p.theme.spacing.s}`};
  }

  &:disabled {
    color: ${(p) => p.theme.color.interactiveDisabled};
    border-color: ${(p) => p.theme.color.interactiveDisabled};
  }
`;

const FieldWrapper = styled.div<{ error: boolean | undefined }>`
  position: relative;
  display: flex;
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  &:focus-within {
    ${IconWrapper} {
      border-color: ${(p) => (p.error === true ? p.theme.color.error : p.theme.color.interactive01)};
    }
  }
`;

const BackgroundIcon = styled.div<{ onClick?: any; inversed: boolean }>`
  position: absolute;
  right: ${(p) => p.theme.spacing.xs};
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
  cursor: ${(p) => (p.onClick ? "pointer" : "default")};
  svg {
    path {
      fill: ${(p) => p.inversed ? p.theme.color.iconNonActive : p.theme.color.interactive01};
    }
  }
`;

const Prefix = styled.div`
  ${(p) => p.theme.textStyle.fieldContent};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${(p) => p.theme.spacing.s};
  color: ${(p) => p.theme.color.textLowEmphasis};
`;

export { IconWrapper, Input, FieldWrapper, BackgroundIcon, Prefix };
