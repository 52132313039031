import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const LeftPanel = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding-left: ${(p) => p.theme.spacing.m};
  padding-right: ${(p) => p.theme.spacing.m};
  align-items: center;
`;

const FilePreview = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  flex-flow: column wrap;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 300px;
`;

const IconWrapper = styled.div`
  position: relative;
  height: 300px;
  img {
    height: 300px;
  }
`;

const FileName = styled.div`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => p.theme.colors.textHighEmphasis};
  margin-top: ${(p) => p.theme.spacing.s};
  text-align: center;
  word-break: break-word;
`;

const FileActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(p) => p.theme.spacing.m};
`;

const ReplaceWrapper = styled.div`
  position: relative;
`;

const ActionMenu = styled.ul`
  padding: 0;
  white-space: nowrap;
`;

const ActionItem = styled.li`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textHighEmphasis};
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xs};
  cursor: pointer;

  &:focus {
    background: ${(p) => p.theme.color.overlayFocusPrimary};
  }

  &:hover {
    background: ${(p) => p.theme.color.overlayHoverPrimary};
  }
`;

const TextType = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  padding-left: ${(p) => p.theme.spacing.xs};
  padding-top: ${(p) => p.theme.spacing.xxs};
  width: 140px;
`;

const DragAndDropWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 300px;
  width: 300px;
`;

export {
  Wrapper,
  FilePreview,
  ImageWrapper,
  IconWrapper,
  FileName,
  LeftPanel,
  FileActions,
  ReplaceWrapper,
  ActionMenu,
  ActionItem,
  TextType,
  DragAndDropWrapper,
};
