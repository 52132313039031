import * as React from "react";

function SvgColorPicker(props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.71 5.63l-2.34-2.34a.996.996 0 00-1.41 0l-3.12 3.12-1.93-1.91-1.41 1.41 1.42 1.42L3 16.25V21h4.75l8.92-8.92 1.42 1.42 1.41-1.41-1.92-1.92 3.12-3.12c.4-.4.4-1.03.01-1.42zM10.92 15H7.08l5.98-5.98 1.92 1.92L10.92 15z"
        fill="#5057FF"
      />
    </svg>
  );
}

export default SvgColorPicker;
