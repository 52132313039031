import React, { useLayoutEffect } from "react";

import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { IGetRoles, IRootState, ISite } from "@ax/types";
import { Loading } from "@ax/components";
import { appActions } from "@ax/containers/App";
import { sitesActions } from "@ax/containers/Sites";
import { dataPacksActions } from "@ax/containers/Settings/DataPacks";
import { structuredDataActions } from "@ax/containers/StructuredData";
import { usersActions } from "@ax/containers/Users";

import SitesList from "./SitesList";

const Sites = (props: ISitesProps): JSX.Element => {
  const {
    isLoading,
    token,
    setCurrentSiteInfo,
    getStructuredData,
    setLanguage,
    getAllDataPacks,
    getUser,
    globalLangs,
    getRoles,
    hasAnimation,
    updateCurrentSearch,
  } = props;

  useLayoutEffect(() => {
    const fetchInitialData = async () => {
      setCurrentSiteInfo(null);
      updateCurrentSearch("");
      await getRoles({ siteId: "global" }, token);
      await getUser("me", token);
      await getStructuredData(token);
      await getAllDataPacks();

      const defaultLanguage = globalLangs.find((language: any) => language.isDefault);
      if (defaultLanguage) {
        const { locale, id } = defaultLanguage;
        const lang = {
          locale,
          id,
        };

        setLanguage(lang);
      }
    };

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return isLoading && !hasAnimation ? <Loading /> : <SitesList />;
};

const mapStateToProps = (state: IRootState) => ({
  isLoading: state.app.isLoading,
  token: state.app.token,
  globalLangs: state.app.globalLangs,
  hasAnimation: state.app.hasAnimation,
});

interface IStateProps {
  isLoading: boolean;
  token: string;
  globalLangs: any[];
  hasAnimation: boolean;
}

interface IDispatchProps {
  setCurrentSiteInfo(currentSiteInfo: ISite | null): void;
  getStructuredData(token: string, siteId?: number | null): Promise<void>;
  setLanguage(lang: { locale: string; id: number | null }): void;
  getAllDataPacks: () => Promise<void>;
  getUser: (id: string, token?: string) => Promise<void>;
  getRoles: (params: IGetRoles, token?: string) => Promise<void>;
  updateCurrentSearch(query: string): Promise<void>;
}

export type ISitesProps = IStateProps & IDispatchProps & RouteComponentProps;

const mapDispatchToProps = {
  setCurrentSiteInfo: sitesActions.setCurrentSiteInfo,
  getStructuredData: structuredDataActions.getStructuredData,
  setLanguage: appActions.setLanguage,
  getAllDataPacks: dataPacksActions.getAllDataPacks,
  getUser: usersActions.getUser,
  getRoles: usersActions.getRoles,
  updateCurrentSearch: structuredDataActions.updateCurrentSearch,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sites));
