import React, { useCallback, useRef, useState } from "react";

import { IIntegration, IIntegrationVariable, ILanguage } from "@ax/types";
import { FloatingPanel, Button, FieldsBehavior, NoteField } from "@ax/components";

import * as S from "./style";
import { modifyVariableValue } from "./helpers";

const VariablesPanel = (props: IProps): JSX.Element => {
  const { isOpen, toggleModal, integration, setIntegrationVariables, languages } = props;
  const { variables } = integration;

  const [variablesForm, setVariablesForm] = useState(variables);

  const setVariableValue = (
    id: number | undefined,
    value: string,
    multiLangVariable?: { id?: number; languageId: number; value: string }
  ) => {
    const newVariableValues = { id, value, multiLangVariable };
    const newVariables = modifyVariableValue(variablesForm, newVariableValues);
    setVariablesForm(newVariables);
  };
  const setVariableValueRef = useRef(setVariableValue);
  setVariableValueRef.current = setVariableValue;

  const editItemAction = () => {
    variablesForm && setIntegrationVariables(variablesForm);
    toggleModal();
  };

  const editButton = {
    label: "Update values",
    action: editItemAction,
  };

  const noteText =
    "This add-on has some variables assigned. You can define its values to customize some properties.";

  const Variable = ({ variable }: { variable: IIntegrationVariable }) => {
    const handleChange = (value: string) => setVariableValueRef.current(variable.id, value);
    return (
      <FieldsBehavior
        title={variable.variableName}
        name={variable.variableName}
        fieldType="TextArea"
        value={variable.value}
        placeholder={variable.defaultValue}
        onChange={handleChange}
        mandatory
        validators={{ mandatory: true }}
        rows={4}
      />
    );
  };

  const MultilanguageVariable = (props: {
    variable: IIntegrationVariable;
    multiLangVariable: { id?: number; languageId: number; value: string };
  }) => {
    const { variable, multiLangVariable } = props;
    const langName = languages.find((lang) => lang.id === multiLangVariable.languageId)?.language;
    const variableName = `${langName} ${variable.variableName}`;
    const handleChange = (value: string) => setVariableValueRef.current(variable.id, value, multiLangVariable);
    return (
      <FieldsBehavior
        title={variableName}
        name={variable.variableName}
        fieldType="TextArea"
        value={multiLangVariable.value}
        placeholder={variable.defaultValue}
        onChange={handleChange}
        mandatory
        validators={{ mandatory: true }}
        rows={4}
      />
    );
  };

  const renderVariables = useCallback(
    () =>
      variablesForm?.map((variable, index) =>
        variable.isMultilanguage ? (
          variable.multilanguage?.map((multiLangVariable, i) => (
            <MultilanguageVariable key={i} variable={variable} multiLangVariable={multiLangVariable} />
          ))
        ) : (
          <Variable key={index} variable={variable} />
        )
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variables]
  );

  return (
    <FloatingPanel title="Define values" toggleModal={toggleModal} isOpen={isOpen}>
      <S.Wrapper>
        <S.ContentWrapper>
          <S.NoteWrapper>
            <NoteField value={{ text: noteText }} />
          </S.NoteWrapper>
          {renderVariables()}
        </S.ContentWrapper>
        <S.Footer>
          <Button className="button" type="button" onClick={editButton.action}>
            {editButton.label}
          </Button>
        </S.Footer>
      </S.Wrapper>
    </FloatingPanel>
  );
};

interface IProps {
  integration: Partial<IIntegration>;
  isOpen: boolean;
  toggleModal: () => void;
  setIntegrationVariables(variables: IIntegrationVariable[]): void;
  languages: ILanguage[];
}

export default VariablesPanel;
