import type { Source } from "../types/api-response-fields";
import type { FilterItemFromFilterEndPoint } from "../types/global";

import { getLang, getToken } from "../functions/utils";

type SimpleFilter = Array<unknown>;
type FilterWithSource = Array<{ id: number; source: string; label: string }>;
type FilterFromEndpoint = Record<string, FilterItemFromFilterEndPoint>;
type FilterWithCategory = Array<{
	id: number;
	category: string;
	label: string;
	color: string;
}>;

// Return a RequestInit from node types
function getOptions(
	body: Record<string, unknown>,
	langID?: number,
): RequestInit {
	const lang = langID || getLang();

	return {
		method: "POST",
		mode: "cors",
		cache: "no-cache",
		headers: {
			"Content-Type": "application/json",
			lang: lang?.toString(),
			...getToken(),
		},
		redirect: "follow",
		referrerPolicy: "no-referrer",
		body: JSON.stringify(body),
	};
}

// Si es un array de números, es oldFormat
function isArrayOfNumbers(arr?: Array<unknown>): arr is Array<number> {
	if (!arr) return false;
	return arr.every((item) => typeof item === "number");
}

function isSimpleFilter(
	someFilter?: SimpleFilter | FilterWithSource | FilterFromEndpoint,
): someFilter is Array<number> {
	return Array.isArray(someFilter) && isArrayOfNumbers(someFilter);
}

// TODO: Asegurar más este guard para que analice la estructura de `someFilter`
function isFilterWithSource(
	someFilter?: SimpleFilter | FilterWithSource | FilterFromEndpoint,
): someFilter is FilterWithSource {
	return Array.isArray(someFilter) && !isArrayOfNumbers(someFilter);
}

// TODO: Asegurar más este guard para que analice la estructura de `someFilter`
function isFilterFromEndpoint(
	someFilter?: SimpleFilter | FilterWithSource | FilterFromEndpoint,
): someFilter is FilterFromEndpoint {
	return !isSimpleFilter(someFilter) && !isFilterWithSource(someFilter);
}

// TODO: Asegurar más este guard para que analice la estructura de `someFilter`
function isFilterWithCategory(
	someFilter?: SimpleFilter | FilterFromEndpoint | FilterWithCategory,
): someFilter is FilterWithCategory {
	return Array.isArray(someFilter) && !isArrayOfNumbers(someFilter);
}

function parseFilterWithSource(filters?: FilterWithSource) {
	if (!filters) return undefined;

	const filtersReduced = filters
		? filters.reduce(
				(acc, filterItem) => {
					const { source, id } = filterItem;
					// init si no existe...
					if (!acc[source]) acc[source] = [];
					// add...
					acc[source].push(id);
					return acc;
				},
				{} as Record<string, Array<number>>,
			)
		: {};

	return Object.keys(filtersReduced)
		.map((key) => `${key}:${filtersReduced[key]}`)
		.join(";");
}

function parseSimpleFilter(filters?: Array<number>) {
	return filters?.join(",");
}

function parseFilterWithCategory(filters?: FilterWithCategory) {
	return filters?.map((filter) => filter.id).join(",");
}

function parseFilterFromEndpoint(filters?: FilterFromEndpoint) {
	if (!filters) return undefined;

	return Object.keys(filters).map(
		(keyName) => filters[keyName].items?.map(({ id }) => id) || [],
	);
}

function parseFilter(
	filter?: SimpleFilter | FilterWithSource | FilterFromEndpoint,
) {
	if (isSimpleFilter(filter)) {
		return parseSimpleFilter(filter);
	}
	if (isFilterWithCategory(filter)) {
		return parseFilterWithCategory(filter);
	}
	if (isFilterFromEndpoint(filter)) {
		return parseFilterFromEndpoint(filter);
	}
	return undefined;
}

/**
 * Compute `related` with legacy support for the number and Array<number> value
 */
function parseRelated(filter?: number | SimpleFilter | FilterFromEndpoint) {
	if (typeof filter === "number") {
		return filter;
	}
	if (isSimpleFilter(filter)) {
		return parseSimpleFilter(filter);
	}
	if (isFilterWithSource(filter)) {
		return parseFilterWithSource(filter);
	}
	if (isFilterFromEndpoint(filter)) {
		return parseFilterFromEndpoint(filter);
	}
	return undefined;
}

function isOperator(operator?: string) {
	return !!operator && ["or", "and"].includes(operator.toLowerCase());
}

function parseSources<ContentType>(sources?: Array<Source<ContentType>>) {
	if (!sources) return "";

	const query: string[] = [];
	for (const source of sources) {
		const filters = parseFilter(source.filters);

		const options: string[] = [];
		if (filters) {
			options.push(`filters=${filters}`);
		}

		if (source.filterOperator) {
			options.push(`filterOperator=${source.filterOperator}`);
		}

		if (source.globalOperator) {
			options.push(`globalOperator=${source.globalOperator}`);
		}

		options.length
			? query.push(`${source.structuredData}[${options.join(";")}]`)
			: query.push(`${source.structuredData}`);
	}

	return query.join(",");
}

/**
 * Compute `relations` with legacy support for the boolean value
 */
function parseRelations(relations?: boolean | "off" | "simple" | "full") {
	// Boolean value: legacy
	if (typeof relations === "boolean") {
		return relations ? "simple" : "off";
	}

	// New values
	if (relations === "off" || relations === "simple" || relations === "full") {
		return relations;
	}

	// off...
	return "off";
}

export {
	getOptions,
	isArrayOfNumbers,
	isFilterFromEndpoint,
	isFilterWithCategory,
	isFilterWithSource,
	isOperator,
	isSimpleFilter,
	parseFilter,
	parseFilterFromEndpoint,
	parseFilterWithCategory,
	parseFilterWithSource,
	parseRelated,
	parseRelations,
	parseSimpleFilter,
	parseSources,
};
