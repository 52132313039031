const NAME = "app";

const SET_ERROR = `${NAME}/SET_ERROR`;
const SET_IS_LOADING = `${NAME}/SET_IS_LOADING`;
const SET_IS_SAVING = `${NAME}/SET_IS_SAVING`;
const SET_TOKEN = `${NAME}/SET_TOKEN`;
const LOGOUT = `${NAME}/LOGOUT`;
const IS_LOGGING_IN = `${NAME}/IS_LOGGING_IN`;
const SET_LANGUAGE = `${NAME}/SET_LANGUAGE`;
const SET_GLOBAL_LANGUAGES = `${NAME}/SET_GLOBAL_LANGUAGES`;
const SET_GLOBAL_SETTINGS = `${NAME}/SET_GLOBAL_SETTINGS`;
const SET_USER = `${NAME}/SET_USER`;
const SET_SESSION_STARTED_AT = `${NAME}/SET_SESSION_STARTED_AT`;
const SET_HAS_ANIMATION = `${NAME}/SET_HAS_ANIMATION`;

export {
  SET_ERROR,
  SET_IS_LOADING,
  SET_IS_SAVING,
  SET_TOKEN,
  LOGOUT,
  IS_LOGGING_IN,
  SET_LANGUAGE,
  SET_GLOBAL_LANGUAGES,
  SET_GLOBAL_SETTINGS,
  SET_USER,
  SET_SESSION_STARTED_AT,
  SET_HAS_ANIMATION,
};
