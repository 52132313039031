import styled from "styled-components";

const Content = styled.section`
  display: flex;
  min-height: 100%;
`;

const NotificationWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: ${p => `calc(${p.theme.spacing.s} * 4)`};
  left: 0;
  right: 0;
  z-index: 1100;
`;

const ModalContent = styled.div`
  padding: ${p => p.theme.spacing.m};
`;

export {
  Content,
  NotificationWrapper,
  ModalContent
}
