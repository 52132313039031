import styled from "styled-components";

const Item = styled.li`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: ${(p) => p.theme.spacing.s};
  padding-bottom: ${(p) => p.theme.spacing.s};
  margin-bottom: ${(p) => p.theme.spacing.s};
  box-shadow: ${(p) => p.theme.shadow.shadowS};
  border-radius: ${(p) => p.theme.radii.s};
  background-color: ${(p) => p.theme.color.interactiveBackground};
  &:hover {
    background: ${(p) => p.theme.color.overlayHoverPrimary};
  }
  &:focus {
    background-color: ${(p) => p.theme.color.overlayFocusPrimary};
  }
`;

const Title = styled.span`
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.color.textHighEmphasis};
`;

const Description = styled.span`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
`;

const CustomSticker = styled.div`
  position: absolute;
  width: 80px;
  height: 40px;
  left: -40px;
  top: -18px;
  background: ${(p) => p.theme.color.interactive01};
  transform: rotate(-45deg);
`;

const Icon = styled.div`
  position: absolute;
  right: 50%;
  bottom: 2px;
  margin-right: -2px;
  transform: rotate(45deg) translate(50%);
  & svg path {
    fill: ${(p) => p.theme.color.iconHighEmphasisInverse};
  }
`;

export { Item, Title, Description, CustomSticker, Icon };
