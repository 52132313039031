import React from "react";

const SvgPhotoLibrary = props => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      d="M20 4v12H8V4h12zm0-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 9.67l1.69 2.26 2.48-3.1L19 15H9l2.5-3.33zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z"
      fill="#5057FF"
    />
  </svg>
);

export default SvgPhotoLibrary;
