import * as React from "react";

const SvgCategory = (props) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m11 2-5.5 9h11L11 2Zm0 3.84L12.93 9H9.06L11 5.84ZM16.5 13c-2.49 0-4.5 2.01-4.5 4.5s2.01 4.5 4.5 4.5 4.5-2.01 4.5-4.5-2.01-4.5-4.5-4.5Zm0 7a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5ZM2 21.5h8v-8H2v8Zm2-6h4v4H4v-4Z"
      fill="#5057FF"
    />
  </svg>
);

export default SvgCategory;
