import styled from "styled-components";

const Wrapper = styled.div`
  padding: ${(p) => p.theme.spacing.m};
  height: 100%;
`;

const Footer = styled.div`
  position: absolute;
  bottom: ${(p) => p.theme.spacing.m};
  right: ${(p) => p.theme.spacing.m};
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};
`;

export { Wrapper, Footer, ModalContent };
