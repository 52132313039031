import { IPage, IBreadcrumbItem, ISchema, IErrorItem, IUserEditing, IModule } from "@ax/types";

import {
  SET_EDITOR_CONTENT,
  SET_TEMPLATE,
  SET_BREADCRUMB,
  SET_SCHEMA,
  SET_SELECTED_CONTENT,
  SET_TAB,
  SET_SELECTED_EDITOR_ID,
  SET_CURRENT_PAGE_ID,
  SET_CURRENT_PAGE_STATUS,
  SET_CURRENT_PAGE_NAME,
  SET_CURRENT_PAGE_LANGUAGES,
  SET_IS_NEW_TRANSLATION,
  INITIAL_TEMPLATE,
  SET_TEMPLATE_CONFIG,
  SET_SELECTED_PARENT,
  SET_ERRORS,
  SET_VALIDATED,
  SET_SITE_PAGE_ID,
  SET_USER_EDITING,
  SET_LAST_ELEMENT_ADDED_ID,
  SET_COPY_MODULE,
  SET_IS_IA_TRANSLATED,
  SET_LAST_TIMEOUT,
  RESET_PAGE_EDITOR_STORE,
  SET_SCROLL_EDITOR_ID,
} from "./constants";

import { PageEditorActionsCreators } from "./interfaces";

export interface IPageEditorState {
  editorContent: IPage | Record<string, unknown> | { path: string; slug: string; [key: string]: unknown };
  template: string;
  breadcrumb: IBreadcrumbItem[];
  schema: ISchema | Record<string, unknown>;
  selectedContent: IPage | Record<string, unknown>;
  tab: string;
  selectedEditorID: number | undefined;
  currentPageID: number | null;
  currentPageStatus: string | null;
  currentPageName: string;
  currentPageLanguages: any[];
  isNewTranslation: boolean;
  templateConfig: any;
  selectedParent: Record<string, unknown> | null;
  errors: IErrorItem[];
  validated: boolean;
  sitePageID: number | null;
  userEditing: IUserEditing | null;
  lastElementAddedId: null | number;
  moduleCopy: { date: Date; elements: IModule[] } | null;
  isIATranslated: boolean;
  lastTimeout: NodeJS.Timeout | null;
  scrollEditorID: number | null;
}

export const initialState = {
  editorContent: {},
  template: INITIAL_TEMPLATE,
  breadcrumb: [],
  schema: {},
  selectedContent: {},
  tab: "content",
  selectedEditorID: 0,
  currentPageID: null,
  currentPageStatus: null,
  currentPageName: "",
  currentPageLanguages: [],
  isNewTranslation: false,
  templateConfig: {},
  selectedParent: null,
  errors: [],
  validated: false,
  sitePageID: null,
  userEditing: null,
  lastElementAddedId: null,
  moduleCopy: null,
  isIATranslated: false,
  lastTimeout: null,
  scrollEditorID: null,
};

export function reducer(state = initialState, action: any): IPageEditorState {
  switch (action.type) {
    case SET_EDITOR_CONTENT:
      return { ...state, ...action.payload };
    case SET_TEMPLATE:
    case SET_BREADCRUMB:
    case SET_SCHEMA:
    case SET_SELECTED_CONTENT:
    case SET_TAB:
    case SET_SELECTED_EDITOR_ID:
    case SET_CURRENT_PAGE_ID:
    case SET_CURRENT_PAGE_STATUS:
    case SET_CURRENT_PAGE_NAME:
    case SET_CURRENT_PAGE_LANGUAGES:
    case SET_IS_NEW_TRANSLATION:
    case SET_TEMPLATE_CONFIG:
    case SET_SELECTED_PARENT:
    case SET_ERRORS:
    case SET_VALIDATED:
    case SET_SITE_PAGE_ID:
    case SET_USER_EDITING:
    case SET_LAST_ELEMENT_ADDED_ID:
    case SET_COPY_MODULE:
    case SET_IS_IA_TRANSLATED:
    case SET_LAST_TIMEOUT:
    case SET_SCROLL_EDITOR_ID:
      return { ...state, ...action.payload };
    case RESET_PAGE_EDITOR_STORE:
      return { ...initialState, moduleCopy: state.moduleCopy };
    default:
      return state;
  }
}

export { initialState as pageEditorInitialState, reducer as pageEditorReducer };
