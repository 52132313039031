import * as React from "react";
const SvgCodeHeadBody = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#5057FF"
      fillRule="evenodd"
      d="M2.9 4.175 4.375 2.7c.2-.2.3-.433.3-.7 0-.267-.1-.5-.3-.7-.2-.2-.438-.3-.712-.3-.275 0-.513.1-.713.3L.775 3.475c-.1.1-.17.208-.213.325a1.107 1.107 0 0 0-.062.375c0 .133.02.258.063.375a.877.877 0 0 0 .212.325L2.95 7.05c.2.2.438.3.712.3.276 0 .513-.1.713-.3.2-.2.3-.433.3-.7 0-.267-.1-.5-.3-.7L2.9 4.175Zm7.35 0L8.775 5.65c-.2.2-.3.433-.3.7 0 .267.1.5.3.7.2.2.438.3.713.3.274 0 .512-.1.712-.3l2.175-2.175c.1-.1.17-.208.213-.325.041-.117.062-.242.062-.375s-.02-.258-.063-.375a.878.878 0 0 0-.212-.325L10.2 1.3a.999.999 0 0 0-1.425 0c-.2.2-.3.433-.3.7 0 .267.1.5.3.7l1.475 1.475ZM6.5 8a1 1 0 0 0-1 1v1a1 1 0 1 0 2 0V9a1 1 0 0 0-1-1Zm7-4a1 1 0 0 0 1 1h6a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-12a1 1 0 0 1-1-1 1 1 0 1 0-2 0v1c0 .55.196 1.02.588 1.413.391.391.862.587 1.412.587h14c.55 0 1.02-.196 1.413-.587.391-.392.587-.863.587-1.413V5c0-.55-.196-1.02-.587-1.413A1.926 1.926 0 0 0 21.5 3h-7a1 1 0 0 0-1 1Zm-9.125 8.7L2.9 14.175l1.475 1.475c.2.2.3.433.3.7 0 .267-.1.5-.3.7-.2.2-.438.3-.712.3a.973.973 0 0 1-.713-.3L.775 14.875a.877.877 0 0 1-.213-.325 1.107 1.107 0 0 1-.062-.375c0-.133.02-.258.063-.375a.877.877 0 0 1 .212-.325L2.95 11.3c.2-.2.438-.3.712-.3.276 0 .513.1.713.3.2.2.3.433.3.7 0 .267-.1.5-.3.7Zm4.4 2.95 1.475-1.475L8.775 12.7a.96.96 0 0 1-.3-.7c0-.267.1-.5.3-.7a.999.999 0 0 1 1.425 0l2.175 2.175c.1.1.17.208.213.325.041.117.062.242.062.375s-.02.258-.063.375a.877.877 0 0 1-.212.325L10.2 17.05c-.2.2-.438.3-.712.3a.973.973 0 0 1-.713-.3.96.96 0 0 1-.3-.7c0-.267.1-.5.3-.7Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCodeHeadBody;
