const getSortedListStatus = (orderPointer: string, isAscending: boolean) => {
  const sortedListStatus = {
    isAscending,
    sortedByTitle: orderPointer === "name",
    sortedByUsers: orderPointer === "users",
    sortedByPermissions: orderPointer === "permissions",
  };

  return sortedListStatus;
};

export { getSortedListStatus };
