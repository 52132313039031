import React from "react";
import RadioField, { IRadioFieldProps } from "../RadioField";

import * as S from "./style";

const RadioGroup = (props: IRadioGroupProps): JSX.Element => {
  const { value, name, options, onChange, disabled, optionsOnSelect, inline } = props;

  const handleChange = (newValue: string) => {
    onChange(str2bool(newValue));
  };

  const str2bool = (val: any) => {
    if (val && typeof val === "string") {
      if (val.toLowerCase() === "true") return true;
      if (val.toLowerCase() === "false") return false;
    }
    return val;
  };

  const radiosArray = options
    ? options.map((item: IRadioFieldProps) => {
        const checked = value !== null && value !== undefined ? str2bool(value) === str2bool(item.value) : false;
        return (
          <S.RadioFieldWrapper key={item.name} inline={inline}>
            <RadioField
              onChange={handleChange}
              checked={checked}
              value={item.value}
              title={item.title}
              name={name}
              error={item.error}
              disabled={item.disabled || disabled}
            />
            {checked && optionsOnSelect}
          </S.RadioFieldWrapper>
        );
      })
    : null;

  return <S.FieldGroup inline={inline}>{radiosArray}</S.FieldGroup>;
};

interface IRadioGroupProps {
  name: string;
  value?: string | boolean;
  options: IRadioFieldProps[];
  error?: boolean;
  onChange: (value: string | boolean) => void;
  disabled?: boolean;
  optionsOnSelect?: React.ReactElement;
  inline?: boolean;
}

export default RadioGroup;
