import styled from "styled-components";

const Table = styled.div`
  display: flex;
  height: 100%;
  padding: ${(p) => p.theme.spacing.xs} 0 ${(p) => p.theme.spacing.s};
`;

const Column = styled.div`
  width: 100%;
  padding: 0 ${(p) => p.theme.spacing.m};
  height: calc(100% - ${(p) => p.theme.spacing.xs} * 4);
  padding-bottom: ${(p) => p.theme.spacing.l};

  &:first-child {
    width: calc(${(p) => p.theme.spacing.m} * 18);
    margin-top: ${(p) => p.theme.spacing.xs};
    padding-right: ${(p) => p.theme.spacing.xs};
  }

  &:last-child {
    padding-left: ${(p) => p.theme.spacing.xs};
  }
`;

const PageItem = styled.div`
  position: relative;
  background-color: ${(p) => p.theme.color.uiBackground02};
  display: flex;
  border: 1px solid ${(p) => p.theme.color.uiLine};
  border-radius: 4px;
  margin-bottom: ${(p) => p.theme.spacing.xs};
  padding: ${(p) => p.theme.spacing.xxs};
`;

const Header = styled.div`
  display: flex;
  width: 100%;
`;

const Check = styled.div`
  padding-right: ${(p) => p.theme.spacing.xs};
  padding-left: ${(p) => p.theme.spacing.xs};
  padding-top: ${(p) => p.theme.spacing.xs};
`;

const CheckAll = styled.div`
  display: flex;
  align-items: center;
  margin: ${(p) => p.theme.spacing.xs} calc(${(p) => p.theme.spacing.xxs} * 3.5);

  & > span {
    margin-left: ${(p) => p.theme.spacing.xs};
    ${(p) => p.theme.textStyle.fieldContent};
    color: ${(p) => p.theme.color.textHighEmphasis};
  }
`;

const PageInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Type = styled.div`
  padding: ${(p) => p.theme.spacing.xs} 0;
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.color.textLowEmphasis};
  flex-grow: 2;
`;

const Date = styled.div`
  padding: ${(p) => p.theme.spacing.xs};
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  flex-grow: 1;
  text-align: right;
`;

const Title = styled.div`
  padding: 0 0 ${(p) => p.theme.spacing.xs};
  ${(p) => p.theme.textStyle.fieldContent};
  color: ${(p) => p.theme.color.textHighEmphasis};
  display: inline;
`;

const List = styled.div`
  padding-right: ${(p) => p.theme.spacing.xxs};
  overflow: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 100%;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${(p) => p.theme.color.iconNonActive};
  }
`;

const PageList = styled(List)`
  height: 100%;
`;

const SelectedList = styled(List)`
  height: calc(${(p) => p.theme.spacing.l} * 3);
`;

const SelectedTitle = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin: ${(p) => p.theme.spacing.s} 0;
`;

const SelectedItem = styled.div`
  margin: ${(p) => p.theme.spacing.xxs} 0;
  display: flex;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  display: inline;
  margin-right: ${(p) => p.theme.spacing.xxs};
`;

export {
  Table,
  Column,
  PageItem,
  Check,
  Type,
  Title,
  Date,
  Header,
  PageInfo,
  PageList,
  SelectedList,
  CheckAll,
  SelectedTitle,
  SelectedItem,
  IconWrapper,
};
