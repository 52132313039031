import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import history from "./routes/history";
import { ConnectedRouter } from "connected-react-router";

import { GlobalStore } from "./GlobalStore";
import App from "./modules/App";

// TODO: Remove @ts-ignore when connected-react-router updates its types
export class Main {
  public static start(): void {
    const globalStore = new GlobalStore();
    const configStore = globalStore.configureStore(history);

    const container = document.getElementById("root");
    if (!container) throw new Error("Failed to find the root element");
    const root = ReactDOM.createRoot(container);

    root.render(
      <Provider store={configStore.store}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <ConnectedRouter history={history}>
          <PersistGate loading={undefined} persistor={configStore.persistor}>
            <App />
          </PersistGate>
        </ConnectedRouter>
      </Provider>
    );
  }
}

Main.start();
