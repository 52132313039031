import type { Site } from "../types/core";

import * as React from "react";

import { SiteContext } from "../contexts";

/**
 * `useSite()`
 *
 * Hook to get some site properties.
 * @see [Documentation](https://www.notion.so/griddoio/useSite-6d8065726c03409f9f884515ecab2eca)
 *
 * @example
 * const { renderer } = useSite()
 */
const useSite: UseSite = () => React.useContext(SiteContext);

type UseSite = () => Site;

export { useSite };
