import React, { useState, useRef, useEffect } from "react";

import { IconAction, VisualOption } from "@ax/components";
import { getOptions } from "../utils";

import * as S from "./style";

const ScrollableSelection = (props: IScrollableSelectionProps): JSX.Element => {
  const { value, onChange, actions, options, objKey, columns, disabled, reference, theme } = props;
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [carouselItemWidth, setCarouselItemWidth] = useState<number>();
  const carouselRef = useRef<HTMLDivElement>(null);

  const mappedOptions: any = getOptions(theme, options);

  const slides = Math.ceil(mappedOptions.length / columns);

  const handleSelection = (newValue: string) => {
    onChange(newValue);
    actions.replaceElementsInCollectionAction(newValue, reference);
  };

  const handlePreviousPage = () => {
    setCarouselIndex(carouselIndex > 0 ? carouselIndex - 1 : 0);
  };

  const handleNextPage = () => {
    setCarouselIndex(carouselIndex < slides - 1 ? carouselIndex + 1 : 0);
  };

  useEffect(() => {
    carouselRef.current!.scrollLeft = carouselItemWidth ? carouselItemWidth * carouselIndex * columns : 0;
  }, [carouselIndex, carouselItemWidth, columns]);

  useEffect(() => {
    if (carouselRef && carouselRef.current) {
      setCarouselItemWidth(Math.floor(carouselRef.current.offsetWidth / columns));
    }
  }, [carouselRef, columns]);

  return (
    <S.Wrapper data-testid="scrollableSelectionComponent">
      <S.Carousel ref={carouselRef}>
        {mappedOptions.map((option: IScrollableUniqueSelectionFieldOptionsProps, index: number) => (
          <S.CarouselItem width={carouselItemWidth} columns={columns} key={index}>
            <VisualOption
              value={option.value}
              onChange={handleSelection}
              label={option.value}
              name={objKey}
              checked={option.value === value}
              img={option.img}
              disabled={disabled}
            />
          </S.CarouselItem>
        ))}
      </S.Carousel>
      {options.length > columns && (
        <S.SlideSelector>
          <S.MoveButton>
            <IconAction icon="Left-arrow" onClick={handlePreviousPage} />
          </S.MoveButton>
          <S.DotGroup>
            {[...Array(slides)].map((_, i) => (
              <S.Dot data-testid="dotSelected" selected={i === carouselIndex} key={i}></S.Dot>
            ))}
          </S.DotGroup>
          <S.MoveButton>
            <IconAction icon="Right-arrow" onClick={handleNextPage} />
          </S.MoveButton>
        </S.SlideSelector>
      )}
    </S.Wrapper>
  );
};

export interface IScrollableSelectionProps {
  value: string;
  title: string;
  onChange: (value: string) => void;
  options: any[];
  name: string;
  objKey: string;
  columns: number;
  disabled?: boolean;
  actions: any;
  selectedContent: any;
  reference?: string;
  theme: string;
  elementUniqueSelection: boolean;
}

interface IScrollableUniqueSelectionFieldOptionsProps {
  value: string;
  img: string;
}

export default ScrollableSelection;
