import React, { useState } from "react";

import { IDomainRobot } from "@ax/types";
import { FloatingPanel, Button, FieldsBehavior } from "@ax/components";

import * as S from "./style";

const RobotsPanel = (props: IProps): JSX.Element => {
  const { isOpen, toggleModal, item, setItemContent } = props;

  const [value, setValue] = useState(item.content);

  const editItemAction = () => {
    setItemContent(value);
    toggleModal();
  };

  const resetItem = () => setValue("");

  const title = item.content ? "Update Robots.txt" : "Add Robots.txt";

  const editButton = {
    label: title,
    action: editItemAction,
  };

  const placeholder = "User-agent: * \nAllow: *";

  return (
    <FloatingPanel title={title} toggleModal={toggleModal} isOpen={isOpen}>
      <S.Wrapper>
        <S.Item>
          <S.ItemHeader>You are editing</S.ItemHeader>
          <S.ItemContent>{item.fullUrl}</S.ItemContent>
        </S.Item>
        <FieldsBehavior
          fieldType="TextArea"
          onChange={setValue}
          value={value}
          title="Robots.txt"
          placeholder={placeholder}
        />
        <Button buttonStyle="text" type="button" icon="refresh" disabled={!value} onClick={resetItem}>
          Reset to default
        </Button>
        <S.Footer>
          <Button className="button" type="button" onClick={editButton.action}>
            {editButton.label}
          </Button>
        </S.Footer>
      </S.Wrapper>
    </FloatingPanel>
  );
};

interface IProps {
  item: IDomainRobot;
  isOpen: boolean;
  toggleModal(): any;
  setItemContent(content: string): void;
}

export default RobotsPanel;
