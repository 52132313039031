import React, { useRef } from "react";
import { createPortal } from "react-dom";

import { useHandleClickOutside } from "@ax/hooks";
import { IconAction } from "@ax/components";
import { IIntegration } from "@ax/types";

import SideModalOption from "./SideModalOption";
import * as S from "./style";

const SideModal = (props: ISideModalProps): JSX.Element | null => {
  const { integrations, isOpen, toggleModal, handleClick } = props;

  const node = useRef<HTMLDivElement>(null);
  const handleClickOutside = (e: React.MouseEvent) => {
    if (node.current?.contains(e.target as Node)) {
      return;
    }
    toggleModal();
  };

  useHandleClickOutside(isOpen, handleClickOutside);

  return isOpen
    ? createPortal(
        <S.Wrapper ref={node}>
          <S.Header>
            <S.Title>Add-ons</S.Title>
            <S.ButtonWrapper>
              <IconAction icon="close" onClick={toggleModal} />
            </S.ButtonWrapper>
          </S.Header>
          <S.Content>
            {integrations?.map((option, i) => (
              <SideModalOption
                key={`${option.id}${i}`}
                option={option}
                handleClick={handleClick}
                toggleModal={toggleModal}
              />
            ))}
            <SideModalOption handleClick={handleClick} toggleModal={toggleModal} custom />
          </S.Content>
        </S.Wrapper>,
        document.body
      )
    : null;
};

interface ISideModalProps {
  isOpen: boolean;
  integrations: IIntegration[] | undefined;
  toggleModal: () => void;
  handleClick: (integrationId: Partial<IIntegration>) => void;
}

export default SideModal;
