import React, { memo } from "react";
import { IHeadingField } from "@ax/types";
import { FieldsBehavior, TextField } from "@ax/components";

import * as S from "./style";

const HeadingField = (props: IHeadingFieldProps): JSX.Element => {
  const { value, onChange, options, showAdvanced } = props;

  const getContentValue = () => value?.content || props.default?.content || "";
  const getTagValue = () => value?.tag || props.default?.tag || "";

  const handleChange = (newValue: string) => {
    const tag = getTagValue();
    onChange({ content: newValue, tag });
  };

  const handleSelectChange = (newValue: string) => {
    const content = getContentValue();
    onChange({ content, tag: newValue });
  };

  return (
    <>
      <TextField {...props} value={getContentValue()} onChange={handleChange} />
      {showAdvanced && (
        <S.AdvancedWrapper data-testid="text-field-advanced-wrapper">
          <FieldsBehavior fieldType="Select" options={options} value={getTagValue()} onChange={handleSelectChange} />
        </S.AdvancedWrapper>
      )}
    </>
  );
};

interface IHeadingFieldProps {
  value: IHeadingField;
  title: string;
  onChange: (value: IHeadingField) => void;
  options: IOption[];
  showAdvanced: boolean;
  default: IHeadingField;
}

interface IOption {
  value: string;
  label: string;
}

export default memo(HeadingField);
