import React from "react";
import styled from "styled-components";
import { ActionMenu } from "@ax/components";

const StyledActionMenu = styled((props) => <ActionMenu {...props} />)`
  position: absolute;
  top: ${(p) => p.theme.spacing.xs};
  right: ${(p) => p.theme.spacing.xs};
  width: 32px;
  opacity: 0;
`;

const Item = styled.li`
  position: relative;
  display: flex;
  align-items: stretch;
  background-color: ${(p) => p.theme.color.uiBarBackground};
  border: ${(p) => `1px solid ${p.theme.color.uiLine}`};
  border-radius: ${(p) => p.theme.radii.s};
  padding: ${(p) => `${p.theme.spacing.s} ${p.theme.spacing.s} ${p.theme.spacing.s} ${p.theme.spacing.xs}`};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  cursor: pointer;
  &:hover {
    background-color: ${(p) => p.theme.color.overlayHoverPrimary};
    ${StyledActionMenu} {
      opacity: 1;
    }
  }
  :focus {
    background-color: ${(p) => p.theme.color.overlayFocusPrimary};
    ${StyledActionMenu} {
      opacity: 1;
    }
  }
`;

const TextWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: ${(p) => p.theme.spacing.l};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const Date = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
`;

const Type = styled.div`
  ${(p) => p.theme.textStyle.headingXSS};
  color: ${(p) => p.theme.color.textLowEmphasis};
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.fieldContent};
  color: ${(p) => p.theme.color.textHighEmphasis};
`;

const ButtonsWrapper = styled.span`
  display: flex;
  position: absolute;
  bottom: ${(p) => p.theme.spacing.xs};
  right: ${(p) => p.theme.spacing.xs};
`;

const HandleWrapper = styled.div<{ hidden?: boolean }>`
  display: ${(p) => (p.hidden ? "none" : "flex")};
  align-items: center;
  padding-right: ${(p) => p.theme.spacing.xxs};
`;

const IconHandleWrapper = styled.div`
  width: ${(p) => p.theme.spacing.s};
  height: ${(p) => p.theme.spacing.s};
  svg {
    path {
      fill: ${(p) => p.theme.color.textLowEmphasis};
    }
  }
`;

export {
  Item,
  TextWrapper,
  Header,
  Date,
  Type,
  Title,
  ButtonsWrapper,
  HandleWrapper,
  IconHandleWrapper,
  StyledActionMenu,
};
