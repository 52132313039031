import React, { Dispatch, SetStateAction } from "react";
import { connect } from "react-redux";

import { IRootState } from "@ax/types";
import Item from "./../Item";

import * as S from "./style";

const List = (props: IProps): JSX.Element => {
  const { activated, toggleToast, setDeactivatedPack } = props;

  return (
    <>
      <S.List>
        {activated &&
          activated.map((pack: any, key: number) => (
            <Item key={key} pack={pack} toggleToast={toggleToast} setDeactivatedPack={setDeactivatedPack} />
          ))}
      </S.List>
    </>
  );
};

interface IProps {
  activated?: any;
  toggleToast: () => void;
  setDeactivatedPack: Dispatch<SetStateAction<string>>;
}

const mapStateToProps = (state: IRootState) => ({
  activated: state.dataPacks.activated,
});

export default connect(mapStateToProps, null)(List);
