import { ICategoryGroup, IDataLanguage, IStructuredDataCategory } from "@ax/types";

const getAllLangCategoriesIds = (
  currentCategories: (IStructuredDataCategory | ICategoryGroup)[],
  selectedItems: Record<string, number[]>
): number[] => {
  let langsCategoryIds: number[] = [];

  const selectedCategories = currentCategories.filter((category) => selectedItems.all.includes(category.id));
  langsCategoryIds = selectedCategories.reduce((ids: number[], category: IStructuredDataCategory | ICategoryGroup) => {
    const langsCategoryIds = category.dataLanguages.map((lang: IDataLanguage) => lang.id);
    return [...ids, ...langsCategoryIds];
  }, []);

  return langsCategoryIds;
};

const filterCategoriesAndGroups = (
  currentCategories: (IStructuredDataCategory | ICategoryGroup)[]
): { groups: ICategoryGroup[]; categories: IStructuredDataCategory[] } => {
  const groups = currentCategories.filter(
    (cat: IStructuredDataCategory | ICategoryGroup) => cat.type === "group"
  ) as ICategoryGroup[];

  const categories = currentCategories.filter(
    (cat: IStructuredDataCategory | ICategoryGroup) => cat.type === "category"
  ) as IStructuredDataCategory[];

  return { groups, categories };
};

const isCategoryGroup = (obj: any): obj is ICategoryGroup => {
  return obj && Object.prototype.hasOwnProperty.call(obj, "type") && obj.type === "group";
};

const isCategory = (obj: any): obj is IStructuredDataCategory => {
  return obj && Object.prototype.hasOwnProperty.call(obj, "type") && obj.type === "category";
};

export { getAllLangCategoriesIds, filterCategoriesAndGroups, isCategoryGroup, isCategory };
