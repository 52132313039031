import React from "react";

import * as S from "./style";

const SubNav = ({ children }: ISubNavProps): JSX.Element => {
  return (
    <S.Wrapper data-testid="sub-nav-wrapper">
      <S.SubNav data-testid="sub-nav">{children}</S.SubNav>
    </S.Wrapper>
  );
};

export interface ISubNavProps {
  children: any;
}

export default SubNav;
