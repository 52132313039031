import { IDataPack, IDataPackCategory } from "@ax/types";
import {
  SET_ACTIVATED,
  SET_SELECTED,
  SET_CONFIG_FORM_DATA,
  SET_AVAILABLE,
  SET_CATEGORIES,
  SET_MODULES,
  SET_TEMPLATES,
} from "./constants";

export interface IDataPacksState {
  activated: IDataPack[];
  selected: IDataPack | null;
  configFormData: any;
  available: IDataPack[];
  categories: IDataPackCategory[];
  modules: any[];
  templates: any[];
}

export const initialState = {
  activated: [],
  selected: null,
  configFormData: {
    defaultParent: null,
    indexDefault: true,
    modifiableOnPage: false,
  },
  available: [],
  categories: [],
  modules: [],
  templates: [],
};

export function reducer(state = initialState, action: any): IDataPacksState {
  switch (action.type) {
    case SET_ACTIVATED:
    case SET_SELECTED:
    case SET_CONFIG_FORM_DATA:
    case SET_AVAILABLE:
    case SET_CATEGORIES:
    case SET_MODULES:
    case SET_TEMPLATES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export { initialState as dataPacksInitialState, reducer as dataPacksReducer };
