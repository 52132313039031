import * as React from "react";

import { SiteContext } from "../contexts";

/**
 * `useLink()`
 *
 * @deprecated This hooks is used by the old `<Link>` component from Griddo which you may want not use anymore. Use `<GriddoLink>` instead.
 */
function useLink() {
	const siteContextValue = React.useContext(SiteContext);
	return siteContextValue?.linkComponent;
}

export { useLink };
