import React from "react";

import { CheckField, Tag } from "@ax/components";
import { ICheck, IRole } from "@ax/types";

import * as S from "./style";

const RoleItem = (props: IRoleItemProps): JSX.Element => {
  const { role, onChange, onClick, isChecked = false, isSelected = false, isReadOnly = false } = props;

  const handleClick = () => {
    onClick && onClick(role);
    onChange && onChange({ value: role.id, isChecked: !isChecked });
  };

  return (
    <S.RoleItem
      role="rowgroup"
      selected={isChecked}
      isSelected={isSelected}
      isReadOnly={isReadOnly}
      data-testid="roles-modal-item"
    >
      {!isReadOnly && (
        <S.CheckCell role="cell">
          <CheckField name={role.name} value={role.id} checked={isChecked} onChange={onChange} />
        </S.CheckCell>
      )}
      <S.TitleCell onClick={handleClick} data-testid="roles-modal-item-title">
        <S.Name>
          <Tag text={role.name} color={role.hex} />
        </S.Name>
        <S.Description>{role.description}</S.Description>
      </S.TitleCell>
    </S.RoleItem>
  );
};

export interface IRoleItemProps {
  role: IRole;
  isChecked?: boolean;
  isSelected?: boolean;
  onChange?(value: ICheck): void;
  onClick?(role: IRole): void;
  isReadOnly?: boolean;
}

export default RoleItem;
