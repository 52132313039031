import styled from "styled-components";
import { ActionMenu } from "@ax/components";

const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  pointer-events: ${(p) => (p.disabled ? "none" : "auto")};
`;

const IconWrapper = styled.div`
  margin-right: ${(p) => p.theme.spacing.xxs};
`;

const IconWrapperDrag = styled.div`
  margin-right: ${(p) => p.theme.spacing.xxs};
  ${(p) => p.theme.textStyle.uiXS};
  opacity: 1;
  svg {
    path {
      fill: ${(p) => p.theme.color.textLowEmphasis};
    }
  }
`;

const StyledActionMenu = styled(ActionMenu)`
  opacity: 0;
`;

const Component = styled.span<{ isGroup: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  color: ${(p) => (p.isGroup ? p.theme.color.textMediumEmphasis : p.theme.color.textHighEmphasis)};
  height: ${(p) => p.theme.spacing.l};
  background-color: ${(p) => p.theme.color.uiBackground02};
  border: 1px solid transparent;
  margin-top: ${(p) => p.theme.spacing.xxs};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  padding: 0 ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.radii.s};
  box-shadow: ${(p) => p.theme.shadow.shadowS};
  ${(p) => p.theme.textStyle.fieldLabel};
  text-align: left;
  cursor: pointer;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: ${(p) => p.theme.color.uiBackground02};
  }

  &:hover {
    background-color: ${(p) => p.theme.color.overlayHoverPrimary};
    ${StyledActionMenu as any} {
      opacity: 1;
    }
  }

  &:focus,
  &:active {
    background-color: ${(p) => p.theme.color.overlayFocusPrimary};
    border: 1px solid ${(p) => p.theme.color.interactive01};
    outline: none;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const GroupIcon = styled.div`
  height: ${(p) => p.theme.spacing.s};
  width: ${(p) => p.theme.spacing.s};
  margin-right: ${(p) => p.theme.spacing.xs};
  svg {
    path {
      fill: #dadada;
    }
  }
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};
  p {
    margin-bottom: ${(p) => p.theme.spacing.m};
  }
  div {
    margin-bottom: 0;
  }
`;

export { Container, IconWrapper, IconWrapperDrag, Component, FlexWrapper, StyledActionMenu, GroupIcon, ModalContent };
