import styled from "styled-components";

export const Title = styled.div`
  ${(p) => p.theme.textStyle.headingM}
  color: ${(p) => p.theme.colors.textHighEmphasis};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: ${(p) => p.theme.spacing.xl};
  width: 100%;
  padding: 0 ${(p) => p.theme.spacing.m};
`;

export const Wrapper = styled.section<{
  right?: boolean;
  isOpen: boolean;
  isOpenedSecond: boolean | undefined;
  secondary?: boolean;
}>`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1200;
  width: calc(${(p) => p.theme.spacing.xl} * 6);
  height: 100vh;
  background: ${(p) => p.theme.colors.uiBackground01};
  box-shadow: ${(p) => (p.secondary || !p.isOpen ? `none` : p.theme.shadow.rightPanel)};
  border: ${(p) => (p.secondary ? `1px solid ${p.theme.colors.uiLine}` : `none`)};
  transform: ${(p) => (p.isOpen ? (p.isOpenedSecond ? `translateX(-384px)` : `translateX(0)`) : `translateX(100%)`)};
  transition: transform 0.3s ease-in-out;
  body.modal-open:not(&) {
    overflow: hidden;
  }

  ${Header} {
    background-color: ${(p) => (p.secondary ? `trasnparent` : p.theme.colors.uiBackground02)};
    border-bottom: ${(p) => (p.secondary ? `none` : `1px solid ${p.theme.colors.uiLine}`)};

    ${Title} {
      ${(p) => (p.secondary ? p.theme.textStyle.headingS : p.theme.textStyle.headingM)}
      color: ${(p) => p.theme.colors.textHighEmphasis};
    }
  }
`;

export const Content = styled.div`
  height: 100%;
`;

export const ButtonWrapper = styled.div`
  margin: 0 0 0 auto;
`;
