import styled from "styled-components";

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.uiMSemibold};
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

export { ModalContent, Title };
