import React from "react";

import { CheckField, TableCounter, CheckGroupFilter, StateFilter, Tooltip } from "@ax/components";
import { IQueryValue } from "@ax/types";

import * as S from "./style";

const TableHeader = (props: IProps): JSX.Element => {
  const { totalItems, selectAllItems, isScrolling, filterItems, filterValues, checkState, setHoverCheck } = props;

  const filters = [
    {
      name: "all",
      value: "all",
      title: "ALL",
    },
    {
      name: "allpages",
      value: "allpages",
      title: "All pages",
    },
    {
      name: "somepages",
      value: "somepages",
      title: "Some pages",
    },
    {
      name: "none",
      value: "none",
      title: "None",
    },
  ];

  const filtersCode = [
    {
      name: "all",
      value: "all",
      title: "ALL",
    },
    {
      name: "head",
      value: "head",
      title: "Head",
    },
    {
      name: "body",
      value: "body",
      title: "Body",
    },
    {
      name: "headBody",
      value: "headBody",
      title: "Head&Body",
    },
  ];

  return (
    <S.TableHeader isScrolling={isScrolling}>
      <S.CheckHeader>
        <Tooltip content="Select All Add-ons" bottom>
          <CheckField
            key="selectAll"
            name="selectAll"
            value="selectAll"
            onChange={selectAllItems}
            checked={checkState.isAllSelected || checkState.hoverCheck}
            disabled={false}
            error={false}
            setHoverCheck={setHoverCheck}
          />
        </Tooltip>
      </S.CheckHeader>
      <S.NameHeader>Name</S.NameHeader>
      <S.DescriptionHeader></S.DescriptionHeader>
      <S.AppliedOnHeader>
        <CheckGroupFilter
          filterItems={filterItems}
          value={filterValues.filterApplication}
          pointer="filterApplication"
          label="Applied on"
          description="Filter by applicated"
          initialState={["all"]}
          selectAllOption="all"
          filters={filters}
        />
      </S.AppliedOnHeader>
      <S.CodeHeader>
        <CheckGroupFilter
          filterItems={filterItems}
          value={filterValues.filterCode}
          pointer="filterCode"
          label="Code"
          description="Filter by code"
          initialState={["all"]}
          selectAllOption="all"
          filters={filtersCode}
        />
      </S.CodeHeader>
      <S.StateHeader>
        <StateFilter filterItems={filterItems} value={filterValues.filterState} />
      </S.StateHeader>
      <S.ActionsHeader>
        <TableCounter totalItems={totalItems} />
      </S.ActionsHeader>
    </S.TableHeader>
  );
};

interface IProps {
  totalItems: number;
  isScrolling: boolean;
  selectAllItems: () => void;
  filterItems: (filterPointer: string, filtersSelected: IQueryValue[]) => void;
  filterValues: Record<string, IQueryValue[]>;
  setHoverCheck: (state: boolean) => void;
  checkState: Record<string, boolean>;
}

export default TableHeader;
