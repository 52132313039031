const formatBytes = (bytes: number | null, decimals = 2) => {
  if (bytes === 0 || bytes === null) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const imageToBase64 = (file: any): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader: any = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export { formatBytes, imageToBase64 };
