import { useEffect, useRef, useState } from "react";
import { IQueryValue, ISitesQueryValues } from "@ax/types";

const useSortedListStatus = () => {
  const sortedInitialState: {
    isAscending: boolean;
    sortedByTitle: boolean;
    sortedByLastAccess: boolean;
    sortedByDateCreated: boolean;
  } = {
    isAscending: false,
    sortedByTitle: false,
    sortedByLastAccess: true,
    sortedByDateCreated: false,
  };

  const [sortedListStatus, setSortedListStatus] = useState(sortedInitialState);

  return {
    sortedListStatus,
    setSortedListStatus,
  };
};

const useFilterQuery = (defaultValues?: { order: IQueryValue[]; liveStatus: IQueryValue[] }): ISitesFilterQuery => {
  const initialQueryValues: Record<string, IQueryValue[]> = {
    order: [{ value: "lastAccess-desc", label: "lastAccess-desc"}],
    liveStatus: [{ value: "all", label: "All"}],
  };

  const [query, setQuery] = useState(defaultValues || initialQueryValues);
  const [currentFilterQuery, setCurrentFilterQuery] = useState("");

  const setFilterQuery = (filterValues: ISitesQueryValues) => {
    const { order, liveStatus } = filterValues;

    let filterQuery = "";
    const currentQuery = (pointer: string, values: IQueryValue[]) => {
      return values.length && values[0].value !== "all" ? `&${pointer}=${values[0].value}` : "";
    };

    const isNotInitialValue = (pointer: keyof ISitesQueryValues) => {
      return filterValues[pointer] && initialQueryValues[pointer] !== filterValues[pointer];
    };

    if (isNotInitialValue("liveStatus")) {
      const query = currentQuery("liveStatus", liveStatus);
      filterQuery = `${filterQuery}${query}`;
    }

    if (isNotInitialValue("order")) {
      const query = currentQuery("order", order);
      filterQuery = `${filterQuery}${query}`;
    }

    setCurrentFilterQuery(filterQuery);
  };

  const setFiltersSelection = (pointer: string, filter: IQueryValue[], isAscendent?: boolean) => {
    const { order, liveStatus } = query;
    const orderMethod = isAscendent ? "asc" : "desc";
    const liveStatusValue: Record<string, string> = {
      all: "all",
      active: "online",
      offline: "offline",
    };

    const liveValue = filter.length > 0 ? [{value: liveStatusValue[filter[0].value], label: filter[0].label}] : []

    const filterValues = {
      order: pointer === "order" ? [{ value: `${filter[0].value}-${orderMethod}`, label: filter[0].label }] : order,
      liveStatus: pointer === "liveStatus" ? liveValue : liveStatus,
    };

    setQuery(filterValues);
    setFilterQuery(filterValues);
  };

  const resetFilterQuery = () => {
    setQuery(initialQueryValues);
    setCurrentFilterQuery("");
  }

  return {
    setFiltersSelection,
    resetFilterQuery,
    filterValues: query,
    filterQuery: currentFilterQuery,
  };
};

const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

interface ISitesFilterQuery {
  setFiltersSelection(pointer: string, filter: IQueryValue[], isAscendent?: boolean): void;
  resetFilterQuery(): void;
  filterValues: Record<string, IQueryValue[]>;
  filterQuery: string;
}

export { useSortedListStatus, useFilterQuery, useIsMount };
