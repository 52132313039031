import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  background-color: ${(p) => p.theme.color.uiBackground02};
  border: ${(p) => `1px solid ${p.theme.color.uiLine}`};
  border-radius: ${p => p.theme.radii.s};
  display: flex;
  align-items: center;
  min-width: 165px;
  max-width: 180px;
  height: 40px;
  cursor: pointer;
  &:hover {
    background-color: ${(p) => p.theme.color.overlayHoverPrimary};
  }
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-left: ${(p) => p.theme.spacing.m};
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.colors.textHighEmphasis};
  margin-left: ${(p) => p.theme.spacing.xs};
`;

export { Wrapper, IconWrapper, Title };
