import type { SVGProps } from "react";

import * as React from "react";

const SvgDuplicate = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={24}
		height={24}
		fill="none"
		role="img"
		aria-label="Duplicate"
		{...props}
	>
		<path d="M12.3254 13.9H14V11.8246H16.0754V10.1746H14V8.0998H12.3254V10.1746H10.2752V11.8246H12.3254V13.9ZM8.0004 19.675C7.5504 19.675 7.1586 19.5082 6.825 19.1746C6.4918 18.8414 6.3252 18.4498 6.3252 17.9998V3.6746C6.3252 3.2246 6.4918 2.833 6.825 2.4998C7.1586 2.1666 7.5504 2 8.0004 2H16.125L20.0002 5.8746V17.9998C20.0002 18.4498 19.8336 18.8414 19.5004 19.1746C19.1668 19.5082 18.775 19.675 18.325 19.675H8.0004ZM8.0004 17.9998H18.325V6.6246L15.3 3.6746H8.0004V17.9998ZM4.6752 22.9996C4.2252 22.9996 3.8336 22.833 3.5004 22.4998C3.1668 22.1666 3 21.775 3 21.325V6.3496H4.6752V21.325H15.6254V22.9996H4.6752ZM8.0004 17.9998V3.6746V7.6246V17.9998Z" />
	</svg>
);

export default SvgDuplicate;
