import React from "react";

import { IModal } from "@ax/types";
import { Modal, FieldsBehavior, Button } from "@ax/components";

import * as S from "./style";

const DeleteModal = (props: IDeleteModal): JSX.Element => {
  const {
    isOpen,
    toggleModal,
    mainModalAction,
    secondaryModalAction,
    arePagesTranslated,
    deleteAllVersions,
    setDeleteAllVersions,
  } = props;

  const options = [
    {
      title: "Delete only this version",
      name: "deletePage",
      value: false,
    },
    {
      title: "Delete all languages versions",
      name: "deleteAll",
      value: true,
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Delete Global Data?"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
    >
      {arePagesTranslated ? (
        <>
          <S.ModalContent>
            <p>
              Are you sure you want to delete <strong>these pages</strong> page? If you delete it, this page does not
              appear in the sites associated. Also, this page has some translations associated.
            </p>
            <FieldsBehavior
              name="removeAllVersions"
              fieldType="RadioGroup"
              value={deleteAllVersions}
              options={options}
              onChange={setDeleteAllVersions}
            />
          </S.ModalContent>
        </>
      ) : (
        <S.ModalContent>
          Are you sure you want to delete <strong>these pages</strong> page? If you delete it, this page does not appear
          in the sites associated. This action <strong>cannot be undone</strong>.
        </S.ModalContent>
      )}
    </Modal>
  );
};

const MainActionButton = (props: IActionButton): JSX.Element => (
  <Button type="button" onClick={props.onClick}>
    {props.title}
  </Button>
);

const SecondaryActionButton = (props: IActionButton): JSX.Element => (
  <Button type="button" buttonStyle="text" onClick={props.onClick}>
    {props.title}
  </Button>
);

interface IDeleteModal extends IModal {
  arePagesTranslated: boolean;
  deleteAllVersions: boolean;
  setDeleteAllVersions: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IActionButton {
  onClick: () => void;
  title: string;
}

export { DeleteModal, MainActionButton, SecondaryActionButton };
