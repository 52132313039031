import React from "react";
import { createPortal } from "react-dom";
import { connect } from "react-redux";

import { appActions } from "@ax/containers/App";
import { IRootState } from "@ax/types";
import { Notification } from "@ax/components";

const blockingErrors: any[] = [];

const ErrorView = (props: any) => {
  return (
    <>
      <h3>Oops! An error occured</h3>
      <h4>Error Type: {props.code}</h4>
      <p>{props.text}</p>
    </>
  );
};

const ErrorGuard = (props: IProps) => {
  const { text, code, resetError, btnText, actionsBelow, subErrors, btnAction } = props;

  const isBlocking = blockingErrors.includes(code);

  const createErrorNotification = () => {
    const errorElements = document.querySelectorAll("#error");
    const lastErrorElement = errorElements.length - 1;
    const domNode = errorElements[lastErrorElement] || document.getElementById("default-error");

    return (
      domNode &&
      createPortal(
        <Notification
          resetError={resetError}
          text={text}
          type="error"
          btnText={btnText}
          actionsBelow={actionsBelow}
          subErrors={subErrors}
          onClick={btnAction}
        />,
        domNode
      )
    );
  };

  return code || text ? isBlocking ? <ErrorView code={code} text={text} /> : createErrorNotification() : null;
};

const mapStateToProps = (state: IRootState) => {
  const {
    app: {
      error: { code, text, btnText, actionsBelow, subErrors, btnAction },
    },
  } = state;
  return {
    code,
    text,
    btnText,
    actionsBelow,
    subErrors,
    btnAction,
  };
};

const mapDispatchToProps = {
  resetError: appActions.resetError,
};

interface IProps {
  code: any;
  text: any;
  resetError: () => void;
  btnText?: string;
  actionsBelow?: boolean | undefined;
  subErrors?: any[];
  btnAction?: () => void;
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorGuard);
