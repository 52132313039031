import React, { useEffect, useState } from "react";

import { findByEditorID } from "@ax/forms";
import { copyTextToClipboard } from "@ax/helpers";
import { useOnMessageReceivedFromIframe, useToast } from "@ax/hooks";

import Icon from "../Icon";
import Tooltip from "../Tooltip";
import Toast from "../Toast";
import BrowserContent from "../BrowserContent";

import * as S from "./style";

const Browser = (props: IBrowserProps): JSX.Element => {
  const {
    url,
    content,
    header,
    footer,
    isPage,
    theme,
    socials,
    cloudinaryName,
    siteLangs,
    disabled,
    siteID,
    isPreview,
    showIframe,
    browserRef,
    actions,
  } = props;

  const { id, entity } = content;
  const domain = window.location.origin;
  const urlPreview = `${domain}/editor/page-preview?preview=${!!isPreview}&disabled=${!!disabled}`;

  const [resolution, setResolution] = useState("desktop");
  const { isVisible, toggleToast, setIsVisible } = useToast();

  useOnMessageReceivedFromIframe(actions);

  useEffect(() => {
    localStorage.setItem("selectedID", "0");
    (window as any).browserRef = null;
  }, []);

  const selectEditorID = (
    selectedComponent: { editorID: number; component: any; type: string; parentEditorID: number },
    parentComponent: string | undefined | null,
    e: React.SyntheticEvent
  ) => {
    const { element } = findByEditorID(content, selectedComponent.parentEditorID);
    element && e.stopPropagation();

    const { editorID } = selectedComponent;

    const isNavigationModule = ["header", "footer"].includes(selectedComponent.type);

    if (!isPreview && (!disabled || isNavigationModule)) {
      actions?.setSelectedContentAction(editorID);
    }
  };

  const getWidth = (res: string) => {
    switch (res) {
      case "tablet":
        return "768px";
      case "phone":
        return "425px";
      default:
        return "100%";
    }
  };

  const copyUrl = () => {
    const sharedUrl = `${domain}/page-preview/${id}/${entity}`;
    copyTextToClipboard(sharedUrl).then(
      function () {
        toggleToast();
      },
      function (err) {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const toastProps = {
    setIsVisible,
    message: "URL Copied",
  };

  const deleteModuleSelected = (editorID: number) => {
    actions?.setSelectedContentAction(0);
    actions?.deleteModuleAction([editorID]);
  };

  const duplicateModuleSelected = (editorID: number) => {
    const duplicatedEditorID = actions?.duplicateModuleAction([editorID]);
    actions?.setSelectedContentAction(duplicatedEditorID);
  };

  const moduleActions = {
    deleteModuleAction: deleteModuleSelected,
    duplicateModuleAction: duplicateModuleSelected,
  };

  return (
    <S.BrowserWrapper data-testid="browser-wrapper" ref={browserRef}>
      <S.NavBar>
        <S.NavUrl>{url}</S.NavUrl>
        {isPreview && (
          <S.NavActions data-testid="nav-actions-wrapper">
            <S.IconWrapper data-testid="icon-wrapper-browser" onClick={copyUrl}>
              <Tooltip content="Copy url to share draft" bottom>
                <Icon name="share" size="24" />
              </Tooltip>
            </S.IconWrapper>
            <S.IconWrapper active={resolution === "desktop"} onClick={() => setResolution("desktop")}>
              <Tooltip content="Desktop" bottom>
                <Icon name="desktop" size="24" />
              </Tooltip>
            </S.IconWrapper>
            <S.IconWrapper
              data-testid="icon-res-tablet"
              active={resolution === "tablet"}
              onClick={() => setResolution("tablet")}
            >
              <Tooltip content="Tablet" bottom>
                <Icon name="tablet" size="24" />
              </Tooltip>
            </S.IconWrapper>
            <S.IconWrapper
              data-testid="icon-res-phone"
              active={resolution === "phone"}
              onClick={() => setResolution("phone")}
            >
              <Tooltip content="Mobile" bottom>
                <Icon name="phone" size="24" />
              </Tooltip>
            </S.IconWrapper>
          </S.NavActions>
        )}
      </S.NavBar>
      {showIframe ? (
        <S.FrameWrapper data-testid="navbar-iframe-wrapper">
          <iframe title="Preview" width={getWidth(resolution)} height="100%" src={urlPreview} loading="lazy" />
        </S.FrameWrapper>
      ) : (
        <S.Wrapper
          data-testid="browser-content-wrapper"
          ref={(ref: any) => ((window as any).browserRef = ref)}
          className="browser-content"
        >
          <BrowserContent
            cloudinaryName={cloudinaryName}
            theme={theme}
            socials={socials}
            siteLangs={siteLangs}
            selectEditorID={selectEditorID}
            siteID={siteID}
            isPage={isPage}
            content={content}
            header={header}
            footer={footer}
            languageID={content.language}
            pageLanguages={content.pageLanguages}
            moduleActions={moduleActions}
            renderer="editor"
          />
        </S.Wrapper>
      )}
      {isVisible && <Toast {...toastProps} />}
    </S.BrowserWrapper>
  );
};

export interface IBrowserProps {
  content: any;
  header?: any;
  footer?: any;
  url: string;
  isPage: boolean;
  theme: string;
  socials: any;
  cloudinaryName: string | null;
  siteLangs: any[];
  disabled?: boolean;
  siteID?: number;
  isPreview?: boolean;
  showIframe?: boolean;
  browserRef?: React.RefObject<HTMLDivElement>;
  actions?: {
    setSelectedContentAction: any;
    deleteModuleAction(editorID: number[]): void;
    duplicateModuleAction(editorID: number[]): number;
    setScrollEditorIDAction(editorID: number | null): void;
  };
}

export default Browser;
