import React from "react";
import { ICheck } from "@ax/types";
import Icon from "@ax/components/Icon";

import * as S from "./style";

const CheckField = (props: ICheckFieldProps): JSX.Element => {
  const {
    name,
    title,
    value,
    checked,
    error,
    disabled,
    indeterminate,
    onChange,
    icon,
    className,
    inversed = false,
    light = false,
    setHoverCheck,
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    onChange && onChange({ value, isChecked });
  };

  const handleMouseOver = () => setHoverCheck && setHoverCheck(true);

  const handleMouseLeave = () => setHoverCheck && setHoverCheck(false);

  return (
    <S.Wrapper className={className}>
      <S.Label
        data-testid="check-field-label"
        disabled={disabled}
        inversed={inversed}
        hasText={!!title}
        onMouseOver={handleMouseOver}
        onFocus={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        {icon ? (
          <S.IconLabelWrapper data-testid="check-field-icon-label">
            <S.IconWrapper>
              <Icon name={icon} />
            </S.IconWrapper>
            {title}
          </S.IconLabelWrapper>
        ) : (
          title
        )}
        <S.Input
          data-testid="check-field-input"
          type="checkbox"
          name={`${name}`}
          value={value || ""}
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
          aria-label={`${name}`}
        />
        <S.CheckMark
          data-testid="check-field-check-mark"
          checked={checked}
          error={error}
          indeterminate={indeterminate}
          inversed={inversed}
          className={light ? "light" : ""}
        />
      </S.Label>
    </S.Wrapper>
  );
};

export interface ICheckFieldProps {
  name: string | number;
  title?: string;
  value: string | number;
  checked?: boolean;
  error?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  onChange?: (value: ICheck) => void;
  icon?: string;
  inversed?: boolean;
  className?: string;
  light?: boolean;
  setHoverCheck?: (state: boolean) => void;
}

export default CheckField;
