import React from "react";

import { ActionMenu } from "@ax/components";

import * as S from "./style";

const ArrayContainerButtons = (props: any) => {
  const { options, icon } = props;
  return (
    <S.ButtonsWrapper>
      <ActionMenu options={options} icon={icon} />
    </S.ButtonsWrapper>
  );
};

const ContainerButtons = (props: any) => {
  const { options, icon } = props;
  return (
    <S.HiddenButtonsWrapper>
      <ActionMenu options={options} icon={icon} />
    </S.HiddenButtonsWrapper>
  );
};

export { ArrayContainerButtons, ContainerButtons };
