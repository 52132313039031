import { useState } from "react";
import { IQueryValue, IRolesQueryValues } from "@ax/types";

const useSortedListStatus = (): any => {
  const sortedInitialState: {
    isAscending: boolean;
    sortedByTitle: boolean;
    sortedByUsers: boolean;
    sortedByPermissions: boolean;
  } = {
    isAscending: false,
    sortedByTitle: false,
    sortedByUsers: false,
    sortedByPermissions: false,
  };

  const [sortedListStatus, setSortedListStatus] = useState(sortedInitialState);

  return {
    sortedListStatus,
    setSortedListStatus,
  };
};

const useFilterQuery = (): IRolesFilterQuery => {
  const initialQueryValues: Record<string, IQueryValue[]> = {
    order: [],
  };

  const [query, setQuery] = useState(initialQueryValues);
  const [currentFilterQuery, setCurrentFilterQuery] = useState("");

  const setFilterQuery = (filterValues: IRolesQueryValues) => {
    const { order } = filterValues;
    let filterQuery = "";

    const currentQuery = (pointer: string, values: IQueryValue[]): string => {
      const stringValues = Array.isArray(values) ? values.map((value) => value.value).join(",") : "";

      return !stringValues.length
        ? filterQuery
        : filterQuery.length
        ? filterQuery.concat(`&${pointer}=${stringValues}`)
        : `?${pointer}=${stringValues}`;
    };

    const isNotInitialValue = (pointer: keyof IRolesQueryValues) => {
      return filterValues[pointer] && initialQueryValues[pointer] !== filterValues[pointer];
    };

    if (isNotInitialValue("order")) filterQuery = currentQuery("order", order);

    setCurrentFilterQuery(filterQuery);
  };

  const setFiltersSelection = (pointer: string, filter: IQueryValue[], isAscendent?: boolean) => {
    const { order } = query;
    const orderMethod = isAscendent ? "asc" : "desc";
    const filterValues = {
      order: pointer === "order" ? [{ value: `${filter[0].value}-${orderMethod}`, label: filter[0].label }] : order,
    };

    setQuery(filterValues);
    setFilterQuery(filterValues);
  };

  const resetFilterQuery = () => {
    setQuery(initialQueryValues);
    setCurrentFilterQuery("");
  }

  return {
    setFiltersSelection,
    resetFilterQuery,
    filterValues: query,
    filterQuery: currentFilterQuery,
  };
};

interface IRolesFilterQuery {
  setFiltersSelection(pointer: string, filter: IQueryValue[], isAscendent?: boolean): void;
  resetFilterQuery(): void;
  filterValues: Record<string, IQueryValue[]>;
  filterQuery: string;
}

export { useSortedListStatus, useFilterQuery };
