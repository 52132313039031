import styled from "styled-components";

const Wrapper = styled.div`
  padding: ${(p) => p.theme.spacing.m};
`;

const Pack = styled.div`
  padding-bottom: ${(p) => p.theme.spacing.xs};
  width: calc(${(p) => p.theme.spacing.l} * 15);
`;

const Heading = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  color: ${p => p.theme.color.textMediumEmphasis};
  padding-bottom: ${(p) => p.theme.spacing.xs};
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.headingM};
  padding-bottom: ${(p) => p.theme.spacing.m};
`;

const Description = styled.div`
  padding: ${(p) => p.theme.spacing.s};
  background-color: ${(p) => p.theme.colors.uiBackground03};
  border-radius: 4px;
  width: 100%;
`;

const Items = styled.div`
  padding-bottom: ${(p) => p.theme.spacing.m};
`;

const CardList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 225px);
  grid-gap: ${(p) => p.theme.spacing.s};
  padding-top: ${(p) => p.theme.spacing.m};
`;

const Card = styled.div`
  padding: 0 ${(p) => p.theme.spacing.s};
  background-color: white;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.color.uiLine};
`;

const CardTitle = styled.div`
  ${p => p.theme.textStyle.headingXXS};
  color: ${p => p.theme.color.textMediumEmphasis};
  padding-top: ${(p) => p.theme.spacing.s};
  padding-bottom: ${(p) => p.theme.spacing.xxs};
`;

const CardSubtitle = styled.div`
  padding-bottom: ${(p) => p.theme.spacing.s};
`;

const Thumbnail = styled.div<{backgroundUrl: string}>`
  background: url(${p => p.backgroundUrl}) no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 140px;
  margin-bottom: ${(p) => p.theme.spacing.s};
  width: 193px;
`;


export { Wrapper, Heading, Title, Description, Pack, Items, CardList, Card, CardTitle, CardSubtitle, Thumbnail }
