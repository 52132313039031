import styled from "styled-components";

const Wrapper = styled.section`
  width: 720px;
  margin: ${(p) => `${p.theme.spacing.m} 0 0 ${p.theme.spacing.m}`};
  padding-bottom: calc(${(p) => p.theme.spacing.xl} * 2);
`;

const NameTitle = styled.div`
  ${(p) => p.theme.textStyle.headingM};
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const SubTitle = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};
`;

const SettingsWrapper = styled.div`
  position: relative;
  border-top: 1px solid ${(p) => p.theme.color.uiLine};
  padding-top: ${(p) => p.theme.spacing.m};
  padding-bottom: ${(p) => p.theme.spacing.s};
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  & button {
    margin-left: auto;
  }
`;

const Heading = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  color: ${(p) => p.theme.color.textHighEmphasis};
  padding-bottom: ${(p) => p.theme.spacing.xs};
`;

const SettingContent = styled.div`
  padding-bottom: ${(p) => p.theme.spacing.s};
`;

const SettingText = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  width: calc(${(p) => p.theme.spacing.l} * 12);
`;

const SearchFieldWrapper = styled.div`
  padding-top: ${(p) => p.theme.spacing.xs};
  padding-bottom: ${(p) => p.theme.spacing.xs};
  max-width: calc(${(p) => p.theme.spacing.l} * 7);
`;

const SelectAllSitesFieldWrapper = styled.div``;

const GlobalPermissionsFieldWrapper = styled.div`
  padding-bottom: ${(p) => p.theme.spacing.m};
`;

const SiteList = styled.div`
  padding-bottom: ${(p) => p.theme.spacing.xs};
  & > div {
    margin-bottom: 0;
    border-radius: 0;
    border-bottom: none;

    &:first-child {
      border-radius: ${(p) => `${p.theme.radii.s} ${p.theme.radii.s} 0 0`};
    }
    &:last-child {
      border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
      border-radius: ${(p) => `0 0 ${p.theme.radii.s} ${p.theme.radii.s}`};
    }
  }
`;

const RoleList = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
  padding-top: ${(p) => p.theme.spacing.xs};

  & button {
    margin-top: ${(p) => p.theme.spacing.s};
    margin-bottom: ${(p) => p.theme.spacing.s};
  }
`;

const MemberWrapper = styled.div``;

const OptionsWrapper = styled.div`
  padding-bottom: ${(p) => p.theme.spacing.m};
`;

const Label = styled.div`
  ${(p) => p.theme.textStyle.fieldLabel};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  display: block;
  margin-bottom: ${(p) => p.theme.spacing?.xs};
`;

const HelpText = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color?.textMediumEmphasis};
`;

export {
  Wrapper,
  NameTitle,
  SubTitle,
  ModalContent,
  SettingsWrapper,
  SettingText,
  SettingContent,
  Heading,
  SearchFieldWrapper,
  SelectAllSitesFieldWrapper,
  SiteList,
  GlobalPermissionsFieldWrapper,
  RoleList,
  HeadingWrapper,
  Label,
  HelpText,
  OptionsWrapper,
  MemberWrapper,
};
