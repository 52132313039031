import React from "react";
import NavConnectedField from "./NavConnectedField";
import PageConnectedField from "./PageConnectedField";

const ConnectedField = (props: any): JSX.Element => {
  const { isPage } = props;
  if (isPage) return <PageConnectedField {...props} />;
  else return <NavConnectedField {...props} />;
};

export default ConnectedField;
