import React from "react";
import { connect } from "react-redux";

import { ITemplate } from "@ax/types";
import { appActions } from "@ax/containers/App";
import { pageEditorActions } from "@ax/containers/PageEditor";
import { getTemplateThumbnails } from "@ax/helpers";

import * as S from "./style";

const TemplateConfig = (props: IProps): React.ReactElement => {
  const { setHistoryPush, templates, getTemplatePage, currentSite } = props;

  const goToEditor = async (template: ITemplate) => {
    await getTemplatePage(template.id);
    setHistoryPush("/sites/settings/content-types/editor", true);
  };

  return (
    <>
      <S.Heading>Template configuration</S.Heading>
      <S.Text>
        {
          "You can set custom settings in these templates and will be shown by default. However, they can be modified on the page's editor."
        }
      </S.Text>
      <S.TemplatesWrapper>
        {templates &&
          templates.map((template: ITemplate, key: number) => {
            const thumbnails = getTemplateThumbnails(template.id, currentSite.theme);
            return (
              <S.TemplateCard key={`${key}${template.title}`} onClick={() => goToEditor(template)}>
                {template.thumbnails && <S.TemplateThumbnail backgroundUrl={thumbnails && thumbnails["2x"]} />}
                <S.TemplateCardTitle data-testid="template-card">{template.title}</S.TemplateCardTitle>
              </S.TemplateCard>
            );
          })}
      </S.TemplatesWrapper>
    </>
  );
};

interface IProps {
  currentSite: any;
  templates: ITemplate[];
  setHistoryPush(path: string, isEditor?: boolean): void;
  getTemplatePage: (template: string) => Promise<void>;
}

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
  getTemplatePage: pageEditorActions.getTemplatePage,
};

export default connect(null, mapDispatchToProps)(TemplateConfig);
