import React from "react";

const SvgClose = props => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      d="M18.646 6.764a.5.5 0 000-.708l-.702-.702a.5.5 0 00-.708 0l-4.882 4.882a.5.5 0 01-.708 0L6.764 5.354a.5.5 0 00-.708 0l-.702.702a.5.5 0 000 .708l4.882 4.882a.5.5 0 010 .708l-4.882 4.882a.5.5 0 000 .708l.702.702a.5.5 0 00.708 0l4.882-4.882a.5.5 0 01.708 0l4.882 4.882a.5.5 0 00.708 0l.702-.702a.5.5 0 000-.708l-4.882-4.882a.5.5 0 010-.708l4.882-4.882z"
      fill="#5057FF"
    />
  </svg>
);

export default SvgClose;
