import styled from "styled-components";

export const FieldGroup = styled.div`
  width: 100%;
  max-width: calc(${p => p.theme.spacing.xl} * 5);
  overflow: auto;
  & > div {
    margin-bottom: ${p => p.theme.spacing.xxs}
  }
`;
