import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { createPortal } from "react-dom";
import { Button, IconAction } from "@ax/components";

import * as S from "./style";

const Toast = (props: IToastProps): JSX.Element => {
  const { action, message, setIsVisible } = props;
  const toast = useRef<any>(null);

  const { pathname } = useLocation();
  const isEditor = pathname.includes("/editor");

  let temp: any;
  const setTemp = (time: number) => (temp = setTimeout(() => setIsVisible(false), time));

  const close = () => {
    toast.current.classList.add("close-animation");
    setTemp(1000);
  };

  useEffect(() => {
    setTemp(5000);
    return () => clearTimeout(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return createPortal(
    <S.Wrapper ref={toast} isEditor={isEditor} data-testid="toast-wrapper">
      <S.Text data-testid="toast-message">{message}</S.Text>
      <S.Buttons>
        {action && (
          <Button type="button" buttonStyle="lineInverse" onClick={action}>
            Undo
          </Button>
        )}
        <IconAction icon="close" onClick={close} inversed={true} />
      </S.Buttons>
    </S.Wrapper>,
    document.body
  );
};

export interface IToastProps {
  action?: () => void;
  message: string;
  setIsVisible: (visibility: boolean) => void;
}

export default Toast;
