import React from "react";

import * as S from "./style";

const ProgressBar = (props: IProgressBarProps): JSX.Element => {
  const { percentage, inverse = false } = props;

  const percentageLimits = (min: number, value: number, max: number) => {
    return Math.min(Math.max(min, value), max);
  };

  return (
    <S.Tracker data-testid="progressbar-tracker">
      <S.ProgressInTracker percentage={percentageLimits(0, percentage, 100)} inverse={inverse} />
    </S.Tracker>
  );
};

export interface IProgressBarProps {
  percentage: number;
  inverse?: boolean;
}

export default ProgressBar;
