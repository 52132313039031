import StructuredDataList from "./../modules/StructuredData/StructuredDataList";
import CategoriesList from "./../modules/Categories/CategoriesList";
import Sites from "./../modules/Sites";
import StructuredData from "./../modules/StructuredData";
import Users from "./../modules/Users";
import Profile from "../modules/Users/Profile";
import UserCreate from "../modules/Users/UserCreate";
import UserEdit from "../modules/Users/UserEdit";
import Editor from "./../modules/GlobalEditor";
import GlobalSettings from "./../modules/GlobalSettings";
import AnalyticsSettings from "../modules/Analytics";
import FramePreview from "../modules/FramePreview";
import FileDrive from "../modules/FileDrive";

export default [
  {
    path: "/sites",
    component: Sites,
    name: "Sites",
    showInNav: true,
    icon: "Project",
    permission: "",
  },
  {
    path: "/data",
    component: StructuredDataList,
    name: "Global Content",
    showInNav: true,
    icon: "Data",
    permission: "global.globalData.accessToGlobalData",
  },
  {
    path: "/data/:id/editor",
    component: StructuredData,
    showInNav: false,
    name: "New StructuredData",
    permission: "global.globalData.readGlobalData",
  },
  {
    path: "/categories",
    component: CategoriesList,
    name: "Categories",
    showInNav: true,
    icon: "Category",
    permission: "global.globalData.accessToTaxonomies",
  },
  {
    path: "/files",
    component: FileDrive,
    name: "File Drive Manager",
    showInNav: true,
    icon: "Photo-library",
    permission: "global.mediaGallery.accessToGlobalFileDrive",
  },
  {
    path: "/users",
    component: Users,
    name: "Users",
    showInNav: true,
    icon: "Users",
    permission: "global.usersRoles.accessToUsersRoles",
  },
  { path: "/profile", component: Profile, name: "Profile", showInNav: false, icon: "User" },
  {
    path: "/roles",
    component: Users,
    name: "Roles",
    showInNav: false,
    permission: "global.usersRoles.accessToUsersRoles",
  },
  {
    path: "/users/new",
    component: UserCreate,
    name: "New User",
    showInNav: false,
    permission: "global.usersRoles.createUsers",
  },
  {
    path: "/users/edit",
    component: UserEdit,
    name: "New User",
    showInNav: false,
    permission: "global.usersRoles.editUsers",
  },
  {
    path: `/data/pages/editor`,
    component: Editor,
    name: "Page Editor",
    showInNav: false,
    hideNav: true,
    permission: "global.globalData.readGlobalData",
  },
  {
    path: `/data/pages/editor/new`,
    component: Editor,
    name: "New Page Editor",
    showInNav: false,
    hideNav: true,
    permission: "global.globalData.createAllGlobalData",
  },
  {
    path: `/settings`,
    component: null,
    name: "Settings",
    showInNav: true,
    icon: "Settings",
    permission: "global.seoAnalytics.editSeoAnalyticsInGlobalPages",
    routesGroups: [
      {
        name: "Settings",
        routes: [
          {
            path: `/settings/robots`,
            component: GlobalSettings,
            name: "SEO tools",
            permission: "global.seoAnalytics.editSeoAnalyticsInGlobalPages",
          },
          {
            path: `/settings/analytics`,
            component: AnalyticsSettings,
            name: "Analytics tools",
            permission: "global.seoAnalytics.editSeoAnalyticsInGlobalPages",
          },
        ],
      },
    ],
  },
  {
    path: `/settings/redirects`,
    component: GlobalSettings,
    name: "Settings",
    showInNav: false,
    permission: "global.seoAnalytics.editSeoAnalyticsInGlobalPages",
  },
  {
    path: `/editor/page-preview`,
    component: FramePreview,
    name: "Preview",
    showInNav: false,
    hideNav: true,
  },
];
