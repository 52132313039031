import type {
	ImageCropType,
	ImageDecoding,
	ImageFormats,
	ImageLoading,
	ImagePosition,
	ImageTransform,
	ResponsiveImageProps,
} from "../types/core";

import * as React from "react";

import { SiteContext } from "../contexts";
import {
	removeUnit,
	rearrangeResponsiveArray,
	getGriddoDamURIWithParams,
	getDomainFromDamUrl,
	getDamIdFromDamUrl,
} from "../functions/image-utils";

const MIME_TYPES = {
	jpg: "image/jpeg",
	jpeg: "image/jpeg",
	gif: "image/gif",
	svg: "image/svg+xml",
	png: "image/png",
	avif: "image/avif",
	webp: "image/webp",
};

const internalDefaults: ImageConfig = {
	quality: 75,
	crop: "cover",
	loading: "lazy",
	decoding: "auto",
	blurSize: "8px",
	blurCSSTransition: "filter 0s ease-in-out",
	formats: ["webp"],
};

/**
 * `useGriddoImage()`
 */
function useGriddoImage({
	url,
	...props
}: UseGriddoImageProps): UseGriddoImageReturn {
	if (!url) {
		return {};
	}

	const siteContext = React.useContext(SiteContext);
	const griddoDamDefaults = siteContext?.griddoDamDefaults || {};

	const domain = getDomainFromDamUrl(url);
	const damId = getDamIdFromDamUrl(url);

	// This object contains every aspect of the image configuration
	const imageConfig: ImageConfig = {
		// defaults props from griddo-core
		...internalDefaults,
		// defaults props from instance through site context
		...griddoDamDefaults,
		// props from component
		...props,
		format: "jpeg",
		domain,
	};

	// Build a one element responsive prop if is not provided
	if (imageConfig.responsive === undefined) {
		imageConfig.responsive = [
			{
				width: imageConfig.width,
				height: imageConfig.height,
				quality: imageConfig.quality,
				crop: imageConfig.crop,
				position: imageConfig.position,
				transforms: imageConfig.transforms,
			},
		];
	}

	// Create the content of the srcSet <img> attribute
	const srcSet = imageConfig.responsive.map(
		({ width, height, quality, crop, position, transforms }) => {
			const griddoDamURL = getGriddoDamURIWithParams({
				// damid
				damId: damId,
				// image config
				imageConfig: imageConfig,
				// extra props comming from <GriddoImage> props
				quality,
				crop,
				width,
				height,
				position,
				transforms,
			});

			// empty string for the "w" value if not width
			const withWProp: string = width ? `${removeUnit(width)}w` : "";
			return `${griddoDamURL} ${withWProp}`.trim();
		},
	);

	// Create the content of the sizes <img> attribute.
	// rearrangeResponsiveArray put the element of the array with the
	// breakpoint === null at the last position to use properly in the mediaquery
	// interpolation.
	const sizes = rearrangeResponsiveArray(imageConfig.responsive.reverse())
		.map((query, idx) => {
			if (imageConfig.responsive && idx < imageConfig.responsive.length - 1) {
				return `(min-width: ${query.breakpoint}) ${query.width}`;
			}
			return `${query.width}`;
		})
		.join(", ");

	// Create a kind of srcSet for background images (removing the final size xxxw)
	const srcSetURL = srcSet.map((entry) => entry?.split(" ")[0]);

	// Get the src from the first (usually the small) breakpoint
	const smallForSrc = imageConfig.responsive[0];

	// Old browsers src fallback (in case it doesn't support srcSet)
	const src = `${getGriddoDamURIWithParams({
		damId,
		imageConfig,
		...smallForSrc,
	})}`;

	// MIMEType
	const type = imageConfig?.format
		? MIME_TYPES[imageConfig?.format]
		: MIME_TYPES.jpeg;

	return {
		type,
		srcSet,
		srcSetURL,
		src,
		sizes,
		webpFallback: generateImageChunk({ srcSet, srcSetURL, format: "jpeg" }),
		jpeg: generateImageChunk({ srcSet, srcSetURL, format: "jpeg" }),
		webp: generateImageChunk({ srcSet, srcSetURL, format: "webp" }),
		avif: generateImageChunk({ srcSet, srcSetURL, format: "avif" }),
		png: generateImageChunk({ srcSet, srcSetURL, format: "png" }),
		gif: generateImageChunk({ srcSet, srcSetURL, format: "gif" }),
		svg: generateImageChunk({ srcSet, srcSetURL, format: "svg" }),
	};
}

function generateImageChunk({
	srcSet,
	srcSetURL,
	format,
}: GenerateImageChunkProps): ImageChunk {
	return {
		type: MIME_TYPES[format] as MIMETypes,
		srcSet: srcSet?.map((url) => url?.replace(/f\/\w+/, `f/${format}`)),
		srcSetURL: srcSetURL?.map((url) => url?.replace(/f\/\w+/, `f/${format}`)),
	};
}

export interface GenerateImageChunkProps {
	srcSet?: Array<string>;
	srcSetURL?: Array<string>;
	format: ImageFormats;
}

export interface UseGriddoImageProps extends Omit<ImageConfig, "formats"> {
	url?: string;
}

export interface ImageConfig {
	blurCSSTransition?: string;
	blurSize?: string;
	crop?: ImageCropType;
	decoding?: ImageDecoding;
	domain?: string;
	format?: ImageFormats;
	formats?: Array<ImageFormats>;
	height?: string;
	loading?: ImageLoading;
	position?: ImagePosition;
	quality?: number;
	responsive?: Array<ResponsiveImageProps>;
	transforms?: ImageTransform;
	width?: string;
	sizes?: string;
}

export type MIMETypes =
	| "image/avif"
	| "image/gif"
	| "image/jpeg"
	| "image/png"
	| "image/svg+xml"
	| "image/webp";

export interface ImageChunk {
	type: MIMETypes;
	srcSet?: Array<string>;
	srcSetURL?: Array<string>;
}

export interface UseGriddoImageReturn {
	type?: string;
	srcSet?: Array<string>;
	srcSetURL?: Array<string>;
	src?: string;
	sizes?: string;
	webpFallback?: ImageChunk;
	jpg?: ImageChunk;
	jpeg?: ImageChunk;
	webp?: ImageChunk;
	avif?: ImageChunk;
	png?: ImageChunk;
	gif?: ImageChunk;
	svg?: ImageChunk;
}

export { useGriddoImage };
