import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;
  width: 100%;
  padding: ${(p) => p.theme.spacing.m};
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${(p) => p.theme.spacing.m};
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const Description = styled.div`
  ${(p) => p.theme.textStyle.uiM};
`;

export { Wrapper, ContentWrapper, Table, Title, Description };
