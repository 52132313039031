import * as React from "react";
const SvgCodeHead = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#5057FF"
      fillRule="evenodd"
      d="M2.9 5.175 4.375 3.7c.2-.2.3-.433.3-.7 0-.267-.1-.5-.3-.7-.2-.2-.438-.3-.712-.3-.275 0-.513.1-.713.3L.775 4.475c-.1.1-.17.208-.213.325a1.107 1.107 0 0 0-.062.375c0 .133.02.258.063.375a.877.877 0 0 0 .212.325L2.95 8.05c.2.2.438.3.712.3.276 0 .513-.1.713-.3.2-.2.3-.433.3-.7 0-.267-.1-.5-.3-.7L2.9 5.175Zm7.35 0L8.775 6.65c-.2.2-.3.433-.3.7 0 .267.1.5.3.7.2.2.438.3.713.3.274 0 .512-.1.712-.3l2.175-2.175c.1-.1.17-.208.213-.325.041-.117.062-.242.062-.375s-.02-.258-.063-.375a.878.878 0 0 0-.212-.325L10.2 2.3a.999.999 0 0 0-1.425 0c-.2.2-.3.433-.3.7 0 .267.1.5.3.7l1.475 1.475ZM5.5 11a1 1 0 1 1 2 0v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1h-6a1 1 0 1 1 0-2h7c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v14c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 21.5 22h-14c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 5.5 20v-9Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCodeHead;
