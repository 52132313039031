import { analytics } from "@ax/api";
import { appActions } from "@ax/containers/App";
import { handleRequest } from "@ax/helpers";
import { IAnalytics } from "@ax/types";
import { SET_ANALYTICS } from "./constants";
import { ISetAnalytics } from "./interfaces";

function setAnalytics(analytics: IAnalytics): ISetAnalytics {
  return { type: SET_ANALYTICS, payload: analytics };
}

function getAnalytics(siteId: number | string = "global"): (dispatch: any) => Promise<void> {
  return async (dispatch) => {
    try {
      const callback = async () => analytics.getAnalytics(siteId);

      const responseActions = {
        handleSuccess: (response: any) => {
          dispatch(setAnalytics(response));
        },
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };

      await handleRequest(callback, responseActions, [])(dispatch);
    } catch (e) {
      console.log(e);
    }
  };
}

function updateAnalytics(
  analyticsState: IAnalytics,
  siteId: number | string = "global"
): (dispatch: any) => Promise<boolean> {
  return async (dispatch) => {
    try {
      const { scriptCode, dimensions, groups } = analyticsState;
      const callback = async () => {
        const promises = [];
        promises.push(analytics.updateScriptCode(siteId, { scriptCode }));
        promises.push(analytics.createDimensions(siteId, { dimensions }));
        promises.push(analytics.createDimensionsGroups(siteId, { groups }));
        return Promise.all(promises);
      };

      const responseActions = {
        handleSuccess: () => {
          dispatch(getAnalytics());
        },
        handleError: (response: any) => {
          const {
            data: { message },
          } = response;
          const isMultiple = Array.isArray(message) && message.length > 1;
          const msg = isMultiple ? `The action failed due to ${message.length} errors.` : undefined;
          appActions.handleError(response, isMultiple, msg)(dispatch);
        },
      };

      return await handleRequest(callback, responseActions, [appActions.setIsSaving])(dispatch);
    } catch (e) {
      console.log(e);
      return false;
    }
  };
}

function updateScriptCode(scriptCode: string, siteId: number): (dispatch: any) => Promise<boolean> {
  return async (dispatch) => {
    try {
      const callback = async () => {
        return analytics.updateScriptCode(siteId, { scriptCode });
      };

      const responseActions = {
        handleSuccess: () => {
          dispatch(getAnalytics(siteId));
        },
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };

      return await handleRequest(callback, responseActions, [appActions.setIsSaving])(dispatch);
    } catch (e) {
      console.log(e);
      return false;
    }
  };
}

export { setAnalytics, getAnalytics, updateAnalytics, updateScriptCode };
