import React from "react";
import { BulkSelectionOptions } from "@ax/components";
import { IQueryValue } from "@ax/types";
import TableHeader from "./TableHeader";

const BulkHeader = (props: IProps): JSX.Element => {
  const {
    showBulk,
    checkState,
    selectItems,
    selectAllItems,
    totalItems,
    isScrolling,
    bulkActions,
    filterItems,
    filterValues,
    setHoverCheck,
  } = props;

  return showBulk ? (
    <BulkSelectionOptions
      checkState={checkState}
      actions={bulkActions}
      selectItems={selectItems}
      totalItems={totalItems}
    />
  ) : (
    <TableHeader
      totalItems={totalItems}
      selectAllItems={selectAllItems}
      isScrolling={isScrolling}
      filterItems={filterItems}
      filterValues={filterValues}
      setHoverCheck={setHoverCheck}
      checkState={checkState}
    />
  );
};

interface IProps {
  showBulk: boolean;
  checkState: Record<string, boolean>;
  selectItems: () => void;
  selectAllItems: () => void;
  totalItems: number;
  isScrolling: boolean;
  bulkActions: { icon: string; text: string; action: () => void }[];
  filterValues: Record<string, IQueryValue[]>;
  filterItems: (filterPointer: string, filtersSelected: IQueryValue[]) => void;
  setHoverCheck: (state: boolean) => void;
}

export default BulkHeader;
