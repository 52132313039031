import React from "react";
import styled from "styled-components";
import { ActionMenu, CheckField } from "@ax/components";

const ActionsWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
`;

const IconWrapper = styled.div`
  width: 32px;
`;

const StyledActionMenu = styled((props) => <ActionMenu {...props} />)`
  width: 32px;
`;

const TypeContainer = styled.div`
  position: relative;
  width: 100%;
  padding: ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.radii.s};
  box-shadow: ${(p) => p.theme.shadow.shadowS};
  background: ${(p) => p.theme.color.uiBackground02};
  text-transform: capitalize;
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const TypeWrapper = styled.div`
  position: relative;
`;

const TypeLabel = styled.div`
  display: block;
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const TypeName = styled.div`
  ${(p) => p.theme.textStyle.fieldContent};
  color: ${(p) => p.theme.color.textHighEmphasis};
`;

const FiltersWrapper = styled.div<{ isActive: boolean; isEmpty: boolean }>`
  margin-top: ${(p) => p.theme.spacing.xs};
  border: ${(p) => (p.isActive ? `1px solid ${p.theme.color.uiLine}` : "none")};
  border-radius: ${(p) => p.theme.radii.s};
  padding: ${(p) =>
    p.isEmpty
      ? `0 ${p.theme.spacing.xs} 0 ${p.theme.spacing.xs}`
      : `${p.theme.spacing.xs} ${p.theme.spacing.xs} 0 ${p.theme.spacing.xs}`};
`;

const ActionsFilterWrapper = styled.div<{ isOpen: boolean }>`
  display: ${(p) => (p.isOpen ? `block` : `none`)};
  margin-top: ${(p) => p.theme.spacing.s};
  padding-top: ${(p) => p.theme.spacing.m};
  border-top: 1px solid ${(p) => p.theme.color.uiLine};
`;

const ChecksWrapper = styled.div`
  max-height: ${(p) => `calc(${p.theme.spacing.l} * 4)`};
  overflow: auto;
`;

const OptionsBlock = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  display: flex;
  align-items: baseline;
`;

const SelectWrapper = styled.span`
  margin-left: ${(p) => p.theme.spacing.xs};
`;

const SearchWrapper = styled.div`
  margin-top: ${(p) => `-${p.theme.spacing.s}`};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const ClearWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.xs};
`;

const StyledCheckField = styled((props) => <CheckField {...props} />)`
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const GroupWrapper = styled.div`
  padding-left: ${(p) => p.theme.spacing.s};
  padding-bottom: ${(p) => p.theme.spacing.xxs};
`;

const GroupTitle = styled.div`
  ${(p) => p.theme.textStyle.fieldContent};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  font-style: italic;
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

export {
  IconWrapper,
  TypeContainer,
  TypeWrapper,
  TypeLabel,
  TypeName,
  ActionsFilterWrapper,
  FiltersWrapper,
  ChecksWrapper,
  OptionsBlock,
  SelectWrapper,
  StyledActionMenu,
  ActionsWrapper,
  SearchWrapper,
  ClearWrapper,
  StyledCheckField,
  GroupTitle,
  GroupWrapper,
};
