import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

export const Content = styled.div`
  transition: all 0.5s ease-in-out;
  margin-top: ${(p) => p.theme.spacing.m};
  div:last-child {
    margin-bottom: 0;
  }
`;
