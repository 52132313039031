import styled from "styled-components";

const Wrapper = styled.div`
  height: calc(100vh - ${(p) => p.theme.spacing.xl});
`;

const SubNav = styled.div`
  position: relative;
  width: calc(${(p) => p.theme.spacing.s} * 17);
  padding: ${(p) => p.theme.spacing.m};
  background: ${(p) => p.theme.color.uiBackground02};
  border-right: ${(p) => `1px solid ${p.theme.color.uiLine}`};
  height: 100%;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 100%;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${(p) => p.theme.color.iconNonActive};
  }
`;

export { Wrapper, SubNav };
