import React, { memo, useEffect, useRef } from "react";

import * as S from "./style";

const SliderField = (props: ITextFieldProps): JSX.Element => {
  const { value, min = 0, max = 100, defaultValue = min, step, prefix, suffix, onChange, disabled } = props;
  const bubbleRef = useRef<HTMLOutputElement>(null);

  const val = typeof value === "undefined" || value === null ? defaultValue : value;

  useEffect(() => {
    onChange(Number(val));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bubbleRef.current) {
      bubbleRef.current.style.left = setBubbleLeft();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bubbleRef, value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onChange(Number(newValue));
  };

  const setBubbleLeft = () => {
    const half = Number(((val - min) * 100) / (max - min));
    return `calc(${half}% + (${8 - half * 0.16}px))`;
  };

  return (
    <S.Slider data-testid="slider-component">
      <S.Bubble data-testid="bubble-component" ref={bubbleRef} disabled={disabled}>
        {prefix && `${prefix} `}
        {val}
        {suffix && ` ${suffix}`}
      </S.Bubble>
      <S.Input
        data-testid="input-slider-component"
        value={val}
        min={min}
        max={max}
        step={step}
        onChange={handleChange}
        disabled={disabled}
      />
    </S.Slider>
  );
};

export interface ITextFieldProps {
  title: string;
  value: number | undefined | null;
  defaultValue?: number;
  min: number;
  max: number;
  step: number;
  prefix?: string;
  suffix?: string;
  helptext?: string;
  onChange: (value: number) => void;
  disabled?: boolean;
}

export default memo(SliderField);
