import { useState, useEffect, useRef, memo } from "react";
import isEqual from "lodash.isequal";

import { deepClone } from "@ax/helpers";
import { cleanPageValues, getIsSavedData } from "@ax/forms";
import { IUser } from "@ax/types";

const useDebounce = (value: any) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Set debouncedValue to value (passed in) after the specified delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

const useEqualStructured = (component: any) => {
  return memo(component, (prevProps: any, newProps: any) => {
    const { fieldKey } = prevProps;
    const prevValue = prevProps.form.content && prevProps.form.content[fieldKey];
    const newValue = newProps.form.content[fieldKey];

    return prevValue === newValue;
  });
};

const usePrevious = (value: any, isSaved?: boolean): Record<string, unknown> | undefined => {
  const stringValue = value && JSON.stringify(value);
  const ref = useRef();
  const isSavedData = getIsSavedData();

  useEffect(() => {
    if (!ref.current) {
      ref.current = stringValue && JSON.parse(stringValue);
    }
  }, [stringValue]);

  useEffect(() => {
    if (isSavedData || isSaved) {
      ref.current = stringValue && JSON.parse(stringValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSavedData, isSaved]);

  return ref.current;
};

const useIsDirty = (
  updatedValues: any,
  isNew?: boolean
): { isDirty: boolean; setIsDirty(state: boolean): void; resetDirty: any } => {
  const [isDirty, setIsDirty] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isResetting, setIsResetting] = useState(false);

  const updatedValuesStr = JSON.stringify(updatedValues);

  const prevContent = usePrevious(updatedValues, isSaved);

  const hasChanged = (): boolean => {
    if (prevContent && updatedValuesStr) {
      const updatedValuesCloned = updatedValuesStr && deepClone(updatedValuesStr);
      const originalValuesCloned = prevContent && deepClone(prevContent);

      const { cleanUpdatedValues, cleanOriginalValues } = cleanPageValues(updatedValuesCloned, originalValuesCloned);

      const hasChanged = !isEqual(cleanUpdatedValues, cleanOriginalValues);

      return hasChanged;
    }
    return false;
  };

  const resetDirty = (reseting = true) => {
    setIsDirty(false);
    setIsSaved(true);
    reseting && setIsResetting(true);
  };

  useEffect(() => {
    if (isResetting) {
      setIsResetting(false);
      return;
    }

    if (isNew) {
      setIsDirty(false);
    } else if (prevContent && updatedValuesStr) {
      const isUpdated = hasChanged();

      if (isUpdated) {
        setIsDirty(true);
        setIsSaved(false);
      } else {
        setIsDirty(false);
      }
    }
    // eslint-disable-next-line
  }, [updatedValuesStr]);

  return { isDirty, setIsDirty, resetDirty };
};

const cleanModified = (updatedValues: any, originalValues: any) => {
  delete updatedValues["modified"];

  delete originalValues["modified"];

  return {
    cleanUpdatedValues: updatedValues,
    cleanOriginalValues: originalValues,
  };
};

const useShouldBeSaved = (form: Record<string, unknown> | IUser) => {
  const [isDirty, setIsDirty] = useState(false);
  const formRef = useRef();

  const stringValue = form && JSON.stringify(form);

  useEffect(() => {
    if (!formRef.current) {
      formRef.current = stringValue && JSON.parse(stringValue);
    }
    if (form !== null) {
      const { cleanUpdatedValues, cleanOriginalValues } = cleanModified(formRef.current, form);
      const isFormDirty =
        cleanOriginalValues.id && !cleanUpdatedValues.id ? false : !isEqual(cleanUpdatedValues, cleanOriginalValues);
      setIsDirty(isFormDirty);
      formRef.current = stringValue && JSON.parse(stringValue);
    } else {
      setIsDirty(false);
    }
  }, [form]);

  return { isDirty, setIsDirty };
};

export { useDebounce, useEqualStructured, usePrevious, useIsDirty, useShouldBeSaved };
