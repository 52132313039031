import styled from "styled-components";

const TagStatus = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  background-color: #ffffff;
  border: 1px solid ${(p) => p.theme.color.uiLine};
  box-sizing: border-box;
  border-radius: ${(p) => p.theme.spacing.s};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  display: inline-block;
  padding: 3px 8px;
  text-transform: capitalize;
`;

const TagSquare = styled.div<{ color?: string; textColor?: string }>`
  ${(p) => p.theme.textStyle.uiXS};
  display: flex;
  background-color: ${(p) => (p.color ? p.color : p.theme.colors.uiBackground01)};
  box-sizing: border-box;
  border-radius: ${(p) => p.theme.radii.xs};
  color: ${(p) =>
    p.textColor ? p.textColor : p.color ? p.theme.color.textMediumEmphasisInverse : p.theme.color.textMediumEmphasis};
  white-space: nowrap;
  padding: 3px 8px;
  text-transform: capitalize;
`;

const Bullet = styled.span<{ color?: string }>`
  background-color: ${(p) => (p.color ? p.color : `#4096D6`)};
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 4px;
`;

const TagFixed = styled.div<{ color?: string; rounded: boolean }>`
  ${(p) => p.theme.textStyle.uiS};
  background-color: ${(p) => (p.color ? p.color : p.theme.color.interactive02)};
  border-radius: ${(p) => (p.rounded ? p.theme.spacing.s : "0")};
  box-sizing: border-box;
  color: ${(p) => p.theme.color.textMediumEmphasis};
  display: inline-block;
  padding: 3px 8px;
  margin-right: ${(p) => p.theme.spacing.xxs};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const Title = styled.div``;

const IconWrapper = styled.div`
  height: 16px;
  margin-left: ${(p) => p.theme.spacing.xxs};
  cursor: pointer;
  svg {
    path {
      fill: ${(p) => p.theme.color.textMediumEmphasis};
    }
  }
`;

const IconTag = styled.div`
  height: 16px;
  margin-right: ${(p) => p.theme.spacing.xs};
  svg {
    path {
      fill: ${(p) => p.theme.color.textMediumEmphasis};
    }
  }
`;

const TagText = styled.div`
  display: flex;
  align-items: center;
`;

export { TagStatus, TagSquare, Bullet, TagFixed, Title, IconWrapper, IconTag, TagText };
