import styled, { keyframes } from "styled-components";

const PrevAnimation = keyframes`
  to {
    opacity: 0;
  }
}`;

const Wrapper = styled.div`
  position: relative;
  bottom: 0;

  .prev-slide-anim {
    opacity: 1;
    animation: ${PrevAnimation} 0.8s ease-out;
    animation-fill-mode: forwards;
  }
`;

const BannerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  padding-left: 100px;
`;

const Title = styled.div`
  color: ${(p) => p.theme.color.textHighEmphasisInverse};
  font-family: Source Sans Pro;
  font-size: 36px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: left;
  padding-top: 140px;
  width: 450px;
`;

const Image = styled.div<{ image: string }>`
  background: no-repeat top left url(${(p) => p.image});
  background-size: 130%;
  width: 100%;
  height: 100%;
  margin-top: ${(p) => p.theme.spacing.l};
`;

const Buttons = styled.div`
  display: flex;
  position: absolute;
  left: 100px;
  top: 64px;
  z-index: 10;
`;

const Arrow = styled.div<{ direction: string }>`
  background: ${(p) => `no-repeat url("/img/slider/${p.direction}.svg")`};
  width: 40px;
  height: 40px;
  cursor: pointer;
  &:last-child {
    margin-left: ${(p) => p.theme.spacing.s};
  }
  &:hover {
    background: ${(p) => `no-repeat url("/img/slider/${p.direction}-on.svg")`};
  }
`;

export { Wrapper, BannerWrapper, Title, Image, Buttons, Arrow };
