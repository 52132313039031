import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Tooltip = styled.div`
  position: absolute;
  z-index: 1;
  display: none;
  padding: ${(p) => p.theme.spacing.s};
  top: -50%;
  transform: translateX(-50%);
  box-shadow: ${(p) => p.theme.shadow.shadowL};
  background-color: ${(p) => p.theme.colors.uiBarBackground};
  border-radius: ${(p) => p.theme.radii.s};
`;

const Element = styled.div<{ color?: string; rounded: boolean; size: "S" | "M" }>`
  position: relative;
  ${(p) => (p.size === "M" ? p.theme.textStyle.uiS : p.theme.textStyle.uiXS)};
  ${(p) => p.theme.colors.textMediumEmphasis};
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xs};
  margin: calc(${(p) => p.theme.spacing.xxs} / 2);
  background-color: ${(p) => (p.color ? p.color : p.theme.colors.uiBackground01)};
  border-radius: ${(p) => (p.rounded ? "34px" : p.theme.radii.xs)};
  white-space: nowrap;

  &:hover > ${Tooltip} {
    display: block;
  }
`;

const Row = styled.div`
  display: flex;
`;

export { Wrapper, Element, Tooltip, Row };
