import React from "react";
import { Loader } from "@ax/components";

import * as S from "./style";

const Loading = (): JSX.Element => {
  return (
    <S.Wrapper data-testid="loading-wrapper">
      <S.LoadingWrapper>
        <Loader name="circle" />
      </S.LoadingWrapper>
    </S.Wrapper>
  );
};

export default Loading;
