import React from "react";

import { ISchema, ISchemaTab, ISchemaField } from "@ax/types";
import { Tabs } from "@ax/components";
import { usePermission } from "@ax/hooks";

import ConnectedField from "./ConnectedField";

import * as S from "./style";

export const Form = (props: IFormProps): JSX.Element => {
  const {
    schema,
    selectedTab,
    setSelectedTab,
    actions,
    isPage,
    isGlobal,
    theme,
    setHistoryPush,
    header,
    footer,
    isEditLive,
    headerHeight,
  } = props;

  const isAllowedToEditSitePages = usePermission("content.editContentPages");
  const isAllowedToEditGlobalData = usePermission("global.globalData.editAllGlobalData");
  const isAllowedToEditConfigPages = usePermission("content.editConfigPages");
  const isAllowedToEditSiteSeo = usePermission("seoAnalytics.editSeoAnalyticsPages");
  const isAllowedToEditGlobalSeo = usePermission("global.seoAnalytics.editSeoAnalyticsInGlobalPages");

  const isAllowedToEditPageContent = (!isGlobal && isAllowedToEditSitePages) || (isGlobal && isAllowedToEditGlobalData);
  const isAllowedToEditPageConfig =
    (!isGlobal && isAllowedToEditConfigPages) || (isGlobal && isAllowedToEditGlobalData);
  const isAllowedToEditPageSEO = (!isGlobal && isAllowedToEditSiteSeo) || (isGlobal && isAllowedToEditGlobalSeo);

  const tabContent = schema.configTabs.find((tab: ISchemaTab) => tab.title === selectedTab);
  const setTab = (tab: string) => setSelectedTab(tab);

  const generateFields = (field: ISchemaField) => {
    const { setSelectedContent } = props;
    const { key, whiteList } = field;

    const goTo = (editorID: number) => setSelectedContent(editorID);

    return (
      <ConnectedField
        selectedTab={selectedTab}
        whiteList={whiteList}
        key={key}
        objKey={key}
        field={field}
        goTo={goTo}
        actions={actions}
        componentType={schema.schemaType}
        isPage={isPage}
        isGlobal={isGlobal}
        theme={theme}
        setHistoryPush={setHistoryPush}
        isReadOnly={isEditLive}
      />
    );
  };

  const getTabs = () => {
    let mappedTabs;
    const isHeader = schema.type === "header";
    const isPageSchema = schema.schemaType === "page";
    if (isHeader && isPage) {
      mappedTabs = schema.configTabs
        .map((tab: ISchemaTab) => {
          const hasModifiableFields = tab.fields.some((field: any) => field.modifiable);
          return hasModifiableFields && tab.title;
        })
        .filter((value: string | boolean) => !!value);
    } else {
      mappedTabs = schema.configTabs.reduce((acc: string[], curr: ISchemaTab) => {
        if (
          !isPageSchema ||
          (isPageSchema &&
            ((curr.title === "content" && isAllowedToEditPageContent) ||
              (curr.title === "config" && isAllowedToEditPageConfig) ||
              (curr.title === "SEO & Analytics" && isAllowedToEditPageSEO)))
        ) {
          return [...acc, curr.title];
        }
        return acc;
      }, []);
    }

    return mappedTabs;
  };

  const tabs = getTabs();

  const handleRestoreHeader = () =>
    actions.restorePageNavigationAction && actions.restorePageNavigationAction("header");

  const handleRestoreFooter = () =>
    actions.restorePageNavigationAction && actions.restorePageNavigationAction("footer");

  return (
    <S.Wrapper data-testid="form-section">
      <S.TabsWrapper headerHeight={headerHeight}>
        <Tabs tabs={tabs} active={selectedTab} setSelectedTab={setTab} />
      </S.TabsWrapper>
      {selectedTab === "content" && isEditLive && (
        <S.FieldWrapper>
          There are some changes in the <strong>draft associated</strong> with this page. Before making changes to the
          published version, you should <strong>discard or publish your draft</strong>.
        </S.FieldWrapper>
      )}
      {selectedTab === "content" && !isGlobal && header === 0 && (
        <S.FieldWrapper>
          This page doesn&apos;t have a header. Click{" "}
          <S.Link data-testid="header-link" onClick={handleRestoreHeader}>
            here
          </S.Link>{" "}
          to restore it.
        </S.FieldWrapper>
      )}
      {selectedTab === "content" && !isGlobal && footer === 0 && (
        <S.FieldWrapper>
          This page doesn&apos;t have a footer. Click{" "}
          <S.Link data-testid="footer-link" onClick={handleRestoreFooter}>
            here
          </S.Link>{" "}
          to restore it.
        </S.FieldWrapper>
      )}
      {tabContent && tabContent.fields.map((field: ISchemaField) => generateFields(field))}
    </S.Wrapper>
  );
};

export interface IFormProps {
  selectedTab: string;
  schema: ISchema;
  actions: any;
  setSelectedTab(tab: string): void;
  setSelectedContent(editorID: number): void;
  isPage: boolean;
  isGlobal?: boolean;
  theme: string;
  setHistoryPush?: (path: string, isEditor: boolean) => void;
  header?: number | null;
  footer?: number | null;
  isEditLive?: boolean;
  headerHeight: number;
}

export default Form;
