import React from "react";
import { CheckField } from "@ax/components";

const UniqueCheck = (props: IProps) => {
  const { name, value, options, onChange, disabled, inversed = false, className } = props;
  const uniqueOption = options[0];

  const handleChange = (changeEvt: ICheckEvent) => {
    const { isChecked } = changeEvt;
    onChange(isChecked);
  };

  return (
    <CheckField
      name={name}
      checked={!!value && value}
      title={uniqueOption.title}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      inversed={inversed}
      className={className}
    />
  );
};

interface ICheckEvent {
  value: string;
  isChecked: boolean;
}

interface IProps {
  name: string;
  value: any;
  options: ICheck[];
  onChange: any;
  disabled?: boolean;
  inversed?: boolean;
  className?: string;
}

interface ICheck {
  name: string;
  value: any;
  title: string;
}

export default UniqueCheck;
