import React from "react";
import Button from "../Button";

import * as S from "./style";

const SearchTagsBar = (props: ISearchTagsBarProps): JSX.Element => {
  const { query, setQuery } = props;

  const tags = query.split(" ").filter((tag: string) => tag.trim().length > 0);

  const deleteTag = (position: number) => {
    const newTags = tags.filter((tag: string, index: number) => position !== index).join(" ");
    setQuery(newTags);
  };

  const handleDeleteAll = () => setQuery("");

  return tags.length > 0 ? (
    <S.Wrapper>
      <S.Text>Search result for</S.Text>
      <S.TagList>
        {tags.map((tag: string, index: number) => {
          const handleDeleteTag = () => deleteTag(index);
          return <S.StyledTag key={index} text={tag} color="#FFFFFF" onDeleteAction={handleDeleteTag} />;
        })}
      </S.TagList>
      <S.ButtonWrapper>
        <Button type="button" buttonStyle="minimal" icon="close" onClick={handleDeleteAll}>
          Clear All
        </Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  ) : (
    <></>
  );
};

export interface ISearchTagsBarProps {
  query: string;
  setQuery: (query: string) => void;
}

export default SearchTagsBar;
