import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const Content = styled.div`
  position: relative;
  display: flex;

  & > div {
    margin-right: ${(p) => p.theme.spacing.m};

    &:last-child,
    &:first-child {
      margin-right: 0;
    }
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: -2px;
  right: 10px;
  width: ${(p) => p.theme.spacing.s};
  height: ${(p) => p.theme.spacing.s};
  z-index: 99;
`;

export { Wrapper, Content, IconWrapper };
