import { schemas } from "components";

const getTemplateOptions = (): ITemplateOption[] => {
  const { templates } = schemas;
  const templatesOptionsValues: ITemplateOption[] = [];

  Object.keys(templates).forEach((schema: string) => {
    const currSchema = templates[schema];
    const { component, displayName } = currSchema;

    !!currSchema.type &&
      ["list", "detail"].includes(currSchema.type.mode) &&
      templatesOptionsValues.push({
        name: component,
        title: displayName,
        value: component,
      });
  });

  return templatesOptionsValues;
};

interface ITemplateOption {
  name: string;
  title: string;
  value: string;
}

export { getTemplateOptions };
