import React from "react";
import { Button, Icon } from "@ax/components";

import * as S from "./style";

const ActionMenuItem = (item: any, color?: boolean) => {
  const { icon, action, label } = item;
  return (
    <S.ActionItem data-testid="action-menu-item" className={color ? "" : "grey"} key={icon} onClick={action}>
      <Icon name={icon} />
      <S.ActionText>{label}</S.ActionText>
    </S.ActionItem>
  );
};

const ActionMenuBtn = (props: any) => {
  const { menu } = props;
  return (
    menu &&
    menu.button && (
      <S.ButtonWrapper>
        <Button type="button" buttonStyle="line" onClick={menu.button.action} disabled={menu.button.disabled}>
          {menu.button.label}
        </Button>
        {menu.button.helpText && <S.HelpText>{menu.button.helpText}</S.HelpText>}
      </S.ButtonWrapper>
    )
  );
};

const ActionMenu = (props: any) => {
  const { menu } = props;
  return (
    <S.ActionMenu>
      <S.ActionMenuTitle> More actions </S.ActionMenuTitle>
      <ActionMenuBtn menu={menu} />
      {menu && menu.options.map((item: any, i: number) => item && ActionMenuItem(item))}
    </S.ActionMenu>
  );
};

const DownArrowButton = (props: any) => (
  <S.IconWrapper inversed={props.inversed} data-testid="down-arrow-button-wrapper">
    <Icon name="DownArrow" />
  </S.IconWrapper>
);

const ActionSimpleMenu = (props: any) => {
  const { menu } = props;
  return (
    menu &&
    menu.options.length > 0 && (
      <S.ActionMenu data-testid="action-simple-menu">
        {menu.options.map((item: any, i: number) => ActionMenuItem(item, true))}
      </S.ActionMenu>
    )
  );
};

export { ActionMenu, DownArrowButton, ActionSimpleMenu };
