import React from "react";

import { Button, FloatingMenu, IconAction } from "@ax/components";

import * as S from "./style";

const InformativeMenu = (props: IInformativeMenuProps): JSX.Element => {
  const { icon, message, actionText, iconSize, position } = props;

  const InfoButton = () => <IconAction icon={icon} size={iconSize} />;

  const handleClick = (event: any) => event.preventDefault();

  return (
    <FloatingMenu Button={InfoButton} reactiveToHover={true} position={position}>
      <S.InformativeMenu>
        {message}
        <S.Footer>
          <Button type="button" buttonStyle="text" onClick={handleClick}>
            {actionText}
          </Button>
        </S.Footer>
      </S.InformativeMenu>
    </FloatingMenu>
  );
};

export interface IInformativeMenuProps {
  icon: string;
  message: string;
  actionText: string;
  iconSize?: "s" | "m";
  position?: string;
}
export default InformativeMenu;
