import React from "react";
import styled from "styled-components";

import { Cell } from "@ax/components/TableList/TableItem/style";

const CategoryCell = styled((props) => <Cell {...props} />)`
  flex: 0 0 215px;
  align-items: center;
  position: relative;
`;

export { CategoryCell };
