import styled, { keyframes } from "styled-components";
import LoginSlider from "./LoginSlider";

const Wrapper = styled.section`
  position: relative;
  display: flex;
  height: 100vh;
  background: ${(p) => p.theme.color.uiBackground04};
  flex-direction: row;
`;

const LoginAnimation = keyframes`
  to {
    width: 100%;
  }
}`;

const OpacityAnimation = keyframes`
  to {
    opacity: 0;
  }
}`;

const LeftWrapper = styled.div`
  position: relative;
  display: flex;
  width: 45%;
  background: ${(p) => p.theme.color.uiBackground04};

  &.animate {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    animation: ${LoginAnimation} 1.2s ease-in;
    animation-fill-mode: forwards;
  }
`;

const LoginWrapper = styled.div<{ width: number }>`
  width: ${(p) => (p.width > 0 ? `${p.width}px` : "100%")};
  display: flex;
  align-items: center;
  justify-content: center;

  &.animate {
    opacity: 1;
    margin-right: auto;
    animation: ${OpacityAnimation} 1.2s ease-in;
    animation-fill-mode: forwards;
  }
`;

const RightWrapper = styled.div`
  background-color: #001b3c;
  position: relative;
  width: 55%;
  height: 100%;
  color: ${(p) => p.theme.color.textHighEmphasisInverse};
  margin-left: auto;
  z-index: 1;
  overflow: hidden;
`;

const AnimatedLoginSlider = styled(LoginSlider)`
  &.animate {
    opacity: 1;
    animation: ${OpacityAnimation} 1.2s ease-in;
    animation-fill-mode: forwards;
  }
`;

const Secuoyas = styled.div<{ width: number }>`
  ${(p) => p.theme.textStyle.uiXS};
  position: absolute;
  display: flex;
  color: ${(p) => p.theme.color.textMediumEmphasisInverse};
  width: ${(p) => (p.width > 0 ? `${p.width}px` : "100%")};
  justify-content: center;
  align-items: center;
  left: 0;
  bottom: ${(p) => p.theme.spacing.m};
  padding-bottom: ${(p) => p.theme.spacing.xs};
  img {
    width: 20px;
    height: 20px;
    margin-left: ${(p) => p.theme.spacing.xs};
  }
`;

export { Wrapper, LeftWrapper, RightWrapper, LoginWrapper, AnimatedLoginSlider, Secuoyas };
