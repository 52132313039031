import React, { useState, useRef } from "react";
import { Tag } from "@ax/components";

import * as S from "./style";

const TagsField = (props: IProps): JSX.Element => {
  const { value, onChange, disabled } = props;
  const valueArray = value && Array.isArray(value) ? value : [];
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const _handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const _handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const newTag = inputValue.trim();
      if (newTag !== "" && !valueArray.includes(newTag)) {
        onChange([...valueArray, inputValue]);
        setInputValue("");
      }
    }
  };

  const _handleClick = () => {
    inputRef.current?.focus();
  };

  const deleteTag = (index: number) => {
    const newValue = [...valueArray];
    newValue.splice(index, 1);
    onChange(newValue);
  };

  const placeholder = valueArray.length > 0 ? "" : "Type a tag...";

  return (
    <S.Wrapper data-testid="tag-field-wrapper" onClick={_handleClick}>
      {valueArray &&
        valueArray.map((tag: string, index: number) => {
          const handleDelete = () => deleteTag(index);
          return <Tag key={tag} text={tag} onDeleteAction={disabled ? undefined : handleDelete} />;
        })}
      <S.Input
        data-testid="tag-field-input"
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={_handleChange}
        placeholder={placeholder}
        onKeyDown={_handleKeyDown}
        disabled={disabled}
      />
    </S.Wrapper>
  );
};

interface IProps {
  value: string[];
  placeholder?: string;
  onChange: (value: string[]) => void;
  disabled?: boolean;
}

export default TagsField;
