import * as React from "react";

function SvgHide(props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="#5057FF"
        d="M3 4l1.414-1.414 16.97 16.97-1.413 1.415zM18.868 14.211A9.88 9.88 0 0020.82 11.5 9.77 9.77 0 0012 6a10 10 0 00-1.263.08L9.033 4.376A11.818 11.818 0 0112 4c5 0 9.27 3.11 11 7.5a11.875 11.875 0 01-2.728 4.115l-1.404-1.404z"
      />
      <path
        d="M5.174 6.174A11.888 11.888 0 001 11.5C2.73 15.89 7 19 12 19c1.758 0 3.427-.385 4.926-1.074l-1.524-1.524A9.881 9.881 0 0112 17a9.76 9.76 0 01-8.82-5.5 9.81 9.81 0 013.424-3.896l-1.43-1.43z"
        fill="#5057FF"
      />
      <path d="M8.159 9.159A4.471 4.471 0 007.5 11.5c0 2.48 2.02 4.5 4.5 4.5a4.47 4.47 0 002.341-.659l-1.49-1.49A2.5 2.5 0 019.65 10.65l-1.49-1.49z" fill="#5057FF" />
    </svg>
  );
}

export default SvgHide;
