import { domains } from "@ax/api";
import { appActions } from "@ax/containers/App";
import { handleRequest } from "@ax/helpers";
import { IDomainRobot } from "@ax/types";
import { SET_DOMAINS_ROBOTS } from "./constants";
import { ISetDomainsRobots } from "./interfaces";

function setDomainsRobots(robots: IDomainRobot[]): ISetDomainsRobots {
  return { type: SET_DOMAINS_ROBOTS, payload: { robots } };
}

function getDomainsRobots(): (dispatch: any) => Promise<void> {
  return async (dispatch) => {
    try {
      const callback = async () => domains.getDomainsRobots();

      const responseActions = {
        handleSuccess: (response: any) => {
          dispatch(setDomainsRobots(response));
        },
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };

      await handleRequest(callback, responseActions, [appActions.setIsLoading])(dispatch);
    } catch (e) {
      console.log(e);
    }
  };
}

function updateDomainsRobots(robots: IDomainRobot[]): (dispatch: any) => Promise<boolean> {
  return async (dispatch) => {
    try {
      const callback = async () => domains.updateDomainsRobots(robots);

      const responseActions = {
        handleSuccess: () => {
          dispatch(getDomainsRobots());
        },
        handleError: (response: any) => {
          const {
            data: { message },
          } = response;
          const isMultiple = Array.isArray(message) && message.length > 1;
          const msg = isMultiple ? `The action failed due to ${message.length} errors.` : undefined;
          appActions.handleError(response, isMultiple, msg)(dispatch);
        },
      };

      return await handleRequest(callback, responseActions, [appActions.setIsSaving])(dispatch);
    } catch (e) {
      console.log(e);
      return false;
    }
  };
}

export { getDomainsRobots, updateDomainsRobots };
