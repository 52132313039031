import React from "react";
import { CheckField, Icon, IconAction } from "@ax/components";
import { IBulkAction } from "@ax/types";

import * as S from "./style";

const BulkHeader = (props: IProps): JSX.Element => {
  const { checkState, selectItems, totalItems, actions, toggleBulk } = props;

  const handleClick = () => toggleBulk();

  return (
    <S.Wrapper>
      <S.CloseWrapper onClick={handleClick}>
        <Icon name="close" size="16" />
      </S.CloseWrapper>
      <S.SelectAllCheckField>
        <CheckField
          key="selectAll"
          name="selectAll"
          value="selectAll"
          onChange={selectItems}
          checked={checkState.isAllSelected}
          indeterminate={checkState.indeterminate}
          disabled={false}
          error={false}
        />
      </S.SelectAllCheckField>
      <S.Counter>{totalItems} selected</S.Counter>
      <S.BulkActions>
        {actions.map((item: IBulkAction) => (
          <IconAction key={item.text} icon={item.icon || ""} onClick={item.action} size="s" />
        ))}
      </S.BulkActions>
    </S.Wrapper>
  );
};

interface IProps {
  checkState: any;
  selectItems: () => void;
  totalItems: number;
  toggleBulk: () => void;
  actions: IBulkAction[];
}

export default BulkHeader;
