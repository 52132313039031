import React, { useEffect } from "react";
import { ElementsTooltip } from "@ax/components";
import * as S from "./style";

const CategoryCell = (props: ICategoryCellProps): JSX.Element => {
  const { categories, categoryColors, addCategoryColors } = props;

  useEffect(() => {
    addCategoryColors(categories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  return (
    <S.CategoryCell>
      <ElementsTooltip elements={categories} colors={categoryColors} maxChar={30} rounded={true} />
    </S.CategoryCell>
  );
};

export interface ICategoryCellProps {
  categories: string[];
  categoryColors: any;
  addCategoryColors(cats: string[]): void;
}

export default CategoryCell;
