import styled from "styled-components";

const Wrapper = styled.div`
  padding: ${(p) => p.theme.spacing.m};
  height: 100%;
`;

const Item = styled.div`
  background-color: ${(p) => p.theme.color.uiBarBackground};
  padding: ${(p) => p.theme.spacing.s};
  margin-bottom: ${(p) => p.theme.spacing.m};
  border: 1px solid ${(p) => p.theme.color.uiLine};
  box-sizing: border-box;
  border-radius: ${(p) => p.theme.radii.s};
`;

const ItemHeader = styled.div`
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.color.textLowEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const ItemContent = styled.div`
  ${(p) => p.theme.textStyle.fieldContent};
  display: flex;
  color: ${(p) => p.theme.color.textHighEmphasis};
`;

const Footer = styled.div`
  position: absolute;
  bottom: ${(p) => p.theme.spacing.m};
  right: ${(p) => p.theme.spacing.m};
`;

export { Wrapper, Item, ItemHeader, ItemContent, Footer };
