import React, { memo } from "react";
import { IDataPack } from "@ax/types";
import { Button, Tag } from "@ax/components";
import Item from "./Item";

import * as S from "./style";

const DetailPanel = (props: IProps) => {
  const { pack, activatePacks } = props;

  const hasTags = pack.categories.length > 0 || pack.modules.length > 0 || pack.templates.length > 0;
  const hasModules = pack.modules.length > 0 || pack.templates.length > 0;

  return (
    <S.DetailPanelWrapper>
      <S.PackType>Site Data</S.PackType>
      <S.PackTitle>{pack.title} package</S.PackTitle>
      <S.PackDescription>{pack.description}</S.PackDescription>
      {hasTags && <S.SectionTitle>Includes</S.SectionTitle>}
      <S.TagsWrapper>
        {pack.categories.length > 0 && <Tag text="Categories" />}
        {pack.modules.length > 0 && <Tag text="Modules" />}
        {pack.templates.length > 0 && <Tag text="Templates" />}
      </S.TagsWrapper>
      {hasModules && <S.SectionTitle>Modules & Templates</S.SectionTitle>}
      <S.ModulesWrapper>
        {pack.modules && pack.modules.map((item: any) => <Item key={item.title} item={item} type="MODULE" />)}
        {pack.templates && pack.templates.map((item: any) => <Item key={item.title} item={item} type="TEMPLATE" />)}
      </S.ModulesWrapper>
      <S.ActionsWrapper>
        <Button type="button" buttonStyle="line" onClick={activatePacks}>
          Activate
        </Button>
      </S.ActionsWrapper>
    </S.DetailPanelWrapper>
  );
};

interface IProps {
  pack: IDataPack;
  activatePacks: () => void;
}

export default memo(DetailPanel);
