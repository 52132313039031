
import styled from "styled-components";

export const ModalContent = styled.div`
  padding: ${p => p.theme.spacing.m};
  p {
    margin-bottom: ${p => p.theme.spacing.m};
  }
  div {
    margin-bottom: 0;
  }
`;

export const Link = styled.div<{ active: boolean }>`
    color: ${p => p.active ? p.theme.color.textHighEmphasis : p.theme.color.textMediumEmphasis};
`;
