import React from "react";

import { RadioField } from "@ax/components";

import * as S from "./style";

const OptionItem = (props: IOptionItemProps): JSX.Element => {
  const { name, title, description, value, onChange, readOnly = false } = props;

  const handleClick = (e: React.MouseEvent) => {
    if (readOnly) return;
    e.preventDefault();
    e.stopPropagation();
    onChange(name);
  };

  const handleChange = () => onChange(name);

  return (
    <>
      <S.OptionItem role="rowgroup" data-testid="user-option-item" readOnly={readOnly} selected={!readOnly && value}>
        {!readOnly && (
          <S.RadioCell role="cell" onClick={handleClick} data-testid="radio-cell">
            <RadioField name={name} value={name} checked={value} onChange={handleChange} />
          </S.RadioCell>
        )}
        <S.WrapperCell role="cell" data-testid="wrapper-cell-option">
          <S.Name onClick={handleClick}>{title}</S.Name>
          <S.Description onClick={handleClick}>{description}</S.Description>
        </S.WrapperCell>
      </S.OptionItem>
    </>
  );
};

export interface IOptionItemProps {
  name: string;
  title: string;
  description: string;
  onChange: (value: string) => void;
  value: boolean;
  readOnly?: boolean;
}

export default OptionItem;
