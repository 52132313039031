import React from "react";
import { BulkSelectionOptions } from "@ax/components";
import TableHeader from "./TableHeader";
import { IQueryValue, IRole } from "@ax/types";

const BulkHeader = (props: IBulkHeaderProps): JSX.Element => {
  const {
    showBulk,
    checkState,
    activateRoles,
    selectItems,
    selectAllItems,
    totalItems,
    isScrolling,
    sortItems,
    sortedListStatus,
    filterValues,
    isSiteView,
    roles,
    selectedRoles,
    setHoverCheck,
  } = props;

  const deactivatedSelectedRoles = roles.filter((role: IRole) => selectedRoles.includes(role.id) && !role.active);

  const bulkAction = deactivatedSelectedRoles.length
    ? {
        text: "Activate",
        action: () => activateRoles(true),
      }
    : {
        text: "Deactivate",
        action: () => activateRoles(false),
      };

  return showBulk ? (
    <BulkSelectionOptions
      checkState={checkState}
      actions={[bulkAction]}
      selectItems={selectItems}
      totalItems={totalItems}
    />
  ) : (
    <TableHeader
      filterValues={filterValues}
      totalItems={totalItems}
      selectAllItems={selectAllItems}
      isScrolling={isScrolling}
      sortItems={sortItems}
      sortedListStatus={sortedListStatus}
      isSiteView={isSiteView}
      checkState={checkState}
      setHoverCheck={setHoverCheck}
    />
  );
};

export interface IBulkHeaderProps {
  showBulk: boolean;
  checkState: any;
  activateRoles: (active: boolean) => void;
  selectItems: () => void;
  selectAllItems: () => void;
  totalItems: number;
  isScrolling: boolean;
  sortItems: (orderPointer: IQueryValue[], isAscending: boolean) => void;
  sortedListStatus: any;
  filterValues: any;
  isSiteView: boolean;
  roles: IRole[];
  selectedRoles: number[];
  setHoverCheck: (state: boolean) => void;
}

export default BulkHeader;
