import { IRole, IUser } from "@ax/types";
import {
  SET_USERS,
  SET_USER_FORM,
  SET_CURRENT_USER,
  SET_ROLES,
  SET_CURRENT_PERMISSIONS,
  SET_GLOBAL_PERMISSIONS,
} from "./constants";

export interface IUsersState {
  users: IUser[];
  userForm: IUser;
  currentUser: IUser | null;
  currentPermissions: string[];
  globalPermissions: string[];
  roles: IRole[];
}

export const initialState = {
  users: [],
  userForm: {
    id: null,
    username: "",
    name: "",
    email: "",
    failed: 0,
    roles: [],
    isSuperAdmin: false,
  },
  currentUser: null,
  currentPermissions: [],
  globalPermissions: [],
  roles: [],
};

export function reducer(state = initialState, action: any): IUsersState {
  switch (action.type) {
    case SET_USERS:
    case SET_USER_FORM:
    case SET_CURRENT_USER:
    case SET_ROLES:
    case SET_CURRENT_PERMISSIONS:
    case SET_GLOBAL_PERMISSIONS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export { initialState as usersInitialState, reducer as usersReducer };
