import styled from "styled-components";

const AvatarWrapper = styled.div<{ size?: string }>`
  width: ${(p) => (p.size === "s" ? " 24px" : "32px")};
  height: ${(p) => (p.size === "s" ? " 24px" : "32px")};
`;

const Avatar = styled.span<{ image?: string | null; initials: string; size?: string }>`
  border-radius: 50%;
  width: ${(p) => (p.size === "s" ? " 24px" : "32px")};
  height: ${(p) => (p.size === "s" ? " 24px" : "32px")};
  background: ${(p) => `url(${p.image})`}, ${(p) => p.theme.colors.uiBackground03};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: ${(p) => !p.image && JSON.stringify(p.initials)};
    color: ${(p) => p.theme.colors.textMediumEmphasis};
    ${(p) => p.theme.textStyle.uiXS};
  }
`;

export { AvatarWrapper, Avatar };
