import React, { useRef, useEffect, useState } from "react";
import { Icon, IconAction } from "@ax/components";

import * as S from "./style";

const TextField = (props: ITextFieldProps): JSX.Element => {
  const {
    name,
    value,
    error,
    placeholder,
    autoComplete,
    readonly,
    icon,
    onClickIcon,
    className,
    onChange,
    onBlur,
    onKeyDown,
    inputType = "text",
    disabled,
    iconPosition = "out",
    prefix,
    handleValidation,
    validators,
    editorID,
    autoFocus,
    inversed = false,
  } = props;

  const [width, setWidth] = useState(0);
  const [state, setState] = useState<string>(value);
  const prefixRef = useRef<HTMLDivElement>(null);
  const timeOutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    const width = prefixRef.current ? prefixRef.current.offsetWidth : 0;
    setWidth(width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefixRef]);

  useEffect(() => {
    if (!autoComplete && state !== value) {
      timeOutRef.current && clearTimeout(timeOutRef.current);
      timeOutRef.current = setTimeout(() => {
        delayedChange(state);
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    !autoComplete && setState(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorID, value]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    !autoComplete ? setState(e.target.value) : delayedChange(e.target.value);
  };

  const delayedChange = (newValue: string) => {
    onChange && onChange(newValue);
    error && handleValidation && handleValidation(newValue, validators);
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onBlur && onBlur(newValue);
    handleValidation && handleValidation(newValue, validators);
  };

  const hasBackgroundIcon = !!icon && iconPosition === "in";
  const hasButton = !!icon && iconPosition === "out";

  const Prefix = () => (
    <S.Prefix data-testid="prefix" ref={prefixRef}>
      {prefix}
    </S.Prefix>
  );

  const inputValue = !autoComplete ? state : value || "";

  return (
    <S.FieldWrapper data-testid="text-field-container" error={error} className={className}>
      {prefix && <Prefix />}
      <S.Input
        data-testid="input-component"
        type={inputType}
        value={inputValue}
        name={name}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        autoComplete={autoComplete}
        error={error}
        readOnly={readonly}
        button={hasButton}
        disabled={disabled}
        icon={hasBackgroundIcon}
        hasPrefix={!!prefix}
        prefixWidth={width}
        aria-label={name}
        autoFocus={autoFocus}
      />
      {hasBackgroundIcon && (
        <S.BackgroundIcon data-testid="background-icon-component" onClick={onClickIcon} inversed={inversed}>
          <Icon name={icon || ""} size="24" />
        </S.BackgroundIcon>
      )}
      {hasButton && (
        <S.IconWrapper data-testid="icon-wrapper-component" readonly={readonly} error={error}>
          <IconAction icon={icon || ""} onClick={onClickIcon} disabled={disabled} size="s" />
        </S.IconWrapper>
      )}
    </S.FieldWrapper>
  );
};

interface ITextFieldProps {
  value: string;
  onChange: (value: string) => void;
  autoComplete?: string;
  inputType?: string;
  name?: string;
  error?: boolean;
  placeholder?: string;
  readonly?: boolean;
  icon?: string;
  onClickIcon?: () => void;
  className?: string;
  onBlur?: (value: string) => void;
  onKeyDown?: () => void;
  disabled?: boolean;
  iconPosition?: string;
  prefix?: string;
  handleValidation?: (value: string, validators?: Record<string, unknown>) => void;
  validators?: Record<string, unknown>;
  editorID?: number;
  autoFocus?: boolean;
  inversed?: boolean;
}

export default TextField;
