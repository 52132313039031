import React, { memo } from "react";

import { FieldsBehavior } from "@ax/components";
import { ILinkField, IUrlField } from "@ax/types";

const LinkField = (props: ILinkFieldProps): JSX.Element => {
  const { value, onChange, disabled, whiteList, goTo, theme, actions, selectedContent, objKey } = props;

  const handleTextChange = (newValue: string) => onChange({ ...value, text: newValue });
  const handleLinkTypeChange = (newValue: string) => onChange({ ...value, linkType: newValue });
  const handleUrlChange = (newValue: IUrlField) => onChange({ ...value, url: newValue });
  const handleModalChange = (newValue: any) => onChange({ ...value, modal: newValue });

  const modalFieldSchema = {
    title: "Modal",
    type: "ComponentContainer",
    hideable: true,
    mandatory: true,
    key: "modal",
    condition: "modal",
    whiteList,
  };

  const urlFieldSchema = {
    title: "URL",
    type: "UrlField",
    key: "url",
    advanced: true,
    condition: "url",
  };

  return (
    <>
      <FieldsBehavior
        title="Text"
        name="text"
        fieldType="TextField"
        value={value.text}
        onChange={handleTextChange}
        disabled={disabled}
      />
      <FieldsBehavior
        title="Type of link"
        name="linkType"
        fieldType="ConditionalField"
        value={value.linkType}
        onChange={handleLinkTypeChange}
        disabled={disabled}
        options={[
          {
            value: "url",
            title: "Link to Page",
            name: "url",
          },
          {
            value: "modal",
            title: "Open Modal",
            name: "modal",
          },
        ]}
        innerFields={[
          <FieldsBehavior
            key="modal"
            title="Modal"
            name="modal"
            fieldType="ComponentContainer"
            value={value.modal}
            onChange={handleModalChange}
            disabled={disabled}
            whiteList={whiteList}
            selectedContent={selectedContent}
            parentKey={objKey}
            objKey="modal"
            goTo={goTo}
            theme={theme}
            actions={actions}
            mandatory
            condition="modal"
            field={modalFieldSchema}
          />,
          <FieldsBehavior
            key="url"
            title="URL"
            name="url"
            fieldType="UrlField"
            value={value.url}
            onChange={handleUrlChange}
            disabled={disabled}
            mandatory
            condition="url"
            field={urlFieldSchema}
            advanced
          />,
        ]}
      />
    </>
  );
};

interface ILinkFieldProps {
  value: ILinkField;
  onChange: (value: ILinkField | null) => void;
  disabled?: boolean;
  whiteList: string[];
  actions: any;
  theme: string;
  goTo: any;
  selectedContent: any;
  objKey: string;
}

export default memo(LinkField);
