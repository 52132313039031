import React, { useState } from "react";
import { hex3to6, defaultColors } from "./../helpers";

import * as S from "./style";

const Picker = (props: IProps): JSX.Element => {
  const { value, onChange, colors } = props;
  const [color, setColor] = useState(value);
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;
    setInputValue(newValue);

    const re3 = /^#[0-9a-fA-F]{3}$/;
    const re6 = /^#[0-9a-fA-F]{6}$/;

    if (re3.test(newValue)) {
      newValue = hex3to6(newValue);
      setColor(newValue);
      onChange(newValue);
    } else if (re6.test(newValue)) {
      setColor(newValue);
      onChange(newValue);
    }
  };

  const handleClick = (newColor: string | any) => () => {
    const gridColor = typeof newColor !== "object" ? newColor : newColor.hex;
    setInputValue(gridColor);
    setColor(gridColor);
    onChange(newColor);
  };

  const isLight = (color: string) => {
    if (!color) return true;
    const hex = color.replace("#", "");
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
    return brightness > 155;
  };

  const gridColors = colors ? colors : defaultColors;

  return (
    <S.Wrapper data-testid="picker-wrapper">
      <S.Cover background={color} light={isLight(color)}>
        {color}
      </S.Cover>
      <S.Grid>
        {gridColors.map((item: any) => {
          const gridColor = typeof item === "object" ? item.hex : item;
          return (
            <S.GridItem
              data-testid="grid-item"
              key={gridColor}
              background={gridColor}
              onClick={handleClick(item)}
              selected={gridColor === color}
            />
          );
        })}
      </S.Grid>
      <S.InputWrapper>
        <S.Input data-testid="input-picker" type="text" value={inputValue} onChange={handleChange} />
      </S.InputWrapper>
    </S.Wrapper>
  );
};

export interface IProps {
  value: string;
  colors?: string[];
  onChange: (newColor: string) => void;
}

export default Picker;
