import React from "react";
import { Route, Switch } from "react-router-dom";

import DataPacks from "./DataPacks";

const Globals = (): JSX.Element => {
  return (
    <Switch>
      <Route exact path="/sites/settings/content-types" component={DataPacks} />
    </Switch>
  );
};

export default Globals;
