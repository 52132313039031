import styled from "styled-components";

const Component = styled.div`
  ${(p) => p.theme.textStyle.fieldLabel};
  color: ${(p) => p.theme.color.textHighEmphasis};
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: ${(p) => p.theme.spacing.l};
  background: ${(p) => p.theme.color.uiBackground02};
  border: 1px solid transparent;
  margin-bottom: ${(p) => p.theme.spacing.xs};
  padding: 0 ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.radii.s};
  box-shadow: ${(p) => p.theme.shadow.shadowS};
  text-align: left;
  cursor: pointer;
`;

const Button = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  display: flex;
  color: ${(p) => p.theme.color.interactive01};
  margin-left: auto;
`;

export { Component, Button };
