import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: ${(p) => p.theme.spacing.xl};
  height: ${p => `calc(${p.theme.spacing.m} * 2)`};
  padding: ${(p) => p.theme.spacing.s};
  margin: ${(p) => `0 ${p.theme.spacing.xs}`};
  border: ${(p) => `1px solid ${p.theme.color.uiLine}`};
  border-radius: ${(p) => p.theme.spacing.xxs};
  color: ${(p) => p.theme.colors.textLowEmphasis};

  &:active,
  &:focus {
    outline: none;
  }
`;

const Literal = styled.div<{size?: string}>`
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  margin-right: ${p => p.size==="S" ? p.theme.spacing.xs : `calc(${p.theme.spacing.s} * 2)`};
`;

export {
  Input,
  Wrapper,
  Literal
};
