import React from "react";

import Circle from "./components/Circle";
import Dots from "./components/Dots";

const components: Record<string, () => JSX.Element> = { Circle, Dots };

const getImage = (name: string) => {
  try {
    return components[name];
  } catch (err) {
    return null;
  }
};

const Loader = (props: IProps): JSX.Element | null => {
  const name = props.name
    .replace(".svg", "")
    .split("-")
    .map((type: string) => type.charAt(0).toUpperCase() + type.slice(1))
    .join("");

  const { size = "70" } = props;

  const elementProps = {
    height: size,
    width: size,
    viewBox: "0 0 100 100",
  };

  const Svg = getImage(name);

  if (Svg) {
    return React.createElement(Svg, elementProps);
  }
  
  return null;
};

export interface IStateProps {
  name: string;
  size?: string;
}

type IProps = IStateProps;

export default Loader;
