import React from "react";
import { Switch, Route } from "react-router-dom";
import { INavItem } from "@ax/types";
import { usePermission } from "@ax/hooks";

import Globals from "./Globals";
import Languages from "./Languages";
import Social from "./Social";

const navItems: INavItem[] = [
  {
    title: "Globals",
    path: "/sites/settings/globals",
    component: Globals,
    permission: "general.manageSiteSettings",
  },
  {
    title: "Languages",
    path: "/sites/settings/languages",
    component: Languages,
    permission: "general.accessToLanguages",
  },
  {
    title: "Social",
    path: "/sites/settings/social",
    component: Social,
    permission: "general.manageSocialMedia",
  },
];

const Settings = (): JSX.Element => {
  return (
    <Switch>
      {navItems.map((item, index) => {
        const isAllowed = usePermission(item.permission);
        return (
        <Route exact path={item.path} key={index}>
          {isAllowed && React.createElement(item.component, { navItems, currentNavItem: item }, null)}
        </Route>
      )})}
    </Switch>
  );
};

export default Settings;
