/* eslint-disable @typescript-eslint/no-empty-function */

export const setHeadComponents = (props) => {
  const headKeyIdentifier = "data-head-component";
  const headValueIdentifier = "griddo-instance-head-component";
  const headElementsIdentifier = `[${headKeyIdentifier}="${headValueIdentifier}"]`;

  [...document.querySelectorAll(headElementsIdentifier)].map((e) => e.remove());

  props.map((tag) => {
    const element = document.createElement(tag.type);
    element.setAttribute(headKeyIdentifier, headValueIdentifier);

    Object.keys(tag.props).map((attKey) => {
      if (attKey === "dangerouslySetInnerHTML") {
        return (element.innerHTML = tag.props.dangerouslySetInnerHTML.__html);
      }
      return element.setAttribute(attKey, tag.props[attKey]);
    });

    document.head.appendChild(element);
  });

  return props;
};

export const setHtmlAttributes = (props) => {
  return props;
};

export const setBodyAttributes = (props) => {
  return props;
};

export const setPreBodyComponents = (props) => {
  return props;
};

export const setPostBodyComponents = (props) => {
  return props;
};

export const setBodyProps = (props) => {
  return props;
};

export const pathname = "ax-editor";
