const getSortedListStatus = (orderPointer: string, isAscending: boolean): { isAscending: boolean, sortedByDate: boolean } => {
  const sortedListStatus = {
    isAscending,
    sortedByDate: orderPointer === "date",
  };

  return sortedListStatus;
};

export { getSortedListStatus }
