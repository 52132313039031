import styled from "styled-components";

export const Form = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: ${(p) => `${p.theme.spacing.m} ${p.theme.spacing.m} 0 ${p.theme.spacing.m}`};
  flex-direction: column;
  overflow: auto;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  width: 100%;
  & > div:nth-child(odd) {
    margin-right: ${(p) => p.theme.spacing.m};
  }
`;
