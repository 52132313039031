import styled from "styled-components";

export const SubItem = styled.li<{ active: boolean; type: string }>`
  ${(p) => p.active ? p.theme.textStyle.uiMSemibold : p.theme.textStyle.uiM};
  background-color: ${(p) => (p.active ? p.theme.color.overlayHoverDark : `transparent`)};
  display: block;
  color: ${(p) => (p.active ? p.theme.color.textHighEmphasisInverse : p.theme.color.textMediumEmphasisInverse)};
  clear: both;
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  border-radius: ${(p) => p.theme.radii.s};
  &:last-child {
    margin-bottom: 0;
  }

  > a {
    display: flex;
    padding: ${(p) => p.theme.spacing.xs};
    color: inherit;
    border-radius: ${(p) => p.theme.radii.s};

    &.selected {
      background-color: ${(p) => p.theme.color.overlayHoverPrimary};
    }

    &:hover {
      background-color: ${(p) => (p.type === "multisite" ? p.theme.color.uiLineInverse : p.theme.color.uiSiteMenu)};
      cursor: pointer;
      color: ${(p) => p.theme.color.textHighEmphasisInverse};
    }

    &:active {
      background-color: ${(p) => p.theme.color.overlayPressedPrimary};
    }
  }
`;
