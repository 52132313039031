import { IGetRoles, IActivateRole } from "@ax/types";
import { AxiosResponse } from "axios";
import { template } from "./config";
import { IServiceConfig, sendInitialRequest, sendRequest } from "./utils";

const SERVICES: { [key: string]: IServiceConfig } = {
  GET_ROLES: {
    ...template,
    endpoint: "/roles",
    method: "GET",
  },
  GET_SITE_ROLES: {
    ...template,
    endpoint: ["/site/", "/roles"],
    method: "GET",
  },
  ACTIVATE_ROLE: {
    ...template,
    endpoint: ["/role/", "/site"],
    method: "PUT",
  },
  ACTIVATE_ROLE_BULK: {
    ...template,
    endpoint: ["/site/", "/role/activate/bulk"],
    method: "POST",
  },
};

const getRoles = async (params: IGetRoles, token?: string): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_SITE_ROLES;
  const { siteId, order } = params;

  const BASE_URL = `${host}${prefix}${siteId}${suffix}`;

  SERVICES.GET_SITE_ROLES.dynamicUrl = order ? `${BASE_URL}/?order=${order}` : `${BASE_URL}`;

  if (token) {
    return sendInitialRequest(SERVICES.GET_SITE_ROLES, token);
  } else {
    return sendRequest(SERVICES.GET_SITE_ROLES);
  }
};

const activateRole = async (params: IActivateRole): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.ACTIVATE_ROLE;
  const { id, siteId, active } = params;

  SERVICES.ACTIVATE_ROLE.dynamicUrl = `${host}${prefix}${id}${suffix}/${siteId}/activate`;
  const data = { active };

  return sendRequest(SERVICES.ACTIVATE_ROLE, { ...data });
};

const activateRolesBulk = async (params: IActivateRole): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.ACTIVATE_ROLE_BULK;
  const { id, siteId, active } = params;

  SERVICES.ACTIVATE_ROLE_BULK.dynamicUrl = `${host}${prefix}${siteId}${suffix}`;
  const data = { active, roles: id };

  return sendRequest(SERVICES.ACTIVATE_ROLE_BULK, { ...data });
};

export default {
  getRoles,
  activateRole,
  activateRolesBulk,
};
