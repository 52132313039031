const NAME = "redirects";

const SET_REDIRECTS = `${NAME}/SET_REDIRECTS`;
const SET_TOTAL_ITEMS = `${NAME}/SET_TOTAL_ITEMS`;
const SET_TOTAL_IMPORTS = `${NAME}/SET_TOTAL_IMPORTS`;
const SET_IMPORTS = `${NAME}/SET_IMPORTS`;

const ITEMS_PER_PAGE = 50;

const DEFAULT_PARAMS = {
  page: 1,
  itemsPerPage: ITEMS_PER_PAGE,
  pagination: true,
};

const ERROR_CODE = {
  isBeingUsed: 409,
};

export {
  NAME,
  SET_REDIRECTS,
  SET_TOTAL_ITEMS,
  ERROR_CODE,
  DEFAULT_PARAMS,
  ITEMS_PER_PAGE,
  SET_IMPORTS,
  SET_TOTAL_IMPORTS,
};
