import React from "react";

import { CheckField, TableCounter, Tooltip, TranslationsFilter } from "@ax/components";
import { IQueryValue } from "@ax/types";

import * as S from "./style";

const TableHeader = (props: IProps): JSX.Element => {
  const { totalItems, selectAllItems, isScrolling, filterItems, filterValues, setHoverCheck, checkState } = props;
  return (
    <S.TableHeader isScrolling={isScrolling}>
      <S.CheckHeader>
        <Tooltip content="Select All Items">
          <CheckField
            key="selectAll"
            name="selectAll"
            value="selectAll"
            onChange={selectAllItems}
            checked={checkState.isAllSelected || checkState.hoverCheck}
            disabled={false}
            error={false}
            setHoverCheck={setHoverCheck}
          />
        </Tooltip>
      </S.CheckHeader>
      <S.NameHeader>Name</S.NameHeader>
      <S.CodeHeader>Code</S.CodeHeader>
      <S.SelectHeader>Selectable</S.SelectHeader>
      <S.TransHeader>
        <TranslationsFilter filterItems={filterItems} value={filterValues.translated} />
      </S.TransHeader>
      <S.ActionsHeader>
        <TableCounter totalItems={totalItems} />
      </S.ActionsHeader>
    </S.TableHeader>
  );
};

interface IProps {
  totalItems: number;
  isScrolling: boolean;
  selectAllItems: () => void;
  filterValues: Record<string, IQueryValue[]>;
  filterItems: (filterPointer: string, filtersSelected: IQueryValue[]) => void;
  setHoverCheck: (state: boolean) => void;
  checkState: Record<string, boolean>;
}

export default TableHeader;
