import React from "react";
import { getFormattedDateWithTimezone } from "@ax/helpers";

import * as S from "./style";

const Item = (props: IItemProps): JSX.Element => {
  const { title, type, date, onClick } = props;

  const pageType = type || "Page";

  return (
    <S.ListItem onClick={onClick}>
      <S.Content>
        <S.Header>
          <S.Type>{pageType}</S.Type>
          <S.Date>{getFormattedDateWithTimezone(date, "d MMM Y")}</S.Date>
        </S.Header>
        <S.Title>{title}</S.Title>
      </S.Content>
    </S.ListItem>
  );
};

interface IItemProps {
  title: string;
  type?: string;
  date: Date;
  onClick: () => void;
}

export default Item;
