import styled from "styled-components";

import { Header } from "@ax/components/TableList/style";

const TableHeader = styled.div<{ isScrolling?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: ${(p) => p.theme.spacing.m};
  border-bottom: ${(p) => (p.isScrolling ? `1px solid ${p.theme.color.uiLine};` : "")};
`;

const HeaderWrapper = styled.div`
  position: relative;
`;

const CheckHeader = styled(Header)`
  padding-right: 0;
  width: 40px;
  label {
    margin-bottom: ${(p) => p.theme.spacing.s};
  }
  > div {
    width: ${(p) => p.theme.spacing.s};
  }
`;

const NameWrapper = styled.div`
  position: relative;
  width: 438px;
`;

const DomainHeader = styled(Header)`
  flex-grow: 1;
  position: relative;
  width: 440px;
`;

const LiveHeader = styled(Header)`
  position: relative;
  justify-content: center;
`;

const LastAccessCell = styled(Header)`
  position: relative;
  white-space: nowrap;
  width: 200px;
`;

export { TableHeader, CheckHeader, DomainHeader, LiveHeader, NameWrapper, HeaderWrapper, LastAccessCell };
