import React, { useState } from "react";

import { connect } from "react-redux";

import { IRootState, ICheck, IRole, IUser } from "@ax/types";
import { CheckField, ToggleField, Tag, Tooltip, Avatar } from "@ax/components";

import * as S from "./style";

const RoleItem = (props: IRoleItemProps): JSX.Element => {
  const { role, users, isSelected, onChange, activateRole, siteId, onClick, hoverCheck } = props;
  const isSiteView = siteId !== "global";
  const [isOpen, setIsOpen] = useState(false);

  const toggleStatus = (value: boolean) => activateRole(value);

  const roleUsers =
    isSiteView && role?.users?.length > 0 ? users.filter((user: IUser) => role.users.includes(user.id)) : [];

  const avatarList =
    isSiteView && roleUsers.length
      ? roleUsers.map(
          (user: IUser, i: number) => i < 5 && <Avatar image={user.image?.thumb} name={user.name} size="s" key={i} />
        )
      : [];

  const tooltipAvatarList =
    isSiteView && roleUsers.length
      ? roleUsers.map((user: IUser) => (
          <S.AvatarContainer addSpacing={true} key={user.id}>
            <Avatar image={user.image?.thumb} name={user.name} size="s" key={user.id} />
          </S.AvatarContainer>
        ))
      : [];

  const UsersTooltip = () => <S.AvatarsTooltip data-testid="avatars-tooltip">{tooltipAvatarList}</S.AvatarsTooltip>;

  return (
    <S.RoleRow role="rowgroup" selected={isSelected} data-testid="role-item" key={`${role.name}${role.id}`}>
      <S.CheckCell role="cell">
        <CheckField
          name="check"
          value={role.id ?? ""}
          checked={isSelected || (hoverCheck && role.editable)}
          onChange={(value: ICheck) => onChange(value)}
          disabled={!role.editable}
        />
      </S.CheckCell>
      <S.RoleCell role="cell" onClick={onClick}>
        <S.Name data-testid="role-item-name">
          <Tag key={role.name} text={role.name} color={role.hex} />
        </S.Name>
        <S.Description>{role.description}</S.Description>
      </S.RoleCell>
      {isSiteView && (
        <S.AvatarCell
          data-testid="avatar-cell"
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
          onClick={onClick}
        >
          {roleUsers.length > 5 && isOpen && <UsersTooltip />}
          <S.AvatarsWrapper>
            {avatarList}
            {roleUsers?.length > 5 ? (
              <S.UsersLength data-testid="users-length"> +{roleUsers.length - 5}</S.UsersLength>
            ) : null}
          </S.AvatarsWrapper>
        </S.AvatarCell>
      )}
      <S.Permissions isSiteView={isSiteView} onClick={onClick} data-testid="role-item-permissions">
        <Tooltip content="Number of permissions of this Role">{role?.permissions?.totalPermissions}</Tooltip>
      </S.Permissions>
      <S.Status>
        <Tooltip content={!role.editable ? "It can't be disabled because it's a main role." : undefined}>
          <ToggleField
            name={role?.name}
            size="s"
            value={role?.active}
            onChange={toggleStatus}
            disabled={!role.editable}
          />
        </Tooltip>
      </S.Status>
      <S.ActionsCell role="cell"></S.ActionsCell>
    </S.RoleRow>
  );
};

interface IRoleItemProps {
  role: IRole;
  users: IUser[];
  isSelected: boolean;
  siteId: string | number;
  hoverCheck: boolean;
  onChange: (value: ICheck) => void;
  onClick: () => void;
  activateRole(value: boolean): void;
}

const mapStateToProps = (state: IRootState) => ({
  users: state.users.users,
});

export default connect(mapStateToProps)(RoleItem);
