import React, { useState } from "react";
import { connect } from "react-redux";

import {
  IDataPack,
  IDataPackConfigImport,
  IDataPackConfigImportCategory,
  IRootState,
  ISite,
  IStructuredData,
  ITemplate,
} from "@ax/types";
import { dataPacksActions } from "@ax/containers/Settings";
import { FieldsBehavior, Modal, Tag } from "@ax/components";
import { useModal } from "@ax/hooks";

import TemplateConfig from "./TemplateConfig";
import * as S from "./style";

const Form = (props: IProps): JSX.Element => {
  const { currentSite, selected, updateFormValue, configFormData, allStructuredData } = props;

  if (!currentSite) {
    throw new Error(`ERROR: User reached Data Pack form with null site info`);
  }

  if (!selected) {
    throw new Error(`ERROR: User reached Data Pack form without one selected`);
  }

  const { templates, categories, structuredData } = selected;

  const getSubscribedData = (importValue: IDataPackConfigImport[]) => {
    const value: Record<string, ICategoryValue[]> = {};
    if (!importValue) return value;
    importValue.forEach((item: IDataPackConfigImport) => {
      item.categories &&
        item.categories.forEach((cat: IDataPackConfigImportCategory) => {
          const catObj = { value: cat.id, title: cat.title, structuredData: cat.structuredData };
          value[cat.structuredData] = value[cat.structuredData] ? [...value[cat.structuredData], catObj] : [catObj];
        });
    });
    return value;
  };

  const initialValues = configFormData && getSubscribedData(configFormData.import);
  const [selectedCategories, setSelectedCategories] = useState(initialValues);
  const { isOpen, toggleModal } = useModal();

  const selectOptions: { value: string; label: string }[] = [];
  templates.forEach((template: ITemplate) =>
    selectOptions.push({
      value: template.id,
      label: template.title,
    })
  );

  const indexDefaultOptions = [
    {
      value: true,
      title: "Index",
      name: "index",
    },
    {
      value: false,
      title: "No index",
      name: "noindex",
    },
  ];

  const setDefaultParent = (value: number) => updateFormValue({ defaultParent: value });
  const setModifiableOnPage = (value: boolean) => updateFormValue({ modifiableOnPage: value });
  const setIndexDefault = (value: boolean) => updateFormValue({ indexDefault: value });

  const setSubscribedData = (value: Record<string, ICategoryValue[]>) => {
    const newCategories: IDataPackConfigImportCategory[] = [];
    Object.keys(value).forEach((key: string) => {
      const categories: IDataPackConfigImportCategory[] = value[key].map((cat: ICategoryValue) => {
        return { id: cat.value, title: cat.title, structuredData: key };
      });
      newCategories.push(...categories);
    });
    const newValue = [];
    if (newCategories.length) {
      newValue.push({ structuredData: structuredData[0].id, categories: newCategories });
    }
    updateFormValue({ import: newValue });
  };

  const mainAction = {
    title: "Add Subscription",
    onClick: () => setSubscribedData(selectedCategories),
  };

  const secondaryAction = { title: "Cancel", onClick: toggleModal };

  const getCategoryText = (id: string): string => {
    const data = [...categories, ...allStructuredData.global, ...allStructuredData.site].find((elem) => elem.id === id);
    return data ? data.title : id;
  };

  const getIsGlobal = (id: string): boolean => {
    const data = allStructuredData && allStructuredData.site.find((elem) => elem.id === id);
    return data && data.local ? false : true;
  };

  const globalPageData = structuredData.find((data) => data.fromPage && !data.local);

  const elements =
    globalPageData &&
    globalPageData.relatedCategories &&
    globalPageData.relatedCategories.map((cat) => {
      return { source: cat, key: cat, placeholder: getCategoryText(cat), isGlobal: getIsGlobal(cat) };
    });

  const deleteTag = (catID: number) => {
    const newCategories = configFormData.import.map((type: IDataPackConfigImport) => {
      const filteredCats = type.categories.filter((el: IDataPackConfigImportCategory) => el.id !== catID);
      return { ...type, categories: filteredCats };
    });
    const filteredNewCategories = newCategories.filter((cat: IDataPackConfigImport) => cat.categories.length);
    updateFormValue({ import: filteredNewCategories });
  };

  const getCategoryLabels = () => (
    <S.CategoriesWrapper isEmpty={!Object.keys(selectedCategories).length}>
      {configFormData &&
        configFormData.import &&
        configFormData.import.map((data: IDataPackConfigImport) => {
          return (
            data.categories &&
            data.categories.map((category: IDataPackConfigImportCategory) => {
              const categoryText = getCategoryText(category.structuredData);
              const handleDeleteTag = () => deleteTag(category.id);
              return (
                <Tag
                  key={`${categoryText}-${category.title}`}
                  text={`${categoryText}: ${category.title}`}
                  onDeleteAction={handleDeleteTag}
                />
              );
            })
          );
        })}
    </S.CategoriesWrapper>
  );

  return (
    <>
      <S.Config>
        {!!globalPageData && (
          <S.SectionContent>
            <S.SubscribeWrapper>
              <S.Heading>Subscribe to data</S.Heading>
              <S.Text>
                If you want to get any Global {selected.title} Pages on this site automatically, you can do so by
                selecting the respective categories.
              </S.Text>
              {configFormData && configFormData.import && getCategoryLabels()}
              <S.StyledButton type="button" buttonStyle="text" icon="addCircle" onClick={toggleModal}>
                Add Category
              </S.StyledButton>
            </S.SubscribeWrapper>
          </S.SectionContent>
        )}
        <S.SectionContent>
          <S.Heading>Detail page configuration</S.Heading>
          <FieldsBehavior
            title="Default URL parent for detail template"
            name="defaultParent"
            fieldType="AsyncSelect"
            site={currentSite}
            entity={"pages"}
            value={configFormData && configFormData.defaultParent}
            onChange={setDefaultParent}
            options={{ excludeDetailPages: true }}
          />
          <FieldsBehavior
            title="Modifiable in page"
            name="defaultParent"
            fieldType="ToggleField"
            value={(configFormData && configFormData.modifiableOnPage) || false}
            onChange={setModifiableOnPage}
          />
          <FieldsBehavior
            title="Meta robots index default"
            name="indexDefault"
            fieldType="RadioGroup"
            value={(configFormData && configFormData.indexDefault) || false}
            options={indexDefaultOptions}
            onChange={setIndexDefault}
          />
        </S.SectionContent>
        <TemplateConfig templates={templates} currentSite={currentSite} />
      </S.Config>
      <Modal
        isOpen={isOpen}
        hide={toggleModal}
        size="M"
        title="Subscribe to data"
        mainAction={mainAction}
        secondaryAction={secondaryAction}
      >
        {isOpen ? (
          <S.ModalContent>
            <FieldsBehavior
              fieldType="MultiCheckSelectGroup"
              title="Select Categories"
              filled={true}
              elements={elements}
              site={currentSite}
              value={selectedCategories}
              onChange={setSelectedCategories}
            />
          </S.ModalContent>
        ) : null}
      </Modal>
    </>
  );
};

interface ICategoryValue {
  value: number;
  title: string;
  structuredData: string;
}

interface IProps {
  selected: IDataPack | null;
  currentSite: ISite | null;
  updateFormValue: (config?: any) => void;
  configFormData: any;
  allStructuredData: { global: IStructuredData[]; site: IStructuredData[] };
}

const mapDispatchToProps = {
  updateFormValue: dataPacksActions.updateFormValue,
};

const mapStateToProps = (state: IRootState) => ({
  selected: state.dataPacks.selected,
  currentSite: state.sites.currentSiteInfo,
  configFormData: state.dataPacks.configFormData,
  allStructuredData: state.structuredData.structuredData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
