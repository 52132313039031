import React from "react";
import { IErrorItem } from "@ax/types";
import { Icon } from "@ax/components";

import * as S from "./style";

const ErrorCenter = (props: IErrorCenterProps): JSX.Element => {
  const { errors, actions } = props;

  const goToElement = (key: string) => {
    const element = document.getElementById(`gdd_${key}`);

    if (element) {
      const pos = element.style.position;
      const top = element.style.top;
      element.style.position = "relative";
      element.style.top = "-120px";
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
        element.style.top = top;
        element.style.position = pos;
      }, 100);
    }
  };

  const getErrorItem = (item: IErrorItem): JSX.Element => {
    const handleClick = () => {
      if (item.hasDeactivatedPackage) {
        actions?.goToPackage();
      } else {
        item.editorID !== null && actions?.goToError(item.editorID, item.tab, item.template);
        goToElement(item.key);
      }
    };

    const icon = item.type === "warning" ? "warning" : "alert";
    const isClicable = !!item.editorID || !!item.hasDeactivatedPackage || !!item.key;
    return (
      <S.Wrapper
        data-testid="error-center-wrapper"
        key={`${item.editorID}${item.key}`}
        clickable={isClicable}
        onClick={handleClick}
      >
        <S.Header type={item.type}>
          <Icon name={icon} size="16" />
          <S.Type>{item.type}</S.Type>
        </S.Header>
        <S.Content>
          <S.Title>{item.message}</S.Title>
          <S.Subtitle>{item.name}</S.Subtitle>
          {isClicable && !item.hasDeactivatedPackage && <S.Link>Go to field</S.Link>}
          {isClicable && item.hasDeactivatedPackage && <S.Link>Go to package</S.Link>}
        </S.Content>
      </S.Wrapper>
    );
  };

  return (
    <S.ActionMenu>
      <S.MenuHeader>Error Center</S.MenuHeader>
      {errors && errors.map((item: IErrorItem) => getErrorItem(item))}
    </S.ActionMenu>
  );
};

export interface IErrorCenterProps {
  errors: IErrorItem[];
  actions?: {
    goToError(editorID: number | null, tab: string, template: boolean): void;
    goToPackage(): void;
  };
}

export default ErrorCenter;
