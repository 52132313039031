import styled from "styled-components";

export const LanguageItem = styled.div`
  background-color: ${(p) => p.theme.color.uiBarBackground};
  padding: ${(p) => p.theme.spacing.s};
  margin-bottom: ${(p) => p.theme.spacing.m};
  border: 1px solid ${(p) => p.theme.color.uiLine};
  box-sizing: border-box;
  border-radius: ${(p) => p.theme.radii.s};
`;

export const ItemHeader = styled.div`
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.color.textLowEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

export const ItemContent = styled.div`
  ${(p) => p.theme.textStyle.fieldContent};
  display: flex;
  color: ${(p) => p.theme.color.textHighEmphasis};
  span {
    margin-left: ${(p) => p.theme.spacing.xs};
  }
`;
