import React, { useCallback, useEffect } from "react";
import * as components from "components";
import { builderSSR, ssrHelpers, SiteProvider } from "components";
import { Core, Preview } from "@griddo/core";
import { ILanguage, ISocialState } from "@ax/types";

const BrowserContent = (props: IProps) => {
  const {
    content,
    socials,
    cloudinaryName,
    siteLangs,
    siteID,
    theme,
    isPage,
    header,
    footer,
    languageID,
    pageLanguages,
    selectEditorID,
    moduleActions,
    renderer,
    selectHoverEditorID,
  } = props;

  const API_URL = process.env.REACT_APP_API_ENDPOINT;
  const PUBLIC_API_URL = process.env.REACT_APP_PUBLIC_API_ENDPOINT;
  const INSTANCE = process.env.REACT_APP_INSTANCE || process.env.GRIDDO_REACT_APP_INSTANCE;

  const useInstanceExternalAssets = useCallback(() => {
    if (builderSSR && builderSSR.onRenderBody) {
      builderSSR.onRenderBody(ssrHelpers);
    }
  }, []);

  useEffect(useInstanceExternalAssets, [useInstanceExternalAssets]);

  return (
    <SiteProvider
      cloudinaryCloudName={cloudinaryName}
      theme={theme}
      socials={socials}
      siteLangs={siteLangs}
      selectEditorID={selectEditorID}
      renderer={renderer}
      apiUrl={API_URL}
      publicApiUrl={PUBLIC_API_URL}
      instance={INSTANCE}
      siteId={siteID}
      moduleActions={moduleActions}
      selectHoverEditorID={selectHoverEditorID}
    >
      <Preview
        isPage={isPage}
        apiUrl={API_URL}
        library={components}
        content={content}
        header={header}
        footer={footer}
        languageId={languageID}
        pageLanguages={pageLanguages}
      />
    </SiteProvider>
  );
};

interface IProps {
  content: any;
  socials: ISocialState;
  cloudinaryName: string | null;
  siteLangs: ILanguage[];
  siteID?: number;
  theme: string;
  isPage: boolean;
  header?: any;
  footer?: any;
  languageID: number;
  pageLanguages: Core.Page["pageLanguages"];
  renderer: "editor" | "preview";
  selectEditorID?(
    selectedComponent: { editorID: number; component: any; type: string; parentEditorID: number },
    parentComponent: string | undefined | null,
    e: React.SyntheticEvent
  ): void;
  selectHoverEditorID?(editorID: number, parentEditorID: number): void;
  moduleActions?: {
    deleteModuleAction(editorID: number): void;
    duplicateModuleAction(editorID: number): void;
  };
}

export default BrowserContent;
