import React from "react";

const HeroCard = props => (
  <svg width={24} height={24} fill="none" {...props}>
    <path d="M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z" fill="#5057FF"/>
    <rect x="7" y="7" width="10" height="2" fill="#5057FF"/>
    <rect x="7" y="10" width="10" height="4" fill="#5057FF"/>
    <rect x="7" y="15" width="4" height="2" fill="#5057FF"/>
  </svg>
);

export default HeroCard;
