import { template } from "./config";
import { sendRequest, sendInitialRequest, IServiceConfig } from "./utils";

const SERVICES: { [key: string]: IServiceConfig } = {
  GET_LANGUAGES: {
    ...template,
    endpoint: "/languages",
    method: "GET",
  },
  GET_SITE_LANGUAGES: {
    ...template,
    endpoint: ["/site/", "/languages"],
    method: "GET",
  },
  CREATE_SITE_LANGUAGE: {
    ...template,
    endpoint: ["/site/", "/languages"],
    method: "POST",
  },
  UPDATE_SITE_LANGUAGE: {
    ...template,
    endpoint: ["/site/", "/languages"],
    method: "PUT",
  },
  DELETE_SITE_LANGUAGE: {
    ...template,
    endpoint: ["/site/", "/languages/"],
    method: "DELETE",
  },
};

const getLanguages = async (token: string) => {
  return sendInitialRequest(SERVICES.GET_LANGUAGES, token);
};

const getSiteLanguages = async (siteID: number) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_SITE_LANGUAGES;

  SERVICES.GET_SITE_LANGUAGES.dynamicUrl = `${host}${prefix}${siteID}${suffix}`;

  return sendRequest(SERVICES.GET_SITE_LANGUAGES);
};

const createSiteLanguage = (siteID: number, data: any) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.CREATE_SITE_LANGUAGE;

  SERVICES.CREATE_SITE_LANGUAGE.dynamicUrl = `${host}${prefix}${siteID}${suffix}`;

  return sendRequest(SERVICES.CREATE_SITE_LANGUAGE, { ...data });
};

const updateSiteLanguage = (siteID: number, data: any) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.UPDATE_SITE_LANGUAGE;

  SERVICES.UPDATE_SITE_LANGUAGE.dynamicUrl = `${host}${prefix}${siteID}${suffix}`;
  return sendRequest(SERVICES.UPDATE_SITE_LANGUAGE, { ...data });
};

const deleteSiteLanguage = async (siteID: number, langID: number) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.DELETE_SITE_LANGUAGE;

  SERVICES.DELETE_SITE_LANGUAGE.dynamicUrl = `${host}${prefix}${siteID}${suffix}${langID}`;
  return sendRequest(SERVICES.DELETE_SITE_LANGUAGE);
};

export default { getLanguages, getSiteLanguages, createSiteLanguage, updateSiteLanguage, deleteSiteLanguage };
