import styled from "styled-components";
import { ActionMenu } from "@ax/components";

const StyledActionMenu = styled(ActionMenu)`
  opacity: 0;
  width: 32px;
  display: flex;
  position: absolute;
  right: ${(p) => p.theme.spacing.xxs};
  top: ${(p) => p.theme.spacing.xs};
`;

const Wrapper = styled.div<{ isTall: boolean }>`
  position: relative;
  background-color: ${(p) => p.theme.color.uiBackground02};
  border: ${(p) => `1px solid ${p.theme.color.uiLine}`};
  border-radius: ${(p) => p.theme.radii.s};
  display: flex;
  flex-direction: column;
  padding: ${(p) => p.theme.spacing.s};
  width: 100%;
  min-width: 220px;
  max-width: 280px;
  height: ${(p) => (p.isTall ? "180px" : "154px")};
  margin-right: ${(p) => p.theme.spacing.s};
  cursor: pointer;
  &:hover {
    background-color: ${(p) => p.theme.color.overlayHoverPrimary};
    ${StyledActionMenu} {
      opacity: 1;
    }
  }
`;

const Header = styled.div`
  display: flex;
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const CheckWrapper = styled.div`
  margin-right: ${(p) => p.theme.spacing.xs};
  & > div {
    width: ${(p) => p.theme.spacing.s};
  }
  label {
    margin-bottom: 0;
  }
`;

const IconWrapper = styled.div`
  height: 40px;
  img {
    height: 40px;
  }
`;

const Name = styled.div`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => p.theme.colors.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xs};
  word-break: break-word;
`;

const Info = styled.div`
  display: flex;
  margin-top: auto;
`;

const Tag = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  border: ${(p) => `1px solid ${p.theme.color.uiLine}`};
  border-radius: 20px;
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  padding: 2px 8px;
  margin-right: ${(p) => p.theme.spacing.xxs};
`;

const TagWrapper = styled.div`
  display: flex;
  margin-top: ${(p) => p.theme.spacing.xs};
`;

export { Wrapper, Header, Name, Info, CheckWrapper, IconWrapper, StyledActionMenu, Tag, TagWrapper };
