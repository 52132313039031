import styled from "styled-components";

export const RadioButton = styled.input<{disabled: boolean | undefined}>`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  pointer-events: ${(p) => p.disabled ? "none" : "auto"};
`;

export const Label = styled.label``;

export const ImageWrapper = styled.div<{ active: boolean | undefined; disabled: boolean | undefined }>`
  position: relative;
  pointer-events: ${(p) => p.disabled ? "none" : "auto"};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: ${(p) => p.active ? `inset 0 0 0 2px ${ p.disabled ?  p.theme.color.interactiveDisabled : p.theme.color.interactive01}` : "none" };
    border-radius: 4px;
  }

  &:hover:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: ${(p) => `inset 0 0 0 2px ${p.theme.color.interactive01}`} ;
    border-radius: 4px;
  }
`;

export const Image = styled.img`
  border-radius: ${(p) => p.theme.radii.s};
  cursor: pointer;
`;
