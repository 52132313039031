import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import { isReqOk } from "@ax/helpers";
import { global } from "@ax/api";
import { appActions } from "@ax/containers/App";
import { Button, FieldsBehavior } from "@ax/components";

import * as S from "./style";

const ResetPass = (props: IProps) => {
  const { setHistoryPush } = props;

  const { id, token } = useParams<{ id: string; token: string }>();

  const initState = { pass1: "", pass2: "" };
  const [state, setState] = useState(initState);

  const handlePass1Change = (value: string) => {
    setState({ ...state, pass1: value });
  };

  const handlePass2Change = (value: string) => {
    setState({ ...state, pass2: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const params = {
      id,
      token,
      password: state.pass1,
      retypedPassword: state.pass2,
    };

    const response = await global.resetPassword(id, params);
    if (isReqOk(response.status) && response.data.code === 200) {
      setHistoryPush("/login");
    }

    // TODO error and success messages
  };

  const validPasswords = state.pass1.trim() !== "" && state.pass2.trim() && state.pass1 === state.pass2;

  return (
    <S.Wrapper>
      <S.Main>
        <S.Header>
          <S.Subtitle>Welcome back</S.Subtitle>
          <S.Title>Reset your password</S.Title>
        </S.Header>

        <S.Form onSubmit={handleSubmit}>
          <FieldsBehavior
            fieldType="TextField"
            title="Password"
            inputType="password"
            mandatory={true}
            value={state.pass1}
            onChange={handlePass1Change}
          />
          <FieldsBehavior
            fieldType="TextField"
            title="Confirm your Password"
            inputType="password"
            mandatory={true}
            value={state.pass2}
            onChange={handlePass2Change}
          />
          <Button type="submit" disabled={!validPasswords}>
            Reset password
          </Button>
        </S.Form>
      </S.Main>
    </S.Wrapper>
  );
};

interface IProps {
  setHistoryPush(path: string): any;
}

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
};

export default connect(null, mapDispatchToProps)(ResetPass);
