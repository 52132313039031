import styled from "styled-components";

const FieldWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.s};
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

const FieldsDivider = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.s};
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
`;

export { FieldWrapper, FieldsDivider };
