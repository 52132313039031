const getActivatedDataPacksIds = (activatedDataPacks: any) => {
  const activatedDataPacksIds: string[] = [];
  activatedDataPacks.forEach((pack: any) => activatedDataPacksIds.push(pack.id));

  return activatedDataPacksIds;
};

const isModuleDisabled = (selectedComponent: string, type: string, activatedModules: string[]): boolean =>
  type === "module" && !activatedModules.includes(selectedComponent);

const getDeactivatedModules = (modules: any, currentModules: any) => {
  const deactivatedModules = currentModules
    .map((module: any) => (isModuleDisabled(module.component, "module", modules) ? module.component : null))
    .filter((module: string | null) => !!module);
  return deactivatedModules;
};

export { getActivatedDataPacksIds, isModuleDisabled, getDeactivatedModules };
