import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";

import { IEmptyStateProps, IIntegration, IQueryValue, IRootState } from "@ax/types";
import { MainWrapper, ErrorToast, TableList, EmptyState, Toast, FilterTagsBar } from "@ax/components";
import { integrationsActions } from "@ax/containers/Integrations";
import { appActions } from "@ax/containers/App";
import { useBulkSelection, useEmptyState, useModal, usePermission, useToast } from "@ax/hooks";

import BulkHeader from "./BulkHeader";
import IntegrationItem from "./IntegrationItem";
import { useFilterQuery } from "./hooks";
import { DeactivateModal, DeleteModal } from "./atoms";

import * as S from "./style";

const Integrations = (props: IIntegrationsProps): JSX.Element => {
  const {
    isLoading,
    getIntegrations,
    integrations,
    totalItems,
    currentSite,
    resetIntegrations,
    deleteIntegration,
    changeIntegrationState,
    setHistoryPush,
    setCurrentIntegration,
    orderIntegration,
  } = props;

  if (!currentSite) {
    throw new Error(`ERROR: User reached Integrations with null site info`);
  }

  const [isScrolling, setIsScrolling] = useState(false);
  const tableRef = useRef<HTMLDivElement>(null);
  const { setFiltersSelection, resetFilterQuery, filterValues, filterQuery, isFiltered } = useFilterQuery();
  const { isOpen: isOpenDelete, toggleModal: toggleModalDelete } = useModal();
  const { isOpen: isOpenDeactivate, toggleModal: toggleModalDeactivate } = useModal();
  const {
    isVisible: isVisibleDelete,
    toggleToast: toggleToastDelete,
    setIsVisible: setIsVisibleDelete,
    state: stateToastDelete,
  } = useToast();
  const {
    isVisible: isVisibleChange,
    toggleToast: toggleToastChange,
    setIsVisible: setIsVisibleChange,
    state: stateToastChange,
  } = useToast();

  const isAllowedToManageIntegrations = usePermission("general.manageSiteThirdPartyIntegrations");
  const isAllowedToDeactivateIntegrations = usePermission("general.deactivateSiteThirdPartyIntegrations");
  const isAllowedToDeleteIntegrations = usePermission("general.deleteSiteThirdPartyIntegrations");

  const noElementsProps: IEmptyStateProps = {
    message: "To start using add-ons in your site, create as many Custom Code as you need.",
    button: "Create custom code",
    action: () => setHistoryPush(`/sites/settings/addons/new`),
  };

  const fetchState = { isLoading, isFiltered };
  const { isEmpty, emptyStateProps } = useEmptyState(integrations, fetchState, { noElementsProps });

  const integrationsIds =
    integrations &&
    integrations
      .filter((integration: IIntegration) => integration.editable)
      .map((integration: IIntegration) => integration.id);

  const getParams = useCallback(() => {
    const params = {
      pagination: false,
      filter: filterQuery,
    };

    return params;
  }, [filterQuery]);

  useEffect(() => {
    const params = getParams();
    getIntegrations(currentSite, params);
    setCurrentIntegration(null);

    return () => {
      resetIntegrations();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSite, filterQuery]);

  const {
    resetBulkSelection,
    selectedItems,
    isSelected,
    areItemsSelected,
    checkState,
    addToBulkSelection,
    selectAllItems,
    setHoverCheck,
  } = useBulkSelection(integrationsIds);

  const bulkDelete = async () => {
    const params = getParams();
    const deleted = await deleteIntegration(selectedItems.all, params);
    if (deleted) {
      toggleToastDelete({ total: selectedItems.all.length });
    }
    toggleModalDelete();
    resetBulkSelection();
  };

  const bulkDeactivate = async () => {
    const params = getParams();
    const changed = await changeIntegrationState(selectedItems.all, false, params);
    if (changed) {
      toggleToastChange({ total: selectedItems.all.length, active: false });
    }
    toggleModalDeactivate();
    resetBulkSelection();
  };

  const unselectAllItems = () => resetBulkSelection();

  const selectItems = () => (checkState.isAllSelected ? unselectAllItems() : handleSelectAll());

  const handleSelectAll = () => selectAllItems();

  const filterItems = async (filterPointer: string, filtersSelected: IQueryValue[]) =>
    setFiltersSelection(filterPointer, filtersSelected);

  const bulkActions: { icon: string; text: string; action: () => void | Promise<void> }[] =
    isAllowedToDeleteIntegrations
      ? [
          {
            icon: "delete",
            text: "delete",
            action: toggleModalDelete,
          },
        ]
      : [];

  if (isAllowedToDeactivateIntegrations) {
    bulkActions.push({
      icon: "deactivate",
      text: "Deactivate",
      action: toggleModalDeactivate,
    });
  }

  const TableHeader = (
    <BulkHeader
      showBulk={areItemsSelected(integrationsIds)}
      selectAllItems={handleSelectAll}
      totalItems={totalItems}
      selectItems={selectItems}
      checkState={checkState}
      isScrolling={isScrolling}
      filterItems={filterItems}
      filterValues={filterValues}
      bulkActions={bulkActions}
      setHoverCheck={setHoverCheck}
    />
  );

  const onScroll = (e: any) => setIsScrolling(e.target.scrollTop > 0);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newScriptOrder = result.destination.index
      ? integrations[result.destination.index - (result.destination.index > result.source.index ? 0 : 1)].scriptOrder +
        1
      : 1;

    const params = getParams();
    orderIntegration(parseInt(result.draggableId), newScriptOrder, params);
  };

  const mainDeleteModalAction = {
    title: "Delete add-ons",
    onClick: bulkDelete,
  };
  const secondaryDeleteModalAction = { title: "Cancel", onClick: toggleModalDelete };

  const mainDeactivateModalAction = {
    title: "Deactivate add-ons",
    onClick: bulkDeactivate,
  };
  const secondaryDeactivateModalAction = { title: "Cancel", onClick: toggleModalDeactivate };

  const deletedToastProps = {
    setIsVisible: setIsVisibleDelete,
    message: stateToastDelete?.total > 1 ? `${stateToastDelete?.total} add-ons deleted` : "Add-on deleted",
  };

  const changedToastProps = {
    setIsVisible: setIsVisibleChange,
    message: `${stateToastChange?.total} add-on${stateToastChange?.total > 1 ? "s" : ""} ${
      stateToastChange?.active ? "enabled" : "disabled"
    }`,
  };

  const rightButtonProps = isAllowedToManageIntegrations
    ? {
        label: "New Custom Code",
        action: () => setHistoryPush(`/sites/settings/addons/new`),
      }
    : undefined;

  const ComponentList = React.memo(function ComponentList({ components }: any) {
    return components.map((integration: IIntegration, index: number) => {
      const isItemSelected = isSelected(integration.id);
      return (
        <Draggable draggableId={`${integration.id}`} index={index} key={integration.id}>
          {(provided) => (
            <IntegrationItem
              key={`${integration.name}${integration.id}`}
              integration={integration}
              isSelected={isItemSelected}
              onChange={addToBulkSelection}
              toggleToastDelete={toggleToastDelete}
              getParams={getParams}
              deleteIntegration={deleteIntegration}
              changeState={changeIntegrationState}
              toggleToastChange={toggleToastChange}
              innerRef={provided.innerRef}
              provided={provided}
              listLength={integrations.length}
              hoverCheck={checkState.hoverCheck}
            />
          )}
        </Draggable>
      );
    });
  });

  const filterLabels: Record<string, string> = {
    filterApplication: "Applied on",
    filterState: "State",
    filterCode: "Code",
  };

  return (
    <MainWrapper backLink={false} title="Add-ons" rightButton={rightButtonProps}>
      <S.Wrapper data-testid="integrations-main-wrapper">
        <S.ContentWrapper>
          <ErrorToast />
          <S.TitleWrapper>
            <S.Title>Custom Code</S.Title>
            <S.Description>Add custom code to the head or body of your site&apos;s pages.</S.Description>
          </S.TitleWrapper>
          <S.TableWrapper>
            <TableList tableHeader={TableHeader} onScroll={onScroll} hasFixedHeader={true} tableRef={tableRef}>
              <S.SearchTags>
                <FilterTagsBar
                  filters={filterValues}
                  setFilters={setFiltersSelection}
                  resetFilters={resetFilterQuery}
                  labels={filterLabels}
                />
              </S.SearchTags>
              {isEmpty ? (
                <S.EmptyWrapper>
                  <EmptyState {...emptyStateProps} />
                </S.EmptyWrapper>
              ) : (
                <>
                  {integrations.length >= 2 && (
                    <S.OrderNote>
                      You can easily <strong>reorder</strong> the list using <strong>drag and drop</strong>. The order{" "}
                      <strong>determines the priority of the add-on</strong>, and the code appears on the page
                      accordingly.
                    </S.OrderNote>
                  )}
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="integrationsList">
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} data-testid="droppable">
                          <ComponentList components={integrations} />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </>
              )}
            </TableList>
          </S.TableWrapper>
          {isVisibleDelete && <Toast {...deletedToastProps} />}
          {isVisibleChange && <Toast {...changedToastProps} />}
        </S.ContentWrapper>
      </S.Wrapper>
      <DeleteModal
        isOpen={isOpenDelete}
        toggleModal={toggleModalDelete}
        secondaryModalAction={secondaryDeleteModalAction}
        mainModalAction={mainDeleteModalAction}
        integrations={integrations}
        selectedIds={selectedItems.all}
      />
      <DeactivateModal
        isOpen={isOpenDeactivate}
        toggleModal={toggleModalDeactivate}
        secondaryModalAction={secondaryDeactivateModalAction}
        mainModalAction={mainDeactivateModalAction}
        integrations={integrations}
        selectedIds={selectedItems.all}
      />
    </MainWrapper>
  );
};

const mapStateToProps = (state: IRootState) => ({
  isLoading: state.app.isLoading,
  integrations: state.integrations.integrations,
  totalItems: state.integrations.totalItems,
  currentSite: state.sites.currentSiteInfo && state.sites.currentSiteInfo.id,
});

const mapDispatchToProps = {
  getIntegrations: integrationsActions.getIntegrations,
  resetIntegrations: integrationsActions.resetIntegrations,
  deleteIntegration: integrationsActions.deleteIntegration,
  changeIntegrationState: integrationsActions.changeIntegrationState,
  setHistoryPush: appActions.setHistoryPush,
  setCurrentIntegration: integrationsActions.setCurrentIntegration,
  orderIntegration: integrationsActions.orderIntegration,
};

interface IStateProps {
  isLoading: boolean;
  integrations: IIntegration[];
  totalItems: number;
  currentSite: number | null;
}

interface IDispatchProps {
  getIntegrations: (site: number, params: any) => Promise<void>;
  resetIntegrations: () => void;
  deleteIntegration: (
    integrationId: number | number[],
    currentParams: { pagination: boolean; filter: any }
  ) => Promise<boolean>;
  changeIntegrationState: (integrationId: number | number[], active: boolean, currentParams?: any) => Promise<boolean>;
  setHistoryPush: (path: string) => void;
  setCurrentIntegration: (integration: IIntegration | null) => void;
  orderIntegration(id: number, newOrder: number, currentParams?: any): Promise<boolean>;
}

export type IIntegrationsProps = IStateProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
