const getSortedListStatus = (orderPointer: string, isAscending: boolean): ISortedListStatus => {
  const sortedListStatus = {
    isAscending,
    sortedByName: orderPointer === "name",
    sortedByDate: orderPointer === "date",
    sortedBySize: orderPointer === "size",
  };

  return sortedListStatus;
};

export { getSortedListStatus };

interface ISortedListStatus {
  isAscending: boolean;
  sortedByName: boolean;
  sortedByDate: boolean;
  sortedBySize: boolean;
}
