import type { ISOLocale, Locale } from "../types/core";

import * as React from "react";

import { PageContext, type PageContextProps } from "../contexts/Page";

/**
 * `usePage()`
 *
 * Hook to get some page properties.
 * @see [Documentation](https://www.notion.so/griddoio/usePage-2a781f907cf74b4f8df6a4fbe0d8f399)
 *
 * @example
 * const { title } = usePage()
 */
function usePage(): PageContextProps {
	const value = React.useContext(PageContext);

	if (!value) {
		console.warn("Griddo: You forgot to put <PageProvider>.");
		return {} as PageContextProps;
	}

	// If the page in other language is not published (isLive=false) then its
	// removed from the pageLanguage array.
	const pageLanguagesWithLivePages = value.pageLanguages?.filter(
		(language) => language.isLive,
	);
	// Find the Locale
	const pageLocale = value.pageLanguages?.find(
		(langItem) => langItem.languageId === value.languageId,
	);
	const locale = pageLocale?.locale as Locale;
	const ISOLocale = pageLocale?.locale.replace("_", "-") as ISOLocale;

	return {
		...value,
		// Computed context props
		pageLanguages: pageLanguagesWithLivePages,
		locale,
		ISOLocale,
	};
}

export { usePage };
