import React from "react";
import { Switch, Route } from "react-router-dom";

import Redirects from "../../Redirects";
import Analytics from "./Analytics";

const navItems = [
  {
    title: "SEO",
    path: "/sites/settings/seo",
    component: Redirects,
    permission: "seoAnalytics.manageAnalyticsSiteSettings",
  },
  {
    title: "Analytics",
    path: "/sites/settings/analytics",
    component: Analytics,
    permission: "seoAnalytics.manageAnalyticsSiteSettings",
  },
];

const SeoAnalyticsSettings = (): JSX.Element => {
  return (
    <Switch>
      {navItems.map((item, index) => (
        <Route exact path={item.path} key={index}>
          {React.createElement(item.component, { navItems, currentNavItem: item }, null)}
        </Route>
      ))}
    </Switch>
  );
};

export default SeoAnalyticsSettings;
