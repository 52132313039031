import styled from "styled-components";

const Wrapper = styled.div`
  padding: ${(p) => p.theme.spacing.m};
  height: 100%;
`;

const Footer = styled.div`
  position: absolute;
  bottom: ${(p) => p.theme.spacing.m};
  right: ${(p) => p.theme.spacing.m};
`;

const FlagWrapper = styled.div`
  display: flex;
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const TranslationText = styled.div`
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-left: ${(p) => p.theme.spacing.xs};
`;

const Translation = styled.div`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

export { Wrapper, Footer, FlagWrapper, TranslationText, Translation };
