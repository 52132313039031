import React from "react";

import { IDomainRobot } from "@ax/types";
import { useModal } from "@ax/hooks";
import RobotsPanel from "./RobotsPanel";

import * as S from "./style";

const Item = (props: IProps): JSX.Element => {
  const { item, setItemContent } = props;

  const { isOpen, toggleModal } = useModal();

  const handleClick = () => toggleModal();

  const buttonText = item.content && item.content.trim() !== "" ? "Edit robots.txt" : "Add robots.txt";

  return (
    <>
      <S.Component onClick={handleClick}>
        {item.fullUrl} <S.Button>{buttonText}</S.Button>
      </S.Component>
      <RobotsPanel item={item} isOpen={isOpen} toggleModal={toggleModal} setItemContent={setItemContent} />
    </>
  );
};

interface IProps {
  item: IDomainRobot;
  setItemContent(content: string): void;
}

export default Item;
