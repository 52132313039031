import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Tree, { mutateTree, RenderItemParams, TreeItem, TreeData, ItemId } from "@atlaskit/tree";

import { IFolderTree, IRootState } from "@ax/types";
import { Icon } from "@ax/components";
import { trimText } from "@ax/helpers";

import { formatItem, normalizeTree } from "./utils";

import * as S from "./style";

const FolderTree = (props: IProps) => {
  const { folders, folderID, title, hidden, hideRoot, trimNames, createAction, onClick } = props;

  const [tree, setTree] = useState<TreeData>({ rootId: "root", items: {} });

  useEffect(() => {
    setTree(normalizeTree(folders, tree, hidden || []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folders]);

  const NewFolderButton = () =>
    createAction ? (
      <S.NewIconWrapper onClick={createAction}>
        <Icon name="newFolder" size="16" />
      </S.NewIconWrapper>
    ) : (
      <></>
    );

  const getIcon = (item: TreeItem) => {
    if (item.children && item.children.length > 0) {
      return item.isExpanded ? <Icon name="DownArrow" size="16" /> : <Icon name="RightArrow" size="16" />;
    } else {
      return <></>;
    }
  };

  const renderItem = ({ item, onExpand, onCollapse, provided }: RenderItemParams) => {
    const itemFormatted = formatItem(item, tree);

    const handleArrowClick = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      item.isExpanded ? onCollapse(item.id) : onExpand(item.id);
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onClick(itemFormatted.id);
    };

    return (
      <S.ItemWrapper ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <S.Item onClick={handleClick} selected={folderID === item.id}>
          <S.ArrowWrapper onClick={handleArrowClick}>{getIcon(item)}</S.ArrowWrapper>
          <S.IconWrapper>
            <Icon name="project" size="16" />
          </S.IconWrapper>
          <S.Name>{trimNames ? trimText(itemFormatted.name, 15) : itemFormatted.name}</S.Name>
          <NewFolderButton />
        </S.Item>
      </S.ItemWrapper>
    );
  };

  const onExpand = (itemId: ItemId) => {
    const newTree = mutateTree(tree, itemId, { isExpanded: true });
    setTree(newTree);
  };

  const onCollapse = (itemId: ItemId) => {
    const newTree = mutateTree(tree, itemId, { isExpanded: false });
    setTree(newTree);
  };

  const handleRootClick = () => onClick(0);

  return (
    <>
      {!hideRoot && (
        <S.Item selected={!folderID} onClick={handleRootClick}>
          <S.RootName>All Documents</S.RootName>
          <NewFolderButton />
        </S.Item>
      )}
      {title && <S.Title>{title}</S.Title>}
      <Tree tree={tree} renderItem={renderItem} onExpand={onExpand} onCollapse={onCollapse} offsetPerLevel={16} />
    </>
  );
};

interface IProps {
  folders: IFolderTree[];
  folderID: number;
  title?: string;
  hidden?: number[];
  hideRoot?: boolean;
  trimNames?: boolean;
  createAction?(): void;
  onClick(folderID: number): void;
}

const mapStateToProps = (state: IRootState) => ({
  folders: state.fileDrive.foldersTree,
});

export default connect(mapStateToProps)(FolderTree);
