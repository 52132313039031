import React from "react";
import styled from "styled-components";
import { Button } from "@ax/components";

const Wrapper = styled.div<{ isSmall: boolean }>`
  background-color: ${(p) => p.theme.color.uiBackground03};
  padding: ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.radii.s};
  max-width: ${(p) => (p.isSmall ? "320px" : "auto")};
`;

const Text = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(p) => p.theme.spacing.xs};
`;

const StyledButton = styled((props) => <Button {...props} />)`
  width: 100%;
  max-width: 264px;
`;

const ErrorText = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.error};
  margin-top: ${(p) => p.theme.spacing.xs};
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};
`;

const IconWrapper = styled.div`
  width: ${(p) => p.theme.spacing.s};
  height: ${(p) => p.theme.spacing.s};
  cursor: pointer;
  margin-left: auto;
`;

const NotificationContent = styled.div`
  display: flex;
`;

export { Wrapper, Text, ButtonWrapper, StyledButton, ErrorText, ModalContent, NotificationContent, IconWrapper };
