import styled from "styled-components";

const HiddenButtonsWrapper = styled.span`
  margin-left: auto;
  display: none;
  opacity: 0;
`;

const Component = styled.span<{ isArray?: boolean | undefined; disabled?: boolean }>`
  color: ${p => p.theme.color.textHighEmphasis};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${p => p.isArray ? "space-between" : "initial"};
  width: 100%;
  height: ${p => p.theme.spacing.l};
  background: ${p => p.theme.color.uiBackground02};
  border: 1px solid transparent;
  margin-bottom: ${p => p.theme.spacing.xs};
  padding: 0 ${p => p.theme.spacing.xs};
  border-radius: ${p => p.theme.radii.s};
  box-shadow: ${p => p.theme.shadow.shadowS};
  ${p => p.theme.textStyle.fieldLabel};
  text-align: left;
  cursor: ${p => p.disabled ? `default` : `pointer`};

  &:hover {
    background: ${p => p.theme.color.overlayHoverPrimary};
  }

  &:hover ${HiddenButtonsWrapper}{
    display: flex;
    opacity: 1;
  }

  &:focus,
  &:active {
    background: ${p => p.disabled ? p.theme.color.uiBackground02 : p.theme.color.overlayFocusPrimary};
    border: 1px solid ${p => p.disabled ? `transparent` : p.theme.color.interactive01};
    outline: none;
  }
`;

const Label = styled.span`
  ${p => p.theme.textStyle.fieldLabel};
  color: ${p => p.theme.color.textHighEmphasis};
  margin-left: ${p => p.theme.spacing.xs};
`;

export {
  HiddenButtonsWrapper,
  Component,
  Label,
};
