import React from "react";

import { IconAction } from "@ax/components";

import * as S from "./style";

const Pagination = (props: IPaginationProps) => {
  const { itemsPerPage, totalItems, setPage, currPage, size } = props;

  const lastPage = Math.ceil(totalItems / itemsPerPage);
  const isLastPage = currPage === lastPage;
  const isFirstPage = currPage === 1;

  const _handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // tslint:disable-next-line: radix
    const page = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value);
    setPage(page);
  };

  const handlePrev = () => {
    if (currPage <= 1) return;

    const prevPage = currPage - 1;

    setPage(prevPage);
  };

  const handleNext = () => {
    if (lastPage && currPage >= lastPage) return;

    const nextPage = currPage + 1;

    setPage(nextPage);
  };

  const CurrentPage = size === "S" ? currPage : <S.Input data-testid="pagination-text-input" type="text" value={currPage} onChange={_handleChange} />;

  return (
    <S.Wrapper data-testid="pagination-wrapper">
      <S.Literal size={size} data-testid="pagination-label">
        Page {CurrentPage} of {lastPage}
      </S.Literal>
      <IconAction icon="left-arrow" onClick={handlePrev} disabled={isFirstPage} />
      <IconAction icon="right-arrow" onClick={handleNext} disabled={isLastPage} />
    </S.Wrapper>
  );
};

export interface IPaginationProps {
  setPage?: any;
  itemsPerPage: number;
  totalItems: number;
  currPage: number;
  size?: string;
}

export default Pagination;
