import * as React from "react";

function SvgEnGb(props) {
  return (
    <svg viewBox="0 0 512.002 512.002" {...props}>
      <path
        d="M503.172 423.725H8.828A8.829 8.829 0 010 414.897V97.104a8.829 8.829 0 018.828-8.828h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
        fill="#41479b"
      />
      <path
        d="M512 97.104a8.829 8.829 0 00-8.828-8.828h-39.495l-163.54 107.147V88.276h-88.276v107.147L48.322 88.276H8.828A8.829 8.829 0 000 97.104v22.831l140.309 91.927H0v88.276h140.309L0 392.066v22.831a8.829 8.829 0 008.828 8.828h39.495l163.54-107.147v107.147h88.276V316.578l163.54 107.147h39.495a8.829 8.829 0 008.828-8.828v-22.831l-140.309-91.927H512v-88.276H371.691L512 119.935V97.104z"
        fill="#f5f5f5"
      />
      <g fill="#ff4b55">
        <path d="M512 229.518H282.483V88.276h-52.966v141.242H0v52.965h229.517v141.242h52.966V282.483H512z" />
        <path d="M178.948 300.138L.25 416.135c.625 4.263 4.14 7.59 8.577 7.59h12.159l190.39-123.586h-32.428v-.001zM346.388 300.138H313.96l190.113 123.404c4.431-.472 7.928-4.09 7.928-8.646v-7.258l-165.613-107.5zM0 106.849l161.779 105.014h32.428L5.143 89.137C2.123 90.54 0 93.555 0 97.104v9.745zM332.566 211.863L511.693 95.586c-.744-4.122-4.184-7.309-8.521-7.309h-12.647L300.138 211.863h32.428z" />
      </g>
    </svg>
  );
}

export default SvgEnGb;
