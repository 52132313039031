import React from "react";
import * as S from "./style";

const NavigationModulesWarning = (props: INavigationModulesWarning): JSX.Element => {
  const { goTo } = props;

  return (
    <S.NavigationModulesWarning>
      <S.NavigationModulesTitle>Navigation Modules</S.NavigationModulesTitle>
      <S.NavigationModulesDescription>
        Create as many headers as you need for your site. You can decide on which page to use each one. To change the
        header design, you have to go to{" "}
        <S.SiteSettingsLink>
          <span onClick={goTo} onKeyPress={goTo} role="checkbox" aria-checked="false" tabIndex={0}>
            Site Settings
          </span>
        </S.SiteSettingsLink>
        .
      </S.NavigationModulesDescription>
    </S.NavigationModulesWarning>
  );
};

interface INavigationModulesWarning {
  goTo(): void;
}

export { NavigationModulesWarning };
