import React from "react";
import styled from "styled-components";
import { Header } from "@ax/components/TableList/style";

const Orientation = styled((props) => <Header {...props} />)<{ isActive: boolean }>`
  width: 90px;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.color.interactive01};
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  svg {
    margin-left: 4px;
  }
`;

const InteractiveArrow = styled.div`
  display: flex;
  svg {
    path {
      fill: ${(p) => p.theme.color.interactive01};
    }
  }
`;

const ChecksWrapper = styled.div`
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.s};
`;

export { Orientation, IconsWrapper, InteractiveArrow, ChecksWrapper };
