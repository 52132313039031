import * as React from "react";
const SvgWorking = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#5057FF"
      d="m19.166 21-5.229-5.42 1.512-1.559 5.254 5.42L19.166 21ZM6.032 21l-1.536-1.56 6.591-6.8-2.081-2.147-.595.614-1.09-1.125v1.943l-.595.639L4 9.752l.595-.64h1.908L5.388 8.015l2.75-2.812c.265-.273.554-.478.868-.614.314-.136.644-.205.991-.205s.677.069.991.205c.314.136.603.34.868.614L9.972 7.144l1.165 1.176-.62.614L12.6 11.08l2.454-2.53a2.048 2.048 0 0 1-.236-.64 3.734 3.734 0 0 1-.062-.69c0-.903.301-1.666.904-2.288C16.262 4.31 17.002 4 17.878 4a2.717 2.717 0 0 1 1.14.23l-2.23 2.3 1.734 1.816 2.23-2.3c.083.187.145.378.187.574.04.196.061.397.061.601 0 .903-.306 1.666-.917 2.288-.611.622-1.346.933-2.205.933a3.59 3.59 0 0 1-.694-.063 1.457 1.457 0 0 1-.595-.27L6.032 21Z"
    />
  </svg>
);
export default SvgWorking;
