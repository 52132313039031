import React, { memo } from "react";

import { IFile } from "@ax/types";

import DetailPanel from "./DetailPanel";
import FileDragAndDrop from "@ax/modules/FileDrive/FileDragAndDrop";

import * as S from "./style";

const GalleryPanel = (props: IGalleryPanelProps) => {
  const { selectedFile, validFormats, setFile, scope, currentFolderID, isAllowedToAdd, isAllowedToEdit, handleUpload } =
    props;

  return (
    <S.GalleryPanel>
      {!selectedFile ? (
        <FileDragAndDrop
          validFormats={validFormats}
          folderID={currentFolderID}
          handleUpload={handleUpload}
          siteID={scope}
          isAllowedToUpload={isAllowedToAdd}
        />
      ) : (
        <DetailPanel selectedFile={selectedFile} setFile={setFile} isAllowedToEdit={isAllowedToEdit} />
      )}
    </S.GalleryPanel>
  );
};

export interface IGalleryPanelProps {
  selectedFile: IFile | null;
  validFormats: string[];
  setFile: (fileData: any) => void;
  scope: number | "global";
  currentFolderID: number | null;
  handleUpload: (file: IFile[]) => void;
  isAllowedToEdit: boolean;
  isAllowedToAdd: boolean;
}

export default memo(GalleryPanel);
