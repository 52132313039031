import * as React from "react";

function SvgCircle(props) {
  return (
    <svg data-testid="circle-svg" viewBox="0 0 100 100" {...props}>
      <circle fill="none" stroke="#5057FF" strokeWidth={4} cx={50} cy={50} r={44} />
      <circle fill="#fff" stroke="#5057FF" strokeWidth={3} cx={8} cy={54} r={6}>
        <animateTransform attributeName="transform" dur="2s" type="rotate" from="0 50 48" to="360 50 52" repeatCount="indefinite" />
      </circle>
    </svg>
  );
}

export default SvgCircle;
