import React from "react";

const SvgCopy = (props) => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5263 2.3158H3.78947C2.80526 2.3158 2 3.12106 2 4.10527V16.6316H3.78947V4.10527H12.5263V2.3158ZM15.2105 5.89475H7.36842C6.38421 5.89475 5.57894 6.70001 5.57894 7.68422V20.2105C5.57894 21.1947 6.38421 22 7.36842 22H15.2105C16.1947 22 17 21.1947 17 20.2105V18H15.2105V20.2105H7.36843V7.68422H15.2105V10H17V7.68422C17 6.70001 16.1947 5.89475 15.2105 5.89475ZM22 14L18 10V13H13V15H18V18L22 14Z"
      fill="#5057FF"
    />
  </svg>
);

export default SvgCopy;