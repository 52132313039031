import styled from "styled-components";

const Wrapper = styled.div`
  height: 100%;
  padding: ${(p) => p.theme.spacing.m};
  padding-bottom: ${(p) => p.theme.spacing.xl};
  display: flex;
  flex-flow: column nowrap;
`;

const LoadingWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ListWrapper = styled.div`
  flex: 1;
  overflow: auto;
`;

const ItemList = styled.ul``;

const SearchWrapper = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const ButtonWrapper = styled.div`
  text-align: right;
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const ActionsWrapper = styled.div`
  text-align: right;
  padding-top: ${(p) => p.theme.spacing.s};
  padding-bottom: ${(p) => p.theme.spacing.m};
`;

const SelectsWrapper = styled.div`
  display: flex;
  & > div {
    margin-right: ${(p) => p.theme.spacing.s};
  }
`;

const Note = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  margin-bottom: ${(p) => p.theme.spacing.xs};
  background-color: ${(p) => p.theme.color.uiBackground03};
  padding: ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.radii.s};
  color: ${(p) => p.theme.color.textMediumEmphasis};
`;

const Order = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

export {
  Wrapper,
  LoadingWrapper,
  ListWrapper,
  ItemList,
  SearchWrapper,
  ButtonWrapper,
  ActionsWrapper,
  SelectsWrapper,
  Note,
  Order,
};
