import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { IDomainRobot, INavItem, IRootState } from "@ax/types";
import { appActions } from "@ax/containers/App";
import { domainsActions } from "@ax/containers/Domains";
import { MainWrapper, ErrorToast, Nav, Loading } from "@ax/components";
import { RouteLeavingGuard } from "@ax/guards";
import { useIsDirty } from "@ax/hooks";
import Item from "./Item";

import * as S from "./style";

const Robots = (props: IProps): JSX.Element => {
  const {
    isSaving,
    isLoading,
    navItems,
    currentNavItem,
    robots,
    getDomainsRobots,
    setHistoryPush,
    updateDomainRobots,
  } = props;

  const [robotItems, setRobotItems] = useState(robots);
  const { isDirty, setIsDirty, resetDirty } = useIsDirty(robotItems);

  useEffect(() => {
    getDomainsRobots();
    resetDirty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRobotItems(robots);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [robots]);

  const handleSave = async () => {
    const isSaved = await updateDomainRobots(robotItems);
    if (isSaved) {
      setIsDirty(false);
    }
  };

  const rightButtonProps = {
    label: isSaving ? "Saving" : "Save",
    disabled: isSaving,
    action: () => handleSave(),
  };

  const handleMenuClick = (path: string) => {
    setHistoryPush(path);
  };

  const mapItemList = (items: IDomainRobot[]) =>
    Array.isArray(items) &&
    items.map((item: IDomainRobot, i: number) => {
      const handleSetItem = (newContent: string) => {
        const newItems = [...robotItems];
        newItems[i].content = newContent;
        setRobotItems(newItems);
      };

      return <Item key={`${item.path}${i}`} item={item} setItemContent={handleSetItem} />;
    });

  const setRoute = (path: string) => setHistoryPush(path);
  const modalText = (
    <>
      Some robots <strong>are not saved</strong>.{" "}
    </>
  );

  return (
    <>
      <RouteLeavingGuard when={isDirty} action={setRoute} text={modalText} />
      <MainWrapper backLink={false} title="SEO Settings" rightButton={rightButtonProps}>
        <S.Wrapper>
          <Nav current={currentNavItem} items={navItems} onClick={handleMenuClick} />
          <S.ContentWrapper>
            <ErrorToast />
            <S.Title>Robots.txt editor</S.Title>
            <S.Description>
              You can edit your robots.txt files to show search engines which pages on your site to track.
            </S.Description>
            {isLoading ? <Loading /> : <S.Table>{robotItems && mapItemList(robotItems)}</S.Table>}
          </S.ContentWrapper>
        </S.Wrapper>
      </MainWrapper>
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  isSaving: state.app.isSaving,
  isLoading: state.app.isLoading,
  robots: state.domains.robots,
});

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
  getDomainsRobots: domainsActions.getDomainsRobots,
  updateDomainRobots: domainsActions.updateDomainsRobots,
};
interface IRobotsProps {
  isSaving: boolean;
  isLoading: boolean;
  navItems: INavItem[];
  currentNavItem: INavItem;
  robots: IDomainRobot[];
}

interface IDispatchProps {
  getDomainsRobots(): void;
  setHistoryPush(path: string, isEditor?: boolean): void;
  updateDomainRobots(robots: IDomainRobot[]): Promise<boolean>;
}

type IProps = IRobotsProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(Robots);
