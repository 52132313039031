import { template } from "./config";
import { IServiceConfig, sendRequest } from "./utils";

const SERVICES: { [key: string]: IServiceConfig } = {
  GET_SOCIAL: {
    ...template,
    endpoint: ["/site/", "/socials"],
    method: "GET",
  },
  UPDATE_SOCIAL: {
    ...template,
    endpoint: ["/site/", "/socials"],
    method: "PUT",
  },
};

const getSocial = (siteID: number) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_SOCIAL;

  SERVICES.GET_SOCIAL.dynamicUrl = `${host}${prefix}${siteID}${suffix}`;

  return sendRequest(SERVICES.GET_SOCIAL);
};

const updateSocial = (siteID: number, currentSocial: any) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.UPDATE_SOCIAL;

  SERVICES.UPDATE_SOCIAL.dynamicUrl = `${host}${prefix}${siteID}${suffix}`;

  return sendRequest(SERVICES.UPDATE_SOCIAL, currentSocial);
};

export default {
  getSocial,
  updateSocial,
};
