import * as React from "react";

function SvgEmpty(props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.923 11.61l-3.302-6.157A2.509 2.509 0 0016.35 4h-8.7c-.973 0-1.863.57-2.27 1.452L2.077 11.61a.826.826 0 00-.077.35V17.5C2 18.878 3.122 20 4.5 20h15c1.378 0 2.5-1.122 2.5-2.5v-5.542c0-.12-.026-.24-.077-.349zM6.893 6.15a.836.836 0 01.757-.483h8.7c.324 0 .62.19.757.484L19.96 12h-1.716a1.45 1.45 0 00-1.396 1.04l-.895 2.812a.205.205 0 01-.198.148h-7.51a.207.207 0 01-.2-.15l-.893-2.81A1.449 1.449 0 005.755 12H4.04l2.853-5.85z"
        fill="#5057FF"
      />
    </svg>
  );
}

export default SvgEmpty;
