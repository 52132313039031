import React, { useState } from "react";
import { connect } from "react-redux";

import { structuredDataActions } from "@ax/containers/StructuredData";
import {
  IRootState,
  IStructuredData,
  IStructuredDataContent,
  ILanguage,
  IStructuredDataCategory,
  ICategoryGroup,
  ICategoryGroupParams,
} from "@ax/types";
import { Button, Flag, FloatingPanel } from "@ax/components";
import { isCategory, isCategoryGroup } from "../utils";
import Form from "./Form";

import * as S from "./style";

const CategoryPanel = (props: IProps): JSX.Element => {
  const {
    isOpen,
    item,
    toggleModal,
    toggleToast,
    createStructuredDataContent,
    updateStructuredDataContent,
    createCategoryGroup,
    updateCategoryGroup,
    getContents,
    currentStructuredData,
    languages,
    translation,
  } = props;

  if (!currentStructuredData) {
    throw new Error(`ERROR: currentStructuredData missing`);
  }

  const initState: IFormState = {
    name: item?.content.title || "",
    code: (item as IStructuredDataCategory)?.content.code || "",
    type: item?.type || "category",
    selectable: (item as ICategoryGroup)?.selectable || false,
  };

  const [form, setForm] = useState(initState);

  const addItemAction = async () => {
    let isCreated = false;
    if (form.type === "category") {
      const newCategory: any = {
        structuredData: currentStructuredData.id,
        content: {
          title: form.name,
          code: form.code,
        },
        draft: false,
        entity: item && translation ? item.entity : null,
      };

      const langID = translation ? translation.lang.id : undefined;
      isCreated = await createStructuredDataContent(newCategory, langID);
    } else {
      const newGroup: ICategoryGroupParams = {
        structuredData: currentStructuredData.id,
        title: form.name,
        selectable: form.selectable,
        entity: item && translation ? item.entity : null,
        language: item && translation ? translation.lang.id : undefined,
      };

      isCreated = await createCategoryGroup(newGroup);
    }

    if (isCreated) {
      getContents(currentStructuredData.id);
      if (translation?.isNew) {
        toggleToast("1 translation created");
      }
    }

    toggleModal();
  };

  const editItemAction = async () => {
    let isUpdated = false;
    let toastString = "";
    if (isCategory(item)) {
      const updatedCategory: IStructuredDataCategory = {
        ...item,
        content: {
          title: form.name,
          code: form.code,
        },
      };

      isUpdated = await updateStructuredDataContent(updatedCategory);
      toastString = "1 category edited";
    }

    if (isCategoryGroup(item)) {
      const updatedGroup: ICategoryGroupParams = {
        structuredData: item.structuredData,
        title: form.name,
        selectable: form.selectable,
        entity: item.entity,
      };
      isUpdated = await updateCategoryGroup(item.id, updatedGroup);
      toastString = "1 grouping category edited";
    }

    if (isUpdated) {
      getContents(currentStructuredData.id);
      toggleToast(toastString);
    }

    toggleModal();
  };

  const addButton = {
    label: "Add",
    action: addItemAction,
    disabled: false,
  };

  const editButton = {
    label: "Update",
    action: editItemAction,
    disabled: false,
  };

  const translateTitle = translation?.isNew ? "Translate" : "Edit";
  const title = !item
    ? "New Item"
    : item.type === "category"
    ? `${translateTitle} Category`
    : `${translateTitle} Group`;

  const language = languages.find((l: ILanguage) => translation && l.locale === translation.lang.locale);

  const flag =
    language && translation ? (
      <>
        <S.FlagWrapper>
          <Flag name={language.locale} size="16" />
          <S.TranslationText>{`${language.language} translation of:`}</S.TranslationText>
        </S.FlagWrapper>
        <S.Translation>{item?.content.title}</S.Translation>
      </>
    ) : null;

  return (
    <FloatingPanel title={title} toggleModal={toggleModal} isOpen={isOpen}>
      <S.Wrapper>
        <div>{flag}</div>
        <Form edit={!!item} form={form} setForm={setForm} isTranslation={!!translation} />
        <S.Footer>
          {!item || translation?.isNew ? (
            <Button className="button" type="button" onClick={addButton.action} disabled={addButton.disabled}>
              {addButton.label}
            </Button>
          ) : (
            <Button className="button" type="button" onClick={editButton.action} disabled={editButton.disabled}>
              {editButton.label}
            </Button>
          )}
        </S.Footer>
      </S.Wrapper>
    </FloatingPanel>
  );
};

interface IStateProps {
  currentStructuredData: IStructuredData | null;
  languages: ILanguage[];
}

interface ICategoryPanelProps {
  item?: IStructuredDataCategory | ICategoryGroup;
  isOpen: boolean;
  translation?: { lang: { locale: string; id: number }; isNew: boolean } | null;
  toggleModal(): void;
  getContents(dataId: string): void;
  toggleToast(state: string): void;
}

export interface IFormState {
  name: string;
  code: string;
  type: "category" | "group";
  selectable: boolean;
}

const mapStateToProps = (state: IRootState) => ({
  currentStructuredData: state.structuredData.currentStructuredData,
  languages: state.app.globalLangs,
});

const mapDispatchToProps = {
  createStructuredDataContent: structuredDataActions.createStructuredDataContent,
  updateStructuredDataContent: structuredDataActions.updateStructuredDataContent,
  createCategoryGroup: structuredDataActions.createCategoryGroup,
  updateCategoryGroup: structuredDataActions.updateCategoryGroup,
};

interface IDispatchProps {
  createStructuredDataContent: (
    category: IStructuredDataContent | IStructuredDataCategory,
    langId?: number | null
  ) => Promise<boolean>;
  updateStructuredDataContent: (category: IStructuredDataContent | IStructuredDataCategory) => Promise<boolean>;
  createCategoryGroup: (data: ICategoryGroupParams) => Promise<boolean>;
  updateCategoryGroup: (groupID: number, data: ICategoryGroupParams) => Promise<boolean>;
}

type IProps = IDispatchProps & ICategoryPanelProps & IStateProps;

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPanel);
