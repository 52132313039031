import React, { memo } from "react";
import * as S from "./style";

const DragAndDrop = (props: any) => {
  const { onDrop, onDragOver, onDragEnter, onDragLeave, children }: IDragAndDropProps = props;

  const _handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.clearData();
  };

  const _handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onDragEnter(e);
  };

  const _handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onDragLeave(e);
  };

  const _handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onDragOver(e);
  };

  const _handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onDrop(e);
  };

  return (
    <S.DragDropWrapper
      data-testid="drag-drop-wrapper"
      onDrop={_handleDrop}
      onDragOver={_handleDragOver}
      onDragEnter={_handleDragEnter}
      onDragLeave={_handleDragLeave}
      onDragStart={_handleDragStart}
    >
      {children}
    </S.DragDropWrapper>
  );
};

export interface IDragAndDropProps {
  children: JSX.Element | JSX.Element[];
  onDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragEnter: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragLeave: (e: React.DragEvent<HTMLDivElement>) => void;
}

export default memo(DragAndDrop);
