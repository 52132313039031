import React, { useEffect, useRef, useState } from "react";

import { IGlobalSettings } from "@ax/containers/App/reducer";
import { Circle } from "@ax/components";
import LoginForm from "./LoginForm";
import LoginSSO from "./LoginSSO";

import * as S from "./style";

declare global {
  interface Window {
    handleErrorClick: () => void;
  }
}

const Login = (props: ILoginProps): JSX.Element => {
  const {
    handleSubmit,
    email,
    handleEmail,
    password,
    handlePassword,
    isLoggingIn,
    settings,
    rememberMe,
    handleRememberMe,
    isLoginSuccess,
    handleLoginSuccess,
    handleSSO,
    errorSSO,
  } = props;

  const [wrapperWidth, setWrapperWidth] = useState<number>(0);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => wrapperRef.current && setWrapperWidth(wrapperRef.current.clientWidth);

    window.addEventListener("resize", handleResize, false);

    if (wrapperRef.current) {
      setWrapperWidth(wrapperRef.current.clientWidth);
    }
  }, [wrapperRef]);

  const _handleAnimationEnd = () => handleLoginSuccess();

  return (
    <S.Wrapper data-testid="login-wrapper">
      <S.LeftWrapper className={isLoginSuccess ? "animate" : ""} onAnimationEnd={_handleAnimationEnd} ref={wrapperRef}>
        <S.LoginWrapper className={isLoginSuccess ? "animate" : ""} width={wrapperWidth}>
          {settings.SSOActivated ? (
            <LoginSSO handleSSO={handleSSO} errorSSO={errorSSO} />
          ) : (
            <LoginForm
              handleSubmit={handleSubmit}
              email={email}
              handleEmail={handleEmail}
              password={password}
              handlePassword={handlePassword}
              isLoggingIn={isLoggingIn}
              settings={settings}
              rememberMe={rememberMe}
              handleRememberMe={handleRememberMe}
            />
          )}
        </S.LoginWrapper>
      </S.LeftWrapper>
      <S.RightWrapper>
        <Circle animation={1} />
        <Circle animation={2} />
        <S.AnimatedLoginSlider className={isLoginSuccess ? "animate" : ""} />
      </S.RightWrapper>
    </S.Wrapper>
  );
};

export interface ILoginProps {
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  email: string;
  handleEmail: (email: string) => void;
  password: string;
  handlePassword: (password: string) => void;
  isLoggingIn: boolean;
  settings: IGlobalSettings;
  rememberMe: boolean;
  handleRememberMe: () => void;
  isLoginSuccess: boolean;
  handleLoginSuccess: () => void;
  handleSSO: () => void;
  errorSSO: string | null;
}

export default Login;
