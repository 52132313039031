import React, { memo } from "react";
import { Button, Icon } from "@ax/components";

import * as S from "./style";

const MultiplePanel = (props: IProps) => {
  const { activatePacks, uncheckAll } = props;

  return (
    <S.NoDetailsWrapper>
      <S.IconWrapper>
        <Icon name="grid" size="32" />
      </S.IconWrapper>
      <S.Title>You have selected several packages</S.Title>
      <S.Subtitle>To see the details, please select only one</S.Subtitle>
      <S.StyledButton type="button" buttonStyle="line" onClick={activatePacks}>
        Activate all packages
      </S.StyledButton>
      <Button type="button" buttonStyle="text" onClick={uncheckAll}>
        Unselect all
      </Button>
    </S.NoDetailsWrapper>
  );
};

interface IProps {
  activatePacks: () => void;
  uncheckAll: () => void;
}

export default memo(MultiplePanel);
