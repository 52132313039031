import styled from "styled-components";

const Wrapper = styled.div`
  padding: ${(p) => p.theme.spacing.m};
  height: 100%;
`;

const Footer = styled.div`
  position: absolute;
  bottom: ${(p) => p.theme.spacing.m};
  right: ${(p) => p.theme.spacing.m};
`;

const Divider = styled.div`
  position: relative;
  height: 1px;
  margin-top: ${(p) => p.theme.spacing.s};
  margin-bottom: ${(p) => p.theme.spacing.s};

  &:after {
    content: "";
    position: absolute;
    height: 1px;
    width: calc(100% + ${(p) => p.theme.spacing.m} * 2);
    top: 0;
    left: calc(-${(p) => p.theme.spacing.m});
    border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
  }
`;

const Heading = styled.div<{ $spaceBetween?: boolean }>`
  display: flex;
  justify-content: ${(p) => p.$spaceBetween ? "space-between" : "flex-start"};
`;

const HeadingText = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  color: ${(p) => p.theme.color.textHighEmphasis};
`;

const HeadingCounter = styled.div`
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-left: ${(p) => p.theme.spacing.xs};
`;

const Description = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textHighEmphasis};
  padding-bottom: ${(p) => p.theme.spacing.s};
`;

const RadioTemplate = styled.div``;

const ContentWrapper = styled.div`
  height: calc(100% - ${(p) => p.theme.spacing.s} * 10);
  overflow-y: scroll;
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
  position: absolute;
  left: 0;
  padding: 0 ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
`;

export {
  Wrapper,
  Footer,
  Divider,
  Heading,
  HeadingText,
  HeadingCounter,
  Description,
  RadioTemplate,
  ContentWrapper
};
