import * as React from "react";
const SvgCodeBody = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#5057FF"
      fillRule="evenodd"
      d="M7.5 21c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 5.5 19v-1a1 1 0 1 1 2 0 1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-12a1 1 0 0 0-1 1v3a1 1 0 0 1-2 0V5c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 7.5 3h14c.55 0 1.02.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 21.5 21h-14Zm-4.6-7.825L4.375 11.7c.2-.2.3-.433.3-.7 0-.267-.1-.5-.3-.7-.2-.2-.438-.3-.712-.3-.275 0-.513.1-.713.3L.775 12.475c-.1.1-.17.208-.213.325a1.107 1.107 0 0 0-.062.375c0 .133.02.258.063.375a.877.877 0 0 0 .212.325L2.95 16.05c.2.2.438.3.712.3.276 0 .513-.1.713-.3.2-.2.3-.433.3-.7 0-.267-.1-.5-.3-.7L2.9 13.175Zm7.35 0L8.775 14.65c-.2.2-.3.433-.3.7 0 .267.1.5.3.7.2.2.438.3.713.3.274 0 .512-.1.712-.3l2.175-2.175c.1-.1.17-.208.213-.325.041-.117.062-.242.062-.375s-.02-.258-.063-.375a.877.877 0 0 0-.212-.325L10.2 10.3a.999.999 0 0 0-1.425 0c-.2.2-.3.433-.3.7 0 .267.1.5.3.7l1.475 1.475Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCodeBody;
