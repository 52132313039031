import React, { useEffect, useState } from "react";

import { isReqOk } from "@ax/helpers";
import { selects, checkgroups } from "@ax/api";
import { ISite } from "@ax/types";

import * as S from "./style";

const AsyncSelect = (props: IAsyncSelectProps): JSX.Element => {
  const {
    name,
    value,
    error,
    disabled,
    onChange,
    entity,
    entityId,
    site,
    lang,
    selectedContent,
    mandatory,
    placeholder,
    filter,
    options,
    source,
    type,
    maxWidth,
  } = props;

  const initialState: { items: IOption[]; hasEmptyOption: boolean } = {
    items: [],
    hasEmptyOption: true,
  };
  const [state, setState] = useState(initialState);

  const isPage = entity === "pages";
  const isCategories = entity === "categories";
  useEffect(() => {
    const getItems = async () => {
      let data = [];
      try {
        if (entity) {
          let result = null;
          if (site && !isCategories) {
            result =
              isPage && selectedContent
                ? await selects.getSelectSiteItems(site.id, entity, options, selectedContent.id)
                : await selects.getSelectSiteItems(site.id, entity, options);
          } else {
            result = await selects.getSelectItems(entity, entityId);
          }

          if (result && isReqOk(result.status)) {
            data = result.data;
          }
        }

        if (source) {
          const siteID = site ? site.id : null;
          const result = await checkgroups.getCheckGroupItems(siteID, source);
          if (result && isReqOk(result.status)) {
            data = result.data.map((opt: ICheck) => {
              return { value: opt.value, label: opt.title };
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
      return data;
    };

    let isMounted = true;
    getItems()
      .then((result: IOption[]) => {
        const label = placeholder || "Empty";
        const emptyOption = { value: null, label };
        const optionValues: IOption[] = mandatory ? result : [emptyOption, ...result];
        const filteredOptions: IOption[] = filter
          ? optionValues.filter((e: IOption) => !filter.includes(e.value))
          : optionValues;
        if (isMounted) setState((state) => ({ ...state, items: filteredOptions }));
      })
      .catch((apiError) => console.log(apiError));
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, site, lang, selectedContent, entityId, mandatory, placeholder, filter, isCategories, isPage, options]);

  const handleChange = (selectedValue: ICheck | IOption | null) => {
    const newValue = selectedValue ? selectedValue.value : selectedValue;
    onChange(newValue);
  };

  const filterOptions = (inputValue: string) => {
    return state.items.filter((i: IOption) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, "");
    const hasEmptyOption = inputValue.length === 0;
    setState((state) => ({ ...state, hasEmptyOption }));
    return inputValue;
  };

  const loadOptions = (inputValue: string, callback: any) => {
    setTimeout(() => {
      callback(filterOptions(inputValue));
    }, 0);
  };

  // tslint:disable-next-line: no-shadowed-variable
  const getObjectValue = (val: any, options: IOption[]) => {
    const fixedVal = Array.isArray(val) ? val[0] : val;
    const selectedValue = fixedVal && typeof fixedVal === "object" ? fixedVal.id : fixedVal;

    return options && options.find((option: IOption) => option.value === selectedValue);
  };

  const className = error ? `react-select-error ${type}` : type;
  const searchable = type === "inline" ? false : true;

  return (
    <div data-testid="asyncSelect">
      <S.StyledSelect
        name={name}
        value={getObjectValue(value, state.items) || ""}
        cacheOptions
        loadOptions={loadOptions}
        defaultOptions={state.items}
        onInputChange={handleInputChange}
        onChange={handleChange}
        classNamePrefix="react-select"
        error={error}
        isDisabled={disabled}
        className={className}
        mandatory={mandatory}
        hasEmptyOption={state.hasEmptyOption}
        isSearchable={searchable}
        maxWidth={maxWidth}
      />
    </div>
  );
};

interface IOption {
  value: number | string | null;
  label: string;
}

interface ICheck {
  value: number | string | null;
  name: string;
  title: string;
}
export interface IAsyncSelectProps {
  name: string;
  value?: string | number | null;
  entity?: string;
  entityId?: string | number;
  error?: boolean;
  disabled?: boolean;
  onChange: (value: string | number | null) => void;
  site?: ISite | null;
  lang?: string;
  selectedContent?: any;
  mandatory?: boolean;
  placeholder?: string;
  filter?: any[];
  options?: any[];
  source?: string;
  type?: string;
  maxWidth?: number;
}

export default AsyncSelect;
