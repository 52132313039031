import React from "react";

import { NoteField, Tabs } from "@ax/components";
import { IUserEditing } from "@ax/types";

import * as S from "./style";

const PreviewForm = (props: IPreviewFormProps): JSX.Element => {
  const { selectedTab, setSelectedTab, userEditing } = props;
  const tabs = ["content"];

  const noteText = `${
    userEditing && userEditing.name
  } is currently working on this page. You can preview the page but you cannot make changes to it until ${
    userEditing && userEditing.name
  } leaves the page.`;

  const noteTitle = "This page is currently being edited";

  return (
    <section>
      <Tabs tabs={tabs} active={selectedTab} setSelectedTab={setSelectedTab} />
      <S.FieldWrapper data-testid="preview-form-wrapper">
        <NoteField value={{ text: noteText, title: noteTitle }} />
      </S.FieldWrapper>
    </section>
  );
};

export interface IPreviewFormProps {
  selectedTab: string;
  setSelectedTab(tab: string): void;
  userEditing?: IUserEditing | null;
}

export default PreviewForm;
