import * as React from "react";

export type UseFetchStateProps<T> = {
	isError: boolean;
	isLoading: boolean;
	msg: { code: number; msg: string };
	data?: T;
	isFirstFetch: boolean;
};

export function useFetch<DataStructure>(
	url?: string,
	options?: {
		method: "POST" | "GET";
		body: unknown | null;
	},
) {
	const { method, body } = options || {};
	const [data, setData] = React.useState<DataStructure>();
	const [isLoading, setIsLoading] = React.useState(true);
	const [isError, setIsError] = React.useState(false);
	const [isFirstFetch, setIsFirstFetch] = React.useState(true);
	const [msg, setMsg] = React.useState(
		{} as UseFetchStateProps<DataStructure>["msg"],
	);

	React.useEffect(() => {
		if (!url) return;
		if (method === "POST" && !body) return; // With POST, useFetch needs a body.

		setIsLoading(true);

		fetch(url, {
			method: method || "GET",
			body: JSON.stringify(body),
			headers: { "Content-Type": "application/json" },
		})
			.then((response) => {
				setIsFirstFetch(false);
				if (response.status !== 200) {
					setIsLoading(false);
					setIsError(true);
					setMsg({
						code: response.status,
						msg: response.statusText,
					});
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then((data: DataStructure & { code: number; msg: string }) => {
				setData(data);
			})
			.finally(() => {
				setIsLoading(false);
				setIsError(false);
			})
			.catch(() => {
				setIsError(true);
			});
	}, [url, body]);

	return { data, isLoading, isError, isFirstFetch, msg };
}
