import * as React from "react";
const SvgClosePanel = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#5057FF"
      fillRule="evenodd"
      d="M3 20V4c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2v16c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2Zm12 0V4H5v16h10ZM11 9l.705.705L9.415 12l2.29 2.295L11 15l-3-3 3-3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgClosePanel;
