import React from "react";

import { ICheck } from "@ax/types";
import CheckField, { ICheckFieldProps } from "../CheckField";

import * as S from "./style";

const CheckGroup = (props: ICheckGroupProps): JSX.Element => {
  const { options, onChange, value, selectAllOption, multipleSelection = true, disabled } = props;

  let valueArray = !value ? [] : typeof value === "string" ? value.split(",") : value;
  valueArray = valueArray.filter((val: string | number) => options?.some((option) => option.name === val));

  const handleChange = (newValue: ICheck) => {
    const index = valueArray.indexOf(newValue.value);

    if (index >= 0 && !newValue.isChecked) {
      valueArray.splice(index, 1);
    }

    if (index < 0 && newValue.isChecked) {
      valueArray.push(newValue.value);
    }

    if (selectAllOption) {
      const valueArrayNoAll = valueArray.filter((item: string | number) => item !== selectAllOption);
      const uniqueOption = options && options.length === 2;
      const isAllSelected =
        newValue.value === selectAllOption ||
        (options && valueArrayNoAll.length === options.length - 1 && !uniqueOption);

      valueArray = isAllSelected ? [selectAllOption] : valueArrayNoAll;
    }

    if (!multipleSelection) {
      valueArray = [newValue.value];
    }

    const valueString = typeof value === "string" ? valueArray.join(",") : valueArray;
    onChange(valueString);
  };

  const checks = options
    ? options.map((item: ICheckFieldProps) => (
        <CheckField
          data-testid="check-group-checkfield"
          key={item.name}
          onChange={handleChange}
          checked={valueArray.includes(item.name)}
          value={item.name}
          title={item.title}
          name={item.name}
          disabled={item.disabled || disabled}
          error={item.error}
          icon={item.icon}
        />
      ))
    : "";

  return <S.FieldGroup data-testid="check-group-field-group">{checks}</S.FieldGroup>;
};

interface ICheckGroupProps {
  value: string | (string | number)[] | null | undefined;
  options: ICheckFieldProps[] | undefined;
  onChange: (value: string | (string | number)[]) => void;
  selectAllOption?: string;
  multipleSelection?: boolean;
  disabled?: boolean;
}

export default CheckGroup;
