import { useState } from "react";
import { IQueryValue, ISite } from "@ax/types";

const useSortedListStatus = () => {
  const sortedInitialState: { isAscending: boolean; sortedByDate: boolean } = {
    isAscending: false,
    sortedByDate: false,
  };

  const [sortedListStatus, setSortedListStatus] = useState(sortedInitialState);

  return {
    sortedListStatus,
    setSortedListStatus,
  };
};

const useFilterQuery = (site: ISite | null) => {
  const initialQueryValues: Record<string, IQueryValue[]> = {
    sites: site ? [{ value: site.id, label: site.name }] : [{ value: "all", label: "All" }],
    order: [],
  };

  const [query, setQuery] = useState(initialQueryValues);
  const [currentFilterQuery, setCurrentFilterQuery] = useState(`&sites=${site?.id || "global"}`);

  const setFilterQuery = (filterValues: any) => {
    const { sites, order } = filterValues;
    let filterQuery = "";

    const currentQuery = (pointer: string, values: IQueryValue[]): string => {
      const stringValues = Array.isArray(values)
        ? values.map((value) => (value.value !== "all" ? value.value : "")).join(",")
        : "";

      return !stringValues.length ? filterQuery : filterQuery.concat(`&${pointer}=${stringValues}`);
    };

    if (sites) {
      filterQuery = currentQuery("sites", sites);
    }

    if (order) {
      filterQuery = currentQuery("order", order);
    }

    setCurrentFilterQuery(filterQuery);
  };

  const setFiltersSelection = (pointer: string, filter: IQueryValue[], isAscendent?: boolean) => {
    const { sites, order } = query;
    const orderMethod = isAscendent ? "asc" : "desc";
    const filterValues: Record<string, IQueryValue[]> = {
      sites: pointer === "sites" ? filter : sites,
      order: pointer === "order" ? [{ value: `${filter[0].value}-${orderMethod}`, label: filter[0].label }] : order,
    };

    setQuery(filterValues);
    setFilterQuery(filterValues);
  };

  const resetFilterQuery = () => {
    setQuery(initialQueryValues);
    setCurrentFilterQuery("");
  }

  return {
    setFiltersSelection,
    resetFilterQuery,
    filterValues: query,
    filterQuery: currentFilterQuery,
  };
};

export { useSortedListStatus, useFilterQuery };
