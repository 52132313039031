export const NAME = "navigation";

export const SET_EDITOR_CONTENT = `${NAME}/SET_EDITOR_CONTENT`;
export const SET_BREADCRUMB = `${NAME}/SET_BREADCRUMB`;
export const SET_SCHEMA = `${NAME}/SET_SCHEMA`;
export const SET_SELECTED_CONTENT = `${NAME}/SET_SELECTED_CONTENT`;
export const SET_SELECTED_DEFAULT = `${NAME}/SET_SELECTED_DEFAULT`;
export const SET_SELECTED_PARENT = `${NAME}/SET_SELECTED_PARENT`;
export const SET_TAB = `${NAME}/SET_TAB`;
export const SET_SELECTED_EDITOR_ID = `${NAME}/SET_SELECTED_ID`;
export const SET_HEADER = `${NAME}/SET_HEADER`;
export const SET_FOOTER = `${NAME}/SET_FOOTER`;
export const SET_DEFAULTS_CONTENT = `${NAME}/SET_DEFAULTS_CONTENT`;
export const SET_TOTAL_ITEMS = `${NAME}/SET_TOTAL_ITEMS`;
export const SET_CURRENT_NAVIGATION_LANGUAGES = `${NAME}/SET_CURRENT_NAVIGATION_LANGUAGES`;
export const SET_IS_NEW_TRANSLATION = `${NAME}/SET_IS_NEW_TRANSLATION`;
export const SET_COPY_MODULE = `${NAME}/SET_COPY_MODULE`;
export const SET_NAV_PAGES = `${NAME}/SET_NAV_PAGES`;

export const ITEMS_PER_PAGE = 50;
