import React from "react";
import { connect } from "react-redux";

import { ILanguage, IRootState } from "@ax/types";

import Item from "./Item";
import Header from "./Header";

import * as S from "./style";

const Table = (props: IStateProps): JSX.Element => {
  const { items } = props;

  const mapItemList = (menu: any) =>
    menu && menu.map((item: ILanguage, i: number) => <Item key={`${item.label}${i}`} item={item} />);

  return (
    <S.Table>
      <Header items={items} />
      {items && mapItemList(items)}
    </S.Table>
  );
};

const mapStateToProps = (state: IRootState) => ({
  items: state.sites.currentSiteLanguages,
});

interface IStateProps {
  items: ILanguage[];
}

export default connect(mapStateToProps)(Table);
