import { SET_SOCIAL } from "./constants";

import { ISocialState } from "@ax/types";

import { SocialActionsCreators } from "./interfaces";

export const initialState = {};

export function reducer(state = initialState, action: SocialActionsCreators): ISocialState {
  switch (action.type) {
    case SET_SOCIAL:
      return { ...action.payload };
    default:
      return state;
  }
}

export { initialState as socialInitialState, reducer as socialReducer };
