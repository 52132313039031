import styled from "styled-components";

import { Cell, Row } from "@ax/components/TableList/TableItem/style";

const RoleRow = styled(Row as any)<{ disabled: boolean }>``;

const CheckCell = styled(Cell)`
  justify-content: flex-start;
  padding-right: 0;
  padding-left: ${(p) => p.theme.spacing.m};
  label {
    margin-bottom: ${(p) => p.theme.spacing.s};
  }
  > div {
    width: ${(p) => p.theme.spacing.s};
  }
`;

const RoleCell = styled(Cell)`
  width: 400px;
  flex-direction: column;
  justify-content: flex-start;
`;

const Name = styled.div`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => p.theme.colors.textHighEmphasis};
`;

const Description = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  width: 400px;
`;

const AvatarCell = styled(Cell)`
  position: relative;
  align-items: center;
  justify-content: center;
  width: 10%;
`;

const UsersLength = styled.span`
  ${(p) => p.theme.textStyle.uiS};
  position: absolute;
  left: 110px;
`;

const AvatarsWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  div {
    position: absolute;
    &:nth-child(1) {
      position: absolute;
      z-index: 5;
    }
    &:nth-child(2) {
      position: absolute;
      z-index: 4;
      left: 20px;
    }
    &:nth-child(3) {
      position: absolute;
      z-index: 3;
      left: 40px;
    }
    &:nth-child(4) {
      position: absolute;
      z-index: 2;
      left: 60px;
    }
    &:nth-child(5) {
      position: absolute;
      z-index: 1;
      left: 80px;
    }
  }
`;

const Status = styled(Cell)`
  display: flex;
  justify-content: center;
`;

const Permissions = styled(Cell)<{ isSiteView: boolean }>`
  text-align: center;
  flex-grow: 1;
`;

const AvatarsTooltip = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  width: 224px;
  border-radius: ${(p) => p.theme.radii.s};
  padding: ${(p) => p.theme.spacing.s};
  box-shadow: ${(p) => p.theme.shadow.shadowL};
  z-index: 6;
  background: ${(p) => p.theme.color.uiBackground02};
  top: 60%;
  left: 50%;
`;

const AvatarContainer = styled.div<{ addSpacing: boolean }>`
  padding: ${(p) => (p.addSpacing ? p.theme.spacing.xxs : 0)};
`;

const ActionsCell = styled(Cell)``;

export {
  CheckCell,
  RoleCell,
  Name,
  Description,
  RoleRow,
  Status,
  Permissions,
  UsersLength,
  AvatarCell,
  AvatarsWrapper,
  AvatarsTooltip,
  AvatarContainer,
  ActionsCell,
};
