import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";

import { ErrorGuard } from "@ax/guards";
import { IRootState, IUser } from "@ax/types";
import { appActions } from "@ax/containers/App";
import { publicRoutes, privateRoutes, routes, IRouter } from "@ax/routes";
import { ILogoutAction } from "@ax/containers/App/interfaces";
import { ErrorPage } from "@ax/components";
import { usePermission } from "@ax/hooks";
import NavMenu from "./NavMenu";
import PrivateRoute from "./PrivateRoute";
import Logout from "./Logout";

import * as S from "./style";

const Routing = (props: IProps) => {
  const { path, token, currentUser, setHistoryPush, logout } = props;

  useEffect(() => {
    path === "/" && setHistoryPush("/login");

    if (path === "/login" || path === "/") {
      const loggedInUser = localStorage.getItem("user");
      if (loggedInUser) {
        setHistoryPush("/sites", false);
      } else {
        logout();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const route: IRouter | undefined = routes.find((item: IRouter) => {
    const cleanPath = path && path.replace(/\/$/, "");
    return (
      item.path === cleanPath ||
      (item.routesGroups &&
        item.routesGroups.find((subitem) => {
          return subitem.routes.find((subroute) => subroute.path === path);
        }))
    );
  });

  const isAllowed = usePermission(route?.permission);

  if (route && !!currentUser && !isAllowed) {
    route.hideNav = true;
  }

  const getRoute = (component: any, path: string, isPrivate: boolean, permission: string | string[] | undefined) =>
    isPrivate ? (
      <PrivateRoute
        token={token}
        path={path}
        component={component}
        permission={permission}
        currentUser={currentUser}
        exact
      />
    ) : (
      <Route path={path} component={component} exact />
    );

  const mapSubroutes = (subgroup: any, isPrivate: boolean) => {
    return subgroup.routes.map((subRoute: any, subI: number) => (
      <React.Fragment key={subI}>
        {getRoute(subRoute.component, subRoute.path, isPrivate, subRoute.permission)}
      </React.Fragment>
    ));
  };

  const mapRoutes = (routeArr: IRouter[], isPrivate: boolean) =>
    routeArr.map((singleRoute: IRouter, i) => (
      <React.Fragment key={i}>
        {getRoute(singleRoute.component, singleRoute.path, isPrivate, singleRoute.permission)}
        {singleRoute.routesGroups && singleRoute.routesGroups.map((subgroup: any) => mapSubroutes(subgroup, isPrivate))}
      </React.Fragment>
    ));

  return (
    <>
      {route && !route.hideNav && <NavMenu />}
      <S.Main>
        <ErrorGuard />
        <Switch>
          <Route path="/">
            {mapRoutes(publicRoutes, false)}
            {mapRoutes(privateRoutes, true)}
            <Route path="/logout" exact component={Logout} />
            <Route path="/500" exact render={() => <ErrorPage type="500" />} />
            <Route path="/404" render={() => <ErrorPage type="404" />} />
          </Route>
        </Switch>
      </S.Main>
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  path: state.router.location.pathname,
  token: state.app.token,
  currentUser: state.users.currentUser,
});

interface IDispatchProps {
  setHistoryPush(path: string, isEditor?: boolean): Promise<void>;
  logout(): ILogoutAction;
}

interface IStateProps {
  path?: string;
  token: string;
  currentUser: IUser | null;
}

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
  logout: appActions.logout,
};

type IProps = IStateProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(Routing);
