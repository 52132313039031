import * as React from "react";
const SvgBack = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#5057FF"
      d="M7 19v-2h7.1c1.05 0 1.963-.333 2.737-1 .776-.667 1.163-1.5 1.163-2.5s-.387-1.833-1.163-2.5c-.774-.667-1.687-1-2.737-1H7.8l2.6 2.6L9 14 4 9l5-5 1.4 1.4L7.8 8h6.3c1.617 0 3.004.525 4.162 1.575C19.421 10.625 20 11.933 20 13.5c0 1.567-.58 2.875-1.738 3.925C17.104 18.475 15.717 19 14.1 19H7Z"
    />
  </svg>
);
export default SvgBack;
