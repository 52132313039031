import styled from "styled-components";

export const FieldWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.s};
`

export const Title = styled.span`
  ${p => p.theme.textStyle.headingXS};
  border-bottom: 1px solid ${p => p.theme.color.uiLine};
  text-transform: uppercase;
  margin-top: ${(p) => p.theme.spacing.s};
  padding: ${(p) => p.theme.spacing.s};
  display: flex;
  justify-content: center;
  color: ${p => p.theme.color.textHighEmphasis};
`