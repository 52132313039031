import { IDimension, IDimensionsGroup } from "@ax/types";
import { SET_ANALYTICS } from "./constants";
import { AnalyticsActionsCreators } from "./interfaces";

export interface IAnalyticsState {
  scriptCode: string;
  siteScriptCodeExists: boolean;
  dimensions: IDimension[];
  groups: IDimensionsGroup[];
}

export const initialState = {
  scriptCode: "",
  siteScriptCodeExists: false,
  dimensions: [],
  groups: [],
};

export function reducer(state = initialState, action: AnalyticsActionsCreators): IAnalyticsState {
  switch (action.type) {
    case SET_ANALYTICS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export { initialState as analyticsInitialState, reducer as analyticsReducer };
