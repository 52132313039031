import styled from "styled-components";
import { Button } from "@ax/components";

const Heading = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  color: ${(p) => p.theme.color.textHighEmphasis};
  padding-bottom: ${(p) => p.theme.spacing.xs};
`;

const Config = styled.div`
  border-top: 1px solid ${(p) => p.theme.color.uiLine};
  padding: ${(p) => p.theme.spacing.m} 0;
  width: calc(${(p) => p.theme.spacing.l} * 15);
`;

const ModalContent = styled.div`
  padding: ${(p) => `${p.theme.spacing.m} ${p.theme.spacing.m} 0 ${p.theme.spacing.m}`};
`;

const SubscribeWrapper = styled.div`
  position: relative;
`;

const SectionContent = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const Text = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  width: calc(${(p) => p.theme.spacing.l} * 12);
  padding-bottom: ${(p) => p.theme.spacing.m};
`;

const CategoriesWrapper = styled.div<{isEmpty: boolean}>`
  padding-bottom: ${(p) => p.isEmpty ? 0 : p.theme.spacing.m};
`;

const StyledButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
`;

export { Heading, Config, ModalContent, SubscribeWrapper, SectionContent, Text, StyledButton, CategoriesWrapper };
