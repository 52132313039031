import React from "react";

const Menu = props => (
  <svg width={24} height={24} fill="none" {...props}>
    <path 
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 16v2h20v-2H2zm0-5v2h20v-2H2zm0-5v2h20V6H2z"
      fill="#5057FF"
    />
  </svg>
);

export default Menu;
