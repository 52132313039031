import React from "react";
import { Icon, ErrorToast } from "@ax/components";

import * as S from "./style";

const LoginSSO = (props: ILoginSSOProps): JSX.Element => {
  const { handleSSO, errorSSO } = props;

  const handleClick = () => handleSSO();

  return (
    <S.Wrapper>
      <S.Main>
        <S.Logo>
          <img src="/img/logos/logoGriddoExtended@3x.svg" alt="Griddo Logo" />
        </S.Logo>
        <S.Welcome>
          Wel
          <br />
          come
        </S.Welcome>
        <S.StartWrapper>
          <ErrorToast />
          {errorSSO ? (
            <>
              <S.StyledNotification text={errorSSO} type="error" closeButton={false} />
              <S.StyledButton type="button" iconFill={false} onClick={handleClick}>
                <S.ContentButton>
                  <S.TextButton>Try again</S.TextButton>
                </S.ContentButton>
              </S.StyledButton>
            </>
          ) : (
            <>
              <S.Text>
                To start using Griddo, login with your
                <br />
                autentication platform
              </S.Text>
              <S.StyledButton type="button" iconFill={false} onClick={handleClick}>
                <S.ContentButton>
                  <S.IconButton>
                    <Icon name="microsoft" size="16" viewBox={16} />
                  </S.IconButton>
                  <S.TextButton>Microsoft ID</S.TextButton>
                </S.ContentButton>
              </S.StyledButton>
            </>
          )}
        </S.StartWrapper>
      </S.Main>
      <S.Secuoyas>
        Made with care at{" "}
        <a href="https://www.secuoyas.com/" target="_blank" rel="noopener noreferrer">
          <img src="/img/logos/logoSQY.svg" alt="Secuoyas logo" />
        </a>
      </S.Secuoyas>
    </S.Wrapper>
  );
};

interface ILoginSSOProps {
  handleSSO: () => void;
  errorSSO: string | null;
}

export default LoginSSO;
