import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;
  width: 100%;
`;

const LanguageListWrapper = styled.div`
  display: flex;
  height: ${(p) => `calc(100vh - ${p.theme.spacing.xl} - (${p.theme.spacing.m} * 2))`};
  padding: ${(p) => `${p.theme.spacing.m} 0 ${p.theme.spacing.m} ${p.theme.spacing.m}`};
`;

const ItemsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing.xs};
  width: 100%;
`;

const Counter = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textHighEmphasis};
`;

export { Wrapper, ContentWrapper, LanguageListWrapper, ItemsHeader, Counter }
