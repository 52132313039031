import React from "react";

const Video = props => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      fill="#5057FF"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 16.5l6-4.5-6-4.5zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
    />
  </svg>
);

export default Video;
