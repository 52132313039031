import type { Fields } from "..";

import { useReferenceFieldData } from "./useReferenceFieldData";

/**
 * `useContentType<ContentType>()`
 *
 * Returns an array of the ContentType data.
 * The hook abstracts the dual behaviour of the ContentTypes inside Griddo and it will always returns the same value and type regardless the context: editor or build phase.
 * Also the hook accepts a generic type to idicates the return type of the hook.
 *
 * @param options.data The property of the ReferenceField.
 * @param options.queriedData The property returned by the build phase with the data queried.
 * @example
 * useContentType<NewsContentTypeProps>({ data, queriedItems })
 */
function useContentType<ContentType>({
	data,
	queriedData,
}: {
	/** The ReferenceField from the module schema */
	data?: Fields.Reference<ContentType>;
	/** The queriedData prop from the building phase */
	queriedData?: Fields.QueriedData<ContentType>;
}) {
	const dataItems =
		(data && useReferenceFieldData<ContentType>(data)) || queriedData;

	return dataItems;
}

export { useContentType };
