import styled from "styled-components";

import { Cell, Row } from "@ax/components/TableList/TableItem/style";
import { ActionMenu } from "@ax/components";

const CheckCell = styled(Cell)`
  padding-right: 0;
  padding-left: ${(p) => p.theme.spacing.m};
  label {
    margin-bottom: ${(p) => p.theme.spacing.s};
  }
  > div {
    width: ${(p) => p.theme.spacing.s};
  }
`;

const NameCell = styled(Cell)`
  flex-grow: 1;
  svg {
    height: ${(p) => p.theme.spacing.s};
    path {
      fill: ${(p) => p.theme.color.iconMediumEmphasis};
    }
  }
`;

const Title = styled.div<{ width: number }>`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => p.theme.color.textHighEmphasis};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: ${(p) => `${p.width}px`};
`;

const Slug = styled.div<{ width: number }>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: ${(p) => `${p.width}px`};
`;

const TypeCell = styled(Cell)`
  flex: 0 0 170px;
  align-items: center;
`;

const LiveCell = styled(Cell)`
  flex: 0 0 80px;
  align-items: center;
  svg {
    width: ${(p) => p.theme.spacing.m};
    height: ${(p) => p.theme.spacing.m};
  }
`;

const StatusCell = styled(Cell)`
  flex: 0 0 170px;
  align-items: center;
  div:nth-child(2) {
    margin-top: ${(p) => p.theme.spacing.xs};
  }
`;

const TransCell = styled(Cell)`
  ${(p) => p.theme.textStyle.uiXS};
  flex: 0 0 115px;
  position: relative;
  align-items: center;
`;

const SeoCell = styled(Cell)`
  ${(p) => p.theme.textStyle.uiXS};
  flex: 0 0 124px;
`;

const GlobalCell = styled(Cell)`
  flex: 0 0 24px;
  flex-shrink: 0;
  padding-left: 0;
  padding-right: 0;
`;

const Mark = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textHighEmphasis};
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: ${(p) => p.theme.color.uiBackground03};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Home = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  width: 63px;
  height: 20px;
  margin-bottom: 8px;
  background-color: ${(p) => p.theme.color.uiBackground03};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 10px 2px 4px;
  word-break: normal;
`;

const Wrapper = styled.div`
  cursor: pointer;
`;

const ActionsCell = styled(Cell)`
  flex: 0 0 85px;
  padding-left: 0;
`;

const StyledActionMenu = styled(ActionMenu)`
  opacity: 0;
  width: 32px;
  display: flex;
  margin-left: auto;
`;

const PageRow = styled(Row)<{ global?: boolean }>`
  position: relative;
  &:hover {
    ${TypeCell}, ${SeoCell} {
      color: ${(p) => p.theme.color.textHighEmphasis};
    }
    ${StyledActionMenu} {
      opacity: 1;
    }
  }
  ${TypeCell}, ${SeoCell} {
    color: ${(p) => (p.selected ? p.theme.color.textHighEmphasis : p.theme.color.textMediumEmphasis)};
  }
`;

const StyledSeo = styled.div`
  margin-bottom: 4px;
  :hover {
    color: ${(p) => p.theme.color.interactive01};
  }
`;

const StyledDone = styled.span`
  display: inline-block;
  line-height: 0;
  margin-right: 2px;
  svg {
    margin-bottom: -4px;
    width: ${(p) => p.theme.spacing.s};
    height: ${(p) => p.theme.spacing.s};
    path {
      fill: #afc628;
    }
  }
`;

const StyledClose = styled.span`
  display: inline-block;
  line-height: 0;
  margin-right: 2px;
  svg {
    margin-bottom: -4px;
    width: ${(p) => p.theme.spacing.s};
    height: ${(p) => p.theme.spacing.s};
    path {
      fill: ${(p) => p.theme.color.error};
    }
  }
`;

const ModDate = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textLowEmphasis};
`;

const FlagsWrapper = styled.div`
  svg {
    margin-right: 2px;
    margin-bottom: -3px;
    display: inline;
    vertical-align: baseline;
  }
`;

const FloatingSeo = styled.div`
  padding: ${(p) => `${p.theme.spacing.xs} ${p.theme.spacing.s}`};
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};
`;

export {
  CheckCell,
  NameCell,
  Title,
  Slug,
  TypeCell,
  LiveCell,
  StatusCell,
  TransCell,
  SeoCell,
  Wrapper,
  ActionsCell,
  StyledActionMenu,
  PageRow,
  StyledSeo,
  StyledDone,
  StyledClose,
  ModDate,
  FlagsWrapper,
  FloatingSeo,
  ModalContent,
  GlobalCell,
  Mark,
  Home,
};
