import React, { useEffect, useState } from "react";
import { FloatingMenu, Icon, ListTitle, CheckGroup } from "@ax/components";
import { areEquals } from "@ax/helpers";
import { IFilterValue, IQueryValue } from "@ax/types";

import * as S from "./style";

const Type = (props: ITypeProps): JSX.Element => {
  const { filterItems, value } = props;

  const initialState = ["all"];
  const selectAllOption = "all";
  const pointer = "filterType";
  const arrayValues = Array.isArray(value) && value.length ? value.map((val) => val.value) : initialState;
  const [selectedValue, setSelectedValue] = useState(arrayValues);

  const filters = [
    { name: "all", value: "all", title: "ALL" },
    { name: "pdf", value: "pdf", title: "PDF" },
    { name: "doc", value: "doc", title: "DOC" },
    { name: "xls", value: "xls", title: "XLS" },
    { name: "csv", value: "csv", title: "CSV" },
    { name: "zip", value: "zip", title: "ZIP" },
    { name: "txt", value: "txt", title: "TXT" },
  ];

  useEffect(() => {
    const arrayValues = Array.isArray(value) && value.length ? value.map((val) => val.value) : initialState;
    setSelectedValue(arrayValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const setQuery = (selection: any) => {
    if (!selection.length) {
      selection = initialState;
    }
    setSelectedValue(selection);
    const queryFilters: IQueryValue[] = selection.map((value: string | number) => {
      const label = filters.find((opt: IFilterValue) => opt.value === value);
      return { value, label: label?.title || "" };
    });
    filterItems(pointer, queryFilters);
  };

  const isActive = !areEquals(selectedValue, initialState);

  const Header = () => (
    <S.Type data-testid="type-wrapper" isActive={isActive}>
      File type
      <S.IconsWrapper>
        {isActive && <Icon name="Filter" size="16" />}
        <S.InteractiveArrow>
          <Icon name="DownArrow" size="16" />
        </S.InteractiveArrow>
      </S.IconsWrapper>
    </S.Type>
  );

  return (
    <FloatingMenu Button={Header} position="center" closeOnSelect={false}>
      <ListTitle>Filter by type</ListTitle>
      <S.ChecksWrapper>
        <CheckGroup
          options={filters}
          value={selectedValue}
          onChange={setQuery}
          selectAllOption={selectAllOption}
          multipleSelection={true}
        />
      </S.ChecksWrapper>
    </FloatingMenu>
  );
};

export interface ITypeProps {
  filterItems(pointer: string, filter: IQueryValue[]): void;
  value?: IQueryValue[];
}

export default Type;
