import styled from "styled-components";

const DefaultListWrapper = styled.div`
  display: flex;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: calc(100vh - ${(p) => p.theme.spacing.xl});
  overflow: auto;
`;

const PaginationWrapper = styled.span`
  display: flex;
  justify-content: flex-end;
  margin-right: ${(p) => p.theme.spacing.m};
  margin-top: ${(p) => p.theme.spacing.m};
`;

const NavigationModulesWarning = styled.div`
  padding: ${(p) => p.theme.spacing.m};
  border-bottom: ${(p) => `1px solid ${p.theme.color.uiLine}`};
`;

const NavigationModulesTitle = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const NavigationModulesDescription = styled.div`
  ${(p) => p.theme.textStyle.uiM};
`;

const SiteSettingsLink = styled.div`
  display: inline;
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.color.interactive01};
  span {
    cursor: pointer;
  }
`;

export {
  DefaultListWrapper,
  PaginationWrapper,
  TableWrapper,
  NavigationModulesWarning,
  NavigationModulesTitle,
  NavigationModulesDescription,
  SiteSettingsLink,
};
