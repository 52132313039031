import styled from "styled-components";

export const DatePickerWrapper = styled.div`
  .react-datepicker-popper,
  .react-datepicker-popper[data-placement^="bottom"],
  .react-datepicker-popper[data-placement^="bottom-start"] {
    margin-top: 0;
    z-index: 2;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    font-family: Source Sans Pro;
    border: 1px solid ${(p) => p.theme.color.uiLine};
    min-width: 320px;
  }

  .react-datepicker__header {
    background-color: white;
    border-bottom: 0;
    padding-top: ${(p) => p.theme.spacing.xs};
  }

  .react-datepicker__current-month {
    ${(p) => p.theme.textStyle.headingXS};
    margin: ${(p) => p.theme.spacing.xs} 0;
  }

  .react-datepicker__navigation {
    width: ${(p) => p.theme.spacing.m};
    height: ${(p) => p.theme.spacing.m};
    border: none;
    margin-bottom: ${(p) => p.theme.spacing.xs};
    &:hover {
      border-radius: 50%;
      background-color: ${(p) => p.theme.color.overlayHoverPrimary};
    }
    &:focus,
    &:active {
      outline: 0;
      -moz-outline-style: none;
      border: none;
    }

    &.react-datepicker__navigation--next {
      background-image: url(/img/icons/Right-arrow.svg);
      margin-right: ${(p) => p.theme.spacing.s};
    }

    &.react-datepicker__navigation--previous {
      background-image: url(/img/icons/Left-arrow.svg);
      margin-left: ${(p) => p.theme.spacing.s};
    }
  }

  .react-datepicker__day-names {
    min-width: 320px;
    .react-datepicker__day-name {
      line-height: 15px;
      color: ${(p) => p.theme.color.textMediumEmphasis};
    }
  }

  .react-datepicker__month-container {
    min-width: 320px;

  .react-datepicker__day {
    font-size: 15px;
    box-sizing: content-box;
    border-radius: 50%;
    :hover :not(.react-datepicker__day--selected) {
      background-color: ${(p) => p.theme.color.overlayHoverPrimary};
    }
    :focus,
    :active {
      outline: 0;
      border-radius: 50%;
    }
  }

  .react-datepicker__month {
    min-width: 320px;
    margin: ${(p) => p.theme.spacing.xs} 0;
  }

  .react-datepicker__week {
    margin-bottom: ${(p) => p.theme.spacing.xxs};
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    margin: 0 ${(p) => p.theme.spacing.xs};
  }

  .react-datepicker__day--today {
    :not(.react-datepicker__day--selected) {
      color: ${(p) => p.theme.color.interactive01};
    }
  }

  .react-datepicker__day--in-range:not(.react-datepicker__day--selected) {
    background-color:${(p) => p.theme.color.interactive01};
    border-radius: 0;
    color: white;
    &:not(.react-datepicker__day--range-end) {
      margin: 0;
      padding: 0 8px;
    }
  }

  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selected){
    background-color: #FFF;
    color: #000;
  }

  .react-datepicker__day--selected.react-datepicker__day--range-start:not(.react-datepicker__day--range-end){
    border-radius: 50% 0 0 50%;
    margin-right: 0;
    padding: 0 8px 0 0;
  }

  .react-datepicker__day--in-range.react-datepicker__day--range-end:not(.react-datepicker__day--range-start) {
    border-radius: 0 50% 50% 0;
    margin-left: 0;
    padding: 0 0 0 8px;
  }

  .react-datepicker__day--selected {
    border-radius: 50%;
  }

  .react-datepicker__week .react-datepicker__day--in-range:last-of-type,
  .react-datepicker__week .react-datepicker__day--in-selecting-range:last-of-type {
    &:not(.react-datepicker__day--in-range.react-datepicker__day--range-end) {
      border-radius: 0 2px 2px 0;
    }
    padding-right: 0;
    margin-right: 8px;
  }

  .react-datepicker__week .react-datepicker__day--in-range:first-of-type,
  .react-datepicker__week .react-datepicker__day--in-selecting-range:first-of-type {
    &:not(.react-datepicker__day--in-range.react-datepicker__day--range-start) {
      border-radius: 2px 0 0 2px;
    }
    padding-left: 0;
    margin-left: 8px;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range.react-datepicker__day--range-end {
    font-weight: ${(p) => p.theme.fontWeight.semibold}
    background-color: ${(p) => p.theme.color.interactive01};
    color: white;
    :hover {
      background-color: ${(p) => p.theme.color.interactive01};
    }
  }

  .react-datepicker__day--outside-month {
    color: ${(p) => p.theme.color.textLowEmphasis};
    &.react-datepicker__day--in-range {
      color:  ${(p) => p.theme.color.textLowEmphasisInverse};
    }
  }
`;
