import styled from "styled-components";

import { Header } from "@ax/components/TableList/style";

const TableHeader = styled.div<{ isScrolling?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: ${(p) => p.theme.spacing.m};
  border-bottom: ${(p) => (p.isScrolling ? `1px solid ${p.theme.color.uiLine};` : "")};
`;

const CheckHeader = styled(Header)`
  padding-right: 0;
  width: 40px;
  label {
    margin-bottom: ${(p) => p.theme.spacing.s};
  }
  > div {
    width: ${(p) => p.theme.spacing.s};
  }
`;

const NameHeader = styled(Header)`
  flex-grow: 1;
`;

const TypeHeader = styled(Header)`
  flex: 0 0 80px;
  justify-content: center;
`;

const SizeHeader = styled(Header)`
  flex: 0 0 85px;
  justify-content: center;
`;

const UpdatedHeader = styled(Header)`
  flex: 0 0 120px;
  justify-content: center;
`;

const TagsHeader = styled(Header)`
  flex: 0 0 300px;
`;

const FolderHeader = styled(Header)`
  flex: 0 0 200px;
`;

const CounterHeader = styled(Header)`
  flex: 0 0 70px;
  justify-content: flex-end;
  padding 0;
`;

export {
  TableHeader,
  CheckHeader,
  NameHeader,
  TypeHeader,
  SizeHeader,
  UpdatedHeader,
  TagsHeader,
  CounterHeader,
  FolderHeader,
};
