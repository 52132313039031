import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
`;

const ButtonWrapper = styled.div`
  button {
    margin-left: 0 !important;
    margin-right: ${(p) => p.theme.spacing.s} !important;
  }
`;

const MenuWrapper = styled.div`
  padding: ${(p) => `${p.theme.spacing.xs} ${p.theme.spacing.s}`};
`;

const HelpText = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.colors.textHighEmphasis};
`;

const ChecksWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.xs};
`;

const ExportWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.xs};
  button {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
  }
`;

export { Wrapper, ChecksWrapper, MenuWrapper, HelpText, ButtonWrapper, ExportWrapper };
