import styled from "styled-components";

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};

  p {
    margin-bottom: ${(p) => p.theme.spacing.m};
  }
`;

const ModalFields = styled.div`
  display: flex;
  width: 100%;
  & > div:nth-child(odd) {
    margin-right: ${(p) => p.theme.spacing.m};
  }
`;

export { ModalContent, ModalFields };
