import styled from "styled-components";

const ContentListWrapper = styled.div`
  display: flex;
  max-width: 100%;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: calc(100vh - ${(p) => p.theme.spacing.xl});
  overflow: auto;
`;

const PaginationWrapper = styled.span`
  display: flex;
  justify-content: flex-end;
  margin-right: ${(p) => p.theme.spacing.m};
  margin-top: ${(p) => p.theme.spacing.m};
`;

const EmptyWrapper = styled.div`
  height: ${(p) => `calc(100vh - (${p.theme.spacing.xl} * 3))`};
  display: flex;
  align-items: center;
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};

  p {
    margin-bottom: ${(p) => p.theme.spacing.m};
  }
`;

const SelectWrapper = styled.div`
  & .react-select__control,
  .react-select__menu {
    min-width: 100%;
  }
`;

const NotificationWrapper = styled.div`
  width: 100%;
`;

const SearchTags = styled.div`
  & > div:nth-child(1) {
    margin-bottom: ${(p) => p.theme.spacing.xs};
  }
  & > div:nth-child(2) {
    margin-bottom: ${(p) => p.theme.spacing.xs};
  }
`;

export {
  ContentListWrapper,
  TableWrapper,
  PaginationWrapper,
  EmptyWrapper,
  ModalContent,
  SelectWrapper,
  NotificationWrapper,
  SearchTags,
};
