import React, { memo, useEffect, useRef, useState } from "react";

import * as S from "./style";

const TextArea = (props: ITextAreaProps): JSX.Element => {
  const { value, placeholder, error, name, onChange, disabled, handleValidation, editorID, rows = 1 } = props;

  const [state, setState] = useState<string>(value);
  const timeOutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    timeOutRef.current && clearTimeout(timeOutRef.current);
    timeOutRef.current = setTimeout(() => {
      delayedChange();
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    setState(value);
  }, [editorID, value]);

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState(e.target.value);
  };

  const delayedChange = () => {
    onChange(state);
    error && handleValidation && handleValidation(state);
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    handleValidation && handleValidation(e.target.value);
  };

  return (
    <S.TextArea
      data-testid="text-area-component"
      name={name}
      minRows={rows}
      maxRows={10}
      value={state}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      placeholder={placeholder}
      $error={error}
      disabled={disabled}
    />
  );
};

interface ITextAreaProps {
  name: string;
  value: string;
  placeholder?: string;
  error?: boolean;
  onChange: (value: string) => void;
  disabled?: boolean;
  handleValidation?: (value: string) => void;
  editorID?: number;
  rows?: number;
}

export default memo(TextArea);
