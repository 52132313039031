import styled from "styled-components";

import { IStyledProps } from "@ax/types";

export const Wrapper = styled.button`
  height: ${(p: IStyledProps) => p.theme.spacing.l};
  padding: 0 ${(p: IStyledProps) => p.theme.spacing.s};
  margin-bottom: ${(p: IStyledProps) => p.theme.spacing.m};
  color: ${(p: IStyledProps) => p.theme.color.textLowEmphasis};
  background: transparent;
  ${(p: IStyledProps) => p.theme.textStyle.fieldLabel};
  border: 1px solid transparent;
  border-top-color: ${(p: IStyledProps) => p.theme.color.uiLine};
  border-bottom-color: ${(p: IStyledProps) => p.theme.color.uiLine};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &:before,
  &:after {
    display: none;
  }

  svg {
    height: ${(p: IStyledProps) => p.theme.spacing.m};

    path {
      fill: ${(p: IStyledProps) => p.theme.color.textLowEmphasis};
    }
  }

  &:hover {
    background: ${(p: IStyledProps) => p.theme.color.overlayHoverPrimary};
    color: ${(p: IStyledProps) => p.theme.color.textMediumEmphasis};

    svg path {
      fill: ${(p: IStyledProps) => p.theme.color.interactive01};
    }
  }
`;

export const Label = styled.p``;

export const WrapperMultipleOptions = styled.button`
  color: ${(p) => p.theme.color.textHighEmphasis};
  position: relative;
  display: block;
  width: 100%;
  height: ${(p) => p.theme.spacing.l};
  background: ${(p) => p.theme.color.uiBackground02};
  border: 1px solid transparent;
  margin-bottom: ${(p) => p.theme.spacing.xs};
  padding: 0 ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.radii.s};
  box-shadow: ${(p) => p.theme.shadow.shadowS};
  ${(p) => p.theme.textStyle.fieldLabel};
  text-align: center;
  cursor: pointer;

  &:hover {
    background: ${(p) => p.theme.color.overlayHoverPrimary};
  }

  &:focus,
  &:active {
    background: ${(p) => p.theme.color.overlayFocusPrimary};
    border: 1px solid ${(p) => p.theme.color.interactive01};
    outline: none;
  }
`;
