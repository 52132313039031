import React, { useEffect, useState, memo, useRef } from "react";
import { connect } from "react-redux";

import { galleryActions } from "@ax/containers/Gallery";
import { IData, IIsLoading } from "@ax/containers/Gallery/reducer";
import { IGetSiteImages, IImage, IQueryValue, IRootState, ISite } from "@ax/types";
import {
  Icon,
  Loader,
  Tabs,
  SearchField,
  EmptyState,
  ErrorToast,
  Notification,
  SearchTagsBar,
  FilterTagsBar,
} from "@ax/components";
import { usePermission } from "@ax/hooks";

import Orientation from "./GalleryFilters/Orientation";
import SortBy from "./GalleryFilters/SortBy";
import Type from "./GalleryFilters/Type";
import GalleryPanel from "./GalleryPanel";
import { useFilterQuery, useSortedListStatus } from "./hooks";
import { getSortedListStatus } from "./utils";

import * as S from "./style";

const itemsPerPage = 50;
const firstPage = 1;

const Gallery = (props: IProps): JSX.Element => {
  const { data, isLoading, getSiteImages, getImageSelected, toggleModal, site, uploadError } = props;

  const isAllowedToAccessGlobalImages = usePermission("mediaGallery.accessToGlobalGalleryFromSite");

  const tabs: string[] = [];
  if (site && isAllowedToAccessGlobalImages) {
    tabs.unshift(...["Site", "Global"]);
  }
  const [selectedTab, setSelectedTab] = useState(site ? "Site" : "Global");
  const [selectedImage, setSelectedImage] = useState<IImage | null>(null);
  const isSiteTab = selectedTab === "Site";
  const isGlobalTab = selectedTab === "Global";
  const galleryScope = isSiteTab && site ? site.id : "global";

  const validFormats = ["jpeg", "jpg", "png", "svg", "gif"];

  const galleryRef = useRef<HTMLDivElement>(null);

  const { setFiltersSelection, resetFilterQuery, filterQuery, filterValues } = useFilterQuery();
  const { sortedListStatus, setSortedListStatus } = useSortedListStatus();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const isSearching = searchQuery.length > 0;
  const pageRef = useRef(firstPage);

  const getParams = () => ({
    site: galleryScope,
    page: data?.page,
    items: itemsPerPage,
    query: filterQuery,
    search: searchQuery,
  });

  pageRef.current = getParams().page;

  useEffect(() => {
    uploadError(false);
    const params = getParams();
    getSiteImages({ ...params, page: firstPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [galleryScope]);

  useEffect(() => {
    const handleScroll = () => {
      const loadMore =
        galleryRef.current &&
        galleryRef.current.scrollHeight -
          (galleryRef.current.scrollTop + galleryRef.current.getBoundingClientRect().height) <
          60 &&
        galleryRef.current.scrollTop > 0;
      if (!loadMore) return;
      if (!data.isFinished) {
        const params = getParams();
        getSiteImages({ ...params, page: pageRef.current + 1, more: true });
      }
    };
    window.addEventListener("scroll", handleScroll, true);
    return () => window.removeEventListener("scroll", handleScroll, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.isFinished]);

  const refreshImages = async (page = firstPage) => {
    const params = getParams();
    const more = page !== firstPage;
    await getSiteImages({ ...params, page, more });
    if (page < params.page) refreshImages(page + 1);
  };

  useEffect(() => {
    setSelectedImage(null);
    const params = getParams();
    getSiteImages({ ...params, page: firstPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery, searchQuery]);

  const handleClick = (item: IImage) => () => {
    if (item.id !== selectedImage?.id) {
      setSelectedImage(item);
    } else {
      setSelectedImage(null);
    }
  };

  const setImage = (imageData: any) => {
    const updatedImage = { ...selectedImage, ...imageData };
    getImageSelected(updatedImage);
    toggleModal();
  };

  const sortItems = (orderPointer: IQueryValue[], isAscending: boolean) => {
    const sortedState = getSortedListStatus(orderPointer[0].value.toString(), isAscending);
    setSortedListStatus(sortedState);

    setFiltersSelection("order", orderPointer, isAscending);
  };

  const filterItems = (filterPointer: string, filtersSelected: IQueryValue[]) =>
    setFiltersSelection(filterPointer, filtersSelected);

  const handleSelectedTab = (tab: string) => {
    if (tab !== selectedTab) {
      setSelectedImage(null);
      setSelectedTab(tab);
    }
  };

  const emptyStateProps = {
    icon: "search",
    title: "Oh! No Results Found",
    message: "We couldn’t find what you are looking for. Please, try another search.",
  };

  const noSearchResults = !data?.items?.length && isSearching;

  return (
    <S.Wrapper data-testid="gallery-wrapper">
      <S.GalleryTabs>
        <S.Header>
          {!!tabs.length && (
            <S.TabsWrapper>
              <Tabs tabs={tabs} active={selectedTab} setSelectedTab={handleSelectedTab} noMargins />
            </S.TabsWrapper>
          )}
          <S.Filters>
            <Orientation filterItems={filterItems} />
            <Type filterItems={filterItems} />
            <SortBy sortItems={sortItems} sortedState={sortedListStatus} />
          </S.Filters>
        </S.Header>
        <S.Search>
          <SearchField
            onChange={setSearchQuery}
            value={searchQuery}
            placeholder="Type an image’s name, title, or #tag"
            autoFocus={false}
          />
        </S.Search>
        <S.GalleryWrapper ref={galleryRef}>
          {isGlobalTab && (
            <S.NotificationWrapper>
              <Notification
                type="info"
                text="This is a global Library. All the changes you make will be applied to all the sites."
              />
            </S.NotificationWrapper>
          )}
          <ErrorToast size="l" />
          <S.SearchTags>
            <SearchTagsBar query={searchQuery} setQuery={setSearchQuery} />
            <FilterTagsBar
              filters={filterValues}
              setFilters={setFiltersSelection}
              labels={{ orientation: "Orientation", format: "Type" }}
              resetFilters={resetFilterQuery}
            />
          </S.SearchTags>
          {isLoading.init ? (
            <S.LoadingWrapper>
              <Loader name="circle" />
            </S.LoadingWrapper>
          ) : (
            <>
              {noSearchResults ? (
                <S.EmptyWrapper>
                  <EmptyState {...emptyStateProps} />
                </S.EmptyWrapper>
              ) : (
                <S.Grid data-testid="grid-wrapper">
                  {data &&
                    data.items &&
                    data.items.map((item: IImage, index: number) => {
                      const isSelected = item.id === selectedImage?.id;
                      return (
                        <S.GridItem data-testid="grid-item" key={item.name + index} orientation={item.orientation}>
                          <S.ImageWrapper
                            data-testid="image-item"
                            onClick={handleClick(item)}
                            selected={isSelected}
                            orientation={item.orientation}
                          >
                            <img src={item.thumb} alt={item.alt} />
                            <S.IconUnchecked>
                              <Icon name="emptyCheck" size="24" />
                            </S.IconUnchecked>
                            <S.IconChecked>
                              <Icon name="success" size="24" />
                            </S.IconChecked>
                          </S.ImageWrapper>
                        </S.GridItem>
                      );
                    })}
                </S.Grid>
              )}
              {isLoading.more && (
                <S.LoaderWrapper>
                  <Loader name="dots" />
                </S.LoaderWrapper>
              )}
            </>
          )}
        </S.GalleryWrapper>
      </S.GalleryTabs>
      <GalleryPanel
        imageSelected={selectedImage}
        validFormats={validFormats}
        setImage={setImage}
        isGlobalTab={!isSiteTab}
        scope={galleryScope}
        selectedTab={selectedTab}
        refreshImages={refreshImages}
        site={site}
        selectImage={setSelectedImage}
      />
    </S.Wrapper>
  );
};

export interface IGalleryProps {
  getImageSelected: (img: IImage | null) => void;
  toggleModal: () => void;
  site: ISite | null;
  data: IData;
  isLoading: IIsLoading;
}

const mapStateToProps = (state: IRootState) => ({
  data: state.gallery.data,
  isLoading: state.gallery.isLoading,
});

export interface IDispatchProps {
  getSiteImages(params: IGetSiteImages): Promise<void>;
  uploadError: (error: boolean, msg?: string) => Promise<void>;
}

const mapDispatchToProps = {
  getSiteImages: galleryActions.getSiteImages,
  uploadError: galleryActions.uploadError,
};

type IProps = IGalleryProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(memo(Gallery));
