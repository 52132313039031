import React, { useState } from "react";
import { connect } from "react-redux";

import { IActionMenuOption, IFolder } from "@ax/types";
import { Icon, Tooltip } from "@ax/components";
import { useModal } from "@ax/hooks";
import { fileDriveActions } from "@ax/containers/FileDrive";
import { DeleteFolderModal, MoveItemModal, RenameFolderModal } from "../atoms";
import { trimText } from "@ax/helpers";

import * as S from "./style";

const FolderItem = (props: IProps) => {
  const { folder, isAllowedToDelete, isAllowedToEdit, isAllowedToMove, onClick, onDelete, toggleToast, updateFolder } =
    props;
  const { id, folderName, parentId, site } = folder;

  const initState = { folderName, parentId };
  const [folderForm, setFolderForm] = useState(initState);
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();
  const { isOpen: isRenameOpen, toggleModal: toggleRenameModal } = useModal();
  const { isOpen: isMoveOpen, toggleModal: toggleMoveModal } = useModal();

  const setName = (name: string) => setFolderForm({ ...folderForm, folderName: name });
  const setParent = (folderID: number) => setFolderForm({ ...folderForm, parentId: folderID });

  let menuOptions: IActionMenuOption[] = [];

  if (isAllowedToEdit) {
    menuOptions = [
      {
        label: "Rename",
        icon: "edit",
        action: () => toggleRenameModal(),
      },
    ];

    if (isAllowedToMove) {
      menuOptions = [
        ...menuOptions,
        {
          label: "Move to...",
          icon: "change",
          action: () => toggleMoveModal(),
        },
      ];
    }
  }

  if (isAllowedToDelete) {
    menuOptions = [
      ...menuOptions,
      {
        label: "Delete",
        icon: "delete",
        action: () => toggleDeleteModal(),
      },
    ];
  }

  const handleClick = () => onClick(id);

  const handleDeleteFolder = () => {
    onDelete(folder);
    isDeleteOpen && toggleDeleteModal();
  };

  const handleUpdateFolder = async (toast = false) => {
    const updated = await updateFolder(
      id,
      { parentId: folderForm.parentId, folderName: folderForm.folderName },
      site || "global"
    );
    if (updated) {
      isRenameOpen && toggleRenameModal();
      toast && toggleToast();
    }
  };

  const handleModalClose = () => {
    setFolderForm(initState);
    isMoveOpen && toggleMoveModal();
    isRenameOpen && toggleRenameModal();
  };

  const mainDeleteModalAction = {
    title: "Delete Folder",
    onClick: () => handleDeleteFolder(),
  };

  const secondaryDeleteModalAction = { title: "Cancel", onClick: toggleDeleteModal };

  const mainRenameModalAction = {
    title: "Rename Folder",
    onClick: () => handleUpdateFolder(),
    disabled: folderForm.folderName.trim().length === 0,
  };

  const secondaryRenameModalAction = { title: "Cancel", onClick: handleModalClose };

  const mainMoveModalAction = {
    title: "Move",
    onClick: () => handleUpdateFolder(true),
  };

  const secondaryMoveModalAction = { title: "Cancel", onClick: handleModalClose };

  const FolderTitle = () =>
    folderName.length > 15 ? (
      <Tooltip content={folderName}>
        <S.Title>{trimText(folderName, 15)}</S.Title>
      </Tooltip>
    ) : (
      <S.Title>{folderName}</S.Title>
    );

  return (
    <>
      <S.Wrapper onClick={handleClick}>
        <S.IconWrapper>
          <Icon name="project" size="24" />
        </S.IconWrapper>
        <FolderTitle />
        <S.StyledActionMenu icon="more" options={menuOptions} tooltip="File actions" />
      </S.Wrapper>
      {isDeleteOpen && (
        <DeleteFolderModal
          isOpen={isDeleteOpen}
          toggleModal={toggleDeleteModal}
          mainModalAction={mainDeleteModalAction}
          secondaryModalAction={secondaryDeleteModalAction}
          title={folderName}
        />
      )}
      {isRenameOpen && (
        <RenameFolderModal
          isOpen={isRenameOpen}
          toggleModal={handleModalClose}
          mainModalAction={mainRenameModalAction}
          secondaryModalAction={secondaryRenameModalAction}
          value={folderForm.folderName}
          setValue={setName}
        />
      )}
      {isMoveOpen && (
        <MoveItemModal
          isOpen={isMoveOpen}
          toggleModal={handleModalClose}
          mainModalAction={mainMoveModalAction}
          secondaryModalAction={secondaryMoveModalAction}
          folder={folderForm.parentId}
          setFolder={setParent}
          hidden={[folder.id]}
          hideRoot={!parentId}
        />
      )}
    </>
  );
};

interface IProps {
  folder: IFolder;
  isAllowedToEdit: boolean;
  isAllowedToDelete: boolean;
  isAllowedToMove: boolean;
  onClick(folderID: number): void;
  onDelete(folder: IFolder): void;
  toggleToast(): void;
  updateFolder(
    folderID: number,
    data: { parentId: number; folderName: string },
    siteID: number | "global"
  ): Promise<boolean>;
}

const mapDispatchToProps = {
  updateFolder: fileDriveActions.updateFolder,
};

export default connect(null, mapDispatchToProps)(FolderItem);
