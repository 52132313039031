import * as React from "react";

function SvgGrid(props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-2 0H4v-4h4v4zm-4-6h4v-4H4v4zm10 0h-4v-4h4v4zm2-10v4h4V4h-4zm-2 4h-4V4h4v4zm2 6h4v-4h-4v4zm4 6h-4v-4h4v4z"
        fill="#5057FF"
      />
    </svg>
  );
}

export default SvgGrid;
