import React from "react";
import { connect } from "react-redux";
import { navigationActions } from "@ax/containers/Navigation/Defaults";

import { Browser } from "@ax/components";
import { ILanguage, IRootState, ISite, ISocialState } from "@ax/types";

const DefaultsBrowser = (props: IProps) => {
  const { socials, cloudinaryName, content, currentSiteInfo, siteLangs, browserRef, actions } = props;

  if (!currentSiteInfo) {
    throw new Error(`ERROR: User reached Page Editor with null site info`);
  }

  const { theme, id: siteID } = currentSiteInfo;

  const updatedContent = { ...content };

  return (
    <Browser
      browserRef={browserRef}
      isPage={false}
      socials={socials}
      content={updatedContent}
      url={content.slug}
      theme={theme}
      cloudinaryName={cloudinaryName}
      siteLangs={siteLangs}
      siteID={siteID}
      actions={actions}
    />
  );
};

interface IDefaultsBrowserProps {
  actions: any;
  browserRef?: React.RefObject<HTMLDivElement>;
}

interface IDefaultsBrowserStateProps {
  // TODO: Define content Type
  content: any;
  currentSiteInfo: ISite | null;
  socials: ISocialState;
  cloudinaryName: string | null;
  siteLangs: ILanguage[];
}

interface IDefaultsBrowserDispatchProps {
  setSelectedContent(editorID: number): void;
}

type IProps = IDefaultsBrowserProps & IDefaultsBrowserStateProps & IDefaultsBrowserDispatchProps;

const mapStateToProps = (state: IRootState): IDefaultsBrowserStateProps => ({
  content: { ...state.navigation.editorContent },
  currentSiteInfo: state.sites.currentSiteInfo,
  socials: state.social,
  cloudinaryName: state.app.globalSettings.cloudinaryName,
  siteLangs: state.sites.currentSiteLanguages,
});

const mapDispatchToProps = {
  setSelectedContent: navigationActions.setSelectedContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultsBrowser);
