import type { LocaleTranslations } from "../../types/core";

import * as React from "react";

const I18nContext = React.createContext<I18nContextProps>({ translations: {} });

/**
 * This provider component inject the `translation` object in the context of the site. Then you can retrieve the translation based on the page language using the `useI18n()` hook.
 */
function I18nProvider(props: I18nProviderProps): JSX.Element {
	const { children, translations } = props;

	return (
		<I18nContext.Provider value={{ translations }}>
			{children}
		</I18nContext.Provider>
	);
}

interface I18nContextProps {
	translations?: LocaleTranslations;
}

interface I18nProviderProps {
	children: JSX.Element;
	/** Object with static translations using local i18n locale codes.
	 * @example
	 * {
	 *   es_ES: { hi: "Hola", ... }
	 *   en_US: { hi: "Hello", ... }
	 * }
	 */
	translations?: LocaleTranslations;
}

export { I18nContext, I18nProvider };
