import * as React from "react";

function SvgCaEs(props) {
  return (
    <svg width={512} height={512} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M512 86H0v340h512V86z" fill="#FCDD09" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 124h512v37.714H0V124zm512 113.142H0v-37.713h512v37.713zM0 274.858h512v37.714H0v-37.714zm0 75.428h512V388H0v-37.714z"
        fill="#DA121A"
      />
    </svg>
  );
}

export default SvgCaEs;
