import type { ListContentType } from "../types/core";
import type {
	ListApiQueryResponseProps,
	PickRename,
	SetQueryProps,
} from "../types/global";

import * as React from "react";

import { useFetch, type UseFetchStateProps } from "./useFetch";
import { usePage } from "./usePage";
import { useSite } from "./useSite";
import { isOperator, parseRelations, parseSources } from "./utils";
import { concatParams } from "../functions/utils";

/**
 * Get a list of ContentTypes using the Griddo public API.
 *
 * @example
 * const [{ query }, setQuery]  = useList()
 * setQuery(data) // `data` key from a `ReferenceField`.
 */
export function useList<ContentType>(): UseListReturn<ContentType> {
	const { publicApiUrl, siteId, renderer } = useSite();
	const { languageId } = usePage();
	const [url, setUrl] = React.useState<UseListSetUrlAction>();
	const isAX = renderer === "editor";

	// Exposed function to modify the internal state
	function setQuery<ContentType = unknown>({
		apiUrl = `${publicApiUrl}`,
		cached,
		data: refField,
		exclude,
		fields,
		items = 10,
		operator,
		page = 1,
		relations,
		search,
		includePending,
		allLanguages,
		preferenceLanguage,
	}: UseListSetQueryProps<ContentType>) {
		if (!refField) {
			return;
		}

		const customCached = isAX ? new Date().valueOf().toString() : cached;
		const searchWithNoSpaces = search?.replace(/\s/g, "+");
		const isAuto = refField.mode === "auto";
		const isManual = refField.mode === "manual";
		const isNavigation = refField.mode === "navigation";
		const contentType =
			parseSources<ContentType>(refField.sources) ||
			refField.fixed ||
			`reference/${refField.referenceId}` ||
			[];
		const listModeParam = isAuto
			? "/list/v2/"
			: isManual
				? "/list/fixed/"
				: isNavigation
					? "/list/navigations/"
					: "";

		const queryUrl = concatParams({
			baseUrl: `${apiUrl}${listModeParam}${contentType}/`,
			params: {
				site: refField.site || siteId,
				lang: refField.lang || languageId,
				get: fields,
				page,
				items,
				order: isAuto || isNavigation ? refField.order : undefined,
				//filter: parseFilter(filterIds || refField.filter),
				maxItems: refField.quantity,
				search: searchWithNoSpaces,
				operator: isOperator(operator) ? operator : undefined,
				//filterOperator: isOperator(filterOperator) ? filterOperator : undefined,
				//globalOperator: isOperator(globalOperator) ? globalOperator : undefined,
				exclude: Array.isArray(exclude) ? exclude : undefined,
				relations: parseRelations(relations),
				includePending: includePending ? "on" : undefined,
				// For legacy pourposes the hook reads `allLanguages` and
				// `preferenceLanguage` from arguments too. They will be remove
				// in the future...
				allLanguages: allLanguages || refField.allLanguages ? "on" : undefined,
				preferenceLanguage:
					preferenceLanguage || refField.preferenceLanguage
						? "true"
						: undefined,
				cached: customCached,
			},
		});

		// If manual selection is empty, it doesn't call to API
		if (isManual && Array.isArray(contentType) && contentType.length < 1) {
			setUrl({
				queryUrl: undefined,
				extra: undefined,
			});
		}

		setUrl({
			queryUrl,
			extra: {
				totalItems: refField.mode === "manual" ? refField.fixed?.length : false,
			},
		});
	}

	const { isError, isLoading, msg, data, isFirstFetch } = useFetch<
		ListApiQueryResponseProps<ListContentType<ContentType>>
	>(url?.queryUrl);

	return [{ query: data, isLoading, isError, msg, isFirstFetch }, setQuery];
}

// url
export interface UseListSetUrlAction {
	queryUrl: string | undefined;
	extra: Record<string, unknown> | undefined;
}

// setQuery
export type UseListSetQueryProps<ContentType> = SetQueryProps<ContentType>;

// The same state as useFetch but renaming data to query for this hook
export type UseListState<ContentType> = PickRename<
	UseFetchStateProps<ListApiQueryResponseProps<ListContentType<ContentType>>>,
	"data",
	"query"
>;

export type UseListReturn<ContentType> = [
	UseListState<ContentType>,
	(setQuery: UseListSetQueryProps<ContentType>) => void,
];
