import { IDomainRobot } from "@ax/types";
import { SET_DOMAINS_ROBOTS } from "./constants";
import { DomainsActionsCreators } from "./interfaces";

export interface IDomainsState {
  robots: IDomainRobot[];
}

export const initialState = {
  robots: [],
};

export function reducer(state = initialState, action: DomainsActionsCreators): IDomainsState {
  switch (action.type) {
    case SET_DOMAINS_ROBOTS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export { initialState as domainsInitialState, reducer as domainsReducer };
