import React from "react";
import { connect } from "react-redux";
import { SideModal, ActionMenu } from "@ax/components";
import { useModal } from "@ax/hooks";
import { pageEditorActions } from "@ax/containers/PageEditor";
import { IRootState } from "@ax/types";

import * as S from "./style";

const Field = (props: IProps) => {
  const {
    type,
    defaults,
    updateEditorContent,
    selectedContent,
    theme,
    removeNavigationFromPage,
    template,
    configFormData,
  } = props;

  const { isOpen, toggleModal } = useModal();

  const defaultSiteHeader = defaults.find(
    (component: any) => component.setAsDefault && component.type === "header"
  )?.id;
  const defaultSiteFooter = defaults.find(
    (component: any) => component.setAsDefault && component.type === "footer"
  )?.id;

  const { defaultHeader: defaultTemplateHeader, defaultFooter: defaultTemplateFooter } =
    (configFormData.templates && configFormData.templates[template]) || {};

  const defaultOption =
    type === "header" ? defaultTemplateHeader || defaultSiteHeader : defaultTemplateFooter || defaultSiteFooter;

  const options = defaults.filter((component: any) => {
    const isCurrentType = component.type === type;
    const isNotSelected = component.id !== selectedContent.id;
    return isCurrentType && isNotSelected;
  });

  const templateDefaultOption = options
    .filter(
      (option: any) =>
        (type === "header" && option.id === defaultTemplateHeader) ||
        (type === "footer" && option.id === defaultTemplateFooter)
    )
    .map((option: any) =>
      option.id === defaultOption
        ? { ...option, isDefault: true, tag: "template default" }
        : { ...option, isDefault: false, tag: "template default" }
    )?.[0];

  const siteDefaultOption = options
    .filter(
      (option: any) =>
        (type === "header" && option.id === defaultSiteHeader) || (type === "footer" && option.id === defaultSiteFooter)
    )
    .map((option: any) => {
      const templateHasDefaultSiteHeader = type === "header" && defaultTemplateHeader === null;
      const templateHasDefaultSiteFooter = type === "footer" && defaultTemplateFooter === null;

      const tag = templateHasDefaultSiteHeader || templateHasDefaultSiteFooter ? "template default" : "site default";

      const hasDefaultTemplateHeader = type === "header" && defaultTemplateHeader;
      const hasDefaultTemplateFooter = type === "footer" && defaultTemplateFooter;

      return option.id === defaultOption
        ? {
            ...option,
            isDefault: true,
            tag: hasDefaultTemplateHeader || hasDefaultTemplateFooter ? "" : tag,
          }
        : {
            ...option,
            isDefault: false,
            tag: hasDefaultTemplateHeader || hasDefaultTemplateFooter ? "" : tag,
          };
    })?.[0];

  const otherOptions = options.filter(
    (option: any) => option.id !== templateDefaultOption?.id && option.id !== siteDefaultOption?.id
  );

  let orderedOptions = siteDefaultOption ? [siteDefaultOption, ...otherOptions] : otherOptions;
  orderedOptions = templateDefaultOption ? [templateDefaultOption, ...orderedOptions] : orderedOptions;

  const hasMultipleOptions: boolean | undefined = options && options.length > 0;
  const optionsType = `${type}s`;
  const actionMenuIcon = "more";
  const pageEditorID = 0;

  const handleReplace = (option: any) => {
    const optionID = option.isDefault ? null : option.id;
    type && updateEditorContent(pageEditorID, type, optionID);
  };

  const handleRemove = () => type && removeNavigationFromPage(type);

  const actionMenuOptions = [
    {
      label: "remove",
      icon: "delete",
      action: handleRemove,
    },
  ];

  if (hasMultipleOptions) {
    actionMenuOptions.unshift({
      label: "replace",
      icon: "change",
      action: toggleModal,
    });
  }

  return (
    <>
      <S.Component isArray disabled>
        <S.Label containerInfo>{selectedContent.title}</S.Label>
        <S.HiddenButtonsWrapper>
          <ActionMenu options={actionMenuOptions} icon={actionMenuIcon} />
        </S.HiddenButtonsWrapper>
      </S.Component>
      {hasMultipleOptions && (
        <SideModal
          optionsType={optionsType}
          whiteList={orderedOptions}
          handleClick={handleReplace}
          toggleModal={toggleModal}
          isOpen={isOpen}
          theme={theme}
        />
      )}
    </>
  );
};

export interface IField {
  type?: string;
  theme: string;
}

export interface IStateProps {
  defaults: any;
  selectedContent: any;
  template: string;
  configFormData: any;
}

export interface IDispatchProps {
  updateEditorContent: (selectedEditorID: number, key: string, value: any) => void;
  removeNavigationFromPage: (key: string) => void;
}

type IProps = IField & IStateProps & IDispatchProps;

const mapStateToProps = (state: IRootState) => ({
  defaults: state.navigation.currentDefaultsContent,
  selectedContent: state.pageEditor.selectedContent,
  template: state.pageEditor.template,
  configFormData: state.dataPacks.configFormData,
});

const mapDispatchToProps = {
  updateEditorContent: pageEditorActions.updateEditorContent,
  removeNavigationFromPage: pageEditorActions.removeNavigationFromPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Field);
