import { createContext } from "react";

interface INavContext {
  state: any;
  toggleSubmenu: (value: string) => void;
}

const NavContext = createContext<INavContext | undefined>(undefined);
const NavProvider = NavContext.Provider;

export { NavContext, NavProvider };
