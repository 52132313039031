import React from "react";
import styled from "styled-components";
import { Button, Notification } from "@ax/components";

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: strech;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const Logo = styled.div`
  margin-top: 15%;
  margin-bottom: ${(p) => p.theme.spacing.m};
  img {
    height: 40px;
    width: auto;
  }
`;

const Welcome = styled.div`
  color: #ffffff;
  font-size: 181px;
  line-height: 149.32px;
  word-break: break-word;
  margin-bottom: ${(p) => p.theme.spacing.l};
`;

const StartWrapper = styled.div`
  max-width: 416px;
`;

const Text = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasisInverse};
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const StyledButton = styled((props) => <Button {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 288px;
`;

const ContentButton = styled.div`
  display: flex;
  align-items: center;
`;

const IconButton = styled.div`
  width: ${(p) => p.theme.spacing.s};
  height: ${(p) => p.theme.spacing.s};
  margin-right: ${(p) => p.theme.spacing.xs};
`;

const TextButton = styled.div``;

const Secuoyas = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  position: absolute;
  display: flex;
  color: ${(p) => p.theme.color.textMediumEmphasisInverse};
  justify-content: center;
  align-items: center;
  left: 0;
  bottom: ${(p) => p.theme.spacing.m};
  padding-bottom: ${(p) => p.theme.spacing.xs};
  img {
    width: 20px;
    height: 20px;
    margin-left: ${(p) => p.theme.spacing.xs};
  }
`;

const StyledNotification = styled((props) => <Notification {...props} />)`
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

export {
  Wrapper,
  Main,
  Logo,
  Welcome,
  StartWrapper,
  Text,
  StyledButton,
  ContentButton,
  IconButton,
  TextButton,
  Secuoyas,
  StyledNotification,
};
