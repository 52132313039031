import styled from "styled-components";

const HiddenButtonsWrapper = styled.span`
  margin-left: auto;
  display: flex;
  opacity: 0;
`;

const Component = styled.span<{ disabled?: boolean }>`
  color: ${(p) => p.theme.color.textHighEmphasis};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: ${(p) => p.theme.color.uiBackground02};
  border: 1px solid transparent;
  margin-bottom: ${(p) => p.theme.spacing.xs};
  padding: ${(p) => p.theme.spacing.xs};
  border-radius: ${(p) => p.theme.radii.s};
  box-shadow: ${(p) => p.theme.shadow.shadowS};
  ${(p) => p.theme.textStyle.fieldLabel};
  text-align: left;

  &:hover ${HiddenButtonsWrapper} {
    opacity: 1;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span<{ disabled?: boolean }>`
  margin: ${(p) => p.theme.spacing.xxs} 0;
  color: ${(p) => (p.disabled ? p.theme.color.interactiveDisabled : p.theme.color.textHighEmphasis)};
`;

const Subtitle = styled.span<{ disabled?: boolean }>`
  ${(p) => p.theme.textStyle.uiXS};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  color: ${(p) => (p.disabled ? p.theme.color.interactiveDisabled : p.theme.color.textMediumEmphasis)};
`;

const IconWrapper = styled.div`
  height: calc(${(p) => p.theme.spacing.s} * 2);
  width: calc(${(p) => p.theme.spacing.s} * 2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(p) => p.theme.spacing.xs};
`;

const ComponentOptions = styled.div`
  display: flex;
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};
`;

export { Component, Label, IconWrapper, HiddenButtonsWrapper, ComponentOptions, Subtitle, Text, ModalContent };
