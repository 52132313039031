export const NAME = "pageEditor";

export const SET_EDITOR_CONTENT = `${NAME}/SET_EDITOR_CONTENT`;
export const SET_TEMPLATE = `${NAME}/SET_TEMPLATE`;
export const SET_BREADCRUMB = `${NAME}/SET_BREADCRUMB`;
export const SET_SCHEMA = `${NAME}/SET_SCHEMA`;
export const SET_SELECTED_CONTENT = `${NAME}/SET_SELECTED_CONTENT`;
export const SET_SELECTED_PARENT = `${NAME}/SET_SELECTED_PARENT`;
export const SET_TAB = `${NAME}/SET_TAB`;
export const SET_SELECTED_EDITOR_ID = `${NAME}/SET_SELECTED_ID`;
export const SET_CURRENT_PAGE_ID = `${NAME}/SET_CURRENT_PAGE_ID`;
export const SET_CURRENT_PAGE_STATUS = `${NAME}/SET_CURRENT_PAGE_STATUS`;
export const SET_CURRENT_PAGE_NAME = `${NAME}/SET_CURRENT_PAGE_NAME`;
export const SET_CURRENT_PAGE_LANGUAGES = `${NAME}/SET_CURRENT_PAGE_LANGUAGES`;
export const SET_IS_NEW_TRANSLATION = `${NAME}/SET_IS_NEW_TRANSLATION`;
export const SET_TEMPLATE_CONFIG = `${NAME}/SET_TEMPLATE_CONFIG`;
export const SET_ERRORS = `${NAME}/SET_ERRORS`;
export const SET_VALIDATED = `${NAME}/SET_VALIDATED`;
export const SET_SITE_PAGE_ID = `${NAME}/SET_SITE_PAGE_ID`;
export const SET_USER_EDITING = `${NAME}/SET_USER_EDITING`;
export const SET_LAST_ELEMENT_ADDED_ID = `${NAME}/SET_LAST_ELEMENT_ADDED_ID`;
export const SET_COPY_MODULE = `${NAME}/SET_COPY_MODULE`;
export const SET_IS_IA_TRANSLATED = `${NAME}/SET_IS_IA_TRANSLATED`;
export const SET_LAST_TIMEOUT = `${NAME}/SET_LAST_TIMEOUT`;
export const RESET_PAGE_EDITOR_STORE = `${NAME}/RESET_PAGE_EDITOR_STORE`;
export const SET_SCROLL_EDITOR_ID = `${NAME}/SET_SCROLL_EDITOR_ID`;

export const INITIAL_TEMPLATE = "BasicTemplate";
export const MULTIMEDIA_COMPONENTS = ["LinkableImage", "Video"];
