import styled from "styled-components";

const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(${(p) => p.theme.spacing.l} * 15);
  padding: ${(p) => p.theme.spacing.m};
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Droppable = styled.div`
  width: 100%;

  [data-react-beautiful-dnd-droppable] {
    padding-bottom: 100px;
  }
`;

const Draggable = styled.div``;

export {
  TableWrapper,
  Table,
  LoadingWrapper,
  Droppable,
  Draggable
};
