export const NAME = "menu";

export const SET_ORIGINAL_MENUS = `${NAME}/SET_ORIGINAL_MENUS`;
export const SET_ORIGINAL_MENU = `${NAME}/SET_CURRENT_ORIGINAL_MENU`;
export const SET_EDITOR_MENU = `${NAME}/SET_EDITOR_MENU`;
export const SET_ID = `${NAME}/SET_ID`;
export const SET_ITEM = `${NAME}/SET_ITEM`;
export const SET_MENU_FORM_DATA = `${NAME}/SET_MENU_FORM_DATA`;
export const SET_TOTAL_ITEMS = `${NAME}/SET_TOTAL_ITEMS`;
export const SET_CURRENT_MENU = `${NAME}/SET_CURRENT_MENU`;
