import React, { useState } from "react";
import { Icon } from "@ax/components";
import SubNotification from "./SubNotification";

import * as S from "./style";

const Notification = (props: INotificationProps): JSX.Element => {
  const { text, type, btnText, resetError, onClick, closeButton = true, actionsBelow, subErrors, className } = props;

  const [isVisible, setIsVisible] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const getNotificationIcon = (type: string) => {
    switch (type) {
      case "error":
      case "info":
        return (
          <S.IconWrapper data-testid="alert-icon">
            <Icon name="alert" />
          </S.IconWrapper>
        );
      case "success":
        return (
          <S.IconWrapper data-testid="success-icon">
            <Icon name="done" />
          </S.IconWrapper>
        );
      case "warning":
        return (
          <S.IconWrapper data-testid="warning-icon">
            <Icon name="warning" />
          </S.IconWrapper>
        );
      case "private":
        return (
          <S.IconWrapper data-testid="private-icon">
            <Icon name="lock" />
          </S.IconWrapper>
        );
    }
  };

  const { handleErrorClick } = window as any;

  const handleClick = () => (onClick ? onClick() : handleErrorClick());

  const handleClose = () => {
    resetError && resetError();
    setIsVisible(false);
  };

  const toggleShow = () => setIsOpen(!isOpen);

  const ActionBtn = () => (
    <S.ActionBtn onClick={handleClick} actionsBelow={actionsBelow} data-testid="action-button">
      {btnText}
    </S.ActionBtn>
  );

  const showText = isOpen ? "Hide errors" : "Show errors";

  return (
    <S.Wrapper isVisible={isVisible} className={`${type} ${className}`} data-testid="notification-wrapper">
      <S.NotificationWrapper>
        <S.Row>
          {getNotificationIcon(type)}
          <S.Text>{text}</S.Text>
          <S.ActionsWrapper>
            {subErrors && subErrors.length > 0 && (
              <S.Button onClick={toggleShow} isOpen={isOpen} data-testid="suberrors-button">
                {showText}
              </S.Button>
            )}
            {btnText && !actionsBelow && <ActionBtn />}
            {closeButton && (
              <S.CloseWrapper onClick={handleClose} data-testid="close-button">
                <Icon name="close" size="24" />
              </S.CloseWrapper>
            )}
          </S.ActionsWrapper>
        </S.Row>
        {btnText && actionsBelow && <ActionBtn />}
      </S.NotificationWrapper>
      {isOpen &&
        subErrors &&
        subErrors.length > 0 &&
        subErrors.map((not: { id: number; error: string }) => <SubNotification key={not.id} text={not.error} />)}
    </S.Wrapper>
  );
};

export interface INotificationProps {
  text: string;
  type: "error" | "info" | "success" | "warning" | "private";
  resetError?: () => void;
  btnText?: string;
  onClick?: () => void;
  closeButton?: boolean;
  actionsBelow?: boolean;
  subErrors?: { id: number; error: string }[];
  className?: string;
}

export default Notification;
