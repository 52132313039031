import * as React from "react";

import { Icon, ActionMenu, Tooltip, Button, Toast } from "@ax/components";
import { useModal, useToast } from "@ax/hooks";
import { IIntegration, IIntegrationVariable, ILanguage } from "@ax/types";

import VariablesPanel from "./VariablesPanel";
import CustomPanel from "./CustomPanel";
import { DeleteModal } from "./atoms";
import * as S from "./style";

const IntegrationItem = (props: IProps): JSX.Element => {
  const { integration, disabled, removeItem, editIntegration, languages, index, copyIntegration } = props;
  const { isOpen: isOpenDelete, toggleModal: toggleModalDelete } = useModal();
  const { isVisible: isVisibleCopy, toggleToast: toggleToastCopy, setIsVisible: setIsVisibleCopy } = useToast();

  const presenceType = integration.contentPresence?.presenceType;
  const isCustom = presenceType === "page-custom";
  const isEditable = isCustom || !!integration.variables?.length;
  const canDelete = presenceType !== "all" && presenceType !== "page-specific";

  const openFirstTime = isCustom && !integration.name?.length;

  const { isOpen, toggleModal } = useModal(openFirstTime);

  const options = canDelete
    ? [
        {
          label: "Remove",
          icon: "delete",
          action: toggleModalDelete,
        },
      ]
    : [];

  const handleCopyIntegration = () => {
    copyIntegration(integration);
    toggleToastCopy();
  };

  if (isCustom) {
    const copyOption = {
      label: "Copy",
      icon: "copy",
      action: handleCopyIntegration,
    };
    options.unshift(copyOption);
  }

  const handleSetVariables = (variables: IIntegrationVariable[]) => {
    const newIntegration = { ...integration, variables };
    editIntegration(newIntegration, index);
  };

  const handleSetCustom = (modifiedIntegration: Partial<IIntegration>) => {
    const newIntegration = { ...integration, ...modifiedIntegration };
    editIntegration(newIntegration, index);
  };

  const integrationName = isCustom ? "Custom Code" : integration.name;

  const mainDeleteModalAction = {
    title: "Remove add-on",
    onClick: removeItem,
  };
  const secondaryDeleteModalAction = { title: "Cancel", onClick: toggleModalDelete };

  return (
    <>
      <S.Component disabled={disabled} data-testid="integration-item">
        <S.Text>
          <S.Label disabled={disabled}>{integrationName}</S.Label>
          {isCustom && <S.Subtitle disabled={disabled}>{integration.name}</S.Subtitle>}
        </S.Text>
        <S.ComponentOptions>
          {isEditable ? (
            <Tooltip content="Define values">
              <Button type="button" buttonStyle="text" icon="edit" onClick={toggleModal}>
                Edit
              </Button>
            </Tooltip>
          ) : (
            <Tooltip content="Configure in site settings">
              <S.IconWrapper>
                <Icon name="lock" size="14px" />
              </S.IconWrapper>
            </Tooltip>
          )}
          <S.HiddenButtonsWrapper>
            <ActionMenu options={options} icon="more" />
          </S.HiddenButtonsWrapper>
        </S.ComponentOptions>
      </S.Component>
      {isCustom ? (
        <CustomPanel
          isOpen={isOpen}
          toggleModal={toggleModal}
          setIntegration={handleSetCustom}
          integration={integration}
        />
      ) : (
        <VariablesPanel
          isOpen={isOpen}
          toggleModal={toggleModal}
          setIntegrationVariables={handleSetVariables}
          integration={integration}
          languages={languages}
        />
      )}
      <DeleteModal
        isOpen={isOpenDelete}
        toggleModal={toggleModalDelete}
        secondaryModalAction={secondaryDeleteModalAction}
        mainModalAction={mainDeleteModalAction}
        integrationName={integration.name}
      />
      {isVisibleCopy && <Toast message="1 Custom Code copied to clipboard" setIsVisible={setIsVisibleCopy} />}
    </>
  );
};

interface IProps {
  integration: Partial<IIntegration>;
  disabled: boolean;
  removeItem: () => void;
  editIntegration: (integration: Partial<IIntegration>, index: number) => void;
  languages: ILanguage[];
  index: number;
  copyIntegration: (integration: Partial<IIntegration>) => void;
}

export default IntegrationItem;
