import React, { useState } from "react";
import { IFileUseItem } from "@ax/types";
import Item from "../Item";

import * as S from "./style";

const ItemGroup = (props: IItemGroupProps): React.ReactElement => {
  const { title, items, onClick } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => setIsOpen(!isOpen);

  return (
    <S.Wrapper>
      <S.Label onClick={handleClick} isOpen={isOpen}>
        {title}
      </S.Label>
      <S.Content isOpen={isOpen}>
        {items.map((item: any) => {
          const handleClick = () => onClick(item);
          return (
            <Item
              title={item.title}
              date={item.published}
              type={item.structuredDataId || "Page"}
              key={item.id}
              onClick={handleClick}
            />
          );
        })}
      </S.Content>
    </S.Wrapper>
  );
};

interface IItemGroupProps {
  title: string;
  items: IFileUseItem[];
  onClick(item: IFileUseItem): void;
}

export default ItemGroup;
