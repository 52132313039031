import styled from "styled-components";

const Wrapper = styled.div`
  padding: ${(p) => p.theme.spacing.m};
  height: 100%;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  right: 0;
  height: 73px;
  background: ${(p) => p.theme.colors.uiBackground01};
  z-index: 3;
  & button {
    position: absolute;
    right: ${(p) => p.theme.spacing.m};
    bottom: ${(p) => p.theme.spacing.m};
  }
`;

const FormWrapper = styled.div`
  height: calc(100% - ${(p) => p.theme.spacing.s} - 73px);
  overflow: auto;
  margin: -${(p) => p.theme.spacing.m};
  padding: ${(p) => p.theme.spacing.m};
`;

export { Wrapper, Footer, FormWrapper }
