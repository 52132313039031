import styled from "styled-components";

const ActionMenu = styled.ul`
`;

const LanguageTextWrapper = styled.div`
  ${(p) => p.theme.textStyle.uiS};
  text-transform: uppercase;
  color: ${(p) => p.theme.color.interactive01};
  padding: ${(p) => `${p.theme.spacing.xs} ${p.theme.spacing.xxs} ${p.theme.spacing.xs} ${p.theme.spacing.xs}`};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const LanguageItem = styled.li<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(p) => (p.selected ? p.theme.textStyle.uiL : p.theme.textStyle.uiM)};
  color: ${(p) => (p.selected ? p.theme.color.interactive01 : p.theme.color.textMediumEmphasis)};
  padding: ${(p) => p.theme.spacing.s};
  min-height: ${(p) => p.theme.spacing.l};
  min-width: calc(${(p) => p.theme.spacing.l} * 4);
  &:focus {
    background: ${(p) => p.theme.color.overlayFocusPrimary};
  }
  &:hover {
    background: ${(p) => p.theme.color.overlayHoverPrimary};
    color: ${(p) => (p.selected ? p.theme.color.interactive01 : p.theme.color.textHighEmphasis)};
  }
`;

const LanguageItemWrapper = styled.div`
`;

const LanguageLabel = styled.div`
  display: flex;
  margin-right: ${(p) => p.theme.spacing.s};
  align-items: center;
  min-width: 100%;
`;

const LanguageText = styled.div`
  padding-left: ${(p) => p.theme.spacing.xs};
  margin-right: ${(p) => p.theme.spacing.s};
  width: 100%;
`;

const Locale = styled.span`
  text-transform: uppercase;
`;

const LanguageSubtext = styled.span`
  ${(p) => p.theme.textStyle.uiXS};
`;

const LanguageIcon = styled.div`
  svg {
    path {
      fill: ${p => p.theme.color.primary};
    }
  }
`;

export {
  ActionMenu,
  LanguageTextWrapper,
  LanguageItem,
  LanguageItemWrapper,
  LanguageLabel,
  LanguageText,
  Locale,
  LanguageSubtext,
  LanguageIcon
};
