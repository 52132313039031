import styled from "styled-components";
import { Button } from "@ax/components";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  width: 100%;
  padding: ${(p) => p.theme.spacing.m};
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${(p) => p.theme.spacing.m};
`;

const Heading = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  color: ${(p) => p.theme.color.textHighEmphasis};
  padding-bottom: ${(p) => p.theme.spacing.xs};
`;

const SettingsWrapper = styled.div`
  position: relative;
`;

const ScriptCodeWrapper = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const SettingContent = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
  margin-bottom: ${(p) => p.theme.spacing.m};
  padding-bottom: ${(p) => p.theme.spacing.m};
`;

const SettingText = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  width: calc(${(p) => p.theme.spacing.l} * 12);
`;

const StyledButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
`;

const FormWrapper = styled.div`
  width: 720px;
  margin: ${(p) => `${p.theme.spacing.m} 0 0 ${p.theme.spacing.m}`};
`;

export {
  Wrapper,
  Table,
  Heading,
  SettingsWrapper,
  SettingContent,
  SettingText,
  StyledButton,
  FormWrapper,
  ScriptCodeWrapper
};
