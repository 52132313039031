import { template } from "./config";
import { IServiceConfig, sendRequest } from "./utils";

const SERVICES: { [key: string]: IServiceConfig } = {
  GET_NAVIGATION: {
    ...template,
    endpoint: "/navigations/",
    method: "GET",
  },
  GET_HEADERS: {
    ...template,
    endpoint: ["/site/", "/navigations/headers"],
    method: "GET",
  },
  GET_FOOTERS: {
    ...template,
    endpoint: ["/site/", "/navigations/footers"],
    method: "GET",
  },
  UPDATE_NAVIGATION: {
    ...template,
    endpoint: "/navigations/",
    method: "PUT",
  },
  DELETE_NAVIGATION: {
    ...template,
    endpoint: "/navigations/",
    method: "DELETE",
  },
  DELETE_BULK: {
    ...template,
    endpoint: "/navigations/bulk",
    method: "DELETE",
  },
  RESTORE_NAVIGATION: {
    ...template,
    endpoint: ["/navigations/", "/restore"],
    method: "PUT",
  },
  RESTORE_BULK: {
    ...template,
    endpoint: "/navigations/restore/bulk",
    method: "PUT",
  },
  SET_DEFAULT: {
    ...template,
    endpoint: ["/navigations/", "/default"],
    method: "PUT",
  },
  CREATE_NAVIGATION: {
    ...template,
    endpoint: "/navigations",
    method: "POST",
  },
  GET_NAVIGATIONS_LANGUAGES: {
    ...template,
    endpoint: ["/navigations/", "/languages"],
    method: "GET",
  },
  GET_PAGES_BY_HEADER: {
    ...template,
    endpoint: ["/navigations/", "/header/site/", "/pages"],
    method: "GET",
  },
  GET_PAGES_BY_FOOTER: {
    ...template,
    endpoint: ["/navigations/", "/footer/site/", "/pages"],
    method: "GET",
  },
  UPDATE_PAGE_NAVIGATION: {
    ...template,
    endpoint: "/navigations/page/bulk",
    method: "PUT",
  },
};

const getNavigation = (id: number) => {
  const { host, endpoint } = SERVICES.GET_NAVIGATION;

  SERVICES.GET_NAVIGATION.dynamicUrl = `${host}${endpoint}${id}`;

  return sendRequest(SERVICES.GET_NAVIGATION);
};

const getHeaders = (siteID: number, params?: any) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_HEADERS;

  SERVICES.GET_HEADERS.dynamicUrl = `${host}${prefix}${siteID}${suffix}`;

  if (params) {
    const { page, itemsPerPage, pagination, deleted, include_draft } = params;
    SERVICES.GET_HEADERS.dynamicUrl = `${host}${prefix}${siteID}${suffix}?page=${page}&itemsPerPage=${itemsPerPage}&pagination=${pagination}&deleted=${deleted}&includeDraft=${include_draft}`;
  }

  return sendRequest(SERVICES.GET_HEADERS);
};

const getFooters = (siteID: number, params?: any) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_FOOTERS;

  SERVICES.GET_FOOTERS.dynamicUrl = `${host}${prefix}${siteID}${suffix}`;

  if (params) {
    const { page, itemsPerPage, pagination, deleted, include_draft } = params;
    SERVICES.GET_FOOTERS.dynamicUrl = `${host}${prefix}${siteID}${suffix}?page=${page}&itemsPerPage=${itemsPerPage}&pagination=${pagination}&deleted=${deleted}&includeDraft=${include_draft}`;
  }
  return sendRequest(SERVICES.GET_FOOTERS);
};

const createNavigation = (data: any) => sendRequest(SERVICES.CREATE_NAVIGATION, data);

const updateNavigation = (navID: number, data: any) => {
  const { host, endpoint } = SERVICES.UPDATE_NAVIGATION;

  SERVICES.UPDATE_NAVIGATION.dynamicUrl = `${host}${endpoint}${navID}`;

  return sendRequest(SERVICES.UPDATE_NAVIGATION, data);
};

const deleteNavigation = (navID: number) => {
  const { host, endpoint } = SERVICES.DELETE_NAVIGATION;

  SERVICES.DELETE_NAVIGATION.dynamicUrl = `${host}${endpoint}${navID}`;

  return sendRequest(SERVICES.DELETE_NAVIGATION);
};

const deleteNavigationBulk = (navID: number[]) => {
  return sendRequest(SERVICES.DELETE_BULK, { ids: navID });
};

const restoreNavigation = (navID: number) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.RESTORE_NAVIGATION;

  SERVICES.RESTORE_NAVIGATION.dynamicUrl = `${host}${prefix}${navID}${suffix}`;

  return sendRequest(SERVICES.RESTORE_NAVIGATION);
};

const restoreNavigationBulk = (navID: number[]) => {
  return sendRequest(SERVICES.RESTORE_BULK, { ids: navID });
};

const setDefaultNavigation = (navID: number) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.SET_DEFAULT;

  SERVICES.SET_DEFAULT.dynamicUrl = `${host}${prefix}${navID}${suffix}`;

  return sendRequest(SERVICES.SET_DEFAULT);
};

const getNavigationsLanguages = async (navID: number) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_NAVIGATIONS_LANGUAGES;

  SERVICES.GET_NAVIGATIONS_LANGUAGES.dynamicUrl = `${host}${prefix}${navID}${suffix}`;

  return sendRequest(SERVICES.GET_NAVIGATIONS_LANGUAGES);
};

const getPagesByHeader = async (headerID: number | number[], siteID: number) => {
  const {
    host,
    endpoint: [prefix, infix, suffix],
  } = SERVICES.GET_PAGES_BY_HEADER;

  const ids = Array.isArray(headerID) ? headerID.join(",") : headerID;

  SERVICES.GET_PAGES_BY_HEADER.dynamicUrl = `${host}${prefix}${ids}${infix}${siteID}${suffix}`;

  return sendRequest(SERVICES.GET_PAGES_BY_HEADER);
};

const getPagesByFooter = async (footerID: number | number[], siteID: number) => {
  const {
    host,
    endpoint: [prefix, infix, suffix],
  } = SERVICES.GET_PAGES_BY_FOOTER;

  const ids = Array.isArray(footerID) ? footerID.join(",") : footerID;

  SERVICES.GET_PAGES_BY_FOOTER.dynamicUrl = `${host}${prefix}${ids}${infix}${siteID}${suffix}`;

  return sendRequest(SERVICES.GET_PAGES_BY_FOOTER);
};

const updatePageNavigation = (data: any) => sendRequest(SERVICES.UPDATE_PAGE_NAVIGATION, data);

export default {
  getNavigation,
  getHeaders,
  getFooters,
  updateNavigation,
  deleteNavigation,
  restoreNavigation,
  setDefaultNavigation,
  createNavigation,
  getNavigationsLanguages,
  deleteNavigationBulk,
  restoreNavigationBulk,
  getPagesByHeader,
  getPagesByFooter,
  updatePageNavigation,
};
