import styled from "styled-components";
import { ActionMenu } from "@ax/components";

const SiteWrapper = styled.div`
  background: ${(p) => p.theme.color.uiBackground02};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${(p) => p.theme.spacing.s};
  cursor: pointer;
  div {
    max-width: 100%;
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin-top: ${(p) => p.theme.spacing.xs};
  display: flex;
  align-items: center;
  max-height: 36px;
  width: 100%;
  height: 100%;
  padding-right: 36px;
  justify-content: space-between;
`;

const Title = styled.span`
  ${(p) => p.theme.textStyle.headingXS};
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledActionMenu = styled(ActionMenu)`
  position: absolute;
  right: 0;
  top: 0;
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};

  p:nth-child(1) {
    margin-bottom: ${(p) => p.theme.spacing.m};
  }

  p:nth-child(2) {
    margin-bottom: ${(p) => p.theme.spacing.xxs};
  }
`;

export { SiteWrapper, Wrapper, Title, IconsWrapper, StyledActionMenu, ModalContent };
