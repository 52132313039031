import React from "react";
import { connect } from "react-redux";

import { isDevelopment } from "@ax/helpers";
import { ISite } from "@ax/types";
import { appActions } from "@ax/containers/App";
import { sitesActions } from "@ax/containers/Sites";
import { Tooltip } from "@ax/components";

import { ItemName, ItemThumbnail } from "./../atoms";

import * as S from "./style";

const RecentSiteItem = (props: IRecentSiteItemProps): JSX.Element => {
  const { site, setSiteInfo, setHistoryPush } = props;

  const setSite = async () => {
    await setSiteInfo(site);
    const contentPath = "/sites/pages";
    setHistoryPush(contentPath, false);
  };

  const siteTooltip = isDevelopment() && `Site id: ${site.id}`;

  return (
    <S.RecentSiteItem key={site.id} onClick={setSite} data-testid="recent-sites-item">
      <Tooltip content={siteTooltip} bottom={true}>
        <ItemThumbnail src={site.thumbnail} width={213} height={140} borderRadius={true} />
      </Tooltip>
      <S.Wrapper>
        <S.Title>
          <ItemName name={site.name} length={30} />
        </S.Title>
      </S.Wrapper>
    </S.RecentSiteItem>
  );
};

interface IRecentSiteItemProps {
  site: ISite;
  setSiteInfo(currentSiteInfo: ISite): Promise<void>;
  setHistoryPush(page: string, isEditor: boolean): void;
}

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
  setSiteInfo: sitesActions.setSiteInfo,
};

export default connect(null, mapDispatchToProps)(RecentSiteItem);
