import React from "react";

const SvgPower = props => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 3h2v10h-2V3zm5.41 3.59l1.42-1.42A8.932 8.932 0 0121 12a9 9 0 01-18 0c0-2.74 1.23-5.18 3.17-6.83l1.41 1.41A6.995 6.995 0 0012 19c3.87 0 7-3.13 7-7a6.92 6.92 0 00-2.59-5.41z"
      fill="#5057FF"
    />
  </svg>
);

export default SvgPower;
