import styled, { css } from "styled-components";
import AsyncSelect from "react-select/async";

export const StyledSelect = styled(AsyncSelect)<{
  isDisabled: boolean | undefined;
  error: boolean | undefined;
  maxWidth?: number;
}>`
  ${(p) => p.theme.textStyle.fieldContent};
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xxs};

  .react-select__control {
    background-color: ${(p) => p.theme.color.interactiveBackground};
    box-sizing: border-box;
    border-radius: 4px;
    border-color: ${(p) => (p.error ? p.theme.color.error : p.theme.color.uiLine)};
    box-shadow: none;
    height: ${(p) => p.theme.spacing.l};
    min-width: calc(${(p) => p.theme.spacing.xl} * 4);
    max-width: calc(${(p) => p.theme.spacing.xl} * 6);
    cursor: pointer;

    :hover {
      border-color: ${(p) => (p.error ? p.theme.color.error : p.theme.color.uiLine)};
    }
    .react-select__placeholder {
      color: ${(p) => p.theme.color.textLowEmphasis};
    }
    .react-select__indicator-separator {
      display: none;
    }
    .react-select__indicator {
      color: ${(p) => (p.isDisabled ? p.theme.color.interactiveDisabled : p.theme.color.interactive01)};
    }
  }

  .react-select__control--is-focused {
    border-color: ${(p) => (p.error ? p.theme.color.error : p.theme.color.interactive01)} !important;
  }

  .react-select__control--menu-is-open {
    .react-select__indicator {
      transform: rotate(180deg);
    }
  }

  .react-select__control--is-disabled {
    border-color: ${(p) => p.theme.color.interactiveDisabled};
  }

  .react-select__menu {
    box-shadow: none;
    border-radius: 4px;
    min-width: calc(${(p) => p.theme.spacing.xl} * 4);
    max-width: calc(${(p) => p.theme.spacing.xl} * 6);
    margin-top: 0;
    z-index: 99;
    .react-select__menu-list {
      border: 1px solid ${(p) => p.theme.color.uiLine};
      border-radius: 4px;
      max-height: calc(${(p) => p.theme.spacing.l} * 3);
      overflow: auto;
      padding: 0;
      .react-select__option {
        background-color: ${(p) => p.theme.color.interactiveBackground};
        min-height: ${(p) => p.theme.spacing.l};
        padding: ${(p) => `13px ${p.theme.spacing.s} 12px ${p.theme.spacing.s}`};
        &:first-child {
          ${(p) =>
            !p.mandatory &&
            p.hasEmptyOption &&
            css`
              font-style: italic;
              color: ${(p) => p.theme.color.textLowEmphasis};
              background-color: ${(p) => p.theme.color.uiLineInverse};
            `}
        }
        :hover {
          cursor: pointer;
          background-color: ${(p) => p.theme.color.overlayHoverPrimary};
        }
        :focus {
          background-color: ${(p) => p.theme.color.overlayFocusPrimary};
          border: 0.5px solid ${(p) => p.theme.color.interactive02};
        }
        :active {
          background-color: ${(p) => p.theme.color.overlayPressedPrimary};
        }
      }
      .react-select__option--is-selected {
        color: ${(p) => p.theme.color.textHighEmphasis};
        background-color: ${(p) => p.theme.color.overlayPressedPrimary};
      }
    }
  }

  &.inline {
    ${(p) => p.theme.textStyle.uiS};
    text-transform: capitalize;

    .react-select__control {
      background: transparent;
      border: none;
      height: auto;
      justify-content: ${(p) => (p.alignRight ? "flex-end" : "flex-start")};
      min-width: auto;

      .react-select__value-container {
        flex: 0 1 auto;
        padding: 0;
        .react-select__single-value {
          position: relative;
          transform: none;
          color: ${(p) => (p.error ? p.theme.color.error : p.theme.color.interactive01)};
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: ${(p) => (p.maxWidth ? `${p.maxWidth}px` : `calc(${p.theme.spacing.xl} * 4)`)};
          display: block;
          overflow: hidden;
        }
      }
      .react-select__indicator {
        color: ${(p) => (p.error ? p.theme.color.error : p.theme.color.interactive01)};
        padding: 0;
        svg {
          width: ${(p) => p.theme.spacing.s};
          height: ${(p) => p.theme.spacing.s};
        }
      }
      .react-select__input {
        input {
          cursor: default;
        }
      }
    }

    .react-select__control--is-disabled {
      .react-select__value-container {
        .react-select__single-value {
          color: ${(p) => p.theme.color.interactiveDisabled};
        }
      }
    }

    .react-select__menu {
      ${(p) => p.theme.textStyle.fieldContent};
    }
  }
`;
