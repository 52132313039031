import React from "react";

const SvgCompose = props => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      d="M19.619 11.524h-1.428v-3.81a1.91 1.91 0 00-1.905-1.904h-3.81V4.38a2.382 2.382 0 00-4.762 0V5.81h-3.81A1.902 1.902 0 002.01 7.714v3.62h1.419A2.573 2.573 0 016 13.903a2.573 2.573 0 01-2.571 2.572H2v3.62A1.91 1.91 0 003.905 22h3.619v-1.429A2.573 2.573 0 0110.095 18a2.573 2.573 0 012.572 2.571V22h3.619a1.91 1.91 0 001.904-1.905v-3.81h1.429a2.382 2.382 0 000-4.761z"
      stroke="#5057FF"
      strokeWidth={2}
    />
  </svg>
);

export default SvgCompose;
