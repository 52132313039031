import styled from "styled-components";

const NoDetailsWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.fieldLabel};
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const IconWrapper = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.xs};
  height: 32px;
  svg {
    path {
      fill: ${p => p.theme.color.textLowEmphasis};
    }
  }
`;

export {
  NoDetailsWrapper,
  Title,
  IconWrapper,
}
