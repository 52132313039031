import React from "react";

const getImage = (name: string) => {
  try {
    return require(`./components/${name}`).default;
  } catch (err) {
    return null;
  }
};

const Icon = (props: IProps) => {
  const name =
    props.name &&
    props.name
      .replace(".svg", "")
      .split("-")
      .map((type: any) => type.charAt(0).toUpperCase() + type.slice(1))
      .join("");

  const size = props.size ? props.size : "22";
  const fill = props.fill ? props.fill : "black";

  const viewBox = props.viewBox ? `0 0 ${props.viewBox} ${props.viewBox}` : "0 0 24 24";

  const Svg = getImage(name);

  if (Svg) {
    return (
      <Svg
        data-testid={`icon-component-${name.toLowerCase()}`}
        height={size}
        width={size}
        viewBox={viewBox}
        fill={fill}
      />
    );
  }

  return null;
};

export interface IStateProps {
  name: string;
  size?: string;
  fill?: string;
  viewBox?: number;
}

type IProps = IStateProps;

export default Icon;
