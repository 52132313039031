import { template } from "./config";
import { IServiceConfig, sendRequest } from "./utils";

const SERVICES: { [key: string]: IServiceConfig } = {
  LOGIN: {
    ...template,
    endpoint: "/login_check",
    method: "POST",
    hasToken: false,
  },
  GET_SETTINGS: {
    ...template,
    endpoint: "/settings",
    method: "GET",
    hasToken: false,
  },
  FORGOT_PASSWORD: {
    ...template,
    endpoint: ["/user/", "/password/reset"],
    method: "POST",
    hasToken: false,
  },
  RESET_PASSWORD: {
    ...template,
    endpoint: ["/user/", "/password/new"],
    method: "POST",
    hasToken: false,
  },
  CREATE_PASSWORD: {
    ...template,
    endpoint: ["/user/", "/password/init"],
    method: "POST",
    hasToken: true,
  },
};

const login = async (data?: { username?: string; password?: string; petitionId?: string }) => {
  return sendRequest(SERVICES.LOGIN, data);
};

const getSettings = () => {
  return sendRequest(SERVICES.GET_SETTINGS);
};

const forgotPassword = async (user: string) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.FORGOT_PASSWORD;

  SERVICES.FORGOT_PASSWORD.dynamicUrl = `${host}${prefix}${user}${suffix}`;

  return sendRequest(SERVICES.FORGOT_PASSWORD);
};

const resetPassword = async (userID: any, data: any) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.RESET_PASSWORD;

  SERVICES.RESET_PASSWORD.dynamicUrl = `${host}${prefix}${userID}${suffix}`;

  return sendRequest(SERVICES.RESET_PASSWORD, { ...data });
};

const createPassword = async (userID: any, data: any) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.CREATE_PASSWORD;

  SERVICES.CREATE_PASSWORD.dynamicUrl = `${host}${prefix}${userID}${suffix}`;

  return sendRequest(SERVICES.CREATE_PASSWORD, { ...data });
};

export default {
  login,
  getSettings,
  forgotPassword,
  resetPassword,
  createPassword,
};
