import styled from "styled-components";
import { Header } from "@ax/components/TableList/style";

const TableHeader = styled.div<{ isScrolling?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: ${(p) => p.theme.spacing.m};
  border-bottom: ${(p) => (p.isScrolling ? `1px solid ${p.theme.color.uiLine};` : "")};
`;

const CheckHeader = styled(Header)`
  padding-left: ${(p) => p.theme.spacing.m};
  width: 32px;
`;

const NameHeader = styled(Header)`
  width: 70%;
`;

const DefaultHeader = styled(Header)`
  width: 15%;
`;

const TransHeader = styled(Header)`
  width: 25%;
  justify-content: center;
`;

const ActionsHeader = styled(Header)`
  width: 125px;
  padding-right: 0;
`;

export { TableHeader, CheckHeader, NameHeader, DefaultHeader, TransHeader, ActionsHeader };
