import React from "react";

import { FloatingMenu, Icon, IconAction, Tooltip } from "@ax/components";
import { IActionMenuOption } from "@ax/types";

import * as S from "./style";

const ActionMenu = (props: IActionMenuProps): JSX.Element => {
  const { options, icon, className, tooltip } = props;

  const filteredOptions = options.filter((option: IActionMenuOption | undefined | null) => !!option);

  const MoreInfoButton = () => (
    <S.IconActionWrapper data-testid="more-info-button">
      <IconAction icon={icon} />
    </S.IconActionWrapper>
  );

  const ActionMenuItem = (item: IActionMenuOption) => (
    <S.ActionItem
      key={item.icon}
      onClick={item.action}
      disabled={item.disabled}
      className={item.color ? "original" : ""}
      data-testid="action-menu-item"
    >
      <S.IconWrapper>
        <Icon name={item.icon} size="24" />
      </S.IconWrapper>
      <S.ActionText>
        <S.Label>{item.label}</S.Label>
        {item.helpText && <S.HelpText>{item.helpText}</S.HelpText>}
      </S.ActionText>
    </S.ActionItem>
  );

  return options.length > 0 ? (
    <S.Wrapper className={className} data-testid="action-menu-wrapper">
      {filteredOptions.length > 0 && (
        <Tooltip content={tooltip} hideOnClick>
          <FloatingMenu Button={MoreInfoButton}>
            <S.ActionMenu>
              {filteredOptions.map((item: IActionMenuOption | undefined | null) => item && ActionMenuItem(item))}
            </S.ActionMenu>
          </FloatingMenu>
        </Tooltip>
      )}
    </S.Wrapper>
  ) : (
    <></>
  );
};

export interface IActionMenuProps {
  options: (IActionMenuOption | undefined | null)[];
  icon: string;
  className?: string;
  tooltip?: string;
}

export default ActionMenu;
