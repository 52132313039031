import styled from "styled-components";

import { Cell, Row } from "@ax/components/TableList/TableItem/style";
import { ActionMenu } from "@ax/components";

const CheckCell = styled(Cell)`
  padding-left: ${(p) => p.theme.spacing.m};
  width: 32px;
  label {
    margin-bottom: ${(p) => p.theme.spacing.s};
  }
`;

const SiteCell = styled(Cell)`
  width: 200px;
`;

const UrlCell = styled(Cell)`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textHighEmphasis};
  flex: 1;
`;

const DateCell = styled(Cell)`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  width: 100px;
`;

const ActionsCell = styled(Cell)`
  width: 125px;
`;

const StyledActionMenu = styled(ActionMenu)`
  opacity: 0;
  width: 32px;
  display: flex;
  margin-left: auto;
`;

const ItemRow = styled(Row)`
  &:hover {
    ${StyledActionMenu} {
      opacity: 1;
    }
  }
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};

  p {
    margin-bottom: ${(p) => p.theme.spacing.m};
  }
`;

export {
  CheckCell,
  SiteCell,
  ActionsCell,
  UrlCell,
  StyledActionMenu,
  ItemRow,
  ModalContent,
  DateCell,
};
