import React from "react";
import { CheckGroup, FloatingMenu, Icon, ListTitle } from "@ax/components";
import { IColumn } from "@ax/types";

import * as S from "./style";

const CustomizeFilters = (props: ICustomizeFiltersProps): JSX.Element => {
  const { columns, setColumns, value, limit } = props;

  const options = columns.map((col) => {
    const disabled = value.length >= limit.value && !value.includes(col.id);
    return { name: col.id, title: col.title, value: col.id, disabled };
  });

  const toggleColumn = (selection: any) => {
    const updatedColumns: IColumn[] = [...columns];
    columns.forEach((val) => {
      const columIndex = updatedColumns.findIndex((col) => col.id === val.id);
      if (selection.includes(val.id)) {
        updatedColumns[columIndex].show = true;
      } else {
        updatedColumns[columIndex].show = false;
      }
    });
    setColumns(updatedColumns);
  };

  const Button = () => (
    <S.IconsWrapper>
      <Icon name="settings" size="16" />
    </S.IconsWrapper>
  );

  return (
    <S.Wrapper data-testid="customize-filters-wrapper">
      <FloatingMenu Button={Button} position="right" closeOnSelect={false}>
        <ListTitle>Customize filters</ListTitle>
        <S.Note>You can select up to {limit.text} options</S.Note>
        <S.ChecksWrapper>
          <CheckGroup options={options} value={value} onChange={toggleColumn} />
        </S.ChecksWrapper>
      </FloatingMenu>
    </S.Wrapper>
  );
};

export interface ICustomizeFiltersProps {
  setColumns(newValue: IColumn[]): void;
  columns: IColumn[];
  value: string[];
  limit: { value: number; text: string };
}

export default CustomizeFilters;
