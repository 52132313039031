import React, { Dispatch, SetStateAction } from "react";

import { Button, Modal } from "@ax/components";
import { useModal } from "@ax/hooks";

import SideModal from "./SideModal";
import * as S from "./style";

const NavigationModules = (props: IProps): JSX.Element => {
  const {
    navigationModules,
    form,
    theme,
    setNavigationModulesValue,
    isNavigationModulesChanged,
    setIsNavigationModulesChanged,
  } = props;
  const { isOpen: isModalOpen, toggleModal } = useModal();
  const { isOpen: isSideModalOpen, toggleModal: toggleSideModal } = useModal();

  const buttonText = isNavigationModulesChanged
    ? "Change Navigation modules design"
    : "Select Navigation modules design";

  const handleButtonClick = isNavigationModulesChanged ? toggleModal : toggleSideModal;

  const mainAction = {
    title: "Yes, change design",
    onClick: () => {
      toggleModal();
      toggleSideModal();
    },
  };

  const secondaryAction = {
    title: "Cancel",
    onClick: toggleModal,
  };

  return (
    <S.Wrapper>
      <S.Heading>Navigation Modules</S.Heading>
      <S.Text>
        There are multiple designs for the navigation modules in the library.{" "}
        <strong>Choose the design you want for your site</strong> and add as many headers and footers as you need.
      </S.Text>
      <S.Separator />
      <Button type="button" onClick={handleButtonClick} buttonStyle="line">
        {buttonText}
      </Button>
      <Modal
        isOpen={isModalOpen}
        hide={toggleModal}
        size="S"
        title="Change Design"
        mainAction={mainAction}
        secondaryAction={secondaryAction}
      >
        {
          <S.ModalContent>
            <strong>There is already a navigation modules design defined</strong>. If you change it, headers and footers
            on all pages of the site will be affected. Are you sure you want to proceed with the change?
          </S.ModalContent>
        }
      </Modal>
      <SideModal
        navigationModules={navigationModules}
        form={form}
        toggleModal={toggleSideModal}
        isOpen={isSideModalOpen}
        theme={theme}
        setNavigationModulesValue={setNavigationModulesValue}
        isNavigationModulesChanged={isNavigationModulesChanged}
        setIsNavigationModulesChanged={setIsNavigationModulesChanged}
      />
    </S.Wrapper>
  );
};

interface IProps {
  navigationModules: Record<string, any[]>;
  theme: string;
  form: any;
  setNavigationModulesValue: (value: any) => void;
  isNavigationModulesChanged: boolean;
  setIsNavigationModulesChanged: Dispatch<SetStateAction<boolean>>;
}

export default NavigationModules;
