import React from "react";

import * as S from "./style";

const TableCounter = (props: ITableCounter) => {
  const { totalItems } = props;
  const resultsLabel = totalItems === 1 ? "result" : "results";
  return <S.ResultsCount data-testid="table-counter">{`${totalItems} ${resultsLabel}`}</S.ResultsCount>;
};

export interface ITableCounter {
  totalItems: number;
}

export default TableCounter;
