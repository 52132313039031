import { getSchema } from "@ax/helpers";
import { IPage, ISelectOption } from "@ax/types";

const findSchemaComponentArrays = (component: string) => {
  const keys: string[] = [];
  const schemaTabs = getSchema(component).configTabs;
  if (schemaTabs.length) {
    schemaTabs.forEach((tab: any) => {
      tab.fields.forEach((field: any) => {
        if (field.type === "ComponentArray") {
          keys.push(field.key);
        }
      });
    });
  }
  return keys;
};

const findAnchorsFromModule = (module: any) => {
  if (!module) return [];
  let options: ISelectOption[] = [];

  if (module.anchorID && module.anchorID.trim() !== "") {
    const option = { value: module.anchorID, label: module.anchorID };
    options.push(option);
  }

  const arrayKeys = findSchemaComponentArrays(module.component);
  if (arrayKeys.length) {
    arrayKeys.forEach((key: string) => {
      if (module[key] && module[key].length) {
        module[key].forEach((element: any) => {
          const subOptions = findAnchorsFromModule(element);
          options = [...options, ...subOptions];
        });
      }
    });
  }

  return options;
};

const findAnchorsFromPage = (page: IPage): ISelectOption[] => {
  let options: ISelectOption[] = [];
  const { template } = page;
  const sections = Object.keys(template)
    .map((key: string) => template[key])
    .filter((value: any) => typeof value === "object" && value !== null && value.component === "Section");

  if (sections.length) {
    sections.forEach((section: any) => {
      section.modules.forEach((module: any) => {
        const sectionOptions = findAnchorsFromModule(module);
        options = [...options, ...sectionOptions];
      });
    });
  }
  return options;
};

const findTabsFromPage = (page: IPage): ISelectOption[] => {
  const options: ISelectOption[] = [];
  const { template } = page;
  const sections = Object.keys(template)
    .map((key: string) => template[key])
    .filter((value: any) => typeof value === "object" && value !== null && value.component === "Section");

  if (sections.length) {
    sections.forEach((section: any) => {
      section.modules.forEach((module: any) => {
        if (module.hasGriddoMultiPage && module.elements) {
          module.elements.forEach((element: any) => {
            const option = { value: element.sectionSlug, label: element.title };
            options.push(option);
          });
        }
      });
    });
  }
  return options;
};

const findAnchorsFromTab = (page: IPage, tabSlug: string): ISelectOption[] => {
  let options: ISelectOption[] = [];
  const { template } = page;
  const sections = Object.keys(template)
    .map((key: string) => template[key])
    .filter((value: any) => typeof value === "object" && value !== null && value.component === "Section");

  if (sections.length) {
    sections.forEach((section: any) => {
      section.modules.forEach((module: any) => {
        if (module.hasGriddoMultiPage) {
          const tab = module.elements.find((elem: any) => elem.sectionSlug === tabSlug);
          options = findAnchorsFromModule(tab);
        }
      });
    });
  }
  return options;
};

export { findAnchorsFromPage, findTabsFromPage, findAnchorsFromTab };
