import { useState } from "react";
import { IQueryValue } from "@ax/types";

const useSortedListStatus = () => {
  const sortedInitialState: {
    isAscending: boolean;
    sortedByName: boolean;
    sortedByDate: boolean;
    sortedBySize: boolean;
  } = {
    isAscending: false,
    sortedByName: false,
    sortedByDate: false,
    sortedBySize: false,
  };

  const [sortedListStatus, setSortedListStatus] = useState(sortedInitialState);

  return {
    sortedListStatus,
    setSortedListStatus,
  };
};

const useFilterQuery = () => {
  const initialQueryValues: Record<string, IQueryValue[]> = {
    order: [],
    filterType: [],
    filterUsage: [],
  };

  const [query, setQuery] = useState(initialQueryValues);
  const [currentFilterQuery, setCurrentFilterQuery] = useState("");

  const setFilterQuery = (filterValues: Record<string, IQueryValue[]>) => {
    const { order, filterType, filterUsage } = filterValues;
    let filterQuery = "";

    const currentQuery = (pointer: string, values: IQueryValue[]): string => {
      const stringValues = Array.isArray(values)
        ? values.map((value) => (value.value !== "all" ? value.value : "")).join(",")
        : "";

      return !stringValues.length
        ? filterQuery
        : filterQuery.length
        ? filterQuery.concat(`&${pointer}=${stringValues}`)
        : `${pointer}=${stringValues}`;
    };

    if (order) {
      filterQuery = currentQuery("order", order);
    }

    if (filterType) {
      filterQuery = currentQuery("filterType", filterType);
    }

    if (filterUsage) {
      filterQuery = currentQuery("filterUsage", filterUsage);
    }

    setCurrentFilterQuery(filterQuery);
  };

  const setFiltersSelection = (pointer: string, filter: IQueryValue[], isAscendent?: boolean) => {
    const { order, filterType, filterUsage } = query;
    const orderMethod = isAscendent ? "asc" : "desc";
    const filterValues = {
      order: pointer === "order" ? [{ value: `${filter[0].value}-${orderMethod}`, label: filter[0].label }] : order,
      filterType: pointer === "filterType" ? filter : filterType,
      filterUsage: pointer === "filterUsage" ? filter : filterUsage,
    };

    setFilterQuery(filterValues);
    setQuery(filterValues);
  };

  const resetFilterQuery = () => {
    setQuery(initialQueryValues);
    setCurrentFilterQuery("");
  }

  return {
    setFiltersSelection,
    resetFilterQuery,
    filterValues: query,
    filterQuery: currentFilterQuery,
  };
};

export { useSortedListStatus, useFilterQuery };
