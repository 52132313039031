import React, { forwardRef } from "react";

import * as S from "./style";

const ErrorToast = (props: IProps, ref: any) => {
  return <S.ErrorWrapper id="error" data-testid="error-wrapper" {...props} ref={ref} />;
};

export interface IProps {
  size?: string;
  ref?: any;
  fixed?: boolean;
}

export default forwardRef(ErrorToast);
