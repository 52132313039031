import { deepClone } from "@ax/helpers";

const shouldBeSaved = (user: any, form: any) => {
  const userValues = deepClone(user);
  const formValues = deepClone(form);

  const isDirty = form.id && JSON.stringify(userValues) !== JSON.stringify(formValues);

  return { isDirty };
};

export { shouldBeSaved };
