const BASE_PATH = process.env.REACT_APP_API_ENDPOINT;

const headers = {
  "Content-type": "application/json; charset=UTF-8",
  accept: "application/json",
};

export const template = {
  host: BASE_PATH,
  endpoint: "",
  method: "",
  dynamicUrl: "",
  headers: {
    ...headers,
  },
  hasToken: true,
};
