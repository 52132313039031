import styled from "styled-components";

const Wrapper = styled.div`
  width: 368px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Main = styled.div`
  width: 100%;
`;

const Header = styled.header``;

const Subtitle = styled.p`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasisInverse};
  font-weight: normal;
  margin-bottom: ${(p) => p.theme.spacing.xs};
  width: 280px;
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.headingM};
  color: ${(p) => p.theme.color.textHighEmphasisInverse};
  display: flex;
  margin-bottom: ${(p) => p.theme.spacing.xs};
  align-items: center;
  div:last-child {
    margin-left: ${(p) => p.theme.spacing.xxs};
  }
  img {
    height: 24px;
    margin-top: ${(p) => p.theme.spacing.xxs};
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: ${(p) => p.theme.spacing.m};
`;

const Password = styled.div`
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.color.interactiveInverse};
  width: 100%;
  text-align: right;

  span {
    cursor: pointer;
  }
`;

const Actions = styled.div`
  display: flex;
`;

const Secuoyas = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  position: absolute;
  display: flex;
  color: ${(p) => p.theme.color.textMediumEmphasisInverse};
  justify-content: center;
  align-items: center;
  bottom: ${(p) => p.theme.spacing.m};
  padding-bottom: ${(p) => p.theme.spacing.xs};
  width: 100%;
  img {
    width: 20px;
    height: 20px;
    margin-left: ${(p) => p.theme.spacing.xs};
  }
`;

export { Wrapper, Main, Header, Subtitle, Title, Form, Password, Actions, Secuoyas };
