"use strict";

exports.__esModule = true;
exports.default = exports.AspectRatio = void 0;
var _react = _interopRequireDefault(require("./react-15.6"));
var _reactLatest = _interopRequireDefault(require("./react-latest"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = _react.default; // use before react 15.6
exports.default = _default;
var AspectRatio = _reactLatest.default;
exports.AspectRatio = AspectRatio;