import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { IRootState } from "@ax/types";
import { appActions } from "@ax/containers/App";
import { Login } from "@ax/components";
import { IGlobalSettings } from "@ax/containers/App/reducer";
import { errorText } from "./constants";

const LoginModule = (props: IProps) => {
  const { isLoggingIn, globalSettings, login, loginSSO, resetError, getGlobalSettings, setHistoryPush } = props;

  const { petitionId } = useParams<{ petitionId?: string }>();

  const errorSSO = petitionId && Object.keys(errorText).includes(petitionId) ? errorText[petitionId] : null;

  const initState = {
    email: "",
    password: "",
    rememberMe: false,
  };

  const [state, setState] = useState(initState);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const handleLogin = async () => {
      const isLogged = await login(undefined, undefined, undefined, petitionId);
      if (isLogged) {
        setIsSuccess(true);
      }
    };

    if (petitionId && !errorSSO) {
      handleLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petitionId, errorSSO]);

  useEffect(() => {
    const prefix = process.env.REACT_APP_SITE_TITLE ? process.env.REACT_APP_SITE_TITLE : "";
    document.title = `${prefix} ${globalSettings.welcomeText2}`;
  }, [globalSettings]);

  useEffect(() => {
    getGlobalSettings();
  }, [getGlobalSettings]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isLogged = await login(state.email, state.password, state.rememberMe);
    if (isLogged) {
      setIsSuccess(true);
    }
  };

  const handleClickSSO = async () => {
    const urlSSO = await loginSSO();
    if (urlSSO) {
      window.open(urlSSO, "_self");
    }
  };

  const _handleEmail = (email: string) => {
    resetError();
    setState({ ...state, email });
  };

  const handlePassword = (password: string) => {
    resetError();
    setState({ ...state, password });
  };

  const handleLoginSuccess = () => {
    const welcomePageURI = "/sites";
    setHistoryPush(welcomePageURI);
  };

  const _handleRememberMe = () => setState({ ...state, rememberMe: !state.rememberMe });

  return (
    <Login
      handleSubmit={handleSubmit}
      email={state.email}
      handleEmail={_handleEmail}
      password={state.password}
      handlePassword={handlePassword}
      isLoggingIn={isLoggingIn}
      settings={globalSettings}
      rememberMe={state.rememberMe}
      handleRememberMe={_handleRememberMe}
      isLoginSuccess={isSuccess}
      handleLoginSuccess={handleLoginSuccess}
      handleSSO={handleClickSSO}
      errorSSO={errorSSO}
    />
  );
};

interface IProps {
  isLoggingIn: boolean;
  children: any;
  globalSettings: IGlobalSettings;
  login(email?: string, password?: string, rememberMe?: boolean, petitionId?: string): Promise<boolean>;
  loginSSO(): Promise<string | null>;
  resetError(): void;
  getGlobalSettings(): void;
  setHistoryPush(path: string): Promise<void>;
}

const mapDispatchToProps = {
  login: appActions.login,
  loginSSO: appActions.loginSSO,
  resetError: appActions.resetError,
  getGlobalSettings: appActions.getGlobalSettings,
  setHistoryPush: appActions.setHistoryPush,
};

const mapStateToProps = (state: IRootState) => ({
  isLoggingIn: state.app.isLoggingIn,
  globalSettings: state.app.globalSettings,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginModule);
