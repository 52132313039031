import * as React from "react";

import { ModulePreview, type ModulePreviewProps } from "../ModulePreview";
import { Page, type PageProps } from "../Page";

/**
 * <Preview>
 */
function Preview(props: PreviewProps) {
	const { isPage = false, ...rest } = props;

	const pageProps = rest as PageProps;
	const moduleProps = rest as ModulePreviewProps;

	return isPage ? <Page {...pageProps} /> : <ModulePreview {...moduleProps} />;
}

export type PreviewProps = { isPage?: boolean } & (
	| ModulePreviewProps
	| PageProps
);

export { Preview };
