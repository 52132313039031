import React from "react";

import { useModal } from "@ax/hooks";
import { Modal, Tooltip } from "@ax/components";
import { IIntegrationVariable, ILanguage, IVariableLang } from "@ax/types";
import { trimText } from "@ax/helpers";
import VariablePanel from "../VariablePanel";

import * as S from "./style";

const VariableItem = (props: IProps): JSX.Element => {
  const { item, deleteVariable, siteLangs, editVariable } = props;

  const { isOpen, toggleModal } = useModal();
  const { isOpen: isRemoveOpen, toggleModal: toggleRemoveModal } = useModal();

  const handleClick = () => toggleModal();

  const handleDelete = () => {
    deleteVariable();
    toggleRemoveModal();
  };

  const variableOptions = [
    {
      label: "Delete",
      icon: "delete",
      action: toggleRemoveModal,
    },
  ];

  const mainRemoveModalAction = {
    title: "Delete variable",
    onClick: handleDelete,
  };

  const getMultilanguageValues = (langValues: IVariableLang[]): string => {
    let textValue = "";
    langValues.forEach((l: IVariableLang, i: number) => {
      const language = siteLangs.find((lang: ILanguage) => lang.id === l.languageId);
      textValue += `${language?.language}: ${l.value}${langValues.length - 1 !== i ? "; " : ""}`;
    });
    return textValue;
  };

  const secondaryRemoveModalAction = { title: "Cancel", onClick: toggleRemoveModal };

  const VariableTitle = () => {
    const textValue = item.multilanguage?.length ? getMultilanguageValues(item.multilanguage) : item.defaultValue;
    return textValue.length > 75 ? (
      <Tooltip content={textValue}>
        <S.Values>{trimText(textValue, 75)}</S.Values>
      </Tooltip>
    ) : (
      <S.Values>{textValue}</S.Values>
    );
  };

  return (
    <>
      <S.Component onClick={handleClick}>
        <S.Name>{item.variableName}</S.Name>
        <VariableTitle />
        <S.StyledActionMenu icon="more" options={variableOptions} tooltip="Variable actions" />
      </S.Component>
      {isOpen && (
        <VariablePanel onClick={editVariable} langs={siteLangs} isOpen={isOpen} toggleModal={toggleModal} item={item} />
      )}
      <Modal
        isOpen={isRemoveOpen}
        hide={toggleRemoveModal}
        title="Delete Variable"
        secondaryAction={secondaryRemoveModalAction}
        mainAction={mainRemoveModalAction}
        size="S"
      >
        <S.ModalContent>
          <p>
            Are you sure you want to delete <strong>{item.variableName} variable</strong>? Don&apos;t forget to{" "}
            <strong>remove it from the code</strong> as well. This action <strong>cannot be undone</strong>.
          </p>
        </S.ModalContent>
      </Modal>
    </>
  );
};

interface IProps {
  item: IIntegrationVariable;
  deleteVariable: () => void;
  editVariable: (variable: IIntegrationVariable) => void;
  siteLangs: ILanguage[];
}

export default VariableItem;
