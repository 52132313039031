import React, { useState } from "react";
import { connect } from "react-redux";

import { menuActions } from "@ax/containers/Navigation";
import { IRootState } from "@ax/types";
import { IconAction } from "@ax/components";
import { Restricted } from "@ax/guards";
import { useModal } from "@ax/hooks";

import SidePanel from "../SidePanel";

import * as S from "./style";

const Header = (props: IProps): JSX.Element => {
  const { resetItemValues, totalItems } = props;

  const title = `${totalItems} item${totalItems !== 1 ? "s" : ""}`;

  const { isOpen, toggleModal } = useModal();
  const [isOpenedSecond, setIsOpenedSecond] = useState(false);

  const toggleSecondaryPanel = () => setIsOpenedSecond(!isOpenedSecond);
  const resetValues = () => resetItemValues();

  const openModal = () => {
    resetValues();
    setIsOpenedSecond(false);
    toggleModal();
  };

  return (
    <S.Header>
      <S.Title>{title}</S.Title>
      <Restricted to="navigation.manageSiteMenu">
        <IconAction icon="add" size="m" onClick={openModal} />
      </Restricted>
      <SidePanel
        isOpen={isOpen}
        isOpenedSecond={isOpenedSecond}
        toggleModal={toggleModal}
        toggleSecondaryPanel={toggleSecondaryPanel}
      />
    </S.Header>
  );
};
interface ITableHeaderProps {
  totalItems: number;
}

const mapStateToProps = (state: IRootState) => ({
  totalItems: state.menu.totalItems,
});

interface IDispatchProps {
  resetItemValues(): void;
}

type IProps = ITableHeaderProps & IDispatchProps;

const mapDispatchToProps = {
  resetItemValues: menuActions.resetItemValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
