const NAME = "structuredData";

const SET_IS_ACTIVE = `${NAME}/SET_IS_ACTIVE`;
const SET_CATEGORIES = `${NAME}/SET_CATEGORIES`;
const SET_STRUCTURED_DATA = `${NAME}/SET_STRUCTURED_DATA`;
const SET_CURRENT_STRUCTURED_DATA = `${NAME}/SET_CURRENT_STRUCTURED_DATA`;
const SET_CURRENT_STRUCTURED_DATA_ID = `${NAME}/SET_CURRENT_STRUCTURED_DATA_ID`;
const SET_CURRENT_STRUCTURED_DATA_CONTENTS = `${NAME}/SET_CURRENT_STRUCTURED_DATA_CONTENTS`;
const SET_CURRENT_DATA_CATEGORY = `${NAME}/SET_CURRENT_CATEGORIES`;
const SET_SCHEMA = `${NAME}/SET_SCHEMA`;
const CREATE_FORM = `${NAME}/CREATE_FORM`;
const UPDATE_FORM = `${NAME}/UPDATE_FORM`;
const SET_ENTITY = `${NAME}/SET_ENTITY`;
const SET_FILTER: string | null = `${NAME}/SET_FILTER`;
const SET_SCHEMA_VERSION: string | null = `${NAME}/SET_SCHEMA_VERSION`;
const SET_ERRORS = `${NAME}/SET_ERRORS`;
const SET_VALIDATED = `${NAME}/SET_VALIDATED`;
const SET_CONTENT_FILTERS = `${NAME}/SET_CONTENT_FILTERS`;
const SET_IS_IA_TRANSLATED = `${NAME}/SET_IS_IA_TRANSLATED`;
const SET_CURRENT_SEARCH = `${NAME}/SET_CURRENT_SEARCH`;

const ITEMS_PER_PAGE = 50;

const DEFAULT_PARAMS = {
  page: 1,
  itemsPerPage: ITEMS_PER_PAGE,
  pagination: true,
  deleted: false,
  include_draft: true,
};

export {
  NAME,
  SET_CATEGORIES,
  SET_STRUCTURED_DATA,
  SET_CURRENT_STRUCTURED_DATA,
  SET_CURRENT_STRUCTURED_DATA_ID,
  SET_CURRENT_STRUCTURED_DATA_CONTENTS,
  SET_CURRENT_DATA_CATEGORY,
  SET_SCHEMA,
  CREATE_FORM,
  UPDATE_FORM,
  SET_IS_ACTIVE,
  SET_ENTITY,
  ITEMS_PER_PAGE,
  DEFAULT_PARAMS,
  SET_FILTER,
  SET_SCHEMA_VERSION,
  SET_ERRORS,
  SET_VALIDATED,
  SET_CONTENT_FILTERS,
  SET_IS_IA_TRANSLATED,
  SET_CURRENT_SEARCH,
};
