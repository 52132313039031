import styled from "styled-components";

const UrlFieldWrapper = styled.div``;

const PageSelectedWrapper = styled.div`
  display: flex;
  box-shadow: ${(p) => p.theme.shadow.shadowS};
  background-color: ${(p) => p.theme.color.uiBarBackground};
  border: 1px solid transparent;
  border-radius: ${(p) => p.theme.radii.s};
  :hover {
    background-color: ${(p) => p.theme.color.overlayHoverPrimary};
    cursor: pointer;
  }
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const PageField = styled.div`
  flex: 1;
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.color.interactive01};
  min-height: ${(p) => p.theme.spacing.l};
  height: auto;
  padding: ${(p) => `${p.theme.spacing.s} 0 ${p.theme.spacing.s} ${p.theme.spacing.s}`};
  width: 100%;
`;

const IconWrapper = styled.div`
  position: relative;
  width: ${(p) => `calc((${p.theme.spacing.xs} * 2)  + ${p.theme.spacing.m})`};
  button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
      width: ${(p) => p.theme.spacing.m};
      height: ${(p) => p.theme.spacing.m};
    }
  }
`;

const AdvancedWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.s};
  & > div {
    margin-bottom: ${(p) => p.theme.spacing.xs};
    :last-child {
      margin-bottom: ${(p) => p.theme.spacing.s};
    }
  }
`;

const AnchorWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.s};
`;

const Wrapper = styled.div`
  padding: ${(p) => p.theme.spacing.m};
  height: 100%;
`;

export { PageSelectedWrapper, PageField, IconWrapper, AdvancedWrapper, AnchorWrapper, UrlFieldWrapper, Wrapper };
