import React from "react";

const SvgDeactivate = (props) => (
  <svg width={24} height={24} fill="none" {...props}>
    <g>
      <path
        d="M19,6.3H4.9C2.2,6.3,0,8.8,0,12s2.2,5.7,4.9,5.7H19c2.8,0,4.9-2.5,4.9-5.7S21.7,6.3,19,6.3z M5.8,16.4 c-2.3,0-4.2-1.9-4.2-4.4s1.9-4.4,4.2-4.4C8.2,7.6,10,9.5,10,12S8.1,16.4,5.8,16.4z"
        fill="#C7C8D0"
      />
    </g>
  </svg>
);

export default SvgDeactivate;
