import styled from "styled-components";

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};
`;

const LoadingWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const TotalWrapper = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

const ItemList = styled.div`
  overflow: auto;
`;

const Header = styled.div`
  display: flex;
  border-bottom: ${(p) => `1px solid ${p.theme.color.uiLine}`};
  padding: ${(p) => `${p.theme.spacing.xs} ${p.theme.spacing.xs} 0 ${p.theme.spacing.xs}`};
  margin-bottom: ${(p) => p.theme.spacing.xs};
  min-height: 45px;
`;

const Item = styled.div`
  display: flex;
  border-bottom: ${(p) => `1px solid ${p.theme.color.uiLine}`};
  padding: ${(p) => p.theme.spacing.xs};
`;

const CheckWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const PageInfo = styled.div`
  flex-grow: 2;
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const PagePath = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
`;

const Text = styled.div`
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
`;

const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Tag = styled.div<{ active: boolean}>`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  background-color: ${(p) => p.active ? p.theme.color?.interactive02 : p.theme.colors.uiBackground03};
  box-sizing: border-box;
  border-radius: ${(p) => p.theme.radii.xs};
  white-space: nowrap;
  display: inline-block;
  padding: 3px 8px;
  text-transform: capitalize;
`;

export {
  ModalContent,
  LoadingWrapper,
  TotalWrapper,
  ItemList,
  Header,
  Item,
  PageInfo,
  PagePath,
  Title,
  HeaderInfo,
  Tag,
  CheckWrapper,
  Text,
};
