import * as React from "react";

function SvgFilter(props) {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M3 6v2h18V6H3zm7 12h4v-2h-4v2zm8-5H6v-2h12v2z" fill="#5057FF" />
    </svg>
  );
}

export default SvgFilter;
