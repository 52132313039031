import { IRedirect } from "@ax/types";
import { SET_IMPORTS, SET_REDIRECTS, SET_TOTAL_IMPORTS, SET_TOTAL_ITEMS } from "./constants";
import { RedirectsActionsCreators } from "./interfaces";

export interface IRedirectsState {
  redirects: any[];
  totalItems: number;
  totalImports: number;
  imports: null | {
    error: IRedirect[];
    existing: IRedirect[];
    ok: IRedirect[];
  };
}

export const initialState = {
  redirects: [],
  totalItems: 0,
  totalImports: 0,
  imports: null,
};

export function reducer(state = initialState, action: RedirectsActionsCreators): IRedirectsState {
  switch (action.type) {
    case SET_REDIRECTS:
    case SET_TOTAL_ITEMS:
    case SET_IMPORTS:
    case SET_TOTAL_IMPORTS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export { initialState as redirectsInitialState, reducer as redirectsReducer };
