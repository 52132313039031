import { useState } from "react";
import { IQueryValue } from "@ax/types";

const useSortedListStatus = () => {
  const sortedInitialState: {
    isAscending: boolean;
    sortedByName: boolean;
    sortedByDate: boolean;
    sortedBySize: boolean;
  } = {
    isAscending: false,
    sortedByName: false,
    sortedByDate: false,
    sortedBySize: false,
  };

  const [sortedListStatus, setSortedListStatus] = useState(sortedInitialState);

  return {
    sortedListStatus,
    setSortedListStatus,
  };
};

const useFilterQuery = () => {
  const initialQueryValues: Record<string, IQueryValue[]> = {
    orientation: [],
    order: [],
    format: [],
  };

  const [query, setQuery] = useState(initialQueryValues);
  const [currentFilterQuery, setCurrentFilterQuery] = useState("");

  const setFilterQuery = (filterValues: Record<string, IQueryValue[]>) => {
    const { orientation, order, format } = filterValues;
    let filterQuery = "";

    const currentQuery = (pointer: string, values: IQueryValue[]): string => {
      const stringValues = Array.isArray(values)
        ? values.map((value) => (value.value !== "all" ? value.value : "")).join(",")
        : "";

      return !stringValues.length
        ? filterQuery
        : filterQuery.length
        ? filterQuery.concat(`&${pointer}=${stringValues}`)
        : `&${pointer}=${stringValues}`;
    };

    if (orientation) {
      filterQuery = currentQuery("orientation", orientation);
    }

    if (order) {
      filterQuery = currentQuery("order", order);
    }

    if (format) {
      filterQuery = currentQuery("format", format);
    }

    setCurrentFilterQuery(filterQuery);
  };

  const setFiltersSelection = (pointer: string, filter: IQueryValue[], isAscendent?: boolean) => {
    const { orientation, order, format } = query;
    const orderMethod = isAscendent ? "asc" : "desc";
    const filterValues = {
      orientation: pointer === "orientation" ? filter : orientation,
      order: pointer === "order" ? [{ value: `${filter[0].value}-${orderMethod}`, label: filter[0].label }] : order,
      format: pointer === "format" ? filter : format,
    };

    setQuery(filterValues);
    setFilterQuery(filterValues);
  };

  const resetFilterQuery = () => {
    setQuery(initialQueryValues);
    setCurrentFilterQuery("");
  };

  return {
    setFiltersSelection,
    resetFilterQuery,
    filterValues: query,
    filterQuery: currentFilterQuery,
  };
};

export { useSortedListStatus, useFilterQuery };
