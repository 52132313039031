import React from "react";

const SvgCopy = (props) => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5263 2.3158H4.78947C3.80526 2.3158 3 3.12106 3 4.10527V16.6316H4.78947V4.10527H15.5263V2.3158ZM18.2105 5.89475H8.36842C7.38421 5.89475 6.57894 6.70001 6.57894 7.68422V20.2105C6.57894 21.1947 7.38421 22 8.36842 22H18.2105C19.1947 22 20 21.1947 20 20.2105V7.68422C20 6.70001 19.1947 5.89475 18.2105 5.89475ZM8.36843 20.2105H18.2105V7.68422H8.36843V20.2105Z"
      fill="#5057FF"
    />
  </svg>
);

export default SvgCopy;