import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: ${(p) => p.theme.spacing.xl};
  flex-direction: column;
`;

const ListWrapper = styled.div`
  overflow: auto;
  flex: 1;
`;

const PageList = styled.ul`
  height: auto;
`;

const SearchWrapper = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const LoadingWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Text = styled.div`
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

const LowText = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textLowEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${(p) => p.theme.spacing.s};
`;

const LanguageSiteFilters = styled.div`
  display: flex;
  & > *:first-child {
    margin-right: ${(p) => p.theme.spacing.s};
  }
  & > *:last-child {
    flex-grow: 1;
  }
`;

const ContentTypeFilter = styled.div`
  margin-top: ${(p) => p.theme.spacing.xs};
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: ${(p) => p.theme.spacing.s};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export {
  Wrapper,
  ListWrapper,
  PageList,
  SearchWrapper,
  LoadingWrapper,
  Text,
  LowText,
  PaginationWrapper,
  LanguageSiteFilters,
  ContentTypeFilter,
  ActionWrapper,
  ButtonWrapper,
};
