import { Dispatch } from "redux";
import { ISiteLanguage } from "@ax/types";
import { handleRequest } from "@ax/helpers";
import { languages } from "@ax/api";
import { appActions } from "@ax/containers/App";
import { sitesActions } from "@ax/containers/Sites";
import { UPDATE_FORM, CREATE_FORM } from "./constants";
import { languagesInitialState } from "./reducer";

const { setIsSaving, setIsLoading } = appActions;
const { getSiteLanguages } = sitesActions;
function setForm(form: any) {
  return { type: CREATE_FORM, payload: { form } };
}

function updateForm(form: any) {
  return { type: UPDATE_FORM, payload: { form } };
}

function updateFormValue(valueObj: any): (dispatch: Dispatch, getState: any) => void {
  return (dispatch, getState) => {
    const {
      languages: { form },
    } = getState();
    const updatedForm = { ...form, ...valueObj };
    dispatch(updateForm(updatedForm));
  };
}

function resetForm(): (dispatch: Dispatch) => void {
  return (dispatch) => {
    const { form } = languagesInitialState;
    dispatch(updateForm(form));
  };
}

function createSiteLanguage(siteID: number, data: ISiteLanguage): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      const responseActions = {
        handleSuccess: () => getSiteLanguages(siteID)(dispatch),
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };

      const callback = async () => languages.createSiteLanguage(siteID, data);

      await handleRequest(callback, responseActions, [setIsSaving])(dispatch);
    } catch (e) {
      console.log(e); // TODO: capturar error bien
    }
  };
}

function updateSiteLanguage(siteID: number, data: ISiteLanguage): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      const responseActions = {
        handleSuccess: () => getSiteLanguages(siteID)(dispatch),
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };

      const callback = async () => languages.updateSiteLanguage(siteID, data);

      await handleRequest(callback, responseActions, [setIsSaving])(dispatch);
    } catch (e) {
      console.log(e); // TODO: capturar error bien
    }
  };
}

function deleteSiteLanguage(siteID: number, id: number): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      const responseActions = {
        handleSuccess: () => getSiteLanguages(siteID)(dispatch),
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };

      const callback = async () => languages.deleteSiteLanguage(siteID, id);

      await handleRequest(callback, responseActions, [setIsLoading])(dispatch);
    } catch (e) {
      console.log(e); // TODO: capturar error bien
    }
  };
}

export { createSiteLanguage, updateSiteLanguage, setForm, updateForm, updateFormValue, resetForm, deleteSiteLanguage };
