import styled from "styled-components";

export const NavLink = styled.a`
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
`;

export const GoBack = styled.div`
  display: none;
  width: auto;
`;

export const Home = styled.div<{ type: string; isSite: boolean; isOpened: boolean; siteSelector: boolean }>`
  padding: ${(p) => `${p.theme.spacing.xs} 12px`};
  background-color: ${(p) =>
    p.type === "multisite" ? p.theme.color.uiMainMenuBackground : p.theme.color.uiBarBackground};
  height: calc(${(p) => p.theme.spacing.xxs} * 17);
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.isOpened ? "flex-start" : "center")};
  border-right: 1px solid ${(p) => p.theme.color.uiLine};
  margin-right: -1px;

  & + ul {
    margin-top: ${(p) => p.theme.spacing.s};
  }
  cursor: ${(p) => (p.siteSelector ? "default" : "pointer")};

  &:hover {
    & > ${NavLink} {
      display: ${(p) => (p.isSite ? "none" : "flex")};
    }

    & > ${GoBack} {
      display: block;
    }
  }
`;

export const Logo = styled.img`
  max-height: 40px;
  height: 100%;
  width: auto;
  overflow: hidden;
  transition: opacity 0.25s;
  opacity: 0;
  position: absolute;
  left: 0;
`;

export const LogoSite = styled.img`
  display: block;
  max-height: 40px;
  height: 100%;
  width: auto;
  overflow: hidden;
  transition: opacity 0.95s;
  opacity: 0;
  position: absolute;
  left: 0;
`;

export const LogoSiteMini = styled.img`
  margin: 0 auto;
  max-height: 40px;
  height: 100%;
  width: auto;
  overflow: hidden;
  transition: opacity 0.25s;
  opacity: 1;
`;

export const Title = styled.span`
  bottom: -3px;
  position: relative;
  display: flex;
  align-items: flex-end;
  width: ${(p) => p.theme.spacing.xxxl};
  color: ${(p) => p.theme.color.textHighEmphasisInverse};
`;

export const NavWrapper = styled.nav<{ type: string; isOpened: boolean }>`
  ${(p) => p.theme.textStyle.uiL};
  position: relative;
  width: ${(p) => (p.isOpened ? `calc(${p.theme.spacing.l} * 5)` : `calc(${p.theme.spacing.m} * 3)`)};
  transition: width 0.5s;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  background-color: ${(p) => (p.type === "multisite" ? p.theme.color.uiMainMenuBackground : p.theme.color.uiSiteMenu)};
  color: ${(p) => p.theme.color.textMediumEmphasisInverse};
  padding-bottom: ${(p) => p.theme.spacing.s};

  a {
    color: inherit;
    text-decoration: none;
    & ${LogoSite}, ${Logo}, ${Title} {
      opacity: ${(p) => (p.isOpened ? `1` : `0`)};
    }
    & ${LogoSiteMini} {
      opacity: ${(p) => (p.isOpened ? `0` : `1`)};
    }
  }
`;

export const Lists = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-top: ${(p) => p.theme.spacing.s};
`;

export const List = styled.ul``;

export const Separator = styled.li`
  border-bottom: 1px solid ${(p) => p.theme.color.uiLineInverse};
  margin: ${(p) => `${p.theme.spacing.s} 0`};
`;
