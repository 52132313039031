import styled from "styled-components";

const ModalContent = styled.div`
  display: flex;
  max-height: calc(640px - 58px);
  width: 100%;
  overflow: hidden;
`;

const RoleList = styled.div`
  padding: ${(p) => `${p.theme.spacing.s} ${p.theme.spacing.m}`};
  overflow: auto;
  width: 100%;
`;

const SidePanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background-color: ${(p) => p.theme.color.uiBarBackground};
  width: ${(p) => `calc(${p.theme.spacing.xl} * 5)`};
  border-left: 1px solid ${(p) => p.theme.color.uiLine};
  flex-shrink: 0;
`;

const PermissionsList = styled.div``;

const PermissionsWrapper = styled.div`
  height: 340px;
  margin-right: -${(p) => p.theme.spacing.m};
  overflow: auto;
`;

const PermissionsListTitle = styled.div`
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  ${(p) => p.theme.textStyle.uiS};
  margin-top: ${(p) => p.theme.spacing.s};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const Permission = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const RoleInfo = styled.div`
  padding: ${(p) => p.theme.spacing.m};
  height: calc(640px - 58px);
`;

const IconWrapper = styled.span`
  padding-right: ${(p) => p.theme.spacing.s};
  height: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  bottom: 0;
  position: absolute;
  height: 72px;
  width: ${(p) => `calc(${p.theme.spacing.xl} * 5)`};
  background-color: ${(p) => p.theme.color.uiBarBackground};
  border-top: 1px solid ${(p) => p.theme.color.uiLine};
  justify-content: flex-end;
  padding: ${(p) => `${p.theme.spacing.s} ${p.theme.spacing.m} ${p.theme.spacing.m} ${p.theme.spacing.m}`};
`;

const Selected = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

const ModalContentPadding = styled.div`
  display: flex;
  height: 100%;
  wdith: 100%;
  padding: ${(p) => p.theme.spacing.m};
`;

export {
  ModalContent,
  RoleList,
  SidePanel,
  PermissionsList,
  PermissionsListTitle,
  PermissionsWrapper,
  Permission,
  IconWrapper,
  ButtonWrapper,
  RoleInfo,
  Selected,
  ModalContentPadding,
};
