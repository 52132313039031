import React from "react";

import { RadioGroup } from "@ax/components";
import * as S from "./style";

const ConditionalField = (props: IConditionalFieldProps) => {
  const { value, options, innerFields, onChange, defaultValue, disabled } = props;

  const safeValue = value === undefined ? defaultValue : value;

  const handleChange = (newValue: any) => onChange(newValue);

  return (
    <S.Wrapper>
      <RadioGroup name="radio" value={safeValue} options={options} onChange={handleChange} disabled={disabled} />
      <S.Content data-testid="conditionalFieldContent">
        {innerFields &&
          innerFields.map((item: any) => {
            return value === item.props.field.condition && item;
          })}
      </S.Content>
    </S.Wrapper>
  );
};

export default ConditionalField;

interface IConditionalFieldProps {
  value?: string | boolean;
  options: any[];
  innerFields: any;
  onChange: (value: any) => void;
  defaultValue?: string | boolean;
  disabled?: boolean;
}
