import React from "react";
import styled from "styled-components";
import MultiCheckSelect from "../MultiCheckSelect";
import NoteField from "../NoteField";

const StyledMultiCheckSelect = styled((props) => <MultiCheckSelect {...props} />)`
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const StyledNoteField = styled(NoteField)`
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

export { StyledMultiCheckSelect, StyledNoteField };
