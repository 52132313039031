import styled from "styled-components";

const CategoryListWrapper = styled.div`
  display: flex;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: calc(100vh - ${(p) => p.theme.spacing.xl});
  overflow: auto;
`;

const EmptyWrapper = styled.div`
  height: ${(p) => `calc(100vh - (${p.theme.spacing.xl} * 3))`};
  display: flex;
  align-items: center;
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};

  p {
    margin-bottom: ${(p) => p.theme.spacing.m};
  }
`;

const Droppable = styled.div`
  width: 100%;

  [data-react-beautiful-dnd-droppable] {
    padding-bottom: 100px;
  }
`;

const Draggable = styled.div``;

const Notification = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  background-color: ${(p) => p.theme.color.uiBackground03};
  margin-bottom: ${(p) => p.theme.spacing.xs};
  border-radius: ${(p) => p.theme.radii.s};
  padding: ${(p) => p.theme.spacing.s};
`;

const Intro = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  padding: ${(p) => p.theme.spacing.m};
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
`;

const IntroTitle = styled.div`
  ${(p) => p.theme.textStyle.headingM};
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const SearchTags = styled.div`
  & > div:nth-child(1) {
    margin-bottom: ${(p) => p.theme.spacing.xs};
  }
  & > div:nth-child(2) {
    margin-bottom: ${(p) => p.theme.spacing.xs};
  }
`;

export {
  CategoryListWrapper,
  TableWrapper,
  EmptyWrapper,
  ModalContent,
  Draggable,
  Droppable,
  Notification,
  Intro,
  IntroTitle,
  SearchTags,
};
