import React, { memo } from "react";

import * as S from "./style";

const Item = (props: IProps) => {
  const { item, type } = props;

  return (
    <S.ItemWrapper key={item.title}>
      <S.ItemImage><img src={item.thumbnails && item.thumbnails["1x"]} alt={item.title} /></S.ItemImage>
      <S.ItemData>
        <S.ItemType>{type}</S.ItemType>
        <S.ItemTitle>{item.title}</S.ItemTitle>
      </S.ItemData>
    </S.ItemWrapper>
  );
};

interface IProps {
  item: any;
  type: string
}

export default memo(Item);
