import React from "react";
import styled from "styled-components";
import { Button } from "@ax/components";

const Wrapper = styled.div`
  background-color: ${(p) => p.theme.color.uiBackground03};
  padding: ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.radii.s};
`;

const Text = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledButton = styled((props) => <Button {...props} />)`
  width: 100%;
  max-width: 264px;
`;

const ErrorText = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.error};
  margin-top: ${(p) => p.theme.spacing.xs};
`;

export { Wrapper, Text, ButtonWrapper, StyledButton, ErrorText };
