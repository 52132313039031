export default {
  schemaType: "page",
  displayName: "Page",
  component: "GlobalPage",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "",
          type: "TranslateButton",
          key: "translate",
        },
        {
          title: "Title",
          type: "TextField",
          key: "title",
          mandatory: true,
        },
        {
          title: "Template",
          key: "template",
          type: "template",
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Canonical Site",
          key: "canonicalSite",
          type: "AsyncSelect",
          entity: "sites",
          mandatory: true,
          helptext: "Select the owner site of this page",
        },
        {
          title: "Slug",
          type: "TextField",
          key: "slug",
        },
        {
          title: "Template",
          key: "template",
          type: "template",
        },
      ],
    },
    {
      title: "SEO & Analytics",
      fields: [
        {
          title: "SEO Data",
          type: "FieldGroup",
          key: "seoData",
          collapsed: true,
          fields: [
            {
              title: "",
              type: "SummaryButton",
              key: "summary",
            },
            {
              title: "Meta title",
              type: "TextField",
              key: "metaTitle",
            },
            {
              title: "Meta description",
              type: "TextArea",
              key: "metaDescription",
            },
            {
              title: "Keywords",
              type: "TagsField",
              key: "metaKeywords",
            },
            {
              title: "Canonical URL",
              type: "TextField",
              key: "canonicalURL",
            },
            {
              title: "Meta robots index",
              type: "RadioGroup",
              key: "isIndexed",
              options: [
                {
                  value: true,
                  title: "Index",
                  name: "index",
                },
                {
                  value: false,
                  title: "No index",
                  name: "noindex",
                },
              ],
            },
            {
              title: "Meta robots follow",
              type: "RadioGroup",
              key: "follow",
              options: [
                {
                  value: true,
                  title: "Follow",
                  name: "follow",
                },
                {
                  value: false,
                  title: "No follow",
                  name: "nofollow",
                },
              ],
            },
            {
              title: "Meta robots advanced",
              type: "CheckGroup",
              key: "metasAdvanced",
              options: [
                {
                  value: "noimageindex",
                  title: "No image index",
                  name: "noimage",
                },
                {
                  value: "nosnippet",
                  title: "No snippet",
                  name: "nosnippet",
                },
                {
                  value: "noodp",
                  title: "No ODP",
                  name: "noodp",
                },
                {
                  value: "noarchive",
                  title: "No archive",
                  name: "noarchive",
                },
                {
                  value: "noTranslate",
                  title: "No translate",
                  name: "noTranslate",
                },
              ],
            },
          ],
        },
        {
          title: "Analytics Data Layer",
          type: "FieldGroup",
          key: "analytics",
          collapsed: true,
          fields: [
            {
              type: "AnalyticsField",
              key: "dimensions",
              collapsed: true,
            },
          ],
        },
        {
          title: "Social Media",
          type: "FieldGroup",
          key: "socialShare",
          collapsed: true,
          fields: [
            {
              title: "Title",
              type: "TextField",
              key: "socialTitle",
            },
            {
              title: "Description",
              type: "TextField",
              key: "socialDescription",
            },
            {
              title: "Image",
              type: "ImageField",
              key: "socialImage",
            },
          ],
        },
      ],
    },
  ],
  default: {
    component: "GlobalPage",
    slug: "new-global-page",
    title: "New Global Page",
    headerConfig: "{}",
    footerConfig: "{}",
    liveStatus: { id: 1 },
    template: {},
    metaTitle: "",
    metaDescription: "",
    isIndexed: true,
    follow: true,
    metasAdvanced: "",
    socialTitle: "",
    socialDescription: "",
    socialImage: {},
  },
};
