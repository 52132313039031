import React, { memo, useRef } from "react";
import { createPortal } from "react-dom";

import { useHandleClickOutside } from "@ax/hooks";
import { IconAction } from "@ax/components";

import * as S from "./style";

const FloatingPanel = (props: IFloatingPanelProps): JSX.Element | null => {
  const {
    children,
    title,
    isOpen,
    toggleModal,
    isOpenedSecond,
    handlePanel,
    secondary,
    closeOnOutsideClick = true,
  } = props;

  const node = useRef<HTMLElement>(null);

  const handleClickOutside = (e: any) => {
    if ((node.current && node.current.contains(e.target)) || isOpenedSecond || !closeOnOutsideClick) {
      return;
    }
    toggleModal();
    if (handlePanel) {
      handlePanel(false);
    }
  };

  useHandleClickOutside(isOpen, handleClickOutside);

  const handleOnClick = () => {
    toggleModal();
    if (handlePanel) {
      handlePanel(false);
    }
  };

  return createPortal(
    <S.Wrapper
      data-testid="floating-panel"
      ref={node}
      isOpen={isOpen}
      isOpenedSecond={isOpenedSecond}
      secondary={secondary}
    >
      <S.Header>
        <S.Title>{title}</S.Title>
        {!isOpenedSecond && (
          <S.ButtonWrapper>
            <IconAction icon="close" onClick={handleOnClick} />
          </S.ButtonWrapper>
        )}
      </S.Header>
      <S.Content>{children}</S.Content>
    </S.Wrapper>,
    document.body
  );
};

export interface IFloatingPanelProps {
  isOpen: boolean;
  isOpenedSecond?: boolean;
  children: JSX.Element[] | JSX.Element | boolean;
  title: string;
  toggleModal: () => void;
  handlePanel?: (value: boolean) => void;
  secondary?: boolean;
  closeOnOutsideClick?: boolean;
}

export default memo(FloatingPanel);
