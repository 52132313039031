import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(${(p) => p.theme.spacing.s} * 2);
  margin-bottom: ${(p) => p.theme.spacing.xs};
  width: 100%;
`;

export const Title = styled.div`
  ${p => p.theme.textStyle.uiXS};
  color: ${p => p.theme.color.textHighEmphasis};
`;
