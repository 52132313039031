import styled from "styled-components";

export const Wrapper = styled.div``;

export const Label = styled.label`
  ${p => p.theme.textStyle.fieldContent};
  display: block;
  position: relative;
  padding-left: calc(${p => p.theme.spacing.s} + ${p => p.theme.spacing.xs});
  margin-bottom: ${p => p.theme.spacing.xs};
  cursor: pointer;
  user-select: none;
`;

export const CheckMark = styled.span<{ checked: boolean | undefined; error: boolean | undefined }>`
  position: absolute;
  top: 0;
  left: 0;
  height: ${p => p.theme.spacing.s};
  width: ${p => p.theme.spacing.s};
  background-color: ${p => p.theme.color.interactiveBackground};
  border: 2px solid ${p => (p.error ? p.theme.color.error : p.checked ? p.theme.color.interactive01 : p.theme.color.interactiveInactive)};
  border-radius: 50%;
  :after {
    content: "";
    position: absolute;
    display: ${p => (p.checked ? `blocked` : `none`)};
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${p => (p.error ? p.theme.color.error : p.theme.color.interactive01)};
  }
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  :disabled ~ ${CheckMark} {
    background-color: ${p => p.theme.color.interactiveBackground};
    border: 2px solid ${p => p.theme.color.interactiveDisabled};
    &:after {
      background: ${p => p.theme.color.interactiveDisabled};
    }
  }
`;
