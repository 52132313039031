import { IIntegrationVariable } from "@ax/types";

const modifyVariableValue = (
  variables: IIntegrationVariable[] | undefined,
  newVariableValues: {
    id: number | undefined;
    value: string;
    multiLangVariable?: { id?: number; languageId: number; value: string };
  }
): IIntegrationVariable[] | undefined => {
  const { id, value, multiLangVariable } = newVariableValues;
  let newVariables;
  if (multiLangVariable) {
    newVariables = variables?.map((variable) =>
      variable.id === id
        ? {
            ...variable,
            multilanguage:
              variable.multilanguage?.map((langVariable) =>
                langVariable.id === multiLangVariable.id ? { ...langVariable, value } : langVariable
              ) || null,
          }
        : variable
    );
  } else {
    newVariables = variables?.map((variable) => (variable.id === id ? { ...variable, value } : variable));
  }
  return newVariables;
};

export { modifyVariableValue };
