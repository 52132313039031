const getFileIcon = (type: string) => {
  switch (type) {
    case "pdf":
      return "pdf.png";
    case "doc":
    case "docx":
      return "word.png";
    case "xls":
    case "xlsx":
    case "csv":
      return "excel.png";
    case "zip":
      return "zip.png";
    case "mp4":
    case "mpg":
    case "mp2":
    case "mpeg":
    case "mpe":
    case "mpv":
      return "iconVideo.svg";
    case "txt":
      return "txt.svg";
    default:
      return "iconFile.svg";
  }
};

export { getFileIcon };
