import styled from "styled-components";

const DeactivateIcon = styled.div`
  opacity: 0;
`;

const Pack = styled.div`
  background: ${p => p.theme.color.uiBackground02};
  height: 162px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${p => p.theme.spacing.s};
  cursor: pointer;
  &:hover {
    background: ${p => p.theme.color.overlayHoverPrimary};
    ${DeactivateIcon} {
      opacity: 1;
    }
  }
`;

const Thumbnail = styled.div<{backgroundUrl: string}>`
  background: url(${p => p.backgroundUrl}) no-repeat;
  background-size: cover;
  min-height: 96px;
  width: 258px;
`;

const Footer = styled.div`
  position: relative;
  margin-top: ${p => p.theme.spacing.xs};
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

const Title = styled.span`
  ${p => p.theme.textStyle.headingXS};
  text-transform: none;
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  padding: ${p => p.theme.spacing.m};
  p {
    margin-bottom: ${p => p.theme.spacing.m};
  }
  div {
    margin-bottom: 0;
  }
`;

export {
  Pack,
  Thumbnail,
  Footer,
  Title,
  IconsWrapper,
  ModalContent,
  DeactivateIcon
}