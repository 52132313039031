import styled from "styled-components";

const DetailPanelWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: ${(p) => `${p.theme.spacing.s} ${p.theme.spacing.m}`};
`;

const PackTitle = styled.div`
  ${(p) => p.theme.textStyle.headingM};
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

const PackType = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const PackDescription = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const SectionTitle = styled.div`
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const TagsWrapper = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.m};
  & > div {
    margin-right: ${(p) => p.theme.spacing.xs};
  }
`;

const ModulesWrapper = styled.div`
  overflow: auto;
  height: 290px;
  padding-right: ${(p) => p.theme.spacing.xs};
`;

const ActionsWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: ${p => p.theme.color.uiBarBackground};
  border-top: 1px solid ${p => p.theme.color.uiLine};
  width: 100%;
  padding: ${(p) => `${p.theme.spacing.s} ${p.theme.spacing.m}`};
  text-align: right;
`;

export {
  DetailPanelWrapper,
  PackTitle,
  PackType,
  PackDescription,
  SectionTitle,
  TagsWrapper,
  ModulesWrapper,
  ActionsWrapper,
}
