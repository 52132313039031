import React from "react";

import * as S from "./style";

const VisualOption = ({ value, onChange, name, label, checked, img, disabled }: IVisualOptionProps): JSX.Element => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <S.Label>
      <S.RadioButton
        data-testid="radioButtons"
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
      <S.ImageWrapper active={checked} disabled={disabled}>
        <S.Image src={img} />
      </S.ImageWrapper>
    </S.Label>
  );
};

interface IVisualOptionProps {
  value: string;
  onChange: any;
  name: string;
  label: string;
  checked: boolean;
  img: string;
  disabled?: boolean;
}

export default VisualOption;
