import styled from "styled-components";

const Wrapper = styled.div`
  margin: ${(p) => `${p.theme.spacing.m} 0 ${p.theme.spacing.m} 0`};
`;

const Label = styled.div<{ isOpen: boolean }>`
  position: relative;
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  padding-bottom: ${(p) => p.theme.spacing.xs};
  margin-bottom: ${(p) => p.theme.spacing.xs};
  cursor: pointer;
  :after {
    position: absolute;
    right: 10px;
    top: ${(p) => (p.isOpen ? `10px` : `6px`)};
    content: "";
    border: solid ${(p) => p.theme.color.interactive01};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: ${(p) => (p.isOpen ? `rotate(-135deg)` : `rotate(45deg)`)};
  }
`;

const Content = styled.div<{ isOpen: boolean }>`
  overflow-y: ${(p) => (p.isOpen ? "visible" : "hidden")};
  max-height: ${(p) => (p.isOpen ? `auto` : 0)};
  transition: all 0.5s ease-in-out;
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
`;

export { Wrapper, Label, Content };
