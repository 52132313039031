import styled from "styled-components";

const DividerWrapper = styled.div`
  padding-top: ${(p) => p.theme.spacing.m};
  border-top: 1px solid ${(p) => p.theme.color.uiLine};
`;

const DividerTitle = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  color: ${(p) => p.theme.colors.textHighEmphasis};
`;
const DividerText = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  margin-top: ${(p) => p.theme.spacing.xs};
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

export { DividerWrapper, DividerTitle, DividerText };
