import React from "react";
import { IFolderTree } from "@ax/types";

import * as S from "./style";

const Breadcrumb = (props: IBreadcrumbProps): JSX.Element => {
  const { breadcrumb, onClick } = props;

  const handleRootClick = () => onClick(null);

  return (
    <S.BreadcrumbGroup data-testid="file-breadcrumb">
      <React.Fragment key="root">
        <S.BreadcrumbItem onClick={handleRootClick} isLastItem={false}>
          All Documents
        </S.BreadcrumbItem>
        {"/"}
      </React.Fragment>
      {breadcrumb.slice(0).map((item: IFolderTree, index: number, arr: any) => {
        const { id, name } = item;
        const isLastItem = breadcrumb.length === index + 1;
        const handleClick = () => onClick(id);

        return (
          <React.Fragment key={`${id}${index}`}>
            <S.BreadcrumbItem onClick={handleClick} isLastItem={isLastItem}>
              {name}
            </S.BreadcrumbItem>
            {index <= arr.length - 2 && "/"}
          </React.Fragment>
        );
      })}
    </S.BreadcrumbGroup>
  );
};

export interface IBreadcrumbProps {
  breadcrumb: IFolderTree[];
  onClick(folderID: number | null): void;
}

export default Breadcrumb;
