import React from "react";

const SvgSettings = props => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 8.364a3.636 3.636 0 100 7.272 3.636 3.636 0 000-7.272zm0 5.909a2.273 2.273 0 110-4.546 2.273 2.273 0 010 4.546z"
      fill="#5057FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.636 9.273h-.454a8.502 8.502 0 00-.455-1.141l.314-.314a1.364 1.364 0 000-1.927l-1.927-1.927a1.364 1.364 0 00-1.928 0l-.322.309a8.498 8.498 0 00-1.141-.455v-.454c0-.753-.61-1.364-1.364-1.364h-2.727c-.753 0-1.364.61-1.364 1.364v.454c-.39.124-.772.276-1.14.455l-.314-.314a1.364 1.364 0 00-1.928 0L3.96 5.886a1.364 1.364 0 000 1.928l.314.313a8.5 8.5 0 00-.455 1.141h-.454C2.61 9.268 2 9.878 2 10.632v2.727c0 .753.61 1.364 1.364 1.364h.454c.124.39.276.772.455 1.14l-.314.314a1.364 1.364 0 000 1.928l1.927 1.927a1.364 1.364 0 001.928 0l.322-.305c.37.18.75.331 1.141.455v.454c0 .754.61 1.364 1.364 1.364h2.727c.753 0 1.364-.61 1.364-1.364v-.454c.39-.124.772-.276 1.14-.455l.314.314a1.364 1.364 0 001.928 0l1.927-1.927a1.364 1.364 0 000-1.928l-.314-.313c.18-.37.331-.75.455-1.141h.454c.754 0 1.364-.61 1.364-1.364v-2.727a1.364 1.364 0 00-1.364-1.368zm0 4.09h-1.495a7.23 7.23 0 01-1.127 2.728l1.054 1.054-1.927 1.928-1.055-1.055c-.821.56-1.75.943-2.727 1.127v1.491h-2.727v-1.495a7.227 7.227 0 01-2.727-1.127L6.85 19.068l-1.927-1.927 1.054-1.055A7.232 7.232 0 014.85 13.36H3.364v-2.727h1.495a7.232 7.232 0 011.127-2.727L4.932 6.85l1.927-1.927 1.055 1.054A7.232 7.232 0 0110.64 4.85V3.364h2.727v1.495a7.232 7.232 0 012.728 1.127l1.054-1.054 1.927 1.927-1.063 1.05a7.23 7.23 0 011.127 2.727h1.495v2.728z"
      fill="#5057FF"
    />
  </svg>
);

export default SvgSettings;
