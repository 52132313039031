import { ITemplateOption } from "@ax/types";

const SET_COLUMN_VALUES = "SET_COLUMN_VALUES";
const SET_SHOW_THUMBNAIL = "SET_SHOW_THUMBNAIL";
const SET_SELECTED_OPTION = "SET_SELECTED_OPTION";
const SET_SELECTED_TYPE = "SET_SELECTED_TYPE";
const SELECT_OPTION = "SELECT_OPTION";

export const reducer = (state: IOptionTableStore, action: any): IOptionTableStore => {
  switch (action.type) {
    case SET_COLUMN_VALUES:
    case SET_SHOW_THUMBNAIL:
    case SET_SELECTED_OPTION:
    case SET_SELECTED_TYPE:
    case SELECT_OPTION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export function setColumnValues(columnValues: any) {
  return { type: SET_COLUMN_VALUES, payload: { columnValues } };
}

export function setShowThumbnail(showThumbnail: boolean) {
  return { type: SET_SHOW_THUMBNAIL, payload: { showThumbnail } };
}

export function setSelectedOption(selectedOption: string) {
  return { type: SET_SELECTED_OPTION, payload: { selectedOption } };
}

export function setSelectedType(selectedType: string) {
  return { type: SET_SELECTED_TYPE, payload: { selectedType } };
}

export function setOption(data: any) {
  return { type: SELECT_OPTION, payload: { ...data } };
}

export interface IOptionTableStore {
  columnValues: ITemplateOption[];
  showThumbnail: boolean;
  selectedOption: string;
  selectedType: string;
}
