import React, { useRef } from "react";
import { createPortal } from "react-dom";

import { Icon } from "@ax/components";
import { IPermissions } from "@ax/types";

import * as S from "./style";

const SideModal = (props: ISideModalProps): JSX.Element | null => {
  const {
    name,
    description,
    permissions: { totalPermissions, sitePermissions, globalPermissions },
    isOpen,
    closeModal,
  } = props;

  const node = useRef<HTMLDivElement>(null);

  return isOpen
    ? createPortal(
        <S.Wrapper ref={node} data-testid="role-side-modal">
          <S.Header>
            <S.Title data-testid="role-side-modal-title">{name} Permissions</S.Title>
            <S.CloseIconWrapper onClick={closeModal}>
              <Icon name="close" />
            </S.CloseIconWrapper>
          </S.Header>
          <S.ColumnsWrapper>
            <S.RoleInfo>
              <S.RoleTitle>
                <S.RoleName>{name}</S.RoleName> role
              </S.RoleTitle>
              <S.RoleDescription>{description}</S.RoleDescription>
            </S.RoleInfo>
            <S.ListTitleWrapper>
              <S.ListTitle>Role permissions</S.ListTitle>
              <S.TotalPermissions data-testid="role-side-modal-total-permissions">
                {totalPermissions}
              </S.TotalPermissions>
            </S.ListTitleWrapper>
            <S.RolePermissionsList>
              <S.PermissionGroupTitle>Global Permissions</S.PermissionGroupTitle>
              <S.PermissionGroup first={true}>
              {globalPermissions.map((permission: { name: string; key: string }) => (
                <S.Permission key={permission.key} data-testid="role-permission">
                  <S.IconWrapper data-testid="success-icon">
                    <Icon name="done" size={"16"} />
                  </S.IconWrapper>
                  {permission.name}
                </S.Permission>
              ))}
              </S.PermissionGroup>
              <S.PermissionGroupTitle>Site Permissions</S.PermissionGroupTitle>
              <S.PermissionGroup>
              {sitePermissions.map((permission: { name: string; key: string }) => (
                <S.Permission key={permission.key} data-testid="role-permission">
                  <S.IconWrapper data-testid="success-icon">
                    <Icon name="done" size={"16"} />
                  </S.IconWrapper>
                  {permission.name}
                </S.Permission>
              ))}
              </S.PermissionGroup>
            </S.RolePermissionsList>
          </S.ColumnsWrapper>
        </S.Wrapper>,
        document.body
      )
    : null;
};

interface ISideModalProps {
  name: string;
  description: string;
  permissions: IPermissions;
  isOpen: boolean;
  closeModal: () => void;
}

export default SideModal;
