const NAME = "fileDrive";

const SET_CURRENT_FOLDER_CONTENT = `${NAME}/SET_CURRENT_FOLDER_CONTENT`;
const SET_CURRENT_FOLDER = `${NAME}/SET_CURRENT_FOLDER`;
const SET_BREADCRUMB = `${NAME}/SET_BREADCRUMB`;
const SET_FOLDERS_TREE = `${NAME}/SET_FOLDERS_TREE`;
const SET_IS_UPLOADING = `${NAME}/SET_IS_UPLOADING`;
const SET_UPLOAD_SUCCESS = `${NAME}/SET_UPLOAD_SUCCESS`;
const SET_UPLOAD_ERROR = `${NAME}/SET_UPLOAD_ERROR`;
const SET_DISPLAY_MODE = `${NAME}/SET_DISPLAY_MODE`;
const SET_SELECTED_TAB = `${NAME}/SET_SELECTED_TAB`;

export {
  NAME,
  SET_CURRENT_FOLDER_CONTENT,
  SET_CURRENT_FOLDER,
  SET_BREADCRUMB,
  SET_FOLDERS_TREE,
  SET_IS_UPLOADING,
  SET_UPLOAD_ERROR,
  SET_UPLOAD_SUCCESS,
  SET_DISPLAY_MODE,
  SET_SELECTED_TAB,
};
