import styled from "styled-components";

const Handler = styled.div`
  position: sticky;
  top: 50%;
  height: 64px;
  width: 4px;
  background-color: ${(p) => p.theme.color.interactiveDisabled};
  cursor: ew-resize;
  z-index: 1;
  transform: translateX(${(p) => p.theme.spacing.xs});
  flex-shrink: 0;
  &:hover {
    background-color: ${(p) => p.theme.color.interactive01};
  }
`;

export { Handler };
