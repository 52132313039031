import React, { useEffect, useState } from "react";

import { FieldsBehavior } from "@ax/components";

import { IAnalyticsFieldProps, IState } from "..";
import { getTemplateDimensions } from "../utils";
import { TemplateDimensions, DimensionsSelection } from "./atoms";
import * as S from "../style";

const StructuredDataAnalytics = (props: IAnalyticsFieldProps): JSX.Element => {
  const { value, onChange, analytics, template, disabled } = props;

  const initialState = {
    dimensionsSelect: [],
    values: {},
  };

  const [state, setState] = useState<IState>({ ...initialState, ...value });

  useEffect(() => {
    onChange && onChange(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const templateDimensions = getTemplateDimensions(analytics.groups, template);

  const handleDimensionsSelect = (selection: string[]) => {
    const values: Record<string, string> = {};
    templateDimensions.forEach((dimension) => (values[dimension] = state.values[dimension]));
    let dimensionsSelect = selection;

    if (selection.includes("all")) {
      dimensionsSelect = dimensionOptions.map((dimension) => dimension.name);
    }

    dimensionsSelect?.forEach((dimension) => (values[dimension] = state.values[dimension]));
    setState((state: IState) => ({ ...state, dimensionsSelect, values }));
  };

  const setDimension = (value: Record<string, string>) => {
    setState((state: IState) => ({ ...state, values: { ...state.values, ...value } }));
  };

  const dimensionOptions = [
    {
      name: "all",
      value: "all",
      title: "Select All",
    },
  ];
  analytics.dimensions?.forEach((dimension) => {
    if (!templateDimensions.includes(dimension.name)) {
      const option = {
        name: dimension.name,
        value: dimension.name,
        title: dimension.name,
      };
      dimensionOptions.push(option);
    }
  });

  return (
    <>
      <TemplateDimensions dimensionNames={templateDimensions} {...{ analytics, state, setDimension, disabled }} />
      {dimensionOptions.length > 1 && (
        <>
          <S.FieldsDivider />
          <FieldsBehavior
            title="Add More Dimensions (Optional)"
            fieldType="MultiCheckSelect"
            options={dimensionOptions}
            value={state.dimensionsSelect}
            onChange={handleDimensionsSelect}
            selectAllOption="all"
            placeholder="Select Dimensions"
            disabled={disabled}
            floating
          />
          {!!state.dimensionsSelect && <DimensionsSelection {...{ analytics, state, setDimension, disabled }} />}
        </>
      )}
    </>
  );
};

export default StructuredDataAnalytics;
