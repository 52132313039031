import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;
  width: 100%;
`;

const TitleWrapper = styled.div`
  padding: ${(p) => p.theme.spacing.m};
  border-bottom: ${(p) => `1px solid ${p.theme.color.uiLine}`};
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const Description = styled.div`
  ${(p) => p.theme.textStyle.uiM};
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: calc(100vh - ${(p) => p.theme.spacing.xl});
  overflow: auto;
`;

const EmptyWrapper = styled.div`
  height: ${(p) => `calc(100vh - (${p.theme.spacing.xl} * 5))`};
  display: flex;
  align-items: center;
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};
`;

const OrderNote = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  background-color: ${(p) => p.theme.color.uiBackground03};
  padding: ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.radii.s};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const SearchTags = styled.div`
  & > div:nth-child(1) {
    margin-bottom: ${(p) => p.theme.spacing.xs};
  }
`;

export {
  Wrapper,
  ContentWrapper,
  TitleWrapper,
  Title,
  Description,
  TableWrapper,
  EmptyWrapper,
  ModalContent,
  OrderNote,
  SearchTags,
};
