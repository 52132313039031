import * as React from "react";

function SvgGrid2(props) {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 9H9V5H5V9ZM5 11C3.89543 11 3 10.1046 3 9V5C3 3.89543 3.89543 3 5 3H9C10.1046 3 11 3.89543 11 5V9C11 10.1046 10.1046 11 9 11H5ZM5 19H9V15H5V19ZM5 21C3.89543 21 3 20.1046 3 19V15C3 13.8954 3.89543 13 5 13H9C10.1046 13 11 13.8954 11 15V19C11 20.1046 10.1046 21 9 21H5ZM15 19H19V15H15V19ZM15 21C13.8954 21 13 20.1046 13 19V15C13 13.8954 13.8954 13 15 13H19C20.1046 13 21 13.8954 21 15V19C21 20.1046 20.1046 21 19 21H15ZM15 9H19V5H15V9ZM15 11C13.8954 11 13 10.1046 13 9V5C13 3.89543 13.8954 3 15 3H19C20.1046 3 21 3.89543 21 5V9C21 10.1046 20.1046 11 19 11H15Z"
        fill="#5057FF"
      />
    </svg>
  );
}

export default SvgGrid2;
