import styled from "styled-components";

const InputField = styled.input`
  ${(p) => p.theme.textStyle.fieldContent};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  width: 50px;
  border: none;

  &:active,
  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${(p) => p.theme.color.interactiveDisabled};
  }
`;

export { InputField };
