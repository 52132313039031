import React from "react";

import { Modal } from "@ax/components";

import * as S from "./style";

const GuardModal = (props: IGuardModalProps): JSX.Element => {
  const { isOpen, discardChanges, toggleModal } = props;

  const mainAction = {
    title: "Yes, discard changes",
    onClick: discardChanges,
  };

  const secondaryAction = {
    title: "Cancel",
    onClick: toggleModal,
  };

  const modalText = (
    <>
      Some changes <strong>are not saved</strong>. If you exit without saving it, it will be lost. Do you want to
      discard your changes?
    </>
  );

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      size="S"
      title="Unsaved changes"
      mainAction={mainAction}
      secondaryAction={secondaryAction}
    >
      {<S.ModalContent data-testid='guard-modal-content'>{modalText}</S.ModalContent>}
    </Modal>
  );
};

export interface IGuardModalProps {
  isOpen: boolean;
  discardChanges: () => void;
  toggleModal: () => void;
}

export default GuardModal;
