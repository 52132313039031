import React, { useReducer, useEffect } from "react";

import { IStructuredDataFilter, IStructuredDataValue } from "@ax/types";
import { getDefaultTheme, getThumbnailProps } from "@ax/helpers";
import { MenuItem, RadioGroup } from "@ax/components";

import { reducer, IOptionTableStore, setColumnValues, setShowThumbnail, setSelectedType, setOption } from "./store";
import { SecondaryActionButton, MainActionButton } from "./../atoms";
import * as S from "./style";

const OptionTable = (props: IOptionTableProps): JSX.Element => {
  const { selectPage, filters, values, selectedValue, mainAction, secondaryAction } = props;

  const filterOptions = (value: string, objKey: keyof IStructuredDataValue): IStructuredDataValue[] =>
    values.filter((item: IStructuredDataValue) => item[objKey] === value);
  const currentOption = filterOptions(selectedValue, "value");
  const currentType = currentOption[0] ? currentOption[0].type : "static";

  const initialState: IOptionTableStore = {
    columnValues: filterOptions(currentType, "type"),
    showThumbnail: true,
    selectedOption: selectedValue,
    selectedType: currentType,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const selectOption = (option: any) => {
    setSelectedOption(option);
    selectPage(option);
  };

  let filteredByTypeOptions = filterOptions(state.selectedType, "type");

  useEffect(() => {
    const firstOption = filteredByTypeOptions[0].value;
    selectOption(firstOption);
    // eslint-disable-next-line
  }, []);

  const showThumbnail = (isDisplayed: boolean) => dispatch(setShowThumbnail(isDisplayed));
  const setType = (type: string) => dispatch(setSelectedType(type));
  const setValues = (columns: IStructuredDataValue[]) => dispatch(setColumnValues(columns));
  const setSelectedOption = (selectedOption: string) => dispatch(setOption({ selectedOption }));

  const isOptionInType = (columns: IStructuredDataValue[]) => {
    const optionValues = filterOptions(state.selectedOption, "value");
    return columns.includes(optionValues[0]);
  };

  const theme = getDefaultTheme();

  const thumbnailProps =
    state.showThumbnail &&
    isOptionInType(filteredByTypeOptions) &&
    getThumbnailProps(state.selectedOption, true, theme);

  const displayOptions = (item: IStructuredDataFilter) => {
    const { value } = item;
    setType(value);
    filteredByTypeOptions = filterOptions(value, "type");
    setValues(filteredByTypeOptions);
    const firstOption = filteredByTypeOptions[0] && filteredByTypeOptions[0].value;
    selectOption(firstOption);

    const optionList = filterOptions(state.selectedOption, "value");
    const displayThumbnail = optionList[0] && optionList[0].type !== value;

    showThumbnail(displayThumbnail);
  };

  const hasThumbnail = state.showThumbnail && thumbnailProps && isOptionInType(filteredByTypeOptions);

  return (
    <S.Table>
      <S.Column>
        {filters.map((item: IStructuredDataFilter, i: number) => {
          const displayFilteredOptions = () => state.selectedType !== item.value && displayOptions(item);
          const isSelected = item.value === state.selectedType;
          const selectedClass = isSelected ? "selected" : "";
          return (
            <MenuItem key={`${item.value}${i}`} className={selectedClass} onClick={displayFilteredOptions}>
              <S.NavLink>
                <S.Link active={isSelected}>{item.label}</S.Link>
              </S.NavLink>
            </MenuItem>
          );
        })}
      </S.Column>
      <S.Column>
        <RadioGroup options={state.columnValues} onChange={selectOption} value={state.selectedOption} name="" />
      </S.Column>
      <S.Column>
        {hasThumbnail && (
          <S.ThumbnailWrapper>
            {thumbnailProps && <S.Thumbnail backgroundUrl={thumbnailProps.src} />}
          </S.ThumbnailWrapper>
        )}
        <S.Actions>
          <SecondaryActionButton onClick={secondaryAction.onClick} title={secondaryAction.title} />
          <MainActionButton onClick={mainAction.onClick} title={mainAction.title} />
        </S.Actions>
      </S.Column>
    </S.Table>
  );
};

interface IAction {
  title: string;
  onClick: any;
}

interface IOptionTableProps {
  selectPage: (value: string) => void;
  filters: IStructuredDataFilter[];
  values: IStructuredDataValue[];
  selectedValue: string;
  mainAction: IAction;
  secondaryAction: IAction;
}

export default OptionTable;
