import React from "react";

import { FieldsBehavior } from "@ax/components";
import { IIntegration } from "@ax/types";

import * as S from "./style";

const pageEditorID = 0;

const IntegrationsField = (props: IField): JSX.Element => {
  const { template, templateConfig, updateDataPackFormValue, dataPackConfigFormData, updateEditorContent } = props;

  const handleChange = (integrations: IIntegration[]) => {
    const configFormData = {
      ...dataPackConfigFormData,
      templates: {
        ...dataPackConfigFormData.templates,
        [template]: {
          ...(!!dataPackConfigFormData.templates && dataPackConfigFormData.templates[template]),
          integrations,
        },
      },
    };
    updateDataPackFormValue(configFormData);
    updateEditorContent(pageEditorID, "integrations", integrations);
  };

  return (
    <S.Wrapper>
      <FieldsBehavior
        fieldType="IntegrationsField"
        value={templateConfig.templates?.[template]?.integrations}
        onChange={handleChange}
      />
    </S.Wrapper>
  );
};

interface IField {
  template: string;
  templateConfig: any;
  dataPackConfigFormData: any;
  updateDataPackFormValue: (value: any) => void;
  updateEditorContent: (selectedEditorID: number, key: string, value: any) => void;
}

export { IntegrationsField };
