import styled from "styled-components";

const Wrapper = styled.div`
  padding: ${(p) => p.theme.spacing.m};
  height: 100%;
`;

const Footer = styled.div`
  position: absolute;
  bottom: ${(p) => p.theme.spacing.m};
  right: ${(p) => p.theme.spacing.m};
`;

const NoteWrapper = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.m};

`

const ContentWrapper = styled.div`
  height: calc(100% - ${(p) => p.theme.spacing.s} * 10);
  overflow-y: scroll;
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
  position: absolute;
  left: 0;
  padding: 0 ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
`;

export { Wrapper, Footer, NoteWrapper, ContentWrapper };
