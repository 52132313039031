import React from "react";

import * as S from "./style";

const NoteField = (props: INoteFieldProps): JSX.Element => {
  const { value, className } = props;
  const { title, text } = value;

  return (
    <S.Wrapper className={className} data-testid="note-field-wrapper">
      {title && <S.Title data-testid="note-field-title">{title}</S.Title>}
      <S.Text>{text}</S.Text>
    </S.Wrapper>
  );
};

export interface INoteFieldProps {
  value: { title?: string; text: string | JSX.Element };
  className?: string;
}

export default NoteField;
