import styled from "styled-components";

export const Wrapper = styled.div``;

export const Carousel = styled.div`
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  width: 100%;
`;

export const CarouselItem = styled.div<{ width?: number; columns: number }>`
  border-radius: ${(p) => p.theme.spacing.xxs};
  flex: 1;
  padding-left: ${(p) => p.theme.spacing.xxs};
  min-width: ${(p) => (p.width ? `${p.width}px` : `calc(100%/${p.columns})`)};
  width: ${(p) => (p.width ? `${p.width}px` : `calc(100%/${p.columns})`)};
`;

export const SlideSelector = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(p) => p.theme.spacing.xs};
  justify-content: center;
  width: 100%;
`;

export const DotGroup = styled.div`
  display: flex;
  padding: ${(p) => p.theme.spacing.xxs}; ;
`;

export const Dot = styled.div<{ selected: boolean }>`
  border-radius: 100%;
  margin: ${(p) => p.theme.spacing.xxs};
  width: ${(p) => p.theme.spacing.xs};
  height: ${(p) => p.theme.spacing.xs};
  background-color: ${(p) => (p.selected ? p.theme.color.uiSiteMenu : p.theme.color.uiLine)};
`;

export const MoveButton = styled.div`
  background-color: transparent;
  border: 0;
`;
