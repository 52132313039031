import styled from "styled-components";

const TableList = styled.div<{overflow?: string}>`
  width: 100%;
  height: 100%;
  overflow: ${(p) => p.overflow};
`;

const Table = styled.div`
  width: 100%;
  min-height: calc(100% - ${(p) => p.theme.spacing.l} - ${(p) => p.theme.spacing.m});
  padding-bottom: ${(p) => p.theme.spacing.m};
`;

const TableHeader = styled.div<{hasFixedHeader?: boolean}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative; /* fallback */
  position: ${(p) => p.hasFixedHeader ? `sticky` : `relative`};
  z-index: 6;
  top: 0;
  background: ${(p) => p.theme.color.uiBackground01};
`;

const Header = styled.div<{isActive?: boolean}>`
  ${(p) => p.theme.textStyle.uiS};
  box-sizing: border-box;
  padding: ${(p) => ` 0 ${p.theme.spacing.s}`};
  display: flex;
  flex-flow: column wrap;
  flex-direction: row;
  color: ${(p) => p.isActive ? p.theme.color.textHighEmphasis : p.theme.color.textMediumEmphasis};
`;

const TableBody = styled.div`
  padding: 0 ${(p) => p.theme.spacing.m};

  &.no-padding {
    padding: 0;
  }
`;

const CheckHeader = styled(Header)`
  width: 32px;
`;

const NameHeaderWrapper = styled(Header)`
  flex-grow: 1;
  padding: ${(p) => `0 ${p.theme.spacing.s}`};
`;

const TypeHeader = styled(Header)`
  width: 140px;
`;

const LiveHeader = styled(Header)`
  width: 80px;
`;

const StatusHeader = styled(Header)`
  width: 170px;
`;

const TransHeader = styled(Header)`
  width: 90px;
`;

const SeoHeader = styled(Header)`
  width: 92px;
`;

const ActionsHeader = styled(Header)`
  width: 125px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: ${(p) => p.theme.spacing.m};
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

export {
  TableList,
  Table,
  TableHeader,
  Header,
  TableBody,
  CheckHeader,
  NameHeaderWrapper,
  TypeHeader,
  LiveHeader,
  StatusHeader,
  TransHeader,
  SeoHeader,
  ActionsHeader,
  PaginationWrapper
};
