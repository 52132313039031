import { template } from "./config";
import { IServiceConfig, sendRequest } from "./utils";

const PUBLIC_BASE_PATH = process.env.REACT_APP_PUBLIC_API_ENDPOINT;

const SERVICES: { [key: string]: IServiceConfig } = {
  GET_PAGE_INFO: {
    ...template,
    endpoint: "/page/",
    method: "GET",
  },
  UPDATE_PAGE: {
    ...template,
    endpoint: "/page/",
    method: "PUT",
  },
  CREATE_PAGE: {
    ...template,
    endpoint: "/page",
    method: "POST",
  },
  DELETE_PAGE: {
    ...template,
    endpoint: "/page/",
    method: "DELETE",
  },
  DELETE_UNDO: {
    ...template,
    endpoint: ["/page/", "/undo"],
    method: "DELETE",
  },
  DELETE_BULK_UNDO: {
    ...template,
    endpoint: "/page/bulk/undo",
    method: "DELETE",
  },
  SET_PAGE_STATUS: {
    ...template,
    endpoint: "/pages/status/",
    method: "PUT",
  },
  GET_PAGE_LANGUAGES: {
    ...template,
    endpoint: ["/page/", "/languages"],
    method: "GET",
  },
  GET_PAGE_BREADCRUMB: {
    ...template,
    endpoint: ["/page/", "/breadcrumb"],
    method: "GET",
  },
  DELETE_BULK: {
    ...template,
    endpoint: "/page/bulk",
    method: "DELETE",
  },
  DUPLICATE_PAGE: {
    ...template,
    endpoint: ["/page/", "/duplicate"],
    method: "POST",
  },
  GET_TEMPLATE_CONFIG: {
    ...template,
    endpoint: ["/site/", "/template/", "/config"],
    method: "GET",
  },
  PAGE_PING: {
    ...template,
    endpoint: ["/page/", "/ping"],
    method: "POST",
  },
  GET_PUPLIC_PAGE: {
    ...template,
    host: PUBLIC_BASE_PATH,
    endpoint: ["/page/", "/preview/"],
    method: "GET",
  },
  PAGE_CHECK: {
    ...template,
    endpoint: "/page/check",
    method: "POST",
  },
  PAGE_AI_SUMMARY: {
    ...template,
    endpoint: "/ai/summary/page",
    method: "POST",
  },
  PAGE_AI_TRANSLATION: {
    ...template,
    endpoint: "/translations/page/",
    method: "POST",
  },
};

const getPageInfo = async (pageID: number) => {
  const { host, endpoint } = SERVICES.GET_PAGE_INFO;
  SERVICES.GET_PAGE_INFO.dynamicUrl = `${host}${endpoint}${pageID}`;

  return sendRequest(SERVICES.GET_PAGE_INFO);
};

const updatePage = async (pageID: number, data: any, publishDraft?: boolean) => {
  const { host, endpoint } = SERVICES.UPDATE_PAGE;
  const publish = publishDraft ? "?publishDraft=1" : "";
  SERVICES.UPDATE_PAGE.dynamicUrl = `${host}${endpoint}${pageID}${publish}`;

  return sendRequest(SERVICES.UPDATE_PAGE, { ...data });
};

const createPage = async (data: any) => {
  return sendRequest(SERVICES.CREATE_PAGE, { ...data });
};

const deletePage = async (pageID: number) => {
  const { host, endpoint } = SERVICES.DELETE_PAGE;

  SERVICES.DELETE_PAGE.dynamicUrl = `${host}${endpoint}${pageID}`;

  return sendRequest(SERVICES.DELETE_PAGE);
};

const restorePage = async (id: number | number[]) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.DELETE_UNDO;

  SERVICES.DELETE_UNDO.dynamicUrl = `${host}${prefix}${id}${suffix}`;

  return sendRequest(SERVICES.DELETE_UNDO);
};

const bulkRestore = async (ids: number[]) => {
  const { host, endpoint } = SERVICES.DELETE_BULK_UNDO;

  SERVICES.DELETE_BULK_UNDO.dynamicUrl = `${host}${endpoint}`;

  return sendRequest(SERVICES.DELETE_BULK_UNDO, { ids });
};

const setPageStatus = async (status: string, ids: number[]) => {
  const { host, endpoint } = SERVICES.SET_PAGE_STATUS;
  SERVICES.SET_PAGE_STATUS.dynamicUrl = `${host}${endpoint}${status}`;

  return sendRequest(SERVICES.SET_PAGE_STATUS, { ids });
};

const getPageLanguages = async (pageID: number, siteID: number | null, entity?: string) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_PAGE_LANGUAGES;

  SERVICES.GET_PAGE_LANGUAGES.dynamicUrl = `${host}${prefix}${pageID}${suffix}`;

  const dataHeader = { site: siteID, entity };

  return sendRequest(SERVICES.GET_PAGE_LANGUAGES, null, dataHeader);
};

const getPageBreadcrumb = async (pageID: number) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_PAGE_BREADCRUMB;

  SERVICES.GET_PAGE_BREADCRUMB.dynamicUrl = `${host}${prefix}${pageID}${suffix}`;

  return sendRequest(SERVICES.GET_PAGE_BREADCRUMB);
};

const duplicatePage = async (pageID: number, data?: any, siteID?: number) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.DUPLICATE_PAGE;

  SERVICES.DUPLICATE_PAGE.dynamicUrl = siteID
    ? `${host}${prefix}${pageID}${suffix}/${siteID}`
    : `${host}${prefix}${pageID}${suffix}`;

  return siteID ? sendRequest(SERVICES.DUPLICATE_PAGE) : sendRequest(SERVICES.DUPLICATE_PAGE, { ...data });
};

const bulkDelete = async (ids: any) => sendRequest(SERVICES.DELETE_BULK, { ids });

const getTemplateConfig = async (siteID: number, template: string) => {
  const {
    host,
    endpoint: [prefix, middle, suffix],
  } = SERVICES.GET_TEMPLATE_CONFIG;
  SERVICES.GET_TEMPLATE_CONFIG.dynamicUrl = `${host}${prefix}${siteID}${middle}${template}${suffix}`;

  return sendRequest(SERVICES.GET_TEMPLATE_CONFIG);
};

const sendPagePing = async (pageID: number) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.PAGE_PING;

  SERVICES.PAGE_PING.dynamicUrl = `${host}${prefix}${pageID}${suffix}`;

  return sendRequest(SERVICES.PAGE_PING);
};

const getPublicPage = async (pageID: number, entity: string) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_PUPLIC_PAGE;

  SERVICES.GET_PUPLIC_PAGE.dynamicUrl = `${host}${prefix}${pageID}${suffix}${entity}`;

  return sendRequest(SERVICES.GET_PUPLIC_PAGE);
};

const pageCheck = async (data: any) => {
  return sendRequest(SERVICES.PAGE_CHECK, { ...data });
};

const getPageSummary = async (data: any) => {
  return sendRequest(SERVICES.PAGE_AI_SUMMARY, { ...data });
};

const getPageTranslation = async (data: any, langID: number) => {
  const { host, endpoint } = SERVICES.PAGE_AI_TRANSLATION;
  SERVICES.PAGE_AI_TRANSLATION.dynamicUrl = `${host}${endpoint}${langID}`;

  return sendRequest(SERVICES.PAGE_AI_TRANSLATION, { ...data });
};

export default {
  getPageInfo,
  updatePage,
  createPage,
  deletePage,
  setPageStatus,
  getPageLanguages,
  getPageBreadcrumb,
  bulkDelete,
  duplicatePage,
  getTemplateConfig,
  restorePage,
  bulkRestore,
  sendPagePing,
  getPublicPage,
  pageCheck,
  getPageSummary,
  getPageTranslation
};
