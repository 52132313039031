import React, { memo } from "react";

import Icon from "@ax/components/Icon";
import { IIntegration } from "@ax/types";

import * as S from "./style";

const SideModalOption = (props: IProps) => {
  const { option, handleClick, toggleModal, custom } = props;

  const customOption = {
    name: "",
    contentHead: "",
    contentBody: "",
    contentBodyPosition: null,
    contentPresence: { presenceType: "page-custom", relatedPages: null },
    active: true,
  };

  const setOption = () => {
    handleClick(option || customOption);
    toggleModal();
  };

  const optionProps = {
    name: custom ? "Custom code" : option?.name,
    description: custom ? "Add custom code to the head or body on this page" : option?.description,
  };

  return (
    <S.Item onClick={setOption} data-testid="integration-side-modal-option">
      {custom && (
        <S.CustomSticker>
          <S.Icon>
            <Icon name="add" size="18px" />
          </S.Icon>
        </S.CustomSticker>
      )}
      <S.Title>{optionProps.name}</S.Title>
      <S.Description>{optionProps.description}</S.Description>
    </S.Item>
  );
};

interface IProps {
  option?: Partial<IIntegration>;
  handleClick: (option: Partial<IIntegration>) => void;
  toggleModal: () => void;
  custom?: boolean;
}

export default memo(SideModalOption);
