import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
`;

export const IconActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionMenu = styled.ul`
  padding: 0;
  cursor: pointer;
`;

export const ActionText = styled.div`
  padding-left: ${(p) => p.theme.spacing.xs};
  margin-left: ${(p) => p.theme.spacing.xxs};
`;

export const Label = styled.div`
  &:first-letter {
    text-transform: capitalize;
  }
`;

export const HelpText = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  white-space: normal;
  width: 180px;
`;

export const IconWrapper = styled.div`
  height: ${(p) => p.theme.spacing.m};
  width: ${(p) => p.theme.spacing.m};
`;

export const ActionItem = styled.li<{ disabled?: boolean; color?: boolean }>`
  display: flex;
  align-items: flex-start;
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => (p.disabled ? p.theme.color.interactiveDisabled : p.theme.color.textMediumEmphasis)};
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.s};
  pointer-events: ${(p) => (p.disabled ? "none" : "auto")};
  text-align: left;
  white-space: nowrap;
  cursor: pointer;

  &:focus {
    background: ${(p) => (p.disabled ? "transparent" : p.theme.color.overlayFocusPrimary)};
  }

  &:hover {
    background: ${(p) => (p.disabled ? "transparent" : p.theme.color.overlayHoverPrimary)};
  }

  &:not(.original) svg {
    path {
      fill: ${(p) => (p.disabled ? p.theme.color.interactiveDisabled : p.theme.color.iconMediumEmphasis)};
    }
  }
`;
