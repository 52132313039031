import * as React from "react";

function SvgCollapsed(props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 8.394L9.59 7 5 11.505 9.59 16 11 14.616l-3.17-3.111L11 8.394zm8 0L17.59 7 13 11.505 17.59 16 19 14.616l-3.17-3.111L19 8.394z"
        fill="#5057FF"
      />
    </svg>
  );
}

export default SvgCollapsed;
