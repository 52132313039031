import styled, { css } from "styled-components";

export const Cell = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  box-sizing: border-box;
  padding: ${(p) => p.theme.spacing.s};
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
`;

export const Row = styled.div<{ selected: boolean; global?: boolean }>`
  position: relative;
  background-color: ${(p) => p.theme.color.uiBackground02};

  ${(p) =>
    p.global &&
    css`
      background: ${(p) =>
        `repeating-linear-gradient( -45deg, ${p.theme.color.uiBackground03}, ${p.theme.color.uiBackground03} 1px, ${p.theme.color.uiBackground02} 1px, ${p.theme.color.uiBackground02} 14px )`};
    `}

  display: flex;
  flex-flow: row;
  border: 1px solid ${(p) => p.theme.color.uiLine};
  border-radius: ${(p) => p.theme.radii.s};
  margin-bottom: ${(p) => p.theme.spacing.xs};
  cursor: pointer;
  :before {
    content: "";
    background-color: ${(p) => (p.selected ? p.theme.color.overlayPressedPrimary : `transparent`)};
    border-radius: ${(p) => p.theme.radii.s};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${(p) => (p.selected ? 1 : 0)};
    transition: opacity 0.1s;
    pointer-events: none;
  }
  :hover {
    &:before {
      background-color: ${(p) =>
        p.selected ? p.theme.color.overlayPressedPrimary : p.theme.color.overlayHoverPrimary};
      opacity: 1;
    }
`;
