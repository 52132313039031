import { IImage } from "@ax/types";
import {
  SET_DATA,
  SET_IS_LOADING,
  SET_IS_SAVING,
  SET_IS_UPLOADING,
  SET_UPLOAD_SUCCESS,
  SET_UPLOAD_ERROR,
} from "./constants";
import { GalleryActionsCreators } from "./interfaces";

export interface IData {
  items: IImage[];
  page: number;
  isFinished: boolean;
}

export interface IIsLoading {
  init: boolean;
  more: boolean;
}

export interface IIsSaving {
  save: boolean;
  saveAdd: boolean;
  delete: boolean;
}

export interface IGalleryState {
  data: IData;
  isLoading: IIsLoading;
  isSaving: IIsSaving;
  isUploading: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMsg: string;
}

export const initialState = {
  data: {
    items: [],
    page: 1,
    isFinished: false,
  },
  isLoading: {
    init: false,
    more: false,
  },
  isSaving: {
    save: false,
    saveAdd: false,
    delete: false
  },
  isUploading: false,
  isSuccess: false,
  isError: false,
  errorMsg: "",
};

export function reducer(state = initialState, action: GalleryActionsCreators): IGalleryState {
  switch (action.type) {
    case SET_DATA:
    case SET_IS_LOADING:
    case SET_IS_SAVING:
    case SET_IS_UPLOADING:
    case SET_UPLOAD_SUCCESS:
    case SET_UPLOAD_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export { initialState as galleryInitialState, reducer as galleryReducer };
