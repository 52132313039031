import React, { useState } from "react";

import { IIntegration } from "@ax/types";
import { FloatingPanel, Button, FieldsBehavior, NoteField } from "@ax/components";

import * as S from "./style";

const CustomPanel = (props: IProps): JSX.Element => {
  const { isOpen, toggleModal, integration, setIntegration } = props;

  const [form, setForm] = useState(integration);

  const handleChange = (value: Record<string, string>) => setForm((state) => ({ ...state, ...value }));

  const editItemAction = () => {
    form && setIntegration(form);
    toggleModal();
  };

  const editButton = {
    label: "Add custom code",
    action: editItemAction,
  };

  const noteText =
    "Add custom code to the head or body for this page. The third-party must give you instructions about where to place the code and how to use it.";

  const bodyCodeOptions = [
    {
      title: "Start of the body",
      name: "start",
      value: "start",
    },
    {
      title: "End of the body",
      name: "end",
      value: "end",
    },
  ];

  return (
    <FloatingPanel title="Add custom code" toggleModal={toggleModal} isOpen={isOpen}>
      <S.Wrapper>
        <S.ContentWrapper>
          <S.NoteWrapper>
            <NoteField value={{ text: noteText }} />
          </S.NoteWrapper>
          <FieldsBehavior
            title="Name"
            name="name"
            fieldType="TextField"
            value={form.name}
            placeholder="Type a name"
            onChange={(value: string) => handleChange({ name: value })}
            mandatory
            validators={{ mandatory: true }}
          />
          <FieldsBehavior
            title="Head Code"
            name="contentHead"
            fieldType="TextArea"
            value={form.contentHead}
            placeholder="Enter the code that will be injected into the head’s page."
            onChange={(value: string) => handleChange({ contentHead: value })}
            rows={4}
          />
          <FieldsBehavior
            title="Body Code"
            name="contentBody"
            fieldType="TextArea"
            value={form.contentBody}
            placeholder="Enter the code that will be injected into the body’s page."
            onChange={(value: string) => handleChange({ contentBody: value })}
            rows={4}
          />
          <FieldsBehavior
            title="Page body code in:"
            name="contentBodyPosition"
            fieldType="RadioGroup"
            value={form.contentBodyPosition}
            options={bodyCodeOptions}
            onChange={(value: string) => handleChange({ contentBodyPosition: value })}
            disabled={!form.contentBody?.length}
          />
        </S.ContentWrapper>
        <S.Footer>
          <Button className="button" type="button" onClick={editButton.action} disabled={!form.name?.length}>
            {editButton.label}
          </Button>
        </S.Footer>
      </S.Wrapper>
    </FloatingPanel>
  );
};

interface IProps {
  integration: Partial<IIntegration>;
  isOpen: boolean;
  toggleModal: () => void;
  setIntegration(modifiedIntegration: Partial<IIntegration>): void;
}

export default CustomPanel;
