import React from "react";
import { connect } from "react-redux";

import { IAnalytics, IRootState } from "@ax/types";
import { isEmptyArray } from "@ax/helpers";

import PageAnalytics from "./PageAnalytics";
import StructuredDataAnalytics from "./StructuredDataAnalytics";
import { getTemplateDimensions } from "./utils";

const AnalyticsField = (props: IAnalyticsFieldProps): JSX.Element => {
  const { analytics, template } = props;
  const templateDimensions = getTemplateDimensions(analytics.groups, template);

  return isEmptyArray(templateDimensions) ? (
    <>
      <PageAnalytics {...props} />
    </>
  ) : (
    <>
      <StructuredDataAnalytics {...props} />
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  analytics: state.analytics,
  template: state.pageEditor.template,
});

export interface IAnalyticsFieldProps {
  value: IState;
  onChange: (value: IState) => void;
  analytics: IAnalytics;
  template: string;
  disabled?: boolean;
}

export interface IState {
  contentSelect: string;
  groupSelect: string;
  dimensionsSelect: string[];
  values: Record<string, string>;
}

export default connect(mapStateToProps, null)(AnalyticsField);
