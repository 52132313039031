import { ISite } from "@ax/types";

const getSortedListStatus = (orderPointer: string, isAscending: boolean): any => {
  const sortedListStatus = {
    isAscending,
    sortedByTitle: orderPointer === "name",
    sortedByLastAccess: orderPointer === "lastAccess",
    sortedByDateCreated: orderPointer === "dateCreated",
  };

  return sortedListStatus;
};

const filterByStatus = (bulkSelection: number[], sites: ISite[]): Record<string, number[]> => {
  const notPublishedItems: number[] = [];
  const publishedItems: number[] = [];

  sites.forEach((site: ISite) => {
    const { id, isPublished } = site;
    if (bulkSelection.includes(id)) {
      isPublished ? publishedItems.push(id) : notPublishedItems.push(id);
    }
  });

  const filteredItems = {
    all: bulkSelection,
    notPublished: notPublishedItems,
    published: publishedItems,
  };

  return filteredItems;
};
export { getSortedListStatus, filterByStatus };
