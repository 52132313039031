import React from "react";

import { IDataSource, IStructuredDataContent } from "@ax/types";
import { getFormattedDateWithTimezone } from "@ax/helpers";
import { CheckField, Tooltip } from "@ax/components";

import * as S from "./style";

const Item = (props: IProps): JSX.Element => {
  const { handleOnClick, item, isChecked, source, disabled, tooltip } = props;

  const handleItemClick = (e: any) => {
    e.preventDefault();
    if (!disabled) handleOnClick(item);
  };

  return (
    <Tooltip content={tooltip} bottom>
      <S.Item onClick={handleItemClick} data-testid="manual-panel-item" disabled={disabled}>
        <CheckField name="check" value={item.id} checked={isChecked} disabled={disabled} />
        <S.TextWrapper>
          <S.Header>
            <S.Type>{source.title.toUpperCase()}</S.Type>
            {item.modified && <S.Date>{getFormattedDateWithTimezone(item.modified, "d MMM Y")}</S.Date>}
          </S.Header>
          <S.Title>{item.content.title}</S.Title>
        </S.TextWrapper>
      </S.Item>
    </Tooltip>
  );
};

interface IProps {
  handleOnClick: (item: IStructuredDataContent) => void;
  item: IStructuredDataContent;
  isChecked: boolean;
  source: IDataSource;
  disabled: boolean;
  tooltip: string;
}

export default Item;
