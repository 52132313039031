import React from "react";

import { FloatingMenu, Flag, LanguageMenu } from "@ax/components";

import * as S from "./style";

const LangMenu = (props: IProps) => {
  const { currentLanguages, locale, languages, handleLanguage } = props;

  return (
    <LanguageMenu
      language={locale}
      availableLanguages={languages}
      setLanguage={handleLanguage}
      currentLanguages={currentLanguages}
    />
  );
};

const FlagsButton = (currentLanguages: any[]) => {
  return (
    <S.FlagsWrapper>
      {currentLanguages.map((pageLanguage: any, i: number) => (
        <Flag key={`${pageLanguage.language}${i}`} name={pageLanguage.locale} size="15" />
      ))}
      <span>({currentLanguages.length})</span>
    </S.FlagsWrapper>
  );
};

const Translations = (props: IProps) => {
  const { currentLanguages, locale, languages, handleLanguage } = props;

  return (
    <FloatingMenu Button={() => FlagsButton(currentLanguages)}>
      <LangMenu
        currentLanguages={currentLanguages}
        locale={locale}
        languages={languages}
        handleLanguage={handleLanguage}
      />
    </FloatingMenu>
  );
};

interface IProps {
  currentLanguages: any[];
  locale: string;
  languages: any[];
  handleLanguage(language: any): void;
}

export { Translations };
