import React from "react";
import { IFile } from "@ax/types";
import { getFileIcon, getFormattedDateWithTimezone, trimText } from "@ax/helpers";
import { Tooltip } from "@ax/components";

import * as S from "./style";

const GridItem = (props: IProps) => {
  const { file, onClick, isSelected } = props;
  const { fileName, fileType, uploadDate } = file;

  const handleClick = () => onClick(file);

  const iconUrl = `/img/icons/${getFileIcon(fileType)}`;

  const FileTitle = () =>
    fileName.length > 30 ? (
      <Tooltip content={fileName}>
        <S.Name>{trimText(fileName, 30)}</S.Name>
      </Tooltip>
    ) : (
      <S.Name>{fileName}</S.Name>
    );

  return (
    <S.Wrapper onClick={handleClick} isSelected={isSelected}>
      <S.Header>
        <S.IconWrapper>
          <img src={iconUrl} alt={`${fileType} Icon`} />
        </S.IconWrapper>
      </S.Header>
      <FileTitle />
      <S.Info>
        <S.Tag>{getFormattedDateWithTimezone(uploadDate, "d MMM Y")}</S.Tag>
      </S.Info>
    </S.Wrapper>
  );
};

interface IProps {
  file: IFile;
  onClick(file: IFile): void;
  isSelected: boolean;
}

export default GridItem;
