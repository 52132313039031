import React from "react";
import { connect } from "react-redux";

import { IRootState } from "@ax/types";
import { languagesActions } from "@ax/containers/Settings";
import { FieldsBehavior } from "@ax/components";

const ConnectedField = (props: IProps) => {
  const { form, type, name, updateFormValue } = props;

  const value = form[name];

  const handleChange = (newValue: any) => {
    updateFormValue({ [name]: newValue });
  };

  const fieldProps = {
    value,
    onChange: handleChange,
    ...props,
  };

  return <FieldsBehavior fieldType={type} {...fieldProps} />;
};

interface IProps {
  form: any;
  type: string;
  title: string;
  name: string;
  entity?: string;
  mandatory?: boolean;
  helptext?: string;
  options?: any;
  filter?: any;
  updateFormValue: (value: any) => void;
  autoComplete?: string;
}

const mapStateToProps = (state: IRootState) => ({
  form: state.languages.form,
});

const mapDispatchToProps = {
  updateFormValue: languagesActions.updateFormValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedField);
