import React from "react";
import { Button, Icon } from "@ax/components";
import { IEmptyStateProps } from "@ax/types";

import * as S from "./style";

const EmptyState = (props: IEmptyStateProps) => {
  const { icon, title, message, button, action } = props;

  return (
    <S.Wrapper data-testid="wrapper">
      <S.IconWrapper data-testid="icon-wrapper">
        <Icon name={icon || "empty"} size="26" />
      </S.IconWrapper>
      <S.Title>{title || "Oh! This Looks so empty"}</S.Title>
      {message && <S.Text>{message}</S.Text>}
      {button && action && (
        <S.ActionWrapper>
          <Button type="button" onClick={action} buttonStyle="line">
            {button}
          </Button>
        </S.ActionWrapper>
      )}
    </S.Wrapper>
  );
};

export default EmptyState;
