import styled from "styled-components";

const gutter = "16px";
const columns = "4";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const GridWrapper = styled.div`
  background-color: ${p => p.theme.color.uiBackground01};
  overflow: auto;
  flex: 1;
  padding-right: ${gutter};
`;

const Grid = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: ${p => p.theme.spacing.m};
  margin: 0 -${gutter};
  margin-top: -${gutter};
`;

const GridItem = styled.div`
  width: calc(100% / ${columns});
  padding-left: ${gutter};
  padding-top: ${gutter};
`;

const LoadingWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const IconChecked = styled.div`
  display: none;
  opacity: 0;
  position: absolute;
  bottom: ${p => p.theme.spacing.xs};
  right: ${p => p.theme.spacing.xs};
  transition: opacity 0.1s;
  height: ${p => p.theme.spacing.m};
  width: ${p => p.theme.spacing.m};
  svg {
    path {
      fill: ${p => p.theme.color.interactive01};
    }
  }
`;

const IconUnchecked = styled.div`
  display: block;
  opacity: 1;
  position: absolute;
  bottom: ${p => p.theme.spacing.xs};
  right: ${p => p.theme.spacing.xs};
  transition: opacity 0.1s;
  height: ${p => p.theme.spacing.m};
  width: ${p => p.theme.spacing.m};
  svg {
    path {
      fill: ${p => p.theme.color.interactive01};

    }
  }
`;

const PackWrapper = styled.div<{ selected: boolean }>`
  position: relative;
  background-color: ${p => p.selected ? p.theme.color.overlayHoverPrimary : p.theme.color.uiBarBackground};
  width: 170px;
  height: 134px;
  border-radius: ${p => p.theme.radii.s};
  padding: ${p => `${p.theme.spacing.s} ${p.theme.spacing.s} ${p.theme.spacing.xs} ${p.theme.spacing.s}`};
  cursor: pointer;
  :hover {
    background: ${(p) => p.theme.color.overlayHoverPrimary};
  }
  ${IconChecked} {
    display: ${p => (p.selected === true ? `block` : 0)};
    opacity: ${p => (p.selected === true ? 1 : 0)};
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 81px;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%
    height: auto;
    top: 0;
    left: 0;
  }
`;

const Title = styled.div`
  ${p => p.theme.textStyle.fieldLabel};
  margin-top: ${p => p.theme.spacing.xs};
  padding-right: ${p => p.theme.spacing.m};
`;

const FiltersWrapper = styled.div`
  display: flex;
  ${p => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  padding: ${p => `${p.theme.spacing.s} ${p.theme.spacing.m} 0 ${p.theme.spacing.m}`};
  & > div {
    margin-right: ${p => p.theme.spacing.m};
  }
`;

export { Wrapper, GridWrapper, Grid, GridItem, LoadingWrapper, PackWrapper, ImageWrapper, Title, IconChecked, IconUnchecked, FiltersWrapper }
