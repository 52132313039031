import styled from "styled-components";

const IconWrapper = styled.div`
  background-color: ${p => p.theme.color.uiBackground03};
  border-radius: 4px;
  height: calc(${p => p.theme.spacing.s} * 2);
  width: calc(${p => p.theme.spacing.s} * 2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${p => p.theme.spacing.xs};
`;

const HiddenButtonsWrapper = styled.span`
  margin-left: auto;
  display: none;
  opacity: 0;
`;

const Component = styled.span<{ isArray: boolean | undefined; disabled?: boolean }>`
  color: ${p => p.theme.color.textHighEmphasis};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${p => p.isArray ? "space-between" : "initial"};
  width: 100%;
  height: ${p => p.theme.spacing.l};
  background: ${p => p.theme.color.uiBackground02};
  border: 1px solid transparent;
  margin-bottom: ${p => p.theme.spacing.xs};
  padding: 0 ${p => p.theme.spacing.xs };
  border-radius: ${p => p.theme.radii.s};
  box-shadow: ${p => p.theme.shadow.shadowS};
  ${p => p.theme.textStyle.fieldLabel};
  text-align: left;
  cursor: ${p => p.disabled ? `default` : `pointer`};

  &:hover ${HiddenButtonsWrapper}{
    display: flex;
    opacity: 1;
  }

  &:focus,
  &:active {
    background: ${p => p.disabled ? p.theme.color.uiBackground02 : p.theme.color.overlayFocusPrimary};
    border: 1px solid ${p => p.disabled ? `transparent` : p.theme.color.interactive01};
    outline: none;
  }
`;

const Label = styled.span<{containerInfo: boolean | undefined; disabled?: boolean}>`
  ${p => p.containerInfo ? p.theme.textStyle.headingXS : null };
  color: ${p => p.disabled ? p.theme.color.interactiveDisabled : p.containerInfo ? p.theme.color.textMediumEmphasis : p.theme.color.textHighEmphasis};
  margin-left: ${p => p.theme.spacing.xs};
`;

const ButtonsWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export {
  IconWrapper,
  Component,
  Label,
  ButtonsWrapper,
  HiddenButtonsWrapper
};
