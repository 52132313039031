import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { appActions } from "@ax/containers/App";
import { usersActions } from "@ax/containers/Users";
import { sitesActions } from "@ax/containers/Sites";
import { Loading, MainWrapper } from "@ax/components";
import { IGetRoles, IRootState, ISite, IUser } from "@ax/types";
import { useShouldBeSaved, useURLSearchParam } from "@ax/hooks";

import UserForm from "../UserForm";

const Profile = (props: IProps) => {
  const { user, getUser, updateUser, isSaving, isLoading, getSites, getRoles, currentSiteInfo } = props;

  if (!user) {
    throw new Error(`ERROR: User reached Profile with null user`);
  }

  const isUserInit = useURLSearchParam("init");

  const [form, setForm] = useState<IUser>({ ...user });

  const { isDirty, setIsDirty } = useShouldBeSaved(form);

  const isSiteView = !!currentSiteInfo;

  useEffect(() => {
    isUserInit ? getSites() : getUser("me");
    const siteId = isSiteView ? currentSiteInfo.id : "global";
    getRoles({ siteId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { timezone } = user;
    const newForm = { ...user };
    if (!timezone) newForm.timezone = "Europe/Madrid";
    setForm(newForm);
  }, [user]);

  const handleSave = async () => {
    const isUpdated = form.id ? await updateUser(form.id, form, true, false) : false;
    if (isUpdated) setIsDirty(false);
  };

  const rightButtonProps = {
    label: isSaving ? "Saving" : isDirty ? "Save profile" : "Saved",
    disabled: isSaving || !isDirty,
    action: handleSave,
  };

  if (isLoading) return <Loading />;

  return (
    <MainWrapper title="My Profile" rightButton={rightButtonProps}>
      <UserForm
        form={form}
        setForm={setForm}
        user={user}
        readOnlySites={true}
        site={currentSiteInfo}
        isSiteView={isSiteView}
      />
    </MainWrapper>
  );
};

const mapStateToProps = (state: IRootState) => ({
  user: state.users.currentUser,
  isSaving: state.app.isSaving,
  isLoading: state.app.isLoading,
  token: state.app.token,
  currentSiteInfo: state.sites.currentSiteInfo,
});

interface IDispatchProps {
  setHistoryPush(path: string): any;
  getUser(id: string): any;
  getSites(): Promise<void>;
  updateUser(id: number, data: any, isProfile: boolean, isList: boolean): any;
  getRoles(params: IGetRoles): Promise<void>;
}

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
  getUser: usersActions.getUser,
  updateUser: usersActions.updateUser,
  getSites: sitesActions.getSites,
  getRoles: usersActions.getRoles,
};

interface IProfileProps {
  user: IUser | null;
  isSaving: boolean;
  isLoading: boolean;
  token: string;
  currentSiteInfo: ISite | null;
}

type IProps = IProfileProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
