import React from "react";

import { Icon, FloatingMenu, ListTitle, ListItem } from "@ax/components";

import * as S from "./style";

const SortFilter = (props: IProps) => {
  const { setOrder } = props;

  const orderByNameAscending = () => setOrder({ field: "title", desc: false });

  const orderByNameDescending = () => setOrder({ field: "title", desc: true });

  const orderByCreatedDate = () => setOrder({ field: "dateCreated", desc: false });

  const orderByModifiedDate = () => setOrder({ field: "dateModified", desc: false });

  const Select = () => (
    <S.Wrapper>
      Sort by
      <S.IconsWrapper>
        <S.InteractiveArrow>
          <Icon name="DownArrow" size="16" />
        </S.InteractiveArrow>
      </S.IconsWrapper>
    </S.Wrapper>
  );

  return (
    <FloatingMenu Button={Select} position="left">
      <ListTitle>Name Sorting</ListTitle>
      <ListItem onClick={orderByNameAscending}>Ascendent</ListItem>
      <ListItem onClick={orderByNameDescending}>Descendent</ListItem>
      <ListTitle>Date Sorting</ListTitle>
      <ListItem onClick={orderByModifiedDate}>Last modified</ListItem>
      <ListItem onClick={orderByCreatedDate}>Newest first</ListItem>
    </FloatingMenu>
  );
};

interface IProps {
  setOrder: (order: any) => void;
}

export default SortFilter;
