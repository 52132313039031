import styled from "styled-components";

const IconWrapper = styled.div`
  background-color: ${(p) => p.theme.color.uiBackground03};
  border-radius: ${(p) => p.theme.radii.s};
  height: calc(${(p) => p.theme.spacing.s} * 2);
  width: calc(${(p) => p.theme.spacing.s} * 2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(p) => p.theme.spacing.xs};
`;

const ButtonsWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-left: auto;
  opacity: 0;
`;

const HiddenButtonsWrapper = styled.span`
  margin-left: auto;
  display: none;
  opacity: 0;
`;

const Component = styled.span<{ disabled?: boolean; isActive: boolean }>`
  color: ${(p) => p.theme.color.textHighEmphasis};
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background: ${(p) => p.theme.color.uiBackground02};
  border: 1px solid ${(p) => (p.isActive ? p.theme.color.interactive01 : "transparent")};
  margin-bottom: ${(p) => p.theme.spacing.xs};
  padding: calc(${(p) => p.theme.spacing.xs} - 1px) ${(p) => p.theme.spacing.xs};
  border-radius: ${(p) => p.theme.radii.s};
  box-shadow: ${(p) => p.theme.shadow.shadowS};
  ${(p) => p.theme.textStyle.fieldLabel};
  text-align: left;
  cursor: ${(p) => (p.disabled ? `default` : `pointer`)};
  min-height: 48px;

  &:hover,
  &.active {
    border: 1px solid ${(p) => (p.isActive ? p.theme.color.interactive01 : p.theme.color.overlayHoverPrimary)};
    cursor: pointer;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: ${(p) => p.theme.radii.s};
      background-color: ${(p) => p.theme.color.overlayHoverPrimary};
    }
    span.light {
      border-color: ${(p) => p.theme.color.interactiveInactive};
    }
    ${HiddenButtonsWrapper}, ${ButtonsWrapper} {
      display: flex;
      opacity: 1;
    }
  }

  &:focus,
  &:active {
    background: ${(p) => (p.disabled ? p.theme.color.uiBackground02 : p.theme.color.overlayFocusPrimary)};
    border: 1px solid ${(p) => (p.disabled ? `transparent` : p.theme.color.interactive01)};
    outline: none;
  }

  &.ghosting {
    opacity: 0.3;
  }

  &.dragging {
    border: 1px solid ${(p) => p.theme.color.interactive01};
    height: 48px;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${(p) => p.theme.color.overlayHoverPrimary};
      box-shadow: ${(p) => p.theme.shadow.shadowS};
    }
    &:after {
      content: "";
      position: absolute;
      top: 47px;
      left: 0;
      right: 0;
      width: calc(100% - ${(p) => p.theme.spacing.s});
      height: ${(p) => p.theme.spacing.xs};
      border-radius: ${(p) => `0 0 ${p.theme.radii.s} ${p.theme.radii.s}`};
      background-color: ${(p) => p.theme.color.uiBackground02};
      z-index: -1;
      margin-left: ${(p) => p.theme.spacing.xs};
      margin-right: ${(p) => p.theme.spacing.xs};
      box-shadow: ${(p) => p.theme.shadow.shadowS};
    }
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${(p) => p.theme.spacing.xs};
  justify-content: center;
`;

const Label = styled.span<{ containerInfo: boolean | undefined; disabled?: boolean }>`
  margin: ${(p) => p.theme.spacing.xxs} 0;
  ${(p) => (p.containerInfo ? p.theme.textStyle.headingXS : null)};
  color: ${(p) =>
    p.disabled
      ? p.theme.color.interactiveDisabled
      : p.containerInfo
      ? p.theme.color.textMediumEmphasis
      : p.theme.color.textHighEmphasis};
`;

const LabelDrag = styled.div`
  ${(p) => p.theme.textStyle.fieldLabel};
  color: ${(p) => p.theme.color.textHighEmphasis};
  span {
    color: ${(p) => p.theme.color.interactive01};
  }
`;

const Title = styled.span<{ disabled?: boolean }>`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => (p.disabled ? p.theme.color.interactiveDisabled : p.theme.color.textMediumEmphasis)};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const HandleWrapper = styled.div<{ hidden?: boolean }>`
  display: ${(p) => (p.hidden ? "none" : "flex")};
  align-items: center;
  z-index: 1;
  :hover {
    cursor: grab;
  }
`;

const IconHandleWrapper = styled.div`
  width: ${(p) => p.theme.spacing.s};
  height: ${(p) => p.theme.spacing.s};
  svg {
    path {
      fill: ${(p) => p.theme.color.textLowEmphasis};
    }
  }
`;

const CheckWrapper = styled.div`
  margin-left: ${(p) => p.theme.spacing.xxs};
  width: ${(p) => p.theme.spacing.s};
`;

export {
  IconWrapper,
  Component,
  Text,
  Label,
  LabelDrag,
  Title,
  ButtonsWrapper,
  HiddenButtonsWrapper,
  HandleWrapper,
  IconHandleWrapper,
  CheckWrapper,
};
