import styled from "styled-components";
import { FieldsBehavior } from "@ax/components";

const Wrapper = styled.div`
  display: flex;
  border-top: ${(p) => `1px solid ${p.theme.color.uiLine}`};
  height: 100%;
`;

const FolderPanel = styled.div<{ isOpen: boolean }>`
  position: relative;
  background-color: ${(p) => p.theme.color.uiBackground02};
  border-right: ${(p) => `1px solid ${p.theme.color.uiLine}`};
  width: ${(p) => (p.isOpen ? "240px" : "0")};
  min-width: ${(p) => (p.isOpen ? "240px" : "0")};
  max-width: ${(p) => (p.isOpen ? "50%" : "0")};
  overflow: hidden;
  flex-shrink: 0;
`;

const FolderPanelContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(p) => p.theme.spacing.m};
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const ContentWrapper = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const FolderContent = styled.div`
  padding: ${(p) => `0 ${p.theme.spacing.m} ${p.theme.spacing.m} ${p.theme.spacing.m}`};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const SectionWrapper = styled.div`
  width: 100%;
  padding-top: ${(p) => p.theme.spacing.m};
  display: flex;
  flex-direction: column;
`;

const BigSectionWrapper = styled(SectionWrapper)`
  flex-grow: 1;
  padding-top: ${(p) => p.theme.spacing.m};
`;

const SectionHeader = styled.div`
  display: flex;
  margin-bottom: ${(p) => p.theme.spacing.s};
  align-items: center;
  justify-content: flex-end;
`;

const SectionTitle = styled.div`
  ${(p) => p.theme.textStyle.headingM};
  display: flex;
  color: ${(p) => p.theme.colors.textHighEmphasis};
  margin-right: auto;
  flex-shrink: 0;
`;

const ButtonWrapper = styled.div`
  margin-left: ${(p) => p.theme.spacing.m};
  flex-shrink: 0;
`;

const EmptyStateWrapper = styled.div<{ margin: boolean }>`
  width: 100%;
  flex-grow: 1;
  margin-top: ${(p) => (p.margin ? "-100px" : "auto")};
`;

const DocumentsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const GridWrapper = styled.div<{ isSearching: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-template-rows: ${(p) =>
    p.isSearching ? "repeat(auto-fit, minmax(180px, 180px))" : "repeat(auto-fit, minmax(154px, 154px))"};
  gap: ${(p) => p.theme.spacing.s};
  width: 100%;
  justify-content: start;
`;

const FiltersBar = styled.div<{ isSite: boolean }>`
  display: flex;
  background-color: ${(p) => p.theme.color.uiBackground02};
  padding: ${(p) =>
    p.isSite
      ? `${p.theme.spacing.s} ${p.theme.spacing.m} 0 ${p.theme.spacing.m}`
      : `${p.theme.spacing.s} ${p.theme.spacing.m}`};
  border-bottom: ${(p) => `1px solid ${p.theme.color.uiLine}`};
`;

const DisplayModeWrapper = styled.div`
  margin-left: auto;
  flex-shrink: 0;
  button:last-child {
    margin-left: ${(p) => p.theme.spacing.xxs};
  }
`;

const FoldersWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const FoldersGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(64px, 64px));
  gap: ${(p) => p.theme.spacing.s};
  justify-content: start;
`;

const FoldersIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-left: ${(p) => p.theme.spacing.xs};
  cursor: pointer;
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};
  p {
    margin-bottom: ${(p) => p.theme.spacing.m};
  }
`;

const NoMarginFieldsBehavior = styled(FieldsBehavior)`
  margin-bottom: 0;
`;

const TabsWrapper = styled.div`
  width: ${(p) => `calc(${p.theme.spacing.xl} * 3)`};
`;

const Filters = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  flex-shrink: 0;
  & > * {
    &:not(:last-child) {
      margin-right: ${(p) => p.theme.spacing.m};
    }
  }
`;

const ResizeHandle = styled.div`
  position: absolute;
  width: 2px;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: col-resize;

  &:hover {
    background-color: ${(p) => p.theme.color.interactive01};
  }
`;

const SearchTags = styled.div`
  & > div:nth-child(1){
    margin-top: ${p => p.theme.spacing.m};
  }
  & > div:nth-child(2){
    margin-top: ${p => p.theme.spacing.xs};
  }
`;

export {
  Wrapper,
  FolderPanel,
  FolderPanelContent,
  ContentWrapper,
  FolderContent,
  SectionWrapper,
  SectionHeader,
  SectionTitle,
  ButtonWrapper,
  DocumentsWrapper,
  EmptyStateWrapper,
  GridWrapper,
  FiltersBar,
  DisplayModeWrapper,
  FoldersWrapper,
  FoldersGrid,
  FoldersIconWrapper,
  ModalContent,
  NoMarginFieldsBehavior,
  TabsWrapper,
  ResizeHandle,
  Filters,
  SearchTags,
  BigSectionWrapper,
};
