import React, { memo } from "react";
import { IDataPack } from "@ax/types";
import EmptyPanel from "./EmptyPanel";
import MultiplePanel from "./MultiplePanel";
import DetailPanel from "./DetailPanel";

import * as S from "./style";

const SidePanel = (props: IProps) => {
  const { selected, activatePacks, uncheckAll } = props;

  let Content = <EmptyPanel />;

  if (selected.length > 1) {
    Content = <MultiplePanel activatePacks={activatePacks} uncheckAll={uncheckAll} />;
  }

  if (selected.length === 1) {
    Content = <DetailPanel pack={selected[0]} activatePacks={activatePacks} />;
  }

  return <S.Wrapper>{Content}</S.Wrapper>;
};

interface IProps {
  selected: IDataPack[];
  activatePacks: () => void;
  uncheckAll: () => void;
}

export default memo(SidePanel);
