import React, { useState } from "react";

import { FloatingMenu, Icon, ListTitle, ListItem } from "@ax/components";
import { areEquals } from "@ax/helpers";
import { IQueryValue } from "@ax/types";

import * as S from "./style";

const Type = ({ filterItems }: ITypeProps): JSX.Element => {
  const selectAllOption = "all";
  const initialState = ["all"];
  const pointer = "format";
  const [selectedValue, setSelectedValue] = useState(initialState);

  const filters: Record<string, string> = {
    all: "All",
    bitmap: "Image",
    vectorial: "Icons",
  };

  const setQuery = (selection: string) => {
    if (!selection.length) {
      selection = selectAllOption;
    }
    setSelectedValue([selection]);
    filterItems(pointer, [{ value: selection, label: filters[selection] }]);
  };

  const isActive = !areEquals(selectedValue, initialState);

  const Header = () => (
    <S.Type data-testid="type-wrapper" isActive={isActive}>
      Media type
      <S.IconsWrapper>
        {isActive && <Icon name="Filter" size="16" />}
        <S.InteractiveArrow>
          <Icon name="DownArrow" size="16" />
        </S.InteractiveArrow>
      </S.IconsWrapper>
    </S.Type>
  );

  return (
    <FloatingMenu Button={Header} position="center" closeOnSelect={true}>
      <ListTitle>Filter by type</ListTitle>
      <ListItem isSelected={selectedValue.includes("all")} onClick={() => setQuery("all")}>
        All
      </ListItem>
      <ListItem isSelected={selectedValue.includes("bitmap")} onClick={() => setQuery("bitmap")}>
        Image
      </ListItem>
      <ListItem isSelected={selectedValue.includes("vectorial")} onClick={() => setQuery("vectorial")}>
        Icons
      </ListItem>
    </FloatingMenu>
  );
};

export interface ITypeProps {
  filterItems(pointer: string, filter: IQueryValue[]): void;
}

export default Type;
