import React, { useState, useEffect } from "react";

import { Button, Modal, NoteField, Icon } from "@ax/components";
import { ICheck, IRole } from "@ax/types";
import { useModal } from "@ax/hooks";

import RoleItem from "./RoleItem";

import * as S from "./style";

const RolesModal = (props: IRolesModalProps): JSX.Element => {
  const { roles, isOpen, toggleModal, addRoles, selectedRoles, allSites, isGlobal } = props;

  const [rolesChecked, setRolesChecked] = useState<number[]>(selectedRoles ? selectedRoles : []);
  const [selected, setSelected] = useState<IRole | undefined>(roles ? roles[0] : undefined);

  const { isOpen: isAlertOpen, toggleModal: toggleAlertModal } = useModal(false);

  useEffect(() => {
    setRolesChecked(selectedRoles ? selectedRoles : []);
  }, [selectedRoles]);

  const handleChange = (value: ICheck) => {
    if (value.isChecked) {
      setRolesChecked((state) => [...state, value.value]);
    } else {
      const updatedRoles = rolesChecked.filter((id: number) => id !== value.value);
      setRolesChecked(updatedRoles);
    }
  };

  const handleClick = (role: IRole) => setSelected(role);

  const checkRoles = () => (allSites ? toggleAlertModal() : handleAddRoles());

  const handleAddRoles = () => {
    addRoles(rolesChecked);
    isAlertOpen && toggleAlertModal();
    toggleModal();
  };

  const mainModalAction = {
    title: "Update User Roles",
    onClick: handleAddRoles,
  };

  const secondaryModalAction = { title: "Cancel", onClick: toggleAlertModal };

  const rolePermissions = isGlobal ? selected?.permissions.globalPermissions : selected?.permissions.sitePermissions

  return (
    <>
      <Modal isOpen={isOpen} hide={toggleModal} title="Add User Roles" size="XL" overflow="hidden">
        {isOpen && (
          <S.ModalContent data-testid="modal-roles-content">
            <S.RoleList>
              <S.Selected>{rolesChecked.length} Roles Selected</S.Selected>
              {roles &&
                roles
                  .filter((role: IRole) => role.active)
                  .map((role: IRole) => {
                    const isChecked = rolesChecked.includes(role.id);
                    return (
                      <RoleItem
                        key={role.id}
                        role={role}
                        isChecked={isChecked}
                        isSelected={role.id === selected?.id}
                        onChange={handleChange}
                        onClick={handleClick}
                      />
                    );
                  })}
            </S.RoleList>
            <S.SidePanel>
              <S.RoleInfo>
                {selected && (
                  <div data-testid="permissions-wrapper">
                    <NoteField value={{ title: selected.name, text: selected.description }} />
                    <S.PermissionsList>
                      <S.PermissionsListTitle>
                        Permissions ({selected.permissions.totalPermissions})
                      </S.PermissionsListTitle>
                      <S.PermissionsWrapper>
                        {rolePermissions && rolePermissions.map((permission: { name: string; key: string }) => (
                          <S.Permission key={permission.key}>
                            <S.IconWrapper data-testid="success-icon">
                              <Icon name="done" size="16" />
                            </S.IconWrapper>
                            {permission.name}
                          </S.Permission>
                        ))}
                      </S.PermissionsWrapper>
                    </S.PermissionsList>
                  </div>
                )}
              </S.RoleInfo>
              <S.ButtonWrapper>
                <Button type="button" buttonStyle="line" onClick={checkRoles}>
                  Add User Role
                </Button>
              </S.ButtonWrapper>
            </S.SidePanel>
          </S.ModalContent>
        )}
      </Modal>
      <Modal
        isOpen={isAlertOpen}
        hide={toggleAlertModal}
        title="Update Roles"
        size="S"
        mainAction={mainModalAction}
        secondaryAction={secondaryModalAction}
      >
        {isAlertOpen && (
          <S.ModalContentPadding>
            <p>
              This user can access all existing and future sites as a viewer. By changing the role on this site,{" "}
              <strong>‘All site permissions’ will be removed from the user,</strong> but they will{" "}
              <strong>still have access to all sites</strong> they have access currently. This user must be given{" "}
              <strong>access manually</strong> when creating a new site.
            </p>
          </S.ModalContentPadding>
        )}
      </Modal>
    </>
  );
};

export interface IRolesModalProps {
  roles?: IRole[];
  isOpen: boolean;
  selectedRoles?: number[];
  toggleModal(): void;
  addRoles(roles: number[]): void;
  isGlobal: boolean;
  allSites?: boolean;
}

export default RolesModal;
