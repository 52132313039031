import styled from "styled-components";

const Wrapper = styled.div<{ isSelected: boolean }>`
  position: relative;
  background-color: ${(p) => p.theme.color.uiBackground02};
  border: ${(p) => (p.isSelected ? `2px solid ${p.theme.color.interactive01}` : `1px solid ${p.theme.color.uiLine}`)};
  border-radius: ${(p) => p.theme.radii.s};
  display: flex;
  flex-direction: column;
  padding: ${(p) => p.theme.spacing.s};
  min-width: 165px;
  max-width: 170px;
  height: 144px;
  cursor: pointer;
  &:hover {
    background-color: ${(p) => p.theme.color.overlayHoverPrimary};
  }
`;

const Header = styled.div`
  display: flex;
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const IconWrapper = styled.div`
  height: 40px;
  img {
    height: 40px;
  }
`;

const Name = styled.div`
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.colors.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  word-break: break-word;
`;

const Info = styled.div`
  display: flex;
  margin-top: auto;
`;

const Tag = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  border: ${(p) => `1px solid ${p.theme.color.uiLine}`};
  border-radius: 20px;
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  padding: 2px 8px;
  margin-right: ${(p) => p.theme.spacing.xxs};
`;

export { Wrapper, Header, Name, Info, IconWrapper, Tag };
