import { template } from "./config";
import { IServiceConfig, sendRequest, sendUploadRequest } from "./utils";

const SERVICES: { [key: string]: IServiceConfig } = {
  CREATE_IMAGE: {
    ...template,
    endpoint: "/images",
    method: "POST",
  },
  GET_IMAGE: {
    ...template,
    endpoint: "/image/",
    method: "GET",
  },
  UPDATE_IMAGE: {
    ...template,
    endpoint: "/image/",
    method: "PUT",
  },
  DELETE_IMAGE: {
    ...template,
    endpoint: "/image/",
    method: "DELETE",
  },
};

const createImage = async (data: any, setProgress?: (progress: number) => void) => {
  return sendUploadRequest(SERVICES.CREATE_IMAGE, data, setProgress);
};

const getImageInfo = async (imageID: number) => {
  const { host, endpoint } = SERVICES.GET_IMAGE;
  SERVICES.GET_IMAGE.dynamicUrl = `${host}${endpoint}${imageID}`;

  return sendRequest(SERVICES.GET_IMAGE);
};

const updateImage = async (imageID: number, data: any) => {
  const { host, endpoint } = SERVICES.UPDATE_IMAGE;
  SERVICES.UPDATE_IMAGE.dynamicUrl = `${host}${endpoint}${imageID}`;

  return sendRequest(SERVICES.UPDATE_IMAGE, { ...data });
};

const deleteImage = async (imageID: number) => {
  const { host, endpoint } = SERVICES.DELETE_IMAGE;
  SERVICES.DELETE_IMAGE.dynamicUrl = `${host}${endpoint}${imageID}`;

  return sendRequest(SERVICES.DELETE_IMAGE);
};

export default { createImage, getImageInfo, updateImage, deleteImage };
