import styled from "styled-components";

export const Wrapper = styled.section`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${p => p.theme.color.uiBackground01};
`;

export const Main = styled.div`
  width: calc(${p => p.theme.spacing.xl} * 5);
`;

export const Header = styled.header`
  text-align: center;
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
  margin-bottom: ${p => p.theme.spacing.m};
  padding-bottom: ${p => p.theme.spacing.m};
`;

export const Subtitle = styled.p`
  ${p => p.theme.textStyle.headingXXS};
  color: ${p => p.theme.color.textMediumEmphasis};
  font-weight: normal;
  margin-bottom: ${p => p.theme.spacing.xs};
`;

export const Title = styled.h1`
  ${p => p.theme.textStyle.headingM};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

