import React from "react";

import * as S from "./style";

const FieldGroup = (props: IFieldsGroupProps): React.ReactElement => {
  const { title, children, collapsed } = props;
  const [isOpen, setIsOpen] = React.useState(!collapsed);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <S.Wrapper data-testid="field-group-wrapper">
      <S.Label data-testid="field-group-label" onClick={handleClick} isOpen={isOpen}>
        {title}
      </S.Label>
      <S.Content data-testid="field-group-content" isOpen={isOpen}>
        {children}
      </S.Content>
    </S.Wrapper>
  );
};

export default FieldGroup;

interface IFieldsGroupProps {
  title: string;
  children: any;
  collapsed?: boolean;
}
