import React from "react";
import { connect } from "react-redux";

import { useModal } from "@ax/hooks";
import { languagesActions } from "@ax/containers/Settings";
import { ILanguage } from "@ax/types";
import { IconAction } from "@ax/components";
import { Restricted } from "@ax/guards";
import LanguagePanel from "../../LanguagePanel";

import * as S from "./style";

const Header = (props: IProps): JSX.Element => {
  const { items, resetForm } = props;

  const title = items.length > 1 ? `${items.length} items` : `${items.length} item`;

  const { isOpen, toggleModal } = useModal();

  const resetValues = () => resetForm();

  const openModal = () => {
    resetValues();
    toggleModal();
  };

  return (
    <S.Header>
      <S.Title>{title}</S.Title>
      <Restricted to="general.createLanguages">
        <IconAction icon="add" size="m" onClick={openModal} />
      </Restricted>
      <LanguagePanel isOpen={isOpen} toggleModal={toggleModal} />
    </S.Header>
  );
};
interface ITableHeaderProps {
  items: ILanguage[];
}
interface IDispatchProps {
  resetForm(): void;
}

type IProps = ITableHeaderProps & IDispatchProps;

const mapDispatchToProps = {
  resetForm: languagesActions.resetForm,
};

export default connect(null, mapDispatchToProps)(Header);
