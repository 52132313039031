import styled from "styled-components";

const Tooltip = styled.div<{ expanded?: boolean }>`
  position: ${(p) => (p.expanded ? "static" : "relative")};
`;

const Tip = styled.span<{
  active: boolean;
  childrenWidth: number;
  bottom?: boolean;
  fixOutOfBounds: number;
  left?: number;
  top?: number;
  expanded?: boolean;
}>`
  position: absolute;
  top: ${(p) => (p.top ? `${p.top}px` : p.bottom ? "auto" : "-32px")};
  bottom: ${(p) => (p.bottom ? "-32px" : "auto")};
  left: ${(p) => (p.left ? `${p.left}px` : "auto")};
  border-radius: 2px;
  transform: ${(p) =>
    p.expanded ? "none" : `translateX(calc(-50% + ${p.childrenWidth / 2}px - ${p.fixOutOfBounds}px))`};
  padding: 8px;
  color: #fff;
  background: ${(p) => p.theme.color.uiMainMenuBackground};
  font-size: 12px;
  line-height: 1;
  z-index: 100;
  white-space: ${(p) => (p.expanded ? "normal" : "nowrap")};
  display: ${(p) => (p.active ? "block" : "none")};
  animation: fadeIn 0.3s;
  font-weight: normal;
  text-transform: none;
  max-width: 350px;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export { Tooltip, Tip };
