const getSortedListStatus = (orderPointer: string, isAscending: boolean) => {
  const sortedListStatus = {
    isAscending,
    sortedByName: orderPointer === "name",
    sortedByDateCreated: orderPointer === "dateCreated",
  };

  return sortedListStatus;
};

export { getSortedListStatus };
