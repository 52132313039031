import React, { useEffect } from "react";
import { connect } from "react-redux";

import { INavItem, IRootState, ISite } from "@ax/types";
import { sitesActions } from "@ax/containers/Sites";
import { appActions } from "@ax/containers/App";
import { MainWrapper, Loading, ErrorToast, Nav } from "@ax/components";

import Table from "./Table";

import * as S from "./style";

const Languages = (props: IProps): JSX.Element => {
  const { isLoading, currentSiteInfo, getSiteLanguages, setHistoryPush, navItems, currentNavItem } = props;

  useEffect(() => {
    if (currentSiteInfo) {
      getSiteLanguages(currentSiteInfo.id);
    }
  }, [currentSiteInfo, getSiteLanguages]);

  const handleMenuClick = (path: string) => {
    setHistoryPush(path);
  };

  return (
    <MainWrapper title={`Languages`}>
      <S.Wrapper>
        <Nav current={currentNavItem} items={navItems} onClick={handleMenuClick} />
        <S.ContentWrapper>
          <ErrorToast />
          {isLoading ? (
            <Loading />
          ) : (
            <S.LanguageListWrapper>
              <Table />
            </S.LanguageListWrapper>
          )}
        </S.ContentWrapper>
      </S.Wrapper>
    </MainWrapper>
  );
};

const mapStateToProps = (state: IRootState) => ({
  currentSiteInfo: state.sites.currentSiteInfo,
  isLoading: state.app.isLoading,
});

const mapDispatchToProps = {
  getSiteLanguages: sitesActions.getSiteLanguages,
  setHistoryPush: appActions.setHistoryPush,
};

interface IDispatchProps {
  getSiteLanguages(siteID: number): void;
  setHistoryPush(path: string, isEditor?: boolean): void;
}

interface ILanguagesProps {
  currentSiteInfo: ISite | null;
  isLoading: boolean;
  navItems: INavItem[];
  currentNavItem: INavItem;
}

type IProps = ILanguagesProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(Languages);
