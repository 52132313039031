import styled from "styled-components";

const Wrapper = styled.div<{ optionsType?: string }>`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  width: 384px;
  height: 100vh;
  background: ${(p) => p.theme.colors.uiBackground01};
  box-shadow: ${(p) => p.theme.shadow.rightPanel};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${(p) => p.theme.spacing.xl};
  width: 100%;
  padding: 0 ${(p) => p.theme.spacing.m};
  background-color: ${(p) => p.theme.colors.uiBackground02};
  border-bottom: 1px solid ${(p) => p.theme.colors.uiLine};
  h6 {
    ${(p) => p.theme.textStyle.headingM}
    color: ${(p) => p.theme.colors.textHighEmphasis};
    text-transform: capitalize;
  }
`;

const Title = styled.h6``;

const IconWrapper = styled.span`
  padding-right: ${(p) => p.theme.spacing.xs};
  height: 16px;
`;

const CloseIconWrapper = styled(IconWrapper)`
  cursor: pointer;
`;

const ColumnsWrapper = styled.div`
  padding: ${(p) => p.theme.spacing.m};
`;

const RoleInfo = styled.div`
  width: 336px;
  background-color: ${(p) => p.theme.colors.uiBackground03};
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 33px;
`;

const RoleTitle = styled.div`
  ${(p) => p.theme.textStyle.headingXS}
  color: ${(p) => p.theme.color.textMediumEmphasis};
  text-transform: lowercase;
`;

const RoleName = styled.span`
  text-transform: capitalize;
`;

const RoleDescription = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
`;

const ListTitleWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const ListTitle = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const TotalPermissions = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  padding-left: ${(p) => p.theme.spacing.xs};
`;

const RolePermissionsList = styled.div`
  overflow: auto;
  height: ${(p) => `calc(100vh - 250px)`};
`;

const RolePermissionsListTitle = styled.div``;

const Permission = styled.div`
  display: flex;
  margin-bottom: ${(p) => p.theme.spacing.s};
  font-size: 16px;
  &:last-child{
    margin-bottom: 0;
  }
`;

const PermissionGroupTitle = styled.div`
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const PermissionGroup = styled.div<{ first?: boolean }>`
  padding-bottom: ${(p) => p.theme.spacing.s};
  border-bottom: ${(p) => p.first ? `1px solid ${p.theme.color.uiLine}` : "none"};
  margin-bottom: ${(p) => p.first ? p.theme.spacing.s : 0};
`;


export {
  Wrapper,
  IconWrapper,
  CloseIconWrapper,
  Header,
  Title,
  ColumnsWrapper,
  RoleInfo,
  RoleTitle,
  RoleName,
  RoleDescription,
  ListTitleWrapper,
  ListTitle,
  TotalPermissions,
  RolePermissionsList,
  RolePermissionsListTitle,
  Permission,
  PermissionGroup,
  PermissionGroupTitle,
};
