import styled from "styled-components";

export const Item = styled.li<{ selected: boolean }>`
  cursor: pointer;
  padding: ${(p) => p.theme.spacing.xs};
  margin-bottom: ${(p) => p.theme.spacing.s};
  box-shadow: ${(p) => p.theme.shadow.shadowS};
  border-radius: ${(p) => p.theme.radii.s};
  ${(p) => p.theme.textStyle.uiS};
  background-color: ${(p) => p.theme.color.interactiveBackground};
  border: 2px solid ${(p) => (p.selected ? p.theme.color.interactive01 : "transparent")};
  &:hover {
    background: ${(p) => p.theme.color.overlayHoverPrimary};
  }
  &:focus {
    background-color: ${(p) => p.theme.color.overlayFocusPrimary};
  }
`;

export const Thumbnail = styled.img`
  cursor: pointer;
`;
