import styled from "styled-components";

const RecentSiteItem = styled.div`
  background: ${(p) => p.theme.color.uiBackground02};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  margin-right: 16px;
  width: 213px;
`;

const Wrapper = styled.div`
  position: relative;
  margin-top: ${(p) => p.theme.spacing.xs};
  display: flex;
  align-items: center;
  padding-right: ${(p) => p.theme.spacing.s};
  justify-content: space-between;
`;

const Title = styled.span`
  ${(p) => p.theme.textStyle.headingXS};
  text-transform: capitalize;
`;

export { RecentSiteItem, Wrapper, Title };
