import { IFolderTree } from "@ax/types";

const getPath = (tree: IFolderTree[], targetID: number): IFolderTree[] | undefined => {
  for(const element of tree){
    if (element.id === targetID) {
        return [element];
    }
    if (element.children.length) {
        const result = getPath(element.children, targetID);
        if (result) {
            result.unshift(element);
            return result;
        }
    }
  }
};

const getNewBreadcrumb = (tree: IFolderTree[], target: number | null) => {
  if(!target) return [];

  return getPath(tree, target) || [];
}

const updatePropertyById = (id: number, data: IFolderTree[], property: string, value: any) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === id) {
        data[i] = { ...data[i], [property]: value };
      }
      if (data[i].children !== undefined && data[i].children.length > 0) {
        data[i].children = updatePropertyById(id, data[i].children, property, value);
      }
    }

    return data;
}

export { getNewBreadcrumb, updatePropertyById };
