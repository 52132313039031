import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";

export const TextArea = styled(TextareaAutosize)<{ $error: boolean | undefined }>`
  ${(p) => p.theme.textStyle.fieldContent};
  border: 1px solid ${(p) => (p.$error === true ? p.theme.color.error : p.theme.color.uiLine)};
  border-radius: 4px;
  background-color: ${(p) => p.theme.color.uiBackground02};
  color: ${(p) => p.theme.color.textHighEmphasis};
  padding: ${(p) => p.theme.spacing.s};
  height: auto;
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  width: 100%;
  resize: none;
  overflow: auto;

  &:active,
  &:focus {
    outline: none;
    border-color: ${(p) => (p.$error === true ? p.theme.color.error : p.theme.color.interactive01)};
  }

  &::placeholder {
    color: ${(p) => p.theme.color.textLowEmphasis};
  }

  &:disabled {
    color: ${(p) => p.theme.color.interactiveDisabled};
    border-color: ${(p) => p.theme.color.interactiveDisabled};
  }
`;
