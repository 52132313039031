import React from "react";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { Button, Circle, Icon } from "@ax/components";

import * as S from "./style";

const ErrorPage = (props: IProps): JSX.Element => {
  const { type } = props;
  const history = useHistory();

  const griddoLogo = "/img/logos/logoGriddoExtended@3x.svg";

  const errorTexts: Record<string, IErrorText> = {
    "404": {
      title: "Page not found",
      description: (
        <>
          Sorry, the page you are trying to access does not exist.
          <br />
          Do you want to try another one?
        </>
      ),
      buttonText: "Go to sites",
      buttonAction: () => history.push("/sites"),
    },
    "500": {
      title: "Unexpected error",
      description: (
        <>
          Sorry, we are working to fixing the problem.
          <br />
          Be back soon
        </>
      ),
      buttonText: "Go back",
      buttonAction: () => history.goBack(),
    },
    wrong: {
      icon: "working",
      title: "Something went wrong",
      description: <>Try refreshing the page, or try again later</>,
      buttonText: "Refresh page",
      buttonAction: () => history.push("/logout"),
    },
    wrongEditor: {
      icon: "working",
      title: "Something went wrong",
      description: <>Go back or try again later</>,
      buttonText: "Go back",
      buttonAction: () => history.goBack(),
    },
    forbidden: {
      icon: "alert",
      title: "Access Denied",
      description: (
        <>
          You don&apos;t have the necessary permissions to access this section. Please contact your administrator if you{" "}
          require access.
        </>
      ),
      buttonText: "Go to sites",
      buttonAction: () => history.push("/sites"),
    },
  };

  return (
    <S.Wrapper>
      <S.BackgroundAnimation>
        <Circle animation={1} />
        <Circle animation={2} />
        <S.Content>
          <S.Logo src={griddoLogo} alt="Griddo Logo" />
          {!errorTexts[type].icon && (
            <S.ContentNumber>
              <S.ErrorNumber>{type}</S.ErrorNumber>
              <S.ErrorTitle>{errorTexts[type].title}</S.ErrorTitle>
              <S.ErrorDescription>{errorTexts[type].description}</S.ErrorDescription>
              <S.ErrorAction>
                <Button className="button" type="button" onClick={errorTexts[type].buttonAction}>
                  {errorTexts[type].buttonText}
                </Button>
              </S.ErrorAction>
            </S.ContentNumber>
          )}
          {errorTexts[type].icon && (
            <S.ContentWrong>
              <S.IconWrapper>
                <Icon name={errorTexts[type].icon || "working"} size="32" />
              </S.IconWrapper>
              <S.ErrorTitleWrong>{errorTexts[type].title}</S.ErrorTitleWrong>
              <S.ErrorDescriptionWrong>{errorTexts[type].description}</S.ErrorDescriptionWrong>
              <S.ErrorAction>
                <Button className="button" type="button" onClick={errorTexts[type].buttonAction}>
                  {errorTexts[type].buttonText}
                </Button>
              </S.ErrorAction>
            </S.ContentWrong>
          )}
        </S.Content>
      </S.BackgroundAnimation>
    </S.Wrapper>
  );
};

interface IErrorPateProps {
  type: string;
}

interface IErrorText {
  title: string;
  icon?: string;
  description: JSX.Element;
  buttonText: string;
  buttonAction: () => void;
}

type IProps = IErrorPateProps & RouteComponentProps;

export default withRouter(ErrorPage);
