import styled from "styled-components";

const Wrapper = styled.div``;

const Title = styled.p`
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  padding-bottom: ${(p) => p.theme.spacing.xs};
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
`;

const ItemRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const Subtitle = styled.span`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textLowEmphasis};
`;

const Asterisk = styled.span`
  color: ${(p) => p.theme.color.error};
`;

const ActionsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

export { Wrapper, Title, ItemRow, Subtitle, Asterisk, ActionsWrapper };
