import React, { useEffect } from "react";
import { Icon, Tooltip } from "@ax/components";

import * as S from "./style";

const Tabs = (props: ITabsProps): JSX.Element => {
  const { tabs, icons, active, setSelectedTab, isInAppBar, noMargins, inversed } = props;

  useEffect(() => {
    tabs && tabs.length === 1 && setSelectedTab(tabs[0]);
  }, [tabs, setSelectedTab]);

  if (tabs) {
    return (
      <S.TabsRow isInAppBar={isInAppBar} noMargins={noMargins} data-testid="tabs-row">
        {tabs.map((tab: any) => {
          const handleClick = () => setSelectedTab(tab);
          return (
            <S.TabItem
              isInAppBar={isInAppBar}
              key={tab}
              active={tab === active}
              onClick={handleClick}
              data-testid="tab"
            >
              {tab}
            </S.TabItem>
          );
        })}
      </S.TabsRow>
    );
  }

  if (icons) {
    return (
      <S.TabsRow icons={true} isInAppBar={isInAppBar} data-testid="icons-tabs-row">
        {icons.map((tab: ITabIcon) => {
          const handleClick = () => setSelectedTab(tab.name);
          return (
            <S.TabItem
              key={tab.name}
              active={tab.name === active}
              onClick={handleClick}
              inversed={inversed}
              data-testid="icon-tab"
            >
              <S.TabIcon>
                <Tooltip content={tab.text} bottom>
                  <Icon name={tab.name} />
                </Tooltip>
              </S.TabIcon>
            </S.TabItem>
          );
        })}
      </S.TabsRow>
    );
  }

  return <S.TabsRow data-testid="empty-tabs-row"/>;
};

export interface ITabsProps {
  tabs?: any[];
  icons?: ITabIcon[];
  isInAppBar?: boolean;
  active: string;
  setSelectedTab: any;
  noMargins?: boolean;
  inversed?: boolean;
}

export interface ITabIcon {
  name: string;
  text: string;
}

export default Tabs;
