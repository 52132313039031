import styled from "styled-components";

export const Heading = styled.li`
  ${(p) => p.theme.textStyle.headingXXS};
  cursor: default;
`;

export const Dropdown = styled.ul<{ isSubmenu?: boolean; isSubmenuOpen: boolean; type: string }>`
  background-color: ${(p) =>
    p.type === "multisite" ? p.theme.color.uiMainMenuBackgroundDark : p.theme.color.uiSiteMenuDark};
  color: ${(p) => p.theme.color.textHighEmphasisInverse};
  cursor: default;
  padding-left: ${(p) => p.theme.spacing.s};
  padding-right: ${(p) => p.theme.spacing.s};
  align-items: ${(p) => (p.isSubmenu ? "flex-start" : "center")};
  border-radius: ${(p) => ` 0 ${p.theme.radii.s} ${p.theme.radii.s} 0`};
  flex-direction: ${(p) => (p.isSubmenu ? "column" : "row")};

  ${Heading} {
    margin-bottom: ${(p) => (p.isSubmenu ? p.theme.spacing.xs : "0")};
  }

  &.floating {
    display: none;
    opacity: 0;
    position: absolute;
    left: 100%;
    top: 0;
    z-index: 1000;
    height: ${(p) => (p.isSubmenu ? "auto" : "40px")};
    min-width: calc(${(p) => p.theme.spacing.xl} * 3);
    padding: ${(p) => (p.isSubmenu ? p.theme.spacing.s : "0")};
  }

  &.inline {
    position: relative;
    display: ${(p) => (p.isSubmenuOpen ? "flex" : "none")};
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    opacity: ${(p) => (p.isSubmenuOpen ? "1" : "0")};
    width: 100%;
    padding-top: ${(p) => p.theme.spacing.xs};
    padding-bottom: ${(p) => p.theme.spacing.xs};
    padding-left: ${(p) => `calc(${p.theme.spacing.m} * 2)`};

    ${Heading} {
      display: none;
      opacity: 0;
    }
  }
`;

export const Arrow = styled.div`
  margin-left: auto;
  height: ${(p) => p.theme.spacing.m};
  width: ${(p) => p.theme.spacing.m};

  path {
    fill: ${(p) => p.theme.color.textMediumEmphasisInverse};
  }
`;

export const LinkName = styled.span<{ active: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  color: ${(p) => p.active ? p.theme.color.textHighEmphasisInverse : p.theme.color.textMediumEmphasisInverse};
`;

export const Item = styled.li<{ type: string; isOpened: boolean; isSubmenuOpen: boolean; active: boolean }>`
  position: relative;
  margin-left: ${(p) => p.isSubmenuOpen ? "0" : p.theme.spacing.s};
  margin-right: ${(p) => p.isOpened && !p.isSubmenuOpen ? p.theme.spacing.s : "0"};
  padding-left: ${(p) => p.isSubmenuOpen ? p.theme.spacing.s : "0"};
  padding-right: ${(p) => p.isOpened && !p.isSubmenuOpen ? "0" : p.theme.spacing.s};
  border-radius: ${(p) => p.isOpened ? p.theme.radii.s : `${p.theme.radii.s} 0 0 ${p.theme.radii.s}`};
  background-color: ${(p) => p.isSubmenuOpen ? p.theme.color.overlayHoverDark : "transparent" };
  color: ${(p) => p.isSubmenuOpen ? p.theme.color.textHighEmphasisInverse : "inherit" };
  margin-bottom: ${(p) => p.theme.spacing.xxs};

  svg {
    path {
      fill: ${(p) => (p.isSubmenuOpen || p.active) ? p.theme.color.textHighEmphasisInverse : p.theme.color.textMediumEmphasisInverse };
    }
  }

  :hover {
    background-color: ${(p) => p.theme.color.overlayHoverDark};
    color: ${(p) => p.theme.color.textHighEmphasisInverse};

    ${LinkName} {
      color: ${(p) => p.theme.color.textHighEmphasisInverse};
    }

    svg {
      path {
        fill: ${(p) => p.theme.color.textHighEmphasisInverse};
      }
    }

    ${Dropdown}.floating {
      display: flex;
      opacity: 1;
    }

    > a.selected {
      background-color: transparent;
    }
  }

  > a,
  > button {
    width: 100%;
    padding: ${(p) => p.theme.spacing.xs};
    display: flex;
    align-items: center;
    border-radius: ${(p) => p.theme.radii.s};
    background: transparent;
    color: inherit;
    ${(p) => p.theme.textStyle.uiL};
    cursor: pointer;

    &.selected {
      background-color: ${(p) => p.isSubmenuOpen ? "transparent" : p.theme.color.overlayPressedDark };
      color: ${(p) => p.theme.color.textHighEmphasisInverse};

      path {
        fill: ${(p) => p.theme.color.textHighEmphasisInverse};
      }
    }

    &:focus {
      background-color: ${(p) => p.theme.color.overlayFocusDark};
      border: 1px solid ${(p) => (p.type === "multisite" ? p.theme.color.interactive01 : p.theme.color.interactive02)};
      color: ${(p) => p.theme.color.textHighEmphasisInverse};
      outline: transparent;

      path {
        fill: ${(p) => p.theme.color.textHighEmphasisInverse};
      }
    }

    &:active {
      background-color: ${(p) => p.theme.color.overlayPressedDark};
      color: ${(p) => p.theme.color.textHighEmphasisInverse};

      path {
        fill: ${(p) => p.theme.color.textHighEmphasisInverse};
      }
    }
  }
`;

export const NavLink = styled.a`
  display: flex;
`;

export const Icon = styled.div`
  display: flex;
  margin-right: ${(p) => p.theme.spacing.s};
  height: ${(p) => p.theme.spacing.m};
  width: ${(p) => p.theme.spacing.m};

  path {
    fill: ${(p) => p.theme.color.textMediumEmphasisInverse};
  }
`;
