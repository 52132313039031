import styled from "styled-components";

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: ${(p) => p.theme.spacing.m};
`;

const CheckWrapper = styled.div`
  label {
    margin-bottom: 0;
  }
`;

const CounterWrapper = styled.div``;

export { Header, CheckWrapper, CounterWrapper };
