import React from "react";

import { Icon, FloatingMenu, ListTitle, ListItem } from "@ax/components";
import { IQueryValue } from "@ax/types";

import * as S from "./style";

const PermissionsFilter = ({ sortItems, sortedState }: IPermissionsHeaderProps): JSX.Element => {
  const { isAscending, sortedByPermissions } = sortedState;
  const sortAscendingPermissions = () => sortItems([{ value: "permissions", label: "permissions"}], true);
  const sortDescendingPermissions = () => sortItems([{ value: "permissions", label: "permissions"}], false);

  const SortedStateArrow = () =>
    isAscending ? <Icon name="FullArrowUp" size="16" /> : <Icon name="FullArrowDown" size="16" />;

  const Header = () => (
    <S.PermissionsHeader isActive={sortedByPermissions}>
      Permissions
      <S.IconsWrapper>
        {sortedByPermissions && <SortedStateArrow />}
        <S.InteractiveArrow>
          <Icon name="DownArrow" size="16" />
        </S.InteractiveArrow>
      </S.IconsWrapper>
    </S.PermissionsHeader>
  );

  const SortedMenu = () => (
    <FloatingMenu Button={Header} position="left">
      <ListTitle>Permissions Sorting</ListTitle>
      <ListItem isSelected={sortedByPermissions && isAscending} onClick={sortAscendingPermissions}>
        Ascendent
      </ListItem>
      <ListItem isSelected={sortedByPermissions && !isAscending} onClick={sortDescendingPermissions}>
        Descendent
      </ListItem>
    </FloatingMenu>
  );

  return <SortedMenu />;
};

interface IPermissionsHeaderProps {
  sortedState: {
    isAscending: boolean;
    sortedByPermissions: boolean;
  };
  sortItems(orderPointer: IQueryValue[], isAscendent: boolean): void;
}

export default PermissionsFilter;
