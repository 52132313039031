import { ItemId, TreeData, TreeItem } from "@atlaskit/tree";
import { isEmptyObj } from "@ax/helpers";
import { IMenuItem } from "@ax/types";

const normalizeMenu = (menu: IMenuItem[], tree: TreeData) => {
  const normalized: TreeData = {
    rootId: "root",
    items: {
      root: {
        id: "root",
        children: [],
        hasChildren: true,
        isExpanded: true,
        isChildrenLoading: false,
      },
    },
  };

  const previousMenu: IMenuItem[] = [];
  if (!isEmptyObj(tree.items)) {
    previousMenu.push(...formatMenu(tree));
  }

  const isItemExpanded = (item: IMenuItem) => {
    if (!item) return true;
    return item.isExpanded;
  };

  const getElement = (item: IMenuItem, previousMenu: IMenuItem) => {
    normalized.items[item.id] = {
      ...item,
      children: [],
      isExpanded: isItemExpanded(previousMenu),
    };
    if (item.children && item.children.length > 0) {
      item.children.forEach((child: IMenuItem, index: number) => {
        normalized.items[item.id].children.push(child.id);
        getElement(child, previousMenu?.children[index]);
      });
    }
  };

  menu.forEach((item: IMenuItem, index: number) => {
    normalized.items.root.children.push(item.id);
    getElement(item, previousMenu[index]);
  });

  return normalized;
};

const getElementsFromTree = (tree: TreeData): TreeItem[] => {
  const { items } = tree;
  const elements: TreeItem[] = [];
  for (const item of Object.values(items)) {
    elements.push(item);
  }
  return elements;
};

const getChild = (child: ItemId, elements: any[]): IMenuItem => {
  const element = elements.find((element: IMenuItem) => element.id === child);
  const children = element?.children.map((child: ItemId) => getChild(child, elements));
  return { ...element, children };
};

const formatMenu = (tree: TreeData) => {
  const elements: TreeItem[] = getElementsFromTree(tree);

  const root: any = elements.find((element: TreeItem) => element.id === "root");
  const formattedMenu = root.children.map((child: ItemId) => {
    return getChild(child, elements);
  });

  return formattedMenu;
};

const formatItem = (item: any, tree: TreeData) => {
  const elements = getElementsFromTree(tree);

  const formattedItem = {
    ...item,
    children: item.children.map((child: ItemId) => getChild(child, elements)),
  };

  return formattedItem;
};

export { normalizeMenu, formatMenu, formatItem };
