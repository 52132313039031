import * as React from "react";

import { NavigationContext } from "../contexts";

/**
 * `useNavigation()`
 */
function useNavigation() {
	return React.useContext(NavigationContext);
}

export { useNavigation };
