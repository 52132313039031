import React from "react";

import * as S from "./style";

const ResizeHandle = (props: IResizeHandleProps): JSX.Element => {
  const initListeners = () => {
    window.addEventListener("mousemove", handleResize, false);
    window.addEventListener("mouseup", handleMouseUp, false);
  };

  const removeListeners = () => {
    window.removeEventListener("mousemove", handleResize, false);
    window.removeEventListener("mouseup", handleMouseUp, false);
  };

  const handleMouseDown = () => initListeners();

  const handleMouseUp = () => removeListeners();

  const handleResize = (e: MouseEvent) => {
    e.preventDefault();
    const { onMouseMove } = props;
    const newWidth = window.document.body.offsetWidth - e.clientX;
    onMouseMove(newWidth);
  };

  return <S.Handler onMouseDown={handleMouseDown} data-testid="handler" />;
};

export interface IResizeHandleProps {
  onMouseMove: (value: number) => void;
}

export default ResizeHandle;
