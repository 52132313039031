import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { IErrorItem } from "@ax/types";
import { getScheduleFormatDate, trimText } from "@ax/helpers";
import { useModal } from "@ax/hooks";
import {
  Button,
  Icon,
  IconAction,
  Flag,
  FloatingMenu,
  LanguageMenu,
  Tabs,
  Tooltip,
  ErrorCenter,
  SearchField,
  Modal,
  ExportButton,
} from "@ax/components";

import { ActionMenu, ActionSimpleMenu, DownArrowButton } from "./atoms";

import * as S from "./style";

const AppBar = (props: IProps): JSX.Element => {
  const {
    backLink,
    rightButton,
    rightLineButton,
    title,
    fixedAppBar,
    downArrowMenu,
    tabs,
    pageStatus,
    language,
    availableLanguages,
    pageLanguages,
    languageActions,
    additionalClass,
    subtitle,
    inversed,
    currentPageID,
    searchAction,
    errors,
    errorActions,
    isFromEditor,
    pageStatusActions,
    filterSearchAction,
    searchFilters,
    hasAnimation,
    searchValue,
    isDirty,
    exportAction,
    scheduledPublication,
  } = props;

  const publishedTooltip: any = {
    active: "Live",
    "upload-pending": "Publication pending",
    offline: "Offline",
    "offline-pending": "Offline pending",
    modified: "Live & Modified",
    scheduled: "Scheduled publication",
  };

  const fixedClass = fixedAppBar ? "fixed" : "";
  const isScheduledPub = !!scheduledPublication && pageStatus === "scheduled";

  const { isOpen, toggleModal } = useModal();
  const [langSelected, setLangSelected] = useState(language);

  const goToPages = () => {
    typeof backLink === "string" ? props.history.push(backLink, { isFromEditor }) : props.history.goBack();
  };

  const getCurrentLanguage = (lang: string) =>
    pageLanguages && pageLanguages.find((pageLang: any) => pageLang.locale === lang);

  const handleLanguage = (item: any) => async () => {
    if (langSelected && langSelected.locale !== item.locale && isDirty) {
      setLangSelected(item);
      toggleModal();
      return;
    } else {
      isOpen && toggleModal();
    }
    if (!languageActions || !languageActions.setLanguage) return;

    const lang = {
      locale: item.locale,
      id: item.id,
    };

    const setNewTranslation = (isNewTranslation: boolean) => {
      languageActions.createNewTranslation && languageActions.createNewTranslation(isNewTranslation, lang);
    };

    languageActions.setLanguage(lang);
    const currentLanguage = getCurrentLanguage(item.locale);

    if (languageActions.getContent && currentLanguage) {
      setNewTranslation(false);
      const id = currentLanguage && currentLanguage.pageId ? currentLanguage.pageId : currentLanguage.navigationId;
      currentLanguage && (await languageActions.getContent(id));
    }

    if (languageActions.getDataContent && currentLanguage) {
      currentLanguage && (await languageActions.getDataContent(currentLanguage.dataID));
      setNewTranslation(false);
    }

    if (languageActions.getSiteContent) {
      languageActions.getSiteContent();
    }

    if (pageLanguages && !getCurrentLanguage(item.locale)) {
      languageActions.getDataContent && currentPageID && (await languageActions.getDataContent(currentPageID));
      setNewTranslation(true);
      languageActions.getContent && currentPageID && languageActions.getContent(currentPageID);
    }
  };

  const languageMenu = (
    <LanguageMenu
      language={language && language.locale}
      availableLanguages={availableLanguages}
      currentLanguages={pageLanguages}
      setLanguage={handleLanguage}
      isInAppBar={true}
    />
  );

  const LanguageBtn = () =>
    language && (
      <>
        <S.FlagWrapper data-testid="language-menu-btn">
          <Flag name={language.locale} size="24" />
        </S.FlagWrapper>
        <S.LanguageTextWrapper data-testid="language-locale-label">{language.locale}</S.LanguageTextWrapper>
        <DownArrowButton />
      </>
    );

  const ErrorCenterBtn = () => (
    <S.ErrorWrapper>
      <Tooltip content="Error center" bottom>
        <Icon name="alert" size="24" />
      </Tooltip>
    </S.ErrorWrapper>
  );

  const StatusBtn = () =>
    pageStatus && (
      <S.StatusBtn data-testid="status-button">
        <Icon name={pageStatus} size="24" />
      </S.StatusBtn>
    );

  const statusMenu = {
    options: pageStatusActions,
  };

  const PageStatus = () => {
    const offset = rightButton || rightLineButton ? -20 : -135;
    return statusMenu.options && statusMenu.options.length > 0 ? (
      <FloatingMenu Button={StatusBtn} isInAppBar={true} position="left" offset={offset}>
        <ActionSimpleMenu menu={statusMenu} />
      </FloatingMenu>
    ) : (
      <Icon name={pageStatus ?? "offline"} size="24" />
    );
  };

  const modalText = <>If you change without saving it, it will be lost. Do you want to discard your changes?</>;

  const mainAction = {
    title: "Change language",
    onClick: handleLanguage(langSelected),
  };

  const secondaryAction = {
    title: "Cancel",
    onClick: () => {
      setLangSelected(language);
      toggleModal();
    },
  };

  const changeLanguageModalProps = {
    isOpen,
    hide: toggleModal,
    title: "Change language",
    mainAction,
    secondaryAction,
  };

  const ChangeLanguageModal = () => (
    <Modal {...changeLanguageModalProps}>
      <S.ModalContent>{modalText}</S.ModalContent>
    </Modal>
  );

  const languageTooltip = typeof currentPageID === "number" && "Add language";

  const classes = hasAnimation ? `${fixedClass} ${additionalClass} animate` : `${fixedClass} ${additionalClass}`;

  return (
    <S.Header className={classes} inversed={inversed} data-testid="appbar-header">
      <S.WrapperTitle>
        {backLink && (
          <span data-testid="back-button-wrapper">
            <Tooltip content="Back to content screen" bottom>
              <IconAction icon="FullArrowLeft" onClick={goToPages} inversed={inversed} />
            </Tooltip>
          </span>
        )}
        <S.Title>
          {trimText(title, 50)}
          <S.Subtitle>{subtitle}</S.Subtitle>
        </S.Title>
      </S.WrapperTitle>
      {tabs && (
        <S.WrapperTabs data-testid="tabs-wrapper">
          <S.TabsContent>
            <Tabs
              tabs={tabs.tabSet}
              icons={tabs.icons}
              active={tabs.selectedTab}
              setSelectedTab={tabs.action}
              isInAppBar={true}
              inversed={inversed}
            />
          </S.TabsContent>
        </S.WrapperTabs>
      )}
      <S.WrapperEnd>
        {searchAction && (
          <>
            <S.SearchWrapper length={searchFilters && searchFilters.length ? 8 : 5} data-testid="search-wrapper">
              <SearchField
                onChange={searchAction}
                onFilterChange={filterSearchAction}
                searchFilters={searchFilters}
                value={searchValue}
                closeOnInactive
              />
            </S.SearchWrapper>
            {(language || pageStatus || rightButton || rightLineButton) && <S.Separator />}
          </>
        )}
        {language && (
          <>
            <ChangeLanguageModal />
            <S.LanguageWrapper data-testid="language-wrapper">
              <Tooltip content={languageTooltip} hideOnClick bottom>
                <FloatingMenu
                  Button={LanguageBtn}
                  isInAppBar={true}
                  position="left"
                  offset={rightButton || rightLineButton ? 0 : -85}
                >
                  {languageMenu}
                </FloatingMenu>
              </Tooltip>
            </S.LanguageWrapper>
            {(pageStatus || rightButton || rightLineButton) && <S.Separator />}
          </>
        )}
        {pageStatus && (
          <>
            <S.IconStatusWrapper data-testid="page-status-wrapper" last={!rightButton && !rightLineButton}>
              <Tooltip content={publishedTooltip[pageStatus]} bottom>
                <PageStatus />
              </Tooltip>
              {isScheduledPub && (
                <S.ScheduledDate inversed={inversed}>{getScheduleFormatDate(scheduledPublication)}</S.ScheduledDate>
              )}
            </S.IconStatusWrapper>
            {(rightButton || rightLineButton) && <S.Separator />}
          </>
        )}
        {isFromEditor && errors && errors.length > 0 && (
          <>
            <S.IconStatusWrapper last={false} data-testid="error-center-wrapper">
              <FloatingMenu Button={ErrorCenterBtn} isInAppBar={true} position="left" offset={-20}>
                <ErrorCenter errors={errors} actions={errorActions} />
              </FloatingMenu>
            </S.IconStatusWrapper>
            <S.Separator />
          </>
        )}
        {exportAction && <ExportButton onClick={exportAction} />}
        {rightLineButton && (
          <Button
            className="button"
            type="button"
            buttonStyle="line"
            onClick={rightLineButton.action}
            disabled={rightLineButton.disabled}
          >
            {rightLineButton.label}
          </Button>
        )}
        {rightButton && (
          <Button className="button" type="button" onClick={rightButton.action} disabled={rightButton.disabled}>
            {rightButton.label}
          </Button>
        )}
        {downArrowMenu && downArrowMenu.displayed && (downArrowMenu.button || downArrowMenu.options.length > 0) && (
          <Tooltip content="Actions" hideOnClick bottom>
            <FloatingMenu Button={DownArrowButton} isInAppBar={true}>
              <ActionMenu menu={downArrowMenu} />
            </FloatingMenu>
          </Tooltip>
        )}
      </S.WrapperEnd>
    </S.Header>
  );
};

export interface IAppBarProps {
  backLink?: boolean | string;
  rightButton?: { label: string; disabled?: boolean; action: (e: any) => void };
  rightLineButton?: { label: string; disabled?: boolean; action: (e: any) => void };
  tabs?: { tabSet?: any; icons?: { name: string; text: string }[]; selectedTab: string; action: (e: any) => void };
  downArrowMenu?: { displayed: boolean; options: any; button: any };
  title: string;
  subtitle?: string;
  fixedAppBar?: boolean;
  pageStatus?: string;
  language?: { locale: string; id: number | null } | null;
  availableLanguages?: any[] | null;
  pageLanguages?: any[];
  languageActions?: {
    setLanguage?(lang: { locale: string; id: number | null }): void;
    createNewTranslation?(isNewTranslation: boolean, lang: { locale: string; id: number }): void;
    getContent?(id?: number): Promise<void>;
    getSiteContent?(page?: number, itemsPerPage?: number): Promise<void>;
    getDataContent?(dataID: number): Promise<void>;
  } | null;
  additionalClass?: string;
  inversed?: boolean;
  currentPageID?: number;
  searchAction?(query: string): void;
  filterSearchAction?(filter: string): void;
  searchFilters?: any;
  errors?: IErrorItem[];
  errorActions?: {
    goToError(editorID: number, tab: string, template: boolean): void;
    goToPackage(): void;
  };
  isFromEditor?: boolean;
  pageStatusActions?: any[];
  hasAnimation?: boolean;
  searchValue?: string;
  isDirty?: boolean;
  exportAction?(formats: (number | string)[]): void;
  scheduledPublication?: string | null;
}

type IProps = IAppBarProps & RouteComponentProps;

export default withRouter(AppBar);
