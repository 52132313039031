import styled from "styled-components";

const ItemWrapper = styled.div`
  display: flex;
  border: 1px solid ${p => p.theme.color.uiLine};
  border-radius: ${p => p.theme.radii.s};
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing.xs};
  padding: ${(p) => p.theme.spacing.s};
`;

const ItemImage = styled.div`
  width: ${(p) => p.theme.spacing.xl};
  margin-right: ${(p) => p.theme.spacing.s};
`;

const ItemData = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`;

const ItemType = styled.div`
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.color.textLowEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const ItemTitle = styled.div`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => p.theme.color.textHighEmphasis};
`;

export {
  ItemWrapper,
  ItemImage,
  ItemData,
  ItemType,
  ItemTitle
}
