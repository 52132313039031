import React from "react";

const SvgData = props => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 8C10.087 8 7.644 7.67 6 6.94V9.5c0 .574 1.588 1.727 6.464 1.727 4.7 0 6.412-1.071 6.53-1.663V6.921C17.35 7.67 14.91 8 12.5 8zm8.493-3.332c.005-.055.007-.111.007-.168 0-2.234-2.5-3-8.54-3C7 1.5 4 2.5 4 4.616V19.38h.053c.51 1.663 4.634 3.12 8.447 3.12 4.076 0 8.5-1.7 8.5-3.5h-.007V4.668zM19 19h-.007v-2.894c-1.645.828-4.115 1.394-6.493 1.394-2.357 0-4.832-.557-6.5-1.375V19c0 .574 1.588 1.727 6.464 1.727S19 19.574 19 19zm-.007-4.936v-2.458C17.348 12.435 14.878 13 12.5 13c-2.357 0-4.832-.557-6.5-1.375V14c0 .574 1.588 1.727 6.464 1.727 4.7 0 6.412-1.071 6.53-1.663zM6 4.5C6 3.934 7.588 3 12.46 3 17.336 3 19 3.926 19 4.5S17.372 6 12.5 6 6 5.066 6 4.5z"
      fill="#5057FF"
    />
  </svg>
);

export default SvgData;
