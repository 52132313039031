import React from "react";
import { BulkSelectionOptions } from "@ax/components";
import TableHeader from "./TableHeader";

const BulkHeader = (props: IProps): JSX.Element => {
  const { showBulk, checkState, bulkActions, selectItems, selectAllItems, totalItems, isScrolling, setHoverCheck } =
    props;

  return showBulk ? (
    <BulkSelectionOptions
      checkState={checkState}
      actions={bulkActions}
      selectItems={selectItems}
      totalItems={totalItems}
    />
  ) : (
    <TableHeader
      totalItems={totalItems}
      selectAllItems={selectAllItems}
      isScrolling={isScrolling}
      setHoverCheck={setHoverCheck}
      checkState={checkState}
    />
  );
};

interface IProps {
  showBulk: boolean;
  checkState: Record<string, boolean>;
  bulkActions: { icon: string; text: string; action: () => void }[];
  selectItems: () => void;
  selectAllItems: () => void;
  totalItems: number;
  isScrolling: boolean;
  setHoverCheck: (state: boolean) => void;
}

export default BulkHeader;
