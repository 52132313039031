import React from "react";
import { getFormattedDateWithTimezone } from "@ax/helpers";
import { CheckField } from "@ax/components";
import { ICheck, IPage } from "@ax/types";

import * as S from "./style";

const SelectionListItem = (props: ISelectionListItemProps): JSX.Element => {
  const { option, title, type, date, onClick, pageID, isSelected, onChange, multiple } = props;

  const handleClick = () => (multiple ? onChange({ value: option, isChecked: !isSelected }) : onClick(option));
  const handleChange = (value: ICheck) => onChange({ value: option, isChecked: value.isChecked });

  const pageType = type || "Page";

  return (
    <S.ListItem>
      {multiple && (
        <S.CheckWrapper>
          <CheckField name="check" value={pageID} checked={isSelected} onChange={handleChange} />
        </S.CheckWrapper>
      )}
      <S.Content onClick={handleClick} data-testid="selection-list-item">
        <S.Header>
          <S.Type>{pageType}</S.Type>
          <S.Date>{getFormattedDateWithTimezone(date, "d MMM Y")}</S.Date>
        </S.Header>
        <S.Title>{title}</S.Title>
      </S.Content>
    </S.ListItem>
  );
};

interface ISelectionListItemProps {
  option: any;
  title: string;
  type: string;
  date: Date;
  onClick: (value: any) => void;
  pageID: number;
  isSelected: boolean;
  onChange: (item: { value: IPage; isChecked: boolean }) => void;
  multiple?: boolean;
}

export default SelectionListItem;
