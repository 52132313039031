import React from "react";
import { Switch, Route } from "react-router-dom";

import Roles from "./Roles";
import List from "./UserList";

const navItems = [
  {
    title: "Users",
    path: "/users",
    component: List,
    global: true,
  },
  {
    title: "Roles",
    path: "/roles",
    component: Roles,
    global: true,
  },
  {
    title: "Users",
    path: "/sites/users",
    component: List,
  },
  {
    title: "Roles",
    path: "/sites/roles",
    component: Roles,
  },
];

const Users = (props: any): JSX.Element => {
  const { pathname } = props.location;

  const isSiteView = pathname.includes("sites");
  const currentNavItems = navItems.filter((item) =>
    isSiteView ? item.path.includes("sites") : !item.path.includes("sites")
  );

  return (
    <Switch>
      {navItems.map((item, index) => (
        <Route exact path={item.path} key={index}>
          {React.createElement(item.component, { navItems: currentNavItems, currentNavItem: item }, null)}
        </Route>
      ))}
    </Switch>
  );
};

export default Users;
