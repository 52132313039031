import styled from "styled-components";

const ListWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export { ListWrapper, TableWrapper };
