import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable, DropResult, BeforeCapture } from "react-beautiful-dnd";

import { IModule, INotification, ISchemaField } from "@ax/types";
import { ComponentContainer } from "@ax/components";
import { useBulkSelection } from "@ax/hooks";

import AddItemButton from "./AddItemButton";
import { getComponentProps, getTypefromKey } from "../helpers";
import BulkHeader from "../BulkHeader";

import * as S from "./style";

const SameComponentArray = (props: ISameComponentArrayProps): JSX.Element => {
  const {
    whiteList,
    title,
    value = [],
    goTo,
    selectedContent,
    editorID,
    actions,
    categories,
    maxItems,
    disabled,
    activatedModules,
    objKey,
    field,
    mandatory,
    theme,
  } = props;

  const type = getTypefromKey(objKey);
  const { contentType = type } = field;
  const componentIDs: number[] = value && value.length ? value.map((element) => element.editorID) : [];

  const { addModuleAction, addComponentAction, setNotificationAction, duplicateModuleAction, deleteModuleAction } =
    actions || {};

  const [isBulkOpen, setIsBulkOpen] = useState(false);
  const [draggingId, setDraggingId] = useState<number | null>(null);
  const { resetBulkSelection, selectedItems, isSelected, checkState, addToBulkSelection, selectAllItems } =
    useBulkSelection(componentIDs);

  useEffect(() => {
    if (selectedItems.all.length) {
      setIsBulkOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems.all]);

  const getText = (name: string, index: number) => {
    return value && value.length > 1 ? `#${index + 1} ${name}` : name;
  };

  const componentType = field.reference ? selectedContent[field.reference] : selectedContent["kind"];

  const isModuleArr = contentType === "modules";
  const isComponentModule = contentType === "components";

  const handleAddModule = (moduleType: string) => addModuleAction(moduleType, objKey, editorID, isComponentModule);

  const handleAddComponent = () => addComponentAction && addComponentAction(componentType, objKey);

  const handleAdd = isModuleArr ? handleAddModule : handleAddComponent;

  const showAddItemButton = !maxItems || (value && value.length < maxItems);

  const Asterisk = () => (mandatory ? <S.Asterisk>*</S.Asterisk> : null);

  const onDragEnd = (result: DropResult) => {
    const { moveModuleAction } = actions || {};

    if (!moveModuleAction || !result.destination || result.destination.index === result.source.index) {
      setDraggingId(null);
      return;
    }

    if (selectedItems.all.length > 0 && selectedItems.all.includes(parseInt(result.draggableId))) {
      moveModuleAction(selectedItems.all, selectedContent, result.destination.index, objKey);
    } else {
      moveModuleAction([parseInt(result.draggableId)], selectedContent, result.destination.index, objKey);
    }

    setDraggingId(null);
  };

  const onBeforeCapture = (start: BeforeCapture) => setDraggingId(parseInt(start.draggableId));

  const ComponentList = React.memo(function ComponentList({ components }: any) {
    return components.map((element: any, i: number) => {
      const { editorID } = element;
      const { moduleTitle, isModuleDeactivated, componentTitle, displayName, isModule } = getComponentProps(
        element,
        activatedModules,
        isModuleArr
      );
      const text = getText(componentTitle || displayName, i);
      const isItemSelected = isSelected(editorID);
      const isDraggingSelected = selectedItems.all.includes(draggingId);
      const isGhosting = isItemSelected && !!draggingId && draggingId !== editorID && isDraggingSelected;
      const isMultiDragging = selectedItems.all.length > 1 && draggingId === editorID;

      return (
        <Draggable draggableId={`${editorID}`} index={i} key={editorID}>
          {(provided, snapshot) => (
            <ComponentContainer
              isArray={true}
              key={editorID}
              editorID={editorID}
              goTo={goTo}
              text={text}
              moduleTitle={moduleTitle}
              whiteList={whiteList}
              categories={categories}
              actions={actions}
              selectedContent={selectedContent}
              disabled={disabled}
              canDuplicate={showAddItemButton && !isModuleDeactivated}
              parentKey={objKey}
              theme={theme}
              arrayLength={components.length}
              innerRef={provided.innerRef}
              provided={provided}
              isModule={isModule}
              isSelected={isItemSelected}
              onChange={addToBulkSelection}
              hasMenu={selectedItems.all.length > 0}
              isMultiDragging={snapshot.isDragging && isMultiDragging}
              draggingCount={selectedItems.all.length}
              className={`${isGhosting ? "ghosting" : ""} ${snapshot.isDragging && isMultiDragging ? "dragging" : ""}`}
            />
          )}
        </Draggable>
      );
    });
  });

  const handleDuplicate = () => {
    if (maxItems && maxItems - value.length < selectedItems.all.length) {
      const notification: INotification = {
        type: "error",
        text: "Unable to duplicate modules: The destination area has a limit on the number of modules allowed. Please adjust the selection accordingly.",
      };
      setNotificationAction && setNotificationAction(notification);
    } else {
      duplicateModuleAction && duplicateModuleAction(selectedItems.all, objKey);
      resetBulkSelection();
    }
  };

  const handleDelete = () => {
    deleteModuleAction && deleteModuleAction(selectedItems.all, objKey);
    resetBulkSelection();
  };

  const bulkActions = [
    {
      icon: "duplicate",
      text: "duplicate",
      action: handleDuplicate,
    },
    {
      icon: "delete",
      text: "delete",
      action: handleDelete,
    },
  ];

  const selectItems = () => (checkState.isAllSelected ? resetBulkSelection() : selectAllItems());

  const toggleBulk = () => {
    setIsBulkOpen(false);
    resetBulkSelection();
  };

  return (
    <S.Wrapper data-testid="sameComponentWrapper">
      <S.Title>
        {title} <Asterisk />
      </S.Title>
      <S.ItemRow>
        {isBulkOpen ? (
          <BulkHeader
            selectItems={selectItems}
            checkState={checkState}
            totalItems={selectedItems.all.length}
            toggleBulk={toggleBulk}
            actions={bulkActions}
          />
        ) : (
          <S.Subtitle>{value?.length || 0} items</S.Subtitle>
        )}
        {showAddItemButton && !disabled && (
          <AddItemButton handleClick={handleAdd} tooltipText={isModuleArr ? "Add module" : "Add component"} />
        )}
      </S.ItemRow>
      {value && Array.isArray(value) && (
        <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <ComponentList components={value} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </S.Wrapper>
  );
};

export interface ISameComponentArrayProps {
  maxItems: number;
  title: string;
  whiteList: string[];
  value?: IModule[];
  elementUniqueSelection: boolean;
  selectedContent: any;
  editorID: number;
  goTo: (editorID: string) => void;
  actions: {
    addComponentAction: (componentType: any, key?: string) => void;
    addModuleAction: (moduleType: string, key: string, selectedID: number, isComponentModule?: boolean) => void;
    deleteModuleAction: (editorID: number[], key?: string) => void;
    duplicateModuleAction: (editorID: number[], key?: string) => number;
    copyModuleAction: (editorID: number[]) => boolean | number;
    replaceElementsInCollectionAction: (newValue: string, reference?: string) => void;
    moveModuleAction: (moduleID: number[], selectedContent: any, newIndex: number, key: string) => void;
    pasteModuleAction: (editorID: number, key: string, modulesToPaste: IModule[]) => Promise<{ error?: INotification }>;
    setNotificationAction: (notification: INotification) => void;
    replaceModuleAction: (module: any, parent: any, objKey: string) => void;
  };
  categories?: any;
  disabled?: boolean;
  activatedModules: string[];
  objKey: string;
  field: ISchemaField;
  mandatory?: boolean;
  theme: string;
}

export default SameComponentArray;
