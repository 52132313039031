import React, { useEffect, useState } from "react";

import { Icon, FloatingMenu, ListTitle, ListItem } from "@ax/components";

import * as S from "./style";
import { IQueryValue } from "@ax/types";

const StateFilter = (props: IProps): JSX.Element => {
  const { value, filterItems } = props;

  const initialState = value && value.length ? value[0].value.toString() : "all";
  const [selectedValue, setSelectedValue] = useState<string>(initialState);

  const isFilterActived = selectedValue !== "all";

  const filters: Record<string, string> = {
    all: "All",
    enable: "Enabled",
    disable: "Disabled",
  };

  useEffect(() => {
    const filterValue = value && value.length ? value[0].value.toString() : "all";
    value && setSelectedValue(filterValue);
  }, [value]);

  const setFilterQuery = (selection: string) => {
    if (!selection.length) {
      selection = "";
    }
    setSelectedValue(selection);
    filterItems("filterState", [{ value: selection, label: filters[selection] }]);
  };

  const sortAllStates = () => setFilterQuery("all");
  const sortEnableState = () => setFilterQuery("enable");
  const sortDisableState = () => setFilterQuery("disable");

  const Header = () => (
    <S.State isActive={isFilterActived}>
      State
      <S.IconsWrapper>
        {isFilterActived ? (
          <Icon name="Filter" size="16" />
        ) : (
          <S.InteractiveArrow>
            <Icon name="DownArrow" size="16" />
          </S.InteractiveArrow>
        )}
      </S.IconsWrapper>
    </S.State>
  );

  return (
    <FloatingMenu Button={Header} position="center">
      <ListTitle>Filter by state</ListTitle>
      <ListItem isSelected={!isFilterActived} onClick={sortAllStates}>
        ALL
      </ListItem>
      <ListItem isSelected={selectedValue === "enable"} onClick={sortEnableState}>
        Enabled
      </ListItem>
      <ListItem isSelected={selectedValue === "disable"} onClick={sortDisableState}>
        Disabled
      </ListItem>
    </FloatingMenu>
  );
};

interface IProps {
  filterItems(pointer: string, filter: IQueryValue[]): void;
  value: IQueryValue[];
}

export default StateFilter;
