import styled from "styled-components";
import { ActionMenu } from "@ax/components";

const StyledActionMenu = styled(ActionMenu)`
  opacity: 0;
`;

const Component = styled.span<{ disabled: boolean }>`
  color: ${(p) => p.theme.color.textHighEmphasis};
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: ${(p) => p.theme.spacing.l};
  background: ${(p) => p.theme.color.uiBackground02};
  border: 1px solid transparent;
  margin-bottom: ${(p) => p.theme.spacing.xs};
  padding: 0 ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.radii.s};
  box-shadow: ${(p) => p.theme.shadow.shadowS};
  ${(p) => p.theme.textStyle.fieldLabel};
  text-align: left;
  cursor: pointer;
  pointer-events: ${(p) => (p.disabled ? "none" : "auto")};

  &:hover {
    background: ${(p) => (p.disabled ? p.theme.color.uiBackground02 : p.theme.color.overlayHoverPrimary)};
    ${StyledActionMenu} {
      opacity: 1;
    }
  }

  &:focus,
  &:active {
    background: ${(p) => (p.disabled ? p.theme.color.uiBackground02 : p.theme.color.overlayFocusPrimary)};
    border: 1px solid ${(p) => p.theme.color.interactive01};
    outline: none;
  }
`;

const FlagWrapper = styled.div`
  margin-right: ${(p) => p.theme.spacing.xs};
`;

const Default = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-left: auto;
  margin-right: ${(p) => p.theme.spacing.l};
`;

export { StyledActionMenu, Component, FlagWrapper, Default };
