import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 320px;
  height: 100%;
  flex-shrink: 0;
  background-color: ${(p) => p.theme.color.uiBackground02};
  border-left: ${(p) => `1px solid ${p.theme.color.uiLine}`};
  overflow: hidden;
  flex-flow: column nowrap;
`;

const Header = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  padding: ${(p) => p.theme.spacing.s};
  text-align: center;
  border-bottom: ${(p) => `1px solid ${p.theme.color.uiLine}`};
`;

const Content = styled.div`
  overflow: auto;
  height: 100%;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${(p) => p.theme.spacing.s};
  background-color: ${(p) => p.theme.color.uiBackground02};
  border-top: ${(p) => `1px solid ${p.theme.color.uiLine}`};
  button:not(:first-child) {
    margin-left: ${(p) => p.theme.spacing.s};
  }
`;

const FileInfo = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  padding: ${(p) => `${p.theme.spacing.s} ${p.theme.spacing.s} ${p.theme.spacing.m} ${p.theme.spacing.s}`};
  border-bottom: ${(p) => `1px solid ${p.theme.color.uiLine}`};
`;

const FileName = styled.div`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => p.theme.colors.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  word-break: break-word;
`;

const InfoLine = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const FileActions = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${(p) => p.theme.spacing.s};
  button:first-child {
    margin-right: ${(p) => p.theme.spacing.xs};
  }
`;

const FileForm = styled.div`
  padding: ${(p) => p.theme.spacing.s};
`;

const Label = styled.div`
  ${(p) => p.theme.textStyle.fieldLabel};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const FieldText = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.colors.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

export { Wrapper, Header, Content, Footer, FileInfo, FileName, InfoLine, FileActions, FileForm, Label, FieldText };
