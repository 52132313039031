import { IGetFolderParams } from "@ax/types";
import { encodeData } from "@ax/helpers";
import { template } from "./config";
import { IServiceConfig, sendRequest, sendUploadRequest } from "./utils";

const SERVICES: { [key: string]: IServiceConfig } = {
  UPLOAD_FILE: {
    ...template,
    endpoint: "/files",
    method: "POST",
  },
  GET_FOLDER_CONTENT: {
    ...template,
    endpoint: ["/site/", "/folders"],
    method: "GET",
  },
  GET_FOLDERS_TREE: {
    ...template,
    endpoint: ["/site/", "/folders/tree"],
    method: "GET",
  },
  CREATE_FOLDER: {
    ...template,
    endpoint: "/folder",
    method: "POST",
  },
  UPDATE_FOLDER: {
    ...template,
    endpoint: "/folder/",
    method: "PUT",
  },
  DELETE_FOLDER: {
    ...template,
    endpoint: "/folders/",
    method: "DELETE",
  },
  UPDATE_FILE: {
    ...template,
    endpoint: "/files/",
    method: "PUT",
  },
  DELETE_FILE: {
    ...template,
    endpoint: "/files/",
    method: "DELETE",
  },
  DELETE_FILES_BULK: {
    ...template,
    endpoint: "/files/bulk",
    method: "DELETE",
  },
  MOVE_FILE: {
    ...template,
    endpoint: ["/files/", "/folder/"],
    method: "PUT",
  },
  MOVE_FILES_BULK: {
    ...template,
    endpoint: "/files/bulk/folder/",
    method: "PUT",
  },
  REPLACE_FILE: {
    ...template,
    endpoint: ["/files/", "/replace"],
    method: "PUT",
  },
  DOWNLOAD_FILES: {
    ...template,
    endpoint: "/files/download/",
    method: "POST",
    responseType: "blob",
  },
};

const uploadFile = async (data: FormData, setProgress?: (progress: number) => void) => {
  return sendUploadRequest(SERVICES.UPLOAD_FILE, data, setProgress);
};

const getFolderContent = async (params: IGetFolderParams) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_FOLDER_CONTENT;

  const { siteID, folderID, search, query: filters = "" } = params;

  const data = {
    ...(folderID && { folder: folderID }),
    ...(search && { search }),
  };

  const queryParams = encodeData(data);
  const queryFilters = queryParams.length ? `${queryParams}&${filters}` : filters;
  const query = queryFilters.length ? `?${queryFilters}` : "";

  SERVICES.GET_FOLDER_CONTENT.dynamicUrl = `${host}${prefix}${siteID}${suffix}${query}`;

  return sendRequest(SERVICES.GET_FOLDER_CONTENT);
};

const getFoldersTree = async (siteID: number | "global") => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_FOLDERS_TREE;

  SERVICES.GET_FOLDERS_TREE.dynamicUrl = `${host}${prefix}${siteID}${suffix}`;

  return sendRequest(SERVICES.GET_FOLDERS_TREE);
};

const createFolder = async (data: { folderName: string; site: number | "global"; parentId?: number }) => {
  return sendRequest(SERVICES.CREATE_FOLDER, data);
};

const updateFolder = async (folderID: number, data: { parentId: number; folderName: string }) => {
  const { host, endpoint } = SERVICES.UPDATE_FOLDER;
  SERVICES.UPDATE_FOLDER.dynamicUrl = `${host}${endpoint}${folderID}`;

  return sendRequest(SERVICES.UPDATE_FOLDER, data);
};

const deleteFolder = async (folderID: number) => {
  const { host, endpoint } = SERVICES.DELETE_FOLDER;
  SERVICES.DELETE_FOLDER.dynamicUrl = `${host}${endpoint}${folderID}`;

  return sendRequest(SERVICES.DELETE_FOLDER);
};

const updateFile = async (fileID: number, data: { title: string; alt: string; tags: string[] }) => {
  const { host, endpoint } = SERVICES.UPDATE_FILE;
  SERVICES.UPDATE_FILE.dynamicUrl = `${host}${endpoint}${fileID}`;

  return sendRequest(SERVICES.UPDATE_FILE, data);
};

const deleteFile = async (fileID: number) => {
  const { host, endpoint } = SERVICES.DELETE_FILE;
  SERVICES.DELETE_FILE.dynamicUrl = `${host}${endpoint}${fileID}`;

  return sendRequest(SERVICES.DELETE_FILE);
};

const deleteFilesBulk = async (ids: number[]) => sendRequest(SERVICES.DELETE_FILES_BULK, { ids });

const moveFile = async (fileID: number, folderID: number) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.MOVE_FILE;

  SERVICES.MOVE_FILE.dynamicUrl = `${host}${prefix}${fileID}${suffix}${folderID}`;

  return sendRequest(SERVICES.MOVE_FILE);
};

const moveFilesBulk = async (ids: number[], folderID: number) => {
  const { host, endpoint } = SERVICES.MOVE_FILES_BULK;

  SERVICES.MOVE_FILES_BULK.dynamicUrl = `${host}${endpoint}${folderID}`;

  return sendRequest(SERVICES.MOVE_FILES_BULK, { ids });
};

const replaceFile = async (
  data: FormData,
  fileID: number,
  keepUrl: boolean,
  setProgress?: (progress: number) => void
) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.REPLACE_FILE;

  const query = keepUrl ? "?keepUrl=on" : "";

  SERVICES.REPLACE_FILE.dynamicUrl = `${host}${prefix}${fileID}${suffix}${query}`;

  return sendUploadRequest(SERVICES.REPLACE_FILE, data, setProgress);
};

const downloadFiles = async (fileID: number | number[], zip: boolean) => {
  const { host, endpoint } = SERVICES.DOWNLOAD_FILES;

  const query = zip ? "?zip=on" : "";

  SERVICES.DOWNLOAD_FILES.dynamicUrl = `${host}${endpoint}${fileID}${query}`;

  return sendRequest(SERVICES.DOWNLOAD_FILES);
};

export default {
  uploadFile,
  getFolderContent,
  getFoldersTree,
  createFolder,
  updateFolder,
  deleteFolder,
  updateFile,
  deleteFile,
  deleteFilesBulk,
  moveFile,
  moveFilesBulk,
  replaceFile,
  downloadFiles,
};
