import React from "react";
import styled from "styled-components";
import CheckField from "../CheckField";

const FieldGroup = styled.div<{ full?: boolean }>`
  width: 100%;
  max-width: calc(${(p) => p.theme.spacing.xl} * 5);
  max-height: ${(p) => (p.full ? "auto" : `calc(${p.theme.spacing.l} * 3)`)};
  overflow: auto;
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const GroupWrapper = styled.div`
  padding-left: ${(p) => p.theme.spacing.s};
  padding-bottom: ${(p) => p.theme.spacing.xxs};
`;

const GroupTitle = styled.div`
  ${(p) => p.theme.textStyle.fieldContent};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  font-style: italic;
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const StyledCheckField = styled((props) => <CheckField {...props} />)`
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

export { FieldGroup, GroupWrapper, GroupTitle, StyledCheckField };
