import styled from "styled-components";

export const BreadcrumbGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const BreadcrumbItem = styled.button<{isLastItem: boolean}>`
  border: none;
  ${p => p.theme.textStyle.uiS};
  color: ${p => p.isLastItem ? p.theme.color.textMediumEmphasis : p.theme.color.interactive01};
  background: transparent;
  padding: 0 ${p => p.theme.spacing.xxs};
  border-radius: ${p => p.theme.radii.s};
  cursor: ${p => p.isLastItem ? "default" : "pointer"};
  border: 1px solid transparent;

  &:active,
  &:focus {
    outline: none;
  }

  &:focus {
    border: ${p => p.isLastItem ? "none" : `1px solid ${p.theme.color.interactive01}`};
    background: ${p => p.isLastItem ? "none" : p.theme.color.overlayFocusPrimary};
  }

  &:active {
    background: ${p => p.isLastItem ? "none" : p.theme.color.overlayPressedPrimary};
  }

  &:hover {
    background: ${p => p.isLastItem ? "none" : p.theme.color.overlayHoverPrimary};
  }
`;

export const Icon = styled.span`
  display: inline-flex;
`;
