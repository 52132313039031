import React from "react";
import { trimText } from "@ax/helpers";

import * as S from "./style";

const ElementsTooltip = (props: IElementsTooltipProps): JSX.Element => {
  const {
    elements,
    maxChar,
    defaultElements = 1,
    colors,
    rounded = false,
    elementsPerRow = 1,
    defaultColor,
    prefix = "",
    size = "S",
  } = props;

  if (!elements) return <></>;

  const visibleElements = elements.slice(0, defaultElements);
  const remainingElements = elements.length - defaultElements;

  const elementsRows: string[][] = [];
  elements.forEach((element, idx) => {
    const row = Math.floor(idx / elementsPerRow);
    const isNewRow = idx % elementsPerRow === 0;
    elementsRows[row] = isNewRow ? [element] : [...elementsRows[row], element];
  });

  return (
    <S.Wrapper data-testid="elements-wrapper">
      {visibleElements.map((fullElement, idx) => {
        const element = defaultElements === 1 && maxChar ? trimText(fullElement, maxChar) : fullElement;
        const color = defaultColor ? defaultColor : colors && colors[element] ? colors[element] : undefined;

        return (
          <S.Element data-testid="element" key={idx} color={color} rounded={rounded} size={size}>
            {prefix}
            {element}
          </S.Element>
        );
      })}
      {remainingElements > 0 && (
        <S.Element data-testid="remaining-element" rounded={rounded} size={size}>
          +{remainingElements}
          <S.Tooltip>
            {elementsRows.map((row, idx) => {
              return (
                <S.Row data-testid="row-element" key={idx}>
                  {row.map((element, idx) => {
                    const color = defaultColor ? defaultColor : colors && colors[element] ? colors[element] : undefined;
                    return (
                      <S.Element data-testid="div-element" key={idx} color={color} rounded={rounded} size={size}>
                        {prefix}
                        {element}
                      </S.Element>
                    );
                  })}
                </S.Row>
              );
            })}
          </S.Tooltip>
        </S.Element>
      )}
    </S.Wrapper>
  );
};

export interface IElementsTooltipProps {
  elements?: string[];
  defaultElements?: number;
  maxChar?: number;
  colors?: any;
  rounded?: boolean;
  elementsPerRow?: number;
  defaultColor?: string;
  prefix?: string;
  size?: "S" | "M";
}

export default ElementsTooltip;
