import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  background-color: ${p => p.theme.color.uiBarBackground};
  width: 318px;
  border-bottom: 1px solid ${p => p.theme.color.uiLine};
`;

export {
  Wrapper,
}
