import styled from "styled-components";

const Content = styled.div`
  padding: ${(p) => p.theme.spacing.s};
  overflow: auto;
  height: 100%;
`;

const Note = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  background-color: ${(p) => p.theme.color.uiBackground03};
  padding: ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.radii.s};
`;

const ListWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.xs};
`;

export { Content, Note, ListWrapper };
