import { IDimensionsGroup } from "@ax/types";
import { splitAndTrim } from "@ax/helpers";

const getTemplateDimensions = (groups: IDimensionsGroup[], template: string): string[] => {
  const templateDimensionsGroups = groups?.filter((group) => group.templates?.includes(template));
  const templateDimensions = templateDimensionsGroups?.reduce((dimensions: string[], group: IDimensionsGroup) => {
    return [...dimensions, ...splitAndTrim(group.dimensions, ";")];
  }, []);
  const uniqueTemplateDimensions = [...new Set(templateDimensions)];
  return uniqueTemplateDimensions;
};

export { getTemplateDimensions };
