import React from "react";

import { Icon, FloatingMenu, ListTitle, ListItem } from "@ax/components";
import { IQueryValue } from "@ax/types";

import * as S from "./style";

const UsersFilter = (props: IUsersFilterProps): JSX.Element => {
  const { sortItems, sortedState } = props;
  const { isAscending, sortedByUsers } = sortedState;

  const sortByUsers = (isAscending: boolean) => sortItems([{ value: "users", label: "users"}], isAscending);
  const sortAscendingUsers = () => sortByUsers(true);
  const sortDescendingUsers = () => sortByUsers(false);

  const SortedStateArrow = () =>
    isAscending ? <Icon name="FullArrowUp" size="16" /> : <Icon name="FullArrowDown" size="16" />;

  const isActive = sortedByUsers;
  const title = 'Users';

  const Header = () => (
    <S.UsersHeader isActive={isActive} data-testid="users-filter-header">
      {title}
      <S.IconsWrapper>
        {isActive ? (
          <SortedStateArrow />
        ) : (
          <S.InteractiveArrow data-testid="users-filter-interactive-arrow">
            <Icon name="DownArrow" size="16" />
          </S.InteractiveArrow>
        )}
      </S.IconsWrapper>
    </S.UsersHeader>
  );

  return (
    <FloatingMenu Button={Header} position="left">
      <ListTitle>Number of users</ListTitle>
      <ListItem isSelected={sortedByUsers && isAscending} onClick={sortAscendingUsers}>
        Ascendent
      </ListItem>
      <ListItem isSelected={sortedByUsers && !isAscending} onClick={sortDescendingUsers}>
        Descendent
      </ListItem>
    </FloatingMenu>
  );
};

export interface IUsersFilterProps {
  sortedState: any;
  sortItems(orderPointer: IQueryValue[], isAscendent: boolean): void;
  pointer?: string;
}

export default UsersFilter;
