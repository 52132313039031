import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const FieldWrapper = styled.div<{ closeOnInactive: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  border-color: ${(p) =>
    p.disabled
      ? p.theme.color.interactiveDisabled
      : p.closeOnInactive
      ? p.theme.color.interactive01
      : p.theme.color.uiLine};
  border-width: ${(p) => (p.closeOnInactive ? "0 0 1px" : "1px")};
  border-style: solid;
  border-radius: ${(p) => (p.closeOnInactive ? 0 : p.theme.radii.s)};
  &:focus-within {
    border-color: ${(p) => (p.disabled ? p.theme.color.interactiveDisabled : p.theme.color.interactive01)};
  }
`;

const Input = styled.input<{
  closeOnInactive: boolean;
  disabled: boolean;
  small?: boolean;
  inputSize: "M" | "S" | "XS";
}>`
  ${(p) => p.theme.textStyle.fieldContent};
  color: ${(p) => p.theme.color.textHighEmphasis};
  background-color: ${(p) => p.theme.color.interactiveBackground};
  flex: 1 1 auto;
  height: ${(p) => (p.inputSize === "XS" ? "40px" : p.theme.spacing.l)};
  padding-left: ${(p) => p.theme.spacing.xs};
  border: none;
  border-radius: ${(p) => (p.closeOnInactive ? 0 : p.theme.radii.s)};
  width: ${(p) =>
    p.small || p.inputSize !== "M" ? `calc(${p.theme.spacing.xl} * 2)` : `calc(${p.theme.spacing.xl} * 4)`};

  &:active,
  &:focus {
    outline: none;
  }
`;

const IconSearchWrapper = styled.div<{ onClick?: () => void; disabled: boolean }>`
  position: absolute;
  right: ${(p) => p.theme.spacing.xxs};
  top: 50%;
  width: ${(p) => p.theme.spacing.m};
  height: ${(p) => p.theme.spacing.m};
  cursor: ${(p) => (p.onClick ? "pointer" : "default")};
  transform: translateY(-50%);
  svg {
    path {
      fill: ${(p) => (p.disabled ? p.theme.color.interactiveDisabled : p.theme.color.interactive01)};
    }
  }
`;

const HelpText = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textLowEmphasis};
  font-style: italic;
  position: absolute;
  right: ${(p) => `calc(${p.theme.spacing.xxs} * 2 + ${p.theme.spacing.m})`};
  top: 50%;
  transform: translateY(-50%);
`;

const IconCloseWrapper = styled.div`
  position: absolute;
  right: ${(p) => p.theme.spacing.xxs};
  top: 50%;
  width: ${(p) => p.theme.spacing.m};
  height: ${(p) => p.theme.spacing.m};
  cursor: pointer;
  transform: translateY(-50%);
`;

const FilterWrapper = styled.div``;

const Separator = styled.div`
  border-right: 1px solid ${(p) => p.theme.color.uiLine};
  height: ${(p) => p.theme.spacing.m};
  margin-right: ${(p) => p.theme.spacing.xs};
  margin-left: ${(p) => p.theme.spacing.s};
`;

export { Wrapper, IconSearchWrapper, FieldWrapper, Input, HelpText, IconCloseWrapper, FilterWrapper, Separator };
