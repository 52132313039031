import React, { useState, useEffect } from "react";

import { IDimension, IDimensionsGroup } from "@ax/types";
import { FloatingPanel, Button, FieldsBehavior, CheckGroup, RadioGroup } from "@ax/components";
import { splitAndTrim } from "@ax/helpers";

import * as S from "./style";
import { getTemplateOptions } from "./utils";

const GroupPanel = (props: IProps): JSX.Element => {
  const { isOpen, toggleModal, item, setGroupItem, dimensions } = props;

  const [name, setName] = useState("");
  const [selectedDimensions, setSelectedDimensions] = useState<(string | never)[]>(["all"]);
  const [selectedTemplates, setSelectedTemplates] = useState<(string | never)[]>([]);
  const [hasTemplate, setHasTemplate] = useState(false);
  const [errors, setErrors] = useState({ name: false });

  const templates = getTemplateOptions();
  const resetState = () => {
    setName(item?.name || "");
    setErrors({ name: false });

    const dimensionOptions = [];
    const dimensionsArr = splitAndTrim(item?.dimensions, ";");
    const isAllDimensions = dimensionsArr.length === dimensions.length;
    !item || isAllDimensions ? dimensionOptions.push("all") : dimensionOptions.push(...dimensionsArr);
    setSelectedDimensions(dimensionOptions);

    const templateOptions = [];
    const templatesArr = splitAndTrim(item?.templates, ";");
    !!item && templateOptions.push(...templatesArr);
    const _templateOptions = templateOptions.filter((option) => option !== "null");
    setSelectedTemplates(_templateOptions);

    const hasTemplate = !!templateOptions.length && !templateOptions.includes("null");
    setHasTemplate(hasTemplate);
  };

  useEffect(() => {
    isOpen && resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const editItemAction = () => {
    const dimensionsArr = selectedDimensions.includes("all")
      ? dimensions.map((dimension) => dimension.name)
      : selectedDimensions;
    const dimensionsStr = `;${dimensionsArr.join(";")}`;
    const templatesStr = `;${selectedTemplates.join(";")}`;

    const value = {
      ...item,
      name,
      dimensions: dimensionsStr,
      templates: templatesStr,
    };
    setGroupItem(value);

    toggleModal();
  };

  const validateFields = () => {
    const errorFields = {
      name: !name.length,
    };
    setErrors(errorFields);
    const isFieldsValid = Object.values(errorFields).every((error) => !error);
    isFieldsValid && editItemAction();
  };

  const title = item ? "Update Dimensions Group" : "Create Dimensions Group";

  const editButton = {
    label: title,
    action: validateFields,
  };

  const initialDimensionOption = {
    name: "all",
    value: "all",
    title: "All Dimensions",
  };
  const dimensionOptions = [{ ...initialDimensionOption }];
  dimensions?.forEach((dimension) => {
    const { name } = dimension;
    const option = {
      name: name,
      value: name,
      title: name,
    };
    return dimensionOptions.push(option);
  });

  const setDimensions = (selection: any) => {
    if (!selection.length) selection = ["all"];
    setSelectedDimensions([...selection]);
  };

  const setTemplates = (selection: any) => {
    setSelectedTemplates([...selection]);
  };

  const selectedDimensionsN = selectedDimensions.includes("all") ? dimensions?.length : selectedDimensions.length;

  const hasTemplateOpts = [
    {
      title: "No",
      value: "no",
      name: "no",
    },
    {
      title: "Yes",
      value: "yes",
      name: "yes",
    },
  ];
  const handleChangeHasTemplate = (value: string | boolean) => {
    const hasTemplate = value === "yes";
    setHasTemplate(hasTemplate);
    !hasTemplate && setSelectedTemplates([]);
  };

  return (
    <FloatingPanel title={title} toggleModal={toggleModal} isOpen={isOpen}>
      <S.Wrapper>
        <S.ContentWrapper>
          <FieldsBehavior
            title="Name"
            name="name"
            fieldType="TextField"
            value={name}
            onChange={setName}
            mandatory
            validators={{ mandatory: true }}
            error={errors.name}
          />
          <S.Divider />
          <S.Heading>
            <S.HeadingText>Dimensions</S.HeadingText>
            <S.HeadingCounter>
              {selectedDimensionsN}/{dimensions?.length}
            </S.HeadingCounter>
          </S.Heading>
          <S.Description>Select the dimensions to this content.</S.Description>
          <CheckGroup
            options={dimensionOptions}
            value={selectedDimensions}
            onChange={setDimensions}
            selectAllOption="all"
          />
          <S.Divider />
          <S.Heading $spaceBetween>
            <S.HeadingText>Associated to a template</S.HeadingText>
            <S.RadioTemplate>
              <RadioGroup
                name="hasTemplate"
                value={hasTemplate ? "yes" : "no"}
                options={hasTemplateOpts}
                onChange={handleChangeHasTemplate}
                inline
              />
            </S.RadioTemplate>
          </S.Heading>
          <S.Description>
            If you create a page of this template, it will appear with these dimensions by default.
          </S.Description>
          {hasTemplate ? <CheckGroup options={templates} value={selectedTemplates} onChange={setTemplates} /> : <></>}
        </S.ContentWrapper>
        <S.Footer>
          <Button className="button" type="button" onClick={editButton.action}>
            {editButton.label}
          </Button>
        </S.Footer>
      </S.Wrapper>
    </FloatingPanel>
  );
};

interface IProps {
  item?: IDimensionsGroup;
  isOpen: boolean;
  toggleModal: () => void;
  setGroupItem(content: IDimensionsGroup): void;
  dimensions: IDimension[];
}

export default GroupPanel;
