import styled from "styled-components";

const Wrapper = styled.div`
  width: ${p => `calc(${p.theme.spacing.l} * 5)`};
  border-radius: ${p => p.theme.radii.s};
  border: 1px solid ${(p) => p.theme.color.uiLine};
  background-color: ${(p) => p.theme.color.interactiveBackground};
  position: absolute;
  z-index: 2;
`;

const Cover = styled.div<{background: string; light: boolean}>`
  display: flex;
  ${(p) => p.theme.textStyle.headingM};
  background-color: ${(p) => p.background ? p.background : "#FFFFFF"};
  width: 100%;
  height: 145px;
  color: ${(p) => p.light ? p.theme.color.textMediumEmphasis : p.theme.color.textHighEmphasisInverse};
  align-items: center;
  justify-content: center;
  border-radius: ${p => `${p.theme.radii.s} ${p.theme.radii.s} 0 0`};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: ${p => p.theme.spacing.xs} ${p => p.theme.spacing.xxs};
  padding: ${p => p.theme.spacing.s};
`;

const GridItem = styled.div<{background: string; selected: boolean}>`
  background-color: ${(p) => p.background};
  border-radius: ${p => p.theme.radii.s};
  width: 32px;
  height: 32px;
  margin-right: ${p => p.theme.spacing.xs};
  cursor: pointer;
  box-shadow: ${p => p.selected ? `0 0 4px ${p.background}` : "none"};
`;

const InputWrapper = styled.div`
  padding: ${p => `0 ${p.theme.spacing.s} ${p.theme.spacing.s} ${p.theme.spacing.s}`};
`;

const Input = styled.input`
  ${(p) => p.theme.textStyle.fieldContent};
  width: 100%;
  border: 1px solid ${(p) => p.theme.color.uiLine};
  border-radius: ${p => p.theme.radii.s};
  height: 32px;
  padding: ${(p) => `0 ${p.theme.spacing.xs}`};
  &:active,
  &:focus {
    outline: none;
    border-color: ${(p) => p.theme.color.uiLine};
  }
`;

export { Wrapper, Cover, Grid, GridItem, InputWrapper, Input };
