import { Dispatch } from "redux";

import { SET_SOCIAL } from "./constants";

import { ISetSocialAction } from "./interfaces";

import { appActions } from "@ax/containers/App";
import { handleRequest } from "@ax/helpers";
import { social } from "@ax/api";

function setSocial(socialInfo: any): ISetSocialAction {
  return { type: SET_SOCIAL, payload: { ...socialInfo } };
}

function saveSocial(form: Record<string, string>): (dispatch: Dispatch, getState: any) => Promise<boolean> {
  return async (dispatch, getState) => {
    try {
      const {
        sites: { currentSiteInfo },
      } = getState();

      const responseActions = {
        handleSuccess: (data: Record<string, string>) => {
          dispatch(setSocial(data));
          return true;
        },
        handleError: (data: any) => appActions.handleError(data)(dispatch),
      };

      const callback = async () => social.updateSocial(currentSiteInfo.id, form);

      return await handleRequest(callback, responseActions, [appActions.setIsSaving])(dispatch);
    } catch (e) {
      console.log(e);
      return false;
    }
  };
}

function getSocial(): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch, getState) => {
    try {
      const {
        sites: { currentSiteInfo },
      } = getState();

      const responseActions = {
        handleSuccess: (data: Record<string, string>) => {
          dispatch(setSocial(data));
        },
        handleError: () => console.log("Error en getSocial"),
      };

      const callback = () => social.getSocial(currentSiteInfo.id);

      await handleRequest(callback, responseActions, [appActions.setIsLoading])(dispatch);
    } catch (e) {
      console.log(e);
    }
  };
}

export { getSocial, saveSocial, setSocial };
