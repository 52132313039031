import React, { Dispatch, SetStateAction } from "react";
import { connect } from "react-redux";

import { useModal } from "@ax/hooks";
import { getDataPackSchema } from "@ax/helpers";
import { dataPacksActions } from "@ax/containers/Settings";
import { IRootState, ISite } from "@ax/types";
import { IconAction, InformativeMenu, Modal } from "@ax/components";

import * as S from "./style";

const Item = (props: IProps): JSX.Element => {
  const { getActivatedDataPack, pack, toggleToast, deleteSiteDataPack, setDeactivatedPack, currentSiteInfo } = props;

  if (!currentSiteInfo) {
    throw new Error(`ERROR: User reached DataPacks with null site info`);
  }

  const { title, id, templates, config } = pack;
  const isFromPage = templates && templates.length > 0;
  const { isOpen, toggleModal } = useModal();

  const deactivateDataPack = (force = false) =>
    deleteSiteDataPack(id, toggleModal, force).then((deactivated: boolean) => {
      if (deactivated) {
        setDeactivatedPack(id);
        isOpen && toggleModal();
        toggleToast();
      }
    });

  const forceDeleteAction = () => {
    const force = true;
    deactivateDataPack(force);
  };

  const mainAction = {
    title: "Deactivate",
    onClick: forceDeleteAction,
  };

  const secondaryAction = {
    title: "Cancel",
    onClick: toggleModal,
  };

  const deleteModalProps = {
    isOpen,
    hide: toggleModal,
    title: "Deactivate package",
    mainAction,
    secondaryAction,
  };

  const infoMenuProps = {
    icon: "alert",
    message: "You need to configure this package’s settings.",
    actionText: "Got it",
  };

  const modalText = (
    <>
      You are deactivating a <strong>{title}</strong> package. Some of its content is being used in existing pages. By
      deactivating it, you won&apos;t be able to edit the content nor add new modules tied to it. You will only be able
      to delete it.
    </>
  );

  const DeleteModal = () => (
    <Modal {...deleteModalProps}>
      <S.ModalContent>{modalText}</S.ModalContent>
    </Modal>
  );

  const deleteAction = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const force = false;
    deactivateDataPack(force);
  };

  const setPack = () => getActivatedDataPack(pack.id);

  const schema = getDataPackSchema(pack.id);
  const { image } = schema;

  const imageUrl = typeof image === "string" ? image : image[currentSiteInfo.theme];

  return (
    <>
      <DeleteModal />
      <S.Pack onClick={setPack}>
        <S.Thumbnail backgroundUrl={imageUrl} />
        <S.Footer>
          <S.Title>{title}</S.Title>
          <S.IconsWrapper>
            <S.DeactivateIcon>
              <IconAction icon="delete" onClick={deleteAction} />
            </S.DeactivateIcon>
            {isFromPage && !config && <InformativeMenu {...infoMenuProps} />}
          </S.IconsWrapper>
        </S.Footer>
      </S.Pack>
    </>
  );
};

interface IProps {
  pack: any;
  currentSiteInfo: ISite | null;
  getActivatedDataPack: (selected: string) => void;
  toggleToast: () => void;
  setDeactivatedPack: Dispatch<SetStateAction<string>>;
  deleteSiteDataPack: (dataPackID: string, errorAction: any, force?: boolean) => Promise<boolean>;
}

const mapStateToProps = (state: IRootState) => ({
  currentSiteInfo: state.sites.currentSiteInfo,
});

const mapDispatchToProps = {
  getActivatedDataPack: dataPacksActions.getActivatedDataPack,
  deleteSiteDataPack: dataPacksActions.deleteSiteDataPack,
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
