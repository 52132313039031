import React from "react";
import { Icon } from "@ax/components";

import * as S from "./style";

const IconAction = (props: IIconActionProps): JSX.Element => {
  const { icon, onClick, disabled, inversed, size = "m", active } = props;
  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick !== undefined) {
      e.preventDefault();
      onClick(e);
    }
  };

  return (
    <S.Button
      data-testid="icon-action-component"
      disabled={disabled}
      onClick={handleOnClick}
      size={size}
      inversed={inversed}
      active={active}
    >
      <S.Icon data-testid={`icon-action-${icon}`}>
        <Icon name={icon} />
      </S.Icon>
    </S.Button>
  );
};

export interface IIconActionProps {
  icon: string;
  disabled?: boolean;
  size?: "s" | "m";
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  inversed?: boolean;
  active?: boolean;
}

export default IconAction;
