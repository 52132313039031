import React from "react";
import { IQueryValue } from "@ax/types";
import Button from "../Button";

import * as S from "./style";

const FilterTagsBar = (props: IFilterTagsBarProps): JSX.Element => {
  const { filters, labels, setFilters, resetFilters } = props;

  const tags: string[] = [];
  filters &&
    Object.keys(filters)
      .filter((key: string) => Object.keys(labels).includes(key))
      .forEach((key: string) => {
        Array.isArray(filters[key]) &&
          filters[key].forEach((filter: IQueryValue) => {
            if (filter && filter.value !== "all") {
              tags.push(`${labels[key]}: ${filter.label}`);
            }
          });
      });

  const deleteTag = (tagText: string) => {
    const values = tagText.split(":");
    const key = Object.keys(labels).find((key) => labels[key] === values[0]);
    if (key) {
      const newFilters: IQueryValue[] = filters[key].filter((filter: IQueryValue) => filter.label !== values[1].trim());
      setFilters(key, newFilters);
    }
  };

  const handleDeleteAll = () => resetFilters();

  return tags.length > 0 ? (
    <S.Wrapper>
      <S.Text>Selected filters</S.Text>
      <S.TagList>
        {tags.map((tag: string, index: number) => {
          const handleDeleteTag = () => deleteTag(tag);
          return <S.StyledTag key={index} text={tag} color="#FFFFFF" onDeleteAction={handleDeleteTag} />;
        })}
      </S.TagList>
      <S.ButtonWrapper>
        <Button type="button" buttonStyle="minimal" icon="close" onClick={handleDeleteAll}>
          Clear All
        </Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  ) : (
    <></>
  );
};

export interface IFilterTagsBarProps {
  filters: Record<string, any>;
  labels: Record<string, string | undefined>;
  setFilters: (pointer: string, filter: IQueryValue[], isAscendent?: boolean) => void;
  resetFilters: () => void;
}

export default FilterTagsBar;
