import styled from "styled-components";

const BrowserWrapper = styled.div`
  background-color: ${(p) => p.theme.color.uiBackground01};
  height: 100%;
  width: 100%;
`;

const NavBar = styled.div`
  display: flex;
  background-color: ${(p) => p.theme.color.uiBackground03};
  border-radius: 4px 4px 0 0;
  height: calc(${(p) => p.theme.spacing.m} + (${(p) => p.theme.spacing.xs} * 2));
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.m};
`;

const NavUrl = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  background-color: ${(p) => p.theme.color.uiBackground02};
  border-radius: 10px;
  color: ${(p) => p.theme.color.textMediumEmphasis};
  height: ${(p) => p.theme.spacing.m};
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.s};
  width: 100%;
`;

const NavActions = styled.div`
  display: flex;
  padding-left: ${(p) => p.theme.spacing.xs};
`;

const IconWrapper = styled.div<{ active?: boolean }>`
  margin-left: ${(p) => p.theme.spacing.m};
  cursor: pointer;
  :hover {
    svg {
      path {
        fill: ${(p) => p.theme.color.interactive01};
      }
    }
  }
  svg {
    path {
      fill: ${(p) => (p.active ? p.theme.color.interactive01 : p.theme.color.iconNonActive)};
    }
  }
`;

const FrameWrapper = styled.div`
  border-left: 1px solid ${(p) => p.theme.color.uiLine};
  border-right: 1px solid ${(p) => p.theme.color.uiLine};
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
  display: flex;
  justify-content: center;
  height: 100%;
`;

const Wrapper = styled.div`
  border-left: 1px solid ${(p) => p.theme.color.uiLine};
  border-right: 1px solid ${(p) => p.theme.color.uiLine};
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
  overflow: auto;
  scroll-behavior: smooth;
  height: 100%;
  position: relative;

  .headroom {
    position: relative !important;
  }
`;

export { BrowserWrapper, NavBar, NavUrl, NavActions, IconWrapper, FrameWrapper, Wrapper };
