import styled from "styled-components";
import { BulkSelectionOptions } from "@ax/components";

const BulkWrapper = styled.div`
  width: 100%;
  span {
    margin-left: ${(p) => p.theme.spacing.xs};
  }
`;

const StyledBulkSelectionOptions = styled(BulkSelectionOptions)`
  padding: ${(p) => `0 0 ${p.theme.spacing.m} 0`};
  margin-top: ${(p) => `-${p.theme.spacing.xs}`};
  margin-bottom: ${(p) => `-${p.theme.spacing.xs}`};
`;

export { BulkWrapper, StyledBulkSelectionOptions }
