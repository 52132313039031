import { getSchemaThumbnails, getTemplateThumbnails } from "./schemas";

const imageExists = (src: string) => {
  const req = new XMLHttpRequest();
  req.open("Head", src, false);
  req.send(null);
  return req.status !== 404;
};

const getThumbnailProps = (option: any, template: boolean, theme: string, path?: string) => {
  let PATH = "";
  let thumbnails = {
    "1x": "",
    "2x": "",
  };

  if (path) {
    PATH = `/img/${path}/${option}/thumbnails/`;
    thumbnails["1x"] = imageExists(PATH) ? `${PATH}thumbnail@1x.png` : "/img/placeholder/thumbnail@1x.png";
    thumbnails["2x"] = imageExists(PATH) ? `${PATH}thumbnail@2x.png` : "/img/placeholder/thumbnail@2x.png";
  } else {
    if (template) {
      thumbnails = getTemplateThumbnails(option, theme);
    } else {
      thumbnails = getSchemaThumbnails(option, theme);
    }
  }

  if (!thumbnails) {
    return false;
  }

  const fallback = thumbnails["2x"];
  const imgDensity1x = thumbnails["1x"];
  const srcSet = `${imgDensity1x} 1x, ${fallback} 2x`;

  return {
    srcSet,
    src: fallback,
    alt: option,
  };
};

export { getThumbnailProps };
