import React from "react";

import { CheckField, TableCounter } from "@ax/components";

import * as S from "./style";

const GridHeader = (props: IProps): JSX.Element => {
  const { selectAllItems, checkState, totalItems } = props;

  return (
    <S.Header>
      <S.CheckWrapper>
        <CheckField
          key="selectAll"
          name="selectAll"
          value="selectAll"
          onChange={selectAllItems}
          checked={checkState.isAllSelected}
          disabled={false}
          error={false}
          title="Select all documents"
        />
      </S.CheckWrapper>
      <S.CounterWrapper>
        <TableCounter totalItems={totalItems} />
      </S.CounterWrapper>
    </S.Header>
  );
};

interface IProps {
  totalItems: number;
  selectAllItems: () => void;
  checkState: Record<string, boolean>;
}

export default GridHeader;
