import React, { useEffect, useState } from "react";

import { CheckGroup, FloatingMenu, Icon, ListTitle } from "@ax/components";
import { areEquals } from "@ax/helpers";
import { IFilterValue, IQueryValue } from "@ax/types";

import * as S from "./style";

const TranslationsFilter = (props: ITranslationsFilterProps): JSX.Element => {
  const { filterItems, value } = props;

  const filters: IFilterValue[] = [
    {
      name: "all",
      value: "all",
      title: "All",
    },
    {
      name: "no",
      value: "no",
      title: "Not translated content",
    },
  ];

  const selectAllOption = "all";
  const pointer = "translated";
  const parsedValue = Array.isArray(value) && value.length ? value.map((value) => value.value) : [selectAllOption];
  const [selectedValue, setSelectedValue] = useState(parsedValue);

  useEffect(() => {
    const arrayValues = Array.isArray(value) && value.length ? value.map((value) => value.value) : [selectAllOption];
    setSelectedValue(arrayValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const setQuery = (selection: any) => {
    setSelectedValue(selection);
    const queryFilters: IQueryValue[] = selection.map((value: string | number) => {
      const label = filters.find((opt: IFilterValue) => opt.value === value);
      return { value, label: label?.title || "" };
    });
    filterItems(pointer, queryFilters);
  };

  const isActive = !areEquals(selectedValue, selectAllOption);

  const Header = () => (
    <S.Translations isActive={isActive} data-testid="translation-filter-header">
      Trans.
      <S.IconsWrapper data-testid="translation-filter-icons-wrapper">
        {isActive ? <Icon name="Filter" size="16" /> : <Icon name="DownArrow" size="16" />}
      </S.IconsWrapper>
    </S.Translations>
  );

  return (
    <FloatingMenu Button={Header} position="left" closeOnSelect={true} isCheckGroup={true}>
      <ListTitle>Filter by translations</ListTitle>
      <S.ChecksWrapper data-testid="translation-filter-checks-wrapper">
        <CheckGroup options={filters} value={selectedValue} onChange={setQuery} multipleSelection={false} />
      </S.ChecksWrapper>
    </FloatingMenu>
  );
};

export interface ITranslationsFilterProps {
  filterItems(pointer: string, filter: IQueryValue[]): void;
  value: IQueryValue[];
}

export default TranslationsFilter;
