import * as React from "react";
const SvgIa = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#5057FF"
      fillRule="evenodd"
      d="M11.063 3.063 12 1l.938 2.063L15 4l-2.063.938L12 7l-.938-2.063L9 4l2.063-.938Zm-.22 10.093L8.5 8l-2.344 5.156L1 15.5l5.156 2.344L8.5 23l2.344-5.156L16 15.5l-5.156-2.344ZM18.5 5l-1.406 3.094L14 9.5l3.094 1.406L18.5 14l1.406-3.094L23 9.5l-3.094-1.406L18.5 5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIa;
